import React, { useEffect, useState } from 'react';
import { Box, SpaceBetween, Modal, FormField, Button, Input, Alert, ColumnLayout } from '@amzn/awsui-components-react/polaris';
import { CalculationTemplateMetaData } from 'src/models/calculation-builder/CalculationTemplate';
import CONSTANTS from 'src/utils/constants';
import TemporaryMessage from 'src/models/common/TemporaryMessage';
import LinkedCLI from 'src/models/calculation-builder/LinkedCLI';


export default function CreateRenameTemplateModal (props: {
        visible: boolean,
        workbookName: string,
        alertMessage?: TemporaryMessage,
        isSaving: boolean,
        templateMetaData?: CalculationTemplateMetaData,
        templateName?: string,
        linkedCLIs: LinkedCLI[],
        onClose: () => void,
        onSubmit: (c: CalculationTemplateMetaData) => void,
    }) {
    const { visible, workbookName, alertMessage, isSaving, templateMetaData,linkedCLIs, templateName, onClose: onModalClosed, onSubmit } = props;
    const { CALCULATION_TEMPLATE_FIELDS : FIELDS } = CONSTANTS
    const [model, setModel] = useState(new CalculationTemplateMetaData());
    const [formErrorsModel, setFormErrorsModel] = useState(new CalculationTemplateMetaData());
    const [showError, setShowError] = useState(false);
    
    useEffect(() => {
        if (visible) {
            resetModal();
        }
    }, [visible, templateMetaData])

    useEffect(() => {
        if (isSaving) {
            setShowError(true);
        }
    }, [isSaving])

    const resetModal = () => {
        if (templateMetaData == null || templateMetaData.templateName == null || templateMetaData.dataSetName == null || templateMetaData.modelName == null) {
            const x = {
                templateName: '',
                dataSetName: '',
                modelName: ''
            } as CalculationTemplateMetaData;
            setModel(x);
        } else {
            const templateNamePrefixIndex = templateMetaData.templateName.startsWith(`${CONSTANTS.TEMPLATE_FIELD_PREFIXES.TEMPLATE}${workbookName}_`)? `${CONSTANTS.TEMPLATE_FIELD_PREFIXES.TEMPLATE}${workbookName}_`.length : 0;
            const datasetNamePrefixIndex = templateMetaData.dataSetName.startsWith(`${CONSTANTS.TEMPLATE_FIELD_PREFIXES.DATASET}${workbookName}_`)? `${CONSTANTS.TEMPLATE_FIELD_PREFIXES.DATASET}${workbookName}_`.length : 0;
            const tpModelNamePrefixIndex = templateMetaData.modelName.startsWith(`${CONSTANTS.TEMPLATE_FIELD_PREFIXES.TP_MODEL}${workbookName}_`)? `${CONSTANTS.TEMPLATE_FIELD_PREFIXES.TP_MODEL}${workbookName}_`.length : 0;
            const x = {
                templateName: templateMetaData.templateName.substring(templateNamePrefixIndex),
                dataSetName: templateMetaData.dataSetName.substring(datasetNamePrefixIndex),
                modelName: templateMetaData.modelName.substring(tpModelNamePrefixIndex),
                templateId: templateMetaData.templateId
            } as CalculationTemplateMetaData;
            setModel(x);
        }
        
        setFormErrorsModel(new CalculationTemplateMetaData());
        setShowError(false);
    }
    
    const updateModelFieldValue = function (fieldName: string, value?: string) {
        const updatedModel = {
            ...model,
            [fieldName]: value,
        };
        const updatedErrorModel = {
            ...formErrorsModel
        } as any;

        updatedErrorModel[fieldName] = value?.trim() === ''? updatedErrorModel[fieldName] : '';
        
        setModel(updatedModel);
        setFormErrorsModel(updatedErrorModel);
    }

    const onSaveClickedHandler = function(){
        const updatedErrorModel = new CalculationTemplateMetaData();
        if (model.templateName.trim() === ''){
            updatedErrorModel.templateName = `${FIELDS.TEMPLATE_NAME.value} is required`;
        }
        if (model.dataSetName.trim() === ''){
            updatedErrorModel.dataSetName = `${FIELDS.DATA_SET_NAME.value} is required`;
        }
        if (model.modelName.trim() === ''){
            updatedErrorModel.modelName = `${FIELDS.MODEL_NAME.value} is required`;
        }
        if (updatedErrorModel.templateName.length > 1 || updatedErrorModel.dataSetName.length > 1 || updatedErrorModel.modelName.length > 1) {
            setFormErrorsModel(updatedErrorModel);
            return;
        }
        onSubmit({
            ...model,
            templateName: `${CONSTANTS.TEMPLATE_FIELD_PREFIXES.TEMPLATE}${workbookName}_${model.templateName}`,
            dataSetName: `${CONSTANTS.TEMPLATE_FIELD_PREFIXES.DATASET}${workbookName}_${model.dataSetName}`,
            modelName: `${CONSTANTS.TEMPLATE_FIELD_PREFIXES.TP_MODEL}${workbookName}_${model.modelName}`
        });
    }

    const hasSuccessfulMessage = alertMessage?.type === "success";
    
    return <Modal className='createRenameTemplateModal' visible={visible} 
        header={templateMetaData?.templateId == null? "Save Calculation Template": "Rename Calculation Template"}
        onDismiss={onModalClosed}
        footer={
            <Box float="right">
                {hasSuccessfulMessage? 
                <Button variant="primary" className="okButton" onClick={onModalClosed}>Ok</Button> :
                <>
                    <Button variant="link" className="cancelButton" onClick={onModalClosed}>Cancel</Button>
                    <Button variant="primary" className="saveButton" disabled={isSaving} onClick={onSaveClickedHandler}>{isSaving? "Saving..." : "Save" }</Button>
                </>}
            </Box>
        }>
            <SpaceBetween direction="vertical" size="l"> 
            { hasSuccessfulMessage?
                null :
                <>
                    <FormField className="templateNameField" label={FIELDS.TEMPLATE_NAME.value} stretch={true} 
                        errorText={formErrorsModel.templateName}
                        description={`${FIELDS.TEMPLATE_NAME.value} will be saved as: '${CONSTANTS.TEMPLATE_FIELD_PREFIXES.TEMPLATE}${workbookName}_${model.templateName}'`}
                    >
                        <Input className='templateNameFieldInput'
                            value={model.templateName}
                            onChange={event => updateModelFieldValue(FIELDS.TEMPLATE_NAME.key, event.detail.value)}
                        />
                    </FormField>
                    <FormField className="datasetNameField" label={FIELDS.DATA_SET_NAME.value} stretch={true} 
                        errorText={formErrorsModel.dataSetName}
                        description={`${FIELDS.DATA_SET_NAME.value} will be saved as: '${CONSTANTS.TEMPLATE_FIELD_PREFIXES.DATASET}${workbookName}_${model.dataSetName}'`}
                    >
                        <Input className='datasetNameFieldInput'
                            value={model.dataSetName}
                            onChange={event => updateModelFieldValue(FIELDS.DATA_SET_NAME.key, event.detail.value)}
                        />
                    </FormField>
                    <FormField className="tpModelField" label={FIELDS.MODEL_NAME.value} stretch={true} 
                        errorText={formErrorsModel.modelName}
                        description={`${FIELDS.MODEL_NAME.value} will be saved as: '${CONSTANTS.TEMPLATE_FIELD_PREFIXES.TP_MODEL}${workbookName}_${model.modelName}'`}
                    >
                        <Input className='tpModelFieldInput'
                            value={model.modelName}
                            onChange={event => updateModelFieldValue(FIELDS.MODEL_NAME.key, event.detail.value)}
                        />
                    </FormField> 
                    {templateMetaData?.templateId != null && <Alert type="info" className='linkedCLIsCreateTemplateModalAlert'>
                            <SpaceBetween direction="vertical" size="l"> 
                                <div>
                                    This change will update template <strong>{templateName}</strong> with {linkedCLIs.length} linked CLI{linkedCLIs.length !== 1 && 's'}.
                                </div>                            
                            </SpaceBetween>
                        </Alert>
                    }
                </>           
            }
            <Alert className='createTemplateModalAlert' visible={showError && alertMessage != null} type={alertMessage?.type as any} header={alertMessage?.header}>{alertMessage?.message}</Alert>
        </SpaceBetween>
    </Modal>;
}