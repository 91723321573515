import React from 'react';
import { TPEReactTable } from 'src/components/shared/TPEReactTable';
import { JournalLine } from 'src/models/calculation-builder/Journal';
import CONSTANTS from 'src/utils/constants';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import { CalculationSourceType } from 'src/models/common/CalculationSource';

export default function JournalEntriesGrid(props: {data: JournalLine[]}) {
    const { calcBuilderState } = React.useContext(CalculationBuilderContext);
    const { calculationSource } = calcBuilderState;
    const { data } = props;

    const getColumnDefinitions = () => {
        const colDefs: any[] = [];
            Object.values(CONSTANTS.JE_FIELDS).forEach(x => {
                if (
                    (x.ACCESSOR === CONSTANTS.JE_FIELDS.AGREEMENT_LINE_NUM.ACCESSOR && calculationSource === CalculationSourceType.ICRS) || 
                    (x.ACCESSOR === CONSTANTS.JE_FIELDS.ICRS_REGISTRATION_NUM.ACCESSOR && calculationSource === CalculationSourceType.APTTUS)){
                    // Do nothing
                }
                else {
                    colDefs.push({
                        accessor: x.ACCESSOR,
                        Header: (e: any) => <div className="cell-text-non-sortable">{x.DISPLAY}</div>,
                        headerClass: x.MANDATORY ? "" : "greenTableHeader",
                        Cell: (e: any) => <div className="cell-text">{e.value}</div>
                    });
                }
            });
            Object.values(CONSTANTS.ADDITIONAL_REVIEW_JE_FIELDS).forEach(x => {
                colDefs.push({
                    accessor: x.ACCESSOR,
                    Header: (e: any) => <div className="cell-text-non-sortable">{x.DISPLAY}</div>,
                    Cell: (e: any) => <div className="cell-text">{e.value}</div>
                });
            });
        return colDefs;
    }


    const columnDefinitions = React.useMemo(getColumnDefinitions, []);

    return (
        <div className="dataSourceTableContainer">
            <TPEReactTable {...{
                data,
                columnDefinitions,
                className: "nonGridTable journalEntries",
                sortable: false
            }} />
        </div>
    );
}