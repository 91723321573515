import GetMECCLIDetailsResult from 'src/models/mec/status/GetMECCLIDetailsResult';
import GetMECStatusDataResult, {GroupExecutionStatus} from 'src/models/mec/status/GetMECStatusDataResult';
import { Reversal } from 'src/models/mec/status/Reversal';

export interface MECStatusState {
    group: string,
    statusResult: GetMECStatusDataResult
    cliDetailsResult: GetMECCLIDetailsResult,
    executionStatusSearchResult?: GroupExecutionStatus,
    reversalSearchType?: string,
    reversalSearchText?: string,
    reversals?: Reversal[],
    reversalSearchItems?: Reversal[],
}

export const initialState: MECStatusState = {
    group: '',
    statusResult: {records: []},
    cliDetailsResult: {cliDetails: []}
}