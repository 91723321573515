import React, { useEffect } from 'react';
import 'src/assets/styles/polaris-container.scss';
import './Calculations.scss';
import { Container, TokenGroup, Grid, SpaceBetween, Button, Alert } from '@amzn/awsui-components-react/polaris';
import ServiceCollection from 'src/services/ServiceCollection';
import { calculationsReducer, ACTIONS } from 'src/services/calculations/CalculationsReducer';
import { initialState, SearchCalculationsState } from 'src/services/calculations/SearchCalculationsState';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import SearchCalculationsGrid from './SearchCalculations_Grid';
import SearchMoreOptionsModal from './SearchCalculations_MoreOptionsModal';
import SaveSearchModal from './SearchCalculations_SaveSearchModal';
import TPESearchRequest, { DbConjunctor, DbExpression, DbOperator } from 'src/models/common/TPESearchRequest';
import SavedSearch from 'src/models/calculations/SavedSearch';
import { UserProfile } from 'src/models/users/UserProfile';
import { MoreOptionsModel, MoreOptionsItem } from 'src/models/calculations/SavedSearchModel';
import CONSTANTS from 'src/utils/constants';
import { SortingRule } from 'react-table';
import { SearchSortOrder } from 'src/models/common/SearchSortingSpec';
import CalculationLineSelector from './CalculationLineSelector';
import { useHistory } from 'react-router-dom';

export default function SearchCalculations(props: { services: ServiceCollection, userProfile: UserProfile }) {
    const [state, dispatch] = useReducerWithLogger(calculationsReducer, initialState);
    const {
        isLoading,
        selectedAgreement,
        selectedAgreementLine,
        selectedCalculationLine,
        searchParams,
        searchResultItems,
        searchResultsIsVisible,
        moreOptionsModalIsVisible,
        isSaveSearchModalVisible,
        otherOptions,
        savedSearches,
        selectedSavedSearch,
        lastSavedSearchTimestamp,
        temporaryMessage,
        searchCurrentPage,
        searchPageSize,
        searchTotalSize,
        searchSorting,
        searchFilters,
    } = state as SearchCalculationsState;
    const { services, userProfile } = props;
    const [savedSearchesResult] = services.calculationsService.getSavedSearches(userProfile);
    const [searchResult, isSearching, searchError] = services.calculationsService.searchCalculationLines(searchParams as TPESearchRequest);
    const [moreOptionsModel, setMoreOptionsModel] = React.useState({} as MoreOptionsModel);
    const [atpUsers, loadingAtpUsers, errorAtpUsers] = services.usersService.getAtpUsers("pull");
    const [companies, loadingCompanies, errorCompanies] = services.calculationsService.getCompany(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.WEB_API);
    const [agreementStatus, loadingAgreementStatus, errorAgreementStatus] = services.calculationsService.getAgreementStatus();
    const [calculationStatus, loadingCalculationStatus, errorCalculationStatus] = services.calculationsService.getCalculationStatus();
    const [calculationLineNumber,loadingCalcLineNumber,errorCalcLineNumber] = services.calculationsService.getActiveCLIDetails();
    const history = useHistory();
    services.calculationsService.saveSavedSearches(userProfile, savedSearches);


    useEffect(() => {
        if (savedSearchesResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_SAVED_SEARCHES.withPayload(savedSearchesResult));
    }, [savedSearchesResult]);


    // Triggered when the list of calculation lines changes
    useEffect(() => {
        if (searchResult == null) {
            return;
        }
        // Sets calculation lines on the reducer's state
        dispatch(ACTIONS.SET_SEARCH_RESULTS
            .withPayload(searchResult)
        )
    }, [searchResult]);

    useEffect(() => {
        if (savedSearches == null || savedSearches.length === 0) {
            return;
        }

        services.messageService.showSuccessTimed("Search was successfully saved!");
    }, [lastSavedSearchTimestamp]);

    useEffect(() => {
        if (selectedSavedSearch == null) {
            return;
        }
        const model = new MoreOptionsModel();
        selectedSavedSearch.filters.forEach((filter: MoreOptionsItem) => {
            (model as any)[filter.key] = filter.value;
        });
        setMoreOptionsModel(model);
        if (model.agreement != null) {
            dispatch(ACTIONS.SET_SELECTED_AGREEMENT
                .withPayload(model.agreement)
            )
        }
        if (model.agreementLineNumber != null) {
            dispatch(ACTIONS.SET_SELECTED_AGREEMENT_LINE
                .withPayload(model.agreementLineNumber)
            )
        }
        if (model.calculationNumber != null) {
            dispatch(ACTIONS.SET_SELECTED_CALCULATION_LINE
                .withPayload(model.calculationNumber)
            )
        }
    }, [selectedSavedSearch]);

    useEffect(() => {
        if (isLoading || isSearching) {
            return;
        }
        dispatchSearch();
    }, [searchCurrentPage, searchSorting, searchFilters]);

    const [resetNeededSignal, setResetNeededSignal] = React.useState(null as unknown as string);

    const resetPage = () => {
        dispatch(ACTIONS.CLEAR_FILTERS)
        setMoreOptionsModel({} as MoreOptionsModel);
        setResetNeededSignal(new Date().toString());
    }

    const handleCancelClicked = () => {
        if (selectedAgreement == null || selectedAgreement === '') {
            history.push('/calculations');
            return;
        }
        resetPage();
    }

    const dispatchSearch = function () {
        const payload = {
            page: searchCurrentPage,
            pageSize: parseInt(searchPageSize.toString()),
            searchStartedDate: new Date(),
        } as any;
        let filterExpression = {
            conjunctor: DbConjunctor.AND,
            expressions: [] as DbExpression[],
        };
        if (selectedAgreement != null) {
            filterExpression.expressions.push({
                attributeName: CONSTANTS.CALCULATIONS_SEARCH_FIELDS.AGREEMENT_NUMBER.key,
                operator: DbOperator.CONTAINS,
                values: [selectedAgreement]
            })
        }
        if (selectedAgreementLine != null) {
            filterExpression.expressions.push({
                attributeName: CONSTANTS.CALCULATIONS_SEARCH_FIELDS.AGREEMENT_LINE.key,
                operator: DbOperator.CONTAINS,
                values: [selectedAgreementLine]
            })
        }
        if (selectedCalculationLine != null) {
            filterExpression.expressions.push({
                attributeName: CONSTANTS.CALCULATIONS_SEARCH_FIELDS.CALCULATION_LINE.key,
                operator: DbOperator.CONTAINS,
                values: [selectedCalculationLine]
            })
        }
        if (otherOptions != null) {
            if (filterExpression == null) {
                filterExpression = {
                    conjunctor: DbConjunctor.AND,
                    expressions: [],
                };
            }
            otherOptions.forEach((filter: MoreOptionsItem) => {
                filterExpression?.expressions.push({
                    attributeName: filter.key,
                    operator: DbOperator.CONTAINS,
                    values: [filter.value] //TODO: Split array here if we want to support multiple values
                })
            })
        }
        if (searchSorting != null) {
            payload.sortField = searchSorting.sortField;
            payload.sortOrder = searchSorting.sortOrder;
        }
        payload.filterExpression = filterExpression;

        dispatch(ACTIONS.START_SEARCH.withPayload(payload));
    }

    const onSaveSearchSubmitted = function (searchName: string) {
        const newSearch = new SavedSearch(searchName);
        if (selectedAgreement != null && selectedAgreement.trim().length > 0) {
            newSearch.filters.push(new MoreOptionsItem("agreement", selectedAgreement));
        }
        if (selectedAgreementLine != null && selectedAgreementLine.trim().length > 0) {
            newSearch.filters.push(new MoreOptionsItem("agreementLineNumber", selectedAgreementLine));
        }
        if (selectedCalculationLine != null && selectedCalculationLine.trim().length > 0) {
            newSearch.filters.push(new MoreOptionsItem("calculationNumber", selectedCalculationLine));
        }
        if (otherOptions.length > 0) {
            newSearch.filters = newSearch.filters.concat(otherOptions);
        }
        dispatch(ACTIONS.ADD_SAVED_SEARCH.withPayload(newSearch));
    }

    const dispatchSortByChanged = function (sortBySpecs: Array<SortingRule<any>>) {
        if (sortBySpecs == null || sortBySpecs.length === 0) {
            dispatch(ACTIONS.SET_SEARCH_SORT.withPayload(undefined));
            return;
        }
        const sortBy = sortBySpecs[0];
        dispatch(ACTIONS.SET_SEARCH_SORT.withPayload({
            sortOrder: sortBy.desc ? SearchSortOrder.DESCENDING : SearchSortOrder.ASCENDING,
            sortField: sortBy.id
        }))

    }

    const dispatchFilterChanged = function (filters: Array<{ id: string, value: any }>) {
        if (filters == null || filters.length === 0) {
            dispatch(ACTIONS.SET_SEARCH_FILTERS.withPayload(undefined));
            return;
        }

        const allFiltersHaveValidValue = filters.every(x => x.value.length > 2);
        if (!allFiltersHaveValidValue) {
            return;
        }

        dispatch(ACTIONS.SET_SEARCH_FILTERS.withPayload(filters));
    }

    
    const searchButtonIsEnabled = selectedAgreement != null || selectedAgreementLine != null || selectedCalculationLine != null || otherOptions.length > 0;


    return (
        <Container className="polaris-content-container">
            <Grid gridDefinition={[{ colspan: { xl: 11, default: 10 } }, { colspan: { xl: 1, default: 2 } }]}>
                <CalculationLineSelector
                    selectedAgreementNumber={selectedAgreement}
                    selectedAgreementLine={selectedAgreementLine}
                    selectedCalculationLine={selectedCalculationLine}
                    services={services}
                    resetNeeded={resetNeededSignal}
                    onAgreementNumberSelected={(number) => dispatch(ACTIONS.SET_SELECTED_AGREEMENT
                        .withPayload(number)
                    )}
                    onAgreementLineSelected={(line) => dispatch(ACTIONS.SET_SELECTED_AGREEMENT_LINE
                        .withPayload(line)
                    )}
                    onCalculationLineSelected={(line) => dispatch(ACTIONS.SET_SELECTED_CALCULATION_LINE
                        .withPayload(line)
                    )} />
                <SpaceBetween direction="vertical" size="xxs">
                    <br />
                    <div style={{ textAlign: "center" }}>
                        <Button className="moreOptionsButton" onClick={({ detail }) => {
                            dispatch(ACTIONS.SET_MORE_OPTIONS_VISIBLE
                                .withPayload(true)
                            );
                        }}>More options</Button>
                        <SearchMoreOptionsModal
                            isVisible={moreOptionsModalIsVisible}
                            dispatch={dispatch}
                            model={moreOptionsModel}
                            savedSearches={savedSearches}
                            selectedSavedSearch={selectedSavedSearch}
                            atpUsers={atpUsers}
                            calculationLineNumber={calculationLineNumber}
                            companies={companies}
                            accountingOwner={atpUsers}
                            agreementStatus={agreementStatus}
                            calculationStatus={calculationStatus}
                            searchState={state as SearchCalculationsState}
                            onModelChanged={(model: any) => setMoreOptionsModel(model)} />
                    </div>
                </SpaceBetween>
            </Grid>
            <TokenGroup
                onDismiss={({ detail: { itemIndex } }) => {
                    const key = otherOptions[itemIndex].key;
                    (moreOptionsModel as any)[key] = null;
                    setMoreOptionsModel(moreOptionsModel);
                    dispatch(ACTIONS.SET_MORE_OPTIONS.withPayload([
                        ...otherOptions.slice(0, itemIndex),
                        ...otherOptions.slice(itemIndex + 1)
                    ]));
                }}
                items={(otherOptions as MoreOptionsItem[]).map(x => ({ label: x.getKeyFriendlyName(), description: x.value || '' }))}
            />
            <div className="calcSearchButtonsContainer">
                <div>
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button className="cancelButton" variant="link" onClick={handleCancelClicked}>Cancel</Button>
                        <Button className="saveSearchButton" variant="link"
                            disabled={!searchButtonIsEnabled}
                            onClick={() => {
                                services.messageService.setup(dispatch, ACTIONS.SET_TEMPORARY_MESSAGE);
                                dispatch(ACTIONS.SET_SAVE_SEARCH_VISIBLE.withPayload(true))
                            }} >
                            Save search</Button>
                        <Button className="searchButton"
                            variant="primary"
                            onClick={dispatchSearch}
                            disabled={!searchButtonIsEnabled}
                            loading={isSearching == true}>
                            Search calculation
                        </Button>
                    </SpaceBetween>
                    <SaveSearchModal isVisible={isSaveSearchModalVisible} savedSearches={savedSearches} onSearchSaved={onSaveSearchSubmitted} dispatch={dispatch} />
                    <br />
                    {(temporaryMessage == null ? <span /> : <Alert
                        className="alert-container"
                        onDismiss={() => dispatch(ACTIONS.SET_TEMPORARY_MESSAGE.withPayload(null))}
                        visible={temporaryMessage?.visible}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type={temporaryMessage?.type as any}
                    >
                        {temporaryMessage?.message}
                    </Alert>)}

                </div>
            </div>
            <br />
            <SearchCalculationsGrid
                isVisible={searchResultsIsVisible}
                searchResult={searchResultItems || []}
                isSearching={isSearching as boolean}
                searchError={searchError as string}
                searchCurrentPage={searchCurrentPage}
                searchPageSize={searchPageSize}
                searchTotalSize={searchTotalSize}
                onSortBy={(x: Array<any>) => dispatchSortByChanged(x)}
                onFilter={(x: Array<any>) => dispatchFilterChanged(x)}
                onPageChanged={(page: number) => dispatch(ACTIONS.SET_CURRENT_PAGE.withPayload(page))}
            />
        </Container>
    )
}
