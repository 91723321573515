import { AppModules, AppRoles, PermissionMapping, PermissionTypes, RolePermissions } from "src/models/permissions/RolePermissions";
import { GlobalAppContext } from "./App";
import React, { useContext } from "react";
import { UserProfile } from "src/models/users/UserProfile";
import ArrayUtils from "src/utils/arrayUtils";

const appPermissions = Object.freeze([
    new PermissionMapping(AppRoles.ADMIN, AppModules.DASHBOARD, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.TP_WORKLISTS, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT, PermissionTypes.CAN_VIEW_APTTUS_AGREEMENTS]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.CALCULATION_MANAGER, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.CREATE_NEW_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.CREATE_NEW_ICRS_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.SEARCH_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.BULK_CLONE, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.BULK_LINK, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.CUSTOM_DATA_TABLES, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT, PermissionTypes.CAN_FILTER, PermissionTypes.CAN_ADD_NEW, PermissionTypes.CAN_RENAME]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.CUSTOM_COA, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.TP_ALLOCATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.CALCULATION_BUILDER, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.MEC, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT, PermissionTypes.CAN_RUN_PREVALIDATION, PermissionTypes.CAN_BULK_REVERSE]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.REPORTING, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.READ_ONLY_CALC, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ADMIN, AppModules.BULK_ACTIONS, [PermissionTypes.CAN_VIEW]),

    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.DASHBOARD, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.TP_WORKLISTS, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.CALCULATION_MANAGER, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.CREATE_NEW_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.CREATE_NEW_ICRS_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.SEARCH_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.BULK_CLONE, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.BULK_LINK, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.CUSTOM_DATA_TABLES, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT, PermissionTypes.CAN_FILTER, PermissionTypes.CAN_ADD_NEW, PermissionTypes.CAN_RENAME]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.CUSTOM_COA, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.TP_ALLOCATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.CALCULATION_BUILDER, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.MEC, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.REPORTING, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.READ_ONLY_CALC, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ACCOUNTING, AppModules.BULK_ACTIONS, []),

    new PermissionMapping(AppRoles.TAX, AppModules.DASHBOARD, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.TAX, AppModules.TP_WORKLISTS, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_VIEW_APTTUS_AGREEMENTS]),
    new PermissionMapping(AppRoles.TAX, AppModules.CALCULATION_MANAGER, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.TAX, AppModules.CREATE_NEW_CALCULATION, []),
    new PermissionMapping(AppRoles.TAX, AppModules.CREATE_NEW_ICRS_CALCULATION, []),
    new PermissionMapping(AppRoles.TAX, AppModules.SEARCH_CALCULATION, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.TAX, AppModules.BULK_CLONE, []),
    new PermissionMapping(AppRoles.TAX, AppModules.BULK_LINK, []),
    new PermissionMapping(AppRoles.TAX, AppModules.CUSTOM_DATA_TABLES, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_FILTER]),
    new PermissionMapping(AppRoles.TAX, AppModules.CUSTOM_COA, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.TAX, AppModules.TP_ALLOCATION, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.TAX, AppModules.CALCULATION_BUILDER, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.TAX, AppModules.MEC, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.TAX, AppModules.REPORTING, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.TAX, AppModules.READ_ONLY_CALC, []),
    new PermissionMapping(AppRoles.TAX, AppModules.BULK_ACTIONS, []),

    new PermissionMapping(AppRoles.ENGINEERING, AppModules.DASHBOARD, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.TP_WORKLISTS, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT, PermissionTypes.CAN_VIEW_APTTUS_AGREEMENTS]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.CALCULATION_MANAGER, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.CREATE_NEW_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.CREATE_NEW_ICRS_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.SEARCH_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.BULK_CLONE, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.BULK_LINK, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.CUSTOM_DATA_TABLES, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT, PermissionTypes.CAN_FILTER, PermissionTypes.CAN_ADD_NEW, PermissionTypes.CAN_VIEW_ALL, PermissionTypes.CAN_RENAME]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.CUSTOM_COA, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.TP_ALLOCATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.CALCULATION_BUILDER, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.MEC, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT, PermissionTypes.CAN_RUN_PREVALIDATION, PermissionTypes.CAN_BULK_REVERSE]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.REPORTING, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.READ_ONLY_CALC, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.APP, [PermissionTypes.CAN_SIMULATE]),
    new PermissionMapping(AppRoles.ENGINEERING, AppModules.BULK_ACTIONS, [PermissionTypes.CAN_VIEW]),

    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.DASHBOARD, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.TP_WORKLISTS, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT, PermissionTypes.CAN_VIEW_APTTUS_AGREEMENTS]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.CALCULATION_MANAGER, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.CREATE_NEW_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.CREATE_NEW_ICRS_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.SEARCH_CALCULATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.BULK_CLONE, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.BULK_LINK, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.CUSTOM_DATA_TABLES, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT, PermissionTypes.CAN_FILTER, PermissionTypes.CAN_ADD_NEW, PermissionTypes.CAN_VIEW_ALL, PermissionTypes.CAN_RENAME]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.CUSTOM_COA, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.TP_ALLOCATION, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.CALCULATION_BUILDER, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.MEC, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT, PermissionTypes.CAN_RUN_PREVALIDATION, PermissionTypes.CAN_BULK_REVERSE]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.REPORTING, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.READ_ONLY_CALC, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.ENGINEERING_I3, AppModules.BULK_ACTIONS, []),

    new PermissionMapping(AppRoles.NAFN, AppModules.DASHBOARD, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.NAFN, AppModules.TP_WORKLISTS, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.CALCULATION_MANAGER, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.NAFN, AppModules.CREATE_NEW_CALCULATION, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.CREATE_NEW_ICRS_CALCULATION, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.SEARCH_CALCULATION, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.BULK_CLONE, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.BULK_LINK, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.CUSTOM_DATA_TABLES, [PermissionTypes.CAN_VIEW, PermissionTypes.CAN_EDIT]),
    new PermissionMapping(AppRoles.NAFN, AppModules.CUSTOM_COA, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.TP_ALLOCATION, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.CALCULATION_BUILDER, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.MEC, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.REPORTING, []),
    new PermissionMapping(AppRoles.NAFN, AppModules.READ_ONLY_CALC, [PermissionTypes.CAN_VIEW]),
    new PermissionMapping(AppRoles.NAFN, AppModules.BULK_ACTIONS, []),
]);

export function getPermissions(module: string, initialUserProfile?: UserProfile) : RolePermissions {
    const rolePermissions = new RolePermissions();
    let permissionsUserProfile = initialUserProfile;
    if (initialUserProfile == null) {
        const { userProfile } = useContext(GlobalAppContext);
        permissionsUserProfile = userProfile;
    }
    if (permissionsUserProfile == null || ArrayUtils.isNullOrEmpty(permissionsUserProfile.roles)) {
        return rolePermissions;
    }
    
    const permissionMappingArray = appPermissions.filter(p => p.role == permissionsUserProfile?.roles[0] && p.module == module);
    if (permissionMappingArray.length == 0) {
        return rolePermissions;
    }
    permissionMappingArray[0].permissions.forEach(p => (rolePermissions as any)[p] = true);
    console.log(rolePermissions);
    return rolePermissions;
}