import React, { useEffect, useState } from "react";
import { Box, Button, ExpandableSection, SpaceBetween, Alert } from '@amzn/awsui-components-react';
import { TPAllocationContext } from "src/services/tp-allocation/TPAllocationContext";
import CONSTANTS from "src/utils/constants";
import { AllocationGroupState, initialState } from 'src/services/tp-allocation/allocation-groups/AllocationGroupsState';
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import { allocationGroupsReducer, ACTIONS } from "src/services/tp-allocation/allocation-groups/AllocationGroupsReducer";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import ArrayUtils from "src/utils/arrayUtils";
import { TPAllocationState } from "src/services/tp-allocation/TPAllocationState";
import AllocationGroupContainer from "./AllocationGroupContainer";
import AllocationGroup, { AllocationGroupFormula } from "src/models/tp-allocation/AllocationGroup";
import { DatasetRecord } from "src/models/tp-allocation/DatasetRecord";
import WorksheetTotal from "src/models/tp-allocation/WorksheetTotal";
import { WorksheetMode } from "src/models/tp-allocation/TPAllocationEnums";
import { TPELoadingSpinner } from "src/components/shared/TPELoadingSpinner";
import AllocationGroupEditorModal from "./AllocationGroupEditorModal";
import TemporaryMessage from "src/models/common/TemporaryMessage";
import StringUtils from "src/utils/stringUtils";
import { ACTION_TYPE, TPEBasicModal } from "src/components/shared/TPEBasicModal";
import { ACTIONS as TP_ALLOCATION_ACTIONS } from "src/services/tp-allocation/TPAllocationReducer";
import SimpleUseEffect from "src/components/shared/SimpleUseEffect";
import { SavingStatus } from "src/models/common/SavingStatus";

export type AllocationGroupsContextType = {
    groupsState: AllocationGroupState,
    tpAllocationState: TPAllocationState,
    tpAllocationDispatch: React.Dispatch<TPEAction>,
    groupsDispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    dataSources: DatasetRecord[],
    totals: WorksheetTotal[],
}

const AllocationGroupsProvider = (props: any) => {
    const { state, dispatch, services, children, tpAllocationState, totals, dataSources, tpAllocationDispatch } = props;
    const providerValue = React.useMemo(() => ({
        groupsState: state, groupsDispatch: dispatch, services, tpAllocationState, totals, dataSources, tpAllocationDispatch
    }), [state, dispatch, dataSources, totals]);
    return (
        <AllocationGroupsContext.Provider value={providerValue}>
            {children}
        </AllocationGroupsContext.Provider>
    );
}
export const AllocationGroupsContext = React.createContext(null as unknown as AllocationGroupsContextType);

export default function AllocationGroupsContainer(props: { 
    containerTitle: string,
    expanded?: boolean,
    onCancel: () => void 
}){
    const { containerTitle, expanded } = props;
    const { services, tpAllocationState, tpAllocationDispatch } = React.useContext(TPAllocationContext);
    const [ state, dispatch] = useReducerWithLogger(allocationGroupsReducer, initialState);
    const { viewMode, worksheet, datasetRecords, worksheetTotals, worksheetMode, worksheetTemplate, isValidating, allocationGroupFormulasAreValid } = tpAllocationState;
    const { groups, groupBeingEdited, removeGroupModalPayload, removeGroupModalVisible, updateFormulaPayload, removeFormulaModalPayload } = state;
    const [groupEditorModalVisible, setGroupEditorModalVisible] = useState(false);
    const [shouldRefreshGroups, setShouldRefreshGroups] = useState(true);
    const [groupEditorModalMessage, setGroupEditorModalMessage] = useState(undefined as TemporaryMessage | undefined);
    const [saveGroupPayload, setSaveGroupPayload] = useState(undefined as AllocationGroup | undefined);
    const [deleteGroupPayload, setDeleteGroupPayload] = useState(undefined as any | undefined);
    const [deleteFormulaPayload, setDeleteFormulaPayload] = useState(undefined as any | undefined);
    const [containerLevelError, setContainerLevelError] = useState(undefined as string | undefined);
    const [unsavedFormulas, setUnsavedFormulas] = useState(undefined as AllocationGroupFormula[] | undefined);
    const [wasSavingBeforeValidation, setWasSavingBeforeValidation] = useState(false);

    const isTemplate = worksheetMode === WorksheetMode.TEMPLATE;
    const parentEntityId = isTemplate ? worksheetTemplate?.templateId : worksheet?.worksheetId;
    const parentEntityVersion = isTemplate ? worksheetTemplate?.templateVersion : worksheet?.worksheetVersion;

    const [groupsResult, groupsLoading, groupsError] = isTemplate?
        services.tpAllocationGroupsService.getTemplateAllocationGroups(parentEntityId, parentEntityVersion, shouldRefreshGroups) :
        services.tpAllocationGroupsService.getAllocationGroups(shouldRefreshGroups, parentEntityId, parentEntityVersion);
    
    const [saveGroupResult, isSavingGroup, saveGroupError] = isTemplate? 
        services.tpAllocationGroupsService.saveTemplateAllocationGroup(saveGroupPayload):
        services.tpAllocationGroupsService.saveWorksheetAllocationGroup(saveGroupPayload);

    const [deleteGroupResult, isDeletingGroup, deleteGroupError] = isTemplate? 
        services.tpAllocationGroupsService.deleteTemplateAllocationGroup(deleteGroupPayload):
        services.tpAllocationGroupsService.deleteAllocationGroup(deleteGroupPayload);

    const [saveFormulaResult, isSavingFormula, saveFormulaError] = isTemplate?
        services.tpAllocationGroupsService.saveTemplateGroupFormula(parentEntityId || '', updateFormulaPayload):
        services.tpAllocationGroupsService.saveWorksheetGroupFormula(parentEntityId || '', updateFormulaPayload);

    const [deleteFormulaResult, isDeletingFormula, deleteFormulaError] = isTemplate?
        services.tpAllocationGroupsService.deleteTemplateGroupFormula(deleteFormulaPayload):
        services.tpAllocationGroupsService.deleteWorksheetGroupFormula(deleteFormulaPayload);

    useEffect(() => {
        if (saveFormulaResult == null){
            return;
        }
        dispatch(ACTIONS.REFRESH_ALLOCATION_GROUP_FORMULA.withPayload(saveFormulaResult));
        if (unsavedFormulas != null && unsavedFormulas.some(x => x.description === saveFormulaResult.description)){
            unsavedFormulas.shift();
            setUnsavedFormulas(unsavedFormulas);
        }
    }, [saveFormulaResult])

    useEffect(() => {
        tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_GROUP_FORMULAS_ARE_VALID.withPayload(false))
    }, [updateFormulaPayload])

    useEffect(() => {
        if (unsavedFormulas == null){
            return;
        }
        if (unsavedFormulas.length > 0){
            dispatch(ACTIONS.SET_UPDATE_FORMULA_PAYLOAD.withPayload(unsavedFormulas[0]));
        }
        else if (wasSavingBeforeValidation && !isTemplate){
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_WORKSHEET_NEEDS_VALIDATION.withPayload(true))
        }
        else {
            const allFormulas = services.tpAllocationService.flattenGroupFormulas(groups);
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_GROUP_FORMULAS_ARE_VALID.withPayload(allFormulas.every(x => (x.validationErrors||[]).length === 0)))
        }
    }, [unsavedFormulas])

    
    useEffect(() => {
        if (groups == null){
            return;
        }
        tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_ALLOCATION_GROUPS.withPayload(groups));
    }, [groups])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(saveFormulaError)) {
            dispatch(ACTIONS.SET_GROUP_LEVEL_ERROR.withPayload({groupId: updateFormulaPayload?.allocationGroupId, message: saveFormulaError}));
            // If saving fails we reset the list of unsaved items
            setUnsavedFormulas([]);
        }
    }, [saveFormulaError])
    
    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(deleteFormulaError)) {
            dispatch(ACTIONS.SET_GROUP_LEVEL_ERROR.withPayload({groupId: deleteFormulaPayload?.allocationGroupId, message: deleteFormulaError}));            
        }
    }, [deleteFormulaError])

    useEffect(() => {
        if (deleteFormulaResult == null){
            return;
        }
        setDeleteFormulaPayload(null);
        dispatch(ACTIONS.SET_REMOVE_FORMULA_PAYLOAD.withPayload(null))
        dispatch(ACTIONS.SET_GROUP_ID_TO_REFRESH_FORMULAS.withPayload({groupId:deleteFormulaResult.allocationGroupId}));
        dispatch(ACTIONS.SET_GROUP_LEVEL_ERROR.withPayload({groupId: deleteFormulaResult.allocationGroupId, message: null}));  
    }, [deleteFormulaResult])

    

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(deleteGroupError)){
            dispatch(ACTIONS.SET_REMOVE_GROUP_MODAL_VISIBLE.withPayload(false))
            dispatch(ACTIONS.SET_GROUP_LEVEL_ERROR.withPayload({groupId: deleteGroupPayload?.allocationGroupId, message: deleteGroupError}));
        }
    }, [deleteGroupError])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(groupsError)){
            setContainerLevelError(groupsError);
        }
    }, [groupsError])

    useEffect(() => {
        if (saveGroupResult == null){
            return;
        }
        dispatch(ACTIONS.ADD_OR_UPDATE_ALLOCATION_GROUP.withPayload(saveGroupResult));
        setGroupEditorModalVisible(false);
    }, [saveGroupResult])

    useEffect(() => {
        if (deleteGroupResult == null){
            return;
        }
        setDeleteGroupPayload(null);
        dispatch(ACTIONS.SET_REMOVE_GROUP_MODAL_VISIBLE.withPayload(false))
        setShouldRefreshGroups(true);
    }, [deleteGroupResult])

    useEffect(() => {
        dispatch(ACTIONS.SET_GROUP_LEVEL_ERROR.withPayload({groupId: groupBeingEdited?.allocationGroupId, message: null}));
        setGroupEditorModalMessage(undefined);
        setGroupEditorModalVisible(groupBeingEdited != null);
    }, [groupBeingEdited])

    
     useEffect(() => {
        if (StringUtils.isNullOrEmpty(saveGroupError)){
            return;
        }
        setGroupEditorModalMessage(new TemporaryMessage(saveGroupError, "error", true));
    }, [saveGroupError])

    useEffect(() => {
        if (removeGroupModalPayload == null){
            return;
        }
        dispatch(ACTIONS.SET_GROUP_LEVEL_ERROR.withPayload({groupId: removeGroupModalPayload.allocationGroupId, message: null}));
    }, [removeGroupModalPayload])
    

    useEffect(() => {
        if (groupsResult == null){
            return;
        }
        setShouldRefreshGroups(parentEntityId == null);
        dispatch(ACTIONS.SET_ALLOCATION_GROUPS.withPayload(groupsResult));
    }, [groupsResult])

    const addNewGroupClicked = () => {
        dispatch(ACTIONS.SET_GROUP_BEING_EDITED.withPayload({parentEntityId, parentEntityVersion, isNew: true, allocationGroupId: `newGroup${groups.length + 1}`} as AllocationGroup));
    }

    const onDeleteGroupConfirmed = () => {
        dispatch(ACTIONS.SET_GROUP_LEVEL_ERROR.withPayload({groupId: removeGroupModalPayload?.allocationGroupId, message: null}));
        setDeleteGroupPayload({...removeGroupModalPayload, parentEntityId: parentEntityId});
    }

    const saveAndValidate = () => {
        const allFormulas = services.tpAllocationService.flattenGroupFormulas(groups);
        const allUnsavedFormulas = allFormulas.filter(x => x.savingStatus === SavingStatus.Unsaved);
        dispatch(ACTIONS.EXIT_EDIT_MODE);
        dispatch(ACTIONS.CLEAR_ALL_GROUP_LEVEL_ERRORS);
        if (allUnsavedFormulas.length > 0 && !isSavingFormula){
            // Saving unsaved ones before validating!
            setWasSavingBeforeValidation(true);
            dispatch(ACTIONS.SET_UPDATE_FORMULA_PAYLOAD.withPayload(allUnsavedFormulas[0]));
            setUnsavedFormulas(allUnsavedFormulas);
            return;
        }
        if (!isTemplate) {
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_WORKSHEET_NEEDS_VALIDATION.withPayload(true));
        }
        else {
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_IS_VALIDATING.withPayload(false));
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_GROUP_FORMULAS_ARE_VALID.withPayload(allFormulas.every(x => (x.validationErrors||[]).length === 0)))
        }
    }

    return (        
        <ExpandableSection data-class="polarisExpandableSection" 
            variant="container"
            defaultExpanded={expanded}
            headerText={containerTitle}            
        >
            <AllocationGroupsProvider services={services} state={state} dispatch={dispatch} tpAllocationState={tpAllocationState} tpAllocationDispatch={tpAllocationDispatch} totals={worksheetTotals} dataSources={datasetRecords}>
                <div className="wizardBoxContentContainer">
                    <div className="totalsHeader">
                        <TPELoadingSpinner loading={groupsLoading} loadingText="Loading allocation groups">
                            <SpaceBetween direction="vertical" size="m">
                                {groups.map(g => <AllocationGroupContainer group={g} />)}
                                {
                                    viewMode === CONSTANTS.VIEW_MODE.EDITABLE && 
                                    ArrayUtils.isNullOrEmpty(groups) && 
                                    <Box textAlign="center" padding="m">There are no groups yet. Click on Add new group button to start.</Box>
                                }
                                { containerLevelError && <Alert
                                        key={`allGroupsErrorAlert`}
                                        statusIconAriaLabel="Error"
                                        type="error"
                                        header="Allocation Groups Error"
                                    >
                                        {containerLevelError}
                                    </Alert> 
                                }
                            </SpaceBetween>
                        </TPELoadingSpinner>
                    </div>
                    {viewMode === CONSTANTS.VIEW_MODE.EDITABLE && 
                        <div className="actionButtonsContainer">
                            <Box float="left">
                                <Button data-class="subPrimaryButton" onClick={addNewGroupClicked}>Add new group</Button>
                            </Box>
                            <Box float="right">
                                <Button 
                                    data-class={`saveButton ${allocationGroupFormulasAreValid && !isValidating? 'button-valid' : 'button-invalid'}`} 
                                    onClick={saveAndValidate}
                                    iconName={allocationGroupFormulasAreValid? "status-positive" : undefined}>
                                    {unsavedFormulas != null && unsavedFormulas.length > 0 || isValidating? "Saving..." : "Save"}
                                </Button>
                            </Box>
                            <AllocationGroupEditorModal 
                                group={groupBeingEdited || {} as AllocationGroup} 
                                visible={groupEditorModalVisible} 
                                modalMessage={groupEditorModalMessage} 
                                onClearModalMessage={() => setGroupEditorModalMessage(undefined)} 
                                onModalDismissed={() => setGroupEditorModalVisible(false)}
                                onModalSubmitted={(group:AllocationGroup) => setSaveGroupPayload({...group, parentEntityId: parentEntityId || ''})}
                                primaryButtonDisabledLabel={isSavingGroup? "Saving...": undefined}
                                />
                            <TPEBasicModal
                                className="groupDeleteConfirmModal"
                                visible={removeGroupModalVisible}
                                action={ACTION_TYPE.YES_NO}
                                title="Remove Group Confirmation"
                                onCancel={() => dispatch(ACTIONS.SET_REMOVE_GROUP_MODAL_VISIBLE.withPayload(false))}
                                onConfirm={onDeleteGroupConfirmed}
                                primaryButtonDisabledLabel={isDeletingGroup? "Deleting...": undefined}
                            >
                                <span>Are you sure you want to remove group {removeGroupModalPayload?.description}?</span>
                            </TPEBasicModal>
                            <TPEBasicModal
                                className="formulaDeleteConfirmModal"
                                visible={removeFormulaModalPayload != null}
                                action={ACTION_TYPE.YES_NO}
                                title="Remove Formula Confirmation"
                                onCancel={() => dispatch(ACTIONS.SET_REMOVE_FORMULA_PAYLOAD.withPayload(null))}
                                onConfirm={() => {
                                    setDeleteFormulaPayload(removeFormulaModalPayload?.formula)
                                    dispatch(ACTIONS.SET_GROUP_LEVEL_ERROR.withPayload({groupId: removeGroupModalPayload?.allocationGroupId, message: null}));
                                }}
                                primaryButtonDisabledLabel={isDeletingFormula? "Deleting...": undefined}
                            >
                                <span>Are you sure you want to remove formula #{removeFormulaModalPayload?.formula.visibleSequence} {removeFormulaModalPayload?.formula.description} from this group?</span>
                            </TPEBasicModal>
                        </div>
                    }
                    <SimpleUseEffect 
                        useEffectVars={[saveGroupResult, saveGroupError, deleteGroupResult, deleteGroupError, saveFormulaResult, saveFormulaError, deleteFormulaResult, deleteFormulaError]} 
                        action={() => tpAllocationDispatch(TP_ALLOCATION_ACTIONS.CHECK_AND_REFRESH_ENTITY_DETAILS)}
                    />
                </div>
            </AllocationGroupsProvider>
        </ExpandableSection>
    );
}