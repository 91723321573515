import Placeholder from "src/models/tp-allocation/Placeholder";
import CONSTANTS from "src/utils/constants";

const TPAllocationUtils = {
    convertToPlaceholdersMap(placeholders: Placeholder[]): Map<string, Placeholder[]> {
        const result = new Map<string, Placeholder[]>();
        Object.values(CONSTANTS.COA_SEGMENT_MAPPING).forEach(x => {
            const segmentPlaceholders = placeholders.filter(y => y.coaSegment == x.UI)
                                                    .sort((a, b) => (a.sequence != null ? a.sequence : 0) - (b.sequence != null ? b.sequence : 0));
            if (segmentPlaceholders.length > 0) {
                result.set(x.UI, segmentPlaceholders);
            }
        });
        return result;
    }
}

export default TPAllocationUtils;