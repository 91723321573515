import './CustomAppLayout.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { BreadcrumbGroup, Header, Box, Spinner, Badge, StatusIndicator, Flashbar, Link, ColumnLayout, FormField, Input, Select, Icon, Alert, Button } from '@amzn/awsui-components-react/polaris';
import { constructBreadcrumbs, getPageHeaderMapping, navigateTo } from './AppBreadcrumbs';
import { PageHeaderMapping } from '../models/navigation/PageHeaderMapping';
import { UserProfile } from 'src/models/users/UserProfile';
import { GlobalAppContext } from './App';
import CONSTANTS from 'src/utils/constants';
import { ACTION_TYPE, TPEBasicModal } from './shared/TPEBasicModal';
import { GLOBAL_ACTIONS } from 'src/services/global/GlobalReducer';
import { AppModules, AppRoles } from 'src/models/permissions/RolePermissions';
import { getPermissions } from './AppPermissions';
import { getHeaderHelpPanelDetails } from './help-panels/PanelDetails';

const photoUrl = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=';
const imagePath = '/assets/images/';

function TopNav(props: { userProfile: UserProfile, canSimulate: boolean }) {
    const { userProfile, canSimulate } = props;
    const { globalState, globalDispatch } = useContext(GlobalAppContext);
    const { isSimulateMode } = globalState;
    const role = userProfile == null || userProfile.roles == null ? "" : userProfile.roles[0];
    const isLoadingUserProfile = userProfile == null || userProfile.firstName == null;
    const simulateClass = isSimulateMode ? 'simulateTopNav' : '';

    const [showSimulateModal, setShowSimulateModal] = useState(false);
    const [simulateUser, setSimulateUser] = useState('');
    const [simulateRoleOption, setSimulateRoleOption] = useState(null as unknown as any);
    const [simulateUserFirstName, setSimulateUserFirstName] = useState('');
    const [simulateUserLastName, setSimulateUserLastName] = useState('');

    const roleOptions: { label: string, value: string }[] = [];
    Object.values(AppRoles).forEach(r => roleOptions.push({ label: r, value: r }));

    const startSimulation = () => {
        globalDispatch(GLOBAL_ACTIONS.START_SIMULATION.withPayload({
            user: simulateUser,
            roles: [simulateRoleOption.value],
            firstName: simulateUserFirstName,
            lastName: simulateUserLastName
        } as UserProfile));
        setShowSimulateModal(false);
    }

    const endSimulation = () => {
        globalDispatch(GLOBAL_ACTIONS.END_SIMULATION);
    }

    return (
        <header className="topnav">
            <div className="topnav-1">
                <img src={imagePath + 'amazon-atp-logo.png'} />
                {CONSTANTS.ENVIRONMENT_VARIABLES.TPE_UI_STAGE !== "prod" ? <Badge color="blue">{CONSTANTS.ENVIRONMENT_VARIABLES.TPE_UI_STAGE}</Badge> : null}
            </div>
            <div className={`topnav-2 ${simulateClass}`}>
                {canSimulate &&
                    (isSimulateMode ?
                        <React.Fragment>
                            <Icon name="user-profile-active" />
                            <span>Simulate ON - </span>
                            <Link data-class="endSimulateLink" onFollow={endSimulation}>End</Link>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Icon name="user-profile" />
                            <Link data-class="simulateLink" onFollow={() => setShowSimulateModal(true)}>Simulate</Link>
                        </React.Fragment>
                    )
                }
            </div>
            {isLoadingUserProfile ?
                <div className="topnav-3"><Spinner size="normal" /></div> :
                <div className="topnav-3">
                    <div className="account"><img className="profile" src={photoUrl + userProfile.user} /></div>
                    <Box variant="span" color="inherit" margin={{ left: "s", right: "s", }}>{`${userProfile.firstName} ${userProfile.lastName} (${role})`}</Box>
                </div>
            }
            <TPEBasicModal
                title="See what the user sees"
                action={ACTION_TYPE.CONFIRM_CANCEL}
                visible={showSimulateModal}
                onConfirm={startSimulation}
                onCancel={() => setShowSimulateModal(false)}
            >
                <ColumnLayout columns={2}>
                    <FormField label="Alias">
                        <Input onChange={({ detail }) => setSimulateUser(detail.value)} value={simulateUser} />
                    </FormField>
                    <FormField label="Role">
                        <Select
                            selectedOption={simulateRoleOption}
                            onChange={({ detail }) => setSimulateRoleOption(detail.selectedOption)}
                            options={roleOptions}
                        />
                    </FormField>
                    <FormField label="First Name">
                        <Input onChange={({ detail }) => setSimulateUserFirstName(detail.value)} value={simulateUserFirstName} />
                    </FormField>
                    <FormField label="Last Name">
                        <Input onChange={({ detail }) => setSimulateUserLastName(detail.value)} value={simulateUserLastName} />
                    </FormField>
                </ColumnLayout>
                <br />
                <Alert type="info" dismissible={false}><b>Note: </b>This feature is only for viewing the UI permissions for a role. API calls will still use your Cognito identity.</Alert>
            </TPEBasicModal>
        </header>
    );
}

function MenuIcon(props: { src: string, href: string, onClick?: any, className?: string }) {
    const highlightColor = 'orange500';
    const washoutColor = 'grey600';

    const { globalState } = React.useContext(GlobalAppContext);

    const { src, href, onClick, className } = props;
    const [imageColor, setImageColor] = React.useState(washoutColor);
    const [isCurrentPath, setIsCurrentPath] = React.useState(false);
    const [refreshKey, setRefreshKey] = React.useState(0);

    const history = useHistory();
    const { pathname } = useLocation();

    useEffect(() => {
        setIsCurrentPath(false);
        if (pathname == href) {
            setImageColor(highlightColor);
            setIsCurrentPath(true);
        } else {
            const pathParts = pathname.split('/');
            const hrefParts = href.split('/');
            if (pathParts.length > 1 && hrefParts.length > 1 && pathParts[1] == hrefParts[1]) {
                setImageColor(highlightColor);
                setIsCurrentPath(true);
            } else {
                setImageColor(washoutColor);
                setIsCurrentPath(false);
            }
        }
    }, [pathname]);

    const highlightImage = () => {
        setImageColor(highlightColor);
    }

    const washoutImage = () => {
        setImageColor(washoutColor);
    }

    const imageSrc = src + '-' + imageColor + '.svg';

    let onClickFn = onClick;
    if (onClick == undefined) {
        onClickFn = () => setRefreshKey(refreshKey + 1);
    }

    if (isCurrentPath) {
        return (<a className={className} onClick={() => { onClickFn(); navigateTo(history, pathname, href, undefined, globalState, true) }}><img src={imageSrc} /></a>);
    } else {
        return (
            <a className={className} onMouseEnter={highlightImage} onMouseLeave={washoutImage} onClick={() => { onClickFn(); navigateTo(history, pathname, href, undefined, globalState, true) }}>
                <img src={imageSrc} />
            </a>
        );
    }
}

function LeftSideNav(props: { userProfile: UserProfile }) {
    const { userProfile } = props;
    return (
        <aside className="sidenav-left">
            <ul>
                {getPermissions(AppModules.DASHBOARD, userProfile).canView && <li title="TPE Home"><MenuIcon className="homeIcon" href="/" src={imagePath + 'home-custom'} /></li>}
                {getPermissions(AppModules.TP_WORKLISTS, userProfile).canView && <li title="Agreements Worklists"><MenuIcon className="agreementsIcon" href="/agreements" src={imagePath + 'agreement-custom'} /></li>}
                {getPermissions(AppModules.CALCULATION_MANAGER, userProfile).canView && <li title="Calculations"><MenuIcon className="calculationIcon" href="/calculations" src={imagePath + 'calculator-custom'} /></li>}
                {getPermissions(AppModules.REPORTING, userProfile).canView && <li title="Reports"><MenuIcon className="calculationIcon" href="/reports" src={imagePath + 'report-custom'} /></li>}
                {getPermissions(AppModules.MEC, userProfile).canView && <li title="Month End Close"><MenuIcon className="calculationIcon" href="/mec" src={imagePath + 'mec-custom'} /></li>}

            </ul>
        </aside>
    );
}

function RightSideNav() {
    const { pathname } = useLocation();
    const pageHeaderMapping: PageHeaderMapping = getPageHeaderMapping(pathname);
    const { globalState, globalDispatch } = useContext(GlobalAppContext);
    const { showSideBar, pageSidebarKey, selectedTab } = globalState;

    useEffect(() => {
        const page = document.querySelector('.page');
        if (page) {
            if (showSideBar) {
                page.classList.add('sidebar-open');
            } else {
                page.classList.remove('sidebar-open');
            }
        }
    }, [showSideBar]);

    const toggleSidebar = () => {
        const newSidebarState = !showSideBar;
        if (newSidebarState) {
            globalDispatch(GLOBAL_ACTIONS.SHOW_SIDEBAR.withPayload(pageHeaderMapping?.pageSidebarKey))
        } else {
            globalDispatch(GLOBAL_ACTIONS.HIDE_SIDEBAR)
        }
    };

    const closeSidebar = () => {
        globalDispatch(GLOBAL_ACTIONS.HIDE_SIDEBAR)
    };

    const createSupportTicket = () => {
        if (pathname.startsWith(CONSTANTS.PAGE_NAV.REPORT_DASHBOARD.URL) 
            || (pathname.startsWith(CONSTANTS.PAGE_NAV.MONTH_END_CLOSE.URL) 
                && (selectedTab == CONSTANTS.MEC_TABS.PREVALIDATION || selectedTab == CONSTANTS.MEC_TABS.JE_STATUS_DASHBOARD))) {
            window.open(CONSTANTS.ENVIRONMENT_VARIABLES.REPORTING_SUPPORT_TICKET_TEMPLATE);
        } else {
            window.open(CONSTANTS.ENVIRONMENT_VARIABLES.SUPPORT_TICKET_TEMPLATE);
        }
    }


    return (
        <aside className="sidenav-right">
            <ul>
            {pageHeaderMapping.pageSidebarKey != null && 
                <li><React.Fragment>
                    <Button onClick={toggleSidebar} iconName="status-info" variant="icon" />
                    <nav className={`nav-menu ${showSideBar ? 'active' : ''}`}>
                        <div className="help-panel-container">
                            {getHeaderHelpPanelDetails(pageSidebarKey)}
                        </div>
                        <div className="close-btn-container">
                            <Button onClick={closeSidebar} iconName="close" variant='icon' />
                        </div>
                    </nav>
                </React.Fragment></li>
            }
            {CONSTANTS.ENVIRONMENT_VARIABLES.TPE_UI_STAGE == 'prod' && 
                <li><Button iconName="ticket" variant="icon" onClick={createSupportTicket}/></li>
            }
            </ul>
        </aside>
    )
    
}

function PageHeader() {
    const { pathname } = useLocation();
    const pageHeaderMapping: PageHeaderMapping = getPageHeaderMapping(pathname);
    const { globalState, globalDispatch } = React.useContext(GlobalAppContext);
    const { dynamicBreadcrumb, bannerItems } = globalState;
    let history = useHistory();
    const location: any = useLocation();
    const localBreadcrumb = (dynamicBreadcrumb != null && dynamicBreadcrumb.path == location.pathname) ? dynamicBreadcrumb : null;
    const displayBreadcrumbs = constructBreadcrumbs(location.pathname, location.state?.breadcrumbs, dynamicBreadcrumb);
    const displayPageTitle = localBreadcrumb?.pageTitle != null ? localBreadcrumb.pageTitle : pageHeaderMapping.pageTitle;
    const displayPageDesc = localBreadcrumb?.pageDescription != null ? localBreadcrumb.pageDescription : pageHeaderMapping.pageDescription;

    console.table(bannerItems)

    return (
        <div className="page-header">
            <Flashbar className='custom-flashbar' items={bannerItems} />
            <BreadcrumbGroup
                items={displayBreadcrumbs}
                onClick={(event: any) => {
                    event.preventDefault();
                    navigateTo(history, location.pathname, event.detail.href, location.state, globalState);
                }}
            />
            <Header
                variant="h1"
                description={displayPageDesc}
                info={
                    pageHeaderMapping?.pageSidebarKey ? (
                        <Link variant="info" onFollow={() => globalDispatch(GLOBAL_ACTIONS.SHOW_SIDEBAR.withPayload(pageHeaderMapping?.pageSidebarKey))}>info</Link>
                    ) : null
                }
            >
                {displayPageTitle} <sub className="headerSubscript">{localBreadcrumb?.headerSubscript}</sub>
            </Header>
        </div>
    );
}


function PageContent(props: { content: any }) {
    const { content } = props;
    return (
        <div className="content">
            {content}
        </div>
    );
}

export default function CustomAppLayout(props: { children: any, userProfile: UserProfile, isLoading: boolean, isNafnUser: boolean, canSimulate: boolean }) {
    const { children, userProfile, isLoading, isNafnUser, canSimulate } = props;

    const { globalState } = useContext(GlobalAppContext);
    const { isSimulateMode } = globalState;

    const simulateStyle = isSimulateMode ? 'simulateMode' : '';

    return (
        <div className={`page ${simulateStyle}`}>
            <TopNav userProfile={userProfile} canSimulate={canSimulate}></TopNav>
            {isLoading ?
                <React.Fragment>
                    <PageContent content={<Box textAlign="center" className="big-spinner"><StatusIndicator type="loading">Loading page</StatusIndicator></Box>} />
                </React.Fragment>
                :
                (isNafnUser ?
                    <React.Fragment>
                        <PageHeader></PageHeader>
                        <PageContent content={children} />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <LeftSideNav userProfile={userProfile}></LeftSideNav>
                        <PageHeader></PageHeader>
                        <PageContent content={children} />
                        <RightSideNav></RightSideNav>
                    </React.Fragment>
                )
            }
        </div>
    );
}

