import React, { useEffect, useMemo, useState } from 'react';
import { TPAllocationContext } from '../../../services/tp-allocation/TPAllocationContext';
import { Box, Button, ButtonDropdown, ColumnLayout, Icon, Link, Popover, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { WorksheetMode } from '../../../models/tp-allocation/TPAllocationEnums';
import moment from "moment";
import { TPEBasicModal, ACTION_TYPE } from 'src/components/shared/TPEBasicModal';
import CONSTANTS from 'src/utils/constants';
import StringUtils from 'src/utils/stringUtils';
import EntityStatusUtil from 'src/services/common/EntityStatusUtil';
import { Worksheet, WorksheetTemplate } from 'src/models/tp-allocation/WorksheetList';
import TPEAction from 'src/models/common/TPEAction';
import ServiceCollection from 'src/services/ServiceCollection';
import { ExecuteWorksheetWSMessage } from 'src/models/calculation-builder/PullBalanceWebSocketMessage';
import { ACTIONS } from 'src/services/tp-allocation/TPAllocationReducer';
import { getPermissions } from 'src/components/AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

export default function TPAllocationStatusBar(props: { loading: boolean }){
    const { loading } = props;
    const { services, tpAllocationState, tpAllocationDispatch } = React.useContext(TPAllocationContext);
    const { worksheetMode, 
            worksheet, 
            worksheetTemplate, 
            previousWorksheetExecutionStatus, 
            viewMode, 
            datasetRecords, 
            worksheetTotals, 
            allocationGroups} = tpAllocationState;
    const [executionRequirements, setExecutionRequirements] = useState([] as string[]);
    const { canEdit } = getPermissions(AppModules.TP_ALLOCATION);

    const isTemplate = worksheetMode === WorksheetMode.TEMPLATE;
    const entity = isTemplate ? worksheetTemplate : worksheet;
    const [showLinkedWorksheetsModal, setShowLinkedWorksheetsModal] = useState(false);
    const [alertText, setAlertText] = useState(null as unknown as string);

    const [worksheetExecutionVersionResult, worksheetExecutionVersionLoading, worksheetExecutionVersionError] = services.tpAllocationService.getLatestWorksheetExecutionVersion(worksheet?.worksheetId, worksheet?.executionPeriod?.id);

    useEffect(() => {
        const webSocketListener = setupWebSocketEvents(services, tpAllocationDispatch);
        return () => {
            // Anything in here is fired on component unmount.
            tpAllocationDispatch(ACTIONS.CLEAR_ALL_BUILDER_DATA);
            services.atpWebSocketApiService.removeListener('',webSocketListener);            
        }
    }, [])

    useEffect(() => {
        if (worksheetExecutionVersionResult == null){
            return;
        }
        if (worksheetExecutionVersionResult.executionVersion === undefined) {
            services.messageService.showErrorAutoDismissBanner(`Cannot find allocation worksheet execution for ${worksheet?.executionPeriod?.text}.`);
            return;
        }
        tpAllocationDispatch(ACTIONS.SET_WORKSHEET_EXECUTION_VERSION.withPayload(worksheetExecutionVersionResult.executionVersion))
        tpAllocationDispatch(ACTIONS.SET_VIEW_MODE.withPayload(CONSTANTS.VIEW_MODE.FROZEN));
    },[worksheetExecutionVersionResult])
    
    useEffect(() => {
        if (worksheet?.executionStatus == null){
            return;
        }
        resetViewMode();
        // Only trigger refresh if the execution has run
        if (previousWorksheetExecutionStatus != null && worksheet?.executionStatus === CONSTANTS.PROCESSING_STATUS.SUCCEEDED){
            tpAllocationDispatch(ACTIONS.REFRESH_EXECUTION_RESULTS.withPayload(true))
        }
    }, [worksheet?.executionStatus, canEdit])

    useEffect(() => {
        setAlertText(getAlertText() as unknown as string);
    }, [worksheet?.executionStatus, entity?.status, worksheet?.executionPeriod])

    const getExecutionPeriods = () => {
        const executionPeriods: any[] = [];
        executionPeriods.push({ id: CONSTANTS.CLEAR_SELECTION, text: CONSTANTS.CLEAR_SELECTION });
        const moment = extendMoment(Moment);
        const endDate = moment().subtract(1, 'months').date(1);
        Array.from(moment.rangeFromInterval('month', -22, endDate).by('month')).reverse().forEach(d => {
            const displayPeriod = d.format(CONSTANTS.PERIOD_DISPLAY_FORMAT).toUpperCase();
            const formattedValue = d.format(CONSTANTS.PERIOD_VALUE_FORMAT);
            executionPeriods.push({ id: formattedValue, text: displayPeriod });
        });
        return executionPeriods;
    }

    const executionPeriods = useMemo(getExecutionPeriods, []);

    const resetViewMode = () => {
        if (canEdit) {
            const viewModeBasedOnStatus = worksheet?.status === CONSTANTS.CALCULATION_STATUS.PENDING_REVIEW? CONSTANTS.VIEW_MODE.READ_ONLY : CONSTANTS.VIEW_MODE.EDITABLE;
            tpAllocationDispatch(ACTIONS.SET_VIEW_MODE.withPayload(worksheet?.executionStatus === CONSTANTS.PROCESSING_STATUS.IN_PROGRESS? CONSTANTS.VIEW_MODE.FROZEN : viewModeBasedOnStatus))
        } else {
            tpAllocationDispatch(ACTIONS.SET_VIEW_MODE.withPayload(CONSTANTS.VIEW_MODE.FROZEN));
        }
    }

    function onExecuteClicked(): void {
        if (worksheet == null){
            return;
        }

        const executionRequirements = [
            ...services.tpAllocationService.getValidationMessagesForUnsavedItems(datasetRecords, worksheetTotals, allocationGroups),
            ...services.tpAllocationService.getValidationMessagesForMissingItems(datasetRecords, worksheetTotals, allocationGroups)
        ];
        setExecutionRequirements(executionRequirements);
        if (executionRequirements.length > 0){
            return;
        }

        const lastPeriodId = moment(new Date()).subtract(1,'months').endOf('month').format('YYYYMM');
        const { worksheetId, worksheetVersion } = worksheet;
        services.atpWebSocketApiService.executeTPAllocationWorksheet({
            worksheetId,
            worksheetVersion,
            periodId: lastPeriodId
        });
        tpAllocationDispatch(ACTIONS.SET_WORKSHEET_EXECUTION_STATUS.withPayload(CONSTANTS.PROCESSING_STATUS.IN_PROGRESS));
    }

    const getAlertText = () => {
        if (!isTemplate && worksheet?.executionStatus === CONSTANTS.PROCESSING_STATUS.IN_PROGRESS) {
            return 'This worksheet is being executed and cannot be modified.';
        } else {
            return EntityStatusUtil.getAlertText(isTemplate ? 'worksheet template' : 'worksheet', false,
                isTemplate ? undefined : worksheet?.executionPeriod?.text, entity?.status);
        }
    }
    
    return <React.Fragment>
    <Box padding={{ left: "n", right: "l", top:"xl", bottom:"s" }}>
        <div className="entityStatusBar">
            <div className="topMarginDiv entityStatusBarElement">
                <SpaceBetween size="s" direction="horizontal">
                    <strong>{isTemplate ? 'Worksheet template' : 'Worksheet'} status: </strong>
                    <div>
                        <StatusIndicator data-class="statusText" type={EntityStatusUtil.mapToStatusIndicatorBy(entity?.status)}>{entity?.status}</StatusIndicator>
                        {(!StringUtils.isNullOrEmpty(entity?.statusReason) || !StringUtils.isNullOrEmpty(entity?.submitUser)) &&
                            <sub>
                            <Popover
                                dismissButton={false}
                                position="top"
                                triggerType="custom"
                                content={renderInfoContent(entity)}
                                >
                                    <Link data-class="statusReasonInfoLink" variant="info">Info</Link>
                            </Popover>
                            </sub>
                        }
                    </div>
                    {/*entity?.status == CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS && 
                        <Button data-class="iconButton" 
                        onClick={() => {}} iconName="remove" variant="inline-icon" />*/
                    }
                </SpaceBetween>
            </div>
            {isTemplate &&
                <div className="topMarginDiv verticalSeparator entityStatusBarElement">
                    <div>
                        {worksheetTemplate?.linkedWorksheets == null || worksheetTemplate?.linkedWorksheets.length == 0 ?
                            "0 linked worksheet(s)"
                        :
                            <Link onFollow={() => setShowLinkedWorksheetsModal(true)}>{worksheetTemplate?.linkedWorksheets?.length} linked worksheet(s)</Link>
                        }
                    </div>
            </div>
            }
            {worksheetMode === WorksheetMode.LINKED && 
                <div className="topMarginDiv verticalSeparator entityStatusBarElement">
                    <SpaceBetween size="s" direction="horizontal">
                        <strong>Template: </strong>
                        <div>
                            <Link external externalIconAriaLabel='Opens in a new tab' 
                                  href={CONSTANTS.PAGE_NAV.TP_ALLOCATION_TEMPLATE.URL + '/' + window.btoa(worksheet?.templateId || '')}>
                                {worksheet?.templateName || 'Template'}
                            </Link>
                        </div>
                    </SpaceBetween>
                </div>
            }
            {!isTemplate &&
                <React.Fragment>
                    <div className="topMarginDiv verticalSeparator entityStatusBarElement">
                        <SpaceBetween size="s" direction="horizontal">
                            <strong>Last executed:</strong>
                            <div>
                                {worksheet?.lastExecutionTimestamp == null ? 
                                    <span className="notExecutedText">Not executed</span>
                                : 
                                    <React.Fragment>
                                        <span>{`${worksheet?.lastExecutionTimeDisplay} ${worksheet?.lastExecutionUser == null? "" : `by ${worksheet.lastExecutionUser}`} (`}</span>
                                        {renderExecutionStatus(worksheet.executionStatus || '', worksheet.executionStatusReason || '', tpAllocationDispatch)}
                                        <span>)</span>
                                    </React.Fragment>
                                }
                            </div>
                        </SpaceBetween>
                    </div>
                    <div className="verticalSeparator entityStatusBarElement">
                        <Box float="right">
                            <SpaceBetween size="s" direction="horizontal">
                                <ButtonDropdown
                                    disabled={worksheetExecutionVersionLoading}
                                    className="polarisSmallButtonDropdown"
                                    items={executionPeriods}
                                    onItemClick={({ detail }) => {
                                        if (detail.id == CONSTANTS.CLEAR_SELECTION){
                                            tpAllocationDispatch(ACTIONS.SET_WORKSHEET_EXECUTION_PERIOD.withPayload(null));
                                            tpAllocationDispatch(ACTIONS.SET_WORKSHEET_EXECUTION_VERSION.withPayload(null));
                                            resetViewMode();
                                        }
                                        else {
                                            const displayFormat = moment(detail.id, CONSTANTS.PERIOD_VALUE_FORMAT).format(CONSTANTS.PERIOD_DISPLAY_FORMAT).toUpperCase();
                                            tpAllocationDispatch(ACTIONS.SET_WORKSHEET_EXECUTION_PERIOD.withPayload({ id: detail.id, text: displayFormat }));
                                        }
                                    }}
                                >
                                    {worksheetExecutionVersionLoading? `Checking ${worksheet?.executionPeriod?.text} executions...` : (worksheet?.executionPeriod == null ? 'View past MEC execution results' : worksheet?.executionPeriod?.text)}
                                </ButtonDropdown>
                            </SpaceBetween>
                        </Box>
                    </div>
                    { canEdit && <div className="entityStatusBarElement executeWorksheetDiv">
                            <Box float="right" textAlign='center'>
                                <Button
                                    variant="primary"
                                    disabled={viewMode === CONSTANTS.VIEW_MODE.FROZEN}
                                    onClick={onExecuteClicked}
                                    loadingText='Executing...'
                                    loading={worksheet?.executionStatus === CONSTANTS.PROCESSING_STATUS.IN_PROGRESS}
                                >
                                    {worksheet?.executionStatus === CONSTANTS.PROCESSING_STATUS.IN_PROGRESS? "Executing..." : "Execute worksheet"}
                                </Button>
                            </Box>
                        </div>
                    }
                </React.Fragment>
            }
            <TPEBasicModal
                    title="Execution requirements not met"
                    action={ACTION_TYPE.OK_ONLY}
                    visible={executionRequirements.length > 0}
                    onConfirm={() => setExecutionRequirements([])} onCancel={() => setExecutionRequirements([])} >
                The following requirements need to be fulfilled before executing this worksheet:
                <br />
                <ul>
                {executionRequirements.map(x => <li>{x}</li>)}
                </ul>
            </TPEBasicModal>
            <TPEBasicModal
                    className="linkedWorksheetsModal"
                    visible={showLinkedWorksheetsModal}
                    action={ACTION_TYPE.OK_ONLY}
                    title={`${worksheetTemplate?.linkedWorksheets?.length} linked worksheet(s)`}
                    onCancel={() => setShowLinkedWorksheetsModal(false)}
                    onConfirm={() => setShowLinkedWorksheetsModal(false)}
                >
                    <div className="linkedEntitiesContainer">
                        <ColumnLayout columns={2} disableGutters>
                            {worksheetTemplate?.linkedWorksheets?.map(x => <div key={x.worksheetId} className='verticalPadding'>
                                <Link external externalIconAriaLabel="Opens in a new tab"
                                    href={CONSTANTS.PAGE_NAV.TP_ALLOCATION_LINKED.URL + '/' + window.btoa(x.worksheetId)}>
                                        {x.worksheetName}
                                </Link>
                            </div>)}
                        </ColumnLayout>
                    </div>
                </TPEBasicModal>
            {/*<TPEBasicModal
                title="Discard Draft Confirmation"
                action={ACTION_TYPE.CONFIRM_CANCEL}
                visible={showDiscardDraftConfirmationModal}
                onConfirm={() => { setLoadTime(new Date().toDateString()); setShowDiscardDraftConfirmationModal(false); }}
                onCancel={() => setShowDiscardDraftConfirmationModal(false)}
            >
                {calculationVersions != null && calculationVersions.includes(CONSTANTS.CALCULATION_VERSIONS.CURRENT_VERSION) ?
                    'This will discard all changes made to the calculation. '
                :
                    'This will clear the calculation. '
                }
                {calculationTemplateMetaData?.templateId != null && 'Note that any changes saved to the calculation template will not be reverted. '}
                Confirm to proceed.
            </TPEBasicModal>
            <TPEErrorWatcher services={services} errors={[discardDraftError]} />*/}
        </div>
    </Box>
    {canEdit && !StringUtils.isNullOrEmpty(alertText) && 
        <div className="subtleAlert"><StatusIndicator data-class="alertText" type="info">{alertText}</StatusIndicator></div>
    }
</React.Fragment>
}

function renderInfoContent(entity: Worksheet | WorksheetTemplate | undefined) {
    if (entity == undefined) {
        return '';
    }

    const commenter = entity.status == CONSTANTS.CALCULATION_STATUS.PENDING_REVIEW ? 'Submitter' : 'Reviewer';
    return !StringUtils.isNullOrEmpty(entity.submitUser) ?
        <ul>
            {!StringUtils.isNullOrEmpty(entity.submitUser) && 
                <React.Fragment>
                    <li className="statusInfoItem"><b>Submitted by: </b>{entity.submitUser}</li>
                    <li className="statusInfoItem"><b>Submitted on: </b>{entity.submitDateDisplay}</li>
                </React.Fragment>
            }
            {!StringUtils.isNullOrEmpty(entity.reviewUser) && 
                <React.Fragment>
                    <li className="statusInfoItem"><b>Reviewed by: </b>{entity.reviewUser}</li>
                    <li className="statusInfoItem"><b>Reviewed on: </b>{entity.reviewDateDisplay}</li>
                </React.Fragment>
            }
            {!StringUtils.isNullOrEmpty(entity.statusReason) &&
                <li className="statusInfoItem"><b>{commenter} comment: </b>{entity.statusReason}</li>
            }
        </ul>
        :
        <span className="statusInfoItem">{entity.statusReason || ''}</span>
}

function renderExecutionStatus(executionStatus: string, statusReason: string, tpAllocationDispatch: React.Dispatch<TPEAction>) {
    switch (executionStatus) {
        case CONSTANTS.PROCESSING_STATUS.SUCCEEDED:
            return <span className="executionStatusSuccess">{executionStatus}</span>
        case CONSTANTS.PROCESSING_STATUS.FAILED:
            return <span className="executionStatusFailed">
                    {StringUtils.isNullOrEmpty(statusReason)? executionStatus :
                        <Popover
                            data-class="basicPopover"
                            dismissButton={false}
                            position="top"
                            content={statusReason}
                            >
                                <i>{executionStatus}</i>
                        </Popover>
                    }
                    </span>
        case CONSTANTS.PROCESSING_STATUS.IN_PROGRESS:
            return <span className="executionStatusInProgress">{executionStatus} <Button data-class="iconButton" iconName="refresh" variant="inline-icon" onClick={() => tpAllocationDispatch(ACTIONS.SET_ENTITY_DETAILS_NEED_REFRESH.withPayload(true))} /></span>
    }

    return <></>;
}

/**
 * Appends a listener for the execute worksheet (Web Socket) messages. It checks the messages and
 * dispatches actions on the TP Allocation reducer bases on status and execution data.
 * @param services The list of services
 * @param tpAllocationDispatch The TP Allocation dispatcher function
 */
function setupWebSocketEvents(services: ServiceCollection, tpAllocationDispatch: React.Dispatch<TPEAction>) {
    const listener = (x:any) => {
        const webSocketMessage = JSON.parse(x);
        const executeWorksheetMessage = webSocketMessage as ExecuteWorksheetWSMessage;
        if (executeWorksheetMessage.message === "Endpoint request timed out") {
            return;
        }
        tpAllocationDispatch(ACTIONS.SET_WORKSHEET_EXECUTION_STATUS.withPayload(executeWorksheetMessage.status));
        if (executeWorksheetMessage.status === CONSTANTS.PROCESSING_STATUS.FAILED) {
            services.messageService.showErrorBanner(executeWorksheetMessage.message);
        }
    };
    services.atpWebSocketApiService.addListener(listener);
    return listener;
}