import moment from "moment";
import TPESearchRequest, { DbConjunctor, DbOperator, DbSortOrder } from "src/models/common/TPESearchRequest";
import ReactTableFilter from "src/models/common/ReactTableFilter";
import CustomDataTableRowsSearchRequest from "src/models/custom-data-tables/CustomDataTableRowsSearchRequest";
import CONSTANTS from "src/utils/constants";

export function getCustomDataTablesSearchRequest(activeOnly: boolean = false): TPESearchRequest {
    const searchRequest: TPESearchRequest = {
        page: 1,
        pageSize: 20
    }

    if (activeOnly) {
        searchRequest.filterExpression = {
            conjunctor: DbConjunctor.AND,
                expressions: [
                    {
                        attributeName: CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.ACTIVE.SEARCH_ATTRIBUTE,
                        operator: DbOperator.EQUALS,
                        values: [CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ACTIVE]
                    }
                ]
        }
    }

    return searchRequest;
}

export function getCustomDataTableRowsSearchRequest(tableId: string, activeOnly: boolean = false): CustomDataTableRowsSearchRequest {
    const searchRequest: CustomDataTableRowsSearchRequest = {
        tableId,
        page: 1,
        pageSize: 20
    }

    if (activeOnly) {
        searchRequest.filterExpression = {
            conjunctor: DbConjunctor.AND,
                expressions: [
                    {
                        attributeName: CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.ACTIVE.SEARCH_ATTRIBUTE,
                        operator: DbOperator.EQUALS,
                        values: [CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ACTIVE]
                    }
                ]
        }
    }

    return searchRequest;
}

export function setResultPageNumber(searchRequest: TPESearchRequest | CustomDataTableRowsSearchRequest, pageNumber: number) : TPESearchRequest | CustomDataTableRowsSearchRequest{
    return {...searchRequest, page: pageNumber};
}

export function addCalculationNumberFilter(searchRequest: TPESearchRequest | CustomDataTableRowsSearchRequest, calculationNumber: string) {
    checkAndAddFilterExpression(searchRequest);
    searchRequest.filterExpression?.expressions.push({
        attributeName: CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CALCULATION_NUMBER.SEARCH_ATTRIBUTE,
        operator: DbOperator.EQUALS,
        values: [calculationNumber]
    });
}

export function addTableNameFilter(searchRequest: TPESearchRequest | CustomDataTableRowsSearchRequest, tableName: string) {
    checkAndAddFilterExpression(searchRequest);
    searchRequest.filterExpression?.expressions.push({
        attributeName: CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.TABLE_NAME.SEARCH_ATTRIBUTE,
        operator: DbOperator.CONTAINS,
        values: [tableName]
    });
}

export function addPeriodFilter(searchRequest: CustomDataTableRowsSearchRequest, periodFormatted: string) {
    checkAndAddFilterExpression(searchRequest);
    if (isNaN(parseInt(periodFormatted))){
        searchRequest.filterExpression?.expressions.push({
            attributeName: CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.PERIOD.SEARCH_ATTRIBUTE,
            operator: DbOperator.EQUALS,
            values: [moment(periodFormatted, 'MMM-YYYY').format('YYYYMM')]
        });
    }
    else {
        searchRequest.filterExpression?.expressions.push({
            attributeName: CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.PERIOD.SEARCH_ATTRIBUTE,
            operator: DbOperator.CONTAINS,
            values: [periodFormatted]
        });
    }
}

export function setSortByPeriod(searchRequest: TPESearchRequest | CustomDataTableRowsSearchRequest, descending: boolean = false) {
    setSort(searchRequest, CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.PERIOD.SEARCH_ATTRIBUTE, descending);
}

export function addSorting(searchRequest: TPESearchRequest | CustomDataTableRowsSearchRequest | undefined, sortField: string, descending: boolean){
    if (searchRequest == null){
        return;
    }
    setSort(searchRequest,sortField, descending);
}

export function addCDTRowsExpressionFilters(searchRequest: TPESearchRequest | CustomDataTableRowsSearchRequest, reactTableFilters: Array<ReactTableFilter>){
    checkAndAddFilterExpression(searchRequest);
    reactTableFilters.forEach(filter => {
        if (filter.id === CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.PERIOD.ACCESSOR){
            addPeriodFilter(searchRequest, filter.value);
        }
        else {
            const searchFilter = formatFilterValueBy(filter);
            searchRequest.filterExpression?.expressions.push({
                attributeName: searchFilter.id,
                operator: DbOperator.CONTAINS,
                values: [searchFilter.value]
            })
        }
    });
}

export function getDefaultSearchPayload(): TPESearchRequest {
    return {
        page: 1,
        pageSize: 20,
    };
}

function setSort(searchRequest: TPESearchRequest | CustomDataTableRowsSearchRequest, sortField: string, descending: boolean) {
    searchRequest.sortField = sortField;
    searchRequest.sortOrder = descending ? DbSortOrder.DESCENDING : DbSortOrder.ASCENDING;
}

function checkAndAddFilterExpression(searchRequest: TPESearchRequest | CustomDataTableRowsSearchRequest) {
    if (searchRequest.filterExpression == null) {
        searchRequest.filterExpression = {
            conjunctor: DbConjunctor.AND,
            expressions: []
        }
    }
}

function formatFilterValueBy(filter: ReactTableFilter):ReactTableFilter {
    const cdtFields = CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS;
    const stringFilterValue = filter.value as string;
    switch(filter.id){
        case cdtFields.CALCULATION_NUMBER.ACCESSOR:
            return {id: cdtFields.CALCULATION_NUMBER.SEARCH_ATTRIBUTE, value: filter.value}
        case cdtFields.CURRENCY.ACCESSOR:
            return {id: cdtFields.CURRENCY.SEARCH_ATTRIBUTE, value: stringFilterValue.toUpperCase()}
        case cdtFields.CLASSIFICATION.ACCESSOR:
            return {id: cdtFields.CLASSIFICATION.SEARCH_ATTRIBUTE, value: stringFilterValue.toLowerCase()}
        case cdtFields.UPDATED_BY.ACCESSOR:
            return {id: cdtFields.UPDATED_BY.ACCESSOR, value: stringFilterValue.toLowerCase()}
        case cdtFields.CADENCE.ACCESSOR:
            return {id: cdtFields.CADENCE.SEARCH_ATTRIBUTE, value: stringFilterValue.substring(0,1).toUpperCase().concat(stringFilterValue.substring(1))}
        case cdtFields.ACTIVE.ACCESSOR:
            return {id: cdtFields.ACTIVE.SEARCH_ATTRIBUTE, value: stringFilterValue.toUpperCase() === "Y"? "Active" : (stringFilterValue.toUpperCase() === "N"? "Inactive": "Invalid")}
            
    }
    throw new Error(`Unsupported filter type: ${filter.id}`);
}


