import TPEAction from "src/models/common/TPEAction";
import { Reversal } from "src/models/mec/status/Reversal";
import { MECStatusState } from "src/services/mec/status/MECStatusState";
import CONSTANTS from "src/utils/constants";

export const ACTIONS = Object.freeze({
    SET_GROUP: new TPEAction('SET_GROUP'),
    SET_STATUS_RESULT: new TPEAction('SET_STATUS_RESULT'),
    SET_EXECUTION_STATUS_SEARCH_RESULT: new TPEAction('SET_EXECUTION_STATUS_SEARCH_RESULT'),
    SET_CLI_DETAILS_RESULT: new TPEAction('SET_CLI_DETAILS_RESULT'),
    TOGGLE_ROW_VISIBILITY: new TPEAction('TOGGLE_ROW_VISIBILITY'),
    TOGGLE_ROW_SELECTED: new TPEAction('TOGGLE_ROW_SELECTED'),
    SET_REVERSALS: new TPEAction('SET_REVERSALS'),
    SET_REVERSAL_SEARCH_TYPE: new TPEAction('SET_REVERSAL_SEARCH_TYPE'),
    SET_REVERSAL_SEARCH_TEXT: new TPEAction('SET_REVERSAL_SEARCH_TEXT'),
    ADD_REVERSAL_SEARCH_ITEM: new TPEAction('ADD_REVERSAL_SEARCH_ITEM'),
});

export function mecStatusReducer(state: MECStatusState, action: TPEAction) : MECStatusState {
    switch(action.type) {
        case ACTIONS.SET_GROUP.type:
            return {
                ...state,
                group: action.payload,
            };
        case ACTIONS.SET_STATUS_RESULT.type:
            return {
                ...state,
                statusResult: action.payload
            };
        case ACTIONS.SET_CLI_DETAILS_RESULT.type:
            return {
                ...state,
                cliDetailsResult: action.payload
            };
        case ACTIONS.TOGGLE_ROW_VISIBILITY.type: {
            const statusResultCopy = {...state.statusResult};
            statusResultCopy.records[action.payload].visible = !statusResultCopy.records[action.payload].visible;
            return {
                ...state,
                statusResult: statusResultCopy
            };
        }
        case ACTIONS.TOGGLE_ROW_SELECTED.type: {
            const statusResultCopy = {...state.statusResult};
            statusResultCopy.records[action.payload].isSelected = !statusResultCopy.records[action.payload].isSelected;
            return {
                ...state,
                statusResult: statusResultCopy
            }
        }
        case ACTIONS.SET_REVERSALS.type: {
            const reversals = action.payload as Reversal[];
            if (state.reversalSearchItems != null && state.reversalSearchItems.length > 0) {
                const reversalSearchItemsCopy: Reversal[] = [];
                state.reversalSearchItems.forEach(r => {
                    if (r.type == CONSTANTS.REVERSAL_ITEM_TYPES.WORKBOOK 
                        && reversals.filter(rev => rev.type == CONSTANTS.REVERSAL_ITEM_TYPES.WORKBOOK && rev.workbookId == r.workbookId).length == 0) {
                        reversalSearchItemsCopy.push(r);
                    } else if (r.type == CONSTANTS.REVERSAL_ITEM_TYPES.CLI 
                        && reversals.filter(rev => rev.type == CONSTANTS.REVERSAL_ITEM_TYPES.CLI && rev.calculationNumber == r.calculationNumber).length == 0) {
                        reversalSearchItemsCopy.push(r);
                    } else if (r.type == CONSTANTS.REVERSAL_ITEM_TYPES.GROUP) {
                        reversalSearchItemsCopy.push(r);
                    }
                });

                return {
                    ...state,
                    reversals: reversals,
                    reversalSearchItems: reversalSearchItemsCopy
                }
            }
            return {
                ...state,
                reversals: reversals
            }
        }
        case ACTIONS.SET_REVERSAL_SEARCH_TYPE.type: {
            return {
                ...state,
                reversalSearchType: action.payload
            }
        }
        case ACTIONS.SET_REVERSAL_SEARCH_TEXT.type: {
            return {
                ...state,
                reversalSearchText: action.payload
            }
        }
        case ACTIONS.ADD_REVERSAL_SEARCH_ITEM.type: {
            const reversalSearchItems = state.reversalSearchItems != null ? [...state.reversalSearchItems] : [];
            reversalSearchItems.push(action.payload);
            return {
                ...state,
                reversalSearchItems
            }
        }
        case ACTIONS.SET_EXECUTION_STATUS_SEARCH_RESULT.type: {
            return {
                ...state,
                executionStatusSearchResult: action.payload
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}