import { WorksheetMode } from 'src/models/tp-allocation/TPAllocationEnums';
import TPEAction from '../../models/common/TPEAction';
import { TPAllocationState, getLinkedWorksheetInitialState, getStandaloneInitialState, getTemplateInitialState } from './TPAllocationState';
import CONSTANTS from 'src/utils/constants';
import { WorksheetValidationError } from 'src/models/tp-allocation/ValidateWorksheetResponse';

/**
 * List here the actions supported by this reducer
 */
export const ACTIONS = Object.freeze({
    RESET_STANDALONE_STATE: new TPEAction('RESET_STANDALONE_STATE'),
    RESET_LINKED_STATE: new TPEAction('RESET_LINKED_STATE'),
    RESET_TEMPLATE_STATE: new TPEAction('RESET_TEMPLATE_STATE'),
    SET_ERROR: new TPEAction('SET_ERROR'),
    SET_WIZARD_STEPS: new TPEAction('SET_WIZARD_STEPS'),
    SET_SELECTED_WIZARD_STEP: new TPEAction('SET_SELECTED_WIZARD_STEP'),
    SELECT_NEXT_WIZARD_STEP: new TPEAction('SELECT_NEXT_WIZARD_STEP'),
    SET_WORKSHEET: new TPEAction('SET_WORKSHEET'),
    SET_WORKSHEET_TEMPLATE: new TPEAction('SET_WORKSHEET_TEMPLATE'),
    ENABLE_ALL_WIZARD_STEPS: new TPEAction('ENABLE_ALL_WIZARD_STEPS'),
    SET_PLACEHOLDERS: new TPEAction('SET_PLACEHOLDERS'),    
    SET_DATASET_RECORDS: new TPEAction('SET_DATASET_RECORDS'),
    SET_WORKSHEET_TOTALS: new TPEAction('SET_WORKSHEET_TOTALS'),    
    SET_ALLOCATION_GROUPS: new TPEAction('SET_ALLOCATION_GROUPS'),
    SET_BUILDER_STATE: new TPEAction('SET_BUILDER_STATE'),
    SET_SEVEN_SEGMENTS_LOV_MAP: new TPEAction('SET_SEVEN_SEGMENTS_LOV_MAP'),    
    SET_REFRESH_TOTALS_BUILDER_STEPS: new TPEAction('SET_REFRESH_TOTALS_BUILDER_STEPS'),
    SET_ENTITY_DETAILS_NEED_REFRESH: new TPEAction('SET_ENTITY_DETAILS_NEED_REFRESH'),
    CHECK_AND_REFRESH_ENTITY_DETAILS: new TPEAction('CHECK_AND_REFRESH_ENTITY_DETAILS'),
    REFRESH_EXECUTION_RESULTS: new TPEAction('REFRESH_EXECUTION_RESULTS'),
    SET_WORKSHEET_EXECUTION_STATUS: new TPEAction('SET_WORKSHEET_EXECUTION_STATUS'),
    SET_VIEW_MODE: new TPEAction('SET_VIEW_MODE'),
    SET_TOTALS_ARE_VALID: new TPEAction('SET_TOTALS_ARE_VALID'),
    SET_GROUP_FORMULAS_ARE_VALID: new TPEAction('SET_GROUP_FORMULAS_ARE_VALID'),
    SET_IS_VALIDATING: new TPEAction('SET_IS_VALIDATING'),
    SET_WORKSHEET_NEEDS_VALIDATION: new TPEAction('SET_WORKSHEET_NEEDS_VALIDATION'),
    CLEAR_ALL_BUILDER_DATA: new TPEAction('CLEAR_ALL_BUILDER_DATA'),
    SET_VALIDATION_ERRORS: new TPEAction('SET_VALIDATION_ERRORS'),
    SET_WORKSHEET_EXECUTION_PERIOD: new TPEAction('SET_WORKSHEET_EXECUTION_PERIOD'),
    SET_WORKSHEET_EXECUTION_VERSION: new TPEAction('SET_WORKSHEET_EXECUTION_VERSION')
});

/**  
* This function is responsible for updating the state based on action type
* @param state The current dashboard state
* @param action The current dispatched action 
*/
export function tpAllocationReducer(state: TPAllocationState, action: TPEAction) : TPAllocationState {
    switch (action.type) {
        case ACTIONS.RESET_STANDALONE_STATE.type:
            return getStandaloneInitialState();
        case ACTIONS.RESET_LINKED_STATE.type:
            return getLinkedWorksheetInitialState();
        case ACTIONS.RESET_TEMPLATE_STATE.type:
            return getTemplateInitialState();
        case ACTIONS.SET_ERROR.type:
            return {
                ...state,
                error: action.payload,
                isLoading: false,                
            };
        case ACTIONS.SET_WIZARD_STEPS.type:
            return {
                ...state,
                error: undefined,
                wizardSteps: action.payload
            };
        case ACTIONS.SET_PLACEHOLDERS.type:
            return {
                ...state,
                error: undefined,
                placeholdersMap: action.payload
            }
        case ACTIONS.SET_DATASET_RECORDS.type:
            return {
                ...state,
                error: undefined,
                datasetRecords: action.payload,
                dataSourcesNeedRefresh: false,
            };
        case ACTIONS.SET_WORKSHEET_TOTALS.type:
            return {
                ...state,
                error: undefined,
                worksheetTotals: action.payload,
                totalsNeedRefresh: false
            };
        case ACTIONS.SET_ALLOCATION_GROUPS.type:
            return {
                ...state,
                error: undefined,
                allocationGroups: action.payload,
                allocationGroupsNeedRefresh: false
            };
        case ACTIONS.SET_SELECTED_WIZARD_STEP.type:
            if (state.selectedWizardStep) {
                state.selectedWizardStep.isSelected = false;
            }
            if (action.payload) {
                action.payload.isSelected = true;
                action.payload.isEnabled = true;
            }
            for (var i = 0; i < state.wizardSteps.indexOf(action.payload); i++) {
                state.wizardSteps[i].isEnabled = true;
            }
            return {
                ...state,
                error: undefined,
                isLoading: false,
                selectedWizardStep: action.payload
            };
        case ACTIONS.SELECT_NEXT_WIZARD_STEP.type: {
            const {selectedWizardStep, wizardSteps } = state;
            const nextWizardStep = wizardSteps.find(x => x.number === (selectedWizardStep?.number || 0) + 1);
            if (nextWizardStep == null){
                return state;
            }
            if (selectedWizardStep != null){
                selectedWizardStep.isSelected = false;
            }
            nextWizardStep.isEnabled = nextWizardStep.isSelected = true;
            return {
                ...state,
                selectedWizardStep: nextWizardStep
            };
        }
        case ACTIONS.SET_WORKSHEET.type:
            return {
                ...state,
                worksheet: action.payload,
                entityDetailsNeedRefresh: false,
            }
        case ACTIONS.SET_WORKSHEET_TEMPLATE.type:
            return {
                ...state,
                worksheetTemplate: action.payload,
                entityDetailsNeedRefresh: false,
            }
        case ACTIONS.SET_BUILDER_STATE.type:
            return {
                ...state,
                builderState: action.payload
            }
        case ACTIONS.ENABLE_ALL_WIZARD_STEPS.type: {
            const {wizardSteps} = state;
            wizardSteps.forEach(s => {
                s.isEnabled = true;
                s.isSelected = true;
            })
            return {
                ...state,
            }
        }
        case ACTIONS.SET_SEVEN_SEGMENTS_LOV_MAP.type: {
            return {
                ...state,
                sevenSegmentLOVMap: new Map(action.payload),
            }
        }
        case ACTIONS.SET_REFRESH_TOTALS_BUILDER_STEPS.type: {
            return {
                ...state,
                totalsNeedRefresh: action.payload,
                allocationGroupsNeedRefresh: action.payload,
            }
        }
        case ACTIONS.SET_ENTITY_DETAILS_NEED_REFRESH.type: {
            return {
                ...state,
                entityDetailsNeedRefresh: action.payload,
            }
        }
        case ACTIONS.CHECK_AND_REFRESH_ENTITY_DETAILS.type: {
            const entityStatus = state.worksheetMode == WorksheetMode.TEMPLATE ? state.worksheetTemplate?.status : state.worksheet?.status;
            if (entityStatus == CONSTANTS.CALCULATION_STATUS.ACTIVE) {
                return {
                    ...state,
                    entityDetailsNeedRefresh: true,
                }
            } else {
                return state;
            }
        }
        case ACTIONS.REFRESH_EXECUTION_RESULTS.type: {
            return {
                ...state,
                totalsNeedRefresh: action.payload,
                allocationGroupsNeedRefresh: action.payload,
                entityDetailsNeedRefresh: action.payload
            }
        }
        case ACTIONS.SET_WORKSHEET_EXECUTION_STATUS.type: {
            const { worksheet } = state;
            return {
                ...state,
                previousWorksheetExecutionStatus: worksheet?.executionStatus,
                worksheet: worksheet == null? undefined : {...worksheet, executionStatus: action.payload},
            }
        }
        case ACTIONS.SET_VIEW_MODE.type: {
            return {
                ...state,
                viewMode: action.payload,
            }
        }
        case ACTIONS.SET_TOTALS_ARE_VALID.type: {
            return {
                ...state,
                totalsAreValid: action.payload,
                totalValidationErrors: action.payload? [] : state.totalValidationErrors
            }
        }
        case ACTIONS.SET_GROUP_FORMULAS_ARE_VALID.type: {
            return {
                ...state,
                allocationGroupFormulasAreValid: action.payload,
                allocationFormulaValidationErrors: action.payload? [] : state.allocationFormulaValidationErrors
            }
        }
        case ACTIONS.SET_IS_VALIDATING.type: {
            return {
                ...state,
                isValidating: action.payload,
            }
        }
        case ACTIONS.SET_WORKSHEET_NEEDS_VALIDATION.type: {
            return {
                ...state,
                worksheetNeedsValidation: action.payload,
            }
        }
        case ACTIONS.SET_VALIDATION_ERRORS.type: {
            const allErrors = action.payload as WorksheetValidationError[];
            return {
                ...state,
                totalValidationErrors: allErrors.filter(x => x.worksheetEntityType === CONSTANTS.WORKSHEET_ENTITY_TYPES.TOTAL),
                allocationFormulaValidationErrors: allErrors.filter(x => x.worksheetEntityType === CONSTANTS.WORKSHEET_ENTITY_TYPES.ALLOCATION_FORMULA),                
            }
        }
        case ACTIONS.CLEAR_ALL_BUILDER_DATA.type: {
            return {
                ...state,
                worksheetTotals: [],
                datasetRecords: [],
                allocationGroups: [],
                totalValidationErrors: [],
                allocationFormulaValidationErrors: [],
                placeholdersMap: new Map()
            }
        }
        case ACTIONS.SET_WORKSHEET_EXECUTION_PERIOD.type: {
            const { worksheet } = state;
            return {
                ...state,
                worksheet: worksheet == null? undefined : {...worksheet, executionPeriod: action.payload},
            }
        }
        case ACTIONS.SET_WORKSHEET_EXECUTION_VERSION.type: {
            const { worksheet } = state;
            let version, originalVersion;
            
            if (action.payload != null) {
                // Back up the original worksheet version when viewing an MEC execution version
                // The else part is needed when already an execution is being viewed, and another execution is selected
                // In this case, the first backed up version should not be altered
                if (state.originalWorksheetVersion == null) {
                    originalVersion = worksheet?.worksheetVersion;
                    version = action.payload;
                } else {
                    originalVersion = state.originalWorksheetVersion;
                    version = action.payload;
                }
                
            } else {
                // This triggers when the view is reset to default from MEC results view
                version = state.originalWorksheetVersion;
                originalVersion = undefined;
            }
            return {
                ...state,
                worksheet: worksheet == null? undefined : {...worksheet, worksheetVersion: version},
                originalWorksheetVersion: originalVersion,
                totalsNeedRefresh: true,
                dataSourcesNeedRefresh: true,
                allocationGroupsNeedRefresh: true
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}