import React, { useEffect, useState } from 'react';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import { PullBalancePayload, PullBalanceRequestTypes, PullCDTValueRequest, PullGLBalanceRequest } from 'src/models/calculation-builder/PullBalanceRequest';
import { ACTIONS } from 'src/services/calculation-builder/DataSourcesReducer';
import CONSTANTS from 'src/utils/constants';
import { DatasetRecord } from 'src/models/tp-allocation/DatasetRecord';
import TPEAction from 'src/models/common/TPEAction';
import CommonDataSourceOperations from 'src/services/common/CommonDataSourceOperations';

export default function BalancePuller(props: {
        records: DataSourceRecord[] | DatasetRecord[],
        state: {
            balancePullingStateChangeTimestamp?: string, 
            updateAllBalances: boolean, 
            customCoaReference?: Map<string, string>, 
            recordBeingEdited?: any, 
            pullGLBalancesWithBreakdowns?: boolean,
            balancePullUpdatedRecord?: DataSourceRecord | DatasetRecord
        },
        sevenSegmentLOV: Map<string, string[]>,
        dispatch: React.Dispatch<TPEAction>,
        onRecordNeedsSaving: (record:any) => void;
    }) {
    const { records, state, dispatch, sevenSegmentLOV, onRecordNeedsSaving} = props;
    const { 
        balancePullingStateChangeTimestamp, 
        balancePullUpdatedRecord, 
        updateAllBalances, 
        customCoaReference = new Map<string, string>(), 
        recordBeingEdited, 
        pullGLBalancesWithBreakdowns 
    } = state;
    const [record, setRecord] = useState({} as DataSourceRecord | DatasetRecord);

    const {
        datasourceId,
        dataKeyInput = { selectedCompanies: '', selectedAccounts: '', selectedCOA: new Map<string, string>() },
    } = record;

    const { selectedCompanies, selectedAccounts, selectedCOA } = dataKeyInput;

    useEffect(() => {
        if ( balancePullingStateChangeTimestamp == undefined){
            return;
        }
        if (records.length > 0){
            const balancePullingRecord = (records as any[]).find(x => x.requestBalancePull);
            setRecord(updateAllBalances && balancePullingRecord.datasourceId == null? records[0] : balancePullingRecord || {} as DataSourceRecord | DatasetRecord);
        }
    },[balancePullingStateChangeTimestamp])

    
    useEffect(() => {
        if (record.requestBalancePull && !record.balancePullInProgress) {
            dispatch(ACTIONS.REMOVE_UNUSED_DATA_KEY_COA_INPUTS);
            if (record.datasource === CONSTANTS.DATA_SOURCE_TYPES.GENERAL_LEDGER){
                // Validate inputs
                const errorMap = CommonDataSourceOperations.validateInputsForPullBalance(recordBeingEdited, false);
                CommonDataSourceOperations.validateCOAInput(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI, selectedCompanies, sevenSegmentLOV.get(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI) || [], customCoaReference, errorMap);
                CommonDataSourceOperations.validateCOAInput(CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI, selectedAccounts, sevenSegmentLOV.get(CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI) || [], customCoaReference, errorMap);
                selectedCOA.forEach((value, key) => CommonDataSourceOperations.validateCOAInput(key, value, sevenSegmentLOV.get(key) || [], customCoaReference, errorMap));

                if (errorMap.size === 0) {
                    dispatch(ACTIONS.CLEAR_ERRORS.withPayload({ recordID: datasourceId }));
                    const pullBalanceRequest: PullGLBalanceRequest = CommonDataSourceOperations.convertToPullBalanceRequest(record, customCoaReference, pullGLBalancesWithBreakdowns);
                    dispatch(ACTIONS.ADD_PULL_BALANCE_PAYLOAD.withPayload(new PullBalancePayload(PullBalanceRequestTypes.PullGLBalance, pullBalanceRequest)));
                    dispatch(ACTIONS.SET_BALANCE_PULL_IN_PROGRESS.withPayload({ recordID: datasourceId, value: true }));
                } else {
                    dispatch(ACTIONS.SET_ERRORS.withPayload({ recordID: datasourceId, value: errorMap } ));
                    dispatch(ACTIONS.REQUEST_BALANCE_PULL.withPayload({ recordID: datasourceId, value: false }));
                }
            }
            else if (record.datasource === CONSTANTS.DATA_SOURCE_TYPES.CUSTOM_DATA_TABLE){
                // Note: This cast is safe since currently only DataSource records allow CDT as DS type
                const dataSourceRecord = record as DataSourceRecord || {};
                const errorMap = CommonDataSourceOperations.validateInputsForPullCDTValue(state.recordBeingEdited);
                if (errorMap.size === 0) {
                    dispatch(ACTIONS.CLEAR_ERRORS.withPayload({ recordID: datasourceId }));
                    const pullCDTValueRequest: PullCDTValueRequest = {
                        dataSourceId: dataSourceRecord.datasourceId, 
                        cdtCalculationNumber: dataSourceRecord.customDatasourceCalculationNumber,
                        cdtPeriodId: dataSourceRecord.customPeriod,
                        cdtTableId: dataSourceRecord.customDatasourceTableId,
                        cdtValueId: dataSourceRecord.customDatasourceValueId
                    };
                    dispatch(ACTIONS.ADD_PULL_BALANCE_PAYLOAD.withPayload(new PullBalancePayload(PullBalanceRequestTypes.PullCDTValue, pullCDTValueRequest)));
                    dispatch(ACTIONS.SET_BALANCE_PULL_IN_PROGRESS.withPayload({ recordID: datasourceId, value: true }));
                } else {
                    dispatch(ACTIONS.SET_ERRORS.withPayload({ recordID: datasourceId, value: errorMap } ));
                    dispatch(ACTIONS.REQUEST_BALANCE_PULL.withPayload({ recordID: datasourceId, value: false }));
                }
            }
        }
        if (updateAllBalances) {
            dispatch(ACTIONS.PROCESS_NEXT_BALANCE_PULL_REQUEST.withPayload({ recordID: datasourceId }));
        }
    }, [record.requestBalancePull, record.balancePullInProgress])

    useEffect(() => {
        if (!balancePullUpdatedRecord) {
            return;
        }
        onRecordNeedsSaving(balancePullUpdatedRecord);
    }, [balancePullUpdatedRecord])

    

    return null;
}