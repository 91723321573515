import TPEAction from "src/models/common/TPEAction";
import { MECState } from "src/services/mec/MECState";

export const ACTIONS = Object.freeze({
    SET_SELECTED_TAB: new TPEAction('SET_SELECTED_TAB')
});

export function mecReducer(state: MECState, action: TPEAction) : MECState {
    switch(action.type) {
        case ACTIONS.SET_SELECTED_TAB.type:
            return {
                ...state,
                selectedTab: action.payload,
            };
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}