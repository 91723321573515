import { ExecutionGroupDetails } from "src/models/calculation-builder/ExecutonGroupDetails"
import { Journal } from "src/models/calculation-builder/Journal"

export interface ReviewJournalsState {
    reviewJournals: Journal[],
    executionJournals: Journal[],
    approvalStatus?: any,
    executionGroupDetails?: ExecutionGroupDetails
}

export const initialState: ReviewJournalsState  = {
    reviewJournals: [],
    executionJournals: [],    
}
