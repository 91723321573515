import React, { useEffect, useState } from 'react';
import { Box, Button, Container, FormField, Grid, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { CalculationBuilderContext } from './CalculationBuilderView';
import { ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import 'src/assets/styles/polaris-container.scss';
import StringUtils from 'src/utils/stringUtils';
import TPENavigator from '../TPENavigator';
import CONSTANTS from 'src/utils/constants';

export default function CalculationBuilderSelectICRSRecord(props: { isCreating: boolean }) {
    const { isCreating } = props;
    const { calcBuilderDispatch, services, calcBuilderState } = React.useContext(CalculationBuilderContext);
    const { calculation } = calcBuilderState;
    const [selectedRecord, setSelectedRecord] = useState(undefined as string | undefined);
    const [searchICRSRequest, setSearchICRSRequest] = useState({} as any);
    const [navigateURL, setNavigateURL] = useState('');

    const [icrsRecordsResult, icrsRecordsLoading, icrsRecordsError] = services.icrsService.searchICRS(searchICRSRequest);

    useEffect(() => {
        setSearchICRSRequest({
            page: 1,
            pageSize: 20,
            searchStartedDate: new Date(),
            filterExpression: null
        });
    }, []);
    
    
    useEffect(() => {
        if (calculation == null){
            return;
        }
        setSelectedRecord(calculation.calculationNumber);
    }, [calculation])

    const dispatchCreateCalculation = function () {
        calcBuilderDispatch(ACTIONS.SET_NEW_CALCULATION_NUMBER.withPayload(selectedRecord));        
    };

    const createButtonIsEnabled = !StringUtils.isNullOrEmpty(selectedRecord);

    return (
        <Container className="polaris-content-container">
            <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
                <FormField
                    label="Select ICRS transaction"
                    errorText={icrsRecordsError}
                >
                    <Select className="agreementsDropdown"
                        selectedOption={selectedRecord == null ? null : { label: selectedRecord, value: selectedRecord }}
                        options={icrsRecordsResult == null ? [] : icrsRecordsResult.icRegistrationDataList.map(x => ({ label: x.registrationNumber, value: x.registrationNumber }))}
                        onChange={({ detail }) => {
                            setSelectedRecord(detail.selectedOption.value);
                        }}
                        loadingText="Loading ICRS records"
                        statusType={icrsRecordsLoading ? "loading" : "finished"}
                        placeholder="Select ICRS transaction"
                        virtualScroll={true}
                        filteringType="auto"
                    />
                </FormField>
            </Grid>
            <br />
            <Box float="left" padding={{ top: "xxxl" }}>
                <SpaceBetween direction="horizontal" size="xs">
                    <Button className="cancelButton" variant="link" onClick={() => setNavigateURL(CONSTANTS.PAGE_NAV.CALCULATIONS.URL)}>Cancel</Button>
                    <Button className="createCalculationButton"
                        variant="primary"
                        onClick={dispatchCreateCalculation}
                        disabled={!createButtonIsEnabled}
                        loading={isCreating}
                    >
                        Create calculation
                    </Button>
                </SpaceBetween>
            </Box>
            <TPENavigator path={navigateURL} />
        </Container>
    )

}