import React from 'react';
import { WizardStep } from "../../models/common/WizardStep";

export default function WizardStepContentWrapper(props: { children: any, wizardStep: WizardStep }) {
    const { children, wizardStep } = props;
    return (
        <div id={wizardStep.scrollToId}>
            {children}            
        </div>
    );
}