import ServiceCollection from "src/services/ServiceCollection";
import React, {useEffect} from "react";
import {FormField, Link, Select} from '@amzn/awsui-components-react/polaris';
import {ColumnLayout, Popover} from "@amzn/awsui-components-react";


type ComponentProps = {
    services: ServiceCollection,
    selectedAccountingPeriod?: string,
    onAccountingPeriodSelected: (number: string | undefined) => void
}

export default function JEStatusDashboardFilterSelector(props: ComponentProps) {
    const {
        services,
        selectedAccountingPeriod,
        onAccountingPeriodSelected,
    } = props;

    const [accountingPeriodResult, accountingPeriodLoading, accountingPeriodError] = services.reportsService.getReportingPeriods();

    useEffect(() => {
        if (!accountingPeriodError) {
            return;
        }
        services.messageService.showErrorAutoDismissBanner(accountingPeriodError);
    }, [accountingPeriodError])

    return (
        <ColumnLayout className='jeStatusDashboardFilterSelectorLayout' columns={2}>
            <FormField
                label={
                    <div>
                        <span className="periodWrapper">Select Accounting Period</span>
                        <Popover
                            className="periodPopover"
                            dismissButton={false}
                            position="right"
                            size="small"
                            triggerType="custom"
                            content="Required Field"
                        >
                            <Link variant="info">Info</Link>
                        </Popover>
                    </div>
                }
                errorText={accountingPeriodError}
            >
                <Select className="accountingPeriodDropdown"
                        selectedOption={selectedAccountingPeriod == null ? null : {
                            label: selectedAccountingPeriod,
                            value: selectedAccountingPeriod
                        }}
                        options={accountingPeriodResult == null ? [] : accountingPeriodResult.ReportingPeriodList.map((x => ({
                            label: x,
                            value: x
                        })))}
                        onChange={({detail}) => {
                            onAccountingPeriodSelected(detail.selectedOption.value);
                        }}
                        loadingText="Loading accounting periods"
                        statusType={accountingPeriodLoading ? "loading" : "finished"}
                        placeholder="Select accounting period"
                        virtualScroll={true}
                        filteringType="auto"
                />
            </FormField>
        </ColumnLayout>);
}
