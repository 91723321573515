import { Button, Container, Header, StatusIndicator } from '@amzn/awsui-components-react';
import React, {useContext, useEffect, useState} from 'react';
import ReversalsGrid from './ReversalsGrid';
import { MECStatusContext } from './StatusView';
import { ACTIONS } from 'src/services/mec/status/MECStatusReducer';
import CONSTANTS from "src/utils/constants";
import ServiceCollection from "src/services/ServiceCollection";
import {Reversal} from "src/models/mec/status/Reversal";
import { getPermissions } from 'src/components/AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';

export default function ReversalsContainer(props: { loading: boolean,
                                                    services: ServiceCollection,
                                                    userId: string,
                                                    reverseTriggered: boolean,
                                                    viewMode: string,
                                                    refreshStatus: () => any,
                                                    setReversalActionState: (gridType: any, reverseTriggered: boolean) => any,
                                                    setTriggerGroupDetails: (loadTime: string) => any}) {
    const { loading, services, userId, viewMode, reverseTriggered, refreshStatus, setReversalActionState, setTriggerGroupDetails} = props;
    const { state, dispatch } = useContext(MECStatusContext);
    const { reversals = [], reversalSearchItems = [] } = state;
    const [selectedReversals, setSelectedReversals] = useState<Reversal[]>([]);
    const { canBulkReverse } = getPermissions(AppModules.MEC);

    const [reverseClicked, setReverseClicked] = useState(false);

    useEffect(() => {
        if (!reverseTriggered) {
            setReverseClicked(false);
        }
    }, [reverseTriggered])

    const onSelect = () => {
        setSelectedReversals(reversalSearchItems.concat(reversals).filter(x => x.isSelected));
    }

    const onDisplayGroupDetails = (group: string) => {
        dispatch(ACTIONS.SET_GROUP.withPayload(group));
        setTriggerGroupDetails((new Date()).toString());
    }

    const onReverse = () => {
        const reversalRequests = selectedReversals.map(item => ({
            itemId: item.type == CONSTANTS.REVERSAL_ITEM_TYPES.CLI ? item.calculationNumber : item.workbookId,
            itemType: item.type?.toUpperCase()
        }));

        setReverseClicked(true);
        setReversalActionState(null, true);
        services.atpWebSocketApiService.executeBulkReversal({
            requests: reversalRequests,
            reversedBy: userId
        });

        services.messageService.showSuccessAutoDismissBanner(`${selectedReversals.length} items submitted for reversal.`, 3000);
        refreshStatus();
    }

    return <Container data-class="containerWithHeaderAndGrid">
        <div className="containerHeader">
            <Header 
                variant="h2"
                counter={loading ? '(...)' : `(${reversals?.length + (viewMode == CONSTANTS.VIEW_MODE.EDITABLE ? reversalSearchItems?.length : 0)})`}
                actions={viewMode == CONSTANTS.VIEW_MODE.EDITABLE && canBulkReverse && 
                <Button variant="normal" disabled={selectedReversals.length == 0 || reverseTriggered} onClick={onReverse}>
                    {reverseTriggered && reverseClicked ? 
                        <StatusIndicator type="loading">Reversing...</StatusIndicator>
                    :
                        'Reverse'
                    }
                </Button>}
            >
                Reversals
            </Header>
        </div>
        <div>
            <ReversalsGrid 
                data={viewMode == CONSTANTS.VIEW_MODE.EDITABLE ? reversalSearchItems.concat(reversals) : reversals} 
                loading={loading} 
                onSelect={onSelect} 
                onDisplayGroupDetails={onDisplayGroupDetails}/>
        </div>
    </Container>
}