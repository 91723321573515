import { initialState, ReportsState } from "src/services/reports/ReportsState";
import React, { useEffect, useRef, useState } from "react";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import { reportsReducer } from "src/services/reports/ReportsReducer";
import { TPTaxVarianceRecord, TPTaxVarianceRecordsRequest } from "src/models/reports/TPTaxVarianceRecords";
import { Container, SelectProps, Grid, Button, SpaceBetween, Box } from "@amzn/awsui-components-react";
import { DEFAULT_COLLECTION_PREFERENCES, PAGE_SIZE_OPTIONS, VarianceReportTableColumns, VISIBLE_CONTENT_OPTIONS } from "./Constants";
import { getVarianceTableFilteringProperties, prepareColumnDefinitions } from "./TableUtils";
import TPTaxVarianceReportFilterSelector from "./TPTaxVarianceReportFilterSelector";
import TPPolarisTable from "src/components/shared/TPPolarisTable";
import { useVarianceExcelBasedDownload } from "../downloadReports/DownloadTPTaxVarianceReport";

export type ContextType = {
    state: ReportsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const TPTaxVarianceReportProvider = (props: {
    state: ReportsState, dispatch: React.Dispatch<TPEAction>, services: ServiceCollection,
    children: JSX.Element
}) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <TPTaxReportContext.Provider value={providerValue}>
            {children}
        </TPTaxReportContext.Provider>
    );
}

export const TPTaxReportContext = React.createContext(null as ContextType | null);

export default function TPTaxVarianceReportView(props: { services: ServiceCollection }) {
    const { services } = props;
    const [state, dispatch] = useReducerWithLogger(reportsReducer, initialState);
    const [fileName, setFileName] = useState('')
    const [tpTaxVarianceRecordsRequest, setTpTaxVarianceRecordsRequest] = React.useState(null as unknown as TPTaxVarianceRecordsRequest);
    const [tpTaxVarianceRecordList, setTpTaxVarianceRecordsList] = React.useState<TPTaxVarianceRecord[] | null>(null);
    const [selectedAccountingDayOption, setSelectedAccountingDayOption] = React.useState(null as SelectProps.Option | null);
    const [isViewReportClicked, setIsViewReportClicked] = useState(false);
    const [selectedPeriodNameOption, setSelectedPeriodNameOption] = React.useState(null as SelectProps.Option | null);
    const [fetchTpTaxVarianceRecordsResponse, fetchTpTaxVarianceRecordsLoading, tpTaxVarianceRecordsFetchError] = services.reportsService.fetchCompleteTPTaxVarianceRecords(tpTaxVarianceRecordsRequest);
    const columnDefinitionsRef = useRef<any[]>([]);
    const filteringPropertiesRef = useRef<any[]>([])

    useEffect(() => {
        columnDefinitionsRef.current = prepareColumnDefinitions(selectedAccountingDayOption?.value, selectedPeriodNameOption?.value)
        filteringPropertiesRef.current = getVarianceTableFilteringProperties();
        setTpTaxVarianceRecordsList(null);
        setFileName("TP_TAX_VARIANCE_" + selectedPeriodNameOption?.value + "_" + selectedAccountingDayOption?.value + ".xlsx")
    }, [selectedAccountingDayOption, selectedPeriodNameOption]);

    useEffect(() => {
        if (!tpTaxVarianceRecordsFetchError) {
            return;
        }
        services.messageService.showErrorAutoDismissBanner(tpTaxVarianceRecordsFetchError);
    }, [tpTaxVarianceRecordsFetchError])

    useEffect(() => {
        if (fetchTpTaxVarianceRecordsResponse && isViewReportClicked) {
            setTpTaxVarianceRecordsList(fetchTpTaxVarianceRecordsResponse);
            setIsViewReportClicked(false); // Reset the flag after data is fetched
        }
    }, [fetchTpTaxVarianceRecordsResponse]);


    const fetchCompleteTPTaxVarianceRecords = () => {
        if (!!selectedAccountingDayOption && !!selectedPeriodNameOption) {
            setIsViewReportClicked(true)
            setTpTaxVarianceRecordsRequest({
                period: selectedPeriodNameOption?.value as string,
                accountingDay: selectedAccountingDayOption?.value as string,
                startKey: null
            })
        }
    }

    const resetVarianceRecordsRequest = () => {
        setSelectedAccountingDayOption(null);
        setSelectedPeriodNameOption(null);
        setTpTaxVarianceRecordsList(null);
    }

    return (
        <TPTaxVarianceReportProvider state={state} dispatch={dispatch} services={services}>
            <Container>
                <SpaceBetween direction="vertical" size="s">
                    <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                        <TPTaxVarianceReportFilterSelector
                            selectedAccountingPeriod={selectedPeriodNameOption}
                            selectedAccountingDay={selectedAccountingDayOption}
                            services={services}
                            onAccountingPeriodSelected={(periodOption) => setSelectedPeriodNameOption({
                                label: periodOption?.label,
                                value: periodOption?.value
                            })}
                            onAccountingDaySelected={(accountingDayOption) => setSelectedAccountingDayOption({
                                label: accountingDayOption?.label,
                                value: accountingDayOption?.value
                            })}
                        />
                        <Box float="left" padding={{ top: "xl" }}>
                            <SpaceBetween direction="horizontal" size="l">
                                <Button
                                    onClick={fetchCompleteTPTaxVarianceRecords}
                                    disabled={(!selectedAccountingDayOption || !selectedPeriodNameOption)}
                                    loading={fetchTpTaxVarianceRecordsLoading}
                                    variant="primary"
                                >
                                    View Report
                                </Button>
                                <Button onClick={resetVarianceRecordsRequest}>
                                    Reset
                                </Button>
                            </SpaceBetween>
                        </Box>
                    </Grid>
                    {
                        (!!selectedAccountingDayOption && !!selectedPeriodNameOption && !!tpTaxVarianceRecordList) && <TPPolarisTable
                            sortingColumn="workbookId"
                            getDownloadFile={useVarianceExcelBasedDownload}
                            sheetName="TP Tax Variance Entries"
                            fileName={fileName}
                            tableEmptyState={"No Variance entries found for the filter conditions applied"}
                            headerTitle="TP Tax Variance Records"
                            columnDefinations={columnDefinitionsRef.current}
                            tableItems={tpTaxVarianceRecordList}
                            defaultPreferences={DEFAULT_COLLECTION_PREFERENCES}
                            preferencesStorageKey="varianceReportingTablePreferencesStorageKey"
                            pageSizeOptions={PAGE_SIZE_OPTIONS}
                            visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
                            filteringEmptyText="No TP Tax Variance Entries found for the applied filter conditions"
                            filteringLoadingText="Loading TP Tax Variance Entries for the filter conditions applied"
                            filteringProperties={filteringPropertiesRef.current}
                            loadingText={"Loading TP Tax Variance Records"}
                            resizableColumns={true}
                            visibleColumns={VarianceReportTableColumns}
                            isLoading={fetchTpTaxVarianceRecordsLoading}
                        />
                    }
                </SpaceBetween>
            </Container>
        </TPTaxVarianceReportProvider>
    );
}