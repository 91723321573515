import React, { useEffect } from "react";
import { Alert, Box, Button, ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react';
import { TPAllocationContext } from "src/services/tp-allocation/TPAllocationContext";
import CONSTANTS from "src/utils/constants";
import { PlaceholdersState, initialState } from 'src/services/tp-allocation/placeholders/PlaceholdersState';
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import { placeholdersReducer, ACTIONS } from "src/services/tp-allocation/placeholders/PlaceholdersReducer";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import { TPAllocationState } from "src/services/tp-allocation/TPAllocationState";
import { ACTIONS as TP_ALLOCATION_ACTIONS } from "src/services/tp-allocation/TPAllocationReducer";
import WorksheetTemplatePlaceholdersGrid from "../worksheet-templates/WorksheetTemplatePlaceholdersGrid";
import LinkedWorksheetPlaceholdersGrid from "../worksheets/LinkedWorksheetPlaceholdersGrid";
import { TPELoadingSpinner } from "src/components/shared/TPELoadingSpinner";
import { COA_SEGMENTS_OPTIONS } from "./CoaSegmentSelector";
import { SavingStatus } from "src/models/common/SavingStatus";
import Placeholder from "src/models/tp-allocation/Placeholder";

export type PlaceholdersContextType = {
    placeholdersState: PlaceholdersState,
    tpAllocationState: TPAllocationState,
    placeholdersDispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const PlaceholdersProvider = (props: any) => {
    const { state, dispatch, services, children, tpAllocationState } = props;
    const providerValue = React.useMemo(() => ({
        placeholdersState: state, placeholdersDispatch: dispatch, services, tpAllocationState
    }), [state, dispatch, tpAllocationState]);
    return (
        <PlaceholdersContext.Provider value={providerValue}>
            {children}
        </PlaceholdersContext.Provider>
    );
}
export const PlaceholdersContext = React.createContext(null as unknown as PlaceholdersContextType);

export default function PlaceholdersContainer(props: {
    containerTitle: string,
    expanded?: boolean,
    isForLinkedWorksheet: boolean,
    onCancel: () => void
}){
    const { containerTitle, expanded, isForLinkedWorksheet } = props;
    const { services, tpAllocationState, tpAllocationDispatch } = React.useContext(TPAllocationContext);
    const [ state, dispatch] = useReducerWithLogger(placeholdersReducer, initialState);
    const { viewMode, worksheet, worksheetTemplate, sevenSegmentLOVMap } = tpAllocationState;
    const { placeholdersMap, error, alreadySelectedCOAs } = state;

    const [placeholdersResult, placeholdersLoading, placeholdersError] = isForLinkedWorksheet?
        services.tpAllocationPlaceholdersService.getLinkedWorksheetPlaceholders(worksheet?.worksheetId, worksheet?.worksheetVersion) :
        services.tpAllocationPlaceholdersService.getWorksheetTemplatePlaceholders(worksheetTemplate?.templateId, worksheetTemplate?.templateVersion);

    useEffect(() => {
        if (placeholdersResult == null){
            return;
        }
        dispatch(ACTIONS.SET_PLACEHOLDERS.withPayload(placeholdersResult));
    }, [placeholdersResult])

    useEffect(() => {
        if (placeholdersMap == null) {
            return;
        }
        tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_PLACEHOLDERS.withPayload(placeholdersMap));
    }, [placeholdersMap])

    const addCOAPlaceholderClicked = () => {
        dispatch(ACTIONS.ADD_NEW_COA_SEGMENT);
    }
    const mapKeys = Array.from(placeholdersMap.keys());
    const lastCOAIsBlank = mapKeys.length > 0 && mapKeys[mapKeys.length - 1] === CONSTANTS.NEW_COA_PLACEHOLDER_SEGMENT;
    const hasAtleast1PlaceholderIsSaved = mapKeys.length > 0 && (placeholdersMap.get(mapKeys[0])?.length || 0) > 0 && (placeholdersMap.get(mapKeys[0]) as Placeholder[])[0].savingStatus !== SavingStatus.Unsaved;
    return (
        <ExpandableSection data-class="polarisExpandableSection" 
            variant="container"
            defaultExpanded={expanded}
            headerText={containerTitle}
        >
            <PlaceholdersProvider services={services} state={state} dispatch={dispatch} tpAllocationState={tpAllocationState}>
                <div>
                    <div className="placeholdersContentContainer">
                        <div className="placeholdersGridContainer">
                            <TPELoadingSpinner loading={placeholdersLoading} loadingText="Loading placeholders">
                                { isForLinkedWorksheet?
                                    <LinkedWorksheetPlaceholdersGrid placeholdersMap={placeholdersMap} sevenSegmentLOVMap={sevenSegmentLOVMap}/> :
                                    <WorksheetTemplatePlaceholdersGrid placeholdersMap={placeholdersMap} sevenSegmentLOVMap={sevenSegmentLOVMap}/>
                                }
                            </TPELoadingSpinner>
                        </div>
                        { error &&
                            <Alert
                                key="placeholdersErrorAlert"
                                statusIconAriaLabel="Error"
                                type="error">
                                {error}
                            </Alert>
                        }
                    </div>
                    {viewMode === CONSTANTS.VIEW_MODE.EDITABLE &&
                        <div className="actionButtonsContainer">
                            { !isForLinkedWorksheet &&
                                <Box float="left">
                                    <Button data-class="subPrimaryButton" onClick={addCOAPlaceholderClicked} disabled={lastCOAIsBlank || alreadySelectedCOAs.length === COA_SEGMENTS_OPTIONS.length}>Add new COA placeholder</Button>
                                </Box>
                            }
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="m">
                                    <Button data-class="nextButton" variant="primary" disabled={!hasAtleast1PlaceholderIsSaved} onClick={() => tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SELECT_NEXT_WIZARD_STEP)}>
                                        Next Step
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        </div>
                    }
                </div>
            </PlaceholdersProvider>
        </ExpandableSection>
    );
}