import React, { useEffect, useState } from "react";
import { PlaceholdersContext } from "../shared/PlaceholdersContainer";
import CONSTANTS from "../../../utils/constants";
import { Button, FormField, Icon, Input, Popover, SpaceBetween, Grid } from "@amzn/awsui-components-react";
import Placeholder from "../../../models/tp-allocation/Placeholder"
import StringUtils from "src/utils/stringUtils";
import { ACTIONS } from "src/services/tp-allocation/placeholders/PlaceholdersReducer";
import { SavingStatus } from "src/models/common/SavingStatus";
import CoaSegmentSelector from "../shared/CoaSegmentSelector";
import { template } from "@babel/core";

function PlaceholderCell(props:{placeholder:Placeholder, isEditable:boolean, onEditorBlur: (p:Placeholder) => void}){
    const { placeholder, isEditable, onEditorBlur } = props;
    const [value, setValue] = React.useState(StringUtils.isNullOrEmpty(placeholder.value)? placeholder.placeholderName : `${placeholder.placeholderName}: ${placeholder.value}`);
    const [originalSavingStatus] = useState(placeholder.savingStatus);

    const handleInputBlur = () => {
        placeholder.placeholderName = value;
        onEditorBlur(placeholder);
    }
    
    return <div className="placeholderCell">            
            <FormField errorText={placeholder.error}>
                <Input
                    data-class="basicInput"
                    readOnly={!isEditable}
                    onChange={({ detail }) => { 
                        setValue(detail.value);
                        placeholder.error = '';
                        placeholder.savingStatus = 
                            // If no change
                            detail.value == placeholder.value? 
                                originalSavingStatus :  
                                SavingStatus.Unsaved;
                    }}
                    onBlur={handleInputBlur}
                    onFocus={() => placeholder.isRecentlySaved = false}
                    value={value}
                    disabled={placeholder.savingStatus === SavingStatus.Saving}
                    />
                    {placeholder.isRecentlySaved && <span className="floatingIcon">
                        <Icon
                            name="status-positive"
                            size="small"
                            variant="success"
                            />
                    </span>}
                    
            </FormField> 
        </div>;
}

export default function WorksheetTemplatePlaceholdersGrid(props: {placeholdersMap: Map<string, Placeholder[]>, sevenSegmentLOVMap: Map<string,string[]>}){
    const { tpAllocationState, placeholdersState, placeholdersDispatch, services } = React.useContext(PlaceholdersContext);
    const { placeholdersMap } = props;
    const { viewMode, worksheetTemplate } = tpAllocationState;
    const { alreadySelectedCOAs } = placeholdersState;
    const isEditable = viewMode === CONSTANTS.VIEW_MODE.EDITABLE;
    const [localPlaceholderMap, setLocalPlaceholderMap] = useState(new Map() as Map<string, Placeholder[]>);
    const [savePlaceholderPayload, setSavePlaceholderPayload] = useState(undefined as Placeholder | undefined);
    const [deletePlaceholderPayload, setDeletePlaceholderPayload] = useState(undefined as Placeholder | undefined);
    const [savePlaceholderResult, isSavingPlaceholder, savePlaceholderError] = services.tpAllocationPlaceholdersService.saveTemplatePlaceholder(savePlaceholderPayload);
    const [deletePlaceholderResult, isDeletingPlaceholder, deletePlaceholderError] = services.tpAllocationPlaceholdersService.deleteTemplatePlaceholder(deletePlaceholderPayload);

    useEffect(() => {
        if (placeholdersMap.size === 0){
            placeholdersDispatch(ACTIONS.ADD_NEW_COA_SEGMENT);
        }
        setLocalPlaceholderMap(placeholdersMap);
    }, [placeholdersMap])

    useEffect(() => {
        if (deletePlaceholderResult == null) {
            return;
        }
        placeholdersDispatch(ACTIONS.REMOVE_PLACEHOLDER.withPayload({coaSegment: deletePlaceholderPayload?.coaSegment}))
    }, [deletePlaceholderResult])
    
    useEffect(() => {
        if (!isSavingPlaceholder || savePlaceholderPayload == null) {
            return;
        }
        savePlaceholderPayload.savingStatus = SavingStatus.Saving;
        placeholdersDispatch(ACTIONS.REFRESH_PLACEHOLDER.withPayload({coaSegment: savePlaceholderPayload.coaSegment, placeholder: savePlaceholderPayload}))
    }, [isSavingPlaceholder])
    
    useEffect(() => {
        if (savePlaceholderResult == null || savePlaceholderPayload == null) {
            return;
        }
        savePlaceholderResult.savingStatus = SavingStatus.Saved;
        placeholdersDispatch(ACTIONS.REFRESH_PLACEHOLDER.withPayload({coaSegment: savePlaceholderPayload.coaSegment, placeholder: savePlaceholderResult}))
    }, [savePlaceholderResult])
    
    useEffect(() => {
        if (StringUtils.isNullOrEmpty(savePlaceholderError) || savePlaceholderPayload == null) {
            return;
        }
        savePlaceholderPayload.savingStatus = SavingStatus.Unsaved;
        savePlaceholderPayload.error = savePlaceholderError.replace(`${savePlaceholderPayload.placeholderId}:`,"");
        placeholdersDispatch(ACTIONS.REFRESH_PLACEHOLDER.withPayload({coaSegment: savePlaceholderPayload.coaSegment, placeholder: savePlaceholderPayload}))
    }, [savePlaceholderError])

    const addPlaceholderToCOA = (coa:string) => {
        const arrLength = localPlaceholderMap.get(coa)?.length || 0;
        placeholdersDispatch(ACTIONS.ADD_PLACEHOLDER.withPayload({
            coaSegment: coa, 
            newPlaceholder: services.tpAllocationPlaceholdersService.createNewPlaceholder(coa, arrLength + 1, worksheetTemplate?.templateId)
        }))
    }

    const removePlaceholderFromCOA = (coaSegment:string) => {
        const placeholders = placeholdersMap.get(coaSegment);
        if (placeholders == null || placeholders.length === 0){
            placeholdersDispatch(ACTIONS.REMOVE_PLACEHOLDER.withPayload({coaSegment}))
            return;
        }
        setDeletePlaceholderPayload({...placeholders[(placeholders.length || 0) -1], parentEntityId: worksheetTemplate?.templateId});
    }

    const segmentChanged = (oldSegment: string, newSegment?: string) => {
        placeholdersDispatch(ACTIONS.UPDATE_COA_SEGMENT.withPayload({oldSegment, newSegment, newPlaceholderForNewSegment: services.tpAllocationPlaceholdersService.createNewPlaceholder(newSegment || CONSTANTS.NEW_COA_PLACEHOLDER_SEGMENT, 1, worksheetTemplate?.templateId)}))
    }

    const onPlaceholderEditorBlur = (placeholder: Placeholder) => {
        if (placeholder.savingStatus == null || placeholder.savingStatus === SavingStatus.Saved){
            return;
        }
        if (placeholder.placeholderName.trim() === ''){
            placeholdersDispatch(ACTIONS.UPDATE_PLACEHOLDER_ERROR.withPayload({coaSegment: placeholder.coaSegment, placeholderId: placeholder.placeholderId, error: "Placeholder name is required" }))
            return;
        }
        
        setSavePlaceholderPayload({...placeholder, parentEntityId: worksheetTemplate?.templateId});
    }

    return (
        <SpaceBetween direction="vertical" size="xs">
            {Array.from(localPlaceholderMap.keys()).map(coaSegment =>
            <div className="wizardBoxContentContainer">
                <div className="placeholderList">
                    <CoaSegmentSelector segment={coaSegment} isEditable={isEditable} alreadySelected={alreadySelectedCOAs} onSegmentChanged={segmentChanged} />
                        {localPlaceholderMap.get(coaSegment)?.map(p => <PlaceholderCell 
                            placeholder={p} 
                            isEditable={isEditable} 
                            onEditorBlur={onPlaceholderEditorBlur} />)}
                        {isEditable && <div className="placeholderCell">
                                <SpaceBetween size="m" direction="horizontal">
                                    <Button data-class="smallPrimaryButton placeholderContextActionBtn"  iconName="add-plus" variant="icon" onClick={() => addPlaceholderToCOA(coaSegment)}>+</Button>
                                    <Button data-class="smallPrimaryButton placeholderContextActionBtn" onClick={() => removePlaceholderFromCOA(coaSegment)}>Remove</Button>
                                    { !StringUtils.isNullOrEmpty(deletePlaceholderError) && deletePlaceholderPayload?.coaSegment === coaSegment && <Popover  key={`pop${coaSegment}_error`}
                                            className="errorMessagePopover"
                                            dismissButton={false}
                                            position="right"
                                            size="small"
                                            triggerType="custom"
                                            content={
                                                deletePlaceholderError
                                            }
                                        >
                                            <Icon
                                            name="status-warning"
                                            size="normal"
                                            variant="warning"
                                            />
                                        </Popover> 
                                    }
                                </SpaceBetween>
                            </div>
                        }
                    
                    </div>
                </div>
            )}
        </SpaceBetween>
    );
}