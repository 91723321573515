export default class TemporaryMessage {
    type: string;
    message: string;
    visible: boolean;
    header?: string;

    constructor(message: string, type: string, visible: boolean, header?: string) {
        this.message = message;
        this.type = type;
        this.visible = visible;
        this.header = header;
    }
}