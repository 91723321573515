import { useEffect, useState } from 'react';
import { SearchICRegistrationResult } from 'src/models/icrs/SearchICRegistrationResult';

import apiService from '../ApiCallService'
import TPESearchRequest from 'src/models/common/TPESearchRequest';
import ErrorUtils from 'src/utils/ErrorUtils';
import ICRegistration from 'src/models/icrs/ICRegistration';
import StringUtils from 'src/utils/stringUtils';


/**
 * This class should only contain the API calls related to ICRS transactions
 */
export default class ICRSService {
    /**
     * Gets a single IC registration by registration number
     * @param registrationNumber The ICRS registration number
     * @param cliDetailsNeedRefresh A flag to allow calling the API
     * @returns An ICRS record if found
     */
    getICRegistrationDetails(registrationNumber: string, cliDetailsNeedRefresh: boolean): [ICRegistration, boolean, string] {
        const [result, setResult] = useState(null as unknown as ICRegistration);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getICRegistration(registrationNumber);
                    const json = await response.json();
                    setResult(json.icRegistrationData);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (cliDetailsNeedRefresh && !StringUtils.isNullOrEmpty(registrationNumber)) {
                fetchData();
            }
        }, [cliDetailsNeedRefresh, registrationNumber]);
        return [result, loading, error];
    }
    /**
    * Finds ICRS records based on payload parameters
    * @param transactionLine A NonApttus agreement
    */
    searchICRS(payload: TPESearchRequest): [SearchICRegistrationResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as SearchICRegistrationResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchICRS({...payload, tpCalculation : true});
                    const json = await response.json() as SearchICRegistrationResult;
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }

            if (payload.searchStartedDate != null) {
                fetchData();
            }
        }, [payload.searchStartedDate]);
        return [results, loading, error];
    }


}