import {useEffect, useState} from "react";
import {CalcBuilderComment} from "../../models/calculation-builder/CalcBuilderComment";
import {COACategoryDrillDown, COADrillDown, DataSourceRecord, GLBalance} from "../../models/common/DataSourceRecord";
import {COADataKey} from "../../models/calculation-builder/PullBalanceRequest";
import CommonDataSourceOperations from "src/services/common/CommonDataSourceOperations"

import apiService from "../ApiCallService";
import CONSTANTS from "../../utils/constants";
import ErrorUtils from "../../utils/ErrorUtils";
import DateUtils from "src/utils/dateUtils";

/**
 * This class should only contain the API calls related to DataSources
 */
 export default class DataSourcesService {
     /**
     * Gets all data for data source, currency, fx type, and period LOVs at initial load time
     * @param query: load time as a string
     */

    getDataSourceLOVs(query: string) : [string[], string[], string[], string[], boolean, string] {
        const [dataSourceTypes, setDataSourceTypes] = useState([] as string[]);
        const [currencyCodes, setCurrencyCodes] = useState([] as string[]);
        const [periods, setPeriods] = useState([] as string[]);
        const [currencyConversionTypes, setCurrencyConversionTypes] = useState([] as string[]);

        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const promises = (await Promise.all([
                        apiService.getDataSourceTypes(),
                        apiService.getCurrencyCodes(),
                        apiService.getPeriods(),
                        apiService.getCurrencyConversionTypes(),
                    ])).map(result => result.json());
                    const allData = await Promise.all(promises);
                    setDataSourceTypes(allData[0].dataSourceTypes);
                    setCurrencyCodes(allData[1].currencyCodes);
                    setPeriods(allData[2].periods);
                    setCurrencyConversionTypes(allData[3].currencyConversionTypes);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (query != null && query.length > 0) {
                fetchData();
            }
        }, [query]);

        return [dataSourceTypes, currencyCodes, periods, currencyConversionTypes, loading, error];
    }

    getCoaTypes(query: string) : [string[], boolean, string] {
        const [coaTypes, setCoaTypes] = useState([] as string[]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCoaTypes();
                    const json = await response.json()
                    setCoaTypes(json.coaTypes);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (query != null && query.length > 0) {
                fetchData();
            }
        }, [query]);
        return [coaTypes, loading, error]
    }

    getCoaData(query: string) : [string[], boolean, string] {
        const [coaLOV, setCoaLOV] = useState([] as string[]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCoaData(query);
                    const json = await response.json()
                    setCoaLOV(json.coaData);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (query != null && query.length > 0) {
                fetchData();
            }
        }, [query]);
        return [coaLOV, loading, error]
    }

    convertToGLBalancesModel(glBalanceResponse: any): { glBalances: GLBalance[], glBalanceBreakdownKeys: Map<string, string> } {
        const glBalances: GLBalance[] = [];
        const glBalanceBreakdownKeys: Map<string, string> = new Map<string, string>();

        if (glBalanceResponse.balancePerCompanyAccount != null) {
            Object.keys(glBalanceResponse.balancePerCompanyAccount).forEach(key => {
                var balanceObject = glBalanceResponse.balancePerCompanyAccount[key];
                var glBalance: GLBalance = {
                    company: balanceObject.company,
                    account: balanceObject.account,
                    balance: balanceObject.balance,
                }
                glBalances.push(glBalance);
            });
        } else if (glBalanceResponse.dataKeyInput != null) {
            glBalances.push({
                company: glBalanceResponse.dataKeyInput.selectedCompanies || '',
                account: glBalanceResponse.dataKeyInput.selectedAccounts || '',
                balance: glBalanceResponse.totalBalance
            });
        }

        if (glBalanceResponse.balanceBreakdowns != null) {
            Object.keys(glBalanceResponse.balanceBreakdowns).forEach(coa => {
                var coaBreakdownKey = glBalanceResponse.balanceBreakdowns[coa];
                const coaSegmentArr = Object.values(CONSTANTS.COA_SEGMENT_MAPPING).filter(x => x.GL == coa);
                const coaSegment = coaSegmentArr.length > 0 ? coaSegmentArr[0].UI : '';
                glBalanceBreakdownKeys.set(coaSegment, coaBreakdownKey);
            });
        }

        return { glBalances: glBalances, glBalanceBreakdownKeys: glBalanceBreakdownKeys };
    }

    convertToCOADrillDownModel(coa: string, coaBreakdown: any): Map<string, COADrillDown> {
        const coaDrillDownMap = new Map<string, COADrillDown>();
        Object.keys(coaBreakdown).forEach(key => {
            const coaDrillDown = {} as COADrillDown;
            coaDrillDown.coa = coa;
            coaDrillDown.coaCategoryDrillDowns = this.convertToCOACategoryDrillDownsModel(coaBreakdown[key]);
            coaDrillDownMap.set(key, coaDrillDown);
        });
        return coaDrillDownMap;
    }

    convertToCOACategoryDrillDownsModel(coaBreakdown: any): COACategoryDrillDown[] {
        const coaCategoryDrillDowns: COACategoryDrillDown[] = [];

        coaBreakdown.forEach((item: any) => {
            var coaCategoryDrillDown: COACategoryDrillDown = {
                coaCategory: item.segment,
                description: item.description,
                balance: item.balance,
            };
            if (item.children != undefined && item.children != null) {
                coaCategoryDrillDown.subCategories = this.convertToCOACategoryDrillDownsModel(item.children);
            }
            coaCategoryDrillDowns.push(coaCategoryDrillDown);
        });

        return coaCategoryDrillDowns;
    }

    validateDataSourceDescription(description: string | undefined, allDescriptions: string[]) : Map<string, string> {
        const errorMap = new Map<string, string>();
        CommonDataSourceOperations.checkAndAddRequiredFieldError(description, errorMap, CONSTANTS.DATA_SOURCE_FIELDS.DESCRIPTION.ACCESSOR);
        
        if (description != null && description.trim() != '') {
            if (allDescriptions.filter(x => (x || '').trim() == description.trim()).length > 1) {
                errorMap.set(CONSTANTS.DATA_SOURCE_FIELDS.DESCRIPTION.ACCESSOR, 'Must be unique');
            }
        }
        return errorMap;
    }

    
    getDataSources(shouldPull: boolean, calculationNumber?: string, version?: number, calculationExecutionId?: string) : [DataSourceRecord[], Map<string, string>, boolean, string] {
        const [result, setResult] = useState([] as DataSourceRecord[]);
        const [referenceMap, setReferenceMap] = useState(null as unknown as Map<string, string>)
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData($this: DataSourcesService, calcNumber:string) {
                try {
                    setLoading(true);
                    const response = await apiService.getCalcBuilderDataSources(calcNumber, version == null? -1 : version, calculationExecutionId); 
                    const json = await response.json();
                    const processedData = $this.convertToDataSourceRecords(json.datasourceList, calculationNumber);
                    setResult(processedData.records);
                    setReferenceMap(processedData.customCoaReferenceMap);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null && version != null && shouldPull) {
                fetchData(this, calculationNumber);
            }
        }, [calculationNumber, version, shouldPull]);
        return [result, referenceMap, loading, error]
    }

    deleteDataSource(deleteDataSourceRequest: any) : [DataSourceRecord, boolean, string] {
        const [result, setResult] = useState(null as unknown as DataSourceRecord);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(calculationNumber:string, datasourceId: string) {
                try {
                    const response = await apiService.deleteDataSourceRecord(calculationNumber, datasourceId);
                    const json = await response.json()
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (deleteDataSourceRequest != null) {
                fetchData(deleteDataSourceRequest.calculationNumber, deleteDataSourceRequest.datasourceId);
            }
        }, [deleteDataSourceRequest]);
        return [result, loading, error]
    }

    saveDataSource(customCoaReference: Map<string, string>, dataUpdateOnly: boolean, recordBeingEdited?: DataSourceRecord, recordNeedsSaving?: string): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData($this: DataSourcesService, record: any, customCoaReference: Map<string, string>, dataUpdateOnly: boolean) {
                try {
                    setLoading(true);
                    const saveDataSourceReq = $this.convertToSaveDataSourceRequest(record || {} as DataSourceRecord, customCoaReference, dataUpdateOnly);
                    const response = await (record.isNewRecord?
                        apiService.createDataSourceRecord(record.calculationNumber, saveDataSourceReq) :
                        apiService.updateDataSourceRecord(record.calculationNumber, saveDataSourceReq));
                    const json = await response.json();
                    const jsonObj = json.datasource;
                    const {datasourceType} = jsonObj;
                    const jsonCopy = {...jsonObj, datasource: datasourceType};
                    setResult(jsonCopy);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (recordNeedsSaving != null && recordBeingEdited != null) {
                postData(this, recordBeingEdited, customCoaReference, dataUpdateOnly);
            }
        }, [recordNeedsSaving]);
        return [result, loading, error]
    }

    getDataSourceComments(calculationNumber?: string, version?: number, dataSourceId?: string, pullComments?: boolean) : [CalcBuilderComment[], boolean, string | null] {
        const [result, setResult] = useState([] as CalcBuilderComment[]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null as string | null);

        useEffect(() => {
            async function fetchData(calcNumber:string) {
                try {
                    const response = await apiService.getDataSourceComments(calcNumber, version == null ? -1 : version, dataSourceId || '');
                    const json = await response.json();
                    setResult(json.comments);
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null && dataSourceId != null && pullComments === true) {
                fetchData(calculationNumber);
            }
            else {
                setResult([]);
            }
        }, [calculationNumber, pullComments]);
        return [result, loading, error]
    }

    saveDataSourceComment(comment?: CalcBuilderComment) : [CalcBuilderComment | null, boolean, string | null] {
        const [result, setResult] = useState(null as CalcBuilderComment | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as string | null);
        
        useEffect(() => {
            async function postData(payload: CalcBuilderComment) {
                try {
                    setError(null);
                    const response = await apiService.createDataSourceComment(payload.calculationNumber, payload.calculationVersion == null ? -1 : payload.calculationVersion, payload.datasourceId || '', payload.comment);
                    const json = await response.json();
                    setResult(json.comment);
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (comment != null) {
                setLoading(true);
                postData(comment);
            }
        }, [comment?.createdDate]);
        return [result, loading, error]
    }

    getBalanceBreakdown(coa: string, url: string | null): [Map<string, COADrillDown>, boolean, string | null] {
        const [result, setResult] = useState(null as unknown as Map<string, COADrillDown>);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as string | null);
        
        useEffect(() => {
            async function getData($this: DataSourcesService, coa: string, s3Url: string) {
                try {
                    setError(null);
                    const urlTokens = s3Url?.replace('s3://', '').split('/');
                    if (urlTokens != null && urlTokens.length == 2) {
                        const json = await apiService.downloadFromS3(urlTokens[0], urlTokens[1]);
                        const coaDrillDownMap = $this.convertToCOADrillDownModel(coa, json);
                        setResult(coaDrillDownMap);
                    }
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (url != null) {
                setLoading(true);
                getData(this, coa, url);
            }
        }, [url]);
        return [result, loading, error]
    }

    convertToDataSourceRecords(datasourceList: any[], calculationNumber?: string) : { records: DataSourceRecord[], customCoaReferenceMap: Map<string, string> } {
        const records = [] as DataSourceRecord[];
        const customCoaReferenceMap = new Map<string, string>();

        datasourceList.forEach(apiDataSource => {
            const dataSource = {} as DataSourceRecord;
            dataSource.datasourceId = apiDataSource.datasourceId;
            dataSource.datasource = apiDataSource.datasourceType;
            dataSource.period = apiDataSource.period;
            dataSource.fxDate = apiDataSource.fxDate;
            dataSource.fxType = apiDataSource.fxType;
            dataSource.fxRate = apiDataSource.fxRate;
            dataSource.currency = apiDataSource.currency;
            dataSource.description = apiDataSource.description;
            dataSource.customDatasourceCalculationNumber = apiDataSource.customDatasourceCalculationNumber;
            dataSource.customDatasourceTableId = apiDataSource.customDatasourceTableId;
            dataSource.customDatasourceTableName = apiDataSource.customDatasourceTableName;
            dataSource.customDatasourceValueId = apiDataSource.customDatasourceValueId;
            dataSource.customDatasourceValueLegend = apiDataSource.customDatasourceValueLegend;
            dataSource.tpAllocationWorksheetId = apiDataSource.tpAllocationWorksheetId;
            dataSource.tpAllocationWorksheetName = apiDataSource.tpAllocationWorksheetName;
            dataSource.tpAllocationGroupId = apiDataSource.tpAllocationGroupId;
            dataSource.tpAllocationGroupName = apiDataSource.tpAllocationGroupName;
            dataSource.tpAllocationFormulaId = apiDataSource.tpAllocationFormulaId;
            dataSource.tpAllocationFormulaName = apiDataSource.tpAllocationFormulaName;
            dataSource.tpAllocationMappingType = apiDataSource.tpAllocationMappingType;
            dataSource.standardAllocation = apiDataSource.standardAllocation;
            dataSource.passThroughFlag = apiDataSource.passThroughFlag;

            if (apiDataSource.value != null) {
                dataSource.value = String(apiDataSource.value);
            }

            if (apiDataSource.errors){
                dataSource.errors = new Map(Object.entries(apiDataSource.errors));
            }
            if (calculationNumber != null) {
                dataSource.calculationNumber = calculationNumber;
            }
            if (apiDataSource.startPeriod != null && apiDataSource.endPeriod != null) {
                dataSource.customPeriod = dataSource.datasource === CONSTANTS.DATA_SOURCE_TYPES.CUSTOM_DATA_TABLE? 
                    apiDataSource.startPeriod:
                    `${apiDataSource.startPeriod.replace('-', '/')} - ${apiDataSource.endPeriod.replace('-', '/')}`;
                dataSource.period = null as unknown as string;
            }
            if (dataSource.datasource === CONSTANTS.DATA_SOURCE_TYPES.GENERAL_LEDGER){
                let keyCount = 2;
                if (apiDataSource.dataKeys){
                    dataSource.dataKeyInput = {
                        selectedCompanies: CommonDataSourceOperations.convertToDataKeyJoinedString(apiDataSource.dataKeys.companies),
                        selectedAccounts: CommonDataSourceOperations.convertToDataKeyJoinedString(apiDataSource.dataKeys.glAccounts),
                        selectedCOA: new Map<string, string>()
                    }

                    for (const [key, value] of Object.entries(apiDataSource.dataKeys)) {
                        if (key != CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.WEB_API && key != CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.WEB_API) {
                            const coaSegmentArr = Object.values(CONSTANTS.COA_SEGMENT_MAPPING).filter(x => x.WEB_API == key);
                            const coaSegment = coaSegmentArr.length > 0 ? coaSegmentArr[0].UI : '';
                            dataSource.dataKeyInput.selectedCOA.set(coaSegment, CommonDataSourceOperations.convertToDataKeyJoinedString(value as COADataKey[]));
                            keyCount++;
                        }
                    }
                }
                dataSource.dataKey = keyCount + CONSTANTS.DATA_SOURCE_DATA_KEY_COUNT_SUFFIX;
                CommonDataSourceOperations.updateCustomCoaReferenceMap(apiDataSource.dataKeys, customCoaReferenceMap);
            }
            else if (dataSource.datasource === CONSTANTS.DATA_SOURCE_TYPES.CUSTOM_DATA_TABLE) {
                dataSource.dataKey = dataSource.customDatasourceCalculationNumber;
            } else {
                dataSource.dataKey = dataSource.tpAllocationWorksheetName;
            }
    
            if (apiDataSource.lastBalancePullId != null && apiDataSource.value != null) {
                dataSource.lastBalancePullRequestID = apiDataSource.lastBalancePullId;
                const { glBalances, glBalanceBreakdownKeys } = this.convertToGLBalancesModel({ 
                    balancePerCompanyAccount: apiDataSource.balancePerCompanyAccount, 
                    balanceBreakdowns: apiDataSource.balanceBreakdowns,
                    dataKeyInput: dataSource.dataKeyInput,
                    totalBalance: apiDataSource.value
                });
                dataSource.glBalances = glBalances;
                dataSource.glBalanceDrillDownKeys = glBalanceBreakdownKeys;
            }
    
            if (apiDataSource.lastBalancePullDate != null) {
                dataSource.lastBalancePullDate = apiDataSource.lastBalancePullDate;
            }

            dataSource.hasComments = apiDataSource.hasComments || false;

            dataSource.lastModifiedUser = apiDataSource.lastModifiedUser == null ? 'N/A' : apiDataSource.lastModifiedUser
            dataSource.lastModifiedDate = apiDataSource.lastModifiedDate == null ? 'N/A' : DateUtils.formatTimestamp(apiDataSource.lastModifiedDate);
    
            records.push(dataSource);
        })
        return { records, customCoaReferenceMap };
    }

    convertToSaveDataSourceRequest(record: DataSourceRecord, customCoaReference: Map<string, string>, dataUpdateOnly: boolean) : any {
        const saveDataSourceRequest: any = {
            calculationNumber: record.calculationNumber,
            datasourceId: record.datasourceId,
            datasource: record.datasource,
            period: record.period,
            description: record.description,
            currency: record.currency,
            lastBalancePullId: record.lastBalancePullRequestID,
            customDatasourceCalculationNumber: record.customDatasourceCalculationNumber,
            customDatasourceTableId: record.customDatasourceTableId,
            customDatasourceValueId: record.customDatasourceValueId,
            tpAllocationWorksheetId: record.tpAllocationWorksheetId,
            tpAllocationGroupId: record.tpAllocationGroupId,
            tpAllocationFormulaId: record.tpAllocationFormulaId,
            tpAllocationMappingType: record.tpAllocationMappingType,
            passThroughFlag: record.passThroughFlag
        }
        if (record.customPeriod != null) {
            if (record.customPeriod.indexOf('/') > 0) {
                const customPeriodArr = record.customPeriod.split('-').map(x => x.replace('/', '-'));
                saveDataSourceRequest.startPeriod = customPeriodArr[0].trim();
                saveDataSourceRequest.endPeriod = customPeriodArr[1].trim();
            } else {
                saveDataSourceRequest.startPeriod = record.customPeriod;
                saveDataSourceRequest.endPeriod = record.customPeriod;
            }
            
            saveDataSourceRequest.period = CONSTANTS.DATA_SOURCE_VALUES.CUSTOM_PERIOD;
        }
        if (record.fxDate != null) {
            saveDataSourceRequest.fxDate = record.fxDate;
        }
        if (record.fxType != null) {
            saveDataSourceRequest.fxType = record.fxType;
        }
        if (record.fxRate != null) {
            saveDataSourceRequest.fxRate = record.fxRate;
        }
        if (record.value != null && record.value.length > 0) {
            saveDataSourceRequest.value = Number(record.value);
        }

        if (record.datasource == CONSTANTS.DATA_SOURCE_TYPES.GENERAL_LEDGER){
            const dataKeyInput: any = {};
            dataKeyInput.companies = CommonDataSourceOperations.convertToCOADataKeyList(record.dataKeyInput?.selectedCompanies, customCoaReference);
            dataKeyInput.glAccounts = CommonDataSourceOperations.convertToCOADataKeyList(record.dataKeyInput?.selectedAccounts, customCoaReference);
            record.dataKeyInput?.selectedCOA.forEach((value, key) => {
                const coaSegmentArr = Object.values(CONSTANTS.COA_SEGMENT_MAPPING).filter(x => x.UI == key);
                const coaSegment = coaSegmentArr.length > 0 ? coaSegmentArr[0].WEB_API : '';
                dataKeyInput[coaSegment] = CommonDataSourceOperations.convertToCOADataKeyList(value, customCoaReference);
            });
            saveDataSourceRequest.dataKeys = dataKeyInput;
        }

        if (dataUpdateOnly) {
            saveDataSourceRequest.dataUpdateOnly = true;
        }

        return saveDataSourceRequest;
    }
 }