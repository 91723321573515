import React, { useEffect, useState } from 'react';
import { Box, SpaceBetween, Modal, FormField, Button, Input, Alert, ColumnLayout, Select, Autosuggest } from '@amzn/awsui-components-react/polaris';
import CONSTANTS from 'src/utils/constants';
import { WorksheetTemplate } from 'src/models/tp-allocation/WorksheetList';
import StringUtils from 'src/utils/stringUtils';
import Placeholder from 'src/models/tp-allocation/Placeholder';
import PlaceholderAutosuggest from '../shared/PlaceholderAutosuggest';
import CreateWorksheetRequest from 'src/models/tp-allocation/CreateWorksheetRequest';


export default function CreateWorksheetModal(props: {visible: boolean,
                                                    templates: WorksheetTemplate[],
                                                    sevenSegmentLOVMap: Map<string, string[]>,  
                                                    isCreating: boolean,
                                                    createError: string,
                                                    onClose: () => void, 
                                                    onSubmit: (c: CreateWorksheetRequest) => any }) {

    const { visible, templates, sevenSegmentLOVMap, isCreating, createError, onClose, onSubmit } = props;

    const [worksheetName, setWorksheetName] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState(null as unknown as any);
    const [activeTemplates, setActiveTemplates] = useState([] as any[]);
    const [placeholderMap, setPlaceholderMap] = useState(null as unknown as Map<string, Placeholder[]>);
    const [placeholderValueMap, setPlaceholderValueMap] = useState(null as unknown as Map<string, string>);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setActiveTemplates([{ label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION }, 
            ...templates.filter(x => x.templateVersion == CONSTANTS.CALCULATION_VERSIONS.CURRENT_VERSION).map(x => ({ label: x.templateName, value: x.templateId }))]);
    }, [templates])


    useEffect(() => {
        if (visible) {
            setWorksheetName('');
            setSelectedTemplate(null as unknown as any);
            setShowError(false);
        }
    }, [visible])

    useEffect(() => {
        if (isCreating) {
            setShowError(true);
        }
    }, [isCreating])

    useEffect(() => {
        setPlaceholderMap(null as unknown as Map<string, Placeholder[]>);
        setPlaceholderValueMap(null as unknown as Map<string, string>);
        if (selectedTemplate != null) {
            const templateObject = templates.find(x => x.templateId == (selectedTemplate as any)['value']);
            if (templateObject != null) {
                setPlaceholderMap(templateObject.placeholdersMap);
                const placeholderValues = new Map<string, string>();
                templateObject.placeholdersMap.forEach((v, k) => {
                    v?.forEach(x => placeholderValues.set(x.placeholderId, x.value || ''));
                });
                setPlaceholderValueMap(placeholderValues);
            }
        }
    }, [selectedTemplate])

    const disableCreate = () => {
        if (StringUtils.isNullOrEmpty(worksheetName)) {
            return true;
        }
        if (selectedTemplate != null && placeholderValueMap != null) {
            return Array.from(placeholderValueMap.values()).filter(x => StringUtils.isNullOrEmpty(x)).length > 0
        }
        return false;
    }

    const selectTemplate = (selectedOption: any) => {
        if (selectedOption.value == CONSTANTS.CLEAR_SELECTION) {
            setSelectedTemplate(null as unknown as any);
        } else {
            setSelectedTemplate(selectedOption);
        }
    }

    const setPlaceholderValue = (ph: Placeholder, value: string) => {
        const placeholderValueMapCopy = new Map(placeholderValueMap);
        placeholderValueMapCopy.set(ph.placeholderId, value);
        setPlaceholderValueMap(placeholderValueMapCopy);
    }

    const submit = () => {
        const createWorksheetRequest: CreateWorksheetRequest = {
            worksheetName: worksheetName,
            templateId: selectedTemplate != null ? selectedTemplate.value : undefined,
            placeholders: placeholderValueMap != null ? [... Array.from(placeholderValueMap.keys()).map(x => {
                return { placeholderId: x, value: placeholderValueMap.get(x) } as Placeholder
            })] : undefined
        }
        onSubmit(createWorksheetRequest);
    }

    return <Modal data-class="createWorksheetModal" visible={visible}
        header="Create new TP Allocation worksheet"
        size={selectedTemplate == null ? "medium" : "large"}
        onDismiss={onClose}
        footer={
            <Box float="right">
                    <Button variant="link" className="cancelButton" disabled={isCreating} onClick={onClose}>Cancel</Button>
                    <Button variant="primary" className="saveButton" disabled={isCreating || disableCreate()} onClick={submit}>{isCreating ? 'Creating...' : 'Create worksheet'}</Button>
            </Box>
        }>
            <SpaceBetween direction="vertical" size="m">
                <FormField  data-class="createModalField"
                            label="Enter worksheet name*"
                            stretch={false}
                >
                    <Input data-class="basicModalInput worksheetNameFieldInput"
                            value={worksheetName}
                            onChange={e => setWorksheetName(e.detail.value)}
                    />
                </FormField>
                <FormField  data-class="createModalField"
                            label="Select template"
                            stretch={false}
                >
                    <Select data-class="basicModalDropdown templateFieldDropdown"
                                selectedOption={selectedTemplate}
                                options={activeTemplates}
                                onChange={({ detail }) => selectTemplate(detail.selectedOption)}
                                placeholder="No template selected"
                            />
                </FormField>
                {placeholderMap != null &&
                    <div className="placeholderContainer">
                    <div className="placeholderMainHeader">Enter placeholder values*</div>
                    {Array.from(placeholderMap.keys()).map(k => 
                        <React.Fragment>
                            <div key={`placeholderLabel${k}`}>{k}</div>
                            <div key={`placeholderValue${k}`} className="placeholderList">
                                {placeholderMap.get(k)?.map(ph => 
                                    <PlaceholderAutosuggest placeholder={ph} lov={sevenSegmentLOVMap.get(k) || []} onBlur={setPlaceholderValue} />
                                )}
                            </div>
                        </React.Fragment>
                    )}
                    </div>
                }
                { showError && !StringUtils.isNullOrEmpty(createError) && <Alert type='error' dismissible={false}>{createError}</Alert> }
            </SpaceBetween>
        </Modal>
}