import { Box, Button, ColumnLayout, Container, Header, Icon, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import './TPAllocation.scss';
import { TPELoadingSpinner } from '../shared/TPELoadingSpinner';
import ArrayUtils from 'src/utils/arrayUtils';
import TPENavigator from '../TPENavigator';
import CreateWorksheetModal from './worksheets/CreateWorksheetModal';
import CreateWorksheetTemplateModal from './worksheet-templates/CreateWorksheetTemplateModal';
import CreateWorksheetRequest from 'src/models/tp-allocation/CreateWorksheetRequest';
import { getPermissions } from '../AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';
import CONSTANTS from 'src/utils/constants';

export default function TPAllocationView(props: { services: ServiceCollection }) {
    const { services } = props;
    const { canEdit } = getPermissions(AppModules.TP_ALLOCATION);

    const [expandedTemplates, setExpandedTemplates] = useState([] as string[]);
    const [navigateURL, setNavigateURL] = React.useState('');
    const [showCreateWorksheetModal, setShowCreateWorksheetModal] = useState(false);
    const [showCreateWorksheetTemplateModal, setShowCreateWorksheetTemplateModal] = useState(false);
    const [createWorksheetPayload, setCreateWorksheetPayload] = useState(null as unknown as CreateWorksheetRequest);
    const [newWorksheetTemplateName, setNewWorksheetTemplateName] = useState(null as unknown as string);
    const [createNewWorksheetTemplate, setCreateNewWorksheetTemplate] = useState(null as unknown as string);
    
    const [listWorksheetsResult, isListing, listError] = services.tpAllocationService.listWorksheets('get');
    const [coaSegmentsResponse, coaSegmentsLoading, coaSegmentError] = services.tpAllocationService.getAllCoaSegments('get');
    const [createWorksheetResponse, isCreatingWorksheet, createWorksheetError] = services.tpAllocationService.createWorksheet(createWorksheetPayload);
    const [createWorksheetTemplateResponse, isCreatingWorksheetTemplate, createWorksheetTemplateError] = 
                                    services.tpAllocationService.createWorksheetTemplate(createNewWorksheetTemplate, newWorksheetTemplateName);

    
    useEffect(() => {
        if (createWorksheetResponse == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner(`TP Allocation worksheet ${createWorksheetResponse.worksheetName} successfully created.`)
        if (createWorksheetPayload.templateId != null) {
            navigateToLinkedWorksheet(createWorksheetResponse.worksheetId);
        } else {
            navigateToStandaloneWorksheet(createWorksheetResponse.worksheetId);
        }
    }, [createWorksheetResponse])

    useEffect(() => {
        if (createWorksheetTemplateResponse == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner(`TP Allocation worksheet template ${createWorksheetTemplateResponse.templateName} successfully created.`)
        navigateToWorksheetTemplate(createWorksheetTemplateResponse.templateId);
    }, [createWorksheetTemplateResponse])

    const navigateToStandaloneWorksheet = (worksheetId: string) => {
        setNavigateURL(`${CONSTANTS.PAGE_NAV.TP_ALLOCATION_STANDALONE.URL}/${btoa(worksheetId)}`)
    }
    
    const navigateToLinkedWorksheet = (worksheetId: string) => {
        setNavigateURL(`${CONSTANTS.PAGE_NAV.TP_ALLOCATION_LINKED.URL}/${btoa(worksheetId)}`)
    }
    
    const navigateToWorksheetTemplate = (templateId: string) => {
        setNavigateURL(`${CONSTANTS.PAGE_NAV.TP_ALLOCATION_TEMPLATE.URL}/${btoa(templateId)}`)
    }

    const toggleExpandCollapse = (templateId: string) => {
        if (expandedTemplates.includes(templateId)) {
            setExpandedTemplates(expandedTemplates.filter(x => x != templateId));
        } else {
            setExpandedTemplates([...expandedTemplates, templateId]);
        }
    }

    const getIconName = (templateId: string) => {
        return expandedTemplates.includes(templateId) ? 'caret-down-filled' : 'caret-right-filled';
    }

    return <React.Fragment>
        <TPENavigator path={navigateURL} />
        <SpaceBetween direction="vertical" size="l">
        { canEdit && <Box float="right" key={"tpallocationView_actions"}>
            <SpaceBetween direction="horizontal" size="xs">
                <Button key="tpallocationView_actions_createTemplate" onClick={() => setShowCreateWorksheetTemplateModal(true)}>Create new template</Button>
                <Button variant="primary" key="tpallocationView_actions_createWorksheet" onClick={() => setShowCreateWorksheetModal(true)}>Create new worksheet</Button>
            </SpaceBetween>
        </Box> }
        <ColumnLayout columns={2}>
            <Container
                data-class="worksheetListContainer"
                header={
                    <Header data-class="worksheetListHeader">
                        Worksheet Templates
                    </Header>
                }
                >
                    <TPELoadingSpinner loading={isListing} loadingText="Loading">
                        {ArrayUtils.isNullOrEmpty(listWorksheetsResult?.templates)
                        ?
                            <Box textAlign="center"><span className="noDataMessage">No worksheet templates available</span></Box>
                        :
                            <div className="worksheetListContentContainer">
                                {listWorksheetsResult?.templates?.map(t => 
                                    <React.Fragment>
                                    <div className="worksheetTemplateListGrid">
                                        <Link data-class="expandCollapseIconLink" onFollow={() => toggleExpandCollapse(t.templateId)}
                                            ><Icon data-class="expandCollapseIcon" name={getIconName(t.templateId)} size="small"/>
                                        </Link>
                                        <Link data-class="worksheetLink" onFollow={() => navigateToWorksheetTemplate(t.templateId)}>{t.templateName}</Link>
                                    </div>
                                    {expandedTemplates.includes(t.templateId) && (
                                        ArrayUtils.isNullOrEmpty(t.linkedWorksheets)
                                        ?
                                            <div className="linkedWorksheetListGrid">
                                                <span className="noDataMessage">No linked worksheets</span>
                                            </div>
                                        :
                                            t.linkedWorksheets?.map(w => 
                                            <div className="linkedWorksheetListGrid">
                                                <Link data-class="worksheetLink"  onFollow={() => navigateToLinkedWorksheet(w.worksheetId)}>{w.worksheetName}</Link>
                                            </div>
                                            )
                                    )}
                                    </React.Fragment>
                                )}
                            </div>
                        }
                    </TPELoadingSpinner>
                    <CreateWorksheetModal
                        visible={showCreateWorksheetModal}
                        templates={listWorksheetsResult?.templates || []}
                        sevenSegmentLOVMap={coaSegmentsResponse || new Map<string, string[]>()}
                        isCreating={isCreatingWorksheet}
                        createError={createWorksheetError}
                        onClose={() => setShowCreateWorksheetModal(false)}
                        onSubmit={setCreateWorksheetPayload}
                    />
                    <CreateWorksheetTemplateModal
                        visible={showCreateWorksheetTemplateModal}
                        isCreating={isCreatingWorksheetTemplate}
                        createError={createWorksheetTemplateError}
                        onClose={() => setShowCreateWorksheetTemplateModal(false)}
                        onSubmit={(templateName: string) => {
                            setNewWorksheetTemplateName(templateName); 
                            setCreateNewWorksheetTemplate((new Date()).toString());
                        }}
                    />
                </Container>
                <Container 
                    data-class="worksheetListContainer"
                    header={
                        <Header data-class="worksheetListHeader">
                            Worksheets
                        </Header>
                }
                >
                    <TPELoadingSpinner loading={isListing} loadingText="Loading">
                        {ArrayUtils.isNullOrEmpty(listWorksheetsResult?.worksheets)
                        ?
                        <Box textAlign='center'><span className="noDataMessage">No worksheets available</span></Box>
                        :
                            <div className="worksheetListContentContainer">
                                {listWorksheetsResult?.worksheets?.map(w => 
                                    <div className="worksheetListGrid">
                                        <Link data-class="worksheetLink" onFollow={() => navigateToStandaloneWorksheet(w.worksheetId)}>{w.worksheetName}</Link>
                                    </div>
                                )}
                            </div>
                        }
                    </TPELoadingSpinner>
                </Container>
        </ColumnLayout>
        </SpaceBetween>
    </React.Fragment>
}