export class AgreementDetailsResult {
    agreement: Agreement;

    constructor() {
        this.agreement = {
            accountingOwner: '',
            accountingPrinciples: '',
            agreementCurrency: '',
            agreementName: '',
            agreementNumber: '',
            agreementStatus: '',
            agreementStatusCategory: '',
            businessReason: '',
            drafter: '',
            effectiveDate: 0,
            firstParty: '',
            firstPartyCode: '',
            firstPartyEntityType: '',
            firstPartyJurisdiction: '',
            justification: '',
            lastUpdateDate: 0,
            participatingParty: '',
            participatingPartyCode: '',
            participatingPartyEntityType: '',
            participatingPartyJurisdiction: '',
            recordId: '',
            secondParty: '',
            secondPartyCode: '',
            secondPartyEntityType: '',
            secondPartyJurisdiction: '',
            taxOwner: '',
            terminationDate: 0,
        };
    }
}

export interface Agreement {
    accountingOwner: string,
    accountingPrinciples: string,
    agreementCurrency: string,
    agreementName: string,
    agreementNumber: string,
    agreementStatus: string,
    agreementStatusCategory: string,
    businessReason: string,
    drafter: string,
    effectiveDate: number,
    firstParty: string,
    firstPartyCode: string,
    firstPartyEntityType: string,
    firstPartyJurisdiction: string,
    justification: string,
    lastUpdateDate: number,
    participatingParty: string,
    participatingPartyCode: string,
    participatingPartyEntityType: string,
    participatingPartyJurisdiction: string,
    recordId: string,
    secondParty: string,
    secondPartyCode: string,
    secondPartyEntityType: string,
    secondPartyJurisdiction: string,
    taxOwner: string,
    terminationDate: number,
}

