const ArrayUtils = {
    /**
     * Checks if two arrays contain the same elements regardless of order.
     * @param {*} a Array a
     * @param {*} b Array b
     * @returns false if the counts do not match for any element, true otherwise.
     */
    equalsIgnoreOrder(a: any[], b: string[]) {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter((e: any) => e === v).length;
            const bCount = b.filter((e: any) => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    },
    isNullOrEmpty(array: any[] | undefined) {
        return array == null || array.length === 0;
    },
    toArray(input:string, separator:string, returnEmptyArrayForEmptyString: boolean = true){
        if (input == null || input.trim() === ""){
            return [];
        }
        const result = input.split(separator);
        if (result.length === 1 && result[0].trim() === ""){
            return [];
        }
        return result;
    }
};

export default ArrayUtils;