import {Link} from '@amzn/awsui-components-react';
import React, { useContext, useMemo } from 'react';
import { TPERowExpandableReactTable } from '../../shared/TPERowExpandableReactTable';
import CONSTANTS from "src/utils/constants";
import { MECStatusContext } from './StatusView';
import { ACTIONS } from 'src/services/mec/status/MECStatusReducer';
import ExecutionStatusIndicator from '../../shared/ExecutionStatusIndicator';
import 'src/assets/styles/react-table.scss';
import { getPermissions } from 'src/components/AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';

export default function StatusGrid(props: {loading: boolean, setTriggerGroupDetails: (loadTime: string) => any}) {
    const { loading, setTriggerGroupDetails } = props;
    const { state, dispatch } = useContext(MECStatusContext);
    const { statusResult } = state;
    const { canEdit } = getPermissions(AppModules.MEC);

    const showGroupDetails = (groupNumber: string) => {
        dispatch(ACTIONS.SET_GROUP.withPayload(groupNumber));
        setTriggerGroupDetails((new Date()).toString());
    }

    const getColDefs = () => {
        const colDefs: any[] = [];
        Object.values(CONSTANTS.MEC_STATUS_COLUMN_HEADERS).forEach(column => {
            switch(column.ACCESSOR){
                case CONSTANTS.MEC_STATUS_COLUMN_HEADERS.STATUS.ACCESSOR: 
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => <div className="cell-text"><ExecutionStatusIndicator status={e.value} /></div>
                    })
                    break;
                case CONSTANTS.MEC_STATUS_COLUMN_HEADERS.SELECT.ACCESSOR: 
                    colDefs.push({Header: '',suppressSorting:true, accessor: column.ACCESSOR});
                    break;
                case CONSTANTS.MEC_STATUS_COLUMN_HEADERS.DRY_RUN.ACCESSOR:
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => (e.row.index == e.row.original.headerRow ?<></>:<span className="textCell cell-text-wrap">{e.value? "Yes" : (e.value === false? "No" : "")}</span>)
                    });
                    break;
                case CONSTANTS.MEC_STATUS_COLUMN_HEADERS.DAY_OR_GROUP.ACCESSOR: 
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => (e.row.index != e.row.original.headerRow ?
                            <Link className="cell-link cell-text-wrap" onFollow={() => showGroupDetails(e.value)}>{e.value}</Link> :
                            <span className="textCell cell-text-wrap">{e.value}</span>
                        )
                    });
                    break;
                default: 
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => <span className="textCell cell-text-wrap">{e.value}</span>
                    });
                    break;
            }
        });
        return colDefs;
    }

    const colDefs = useMemo(getColDefs, [statusResult, canEdit]);

    const onVisibilityToggle = (index: number) => {
        dispatch(ACTIONS.TOGGLE_ROW_VISIBILITY.withPayload(index));
    }

    const onSelectChanged = (index: number) => {
        dispatch(ACTIONS.TOGGLE_ROW_SELECTED.withPayload(index));
    }

    return (
        <div className="mecGrid">
            {statusResult && <TPERowExpandableReactTable {...{
                data: statusResult.records,
                columnDefinitions: colDefs,
                className: "mecStatusGrid",
                isSearching: loading,
                showFilters: false,
                sortable: false,
                onVisibilityToggle: onVisibilityToggle,
                onSelectChanged: onSelectChanged,
                expandingColumn: 1,
                canSelectHeaders: false,
                canSelectNonheaders: canEdit
            }} />}
        </div>
    );
}
