import TPEAction from "src/models/common/TPEAction";
import { CustomCOARowsState } from "./CustomCOARowsState";
import { CustomCOARow } from "src/models/custom-coa/CustomCOADefinition";

export const ACTIONS = Object.freeze({
    SET_COA_LOV: new TPEAction('SET_COA_LOV'),
    SET_SEARCH_PAYLOAD: new TPEAction('SET_SEARCH_PAYLOAD'),
    SET_SEARCH_RESULT: new TPEAction('SET_SEARCH_RESULT'),
    SET_CURRENT_PAGE: new TPEAction('SET_CURRENT_PAGE'),
    ADD_NEW_ROW: new TPEAction('ADD_NEW_ROW'),
    UPDATE_CUSTOM_COA_RECORD: new TPEAction('UPDATE_CUSTOM_COA_RECORD'),
});

export function customCOARowsReducuer(state: CustomCOARowsState, action: TPEAction) : CustomCOARowsState {
    switch(action.type) {
        case ACTIONS.SET_COA_LOV.type:
            return {
                ...state,
                coaLov: action.payload
            }
        case ACTIONS.SET_SEARCH_PAYLOAD.type: {
            return {
                ...state,
                searchPayload: action.payload
            }
        }
        case ACTIONS.SET_SEARCH_RESULT.type: {
            return {
                ...state,
                searchResult: action.payload
            }
        }
        case ACTIONS.SET_CURRENT_PAGE.type: {
            if (state.searchPayload != null) {
                const searchPayload = {...state.searchPayload, page: action.payload};
                return {
                    ...state,
                    searchPayload: searchPayload
                }
            } else {
                return state;
            }
        }
        case ACTIONS.ADD_NEW_ROW.type: {
            if (state.searchResult) {
                const rows = [...state.searchResult.rows, {
                    tableId: state.searchResult.tableId,
                    updatedRecord: true,
                    newRecord: true
                }];
                return {
                    ...state,
                    searchResult: {...state.searchResult, rows: rows}
                }
            } else {
                return state;
            }
        }
        case ACTIONS.UPDATE_CUSTOM_COA_RECORD.type: {
            if (state.searchResult) {
                const row: any = state.searchResult.rows[action.payload.rowIndex];
                row[action.payload.attribute] = action.payload.value;
                row.updatedRecord = true;
                if (row.newRecord === undefined) {
                    row.newRecord = false;
                }
                state.searchResult.rows[action.payload.rowIndex] = row as CustomCOARow;
                return state
            } else {
                return state
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}