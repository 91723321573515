import { Alert, Box, Button, ButtonDropdown, ColumnLayout, Container, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CustomDataTableRowsInfo from 'src/models/custom-data-tables/CustomDataTableRowsInfo';
import DynamicBreadcrumb from 'src/models/navigation/DynamicBreadcrumb';
import { GLOBAL_ACTIONS } from 'src/services/global/GlobalReducer';
import ServiceCollection from 'src/services/ServiceCollection';
import CONSTANTS from 'src/utils/constants';
import { GlobalAppContext } from '../App';
import { ACTION_TYPE, TPEBasicModal } from '../shared/TPEBasicModal';
import { TPERowSelectableReactTable } from '../shared/TPERowSelectableReactTable';


export default function DuplicateRecordsView(props: {duplicateRowsInfo: CustomDataTableRowsInfo, services: ServiceCollection, 
    handleRowSelect: (isChecked: boolean, row: any, index: number) => any,
    handleBulkSelect: (selectNew: boolean) => any,
    handleSave: () => any}) {

    const { duplicateRowsInfo, services, handleRowSelect, handleBulkSelect, handleSave } = props;

    const { globalDispatch } = useContext(GlobalAppContext);

    const { pathname } = useLocation();

    const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);

    useEffect(() => {
        if (duplicateRowsInfo != null) {
            globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, 
                CONSTANTS.PAGE_NAV.CUSTOM_TABLES.TITLE, duplicateRowsInfo.tableName, "View duplicate rows and resolve conflicts.")));
        }
    }, [duplicateRowsInfo])

    const bulkSelect = (action: string) => {
        handleBulkSelect(action == 'selectNew');
    }

    const getColDefs = () => {
        const colDefs: any[] = [];
        duplicateRowsInfo.columnDefinitions.forEach(x => {
            colDefs.push({
                ...x,
                Header: (e: any) => <div className="cell-text">{x.Header}</div>,
                Cell: (e: any) => <div className="textCell">{e.value}</div>
            });
        });
        return colDefs;
   }

   const colDefs = useMemo(getColDefs, [duplicateRowsInfo.records]);

    return <SpaceBetween direction="vertical" size="m">
                <Box>
                    <Alert type="info">
                        Some of the rows you are trying to save conflict with existing rows in the table. Please select which rows you would like to keep in the table.
                        Note that taking no action on this page will keep the existing rows and discard the new rows.
                    </Alert>
                </Box>
                <Container data-class="containerWithHeaderAndGrid">
                    <div>
                        <div className="customTableTableHeader">
                            <ColumnLayout columns={2} data-class="fullColumnLayout">
                                <Box variant="h2"><span>{duplicateRowsInfo.tableName} - Duplicate rows</span></Box>
                                <Box float="right">
                                    <SpaceBetween direction="horizontal" size="m">
                                        <ButtonDropdown
                                            items={[
                                                {text: "Select all existing records", id: "selectExisting"},
                                                {text: "Select all new records", id: "selectNew"},
                                            ]}
                                            variant="normal"
                                            onItemClick={(e) => bulkSelect(e.detail.id)}
                                        >
                                            Select
                                        </ButtonDropdown>
                                        <Button variant="primary" onClick={() => setShowSaveConfirmModal(true)}>Keep selected</Button>
                                    </SpaceBetween>
                                </Box>
                            </ColumnLayout>
                        </div>
                        <TPEBasicModal
                            className="duplicateRecordSaveConfirmModal"
                            visible={showSaveConfirmModal}
                            action={ACTION_TYPE.CONFIRM_CANCEL}
                            title="Keep Selected Record(s)"
                            onCancel={() => setShowSaveConfirmModal(false)}
                            onConfirm={() => {setShowSaveConfirmModal(false); handleSave();}}
                        >
                            Are you sure you want to keep the selected records and discard unselected records?
                            <Box color="text-body-secondary" padding={{ top: "m" }}>
                                When you click "Confirm", the records that were not selected will be discarded and cannot be retrieved.
                            </Box>
                        </TPEBasicModal>
                        <div className="tableContainer">
                            <div className="customDataTableRecordsGrid">
                                <TPERowSelectableReactTable {...{ 
                                    data: duplicateRowsInfo.records, 
                                    columnDefinitions: colDefs, 
                                    className: "nonGridTable customDataTableRecordsGrid",
                                    sortable: false,
                                    onRowClicked:handleRowSelect, 
                                    allowMultipleRowSelection: true,
                                    alternateBackgroundColor: true,
                                    alternateCount: 2,
                                    highlightSelected: false
                                }} />
                            </div>
                        </div>
                    </div>
                </Container>
            </SpaceBetween>
}