import React from 'react';
import './Calculations.scss';
import { Link } from '@amzn/awsui-components-react/polaris';
import TPENavigator from '../TPENavigator';
import { AppModules } from 'src/models/permissions/RolePermissions';
import { getPermissions } from '../AppPermissions';
import CONSTANTS from 'src/utils/constants';

export default function CalculationsLanding() {
  const [ navigateURL, setNavigateURL ] = React.useState('');
  const { canView: canViewCreateNewCalculation } = getPermissions(AppModules.CREATE_NEW_CALCULATION);
  const { canView: canViewCreateNewICRSCalculation } = getPermissions(AppModules.CREATE_NEW_ICRS_CALCULATION);
  const { canView: canViewSearchCalculations } = getPermissions(AppModules.SEARCH_CALCULATION);
  const { canView: canViewBulkLink } = getPermissions(AppModules.BULK_LINK);
  const { canView: canViewBulkClone } = getPermissions(AppModules.BULK_CLONE);
  const { canView: canViewCustomDataTables } = getPermissions(AppModules.CUSTOM_DATA_TABLES);
  const { canView: canViewCustomCOA } = getPermissions(AppModules.CUSTOM_COA);
  const { canView: canViewTPAllocation } = getPermissions(AppModules.TP_ALLOCATION);
  const { canView: canViewBulkActions } = getPermissions(AppModules.BULK_ACTIONS);

  return (
    <div className="cardContainer">
      <TPENavigator path={navigateURL}/>
      { canViewCreateNewCalculation && <Link onFollow={() => setNavigateURL('/calculations/builder/new')}><img className="cardImage" src="/assets/images/tpe-calc-create.svg" /></Link> }
      { canViewCreateNewICRSCalculation && <Link onFollow={() => setNavigateURL('/icrs-calculations/builder/new')} data-class="relative-positioning">
        <img className="cardImage" src="/assets/images/tpe-calc-create.svg" />
        <span className='icrsLabel'>ICRS</span>
      </Link> }
      {/* canViewSearchCalculations && <Link onFollow={() => setNavigateURL('/calculations/search')}><img className="cardImage" src="/assets/images/tpe-calc-search.svg" /></Link> */}
      { canViewBulkClone && <Link onFollow={() => setNavigateURL('/calculations/clone')}><img className="cardImage" src="/assets/images/tpe-calc-clone.svg" /></Link> }
      { canViewBulkLink && <Link onFollow={() => setNavigateURL('/calculations/link')}><img className="cardImage" src="/assets/images/tpe-calc-link.svg" /></Link> }
      { canViewCustomDataTables && <Link onFollow={() => setNavigateURL('/calculations/customDataTables')}><img className="cardImage" src="/assets/images/tpe-calc-cdt.svg" /></Link> }
      { canViewCustomCOA && <Link onFollow={() => setNavigateURL('/calculations/customCoa')}><img className="cardImage" src="/assets/images/tpe-calc-coa.svg" /></Link> }
      { canViewTPAllocation && <Link onFollow={() => setNavigateURL('/calculations/tpAllocation')}><img className="cardImage" src="/assets/images/tpe-calc-tp-allocations.svg" /></Link> }
      { canViewBulkActions && 
        <Link onFollow={() => setNavigateURL('/calculations/bulkActions')} data-class="relative-positioning">
          <div className="bulkActionCardImage">
            <span>Bulk Actions</span>
          </div>
        </Link>
      }
    </div>
  )
}
