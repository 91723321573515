import React, { useEffect, useState } from "react";
import { PlaceholdersContext } from "../shared/PlaceholdersContainer";
import { FormField, SpaceBetween, Grid } from "@amzn/awsui-components-react";
import Placeholder from "../../../models/tp-allocation/Placeholder"
import StringUtils from "src/utils/stringUtils";
import { ACTIONS } from "src/services/tp-allocation/placeholders/PlaceholdersReducer";
import { SavingStatus } from "src/models/common/SavingStatus";
import PlaceholderAutosuggest from "../shared/PlaceholderAutosuggest";



function PlaceholderCell(props:{placeholder:Placeholder, coaLov: string[], onEditorBlur: (p:Placeholder) => void}){
    const { placeholder, coaLov, onEditorBlur } = props;
    const [value, setValue] = React.useState(StringUtils.isNullOrEmpty(placeholder.value)? placeholder.placeholderName : `${placeholder.placeholderName}: ${placeholder.value}`);
    const [originalSavingStatus] = useState(placeholder.savingStatus);
    
    function handleAutosuggestBlur(ph: Placeholder, value: string) {
        ph.value = value;
        onEditorBlur(ph);
    }

    return <div className="placeholderCell">            
            <div className="placeholderValueEditor">
                <FormField label={placeholder.placeholderName} errorText={placeholder.error}>
                    <PlaceholderAutosuggest 
                        placeholder={placeholder} 
                        lov={coaLov} 
                        inputPlaceholder={`Enter ${placeholder.coaSegment} code`}
                        onBlur={handleAutosuggestBlur} 
                        onChange={(value:string) => {
                            placeholder.error = '';                            
                            placeholder.savingStatus = 
                            // If no change
                            value == placeholder.value? 
                                originalSavingStatus :  
                                SavingStatus.Unsaved;
                        }} />
                </FormField>
            </div>
        </div>
}

export default function LinkedWorksheetPlaceholdersGrid(props: {placeholdersMap: Map<string, Placeholder[]>, sevenSegmentLOVMap: Map<string,string[]>}){
    const { placeholdersDispatch, services } = React.useContext(PlaceholdersContext);
    const { placeholdersMap, sevenSegmentLOVMap } = props;
    const [localPlaceholderMap, setLocalPlaceholderMap] = useState(new Map() as Map<string, Placeholder[]>);
    const [savePlaceholderPayload, setSavePlaceholderPayload] = useState(undefined as Placeholder | undefined);
    const [savePlaceholderResult, isSavingPlaceholder, savePlaceholderError] = services.tpAllocationPlaceholdersService.saveTemplatePlaceholder(savePlaceholderPayload);

    useEffect(() => {
        setLocalPlaceholderMap(placeholdersMap);
    }, [placeholdersMap])
    
    useEffect(() => {
        if (!isSavingPlaceholder || savePlaceholderPayload == null) {
            return;
        }
        savePlaceholderPayload.savingStatus = isSavingPlaceholder? SavingStatus.Saving : savePlaceholderPayload.savingStatus;
        placeholdersDispatch(ACTIONS.REFRESH_PLACEHOLDER.withPayload({coaSegment: savePlaceholderPayload.coaSegment, placeholder: savePlaceholderPayload}))
    }, [isSavingPlaceholder])
    
    useEffect(() => {
        if (savePlaceholderResult == null || savePlaceholderPayload == null) {
            return;
        }
        savePlaceholderResult.savingStatus = SavingStatus.Saved;
        placeholdersDispatch(ACTIONS.REFRESH_PLACEHOLDER.withPayload({coaSegment: savePlaceholderPayload.coaSegment, placeholder: savePlaceholderPayload}))
    }, [savePlaceholderResult])
    
    useEffect(() => {
        if (StringUtils.isNullOrEmpty(savePlaceholderError) || savePlaceholderPayload == null) {
            return;
        }
        savePlaceholderPayload.savingStatus = SavingStatus.Unsaved;
        savePlaceholderPayload.error = savePlaceholderError.replace(`${savePlaceholderPayload.placeholderId}:`,"");
        placeholdersDispatch(ACTIONS.REFRESH_PLACEHOLDER.withPayload({coaSegment: savePlaceholderPayload.coaSegment, placeholder: savePlaceholderPayload}))
    }, [savePlaceholderError])

    
    const onPlaceholderEditorBlur = (placeholder: Placeholder) => {
        if (placeholder.savingStatus == null || placeholder.savingStatus === SavingStatus.Saved){
            return;
        }
        if (placeholder.value?.trim() === ''){
            placeholdersDispatch(ACTIONS.UPDATE_PLACEHOLDER_ERROR.withPayload({coaSegment: placeholder.coaSegment, placeholderId: placeholder.placeholderId, error: `${placeholder.placeholderName} value is required` }))
            return;
        }
        
        setSavePlaceholderPayload({...placeholder});
    }

    return (
        <SpaceBetween direction="vertical" size="m">
            {Array.from(localPlaceholderMap.keys()).map(coaSegment =>
            <div className="wizardBoxContentContainer">
                <div className="placeholderList">
                    <div className="placeholderCoaHeader">{coaSegment}</div>                    
                        {localPlaceholderMap.get(coaSegment)?.map(p => <PlaceholderCell 
                            placeholder={p} 
                            coaLov={sevenSegmentLOVMap.get(coaSegment) || []}
                            onEditorBlur={onPlaceholderEditorBlur} />
                        )}                        
                    </div>
                </div>
            )}
        </SpaceBetween>
    );
}