import {initialState, ReportsState} from "src/services/reports/ReportsState";
import React, {useEffect} from "react";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import {reportsReducer} from "src/services/reports/ReportsReducer";
import {Box, Button, Container, Icon, Pagination, SpaceBetween} from "@amzn/awsui-components-react";
import TaxAuditRecordFilterSelector from "src/components/reports/taxAuditRecord/TaxAuditRecordFilterSelector";
import {Grid} from "@amzn/awsui-components-react/polaris";
import {
    TaxAuditRecordsRequest,
    TaxAuditRecordSummary
} from "src/models/reports/TaxAuditRecords";
import TaxAuditRecordTableGrid from "src/components/reports/taxAuditRecord/TaxAuditRecordTableGrid";
import {TAX_AUDIT_RECORDS_PAGE_LIMIT} from "src/utils/constants";

export type ContextType = {
    state: ReportsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const TaxAuditRecordsProvider = (props: { state: ReportsState, dispatch: React.Dispatch<TPEAction>, services: ServiceCollection, children: JSX.Element }) => {
    const {state, dispatch, services, children} = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);

    return (
        <TaxAuditRecordsContext.Provider value={providerValue}>
            {children}
        </TaxAuditRecordsContext.Provider>
    );
}

export const TaxAuditRecordsContext = React.createContext(null as unknown as ContextType);

export default function TaxAuditRecordsView(props: { services: ServiceCollection }) {
    const {services} = props;
    const [state, dispatch] = useReducerWithLogger(reportsReducer, initialState);
    const [resetNeededSignal, setResetNeededSignal] = React.useState(null as unknown as string);
    const [taxAuditRecordList, setTaxAuditRecordList] = React.useState([] as TaxAuditRecordSummary[]);
    const [cache, setCache] = React.useState<{[key: number]: TaxAuditRecordSummary[]}>({})
    const [selectedAccountingPeriod, setSelectedAccountingPeriod] = React.useState(null as null | any);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [loadedPage, setLoadedPage] = React.useState(1);
    const [searchResultsIsVisible, setSearchResultsIsVisible] = React.useState(false);
    const [selectedCLI, setSelectedCLI] = React.useState(null as null | any);
    const [taxAuditRecordsRequest, setTaxAuditRecordsRequest] = React.useState(null as unknown as TaxAuditRecordsRequest);
    const [taxAuditRecordsResponse, taxAuditRecordsLoading, taxAuditRecordsError] = services.reportsService.searchTaxAuditRecords(taxAuditRecordsRequest);

    useEffect(() => {
        if (!taxAuditRecordsError) {
            return;
        }
        services.messageService.showErrorAutoDismissBanner(taxAuditRecordsError);
    }, [taxAuditRecordsError])

    useEffect(() => {
        if (taxAuditRecordsResponse == null) {
            return;
        }
        setCache({ ...cache, [selectedPage]: taxAuditRecordsResponse.TaxAuditRecordList})
        setTaxAuditRecordList(taxAuditRecordsResponse.TaxAuditRecordList)
    }, [taxAuditRecordsResponse])

    const fetchTaxAuditRecords = (startKey : string | null) => {
        if (!!selectedAccountingPeriod && !!selectedCLI) {
            setTaxAuditRecordsRequest({
                period: selectedAccountingPeriod,
                cli: selectedCLI,
                limit: TAX_AUDIT_RECORDS_PAGE_LIMIT,
                startKey: startKey
            })
        }
    }

    const handleRefresh = () => {
        setCache({})
        setLoadedPage(1);
        setSelectedPage(1);
        setSearchResultsIsVisible(true)
        fetchTaxAuditRecords(null)
    };

    const onResetClicked = () => {
        setCache({})
        setSearchResultsIsVisible(false)
        setSelectedAccountingPeriod(null)
        setResetNeededSignal(new Date().toString());
    };

    return (<TaxAuditRecordsProvider services={services} state={state} dispatch={dispatch}>
        <Container className="polaris-content-container">
            <SpaceBetween direction="vertical" size="xxl">
                <Grid gridDefinition={[{colspan: 8}, {colspan: 4}]}>
                    <TaxAuditRecordFilterSelector
                        selectedAccountingPeriod={selectedAccountingPeriod}
                        services={services}
                        resetNeeded={resetNeededSignal}
                        onAccountingPeriodSelected={(period) => setSelectedAccountingPeriod(period)}
                        onCalculationLineSelected={(cli) => setSelectedCLI(cli)}
                    />
                    <Box float="right" padding={{top: "xl"}}>
                        <SpaceBetween direction="horizontal" size="s">
                            <Button onClick={onResetClicked}>Reset</Button>
                            <Button
                                className={"applyCriteriaButton"}
                                onClick={handleRefresh}
                                disabled = { (!selectedCLI || !selectedAccountingPeriod || taxAuditRecordsLoading) }
                                variant="primary"
                            >
                                {taxAuditRecordsLoading ? 'Applying Criteria...' : 'Apply Criteria' }
                            </Button>
                        </SpaceBetween>
                    </Box>
                </Grid>
                {
                    searchResultsIsVisible &&
                        <div>
                            <div className="tableHeader">
                                <h3>Tax Audit Records</h3>
                            {taxAuditRecordsResponse != null && taxAuditRecordsResponse.totalRecordsCount > 0 &&
                                <Box float="right">
                                    <Pagination
                                        currentPageIndex={selectedPage}
                                        pagesCount={loadedPage}
                                        onNextPageClick={({ detail }) => {
                                            setSelectedPage(detail.requestedPageIndex);
                                            if (cache.hasOwnProperty(detail.requestedPageIndex)) {
                                                setTaxAuditRecordList(cache[detail.requestedPageIndex]);
                                            } else if (taxAuditRecordsResponse.lastEvaluatedKey) {
                                                setLoadedPage(loadedPage + 1);
                                                fetchTaxAuditRecords(taxAuditRecordsResponse.lastEvaluatedKey);
                                            }
                                        }}
                                        onChange={({ detail }) => {
                                            setSelectedPage(detail.currentPageIndex)
                                            setTaxAuditRecordList(cache[detail.currentPageIndex])
                                        }}
                                        onPreviousPageClick={({ detail }) => {
                                            setSelectedPage(detail.requestedPageIndex)
                                            setTaxAuditRecordList(cache[detail.requestedPageIndex])
                                        }}
                                        openEnd={!!taxAuditRecordsResponse.lastEvaluatedKey}
                                    />
                                </Box>
                            }
                            </div>
                            <div className="tableContainer">
                                <TaxAuditRecordTableGrid
                                    key="taxAuditRecordTableGrid"
                                    loading={taxAuditRecordsLoading}
                                    taxAuditRecordTables = {taxAuditRecordList}
                                />
                            </div>
                        </div>
                }
                </SpaceBetween>
        </Container>
    </TaxAuditRecordsProvider>)

}