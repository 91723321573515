import {TaxAuditRecordDetailsResponse} from "src/models/reports/TaxAuditRecordDetails";
import {Box, Container, Header} from "@amzn/awsui-components-react";
import {TPELoadingSpinner} from "src/components/shared/TPELoadingSpinner";
import React from "react";
import {TPEReactTable} from "src/components/shared/TPEReactTable";

export default function TaxAuditRecordEntityDetails(props: { taxAuditRecordDetails: TaxAuditRecordDetailsResponse, loading: boolean}) {
    const {taxAuditRecordDetails, loading} = props;

    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "attributeName",
            Header: "",
            Cell: (e: any) =><div className="cell-text">{e.value}</div>
        },
        {
            accessor: "provider",
            Header: <div className="cell-text">Provider</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "recipient",
            Header: <div className="cell-text">Recipient</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        }], []);

    return <div>
            <TPELoadingSpinner loading={loading}>
                {taxAuditRecordDetails != null &&
                    <Container className="polaris-content-container-tar-report-entity-details">
                    <div className="tarReportDetailsTableHeader">
                    <Box variant="h2"><span>Entity Details</span></Box>
                    </div>
                    <div className="tableContainer">
                    <TPEReactTable
                        className="taxAuditRecordEntityDetailsGrid"
                        columnDefinitions={columnDefinitions}
                        data={[
                            {
                                attributeName: "Gems Id",
                                provider: taxAuditRecordDetails.provGemsID,
                                recipient: taxAuditRecordDetails.provGemsID,
                            },
                            {
                                attributeName: "Parent Gems Id",
                                provider: taxAuditRecordDetails.provParentGemsID,
                                recipient: taxAuditRecordDetails.recParentGemsID,
                            },
                            {
                                attributeName: "Company code",
                                provider: taxAuditRecordDetails.providerCompanyCode,
                                recipient: taxAuditRecordDetails.recipientCompanyCode,
                            },
                            {
                                attributeName: "Indirect Taxable Status",
                                provider: taxAuditRecordDetails.provIntercoTaxableStatus,
                                recipient: taxAuditRecordDetails.recIntercoTaxableStatus,
                            },
                            {
                                attributeName: "Special Tax Arrangement",
                                provider: taxAuditRecordDetails.provSpecialTaxArrangement,
                                recipient: taxAuditRecordDetails.recSpecialTaxArrangement
                            },
                            {
                                attributeName: "FTZ/SEZStatus",
                                provider: taxAuditRecordDetails.provIntercoFTZSEZStatus,
                                recipient: taxAuditRecordDetails.recIntercoFTZSEZStatus
                            },
                            {
                                attributeName: "Indirect Type of Company",
                                provider: taxAuditRecordDetails.provIntercoCompanyType,
                                recipient: taxAuditRecordDetails.recIntercoCompanyType
                            },
                            {
                                attributeName: "Principal Place Of Business",
                                provider: taxAuditRecordDetails.provPrincipalPlaceOfBusiness,
                                recipient: taxAuditRecordDetails.recPrincipalPlaceOfBusiness
                            },
                            {
                                attributeName: "Parent Principal Place Of Business",
                                provider: taxAuditRecordDetails.provParentPrincipalPlaceOfBusiness,
                                recipient: taxAuditRecordDetails.recParentPrincipalPlaceOfBusiness
                            },
                            {
                                attributeName: "Parent Company Code",
                                provider: taxAuditRecordDetails.provParentCompanyCode,
                                recipient: taxAuditRecordDetails.recParentCompanyCode
                            },
                            {
                                attributeName: "Interco Tax Entity Id",
                                provider: taxAuditRecordDetails.provIntercomapTaxEntityId,
                                recipient: taxAuditRecordDetails.recIntercomapTaxEntityId
                            },
                            {
                                attributeName: "Interco Parent Tax Entity Id",
                                provider: taxAuditRecordDetails.provIntercomapParentTaxEntityId,
                                recipient: taxAuditRecordDetails.recIntercomapParentTaxEntityId
                            },
                            {
                                attributeName: "Location Code",
                                provider: taxAuditRecordDetails.provLocationCode,
                                recipient: taxAuditRecordDetails.recLocationCode
                            },
                            {
                                attributeName: "Location Code Country",
                                provider: taxAuditRecordDetails.provLocationCodeCountry,
                                recipient: taxAuditRecordDetails.recLocationCodeCountry
                            },
                            {
                                attributeName: "Location Code State",
                                provider: taxAuditRecordDetails.provLocationCodeState,
                                recipient: taxAuditRecordDetails.recLocationCodeState
                            },
                        ]}
                        sortable={false}
                    />
                    </div>
                    </Container>
                }
            </TPELoadingSpinner>
        </div>
}