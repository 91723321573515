import React, {useContext} from 'react';
import 'src/assets/styles/react-table.scss';
import 'src/components/CustomAppLayout.scss';
import {DefaultColumnFilter, TPEReactTable} from "src/components/shared/TPEReactTable";
import {IndirectTaxChangeCLIStatus} from "src/components/reports/ReportStatus";
import {PreValidationReportsContext} from "src/components/reports/prevalidation/ReportsTabbedView";

export default function PreValidationIndirectTaxTableGrid(props: { loading: boolean }) {
    const {loading} = props;
    const {state} = useContext(PreValidationReportsContext);
    const {indirectTaxChangeRecordList} = state;


    const columnDefinitions = React.useMemo(() => [
            {
                accessor: "calculationNumber",
                Header: (e: any) => <div className="cell-text">Calculation Number</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "group",
                Header: (e: any) => <div className="cell-text">Group</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "prevMonthTaxRate",
                Header: (e: any) => <div className="cell-text">Old Rate</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "curMonthTaxRate",
                Header: (e: any) => <div className="cell-text">Revised Rate</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "comments",
                Header: (e: any) => <div className="cell-text">Comments</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "workbookName",
                Header: (e: any) => <div className="cell-text">Workbook Name</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "calcAssignee",
                Header: (e: any) => <div className="cell-text">Calc Assignee</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "accountingOwner",
                Header: (e: any) => <div className="cell-text">Accounting Owner</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "tpTaxOwner",
                Header: (e: any) => <div className="cell-text">TP Tax Owner</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "status",
                Header: (e: any) => <div className="cell-text">Status</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{<IndirectTaxChangeCLIStatus indirectTaxChangeCLIStatus={e.value} /> } </div>
            }]
        , []);

    return (
        <div className="preValidationReportGrid">
            {indirectTaxChangeRecordList && indirectTaxChangeRecordList.length > 0 && (<TPEReactTable {...{
                data: indirectTaxChangeRecordList,
                columnDefinitions: columnDefinitions,
                className: "nonGridTable preValidationReportGrid",
                isSearching: loading,
                sortable: false,
                showFilters: false
            }}/>)}
        </div>
    )}