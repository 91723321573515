import React from "react";
import { ExpressionPart, ExpressionPartTypes } from "../../models/calculations/CalculationDetailsResult";
import { SpaceBetween } from '@amzn/awsui-components-react';

export function renderExpressionParts (expressionParts: ExpressionPart[]) {
    const lines: ExpressionPart[][] = [];
    let line: ExpressionPart[] = [];
    expressionParts.forEach(part => {
        if (part.type === ExpressionPartTypes.NEW_LINE){
            lines.push(line);
            line = [];
        }
        else {
            line.push(part);
        }
    })
    lines.push(line);
    return lines.map(line => 
        <SpaceBetween size={"xxxs"} direction={"vertical"}>{renderExpressionLine(line)}</SpaceBetween>
    );
}
export function renderExpressionLine(lineParts: ExpressionPart[]){
    return <SpaceBetween size={"xxxs"} direction={"horizontal"}>
        {lineParts.map(
            (part, index) => <div key={index} className={`calcExpression-${part.type}`}>{part.displayValue || part.value}</div>
        )}
    </SpaceBetween>;
}