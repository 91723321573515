import React, { useEffect, useState } from 'react';
import { TPAllocationContext } from "src/services/tp-allocation/TPAllocationContext";
import { TPAllocationState } from "src/services/tp-allocation/TPAllocationState";
import { tpAllocationReducer, ACTIONS } from '../../../services/tp-allocation/TPAllocationReducer';
import CONSTANTS from 'src/utils/constants';

export default function WorksheetValidator() {
    const { services, tpAllocationState, tpAllocationDispatch } = React.useContext(TPAllocationContext);
    const { worksheetNeedsValidation, worksheet } = tpAllocationState;
    const [validateWorksheetId, setValidateWorksheetId] = useState(null as string | null);

    const [validateWorksheetResult, isValidating, validateWorksheetError] = services.tpAllocationService.validateWorksheet(validateWorksheetId);

    useEffect(() => {
        tpAllocationDispatch(ACTIONS.SET_IS_VALIDATING.withPayload(isValidating))
    }, [isValidating])
    
    useEffect(() => {
        if (worksheetNeedsValidation && worksheet?.worksheetId != null){
            setValidateWorksheetId(worksheet.worksheetId);
        }
    }, [worksheetNeedsValidation])
    
    useEffect(() => {
        if (validateWorksheetResult == null){
            return;
        }
        setValidateWorksheetId(null);
        tpAllocationDispatch(ACTIONS.SET_WORKSHEET_NEEDS_VALIDATION.withPayload(false));
        if (validateWorksheetResult.valid){
            tpAllocationDispatch(ACTIONS.SET_TOTALS_ARE_VALID.withPayload(true));
            tpAllocationDispatch(ACTIONS.SET_GROUP_FORMULAS_ARE_VALID.withPayload(true));
        }
        else {
            tpAllocationDispatch(ACTIONS.SET_VALIDATION_ERRORS.withPayload(validateWorksheetResult.validationErrors));
        }
    }, [validateWorksheetResult])

    useEffect(() => {
        setValidateWorksheetId(null);
    }, [validateWorksheetError])

    return <></>;
}