import React from 'react';
import { Box, Button, PieChart } from '@amzn/awsui-components-react';

interface PieChartData {
  title: string;
  percentage: number;
  value: number;
}

interface PieChartProps {
  data: PieChartData[];
  totalUnits: number;
  ariaDescription: string;
  ariaLabel: string;
  emptyDataDescription: string;
  noMatchDataDescription: string;
}

const TPPieChart: React.FC<PieChartProps> = ({
  data,
  totalUnits,
  ariaDescription,
  ariaLabel,
  emptyDataDescription,
  noMatchDataDescription,
}) => {
  return (
    <PieChart
      data={data}
      segmentDescription={(datum, sum) =>
        `${datum.value} units, ${((datum.value / sum) * 100).toFixed(0)}%`
      }
      ariaDescription={ariaDescription}
      ariaLabel={ariaLabel}
      hideFilter
      hideLegend
      innerMetricDescription="total units"
      innerMetricValue={totalUnits.toString()}
      size="large"
      variant="donut"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box variant="p" color="inherit">
            {emptyDataDescription}
          </Box>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box variant="p" color="inherit">
            {noMatchDataDescription}
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />
  );
};

export default TPPieChart;