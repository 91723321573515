import { CalcBuilderComment } from 'src/models/calculation-builder/CalcBuilderComment';
import CalculationStep from 'src/models/calculation-builder/CalculationStep';
import CalculationStepsRunItem from 'src/models/calculation-builder/CalculationStepsRunItem';
import ArrayUtils from 'src/utils/arrayUtils';
import CONSTANTS from 'src/utils/constants';
import TPEAction from '../../models/common/TPEAction';
import ReadOnlyCalculationService from '../calculations/ReadOnlyCalculationService';
import { CalculationStepsState, initialState } from './CalculationStepsState';
import { SavingStatus } from 'src/models/common/SavingStatus';
import DateUtils from 'src/utils/dateUtils';

/**
 * List here the actions supported by this reducer
 */
 export const ACTIONS = Object.freeze({
    RESET: new TPEAction('RESET'),
    SET_CALCULATION_STEPS: new TPEAction('SET_CALCULATION_STEPS'),
    SET_STEP_BEING_EDITED: new TPEAction('SET_STEP_BEING_EDITED'),
    SET_STEP_COMMENTS: new TPEAction('SET_STEP_COMMENTS'),
    SET_TPE_ENTRIES: new TPEAction('SET_TPE_ENTRIES'),
    SET_EDITOR_SUGGESTIONS: new TPEAction('SET_EDITOR_SUGGESTIONS'),
    SET_EDITOR_CARET_POSITION: new TPEAction('SET_EDITOR_CARET_POSITION'),
    APPEND_TO_FORMULA_EDITOR: new TPEAction('APPEND_TO_FORMULA_EDITOR'),
    ADD_STEP: new TPEAction('ADD_STEP'),
    ADD_STEP_BEFORE: new TPEAction('ADD_STEP_BEFORE'),
    ADD_STEP_AFTER: new TPEAction('ADD_STEP_AFTER'),
    REMOVE_STEP: new TPEAction('REMOVE_STEP'),
    DUPLICATE_STEP: new TPEAction('DUPLICATE_STEP'),
    ADD_SUBSTEP: new TPEAction('ADD_SUBSTEP'),
    DUPLICATE_SUBSTEP: new TPEAction('DUPLICATE_SUBSTEP'),
    SAVE_STEPS: new TPEAction('SAVE_STEPS'),
    TOGGLE_SHOW_VALUES: new TPEAction('TOGGLE_SHOW_VALUES'),
    UPDATE_CURRENT_STEP: new TPEAction('UPDATE_CURRENT_STEP'),
    EXIT_EDIT_MODE: new TPEAction('EXIT_EDIT_MODE'),
    SET_EXPANDED_STATE: new TPEAction('SET_EXPANDED_STATE'),
    SET_REMOVE_STEP_MODAL_PAYLOAD: new TPEAction('SET_REMOVE_STEP_MODAL_PAYLOAD'),
    SET_NEW_COMMENT_PAYLOAD: new TPEAction('SET_NEW_COMMENT_PAYLOAD'),
    SET_ADD_COMMENT_PAYLOAD: new TPEAction('SET_ADD_COMMENT_PAYLOAD'),
    REFRESH_STEP: new TPEAction('REFRESH_STEP'),
    SET_RUN_VALUES: new TPEAction('SET_RUN_VALUES'),
    ADD_STEP_COMMENT: new TPEAction('ADD_STEP_COMMENT'),
    ADD_APPLIED_TPE_ENTRY: new TPEAction('ADD_APPLIED_TPE_ENTRY'),
    SET_STEPS_ARE_SAVED_AND_VALID: new TPEAction('SET_STEPS_ARE_SAVED_AND_VALID'),
    SET_COMMENTS_HISTORY: new TPEAction('SET_COMMENTS_HISTORY'),
    CLEAR_UNSAVED_COMMENTS: new TPEAction('CLEAR_UNSAVED_COMMENTS'),
    SHOW_STANDARD_ALLOCATION_MODAL: new TPEAction('SHOW_STANDARD_ALLOCATION_MODAL'),
    SHOW_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL: new TPEAction('SHOW_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL'),
    HIDE_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL: new TPEAction('HIDE_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL'),
    DELETE_STANDARD_ALLOCATION_RECORD: new TPEAction('DELETE_STANDARD_ALLOCATION_RECORD'),
    SET_STEP_ID_FOR_ACTION: new TPEAction('SET_STEP_ID_FOR_ACTION'),
});

/**  
* This function is responsible for updating the state based on action type
* @param state The current dashboard state
* @param action The current dispatched action 
*/
export function calculationStepsReducer(state: CalculationStepsState, action: TPEAction) : CalculationStepsState {
    switch (action.type) {
        case ACTIONS.RESET.type:
            return {...initialState};
        case ACTIONS.SET_CALCULATION_STEPS.type: {
            const steps = action.payload;
            const flatSteps = ReadOnlyCalculationService.flattenSteps(steps);
            flatSteps.forEach(s => {
                s.formulaOutput = s.value as any as string;
                s.outputBreakdown = s.valueBreakdown;
                s.lastModifiedUser = s.lastModifiedUser == null ? 'N/A' : s.lastModifiedUser
                s.lastModifiedDate = s.lastModifiedDate == null ? 'N/A' : DateUtils.formatTimestamp(s.lastModifiedDate)
            })
            return {
                ...state,
                error: '',
                isLoading: false,
                steps: steps,
                deleteStepPayload: undefined,
                updateStepPayload: undefined,
            };
        }
        case ACTIONS.SET_STEP_BEING_EDITED.type: {
            const { steps } = state;

            if (action.payload == null){
                steps.forEach(x => x.isBeingEdited = false);
                return {
                    ...state,
                    stepBeingEdited: undefined,
                    steps: [...steps],
                };
            }
            
            const allSteps = ReadOnlyCalculationService.flattenSteps(steps);
            allSteps.forEach(x => x.isBeingEdited = false);
            const editingRecord = allSteps.find(x => x.stepId === action.payload.stepId);
            if (editingRecord){
                editingRecord.isBeingEdited = true;                
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                stepBeingEdited: editingRecord,
                steps: [...steps],
            };
        }
        case ACTIONS.EXIT_EDIT_MODE.type: {
            const { steps } = state;
            steps.forEach(x => x.isBeingEdited = false);
            return {
                ...state,
                error: '',
                isLoading: false,
                stepBeingEdited: undefined,
                steps: [...steps],
            };
        }
        case ACTIONS.SET_TPE_ENTRIES.type:
            return {
                ...state,
                error: '',
                isLoading: false,
                tpeEntries: action.payload
            };
        case ACTIONS.ADD_APPLIED_TPE_ENTRY.type:
            let {tpeEntriesApplied} = state;
            tpeEntriesApplied.add(action.payload);
            return {
                ...state,
                error: '',
                isLoading: false,
                tpeEntriesApplied: tpeEntriesApplied,
            };
        case ACTIONS.SET_EDITOR_SUGGESTIONS.type: {
            return {
                ...state,
                error: '',
                isLoading: false,
                suggestionsList: action.payload,
            }
        }
        case ACTIONS.ADD_STEP.type: {
            const { steps } = state;
            steps.push(action.payload)
            return {
                ...state,
                error: '',
                isLoading: false,
                stepsAreSavedAndValid: false,
                steps: [...steps],
            }
        }
        case ACTIONS.ADD_SUBSTEP.type: {
            const { steps } = state;
            const { parentStep, newSubstep } = action.payload;
            const step = steps.find(x => x.stepId === parentStep.stepId);
            if (step == null){
                return {
                    ...state,
                    error: 'No calculation step found. The substep could not be added.',
                    isLoading: false,
                };
            }
            if (!step.subRows){
                step.subRows = [];
            }
            newSubstep.parentId = step.stepId
            step.subRows.push(newSubstep);
            return {
                ...state,
                error: '',
                stepsAreSavedAndValid: false,
                isLoading: false,
                steps: [...steps],
            }
        }
        case ACTIONS.ADD_STEP_BEFORE.type: {
            const { steps } = state;
            const { index, newStep } = action.payload;
            const stepsList = (newStep.isSubstep? steps.find(x => x.stepId === newStep.parentId)?.subRows : steps) || [];
            const allStepsCount = ReadOnlyCalculationService.flattenSteps(steps).length;
            newStep.id = `${allStepsCount + 1}`;
            
            // Inserting new item
            stepsList.splice(index === 0? 0 : index, 0, newStep);
            for (let i = index; i < stepsList.length; i++) {
                const step = stepsList[i];
                step.sequence++;
                step.savingStatus = SavingStatus.Unsaved;
            }
            return {
                ...state,
                stepsAreSavedAndValid: false,
                error: '',
                isLoading: false,
                steps: [...steps],
            }
        }
        case ACTIONS.ADD_STEP_AFTER.type: {
            const { steps } = state;
            let { index, newStep } = action.payload;
            const stepsList = (newStep.isSubstep? steps.find(x => x.stepId === newStep.parentId)?.subRows : steps) || [];
            const allStepsCount = ReadOnlyCalculationService.flattenSteps(steps).length;
            newStep.id = `${allStepsCount + 1}`;
            
            // Inserting new item
            stepsList.splice(index >= stepsList.length - 1? stepsList.length : index + 1, 0, newStep);
            for (let i = index + 1; i < stepsList.length; i++) {
                const step = stepsList[i];
                step.sequence++;
                step.savingStatus = SavingStatus.Unsaved;
            }
            return {
                ...state,
                stepsAreSavedAndValid: false,
                error: '',
                isLoading: false,
                steps: [...steps],
            }
        }
        case ACTIONS.DUPLICATE_STEP.type: {
            const { steps } = state;
            const { index, originalStep } = action.payload;
            const nameCopiesCount = steps.filter(x => x.stepName.endsWith(originalStep.stepName)).length;
            const copyPrefix = `DUPLICATE${nameCopiesCount === 1? '' : '('+nameCopiesCount+')'} - `;
            const copyName = `${copyPrefix}${originalStep.stepName}`;
            const allSteps = ReadOnlyCalculationService.flattenSteps(steps);
            const allStepsCount = allSteps.length;
            const stepCopy = {...originalStep, stepName: copyName, stepId: `${allStepsCount + 1}`, savingStatus: SavingStatus.Unsaved, appliedTPEntry: null, tpEntryType: null, isNew: true} as CalculationStep;
            const stepsList = (stepCopy.isSubstep? steps.find(x => x.stepId === stepCopy.parentId)?.subRows : steps) || [];
            // Making possible duplicated substep ids unique
            if (!ArrayUtils.isNullOrEmpty(stepCopy.subRows)) {
                let count = allStepsCount + 1;

                const subRows = stepCopy.subRows || [];
                for (let i = 0; i < subRows.length; i++) {
                    count++;
                    const sub = subRows[i];
                    subRows[i] = {
                        ...sub,
                        stepName: `${copyPrefix}${sub.stepName}`,
                        stepId: `${count}`,
                        parentId: stepCopy.stepId
                    }
                }
                stepCopy.subRows = subRows;
            }

            // Inserting copy
            stepsList.splice(index === 0? 0 : index, 0, stepCopy);
            for (let i = index; i < stepsList.length; i++) {
                const step = stepsList[i];
                step.sequence++;
                step.savingStatus = SavingStatus.Unsaved;
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                stepsAreSavedAndValid: false,
                updateStepPayload: stepCopy,
                steps: [...steps],
            }
        }
        case ACTIONS.REMOVE_STEP.type: {
            let { steps } = state;
            const { step, index } = action.payload;
            if (state.tpeEntriesApplied != null && state.tpeEntriesApplied.has(step.stepName)) {
                state.tpeEntriesApplied.delete(step.stepName);
            }
            if (step.isNew) {
                const parentStep = step.isSubstep? steps.find(x => x.stepId === step.parentId) : null;
                const stepsList = (step.isSubstep? parentStep?.subRows : steps) || [];
                for (let i = index; i < stepsList.length; i++) {
                    const s = stepsList[i];
                    s.sequence--;
                    s.savingStatus = SavingStatus.Unsaved;
                }
                if (step.isSubstep && parentStep){
                    parentStep.subRows = stepsList.filter(x => x.stepId !== step.stepId)
                }
                else {
                    steps = steps.filter(x => x.stepId !== step.stepId);
                }
                return {
                    ...state,
                    error: '',
                    isLoading: false,
                    removeStepModalPayload: null,
                    stepsAreSavedAndValid: false,
                    steps: [...steps],
                }
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                removeStepModalPayload: null,
                stepsAreSavedAndValid: false,
                deleteStepPayload: {calculationNumber:step.calculationNumber, stepId: step.stepId},
            }
        }
        case ACTIONS.TOGGLE_SHOW_VALUES.type: {
            return {
                ...state,
                error: '',
                isLoading: false,
                showValues: !state.showValues,
            }
        }
        case ACTIONS.SET_EXPANDED_STATE.type: {
            return {
                ...state,
                error: '',
                isLoading: false,
                expandedState: action.payload,
            }
        }
        case ACTIONS.SET_EDITOR_CARET_POSITION.type: {
            const { stepBeingEdited } = state;
            if (stepBeingEdited == null) {
                return {
                    ...state,
                    error: `No calculation step is being edited. The value '${action.payload}' could not be set as caret position.`,
                    isLoading: false,
                };
            }
            stepBeingEdited.formulaEditorCaretPosition = action.payload;
            return {
                ...state,
                error: '',
                isLoading: false,                
            }
        }
        case ACTIONS.APPEND_TO_FORMULA_EDITOR.type: {
            const { stepBeingEdited, steps } = state;
            if (stepBeingEdited == null) {
                return {
                    ...state,
                    error: `No calculation step is being edited. The value '${action.payload}' could not be added to the formula editor.`,
                    isLoading: false,
                };
            }
            const caretPosition = stepBeingEdited.formulaEditorCaretPosition || stepBeingEdited.expression.length - 1;
            const paddingBeforePosition = stepBeingEdited.expression.charAt(caretPosition - 1) === ' '? '' : ' ';
            const paddingAfterPosition = stepBeingEdited.expression.charAt(caretPosition) === ' '? '' : ' ';
            const str = `${paddingBeforePosition}${action.payload}${paddingAfterPosition}`;
            stepBeingEdited.expression = [stepBeingEdited.expression.slice(0, caretPosition), str, stepBeingEdited.expression.slice(caretPosition)].join('');
            const stepsList = (stepBeingEdited.isSubstep? steps.find(x => x.stepId === stepBeingEdited.parentId)?.subRows : steps) || [];
            stepsList[stepsList.findIndex(x => x.stepId === stepBeingEdited.stepId)] = stepBeingEdited;
            return {
                ...state,
                error: '',
                isLoading: false,
                stepBeingEdited: {...stepBeingEdited}
            }
        }
        case ACTIONS.UPDATE_CURRENT_STEP.type: {
            if (state.stepBeingEdited == null) {
                return {
                    ...state,
                    error: `No calculation step is being edited. Step ${action.payload.field} could not be updated.`,
                    isLoading: false,
                };
            }
            const step = (state.stepBeingEdited as any);
            if (step){
                const oldValue = step[action.payload.field];
                const newValue = action.payload.value;
                const {tpeEntriesApplied} = state;
                
                if (oldValue !== newValue){
                    step.savingStatus = SavingStatus.Unsaved;
                }

                step[action.payload.field] = action.payload.value;
                // If the step name is one of the tpeEntries
                if (action.payload.field === CONSTANTS.CALCULATION_STEP_FIELDS.NAME) {
                    const tpeEntry = state.tpeEntries.find(x => x.name === state.stepBeingEdited?.stepName);
                    
                    if (tpeEntry) {
                        tpeEntriesApplied.add(tpeEntry.name);
                    }
                    else if (step.appliedTPEntry) {
                        tpeEntriesApplied.delete(step.appliedTPEntry.name);
                    }
                    step.appliedTPEntry = tpeEntry;
                    step[CONSTANTS.CALCULATION_STEP_FIELDS.TP_ENTRY_TYPE] = tpeEntry?.name;
                    if (tpeEntry != null && tpeEntry.value != null){
                        step[CONSTANTS.CALCULATION_STEP_FIELDS.OVERVIEW] = tpeEntry.value;
                        step[CONSTANTS.CALCULATION_STEP_FIELDS.EXPRESSION_TOKENS] = [{value: tpeEntry.value, type: "L"}];
                    }

                }
                else if (action.payload.field === CONSTANTS.CALCULATION_STEP_FIELDS.OVERVIEW) {
                    step[CONSTANTS.CALCULATION_STEP_FIELDS.EXPRESSION_TOKENS] = action.payload.extraValue;
                }

                return {
                    ...state,
                    error: '',
                    isLoading: false,
                    steps: [...state.steps],
                    stepsAreSavedAndValid: false,
                    tpeEntriesApplied: new Set(tpeEntriesApplied),
                    updateStepPayload: state.stepBeingEdited?.savingStatus === SavingStatus.Unsaved? {...step} : null,
                }
            }

            return {
                ...state,                
            }
        }
        case ACTIONS.SET_REMOVE_STEP_MODAL_PAYLOAD.type:
            return {
                ...state,
                error: '',
                isLoading: false,
                removeStepModalPayload: action.payload,
            }
        case ACTIONS.SET_ADD_COMMENT_PAYLOAD.type:
            return {
                ...state,
                error: '',
                isLoading: false,
                addCommentPayload: action.payload,
            }
        case ACTIONS.SET_STEPS_ARE_SAVED_AND_VALID.type:
            return {
                ...state,
                error: '',
                stepsAreSavedAndValid: action.payload,
            }
        case ACTIONS.SET_NEW_COMMENT_PAYLOAD.type:
            if (action.payload == null){
                return {
                    ...state,
                    commentsModalPayload: action.payload,
                    commentsNeedRefresh: false,
                }
            }
            const {unsavedComments} = state;
            const isNewStep = action.payload?.step?.isNew || false;
            return {
                ...state,
                error: '',
                isLoading: false,
                commentsModalPayload: action.payload,
                commentsNeedRefresh: !isNewStep,
                commentsHistory: isNewStep? [...unsavedComments] : []
            }
        case ACTIONS.SET_STEP_COMMENTS.type:
            const { steps, commentsModalPayload } = state;
            const flatSteps = ReadOnlyCalculationService.flattenSteps(steps);
            const step = flatSteps.find(x => x.stepId === commentsModalPayload.step.stepId);
            if (step != null) {
                const comments = action.payload as CalcBuilderComment[];
                commentsModalPayload.step.comments = step.comments = comments;
                step.hasComments = commentsModalPayload.step.hasComments = comments.length > 0;
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                steps: [...steps],
            }
        case ACTIONS.ADD_STEP_COMMENT.type: {
            const comment: CalcBuilderComment = action.payload;
            const { commentsHistory, unsavedComments, commentsModalPayload, steps } = state;
            commentsHistory.splice(0, 0, comment);
            // If step is not saved yet we add the comment to the unsaved list and attempt to turn on the hasComments flag on the step
            if (comment.ownerIsUnsaved) {
                unsavedComments.splice(0, 0, comment);
                if ( commentsModalPayload.step ){
                    const flatSteps = ReadOnlyCalculationService.flattenSteps(steps);
                    const found = flatSteps.find(x => x.stepId === commentsModalPayload.step.stepId);
                    if (found){
                        found.hasComments = true;
                    }
                }
            }
            return {
                ...state,
                commentsNeedRefresh: !comment.ownerIsUnsaved,
                steps: [...steps],
            }
        }
        case ACTIONS.SET_RUN_VALUES.type: {
            const { steps } = state;
            const flatSteps = ReadOnlyCalculationService.flattenSteps(steps);
            const runValues = action.payload as CalculationStepsRunItem[] || [];
            runValues.forEach(v => {
                const step = flatSteps.find(x => x.stepId === v.stepId);
                if (step){
                    step.formulaOutput = v.value;
                    step.outputBreakdown = v.valueBreakdown;
                }
            })
            return {
                ...state,
                error: '',
                isLoading: false,
                steps: [...steps],
                lastRunValues: action.payload,
            }
        }
        case ACTIONS.REFRESH_STEP.type: {
            const { steps, stepBeingEdited, unsavedComments } = state;
            const stepToBeRefreshed = stepBeingEdited != null && stepBeingEdited.sequence === action.payload.sequence? 
                        stepBeingEdited : 
                        steps.find(x => x.stepId === action.payload.stepId);
            if (stepToBeRefreshed != null){
                stepToBeRefreshed.stepId = action.payload.stepId;
                stepToBeRefreshed.valid = action.payload.valid;
                stepToBeRefreshed.expression = action.payload.expression;
                stepToBeRefreshed.isNew = false;
                stepToBeRefreshed.validationErrors = action.payload.validationErrors;
                stepToBeRefreshed.savingStatus = SavingStatus.Saved;

                // Updating unsaved comments if present with the new step ID
                unsavedComments.forEach(uc => {
                    uc.stepId = stepToBeRefreshed.stepId;
                })
            }
            else {
                console.error("********* Could not refresh step because step was not found");
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                steps: [...steps],
                stepSavedId: stepToBeRefreshed?.stepId,
            }
        }
        case ACTIONS.SET_COMMENTS_HISTORY.type: {
            return {
                ...state,
                commentsHistory: action.payload,
            }
        }
        case ACTIONS.CLEAR_UNSAVED_COMMENTS.type: {
            return {
                ...state,
                commentsHistory: [],
                unsavedComments: [],
            }
        }
        case ACTIONS.SHOW_STANDARD_ALLOCATION_MODAL.type: {
            return {
                ...state,
                showStandardAllocationModal: action.payload,
            }
        }
        case ACTIONS.SHOW_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL.type: {
            return {
                ...state,
                showStandardAllocationDeleteConfirmModal: true,
            }
        }
        case ACTIONS.HIDE_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL.type: {
            return {
                ...state,
                showStandardAllocationDeleteConfirmModal: false,
            }
        }
        case ACTIONS.DELETE_STANDARD_ALLOCATION_RECORD.type: {
            const record = state.steps.find(x => x.stepId == state.stepIdForAction);
            if (record != null) {
                record.standardAllocation = undefined;
            }
            return {
                ...state,
                steps: [...state.steps],
            };
        }
        case ACTIONS.SET_STEP_ID_FOR_ACTION.type: {
            return {
                ...state,
                stepIdForAction: action.payload,
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}




