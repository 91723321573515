export interface ReadOnlyCalculationState {
    isSearching: boolean,
    selectedAgreementNumber?: string
    calculationNumbers: Array<string>,
    selectedCalculationNumber?: string
    versions: number[]
    selectedVersion?: number
    calculationSteps: any[]
}

export const initialState: ReadOnlyCalculationState = {
    isSearching: false,
    calculationNumbers: [],
    versions: [] as number[],
    calculationSteps: [] as any[],
};

