import { useEffect, useState } from 'react';
import SavedSearch from 'src/models/calculations/SavedSearch';
import { SearchCalculationResult } from 'src/models/search-agreements/SearchCalculationsResult';
import { UserData, UserProfile } from 'src/models/users/UserProfile';
import SearchAgreementLOVsResult from '../../models/search-agreements/SearchAgreementLOVsResult';
import TPESearchRequest from '../../models/common/TPESearchRequest'
import apiService from '../ApiCallService'
import {CLIDetailsResult} from "src/models/calculations/CLIDetailsResult";
import ErrorUtils from 'src/utils/ErrorUtils';
import { CalculationDetails } from 'src/models/calculations/CalculationDetailsResult';
import StringUtils from 'src/utils/stringUtils';
import moment from 'moment-timezone';
import { ExecutionGroupDetails } from 'src/models/calculation-builder/ExecutonGroupDetails';
import DateUtils from 'src/utils/dateUtils';


/**
 * This class should only contain the API calls related to Calculations
 */
export default class CalculationsService {
    /**
    * Pulls available calculation lines for the given agreement line
    * @param agreementLine An Apttus agreement
    */
    getCalculationLines(agreementLine?: string): [string[], boolean, string] {
        const [results, setResults] = useState([] as string[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCalculationLines(agreementLine as string);
                    const json = await response.json() as SearchAgreementLOVsResult;
                    setResults(json.calculationNumbers);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }

            if (agreementLine != null) {
                fetchData();
            }
        }, [agreementLine]);
        return [results, loading, error];
    }


    /**
    * Finds calculation lines based on payload parameters
    * @param agreementLine An Apttus agreement
    */
    searchCalculationLines(payload: TPESearchRequest) : [SearchCalculationResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as SearchCalculationResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchCalculations(payload);
                    const json = await response.json() as SearchCalculationResult;
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }

            if (payload.searchStartedDate != null) {
                fetchData();
            }
        }, [payload.searchStartedDate]);
        return [results, loading, error];
    }

    saveSavedSearches(user: UserProfile, payload: SavedSearch[]) {
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function saveData() {
                try {
                    setLoading(true);
                    const data = JSON.parse(user.userData as string) as UserData;
                    data.calculationSearches = payload;
                    user.userData = JSON.stringify(data);
                    apiService.saveUserProfile(user);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload && payload.length > 0) {
                saveData();
            }
        }, [payload]);
        return [loading, error];
    }

    getSavedSearches(user: UserProfile) {
        const [results, setResults] = useState([] as SavedSearch[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData($this: CalculationsService) {
                try {
                    setLoading(true);
                    const data = JSON.parse(user.userData as string) as UserData;
                    if (data.calculationSearches == null) {
                        setResults([]);
                    }
                    setResults(data.calculationSearches);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }
            if (user != null) {
                fetchData(this);
            }
        }, [user]);
        return [results, loading, error];
    }

    getCLIDetails(recordId: string, needsRefresh: boolean) : [CLIDetailsResult, boolean, string]{
        const [results, setResults] = useState<CLIDetailsResult>(null as unknown as CLIDetailsResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as unknown as string);
            useEffect(() => {
                async function fetchData($this: CalculationsService) {
                    try {
                        setLoading(true);
                        const response = await apiService.getCLIDetails(recordId);
                        const data = (await response.json()) as CLIDetailsResult;
                        setResults(data);
                    }
                    catch (ex:any) {
                        setError(ErrorUtils.getMessage(ex));
                    }
                    finally {

                        setLoading(false);
                    }
                }
                if (!StringUtils.isNullOrEmpty(recordId) && needsRefresh) {
                    fetchData(this);
                }
            }, [recordId, needsRefresh]);
        return [results, loading, error];
    }

    /**
     * Pulls version numbers of a specific calculation
     * @param calculationNumber that maps to a list of versions
     * TODO: add api call
     */
     getCalculationVersions(calculationNumber: string, needsRefresh: boolean) : [number[], boolean, string] {
        const [result, setResult] = useState(null as unknown as number[]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(calcNumber:string) {
                try {
                    const response = await apiService.getCalculationVersions(calcNumber);
                    const json = await response.json();
                    setResult(json.versions);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(calculationNumber) && needsRefresh) {
                fetchData(calculationNumber);
            }
        }, [calculationNumber, needsRefresh]);
        return [result, loading, error]
    }

    createCalculation(calculationNumber?: string): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(calcNumber:string) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.createCalculation({calculationNumber: calcNumber})
                    const json = await response.json()
                    setResult(json);
                }
                catch (ex:any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null) {
                postData(calculationNumber);
            }
        }, [calculationNumber]);
        return [result, loading, error]
    }

    getCalculationMetadata(calculationNumber: string, calculationVersion: number, needsRefresh?: boolean) : [CalculationDetails, boolean, string]{
        const [results, setResults] = useState<CalculationDetails>(null as unknown as CalculationDetails);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as unknown as string);
            useEffect(() => {
                async function fetchData() {
                    try {
                        setError(null as unknown as string);
                        setLoading(true);
                        const response = await apiService.getCalculationMetadata(calculationNumber, calculationVersion == null? -1: calculationVersion);
                        const json = await response.json();
                        const data = json.calculation as CalculationDetails;
                        formatAttributes(data);
                        setResults(data);
                    }
                    catch (ex:any) {
                        setError(ErrorUtils.getMessage(ex));
                    }
                    finally {

                        setLoading(false);
                    }
                }
                if (!StringUtils.isNullOrEmpty(calculationNumber) && calculationVersion != null && needsRefresh) {
                    fetchData();
                }
            }, [calculationNumber, calculationVersion, needsRefresh]);
        return [results, loading, error];
    }
    
    submitCalculationForReview(payload?: {calculationNumber: string, notes: string}): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(calculationNumber: string, payload: any) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.submitCalculationForReview(calculationNumber, payload)
                    const json = await response.json()
                    setResult(json);
                }
                catch (ex:any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload.calculationNumber, payload);
            }
        }, [payload]);
        return [result, loading, error]
    }
    
    rejectCalculationDraft(payload?: {calculationNumber: string, rejectionReason: string}): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(calcNumber: string) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.rejectCalculationDraft(calcNumber, payload)
                    const json = await response.json()
                    setResult(json);
                }
                catch (ex:any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload.calculationNumber);
            }
        }, [payload]);
        return [result, loading, error]
    }

    approveCalculation(calculationNumber?: string): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(calcNumber:string) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.approveCalculation(calcNumber)
                    const json = await response.json()
                    setResult(json);
                }
                catch (ex:any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null) {
                postData(calculationNumber);
            }
        }, [calculationNumber]);
        return [result, loading, error]
    }

    getCLIExecutionGroups(query: string | null) : [string[], boolean, string] {
        const [executionGroups, setExecutionGroups] = useState([] as string[]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCLIExecutionGroups();
                    const json = await response.json()
                    setExecutionGroups(json.executionGroups);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (query != null && query.length > 0) {
                fetchData();
            }
        }, [query]);
        return [executionGroups, loading, error]
    }

    saveCalculationAttribute(payload?: {calculationNumber: string, attributeName: string, attributeValue: string}): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(calcNumber:string) {
                try {
                    setLoading(true);
                    setError('');
                    const response = await apiService.saveCalculationAttribute(calcNumber, payload)
                    const json = await response.json()
                    setResult(json);
                }
                catch (ex:any) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload.calculationNumber);
            }
        }, [payload]);
        return [result, loading, error]
    }

    getActiveCLIDetails():any {
        const [results, setResults] = useState([] as string[]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCLIAPI();
                    const json = await response.json() as any;
                    setResults(json.clis);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }
            fetchData();
        }, []);
        return [results, loading, error];
    }

    getCompany(segment:string): [string[], boolean, string] {
        const [result, setResult] = useState([] as string[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCoaData(segment);
                    const json = await response.json();
                    setResult(json.coaData);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if(segment!= ''){
                fetchData()
            }
            }, [segment]);

        return [result, loading, error];
    }

    getAgreementStatus(): [string[], boolean, string] {
        const [result, setResult] = useState([] as string[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getAgreementStatus();
                    const json = await response.json();
                    setResult(json.calculationStatuses);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            fetchData();
            }, []);

        return [result, loading, error];
    }

    getCalculationStatus(): [string[], boolean, string] {
        const [result, setResult] = useState([] as string[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCalculationStatus();
                    const json = await response.json();
                    setResult(json.calculationStatuses);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            fetchData();
            }, []);

        return [result, loading, error];
    }

    getCLIJEStatuses() : [string[], boolean, string] {
        const [jeStatuses, setJEStatuses] = useState([] as string[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getJEStatuses();
                    const json = await response.json();
                    setJEStatuses(json.jeStatuses);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            fetchData();
        }, []);

        return [jeStatuses, loading, error];
    }

    getOrAssignExecutionGroup(query: string | null, calculationNumber?: string): [ExecutionGroupDetails, boolean, string] {
        const [result, setResult] = useState(null as unknown as ExecutionGroupDetails);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(calculationNumber: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getOrAssignExecutionGruop(calculationNumber);
                    const json = await response.json();
                    if (json.lastUpdateDate != null) {
                        json.lastUpdateDate = moment(json.lastUpdateDate).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A zz");
                    }
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(calculationNumber)) {
                fetchData(calculationNumber || '');
            }
        }, [query]);
        return [result, loading, error];
    }

    saveCalculationExecutionGroup(payload: {calculationNumber: string, executionGroup: string}): [ExecutionGroupDetails, boolean, string] {
        const [result, setResult] = useState(null as unknown as ExecutionGroupDetails);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(payload: {calculationNumber: string, executionGroup: string}) {
                try {
                    setLoading(true);
                    const response = await apiService.saveCalculationExecutionGroup(payload.calculationNumber, payload.executionGroup);
                    const json = await response.json();
                    if (json.lastUpdateDate != null) {
                        json.lastUpdateDate = moment(json.lastUpdateDate).tz(moment.tz.guess()).format("MMM DD, YYYY hh:mm A zz");
                    }
                    json.updatedFromCalculation = payload.calculationNumber;
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error];
    }
}

function formatAttributes(calculationDetails: CalculationDetails) {
    calculationDetails.submitDateDisplay = DateUtils.formatDate(calculationDetails?.submitDate);
    calculationDetails.reviewDateDisplay = DateUtils.formatDate(calculationDetails?.reviewDate);
    calculationDetails.lastModifiedDate = calculationDetails.lastModifiedDate == null ? 'N/A' : DateUtils.formatDate(calculationDetails.lastModifiedDate);
    if (calculationDetails.lastModifiedUser == null) {
        calculationDetails.lastModifiedUser = 'N/A';
    }
}

