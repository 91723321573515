import React from "react";
import {Box, Button, Popover, StatusIndicator} from "@amzn/awsui-components-react";

export default function CopyToClipboard (props: {
    textToBeCopied : string
    popoverMessage: string
}) {
    const {textToBeCopied, popoverMessage} = props;
    return <Box margin={{right: 'xxs'}} display="inline-block">
        <Popover
            className="copyIconPopover"
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={<StatusIndicator type="success"> {popoverMessage} </StatusIndicator>}
        >
            <Button
                className="copyIconButton"
                variant="inline-icon"
                iconName="copy"
                onClick={() => {
                    navigator.clipboard.writeText(textToBeCopied)
                }}
            />
        </Popover>
    </Box>
}