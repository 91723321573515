import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Pagination,
    Select,
    SpaceBetween,
    StatusIndicator,
    TextContent
} from '@amzn/awsui-components-react';
import React, {useEffect, useState} from 'react';
import TPEAction from 'src/models/common/TPEAction';
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import CONSTANTS, {INDIRECT_TAX_CHANGE_RECORDS_PAGE_LIMIT} from "src/utils/constants";
import {TPELoadingSpinner} from '../../shared/TPELoadingSpinner';
import PreValidationGrid from './PreValidationGrid';
import {initialState, PreValidationState} from 'src/services/mec/prevalidation/PreValidationState';
import {ACTIONS, preValidationReducer} from 'src/services/mec/prevalidation/PreValidationReducer';
import StringUtils from 'src/utils/stringUtils';
import '../MEC.scss';
import {DownloadReportRequest} from "src/models/reports/DownloadReport";
import {GenerateReportRequest} from "src/models/reports/GenerateReport";
import useLocalStorage from "src/utils/useLocalStorage";
import {FetchReportRequest} from "src/models/reports/FetchReport";
import TPEErrorWatcher from "src/components/shared/TPEErrorWatcher";
import {getPermissions} from 'src/components/AppPermissions';
import {AppModules} from 'src/models/permissions/RolePermissions';
import {Modal} from "@amzn/awsui-components-react/polaris";
import {FetchIndirectTaxChangeRecordsRequest} from 'src/models/reports/FetchIndirectTaxChangeRecords';
import PreValidationIndirectTaxTableGrid from './PreValidationIndirectTaxTableGrid';
import ATPHorizontalRadioGroup from 'src/components/shared/ATPHorizontalRadioGroup';
import {DownloadIndirectTaxChangeRecordsRequest} from 'src/models/reports/DownloadIndirectTaxChangeRecords';
import ReportStatus from 'src/components/reports/ReportStatus';

export type ContextType = {
    state: PreValidationState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const PreValidationProvider = (props: any) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <PreValidationContext.Provider value={providerValue}>
            {children}
        </PreValidationContext.Provider>
    );
}
export const PreValidationContext = React.createContext(null as unknown as ContextType);

export default function RecordsView(props: { services: ServiceCollection }) {
    const { services } = props;
    const [state, dispatch] = useReducerWithLogger(preValidationReducer, initialState);
    const { selectedPage, loadedPage, cache } = state;
    const { canRunPrevalidation } = getPermissions(AppModules.MEC);

    const [enteredCloseDate, setEnteredCloseDate] = useState('');
    const [selectedCategory, setSelectedCategory] = useState({ value: '', label: '' });
    const [closeDate, setCloseDate] = useState('');
    const [syncingReportData, setSyncingReportData] = useState(false);

    const [downloadReportRequestPayload, setDownloadReportRequestPayload] = React.useState(null as unknown as DownloadReportRequest);
    const [fetchIndirectTaxRecordsRequest, setFetchIndirectTaxRecordsRequest] = React.useState(null as unknown as FetchIndirectTaxChangeRecordsRequest);
    const [indirectTaxRecords, indirectTaxRecordsLoading, indirectTaxReportFetchError] = services.reportsService.fetchIndirectTaxChangeRecords(fetchIndirectTaxRecordsRequest);
    const [selectedDetailTabReportId, setSelectedDetailTabReportId] = useState(null as unknown as string);
    const [syncReportRequest, setSyncReportRequest] = React.useState(null as unknown as FetchReportRequest);
    const [reportData, isReportDataLoading, reportFetchError] = services.reportsService.fetchReportData(syncReportRequest);
    const [generateReportRequest, setGenerateReportRequest] = React.useState(null as unknown as GenerateReportRequest);
    const [generateReportResponse, isGeneratingReport, reportGenerationError] = services.reportsService.generateReport(generateReportRequest);
    const [downloadReportUrl, downloadReportLoading, downloadReportError] = services.reportsService.downloadReport(downloadReportRequestPayload);
    const [indirectTaxReportDownloadRequest, setIndirectTaxReportDownloadRequestPayload] = React.useState(null as unknown as DownloadIndirectTaxChangeRecordsRequest);
    const [indirectTaxDownloadReportUrl, indirectTaxDownloadReportUrlLoading, indirectTaxDownloadReportError] = services.reportsService.downloadIndirectTaxChangeRecords(indirectTaxReportDownloadRequest);
    const [isDownloadDisabled, setDownloadDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isIndirectTaxReport, setIsIndirectTaxReport] = useState(false);
    const [lastReportStatusModal, setLastReportStatusModal] = useState(false);

    const [taxRateChangeIndicator, setTaxRateChangeIndicator] = useState(true as unknown as boolean | undefined);
    const INDIRECT_TAX_RATE_CHANGE_INDICATOR: Array<{ value: string, label: string }> = [
        { value: "Show CLI's with Tax Change", label: "Show CLI's with Tax Rate Change" },
        { value: "Show All CLI's", label: "Show All CLI's" }
    ];
    const sleep = (ms: number) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    useEffect(() => {
        if (!reportData) {
            setIsLoading(true);
            setDownloadDisabled(true);
            return;
        }

        const { reportStatus, payload, reportName, reportId } = reportData;

        // Report Status will be null when no past reports are found.
        if (!reportStatus) {
            setIsLoading(false);
            setDownloadDisabled(true);
            setSyncingReportData(false);

            dispatch(ACTIONS.SET_PREVALIDATION_RESULT.withPayload("[]"));
            dispatch(ACTIONS.SET_EMPTY_TABLE_MESSAGE.withEmptyTableMessage(reportData.statusMessage));
            return;
        }

        const isReportFailed = [CONSTANTS.REPORT_STATUS.FAILED, CONSTANTS.REPORT_STATUS.VALIDATION_FAILED].includes(reportStatus);

        if (isReportFailed) {
            setLastReportStatusModal(true);
            setIsLoading(false);
            setDownloadDisabled(true);
            setSyncingReportData(false);
            setSelectedDetailTabReportId(reportId);
            dispatch(ACTIONS.SET_PREVALIDATION_RESULT.withPayload("[]"));
            dispatch(ACTIONS.SET_EMPTY_TABLE_MESSAGE.withEmptyTableMessage(reportData.statusMessage));
        } else if (isIndirectTaxReport && !!reportData.reportStatus) {
            setSelectedDetailTabReportId(reportId);
        } else if (!payload) {
            setSyncingReportData(true)
            setDownloadDisabled(true);
            setIsLoading(true);
            sleep(5000).then(() => setSyncReportRequest({ reportName, params: {} }));
        } else {
            setIsLoading(false);
            setDownloadDisabled(false);
            setSyncingReportData(false);
            setSelectedDetailTabReportId(reportId);
            dispatch(ACTIONS.SET_PREVALIDATION_RESULT.withPayload(payload));
            dispatch(ACTIONS.SET_EMPTY_TABLE_MESSAGE.withEmptyTableMessage("No Results"));
        }
    }, [reportData]);

    useEffect(() => {
        if (isIndirectTaxReport) {
            setIsLoading(true)
            handleRefresh();
        }
    }, [selectedDetailTabReportId, taxRateChangeIndicator])

    useEffect(() => {
        if (!indirectTaxRecords) {
            return;
        }
        setDownloadDisabled(false);
        setIsLoading(false);
        setSyncingReportData(false);
        dispatch(ACTIONS.SET_CACHE.withPayload({ ...cache, [selectedPage]: indirectTaxRecords.indirectTaxChangeRecordList }));
        dispatch(ACTIONS.SET_INDIRECT_TAX_CHANGE_RECORD_LIST.withPayload(indirectTaxRecords.indirectTaxChangeRecordList));
    }, [indirectTaxRecords])

    const handleRefresh = () => {
        setIsLoading(true);
        dispatch(ACTIONS.SET_CACHE.withPayload({}));
        dispatch(ACTIONS.SET_LOADED_PAGE.withPayload(1));
        dispatch(ACTIONS.SET_SELECTED_PAGE.withPayload(1));
        fetchIndirectTaxChangeRecordList();
    };

    const fetchIndirectTaxChangeRecordList = (startKey?: string) => {
        setFetchIndirectTaxRecordsRequest({
            reportId: selectedDetailTabReportId,
            taxRateChangeIndicator: taxRateChangeIndicator,
            limit: INDIRECT_TAX_CHANGE_RECORDS_PAGE_LIMIT,
            startKey: startKey
        })
    }

    useEffect(() => {
        if (generateReportResponse) {
            setSyncingReportData(true);
            setSyncReportRequest({
                reportName: getReportName() || '',
                params: {}
            });
        }
    }, [generateReportResponse]);

    useEffect(() => {
        const reportName = getReportName()
        if (reportName) {
            setSyncReportRequest({
                reportName: reportName,
                params: {}
            })
            dispatch(ACTIONS.SET_CATEGORY.withPayload(selectedCategory?.value));
            if(reportName === CONSTANTS.REPORT_TYPES.PRE_VALIDATION_INDIRECT_TAX) {
                setIsIndirectTaxReport(true);
            } else {
                setIsIndirectTaxReport(false);
            }
        } else {
            setDownloadDisabled(true);
        }
    }, [selectedCategory])

    useEffect(() => {
        if (downloadReportError) {
            services.messageService.showErrorBanner(downloadReportError);
        }
        else if (downloadReportUrl) {
            window.open(downloadReportUrl);
        }
    }, [downloadReportUrl, downloadReportError])

    useEffect(() => {
        if (indirectTaxDownloadReportError) {
            services.messageService.showErrorBanner(indirectTaxDownloadReportError);
        }
        else if (indirectTaxDownloadReportUrl) {
            window.open(indirectTaxDownloadReportUrl);
        }
    }, [indirectTaxDownloadReportUrl, indirectTaxDownloadReportError])

    const setPreValidationParameters = () => {
        setCloseDate(enteredCloseDate);
        updateGenerateReportRequest();
    }

    const getReportName = () => {
        const categories = CONSTANTS.MEC_PREVALIDATION_CATEGORIES;
        if (selectedCategory.value == categories.COA_CHANGES) {
            return CONSTANTS.REPORT_TYPES.PRE_VALIDATION_COA;
        } else if (selectedCategory.value == categories.CLI_CDT_DATA) {
            return CONSTANTS.REPORT_TYPES.PRE_VALIDATION_CDT;
        } else if (selectedCategory.value == categories.CLI_PTA_DATA) {
            return CONSTANTS.REPORT_TYPES.PRE_VALIDATION_PTA;
        } else if (selectedCategory.value == categories.INDIRECT_TAX_CHANGES) {
            return CONSTANTS.REPORT_TYPES.PRE_VALIDATION_INDIRECT_TAX;
        } else {
            return null;
        }
    }

    const updateGenerateReportRequest = () => {
        const reportName = getReportName();
        if (reportName) {
            setGenerateReportRequest({
                userId: useLocalStorage.getUsernameFromLocalStorage(),
                reportName: reportName,
                params: {}
            });
        }
    }

    const handleNextPageClick = (requestedPageIndex: number) => {
        dispatch(ACTIONS.SET_SELECTED_PAGE.withPayload(requestedPageIndex));

        if (cache.hasOwnProperty(requestedPageIndex)) {
            dispatch(ACTIONS.SET_INDIRECT_TAX_CHANGE_RECORD_LIST.withPayload(cache[requestedPageIndex]));
        } else if (indirectTaxRecords.lastEvaluatedKey) {
            dispatch(ACTIONS.SET_LOADED_PAGE.withPayload(loadedPage + 1));
            fetchIndirectTaxChangeRecordList(indirectTaxRecords.lastEvaluatedKey);
        }
    };

    return <PreValidationProvider services={services} state={state} dispatch={dispatch}>
        <div className="customTableTableHeader">
            <Box variant="p">Pre-validation category</Box>
            <SpaceBetween direction="horizontal" size="m">
                <Select
                    className="mecCategorySelector"
                    onChange={(e: any) => {
                        setSelectedCategory(e.detail.selectedOption);
                        setSyncingReportData(true);
                    }}
                    options={CONSTANTS.MEC_PREVALIDATION_CATEGORIES_LIST}
                    selectedOption={selectedCategory}
                    disabled={syncingReportData}
                />
                {canRunPrevalidation && <Button
                    variant="primary"
                    disabled={syncingReportData ||
                        getReportName() == null ||
                        StringUtils.isNullOrEmpty(selectedCategory.value) ||
                        (!!reportData.reportStatus && isIndirectTaxReport && reportData.reportStatus != CONSTANTS.REPORT_STATUS.SUCCESS)
                    }
                    onClick={() => setPreValidationParameters()}
                >
                    {syncingReportData ? 'Running...' : 'Run'}
                </Button>}
            </SpaceBetween>
        </div>
        <Container data-class="containerWithHeaderAndGrid">
            <SpaceBetween direction="vertical" size="m">
                <div className="customTableTableHeader">
                    <ColumnLayout columns={2} data-class="fullColumnLayout">
                        <SpaceBetween direction="horizontal" size="m">
                            <Box variant="h2">Pre-validation</Box>
                            {
                                !isDownloadDisabled && !isReportDataLoading && reportData && reportData.createdAt &&
                                <Box variant="p"> - last generated {reportData.createdAt}</Box>
                            }
                        </SpaceBetween>
                        <Box float="right">
                            <SpaceBetween direction='horizontal' size='m'>
                                <TextContent>
                                    {isIndirectTaxReport && !syncingReportData && !isLoading && !!reportData.reportStatus && (
                                        <small>Report Status: <ReportStatus reportStatus={reportData.reportStatus} /></small>
                                    )}
                                </TextContent>
                                <Button variant="normal" disabled={syncingReportData || isDownloadDisabled || reportData.reportStatus != CONSTANTS.REPORT_STATUS.SUCCESS}
                                    onClick={() => {
                                        if (isIndirectTaxReport) {
                                            setIndirectTaxReportDownloadRequestPayload({
                                                reportId: selectedDetailTabReportId
                                            })
                                        } else {
                                            setDownloadReportRequestPayload({
                                                reportId: selectedDetailTabReportId,
                                                userId: useLocalStorage.getUsernameFromLocalStorage()
                                            })
                                        }
                                    }}>{downloadReportLoading || indirectTaxDownloadReportUrlLoading ? 'Downloading...' : 'Download Report'}</Button>
                            </SpaceBetween>
                        </Box>
                    </ColumnLayout>
                </div>
                {isIndirectTaxReport && !!indirectTaxRecords &&
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="m">
                            <ATPHorizontalRadioGroup
                                data-class="basicModalRadioGroup"
                                onChange={(value) => setTaxRateChangeIndicator(value === INDIRECT_TAX_RATE_CHANGE_INDICATOR[0].value ? true : undefined)}
                                value={taxRateChangeIndicator ? INDIRECT_TAX_RATE_CHANGE_INDICATOR[0].value : INDIRECT_TAX_RATE_CHANGE_INDICATOR[1].value}
                                items={INDIRECT_TAX_RATE_CHANGE_INDICATOR}
                            />
                            <Button
                                className={indirectTaxRecordsLoading ? "" : "blueIcon"}
                                disabled={indirectTaxRecordsLoading}
                                onClick={() => handleRefresh()}
                                iconName="refresh"
                                variant="icon"
                            />
                            <Pagination
                                currentPageIndex={selectedPage}
                                pagesCount={loadedPage}
                                onNextPageClick={({ detail }) => {
                                    handleNextPageClick(detail.requestedPageIndex);
                                }}
                                onChange={({ detail }) => {
                                    dispatch(ACTIONS.SET_SELECTED_PAGE.withPayload(detail.currentPageIndex));
                                    dispatch(ACTIONS.SET_INDIRECT_TAX_CHANGE_RECORD_LIST.withPayload(cache[detail.currentPageIndex]));
                                }}
                                onPreviousPageClick={({ detail }) => {
                                    dispatch(ACTIONS.SET_SELECTED_PAGE.withPayload(detail.requestedPageIndex));
                                    dispatch(ACTIONS.SET_INDIRECT_TAX_CHANGE_RECORD_LIST.withPayload(cache[detail.requestedPageIndex]));
                                }}
                                openEnd={!!indirectTaxRecords.lastEvaluatedKey}
                            />
                        </SpaceBetween>

                    </Box>
                }
                { getReportName() == null || reportData == null ?
                    <Box textAlign="center" padding="l" className="tpeTableEmptyMessage">
                        <StatusIndicator type="info">{'No data to display'}</StatusIndicator>
                    </Box>
                    :
                    <TPELoadingSpinner loading={isLoading || isReportDataLoading} loadingText={syncingReportData ? 'Running Pre-validation job' : 'Loading Pre-validation data'}>
                        <div className="tableContainer">
                            {(!isIndirectTaxReport || !reportData.reportStatus)
                                ? <PreValidationGrid loading={isLoading} />
                                : (reportData.reportStatus != CONSTANTS.REPORT_STATUS.SUCCESS
                                    && indirectTaxRecords?.indirectTaxChangeRecordList?.length == 0
                                    ? <Box textAlign="center">There are no records available. Please refresh the table.</Box>
                                    : <PreValidationIndirectTaxTableGrid loading={isLoading} />
                                )}
                        </div>
                    </TPELoadingSpinner>
                }
            </SpaceBetween>

            <TPEErrorWatcher services={services} errors={[reportFetchError, indirectTaxReportFetchError]} />

            <Modal
                onDismiss={() => setLastReportStatusModal(false)}
                visible={lastReportStatusModal}
                header="Last report status"
            >
                {reportData != null ? reportData.statusMessage : 'Report failed due to an unknown error.'}
            </Modal>

        </Container>
    </PreValidationProvider>
}