import React, { useEffect, useState } from 'react';
import { Autosuggest, Box, Button, Checkbox, Icon, Link, Popover, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { COACategoryDrillDown, COADrillDown, DataKeyInput, GLBalance, emptyGLBalance } from 'src/models/common/DataSourceRecord';
import { TPELoadingSpinner } from '../../shared/TPELoadingSpinner';
import CONSTANTS from 'src/utils/constants';
import CustomCOASelectorModal from './CustomCOASelectorModal';
import StringUtils from 'src/utils/stringUtils';
import ServiceCollection from 'src/services/ServiceCollection';
import TPEAction from 'src/models/common/TPEAction';
import 'src/assets/styles/MagicBox.scss';
import { CommonMagicBoxActions } from 'src/services/common/CommonDataSourceOperations';
import Placeholder from 'src/models/tp-allocation/Placeholder';

export default function DataSourcesMagicBox(props: {
    recordBeingEdited: any | undefined,
    services: ServiceCollection,
    dispatch: React.Dispatch<TPEAction>,
    showDecimals: boolean,
    coaTypes: string[],
    sevenSegmentLOV: Map<string, string[]>,
    selectedCustomCoaValues?: Map<string, string>,
    placeholdersMap?: Map<string, Placeholder[]>,
    allowBalancePull?: boolean,
    ACTIONS: CommonMagicBoxActions
}) {
    const {recordBeingEdited, services, dispatch, coaTypes, showDecimals, selectedCustomCoaValues, sevenSegmentLOV, placeholdersMap, allowBalancePull = true, ACTIONS } = props;
    const {
        datasourceId = '',
        glBalances = null,
        glBalanceDrillDownKeys = null,
        glBalanceDrillDown = null,
        dataKeyInput = { selectedCompanies: '', selectedAccounts: '', selectedCOA: new Map<string, string>() },
        expandedCategories = [],
        expandedAccount = '',
        drillDownCOASelected = '',
        errors = new Map<string, string>(),
        balancePullInProgress = false,
        pullBalanceError = '',
        isEditing = false,
    } = recordBeingEdited || {};
    const { selectedCompanies, selectedAccounts, selectedCOA } = dataKeyInput;

    const [drillDownKey, setDrillDownKey] = useState<string|null>(null);
    const [balanceDrillDown, drillDownLoading, drillDownError] = services.dataSourcesService.getBalanceBreakdown(drillDownCOASelected, drillDownKey);

    useEffect(() => {
        if (drillDownCOASelected == null || glBalanceDrillDownKeys == null || !glBalanceDrillDownKeys.has(drillDownCOASelected)) {
            return;
        }
        const drillDownKey = glBalanceDrillDownKeys.get(drillDownCOASelected);
        setDrillDownKey(drillDownKey == undefined ? null : drillDownKey)
    }, [drillDownCOASelected])

    useEffect(() => {
        if (balanceDrillDown == null) {
            return;
        }
        dispatch(ACTIONS.SET_BALANCE_DRILL_DOWN.withPayload(balanceDrillDown));
    }, [balanceDrillDown])

    const getCount = (stringListOfItems: string) => {
        if (stringListOfItems == undefined || stringListOfItems.trim() == '') {
            return 0;
        }
        return stringListOfItems.split(',').length;
    };

    const pullBalance = () => {
        dispatch(ACTIONS.SET_FX_INPUTS);
        dispatch(ACTIONS.REQUEST_BALANCE_PULL.withPayload({ recordID: datasourceId, value: true }));
    };

    const buildDataKeyCounter = () => {
        const counterArray: any[] = [];
        coaTypes.filter((coaType) => selectedCOA.has(coaType)).map((coaType) => counterArray.push({ key: coaType, count: getCount(selectedCOA.get(coaType) || '') }));
        return counterArray;
    }

    return (
        <div className="dataKeyTable">
            <div>
                <span className="dataKeyCounterSpacer">Company: {getCount(selectedCompanies)}</span>
                <span className="dataKeyCounterSpacer">GL Accounts: {getCount(selectedAccounts)}</span>
                {buildDataKeyCounter().map((dataKey) => <span className="dataKeyCounterSpacer">{dataKey.key}: <Popover className={dataKey.key.replace(' ', '_') + 'Popover dataKeyCoaCounter'} size="large" dismissButton={false} content={selectedCOA.get(dataKey.key)}>{dataKey.count}</Popover></span>)}
            </div>
            <div className="dataKeyTableMainSelection">
                <div className="dataKeyTableContainerSelection">
                    {glBalances != null ?
                        <React.Fragment>
                            <DataKeyGridLeftColumn key="dkgl" 
                                expandedAccount={expandedAccount} glBalances={glBalances} 
                                glBalanceDrillDown={glBalanceDrillDown} dispatch={dispatch} 
                                ACTIONS={ACTIONS} expandedCategories={expandedCategories} />
                            <DataKeyGridRightColumn key="dkrl" 
                                services={services} dispatch={dispatch} showDecimals={showDecimals} 
                                glBalances={glBalances} glBalanceDrillDown={glBalanceDrillDown} 
                                expandedAccount={expandedAccount} ACTIONS={ACTIONS}
                                expandedCategories={expandedCategories}/>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <DataKeyInputLeftColumn key="dkil" coaTypes={coaTypes} selectedCOA={selectedCOA} errors={errors} />
                            <DataKeyInputRightColumn key="dkir" 
                                isEditing={isEditing} 
                                dataKeyInput={dataKeyInput} 
                                services={services}
                                dispatch={dispatch}
                                ACTIONS={ACTIONS}
                                onSelectedCOAApplied={(payload:any)=> {ACTIONS.SET_SELECTED_CUSTOM_COA.withPayload(payload)}}
                                coaTypes={coaTypes} 
                                sevenSegmentLOV={sevenSegmentLOV} 
                                selectedCustomCoaValues={selectedCustomCoaValues}
                                placeholdersMap={placeholdersMap} />
                        </React.Fragment>
                    }
                </div>
                { pullBalanceError !== ''? 
                    <Box textAlign="center" padding="l"><StatusIndicator data-class="smallStatusIndicator pullBalanceError" type='error'>{pullBalanceError}</StatusIndicator></Box> : 
                    <TPELoadingSpinner loading={balancePullInProgress || false} loadingText="Pulling balance">
                        <span></span>
                    </TPELoadingSpinner>
                }
                {allowBalancePull && isEditing &&
                    <div className="dataKeyTableMainSelectionButton">
                        {glBalances == null && <Box textAlign="center"><Button disabled={balancePullInProgress} className="smallActionButton pullBalanceButton" onClick={pullBalance}>Pull balance</Button></Box>}
                    </div>
                }
            </div>
        </div>
    )
}

function COAErrorDisplay(props: { coaSegment: string, errors: Map<string, string> }) {
    const { coaSegment, errors } = props;
    
    if (errors.has(coaSegment)) {
        const coaErrors = errors.get(coaSegment) || '';
        if (coaErrors.includes('Invalid value(s): ')) {
            return <span className="dataSourceInlineError">
                <Popover className="dataKeyCOAErrorPopover" size="large" position="top" dismissButton={false} content={coaErrors.replace('Invalid value(s): ', '')}>*Invalid value(s):</Popover>
            </span>;
        } else {
            return <span className="dataSourceInlineError">*{coaErrors}</span>;
        }
    }
    return <span>&nbsp;</span>;
}

function DataKeyInputLeftColumn(props: {coaTypes: string[], selectedCOA: Map<string, string>, errors: Map<string, string>}) {
    const { coaTypes, selectedCOA, errors } = props;

    return (
        <div className="magicGridStickLeft">
            <div className="magicGridLeft"><span>Company*</span><COAErrorDisplay coaSegment={CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI} errors={errors}/></div>
            <div className="magicGridLeft"><span>GL Account*</span><COAErrorDisplay coaSegment={CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI} errors={errors}/></div>
            {selectedCOA != undefined && coaTypes.filter((coaType) => selectedCOA.has(coaType)).map((coaType) =>
                <div className="magicGridLeft"><span>{coaType}</span><COAErrorDisplay coaSegment={coaType} errors={errors}/></div>)
            }
        </div>
    )
}

function COAAutosugest(props: { className: string, 
                                lov: string[] | undefined,
                                coaType: string,
                                initialValue: string,
                                isEditing: boolean,
                                selectedCustomCoaValues?: Map<string, string>,
                                placeholders?: Placeholder[],
                                onBlur: (coaType: string, value: string) => any,
                                handleCustomRollup: () => any }) {
    const { className, lov, coaType, initialValue, onBlur, handleCustomRollup,isEditing, selectedCustomCoaValues, placeholders = [] } = props;
    const [options, setOptions] = React.useState([] as any[]);
    const [value, setValue] = React.useState(initialValue);

    const customRollupValue = 'Custom rollup';
    const customRollupOption = { value: customRollupValue };

    useEffect(() => {
        if (selectedCustomCoaValues?.has(coaType)) {
            const addedComma = StringUtils.isNullOrEmpty(value) ? '' : (value.trim().charAt(value.length - 1) == ',' ? '' : ', ');
            const newValue = (value || '') + addedComma + (selectedCustomCoaValues.get(coaType) || '');
            handleChange(newValue);
        }
    }, [selectedCustomCoaValues])

    const handleLoadItems = (event: any) => {
        let { filteringText } = event.detail;

        if (filteringText != null && filteringText.includes(',')) {
            filteringText = filteringText.substring(filteringText.lastIndexOf(',')).replace(',', '').replace(' ', '');
        }

        const placeholderOptions = placeholders.map(x => { return {value: `<${x.placeholderName}>`} });

        if (filteringText == null || filteringText == '') {
            setOptions([...placeholderOptions, customRollupOption]);
            return;
        }

        const filteredOptions = lov?.filter(x => x.toLowerCase().startsWith(filteringText.toLowerCase())).map((val) => { return {value: val} });
        setOptions(filteredOptions ? [placeholderOptions, customRollupOption, ...filteredOptions] : [customRollupOption]);
    }

    const beautifyCOAValue = () => {
        if (value == null) {
            return '';
        }
        const valueArr = value.split(',');
        // Trim and remove duplicates
        const beautifiedArr = valueArr.filter(x => x.trim() != '').map(x => x.trim()).filter((value, index, self) => index == self.indexOf(value));
        const beautifiedString = beautifiedArr.join(', ');
        setValue(beautifiedString);
        return beautifiedString;
    }

    const handleChange = (selectedValue: any) => {
        if (selectedValue == customRollupValue) {
            return;
        }
        setValue(selectedValue);
    }

    const handleSelect = (selectedValue: string) => {
        if (selectedValue == customRollupValue) {
            handleCustomRollup();
            return;
        }

        if (value.includes(',')) {
            const newValue = value.substring(0, value.lastIndexOf(',')) + ', ' +  selectedValue;
            setValue(newValue);
        } else {
            setValue(selectedValue);
        }
    }

    return (
        <Autosuggest
            data-class={`coaAutosuggest ${className}`}
            onChange={({ detail }) => handleChange(detail.value)}
            onBlur={() => { onBlur(coaType, beautifyCOAValue()); }}
            onSelect={({ detail }) => handleSelect(detail.value)}
            value={value}
            options={options}
            enteredTextLabel={val => `Use: "${val}"`}
            placeholder="Select or enter comma-separated values"
            empty="No matches found"
            loadingText="Loading"
            onLoadItems={handleLoadItems}
            filteringType="manual"
            statusType={lov == undefined ? 'loading' : 'finished'}
            disabled={!isEditing}
        />
    )
}

function DataKeyInputRightColumn(props: {
            isEditing: boolean,
            dataKeyInput: DataKeyInput,
            services: ServiceCollection,
            dispatch: React.Dispatch<TPEAction>, 
            coaTypes: string[], 
            sevenSegmentLOV: Map<string, string[]>,
            selectedCustomCoaValues?: Map<string, string>,
            placeholdersMap?: Map<string, Placeholder[]>,
            ACTIONS: CommonMagicBoxActions,
            onSelectedCOAApplied: (payload:any) => void
        }
    ) {
    const { isEditing, services, dispatch, coaTypes, sevenSegmentLOV, dataKeyInput, ACTIONS, selectedCustomCoaValues, placeholdersMap } = props;
    const { selectedCompanies, selectedAccounts, selectedCOA } = dataKeyInput;

    // This is needed for refreshing the auto suggest components for viewing the data key for different records in succession
    // The disabled auto suggest does not refresh with the updated values without changing the key
    const [keyCount, setKeyCount] = useState(0);
    const [showCustomCoaModal, setShowCustomCoaModal] = useState(false);
    const [customCoaSegment, setCustomCoaSegment] = useState('');
    const [customCoaType, setCustomCoaType] = useState('');

    useEffect(() => {
        setKeyCount(keyCount + 1);
    }, [isEditing])

    const setSelectedDataKey = (key: string, value: string) => {
        dispatch(ACTIONS.SET_DATA_KEY_INPUT.withPayload({ key: key, value: value }));
    };

    const setSelectedCoa = (key: string, value: string) => {
        dispatch(ACTIONS.SET_DATA_KEY_COA_INPUT.withPayload({ key: key, value: value }));
    };

    const handleCustomRollup = (coaSegment: string, coaType: string) => {
        setCustomCoaSegment(coaSegment);
        setCustomCoaType(coaType);
        setShowCustomCoaModal(true);
    }

    const handleCustomRollupClose = () => {
        setShowCustomCoaModal(false);
        setCustomCoaSegment('');
    }

    return (
        <div className="magicGridRightContainer">
            <div className="magicGridRightInput">
                <COAAutosugest
                    key={`companyAutoSuggest${keyCount}`}
                    className="companiesInput"
                    coaType="selectedCompanies"
                    initialValue={selectedCompanies}
                    onBlur={setSelectedDataKey}
                    lov={sevenSegmentLOV.get(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI)}
                    isEditing={isEditing}
                    selectedCustomCoaValues={selectedCustomCoaValues}
                    placeholders={placeholdersMap != null ? placeholdersMap.get(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI) : undefined}
                    handleCustomRollup={() => handleCustomRollup(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI, 'selectedCompanies')}
                />
            </div>
            <div className="magicGridRightInput">
                <COAAutosugest
                    key={`accountAutoSuggest${keyCount}`}
                    className="accountsInput"
                    coaType="selectedAccounts"
                    initialValue={selectedAccounts}
                    onBlur={setSelectedDataKey}
                    lov={sevenSegmentLOV.get(CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI)}
                    isEditing={isEditing}
                    selectedCustomCoaValues={selectedCustomCoaValues}
                    placeholders={placeholdersMap != null ? placeholdersMap.get(CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI) : undefined}
                    handleCustomRollup={() => handleCustomRollup(CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI, 'selectedAccounts')}
                />
            </div>
            {selectedCOA != undefined && coaTypes.filter((coaType) => selectedCOA.has(coaType)).map((coaType) =>
                <div className="magicGridRightInput">
                    <COAAutosugest
                        key={`${coaType.replace(' ', '_')}AutoSuggest${keyCount}`}
                        className={`${coaType.replace(' ', '_')}Input`}
                        coaType={coaType}
                        initialValue={selectedCOA.get(coaType) || ''}
                        onBlur={setSelectedCoa}
                        lov={sevenSegmentLOV.get(coaType)}
                        isEditing={isEditing}
                        selectedCustomCoaValues={selectedCustomCoaValues}
                        placeholders={placeholdersMap != null ? placeholdersMap.get(coaType) : undefined}
                        handleCustomRollup={() => handleCustomRollup(coaType, coaType)}
                    />
                </div>
            )}
            <CustomCOASelectorModal 
                visible={showCustomCoaModal}
                coaSegment={customCoaSegment}
                coaType={customCoaType}
                services={services}
                onSelectedCOAApplied={(payload:any)=> {dispatch(ACTIONS.SET_SELECTED_CUSTOM_COA.withPayload(payload))}}
                onClose={handleCustomRollupClose} />
        </div>
    )
}

function DataKeyGridLeftColumn(props: { 
        expandedAccount: string, 
        glBalances: GLBalance[], 
        glBalanceDrillDown: Map<string, COADrillDown> | null,
        dispatch: React.Dispatch<TPEAction>,
        ACTIONS: CommonMagicBoxActions,
        expandedCategories: string[]
    }) {
    const { expandedAccount, glBalances, glBalanceDrillDown, dispatch, ACTIONS, expandedCategories } = props;

    const glBalance: GLBalance = (glBalances != null && glBalances.length > 0) ? glBalances[0] : emptyGLBalance;
    const glBalanceDrillDownForCompanyAccount = glBalanceDrillDown?.get(`${glBalance.company}-${glBalance.account}`);

    return (
        <div className="magicGridStickLeft">
            <div className="magicGridLeft">Company*</div>
            <div className="magicGridLeft">GL Account*</div>
            <div className="magicGridLeftBalance">Total Balance</div>
            {expandedAccount != '' && <div className="magicGridLeftAccountExpand">Internet Expense - Capital Lease</div>}
            <div className="magicGridStickLeft">
                {glBalanceDrillDown != null &&
                    <React.Fragment>
                        <div className={'magicGridLeftCOA ' + glBalanceDrillDownForCompanyAccount?.coa.replace(' ', '_') + 'DrillDown'}>{glBalanceDrillDownForCompanyAccount?.coa}</div>
                        <DataKeyCOADrillDownLabels 
                            drillDowns={glBalanceDrillDownForCompanyAccount?.coaCategoryDrillDowns} 
                            firstLevel={true} 
                            dispatch={dispatch} 
                            ACTIONS={ACTIONS}
                            expandedCategories={expandedCategories} />
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

function DataKeyCOADrillDownLabels(props: { 
    drillDowns: COACategoryDrillDown[] | undefined, 
    firstLevel?: boolean, 
    highlighted?: boolean, 
    parentCategory?: string,
    dispatch: React.Dispatch<TPEAction>,
    ACTIONS: CommonMagicBoxActions,
    expandedCategories: string[]
 }) {
    const { drillDowns, firstLevel = false, highlighted = false, parentCategory = '', 
            dispatch, ACTIONS, expandedCategories } = props;

    if (drillDowns == undefined || drillDowns.length == 0) {
        return null;
    }

    const getIconName = (coaCategory: string) => {
        return (expandedCategories.includes(coaCategory) ? 'treeview-collapse' : 'treeview-expand');
    }

    const toggleCategory = (coaCategory: string) => {
        dispatch(ACTIONS.TOGGLE_CATEGORY_EXPANSION.withPayload(coaCategory));
    }

    const getClassName = (coaCategory: string) => {
        if (expandedCategories.includes(coaCategory)) {
            return (highlighted ? 'magicGridLeftBalance magicGridLeftCoaCategory' : 'magicGridLeftBalanceHighlighted magicGridLeftCoaCategory');
        } else {
            return (highlighted ? 'magicGridLeftBalanceHighlighted magicGridLeftCoaCategory' : 'magicGridLeftBalance magicGridLeftCoaCategory');
        }
    }

    return (
        <React.Fragment>
            {drillDowns.map(drillDown =>
                <React.Fragment>
                    <div className={getClassName(drillDown.coaCategory) + ' ' + parentCategory + 'SubCat'}>
                        <div>{firstLevel  && <Checkbox className="magicGridCheckbox" checked={false}></Checkbox>}</div>
                        <div className="magicGridTreeview">{drillDown.subCategories != undefined && <Link className={drillDown.coaCategory + 'Toggle'} onFollow={() => toggleCategory(drillDown.coaCategory)}><Icon name={getIconName(drillDown.coaCategory)} size="small" variant="link" /></Link>}</div>
                        <div>{drillDown.coaCategory}</div>
                        <div className="coaDrillDownCategoryDesc">{drillDown.description}</div>
                    </div>
                    {expandedCategories.includes(drillDown.coaCategory) && <DataKeyCOADrillDownLabels ACTIONS={ACTIONS} drillDowns={drillDown.subCategories} highlighted={!highlighted} parentCategory={drillDown.coaCategory} dispatch={dispatch} expandedCategories={expandedCategories} />}
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

function DataKeyGridRightColumn(props: {
        services: ServiceCollection,
        dispatch: React.Dispatch<TPEAction>,
        ACTIONS: CommonMagicBoxActions,
        showDecimals: boolean,
        glBalances: GLBalance[], 
        glBalanceDrillDown: Map<string, COADrillDown> | null,
        expandedAccount: string,
        expandedCategories: string[]
    }) {
    const { dispatch, ACTIONS, services, showDecimals, glBalances, glBalanceDrillDown, expandedAccount, expandedCategories } = props;

    const getIconName = (account: string) => {
        return (expandedAccount == account ? 'treeview-collapse' : 'treeview-expand');
    }

    const getClassName = (account: string) => {
        return (expandedAccount == account ? 'magicGridRightHighlighted' : 'magicGridRight');
    }

    
    return (
        <React.Fragment>
            {glBalances?.map(glBalance =>
                <div className="magicGridRightContainer">
                    <div className="magicGridRight">{glBalance.company}</div>
                    <div className={getClassName(glBalance.account)}>{glBalance.account}</div>
                    <div className={getClassName(glBalance.account) + ' balanceRightDiv'}>{services.formattingService.formatNumber(glBalance.balance, showDecimals)}</div>
                    {expandedAccount != '' && <div className="magicGridRightAccountExpand">&nbsp;</div>}
                    {glBalanceDrillDown != null &&
                        <React.Fragment>
                            <div className="magicGridRightCOA">&nbsp;</div>
                            <DataKeyCOADrillDownValues drillDowns={glBalanceDrillDown.get(`${glBalance.company}-${glBalance.account}`)?.coaCategoryDrillDowns} services={services} showDecimals={showDecimals} expandedCategories={expandedCategories} />
                        </React.Fragment>
                    }
                </div>
            )}
        </React.Fragment>
    )
}

function DataKeyCOADrillDownValues(props: { 
        drillDowns: COACategoryDrillDown[] | undefined, 
        highlighted?: boolean, 
        expandedCategories: string[], 
        services: ServiceCollection, 
        showDecimals: boolean 
    }) {
    const { drillDowns, highlighted = false, expandedCategories, services, showDecimals } = props;

    const getClassName = (coaCategory: string) => {
        if (expandedCategories.includes(coaCategory)) {
            return (highlighted ? 'magicGridRight' : 'magicGridRightHighlighted');
        } else {
            return (highlighted ? 'magicGridRightHighlighted' : 'magicGridRight');
        }
    }

    if (drillDowns == undefined || drillDowns.length == 0) {
        return null;
    }

    return (
        <React.Fragment>
            {drillDowns.map(drillDown =>
                <React.Fragment>
                    <div className={getClassName(drillDown.coaCategory)}>{services.formattingService.formatNumber(drillDown.balance, showDecimals)}</div>
                    {drillDown.subCategories != undefined && expandedCategories.includes(drillDown.coaCategory) &&
                        <DataKeyCOADrillDownValues drillDowns={drillDown.subCategories} highlighted={!highlighted} services={services} showDecimals={showDecimals} expandedCategories={expandedCategories} />
                    }
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

/**
 * Utility function to return balance pull status information as text
 * @param balancePullingTask The WebSocketMessage containing balance pull status
 * @returns A string with balance pull status information
 */
/*function getBalancePullStatusText(balancePullingTask?: PullBalanceWSMessage): string {
    const text = balancePullingTask?.status.statusType.toLowerCase().replace('_','') || 'requesting';
    const progress = balancePullingTask?.status.percentage || -1;
    return `${text} ${progress === -1? '' : progress+'%'}`;
}*/