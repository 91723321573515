import { SearchCustomCOARowsResult } from "src/models/custom-coa/SearchCustomCOARowsResult";

export interface CustomCOARowsState {
    coaLov: string[]
    searchPayload?: any
    searchResult?: SearchCustomCOARowsResult
}

export const defaultSearchPayload = {
    page: 1,
    pageSize: 20,
}

export const initialState: CustomCOARowsState = {
    coaLov: []
}