import CONSTANTS from "src/utils/constants";
import {COADataKey, PullGLBalanceRequest} from "src/models/calculation-builder/PullBalanceRequest";
import TPEAction from "src/models/common/TPEAction";
import { isMatch, isValid } from "date-fns";
import { DataSourceRecord } from "src/models/common/DataSourceRecord";
import { DatasetRecord } from "src/models/tp-allocation/DatasetRecord";
import Placeholder from "src/models/tp-allocation/Placeholder";

const CommonDataSourceOperations = {
    convertToDataKeyJoinedString: function(coaDataKeyList : COADataKey[] | undefined) : string {
        return (coaDataKeyList || []).map(coaDataKey => this.convertToDataKeyString(coaDataKey)).join(', ');
    },  
    convertToDataKeyString: function(coaDataKey : COADataKey) : string {
        if (coaDataKey.type === CONSTANTS.COA_DATA_KEY_TYPE.CUSTOM) {
            return `[${coaDataKey.tableName}:${coaDataKey.classification}]`;
        }
        return coaDataKey.value || '';
    },  
    updateCustomCoaReferenceMap: function(apiDataKeys: any, customCoaReferenceMap: Map<string, string>) {
      for (const [key, value] of Object.entries(apiDataKeys)) {
          const coaDataKeyList = value as COADataKey[];
          coaDataKeyList.forEach(x => {
              if (x.type == CONSTANTS.COA_DATA_KEY_TYPE.CUSTOM) {
                  customCoaReferenceMap.set(`[${x.tableName}:${x.classification}]`, x.tableId as unknown as string)
              }
          })
      }
    },
    convertToCOADataKeyList: function(dataKeyString: string | undefined, customCoaReference: Map<string, string>, placeholders?: Placeholder[]) : COADataKey[] {
        return (dataKeyString || '').split(',').map(dataKey => this.convertToCOADataKey(dataKey.trim(), customCoaReference, placeholders));
    },
    convertToCOADataKey: function(dataKey: string, customCoaReference: Map<string, string>, placeholders?: Placeholder[]) : COADataKey {
        if (dataKey.startsWith('[') && dataKey.endsWith(']')) {
            const [tableName, classification] = dataKey.substring(1, dataKey.length - 1).split(':');
            return {
                type: CONSTANTS.COA_DATA_KEY_TYPE.CUSTOM,
                tableId: customCoaReference.get(dataKey),
                tableName,
                classification
            };
        }
        if (dataKey.startsWith('<') && dataKey.endsWith('>')) {
            if (placeholders != null) {
                const placeholderObject = placeholders.find(x => x.placeholderName == dataKey.replace('<', '').replace('>', ''));
                return {
                    type: CONSTANTS.COA_DATA_KEY_TYPE.STANDARD,
                    value: placeholderObject != null ? `<${placeholderObject.placeholderId}>` : dataKey
                }
            }
        }
        return {
            type: CONSTANTS.COA_DATA_KEY_TYPE.STANDARD,
            value: dataKey
        }
    },
    checkAndAddRequiredFieldError: function(field: string | undefined, errorMap: Map<string, string>, displayText: string) {
        if (field == null || field.trim() == '') {
            errorMap.set(displayText, 'Required');
        }
    },
    validateInputsForPullBalance: function(dataSourceRecord: {
            datasource?: string, 
            period?: string, 
            customPeriod?: string, 
            fxDate?: string, 
            fxType?: string, 
            currency?: string,
            dataKeyInput? : {
                selectedCompanies: string,
                selectedAccounts: string,
                selectedCOA: Map<string, string>,
            }} | undefined, 
            validateTemplateDataSource: boolean): Map<string, string> {
        if (dataSourceRecord == undefined) {
            return new Map<string, string>();
        }

        const errorMap = new Map<string, string>();

        const { dataKeyInput, datasource = '', period = '', customPeriod, fxDate = '', fxType = '', currency = '' } = dataSourceRecord;
        const { selectedCompanies = '', selectedAccounts = '' } = dataKeyInput || {};

        this.checkAndAddRequiredFieldError(selectedCompanies, errorMap, CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI);
        this.checkAndAddRequiredFieldError(selectedAccounts, errorMap, CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI);
        this.checkAndAddRequiredFieldError(datasource, errorMap, CONSTANTS.DATA_SOURCE_FIELDS.DATA_SOURCE.ACCESSOR);
        if (customPeriod == null) {
            this.checkAndAddRequiredFieldError(period, errorMap, CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR);
        }
        // Only currency can be provided - FX type is defaulted to 'Period Average' and FX date is defaulted to previous month end date
        // Only currency and FX type 'Period Average' or 'Period End' can be provided - the FX date is defaulted to previous month end date
        // For STAT currency, FX date and type provided are discarded
        // In all other cases, all 3 inputs are required
        // If a non-month end date is provided for FX type 'Period Average' or 'Period End', it is set to the month end of the date provided
        // All defaulting/updating of params happens in the DataSourcesReducer, only the validation is happening here
        if (currency != CONSTANTS.STAT_CURRENCY) {
            if (fxDate.trim() != '' || (fxType.trim() != '' && fxType != CONSTANTS.FX_TYPE_PERIOD_AVERAGE && fxType != CONSTANTS.FX_TYPE_PERIOD_END)) {
                this.checkAndAddRequiredFieldError(fxDate, errorMap, CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR);
                this.checkAndAddRequiredFieldError(fxType, errorMap, CONSTANTS.DATA_SOURCE_FIELDS.FX_TYPE.ACCESSOR);
                this.checkAndAddRequiredFieldError(currency, errorMap, CONSTANTS.DATA_SOURCE_FIELDS.CURRENCY.ACCESSOR);

                if (fxDate.trim() != '') {
                    if (!isMatch(fxDate, 'yyyy-MM-dd')) {
                        errorMap.set(CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR, 'Invalid format');
                    } else if (!isValid(new Date(fxDate))) {
                        errorMap.set(CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR, 'Invalid date');
                    }
                }
            } else if (fxType == CONSTANTS.FX_TYPE_PERIOD_AVERAGE || fxType == CONSTANTS.FX_TYPE_PERIOD_END) {
                this.checkAndAddRequiredFieldError(currency, errorMap, CONSTANTS.DATA_SOURCE_FIELDS.CURRENCY.ACCESSOR)
            } else if (validateTemplateDataSource && currency.trim() == '') {
                this.checkAndAddRequiredFieldError(currency, errorMap, CONSTANTS.DATA_SOURCE_FIELDS.CURRENCY.ACCESSOR)
            }
        }
        return errorMap;
    },
    convertToPullBalanceRequest(dataSourceRecord: DataSourceRecord | DatasetRecord | undefined, customCoaReference: Map<string, string>, breakdownByCOA?: boolean): PullGLBalanceRequest {
        if (dataSourceRecord == undefined) {
            return null as unknown as PullGLBalanceRequest;
        }

        const pullBalanceRequest: PullGLBalanceRequest = {
            periodType: dataSourceRecord.customPeriod != null ? CONSTANTS.DATA_SOURCE_VALUES.CUSTOM_PERIOD: (CONSTANTS.DATA_SOURCE_PERIOD_GL_MAPPING as any)[dataSourceRecord.period],
            coaDataKeys: {
                companies: this.convertToCOADataKeyList(dataSourceRecord.dataKeyInput?.selectedCompanies, customCoaReference),
                accounts: this.convertToCOADataKeyList(dataSourceRecord.dataKeyInput?.selectedAccounts, customCoaReference),
            },
            breakdownByCOA: breakdownByCOA == null ? false: breakdownByCOA,
            dataSourceId: dataSourceRecord.datasourceId,
            closePeriodsOnly: true
        }

        if (dataSourceRecord.customPeriod != null) {
            const customPeriodArr = dataSourceRecord.customPeriod.split('-').map(x => x.replace('/', '-'));
            pullBalanceRequest.startPeriod = customPeriodArr[0].trim();
            pullBalanceRequest.endPeriod = customPeriodArr[1].trim();
        }
        
        if (dataSourceRecord.dataKeyInput != null) {
            if (dataSourceRecord.dataKeyInput.selectedCOA.has(CONSTANTS.COA_SEGMENT_MAPPING.COST_CENTER.UI)) {
                pullBalanceRequest.coaDataKeys.costCenters = this.convertToCOADataKeyList(dataSourceRecord.dataKeyInput.selectedCOA.get(CONSTANTS.COA_SEGMENT_MAPPING.COST_CENTER.UI), customCoaReference);
            }
            if (dataSourceRecord.dataKeyInput.selectedCOA.has(CONSTANTS.COA_SEGMENT_MAPPING.PRODUCT_LINE.UI)) {
                pullBalanceRequest.coaDataKeys.productLines = this.convertToCOADataKeyList(dataSourceRecord.dataKeyInput.selectedCOA.get(CONSTANTS.COA_SEGMENT_MAPPING.PRODUCT_LINE.UI), customCoaReference);
            }
            if (dataSourceRecord.dataKeyInput.selectedCOA.has(CONSTANTS.COA_SEGMENT_MAPPING.LOCATION.UI)) {
                pullBalanceRequest.coaDataKeys.locations = this.convertToCOADataKeyList(dataSourceRecord.dataKeyInput.selectedCOA.get(CONSTANTS.COA_SEGMENT_MAPPING.LOCATION.UI), customCoaReference);
            }
            if (dataSourceRecord.dataKeyInput.selectedCOA.has(CONSTANTS.COA_SEGMENT_MAPPING.CHANNEL.UI)) {
                pullBalanceRequest.coaDataKeys.geoMarkets = this.convertToCOADataKeyList(dataSourceRecord.dataKeyInput.selectedCOA.get(CONSTANTS.COA_SEGMENT_MAPPING.CHANNEL.UI), customCoaReference);
            }
            if (dataSourceRecord.dataKeyInput.selectedCOA.has(CONSTANTS.COA_SEGMENT_MAPPING.PROJECT.UI)) {
                pullBalanceRequest.coaDataKeys.projects = this.convertToCOADataKeyList(dataSourceRecord.dataKeyInput.selectedCOA.get(CONSTANTS.COA_SEGMENT_MAPPING.PROJECT.UI), customCoaReference);
            }
        }

        // FX params
        if (dataSourceRecord.fxType != null && dataSourceRecord.fxType.trim() != '') {
            pullBalanceRequest.fxType = dataSourceRecord.fxType;
        }
        if (dataSourceRecord.fxDate != null && dataSourceRecord.fxDate.trim() != '') {
            let d = new Date(Date.parse(dataSourceRecord.fxDate));
            pullBalanceRequest.fxDate = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
        }
        if (dataSourceRecord.currency != null && dataSourceRecord.currency.trim() != '') {
            pullBalanceRequest.currency = dataSourceRecord.currency;
        }

        return pullBalanceRequest;
    },
    validateCOAInput: function(coaType: string, coaInput: string, lov: string[], customCoaReferenceMap: Map<string, string>, 
        errorMap: Map<string, string>, placeholdersMap?: Map<string, Placeholder[]>) {
        if (coaInput == '') {
            return;
        }
        const coaInputArr = coaInput.split(', ').map(x => x.trim());
        const invalidLOV = coaInputArr.filter(x => !lov.includes(x));
        const invalidValues: string[] = [];

        if (invalidLOV.length > 0) {
            invalidLOV.forEach(x => {
                if (x.startsWith('[') && x.endsWith(']')) {
                    if (!customCoaReferenceMap.has(x)) {
                        invalidValues.push(x);
                    }
                } else if (x.startsWith('<') && x.endsWith('>')) {
                    if (placeholdersMap == null || !placeholdersMap.has(coaType) || placeholdersMap.get(coaType)?.find(p => p.placeholderName == x.replace('<', '').replace('>', '')) == null) {
                        invalidValues.push(x);
                    }
                } else {
                    invalidValues.push(x);
                }
            })

            if (invalidValues.length > 0) {
                errorMap.set(coaType, 'Invalid value(s): ' + invalidValues.join(', '));
            }
        }
    },
    validateInputsForPullCDTValue: function(dataSourceRecord: DataSourceRecord | undefined): Map<string, string> {
        if (dataSourceRecord == undefined) {
            return new Map<string, string>();
        }

        const { customDatasourceValueId = '', customDatasourceTableId = '', customDatasourceCalculationNumber = '', customPeriod } = dataSourceRecord;

        const errorMap = new Map<string, string>();
        this.checkAndAddRequiredFieldError(customPeriod, errorMap, CONSTANTS.DATA_SOURCE_FIELDS.CUSTOM_PERIOD.ACCESSOR);
        if ( customDatasourceValueId === '' || customDatasourceTableId === '' || customDatasourceCalculationNumber === '' ){
            errorMap.set('CDT_VALUE', 'Required');
        }

        return errorMap;
    }
}

export interface CommonMagicBoxActions {
    SET_BALANCE_DRILL_DOWN: TPEAction,
    SET_FX_INPUTS: TPEAction,
    REQUEST_BALANCE_PULL: TPEAction,
    SET_DATA_KEY_INPUT: TPEAction,
    SET_DATA_KEY_COA_INPUT: TPEAction,
    TOGGLE_CATEGORY_EXPANSION: TPEAction,
    SET_SELECTED_CUSTOM_COA: TPEAction,
}

export default CommonDataSourceOperations;