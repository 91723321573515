import { useEffect, useState } from 'react';
import SearchAgreementLOVsResult from '../../models/search-agreements/SearchAgreementLOVsResult';
import AgreementStatusCategoriesResult from '../../models/agreements/AgreementStatusCategoriesResult';
import CalculationStatusesResult from '../../models/calculations/CalculationStatusesResult';
import AccountingOwnersResult from '../../models/agreements/AccountingOwnersResult';
import { SearchAgreementResult } from 'src/models/search-agreements/SearchAgreementResult';

import TaxOwnersResult from '../../models/agreements/TaxOwnersResult';
import apiService from '../ApiCallService'
import { AgreementDetailsResult } from '../../models/agreements/AgreementDetailsResult';
import { AgreementVersionsResult } from '../../models/agreements/AgreementVersionsResult';
import { Workbook } from '../../models/agreements/Workbook';
import { TPEUser } from '../../models/users/TPEUser';
import TPESearchRequest from 'src/models/common/TPESearchRequest';
import { CLIDetailsResult } from 'src/models/calculations/CLIDetailsResult';
import { CLI } from 'src/models/common/CLI';
import { delay } from 'q';
import ErrorUtils from 'src/utils/ErrorUtils';


/**
 * This class should only contain the API calls related to Apttus agreements
 */
export default class AgreementsService {
    /**
    * Pulls all available agreements
    * @param query An arbitrary string
    */
    getAgreements(query: string): [string[], boolean, string] {
        const [results, setResults] = useState([] as string[]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getAllAgreements();
                    const json = await response.json() as SearchAgreementLOVsResult;
                    setResults(json.agreementNumbers);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }

            if (query != null && query.length > 0) {
                fetchData();
            }
        }, [query]);
        return [results, loading, error];
    }


    /**
    * Pulls available agreement lines for the given agreement
    * @param agreement An Apttus agreement
    */
    getAgreementLines(agreement?: string): [string[], boolean, string] {
        const [results, setResults] = useState([] as string[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getAgreementLines(agreement as string);
                    const json = await response.json() as SearchAgreementLOVsResult;
                    setResults(json.agreementLineNumbers);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (agreement != null) {
                fetchData();
            }
        }, [agreement]);
        return [results, loading, error];
    }

    getAgreementStatusCategories(query: string) {
        const [results, setResults] = useState(null as unknown as AgreementStatusCategoriesResult);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');


        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getAgreementStatusCategories();
                    const result = await response.json();
                    setResults(new AgreementStatusCategoriesResult(result));
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (query != null && query.length > 0) {
                fetchData();
            }
        }, [query]);
        return [results, loading, error];
    }

    getAgreementLOVs(query: string): 
        [AgreementStatusCategoriesResult | null, 
            CalculationStatusesResult | null, 
            AccountingOwnersResult | null, 
            TaxOwnersResult | null, 
            Workbook[], 
            TPEUser[],
            boolean,
            string
        ] {
        const [categories, setCategories] = useState(null as null | AgreementStatusCategoriesResult);
        const [statuses, setStatuses] = useState(null as null | CalculationStatusesResult);
        const [accoutingOwners, setAccoutingOwners] = useState(null as null | AccountingOwnersResult);
        const [taxOwners, setTaxOwners] = useState(null as null | TaxOwnersResult);
        const [workbooks, setWorkbooks] = useState([] as Workbook[]);
        const [users, setUsers] = useState([] as TPEUser[]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');


        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const promises = (await Promise.all([
                        apiService.getAgreementStatusCategories(),
                        apiService.getCalculationStatuses(),
                        apiService.getAccountingOwners(),
                        apiService.getTaxOwners(),
                        apiService.getWorkbooks(),                        
                        apiService.getTPEUsers(),                        
                    ])).map(result => result.json());
                    const allData = await Promise.all(promises);
                    setCategories(new AgreementStatusCategoriesResult(allData[0]))
                    setStatuses(allData[1]);
                    setAccoutingOwners(allData[2]);
                    setTaxOwners(allData[3]);
                    setWorkbooks(allData[4].workbooks);
                    setUsers(allData[5].users);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (query != null && query.length > 0) {
                fetchData();
            }
        }, [query]);
        return [categories, statuses, accoutingOwners, taxOwners, workbooks, users, loading, error];
    }

    /**
    * Pulls details and CLIs for the given agreement
    * @param agreement An Apttus agreement
    */
    getAgreementDetails(agreement: string): [AgreementDetailsResult, boolean, string] {
        const [results, setResults] = useState<AgreementDetailsResult>(null as unknown as AgreementDetailsResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getAgreementDetails(agreement);
                    const json = await response.json() as AgreementDetailsResult;
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (agreement != null) {
                fetchData();
            }
        }, [agreement]);
        return [results, loading, error];
    }

    /**
    * Pulls the list of versions for the given agreement
    * @param recordId the record id of the agreement
    */
    getAgreementVersions(recordId: string | undefined): [AgreementVersionsResult, boolean, string] {
        const [results, setResults] = useState<AgreementVersionsResult>(new AgreementVersionsResult());
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getAgreementVersions(recordId as string);
                    const json = await response.json() as AgreementVersionsResult;
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (recordId != null && recordId.length > 0) {
                fetchData();
            }
        }, [recordId]);
        return [results, loading, error];
    }

    /**
    * Finds calculation lines based on payload parameters
    * @param agreementLine An Apttus agreement
    */
    searchAgreements(payload: TPESearchRequest) {
        const [results, setResults] = useState(null as unknown as SearchAgreementResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchAgreements(payload);
                    const json = await response.json() as SearchAgreementResult;
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }

            if (payload.searchStartedDate != null) {
                fetchData();
            }
        }, [payload.searchStartedDate]);
        return [results, loading, error];
    }

    /**
    * Saves the CLI workbook name change
    * @param payload The object containing updated data
    * @returns An array with the state of the async operation
    */
     updateCLIWorkbook(payload: any): [CLI | null, boolean, string] {
        const [updatedCLI, setupdatedCLI] = useState(null as null | CLI);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function saveData() {
                try {
                    setLoading(true);
                    const response = await apiService.updateCLIWorkbook(payload);
                    const json = await response.json() as CLIDetailsResult;
                    setupdatedCLI(json.cli);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                saveData();
            }
        }, [payload]);
        return [updatedCLI, loading, error];
    }


    /**
    * Saves the CLI's assignee change
    * @param payload The object containing updated data
    * @returns An array with the state of the async operation
    */
    updateCLICalcAssignee(payload: any): [CLI | null, boolean, string] {
        const [updatedCLI, setupdatedCLI] = useState(null as null | CLI);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function saveData() {
                try {
                    setLoading(true);
                    const response = await apiService.updateCLICalcAssignee(payload);
                    const json = await response.json() as CLIDetailsResult;
                    setupdatedCLI(json.cli);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                saveData();
            }
        }, [payload]);
        return [updatedCLI, loading, error];
    }

    /**
    * Saves the CLI's calc builder change
    * @param payload The object containing updated data
    * @returns An array with the state of the async operation
    */
     updateCLICalcBuilder(payload: any): [CLI | null, boolean, string] {
        const [updatedCLI, setupdatedCLI] = useState(null as null | CLI);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function saveData() {
                try {
                    setLoading(true);
                    const response = await apiService.updateCLICalcBuilder(payload);
                    const json = await response.json() as CLIDetailsResult;
                    setupdatedCLI(json.cli);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                saveData();
            }
        }, [payload]);
        return [updatedCLI, loading, error];
    }
}