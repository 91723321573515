import React from 'react';
import { Box, StatusIndicator } from '@amzn/awsui-components-react/polaris';

export function TPELoadingSpinner(props: { children: any, loading: boolean, loadingText? : string }) {
    const { loading, loadingText = 'Loading' } = props;
    if (loading) {
        return <Box textAlign="center" padding="l"><StatusIndicator data-class="smallStatusIndicator" type="loading">{loadingText}...</StatusIndicator></Box>
    } else {
        return props.children
    }
}