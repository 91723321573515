import CONSTANTS from 'src/utils/constants';
import {IndirectTaxChangeRecord} from "src/models/reports/FetchIndirectTaxChangeRecords";

export interface PreValidationReportState {
    selectedTab: string,
    selectedPage: number,
    loadedPage: number,
    isVisible: boolean,
    disableForceGenerateButton: boolean,
    cache: {[key: number]: IndirectTaxChangeRecord[]},
    indirectTaxChangeRecordList: IndirectTaxChangeRecord[],
    lastReportStatusModal: boolean,
    preValIndirectTaxModal: boolean
}

export const initialState: PreValidationReportState = {
    selectedTab: CONSTANTS.REPORT_TYPES.PRE_VALIDATION_CLI_STATUS,
    selectedPage: 1,
    loadedPage: 1,
    isVisible: false,
    disableForceGenerateButton: true,
    cache: {},
    indirectTaxChangeRecordList: [],
    lastReportStatusModal: false,
    preValIndirectTaxModal: false
}