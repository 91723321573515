//Authenticate.ts

import { CognitoAuth } from 'amazon-cognito-auth-js';
import { UserIdentity } from 'src/models/users/UserIdentity';
import CONSTANTS from '../constants';

const authConfig = {
  AppWebDomain: CONSTANTS.ENVIRONMENT_VARIABLES.USER_POOL_SUB_DOMAIN,
  ClientId: CONSTANTS.ENVIRONMENT_VARIABLES.USER_POOL_APP_CLIENT_ID,
  UserPoolId: CONSTANTS.ENVIRONMENT_VARIABLES.USER_POOL_ID,
  RedirectUriSignIn: CONSTANTS.ENVIRONMENT_VARIABLES.REDIRECT_URI_SIGNIN,
  RedirectUriSignOut: CONSTANTS.ENVIRONMENT_VARIABLES.REDIRECT_URI_SIGNOUT,
  TokenScopesArray: [
    'openid',
    'email',
    'profile',
  ],
};

// This code removes the "?code=..." from the URL. It is because the grant code is not reuseable.
//  Sometimes the SDK will report weird message because of using old grant code.
function removeQueryFromLocation() {
  // Replace the href because the Cognito passes the OAuth2 grant code in the query string
  // And the grant code is not reuseable
  if (window.history.length > 0) {
    const newHref = window.location.href.split('?')[0];
    window.history.replaceState(undefined, 'TPE', newHref);
  }
}

export function getUsernameFromCognitoSession(session: any) {
  try {
    const { identities } = session.getIdToken().decodePayload();
    if (identities.length < 1) {
      return null; // No valid identities; return null.
    }
    return identities[0].userId;
  } catch (e) {
    return null; // Received Invalid object; return null.
  }
}

export function getUserIdentityFromCognitoSession(session: any): UserIdentity {
  try {
    const payload = session.getIdToken().decodePayload();
    const { identities, given_name, family_name } = payload;
    const role = payload[CONSTANTS.COGNITO_ROLE_ATTRIBUTE].split(',');
    if (identities.length < 1) {
      return new UserIdentity('', '', '', []); // No valid identities; return null.
    }
    return new UserIdentity(identities[0].userId, given_name, family_name, role);
  } catch (e) {
    return new UserIdentity('', '', '', []); // Received Invalid object; return null.
  }
}

export function getJwtTokenFromCognitoSession(session: any) {
  return session.getIdToken().getJwtToken();
}

/**
 * Ensures the user is authenticated.
 * If authenticated, return auth object. Otherwise force authentication.
 * Auth object will be used to call API gateway
 */
export function ensureAuthenticated() {
  return new Promise((resolve, reject) => {
    const auth = new CognitoAuth(authConfig);
    auth.useCodeGrantFlow();

    // Register callback functions
    auth.userhandler = {
      onFailure: (err: any) => {
        removeQueryFromLocation();

        if (err === '{"error":"invalid_grant"}') {
          // Cached localStorage contained invalid refresh token;
          // Clear cached Cognito data and try again.
          localStorage.clear();
          ensureAuthenticated()
            .then(result => resolve(result))
            .catch(err2 => reject(err2));
        } else {
          reject(err);
        }
      },
      onSuccess: (result: any) => {
        removeQueryFromLocation();
        resolve(auth);
      },
    };

    const { href } = window.location;
    let session = auth.getSignInUserSession();
    if (session.isValid()) {
      // Already logged in; return auth session.
      resolve(auth);
    } else if (href.indexOf('?') > 0) {
      // Handle Cognito Authentication Redirect.
      //
      // This is required because Cognito needs to get the authentication
      // result from the query string. The parsing is done asynchronously,
      // and the result will be passed to the userHandler. Once the result
      // is parsed, onSuccess userhandler will resolve the promise.
      auth.parseCognitoWebResponse(href);
    } else {
      // Cognito SDK will handle session refresh / authentication.
      session = auth.getSession();
    }

    const username = getUsernameFromCognitoSession(session);
    localStorage.setItem('alias', username);
  });
}
