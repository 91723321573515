import { useState } from "react";
import ErrorUtils from "src/utils/ErrorUtils";
import { VARIANCE_TABLE_COLUMN_CELL_ADDRESS, VARIANCE_TABLE_COLUMN_NAMES, VarianceReportTableColumns } from "../tpTaxVarianceReportDashboard/Constants";
import { getCustomHeaderValue } from "../tpTaxVarianceReportDashboard/TableUtils";
import { TPTaxVarianceRecord } from "src/models/reports/TPTaxVarianceRecords";
import { autoAdjustColumnWidths } from "src/components/reports/downloadReports/Utils";
import * as XLSX from 'xlsx';

/**
 * This function is responsible for downloading the report
 * @param fileName 
 * @param dataURL 
 */
function downloadReport(fileName: string, dataURL: any) {
    const DATA_URL_BASE = 'data:application/vnd.ms-excel;base64,';
    const reportDownload = document.createElement('a');
    reportDownload.download = fileName;
    reportDownload.href = DATA_URL_BASE + dataURL;
    document.body.appendChild(reportDownload);
    reportDownload.click();
    document.body.removeChild(reportDownload);
}


/**
 * This function is responsible for preparing the data 
 * as per the excel requirement. For the columns 
 * where the data needs to mapped as percentage it will divide by 100
 * @param data 
 * @returns 
 */
function prepareVarianceDataForExcel(data: TPTaxVarianceRecord[]): any[] {
    return data.map((row: { [key: string]: any }) => {
        const formattedRow: { [key: string]: any } = {};
        VarianceReportTableColumns.forEach(column => {
            const camelCaseColumn = getCustomHeaderValue(column);
            switch (column) {
                case VARIANCE_TABLE_COLUMN_NAMES.TARGET_COLUMN:
                    if (row[column]) {
                        formattedRow[camelCaseColumn] = row[column] / 100;
                    } else {
                        formattedRow[camelCaseColumn] = row[column];
                    }
                    break;
                default:
                    formattedRow[camelCaseColumn] = row[column];
            }
        });
        return formattedRow;
    });
};

/**
 * This function will perform row specific formatting required for excel.
 * Actual YTD, Variance Column and Target Column -> Complies as percentage columns with 4 decimal places
 * Variance Amount Column -> Complies as number with 4 decimal places
 * @param workSheet 
 */
function formatVarianceWorksheet(workSheet: XLSX.WorkSheet) {
    var range = XLSX.utils.decode_range(workSheet["!ref"]!);
    for (let rowNum = 0; rowNum <= range.e.r; ++rowNum) {
        for (let columnNum = 0; columnNum <= range.e.c; ++columnNum) {
            var cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: columnNum })
            const cell = workSheet[cellAddress];
            if (cell && typeof cell.v == 'number') {
                switch (true) {
                    case cellAddress.includes(VARIANCE_TABLE_COLUMN_CELL_ADDRESS.TARGET_COLUMN):
                        cell.z = '0.0000%'; // Format as percentage with 4 decimal places
                        break;
                    case cellAddress.includes(VARIANCE_TABLE_COLUMN_CELL_ADDRESS.VARIANCE_AMOUNT_COLUMN):
                        cell.z = '0.0000'; // Format as number with 4 decimal places
                        break;
                }
            }
        }
    }
}

/**
 * This is the main hooks which is triggered to 
 * download the variance report based on the filter conditions applied
 * @param sheetName 
 * @param fileName 
 * @returns 
 */
export function useVarianceExcelBasedDownload(sheetName: string, fileName: string) {
    const [downloadResult, setDownloadResult] = useState<string>('');
    const [downloadError, setDownloadError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const downloadVarianceReportExcel = (varianceRecordsToDownload: any[]) => {
        setLoading(true);
        setDownloadResult('');
        setDownloadError('');

        try {
            if (varianceRecordsToDownload.length > 0) {
                const formattedVarianceData = prepareVarianceDataForExcel(varianceRecordsToDownload);
                const workSheet = XLSX.utils.json_to_sheet(formattedVarianceData);
                formatVarianceWorksheet(workSheet);
                autoAdjustColumnWidths(workSheet, varianceRecordsToDownload);
                const workBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
                const wbOut = XLSX.write(workBook, { bookType: 'xlsx', type: 'base64' });
                downloadReport(fileName, wbOut);
                setDownloadResult('Success');
            } else {
                setDownloadResult('No records to download.');
            }
        } catch (error) {
            setDownloadError(ErrorUtils.getMessage(error));
        } finally {
            setLoading(false);
        }
    };

    return [downloadResult, loading, downloadError, downloadVarianceReportExcel] as const;
}