import TPEAction from "src/models/common/TPEAction";
import {ReportsState} from "src/services/reports/ReportsState";
import TPESearchRequest from "src/models/common/TPESearchRequest";

export const ACTIONS = Object.freeze({
    SET_SEARCH_RESULT: new TPEAction('SET_SEARCH_RESULT'),
    SET_CURRENT_PAGE: new TPEAction('SET_CURRENT_PAGE'),
    SET_REPORT_DATA: new TPEAction('SET_REPORT_DATA')
});

export function reportsReducer(state :ReportsState, action: TPEAction) : ReportsState {
    switch(action.type) {
        case ACTIONS.SET_SEARCH_RESULT.type: {
            return {
                ...state,
                searchResult: action.payload,
            }
        }
        case ACTIONS.SET_CURRENT_PAGE.type: {
            const searchPayload: TPESearchRequest = {...state.searchPayload, page: action.payload}
            return {
                ...state,
                searchPayload: searchPayload,
            }
        }
        case ACTIONS.SET_REPORT_DATA.type: {
            const searchPayload: TPESearchRequest = {...state.searchPayload, page: action.payload}
            return {
                ...state,
                reportData: action.payload,
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}