import React, { useEffect, useMemo, useState } from 'react';
import { Box, SpaceBetween, Button, Modal, Alert, Select, FormField, ColumnLayout } from '@amzn/awsui-components-react/polaris';
import CONSTANTS from 'src/utils/constants';
import { eachMonthOfInterval, format } from 'date-fns';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import { DatasetRecord } from 'src/models/tp-allocation/DatasetRecord';

export function CustomPeriodModal(props: { visible: boolean, onClose: () => void, onSubmit: (s: string, e: string) => void,
    recordBeingEdited: DataSourceRecord | DatasetRecord }) {
    const { visible, onClose, onSubmit, recordBeingEdited } = props;

    const defaultDateString = format(new Date(), CONSTANTS.CUSTOM_PERIOD_FORMAT_STRING).toUpperCase();
    const [customPeriodStart, setCustomPeriodStart] = useState(defaultDateString);
    const [customPeriodEnd, setCustomPeriodEnd] = useState(defaultDateString);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        //Clear the modal when opened
        if (visible) {
            if (recordBeingEdited != null && recordBeingEdited.customPeriod != null) {
                const customPeriodArr = recordBeingEdited.customPeriod.split('-');
                setCustomPeriodStart(customPeriodArr[0].trim());
                setCustomPeriodEnd(customPeriodArr[1].trim());
            } else {
                setCustomPeriodStart(defaultDateString);
                setCustomPeriodEnd(defaultDateString);
                setShowError(false);
            }
        }
    }, [visible])

    const populateMonthMap = () => {
        const monthMap = new Map();

        eachMonthOfInterval({
            start: new Date(new Date().getFullYear(), 0, 1), // First day of current year
            end: new Date((d => d.setFullYear(d.getFullYear() + 2))(new Date)), // 2 years ahead
        }).reverse().forEach(x => (monthMap.set(format(x, CONSTANTS.CUSTOM_PERIOD_FORMAT_STRING).toUpperCase(), x)));

        return monthMap;
    }

    const validateAndSubmitPeriod = () => {
        const startDate = monthMap.get(customPeriodStart);
        const endDate = monthMap.get(customPeriodEnd);
        if (endDate < startDate) {
            setShowError(true);
        } else {
            setShowError(false);
            onSubmit(customPeriodStart, customPeriodEnd);
        }
    }

    const monthMap = useMemo(populateMonthMap, []);
    
    return <Modal
                onDismiss={onClose}
                visible={visible}
                size="medium"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" className="customPeriodCancelBtn" onClick={onClose}>Cancel</Button>
                        <Button variant="primary" className="customPeriodOkBtn" onClick={validateAndSubmitPeriod}>OK</Button>
                    </SpaceBetween>
                    </Box>
                }
                header="Custom Period Selection"
            >
                <SpaceBetween direction="vertical" size="l" key="outerSb">
                    <ColumnLayout columns={2}>
                        <FormField  className="startPeriodField"
                                    label="Start Month"
                                    stretch={true}>
                            <Select
                                className="customPeriodStartMonthSelect"
                                selectedOption={{ label: customPeriodStart, value: customPeriodStart }}
                                onChange={(e: any) => { setCustomPeriodStart(e.detail.selectedOption.value) }}
                                options={Array.from(monthMap.keys()).map(x => ({ label: x, value: x }))}
                                placeholder="Choose an option"
                                selectedAriaLabel="Selected"
                                empty="No options" />
                        </FormField>
                        <FormField  className="endPeriodField"
                                    label="End Month"
                                    stretch={true}>
                            <Select
                                className="customPeriodEndMonthSelect"
                                selectedOption={{ label: customPeriodEnd, value: customPeriodEnd }}
                                onChange={(e: any) => { setCustomPeriodEnd(e.detail.selectedOption.value) }}
                                options={Array.from(monthMap.keys()).map(x => ({ label: x, value: x }))}
                                placeholder="Choose an option"
                                selectedAriaLabel="Selected"
                                empty="No options"
                            />
                        </FormField>
                    </ColumnLayout>
                    <Alert className="invalidCustomPeriodAlert" visible={showError} type="error">Start month must precede or equal end month.</Alert>
                </SpaceBetween>
            </Modal>
}