import DashboardNotification from "./DashboardNotification";

export interface DashboardState {
    quarters: Array<string>,
    quarter: any,
    notifications: Array<DashboardNotification>,
    isLoading: Boolean,
    error: string,
    initialLoadTime: string,
    engineRuntimeBatches: Array<string>,
    engineRuntimeSelectedBatch: any,
}

export const initialState: DashboardState = {
    quarter: { label: 'Q3 2021 (current)', value: 'Q3 2021 (current)' },
    quarters: ['Q4 2020', 'Q1 2021', 'Q3 2021 (current)'],
    notifications: [],
    isLoading: true,
    error: '',
    initialLoadTime: '',
    engineRuntimeBatches: ['All batches', 'Batch 1', 'Batch 2'],
    engineRuntimeSelectedBatch: { label: 'All batches', value: 'All batches' },
};