import React, { useEffect, useState } from 'react';
import { Box, SpaceBetween, Modal, FormField, Button, Input, Alert } from '@amzn/awsui-components-react/polaris';
import StringUtils from 'src/utils/stringUtils';

export default function CreateWorksheetTemplateModal(props: {visible: boolean, 
                                                    isCreating: boolean,
                                                    createError: string,
                                                    onClose: () => void, 
                                                    onSubmit: (t: string) => any }) {

    const { visible, isCreating, createError, onClose, onSubmit } = props;

    const [worksheetTemplateName, setWorksheetTemplateName] = useState('');
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (visible) {
            setWorksheetTemplateName('');
            setShowError(false);
        }
    }, [visible])

    useEffect(() => {
        if (isCreating) {
            setShowError(true);
        }
    }, [isCreating])

    const disableCreate = () => {
        if (StringUtils.isNullOrEmpty(worksheetTemplateName)) {
            return true;
        }
        return false;
    }

    return <Modal data-class="createWorksheetTemplateModal" visible={visible}
        header="Create new TP Allocation worksheet template"
        size="medium"
        onDismiss={onClose}
        footer={
            <Box float="right">
                    <Button variant="link" className="cancelButton" disabled={isCreating} onClick={onClose}>Cancel</Button>
                    <Button variant="primary" className="saveButton" disabled={isCreating || disableCreate()} onClick={() => onSubmit(worksheetTemplateName)}>{isCreating ? 'Creating...' : 'Create worksheet template'}</Button>
            </Box>
        }>
            <SpaceBetween direction="vertical" size="m">
                <FormField  data-class="createModalField"
                            label="Enter worksheet template name*"
                            stretch={false}
                >
                    <Input data-class="basicModalInput worksheetNameFieldInput"
                            value={worksheetTemplateName}
                            onChange={e => setWorksheetTemplateName(e.detail.value)}
                    />
                </FormField>
                { showError && !StringUtils.isNullOrEmpty(createError) && <Alert type='error' dismissible={false}>{createError}</Alert> }
            </SpaceBetween>
        </Modal>
}