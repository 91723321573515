export interface TPLOV {
    dataSourceTypes: string[];
    currencyCodes: string[];
    periods: string[];
    currencyConversionTypes: string[]
}

export const getEmptyLOVs = function (): TPLOV {
    return {
        dataSourceTypes: [],
        currencyCodes: [],
        periods: [],
        currencyConversionTypes: [],
    };
}