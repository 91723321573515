import { DbMainExpression } from "../common/TPESearchRequest";

export interface WorklistItem {
    id: string;
    name: string;
    isCustom: boolean;
    createdDate: Date;
    filterDefinition: FilterDefinition;
    position: number;
    isSelected?: boolean;
    type?: string;
}

export interface FilterDefinition {
    filterExpression: DbMainExpression;
}


export enum CustomWorklistStatuses {
    NEW = "NEW",
    CREATED = "CREATED",
    UPDATING = "UPDATING",
    UPDATED = "UPDATED",
    DELETING = "DELETING",
    DELETED = "DELETED",
}

export interface CustomWorklist {
    id?: string;
    name: string;
    accountingOwner: string;
    taxOwner: string;
    calculationStatus: string;
    agreementStatusCategory: string;
    agreementStatus?: string;
    effectiveDateFrom: string;
    effectiveDateFrom_ms?: number;
    effectiveDateTo: string;
    effectiveDateTo_ms?: number;
    workbookName: string;
    status: CustomWorklistStatuses;
}
