import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Box, Button, StatusIndicator, Pagination, SpaceBetween, RadioGroup, Input } from '@amzn/awsui-components-react/polaris';
import './Agreements.scss';
import 'src/assets/styles/polaris-container.scss';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import { agreementsReducer, ACTIONS } from 'src/services/agreements/AgreementsReducer';
import { AgreementsState, initialState } from 'src/services/agreements/AgreementsState';
import ServiceCollection from 'src/services/ServiceCollection';
import { UserProfile } from 'src/models/users/UserProfile';
import AgreementsWorklists from './Agreements_Worklists';
import { CustomWorklistStatuses, WorklistItem } from 'src/models/agreements/Worklist';
import AgreementsGrid from './AgreementsView_AgreementsGrid';
import CalculationsGrid from './AgreementsView_CalculationsGrid';
import ICRSGrid from './AgreementsView_ICRSGrid';
import { SearchSortOrder } from 'src/models/common/SearchSortingSpec';
import { DbConjunctor, DbExpression, DbMainExpression, DbOperator } from 'src/models/common/TPESearchRequest';
import CONSTANTS from 'src/utils/constants';
import { GlobalAppContext } from '../App';
import { GLOBAL_ACTIONS } from '../../services/global/GlobalReducer';
import DynamicBreadcrumb from 'src/models/navigation/DynamicBreadcrumb';
import { NavigationState } from 'src/models/navigation/NavigationState';
import TPEAction from 'src/models/common/TPEAction';
import AccountingOwnersResult from 'src/models/agreements/AccountingOwnersResult';
import AgreementStatusCategoriesResult from 'src/models/agreements/AgreementStatusCategoriesResult';
import TaxOwnersResult from 'src/models/agreements/TaxOwnersResult';
import CalculationStatusesResult from 'src/models/calculations/CalculationStatusesResult';
import ArrayUtils from 'src/utils/arrayUtils';
import { getPermissions } from '../AppPermissions';
import { AppModules, AppRoles } from 'src/models/permissions/RolePermissions';

export type ContextType = {
    state: AgreementsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}


const AgreementsProvider = (props: any) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <AgreementsContext.Provider value={providerValue}>
            {children}
        </AgreementsContext.Provider>
    );
}
export const AgreementsContext = React.createContext(null as unknown as ContextType);


export default function AgreementsView(props: { services: ServiceCollection }) {
    const { userProfile } = React.useContext(GlobalAppContext);
    const { services } = props;
    const location: any = useLocation();
    const { pathname } = location;
    const navigationState: NavigationState = location.state;

    const [state, dispatch] = useReducerWithLogger(agreementsReducer, initialState);
    const {
        isLoading,
        selectedWorklist,
        searchAgreementsItems,
        searchCalculationsItems,
        searchICRSItems,
        searchAgreementsParams,
        searchCalculationsParams,
        searchICRSParams,
        searchCurrentPage,
        searchPageSize,
        searchTotalSize,
        searchSorting,
        searchFilters,
        selectedTab,
        userTouchedSorting,
        customWorklistDeletedDate        
    } = state as AgreementsState;
    const { globalDispatch } = React.useContext(GlobalAppContext);
    const { canViewApttusAgreements } = getPermissions(AppModules.TP_WORKLISTS);

    const [loadTime, setLoadTime] = useState('');
    const [searchCalculationsResult, isSearchingCalculations, searchCalculationsError] = services.calculationsService.searchCalculationLines(searchCalculationsParams);
    const [searchAgreementsResult, isSearchingAgreements, searchAgreementsError] = services.agreementsService.searchAgreements(searchAgreementsParams);
    const [searchICRSResult, isSearchingICRS, searchICRSError] = services.icrsService.searchICRS(searchICRSParams);
    const [agreementStatusCategoriesResult, calculationStatusesResult, accoutingOwnersResult, taxOwnersResult, workbooksResult, usersResult] = services.agreementsService.getAgreementLOVs(loadTime);

    const [showFilters, setShowFilters] = useState(false);
    const [tableKey, setTableKey] = useState(0);
    const [userRoles, setUserRoles] = useState([] as string[]);

    const isSearching = getSingleIsSearching(selectedTab, isSearchingCalculations as boolean, isSearchingAgreements as boolean, isSearchingICRS as boolean);
    const [shouldShowAgreementsTab, setShouldShowAgreementsTab] = useState(false);
    const [showByUser, setShowByUser] = useState(null as boolean | null);
    const [icrsGlobalFilter, setICRSGlobalFilter] = useState("");
    const SHOW_ALL_VALUES: Array<{ value: string, label: string }> = [
        { value: "Show All", label: "Show All" },
        { value: "Show by User", label: "Show by User" }
    ];



    // Intial load 
    useEffect(() => {
        // Trick to trigger API calls needed on intial load
        setLoadTime(new Date().toDateString());
    }, []);

    // Intial load
    useEffect(() => {
        if (userProfile.roles == null) {
            return;
        }
        const roles = userProfile.roles;
        setShouldShowAgreementsTab(canViewApttusAgreements);
        setUserRoles(roles);
        if (canViewApttusAgreements) {
            dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(navigationState?.selectedWorklistsTab || CONSTANTS.WORKLIST_TABS.AGREEMENTS));
        }
        else {
            dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.WORKLIST_TABS.CALCULATIONS));
        }
    }, [userProfile.roles]);

    useEffect(() => {
        if (agreementStatusCategoriesResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_STATUS_CATEGORIES.withPayload(
            (agreementStatusCategoriesResult as AgreementStatusCategoriesResult).statusCategoriesList
        ));
    }, [agreementStatusCategoriesResult]);

    useEffect(() => {
        if (calculationStatusesResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_CALCULATION_STATUSES.withPayload(
            (calculationStatusesResult as CalculationStatusesResult).calculationStatuses
        ));
    }, [calculationStatusesResult]);

    useEffect(() => {
        if (accoutingOwnersResult == null) {
            return;
        }
        const owners = (accoutingOwnersResult as AccountingOwnersResult).accountingOwners;
        dispatch(ACTIONS.SET_ACCOUNTING_OWNERS.withPayload(owners));
    }, [accoutingOwnersResult]);

    useEffect(() => {
        if (taxOwnersResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_TAX_OWNERS.withPayload(
            (taxOwnersResult as TaxOwnersResult).taxOwners
        ));
    }, [taxOwnersResult]);

    useEffect(() => {
        if (workbooksResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_WORKBOOKS.withPayload(
            workbooksResult
        ));
    }, [workbooksResult]);

    useEffect(() => {
        if (usersResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_USERS.withPayload(
            usersResult
        ));
    }, [usersResult]);

    useEffect(() => {
        if (userProfile == {} as UserProfile || selectedWorklist == undefined) {
            return;
        }
        dispatchSearch();
    }, [selectedWorklist]);

    useEffect(() => {
        if (showByUser == null) {
            return;
        }
        dispatchSearch();
    }, [showByUser]);

    useEffect(() => {
        if (customWorklistDeletedDate == null) {
            return;
        }
        dispatchSearch();
    }, [customWorklistDeletedDate]);

    useEffect(() => {
        if (userRoles == null || userRoles.length === 0) {
            return;
        }
        setShowByUser(!canViewApttusAgreements);
    }, [userRoles]);

    useEffect(() => {
        if (selectedTab === CONSTANTS.WORKLIST_TABS.NONE) {
            return;
        }
        setShowFilters(false);

        switch ( selectedTab ){
            case CONSTANTS.WORKLIST_TABS.AGREEMENTS:
                globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, `${CONSTANTS.PAGE_NAV.AGREEMENTS.TITLE} - ${CONSTANTS.PAGE_NAV.AGREEMENTS.AGREEMENTS_TAB_NAME}`)));
                break;
            case CONSTANTS.WORKLIST_TABS.CALCULATIONS:
                globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, `${CONSTANTS.PAGE_NAV.AGREEMENTS.TITLE} - ${CONSTANTS.PAGE_NAV.AGREEMENTS.CALCULATIONS_TAB_NAME}`)));
                break;
            case CONSTANTS.WORKLIST_TABS.ICRS:
                globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, `${CONSTANTS.PAGE_NAV.AGREEMENTS.TITLE} - ${CONSTANTS.PAGE_NAV.AGREEMENTS.ICRS_TAB_NAME}`)));
                break;
        }

        globalDispatch(GLOBAL_ACTIONS.SET_SELECTED_WORKLIST_TAB.withPayload(selectedTab));
        if (selectedWorklist) {
            dispatch(ACTIONS.SET_SELECTED_WORKLIST.withPayload(undefined));
            return;
        }
        dispatchSearch();
    }, [selectedTab]);

    useEffect(() => {
        if (searchAgreementsResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_SEARCH_AGREEMENTS_RESULTS.withPayload(searchAgreementsResult));
    }, [searchAgreementsResult])

    useEffect(() => {
        if (searchCalculationsResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_SEARCH_CALCULATIONS_RESULTS.withPayload(searchCalculationsResult));
    }, [searchCalculationsResult])

    useEffect(() => {
        if (searchICRSResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_SEARCH_ICRS_RESULTS.withPayload(searchICRSResult));
    }, [searchICRSResult])

    useEffect(() => {
        if (isLoading || isSearching) {
            return;
        }
        dispatchSearch();
    }, [searchCurrentPage, searchSorting, searchFilters])

    const addSearchSorting = function (searchSorting: any, payload: any) {
        if (searchSorting != null) {
            payload.sortField = searchSorting.sortField;
            payload.sortOrder = searchSorting.sortOrder;
        }
        // If user has not sorted using the grid headers then we apply the default sorting
        else if (!userTouchedSorting) {
            switch( selectedTab ){
                case CONSTANTS.WORKLIST_TABS.AGREEMENTS:
                    payload.sortField = CONSTANTS.CALCULATIONS_SEARCH_FIELDS.AGREEMENT_NUMBER.key;
                    payload.sortOrder = SearchSortOrder.DESCENDING;
                    break;
                case CONSTANTS.WORKLIST_TABS.CALCULATIONS:
                    payload.sortField = CONSTANTS.CALCULATIONS_SEARCH_FIELDS.CALCULATION_LINE.key;
                    payload.sortOrder = SearchSortOrder.DESCENDING;
                    break;
                case CONSTANTS.WORKLIST_TABS.ICRS:
                    payload.sortField = CONSTANTS.ICRS_SEARCH_FIELDS.TRANSACTION_LINE.key;
                    payload.sortOrder = SearchSortOrder.DESCENDING;
                    break;
            }

        }
    };

    const addSearchFiltering = function (filterExpression: DbMainExpression | null) {
        if (searchFilters != null) {
            filterExpression = createExpressionIfNull(filterExpression);
            let expressionsHolder = filterExpression;
            // When the worklist conjuctor is an OR we need to separate the conditions in 2 groups joined by an AND conjuctor
            // Going from: { conjuctor:'OR', expressions: [...worklist conditions..., ...filter conditions...] }
            // To: { conjuctor:'AND', expressions: [{ conjuctor:'OR', expressions: [...worklist conditions...] }, { conjuctor:'AND', expressions: [...filter conditions...] }] }
            if (selectedWorklist != null && !ArrayUtils.isNullOrEmpty(searchFilters) && filterExpression.conjunctor === DbConjunctor.OR) {
                filterExpression.conjunctor = DbConjunctor.AND;
                const worklistExpressions = [...filterExpression.expressions];
                filterExpression.expressions = [];
                filterExpression.expressions.push({
                    conjunctor: DbConjunctor.OR,
                    expressions: worklistExpressions,
                });
                expressionsHolder = {
                    conjunctor: DbConjunctor.AND,
                    expressions: [],
                };
                filterExpression.expressions.push(expressionsHolder);
            }

            searchFilters.forEach(filter => {
                expressionsHolder.expressions.push({
                    attributeName: filter.id,
                    operator: DbOperator.CONTAINS,
                    values: [filter.value]
                })
            });
        }
        return filterExpression;
    }

    const addRoleBasedFiltering = function (filterExpression: DbMainExpression | null) {
        const isAccountingUser = services.usersService.hasRole(AppRoles.ACCOUNTING, userRoles)
            || services.usersService.hasRole(AppRoles.ADMIN, userRoles); 
        const isTaxUser = services.usersService.hasRole(AppRoles.TAX, userRoles);

        if (showByUser === true) {
            filterExpression = createExpressionIfNull(filterExpression);
            // When the worklist conjuctor is an OR we need to separate the conditions in 2 groups joined by an AND conjuctor
            // Going from: { conjuctor:'OR', expressions: [...worklist conditions..., ...role based conditions...] }
            // To: { conjuctor:'AND', expressions: [{ conjuctor:'OR', expressions: [...worklist conditions...] }, { conjuctor:'OR', expressions: [...role based conditions...] }] }
            if (filterExpression.conjunctor === DbConjunctor.OR){
                filterExpression.conjunctor = DbConjunctor.AND;
                const worklistExpressions = [...filterExpression.expressions];
                filterExpression.expressions = [];
                filterExpression.expressions.push({
                    conjunctor: DbConjunctor.OR,
                    expressions: worklistExpressions,
                });
            }
            //filterExpression = createNestedExpressionIfNotNull(filterExpression, DbConjunctor.OR);
            const expressionsHolder:DbMainExpression = {
                conjunctor: DbConjunctor.OR,
                expressions: [],
            };
            // If there are no other conditions then this is the only conditions set. So no need for a nested group of conditions
            if (ArrayUtils.isNullOrEmpty(filterExpression.expressions)){
                filterExpression = expressionsHolder;
            }
            else {
                filterExpression.expressions.push(expressionsHolder);
            }
            if (isAccountingUser && expressionsHolder.expressions.find(x => (x as DbExpression).attributeName === CONSTANTS.WORKLIST_FILTER_FIELDS.ACCOUNTING_OWNER.key) == null) {
                expressionsHolder.expressions.push({
                    attributeName: CONSTANTS.WORKLIST_FILTER_FIELDS.ACCOUNTING_OWNER.key,
                    operator: DbOperator.CONTAINS,
                    values: [`${userProfile.firstName} ${userProfile.lastName}`]
                })
            }

            if (isTaxUser && expressionsHolder.expressions.find(x => (x as DbExpression).attributeName === CONSTANTS.WORKLIST_FILTER_FIELDS.TAX_OWNER.key) == null) {
                expressionsHolder.expressions.push({
                    attributeName: CONSTANTS.WORKLIST_FILTER_FIELDS.TAX_OWNER.key,
                    operator: DbOperator.CONTAINS,
                    values: [`${userProfile.firstName} ${userProfile.lastName}`]
                })
            }

            if (expressionsHolder.expressions.find(x => (x as DbExpression).attributeName === CONSTANTS.WORKLIST_FILTER_FIELDS.CALC_BUILDER.key) == null) {
                expressionsHolder.expressions.push({
                    attributeName: CONSTANTS.WORKLIST_FILTER_FIELDS.CALC_BUILDER.key,
                    operator: DbOperator.CONTAINS,
                    values: [`${userProfile.firstName} ${userProfile.lastName}`]
                })
            }
            if (expressionsHolder.expressions.find(x => (x as DbExpression).attributeName === CONSTANTS.WORKLIST_FILTER_FIELDS.CALC_ASSIGNEE.key) == null) {
                expressionsHolder.expressions.push({
                    attributeName: CONSTANTS.WORKLIST_FILTER_FIELDS.CALC_ASSIGNEE.key,
                    operator: DbOperator.CONTAINS,
                    values: [`${userProfile.firstName} ${userProfile.lastName}`]
                })
            }
        }
        return filterExpression;
    }


    const dispatchSearch = function () {
        if (showByUser == null || selectedTab === CONSTANTS.WORKLIST_TABS.NONE) {
            return;
        }

        const payload = {
            page: searchCurrentPage,
            pageSize: parseInt(searchPageSize.toString()),
            searchStartedDate: new Date(),
        } as any;
        let filterExpression: DbMainExpression | null = null;
        if (selectedWorklist != null) {
            filterExpression = JSON.parse(JSON.stringify(selectedWorklist.filterDefinition.filterExpression));
        }
        addSearchSorting(searchSorting, payload);
        filterExpression = addSearchFiltering(filterExpression);
        filterExpression = addRoleBasedFiltering(filterExpression);

        payload.filterExpression = filterExpression;

        switch ( selectedTab ){
            case CONSTANTS.WORKLIST_TABS.AGREEMENTS:
                dispatch(ACTIONS.START_AGREEMENTS_SEARCH.withPayload(payload));
                break;
            case CONSTANTS.WORKLIST_TABS.CALCULATIONS:
                dispatch(ACTIONS.START_CALCULATIONS_SEARCH.withPayload(payload));
                break;
            case CONSTANTS.WORKLIST_TABS.ICRS:
                dispatch(ACTIONS.START_ICRS_SEARCH.withPayload({...payload, pageSize: 200}));
                break;
        }
    }



    const onCustomWorklistSubmitted = function (worklist: any, status: CustomWorklistStatuses) {
        if (status === CustomWorklistStatuses.NEW) {
            dispatch(ACTIONS.ADD_CUSTOM_WORKLIST.withPayload(worklist));
        }
        else if (status === CustomWorklistStatuses.UPDATING) {
            dispatch(ACTIONS.UPDATE_CUSTOM_WORKLIST.withPayload(worklist));
        }
    }

    const onWorklistSelected = function (worklist?: WorklistItem) {
        if (worklist == null) {
            return;
        }
        dispatch(ACTIONS.SET_SELECTED_WORKLIST.withPayload(worklist))
    }

    const dispatchSortByChanged = function (sortBySpecs: Array<any>) {
        if (sortBySpecs == null || sortBySpecs.length === 0) {
            dispatch(ACTIONS.SET_SEARCH_SORT.withPayload(undefined));
            return;
        }
        const sortBy = sortBySpecs[0];
        dispatch(ACTIONS.SET_SEARCH_SORT.withPayload({
            sortOrder: sortBy.desc ? SearchSortOrder.DESCENDING : SearchSortOrder.ASCENDING,
            sortField: sortBy.id
        }))
    }

    // Using a local search state to be used as a temporary place to set the search filters before dispatching the API search
    const [localSearchFilter, setLocalSearchFilter] = React.useState(undefined as any | undefined);
    React.useEffect(() => {
        // Waiting 1 second before dispatching the API search
        const timeout = setTimeout(() => {
            dispatch(ACTIONS.SET_SEARCH_FILTERS.withPayload(localSearchFilter));
        }, 1500)
    
        return () => clearTimeout(timeout)    
      }, [localSearchFilter])

    

    const dispatchFilterChanged = function (filters: Array<{ id: string, value: any }>) {
        if (filters == null || filters.length === 0) {
            setLocalSearchFilter(undefined);
            return;
        }

        const allFiltersHaveValidValue = filters.every(x => x.value.length > 1);
        if (!allFiltersHaveValidValue) {
            return;
        }

        setLocalSearchFilter(filters);
    }

    const agreementsPageCount = parseInt(Math.ceil(searchAgreementsResult == null ? 0 : searchTotalSize / searchPageSize).toString());
    const calculationsPageCount = parseInt(Math.ceil(searchCalculationsResult == null ? 0 : searchTotalSize / searchPageSize).toString());
    const icrsPageCount = parseInt(Math.ceil(searchICRSResult == null ? 0 : searchTotalSize / searchPageSize).toString());

    const pagesCount = getSinglePageCount(selectedTab, agreementsPageCount, calculationsPageCount, icrsPageCount);



    return (
        <Container className="polaris-content-container">
            <AgreementsProvider services={services} state={state} dispatch={dispatch}>
                <AgreementsWorklists
                    userProfile={userProfile}
                    onWorklistSelected={onWorklistSelected}
                    shouldShowAgreementsTab={shouldShowAgreementsTab}
                    onCustomWorklistSubmitted={onCustomWorklistSubmitted} />
                {isLoading ?
                    <Box textAlign="center"><StatusIndicator type="loading">Loading resources</StatusIndicator></Box> :
                    <div>
                        <div className="tableHeader">
                            <SpaceBetween direction="horizontal" size="m">
                                {selectedTab !== CONSTANTS.WORKLIST_TABS.ICRS && <Button
                                        className={showFilters ? "buttonIconActive" : "buttonIconInactive"}
                                        iconAlign="left"
                                        iconName="filter"
                                        onClick={() => {
                                            if (showFilters) {
                                                // This resets the table filters state
                                                setTableKey(tableKey + 1);
                                            }
                                            setShowFilters(!showFilters);
                                        }}
                                    >Filter rows</Button> 
                                }
                                <Button className={isSearching ? "" : "blueIcon"} disabled={isSearching} iconName="refresh" onClick={dispatchSearch} variant="icon" />
                                <div className="recordCounter">Total records: {isSearching ? "..." : selectedTab !== CONSTANTS.WORKLIST_TABS.ICRS? searchTotalSize : searchICRSItems.length}</div>
                                {selectedTab !== CONSTANTS.WORKLIST_TABS.ICRS? <div className="horizontalRadioButtons">
                                        <RadioGroup
                                            onChange={({ detail }) => setShowByUser(detail.value === SHOW_ALL_VALUES[1].value)}
                                            value={showByUser == null ? null : showByUser ? SHOW_ALL_VALUES[1].value : SHOW_ALL_VALUES[0].value}
                                            items={SHOW_ALL_VALUES}
                                        />
                                    </div> :
                                    <Input
                                    value={icrsGlobalFilter}
                                    onChange={(e:any) => setICRSGlobalFilter(e.detail.value)}
                                    placeholder="Search all fields"
                                    type="search"
                                    data-class="icrsSearchInput"
                                  />
                                }
                            </SpaceBetween>
                            {
                                pagesCount > 1 ?
                                    <Pagination
                                        currentPageIndex={searchCurrentPage}
                                        pagesCount={pagesCount}
                                        disabled={pagesCount === 0 || (isSearching as boolean)}
                                        onChange={({ detail }) => dispatch(ACTIONS.SET_CURRENT_PAGE.withPayload(detail.currentPageIndex))}
                                    ></Pagination> : null
                            }
                        </div>
                        <div className="tableContainer">
                            {
                                renderGridBySelectedTab(selectedTab, tableKey, searchAgreementsItems, searchCalculationsItems, searchICRSItems, isSearching as boolean, showFilters, dispatchSortByChanged, dispatchFilterChanged, icrsGlobalFilter)
                            }
                        </div>
                    </div>
                }
            </AgreementsProvider>
        </Container>
    )
}
function renderGridBySelectedTab(
    selectedTab: string, 
    tableKey: number, 
    agreementItems: any[], 
    calculationItems: any[], 
    ICRSItems: any[], 
    isSearching: boolean, 
    showFilters: boolean, 
    dispatchSortByChanged: (x: any[]) => void, 
    dispatchFilterChanged: (x: any[]) => void,
    icrsGlobalFilter: string): React.ReactNode {
    switch (selectedTab) {
        case CONSTANTS.WORKLIST_TABS.AGREEMENTS:
            return <AgreementsGrid key={tableKey}
                data={agreementItems}
                showFilters={showFilters}
                isSearching={isSearching as boolean}
                onSortBy={(x: Array<any>) => dispatchSortByChanged(x)}
                onFilter={(x: Array<any>) => dispatchFilterChanged(x)}
            />;
        case CONSTANTS.WORKLIST_TABS.CALCULATIONS:
            return <CalculationsGrid key={tableKey}
                data={calculationItems}
                showFilters={showFilters}
                isSearching={isSearching as boolean}
                onSortBy={(x: Array<any>) => dispatchSortByChanged(x)}
                onFilter={(x: Array<any>) => dispatchFilterChanged(x)}
            />;
        case CONSTANTS.WORKLIST_TABS.ICRS:
             return <ICRSGrid key={tableKey}
                data={ICRSItems}
                showFilters={false}
                isSearching={isSearching as boolean}
                onSortBy={(x: Array<any>) => dispatchSortByChanged(x)}
                onFilter={(x: Array<any>) => dispatchFilterChanged(x)}
                globalFilterValue={icrsGlobalFilter}
             />;

        default:
            return null;
    }
}



function getSingleIsSearching(selectedTab: string, isSearchingCalculations: boolean, isSearchingAgreements: boolean, isSearchingICRS: boolean): boolean {
    switch ( selectedTab ) {
        case CONSTANTS.WORKLIST_TABS.AGREEMENTS:
            return isSearchingAgreements;
        case CONSTANTS.WORKLIST_TABS.CALCULATIONS:
            return isSearchingCalculations;
        case CONSTANTS.WORKLIST_TABS.ICRS:
            return isSearchingICRS;
        default:
            return false;
    }

}

function getSinglePageCount(selectedTab: string, agreementsPageCount: number, calculationsPageCount: number, icrsPageCount: number) {
    switch ( selectedTab ){
        case CONSTANTS.WORKLIST_TABS.AGREEMENTS:
            return agreementsPageCount;
        case CONSTANTS.WORKLIST_TABS.CALCULATIONS:
            return calculationsPageCount;
        case CONSTANTS.WORKLIST_TABS.ICRS:
            return 1;
        default:
            return 0;
    }
}

function createExpressionIfNull(filterExpression: DbMainExpression | null, conjuctor: DbConjunctor = DbConjunctor.AND): DbMainExpression {
    return filterExpression == null ?
        {
            conjunctor: conjuctor,
            expressions: [],
        } : filterExpression;
}

function createNestedExpressionIfNotNull(filterExpression: DbMainExpression | null, conjuctor: DbConjunctor = DbConjunctor.AND): DbMainExpression {
    if (filterExpression == null) {
        return {
            conjunctor: conjuctor,
            expressions: [],
        };
    }
    filterExpression.expressions.push({
        conjunctor: conjuctor,
        expressions: [],
    });
    return filterExpression;
}



