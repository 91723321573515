import { Button, Link } from "@amzn/awsui-components-react";
import React from 'react';

export default function ButtonBasedReportHeaderAction(props: {
    headerAction: any,
    headerName: string,
    externalLink?: string
}) {
    return (<Button variant='inline-link' onClick={props.headerAction}>
        {!!props.externalLink ?
            (<Link external href={props.externalLink} target="_blank" fontSize='heading-m'>{props.headerName}</Link>)
            :
            (<Link fontSize='heading-m'>{props.headerName}</Link>)}
    </Button>)
}