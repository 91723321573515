import { Popover, Icon, SpaceBetween, Button, Input, Autosuggest, ButtonDropdown, ButtonDropdownProps } from "@amzn/awsui-components-react";
import React, { useEffect } from "react";
import FormulaExpressionToolbar from "src/components/shared/FormulaExpressionToolbar";
import { TPEReactTable } from "src/components/shared/TPEReactTable";
import CONSTANTS from "src/utils/constants";
import { AllocationGroupsContext } from "./AllocationGroupsContainer";
import AllocationGroup, { AllocationGroupFormula } from "src/models/tp-allocation/AllocationGroup";
import { renderExpressionParts } from "src/components/shared/FormulaExpressionFunctionComponents";
import { SavingStatus } from "src/models/common/SavingStatus";
import { WorksheetMode } from "src/models/tp-allocation/TPAllocationEnums";
import { ACTIONS } from "src/services/tp-allocation/allocation-groups/AllocationGroupsReducer";
import ATPTextAreaAutoComplete from "src/components/shared/ATPTextAreaAutoComplete"
import DomUtils from "src/utils/DomUtils";
import { AllocationFormula, FormulaMapping } from "src/models/tp-allocation/AllocationFormula";
import { Row } from "react-table";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import StringUtils from "src/utils/stringUtils";
import ATPCompaniesAutosuggest from "src/components/shared/ATPCompaniesAutosuggest"
import { ACTIONS as TP_ALLOCATION_ACTIONS } from "src/services/tp-allocation/TPAllocationReducer";
import { TPELoadingSpinner } from "src/components/shared/TPELoadingSpinner";
import { TPAllocationContext } from "src/services/tp-allocation/TPAllocationContext";


// Cell Editors
const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: { id },
    onRowUpdated, // This is a custom function that we supplied to our table instance
    editorsData, // Editors data contains data needed to render the editors
}: { value: any, initialValue: any, row: any, column: any, onRowUpdated: any, editorsData: any}) => {
    const [value, setValue] = React.useState(initialValue);
    
    
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    

    const onTextAreaBlur = (args: any) => {
        onRowUpdated(index, id, args.textAreaValue);
    }

    const onCompaniesSelectionBlur = (str:string) => {
        onRowUpdated(index, id, {
            mappingType: CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI,
            values: str.split(",")
        });
    }

    const onBlur = () => {
        onRowUpdated(index, id, value)
    }

    const onChange = (e: any) => {
        setValue(e.detail.value);
    }

    const formula = original as AllocationGroupFormula;
    if (formula.isBeingEdited){
        switch (id) {
            case CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.OVERVIEW: {
                return <ATPTextAreaAutoComplete 
                            key={`${id}_${index}`}
                            editorsData={editorsData} 
                            initialValue={initialValue}
                            onTextAreaBlur={onTextAreaBlur} />
            }
            case CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.MAPPING: {
                return <ATPCompaniesAutosuggest
                    className="coaAutosuggest" 
                    companies={editorsData.companiesList}
                    selectedCompanies={(initialValue as FormulaMapping)?.values || []}
                    initialValue={original.mappingFormatted}
                    isEditing={true}
                    onBlur={onCompaniesSelectionBlur}                    
                />;
            }
            case CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.DESCRIPTION:
                return <div key={`${id}_${index}`} className={`save-status-${formula.savingStatus} cell-text ${id}Cell`}>
                    <Input key={`${id}_${index}`} className={"dataSourcesButton " + id + "Editor" + original.allocationGroupId} placeholder="Enter value" value={value} onChange={onChange} onBlur={onBlur} />
                    {original.errorIndicator && <span className="inlineErrorContainer">{original.errorIndicator}</span>}
                </div>;
            default:
                return <Input key={`${id}_${index}`} className={"dataSourcesButton " + id + "Editor" + original.allocationGroupId} placeholder="Enter value" value={value} onChange={onChange} onBlur={onBlur} />;
        }
    }
    else {
        switch (id) {
            case CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.DESCRIPTION:
                return <div key={`${id}_${index}`} className={`save-status-${formula.savingStatus} cell-text formula_${id}Cell`}>
                    <span>{value}</span>
                    {original.errorIndicator && <div className="inlineErrorContainer">{original.errorIndicator}</div>}
                </div>;
            case CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.OVERVIEW: {
                return <div key={`${id}_${index}`} className={`save-status-${formula.savingStatus}`}>
                        {formula.expression == null || formula.expression === ""? "Click to enter TP allocation formula" : formula.expressionFormatted}
                        {editorsData.shouldShowValues && formula.outputBreakdown? <div className="greyBadge">{formula.outputBreakdown}</div> : null }
                    </div>;
            }
            case `${CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.MAPPING}`: {
                return <div key={`${id}_${index}`} className={`save-status-${formula.savingStatus} cell-text ${id}Cell`}>{original.mappingFormatted}</div>
            }
        }
    }
    return <div key={`${id}_${index}`} className={`save-status-${formula.savingStatus} cell-text ${id}Cell`}>{value}</div>;
};

// Set our editable cell renderer as the default Cell renderer
const defaultCellEditor = {
    Cell: EditableCell,
}

const contextMenuActions = {
    EDIT: { text: "Edit", id: "Edit" },
    DUPLICATE: { text: "Duplicate", id: "Duplicate" },
    REMOVE: { text: "Remove", id: "Remove" },
    ADD_FORMULA_BEFORE: { text: "Add formula before", id: "Add formula before" },
    ADD_FORMULA_AFTER: { text: "Add formula after", id: "Add formula after" }
}

const formulasMenuItems = [
    contextMenuActions.EDIT,
    contextMenuActions.DUPLICATE,
    contextMenuActions.REMOVE
];

export default function AllocationGroupFormulasGrid(props: { group: AllocationGroup}) {
    const { group } = props;
    const { tpAllocationState } = React.useContext(TPAllocationContext);
    const { services, groupsState, groupsDispatch, tpAllocationDispatch, dataSources, totals } = React.useContext(AllocationGroupsContext);
    const { showValues, groupIdToRefreshFormulas } = groupsState;
    const { worksheetMode, viewMode, worksheet, worksheetTemplate, sevenSegmentLOVMap, allocationGroupsNeedRefresh, allocationFormulaValidationErrors } = tpAllocationState;
    const isTemplate = worksheetMode == WorksheetMode.TEMPLATE;
    const isEditable = CONSTANTS.VIEW_MODE.EDITABLE === viewMode;
    const [refreshFormulasTimeStamp, setRefreshFormulasTimeStamp] = React.useState(null as any);
    const [formattedRecords, setFormattedRecords] = React.useState([] as any[]);
    const [formulas, setFormulas] = React.useState(group.formulas || []);
    const [skipPageReset, setSkipPageReset] = React.useState(false);
    
    const [formulasResult, formulasLoading, getFormulasError] = isTemplate?
        services.tpAllocationGroupsService.getAllocationTemplateGroupFormulas(worksheetTemplate?.templateId, worksheetTemplate?.templateVersion, group.allocationGroupId, refreshFormulasTimeStamp):
        services.tpAllocationGroupsService.getAllocationGroupFormulas(worksheet?.worksheetId, worksheet?.worksheetVersion, group.allocationGroupId, refreshFormulasTimeStamp, worksheet?.executionPeriod?.id);

    useEffect(() => {
        if (allocationGroupsNeedRefresh){
            setRefreshFormulasTimeStamp(new Date().toISOString());
        }
    }, [allocationGroupsNeedRefresh])
    
    useEffect(() => {
        setRefreshFormulasTimeStamp(new Date().toISOString());
    }, [])

    useEffect(() => {
        if (groupIdToRefreshFormulas == null){
            setRefreshFormulasTimeStamp(group.allocationGroupId)
        }
        else if (groupIdToRefreshFormulas.groupId === group.allocationGroupId){
            setRefreshFormulasTimeStamp(new Date().toISOString())
        }
    }, [groupIdToRefreshFormulas])

    useEffect(() => {
        if (formulasResult == null) {
            return;
        }
        group.formulas = formulasResult;
        setFormulas(formulasResult);
        tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_REFRESH_TOTALS_BUILDER_STEPS.withPayload(false));
    }, [formulasResult])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(getFormulasError)) {
            return;
        }
        groupsDispatch(ACTIONS.SET_GROUP_LEVEL_ERROR.withPayload({groupId: group.allocationGroupId, message: getFormulasError}));
    }, [getFormulasError])
    
    useEffect(() => {
        if (group.formulas == null) {
            return;
        }
        setFormulas(group.formulas);
    }, [group.formulas])

    
    useEffect(() => {
        if (formulas == null) {
            return;
        }
        formulas.forEach((f, index) => {
            f.visibleSequence = `${index + 1}`;
            f.validationErrors = [...(f.validationErrors||[]), ...allocationFormulaValidationErrors.filter(x => x.worksheetEntityId === f.allocationFormulaId)];
        })
        const mappedDataSources = dataSources.map(x => ({name: x.description}));
        const mappedTotals = totals.map(x => ({name: x.description, visibleSequence: x.visibleSequence}));
        
        setFormattedRecords(formulas.map(f => {
            const parts = services.readOnlyCalculationService.breakExpressionIntoParts(
                f.expression,
                mappedTotals,
                mappedDataSources,
                new Set()
            );
            f.expressionTokens = parts;
            const errorsList = (f.validationErrors || []).map(x => <li>{x.message}</li>);
            const errorsContent = errorsList.length > 0? <React.Fragment><b>Validation Errors</b><ul>{errorsList}</ul></React.Fragment> : null;
            
            const gridRow = {
                ...f,
                isEditable: isEditable,
                expressionFormatted: renderExpressionParts(parts),
                formulaOutputFormatted: isNaN(f.formulaOutput as any)? f.value : services.formattingService.formatString(f.value || '', true),
                savingStatus: worksheet?.worksheetVersion == CONSTANTS.STAGING_VERSION_NUMBER? SavingStatus.Unsaved : f.savingStatus,
                mappingFormatted: (f.mapping?.values || []).join(", "),
                errorIndicator: errorsContent? <Popover key={`popFormulaError_${f.visibleSequence}`}
                    className="errorMessagePopover"
                    dismissButton={false}
                    position="top"
                    size="small"
                    triggerType="custom"
                    content={
                        errorsContent
                    }
                >
                    <Icon
                    name="status-warning"
                    size="normal"
                    variant="warning"
                    />
                </Popover> : null
            };
            return gridRow;
        }))
    }, [formulas, totals, dataSources, allocationFormulaValidationErrors])

    
    // Columns without a Cell property have rendering handled by the EditableCell renderer
    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "visibleSequence",
            Header: (e: any) => <div className="cell-text-non-sortable">#</div>,
            Cell: ({ row, rows, toggleRowExpanded }: { row: any, rows: any, toggleRowExpanded: any }) => { 
                const formula = row.original as AllocationGroupFormula;
                return (
                    <div className={`save-status-${formula.savingStatus} formulaSequence textCell`}>
                        <span className="sequenceCellValue">{formula.visibleSequence}</span>
                    </div>
                );
        },
        },
        {
            accessor: CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.DESCRIPTION,
            Header: (e: any) => <div className="cell-text-non-sortable">Formula name</div>,
        },
        {
            accessor: CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.OVERVIEW,
            Header: <div className="headerToggle">Formula overview &nbsp;
                        <Popover
                            className="calcOverviewPopover"
                            dismissButton={false}
                            position="right"
                            size="small"
                            triggerType="custom"
                            content="Click the expression to edit. Type '@' to display the autocomplete menu."
                        >
                            <Icon
                            name="status-info"
                            size="small"
                            variant="link"
                            />
                        </Popover>
                    </div>
        },
        {
            accessor: `${CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.OUTPUT}Formatted`,
            Header: (e: any) => <div className="cell-text-non-sortable">Formula output</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.MAPPING,
            Header: (e: any) => <div className="cell-text-non-sortable">Mapping</div>,
        },
        {
            accessor: "actions",
            Header: (e: any) => <div className="cell-text-non-sortable"></div>,
            Cell: (e: any) => <div className="rowActions formulaOutputCell">
                { viewMode === CONSTANTS.VIEW_MODE.EDITABLE &&
                    <ButtonDropdown className="rowContextMenu gridContextMenu" onItemClick={(bde) => { 
                        onFormulaMenuClicked(bde.detail, e.row, group, groupsDispatch, services, e.row.original.parentEntityId);
                    }}
                        items={formulasMenuItems}
                    >
                    </ButtonDropdown>
                }
            </div>
        }
    ], [showValues, group.formulas, viewMode]);

    const onRecordUpdated = (rowIndex: number, columnId: any, value: any) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
        
        if (columnId === CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.OVERVIEW) {
            groupsDispatch(ACTIONS.UPDATE_CURRENT_FORMULA.withPayload({
                group,
                field: columnId, 
                value: value, 
                extraValue: services.readOnlyCalculationService.breakExpressionIntoParts(
                    value, 
                    totals.map(x => ({name: x.description, visibleSequence: x.visibleSequence})), 
                    dataSources.map(x => ({name: x.description})), 
                    new Set()
                )
            }));
        }
        else {
            groupsDispatch(ACTIONS.UPDATE_CURRENT_FORMULA.withPayload({group, field: columnId, value: value}));
        }
    }

    const onRowClicked = (e: any, formula: AllocationFormula, index:number) => {
        
        if (CONSTANTS.VIEW_MODE.EDITABLE !== viewMode) {
            return;
        }
        // We don't do anything if user clicks on the formula output, error container tooltip or sequence cell
        if (
            !formula.isEditable || 
            DomUtils.isDescendantOfClassName(e.target, 'formulaOutputCell') || 
            DomUtils.isDescendantOfClassName(e.target, 'formulaSequence') || 
            DomUtils.isDescendantOfClassName(e.target, 'inlineErrorContainer') 
        ){
            return;
        }
        groupsDispatch(ACTIONS.SET_FORMULA_BEING_EDITED.withPayload({group, formula}));
    }

    const onAddFormulaClicked = () => {
        const newFormula = services.tpAllocationGroupsService.createNewFormula(group);
        groupsDispatch(ACTIONS.ADD_ALLOCATION_GROUP_FORMULA.withPayload({group, newFormula}));
    }
    return <>
        { isEditable  && <FormulaExpressionToolbar showBuiltInFunctions={false} dispatch={groupsDispatch} /> }
        <SpaceBetween size="m" direction="vertical">
            <TPELoadingSpinner loading={formulasLoading} loadingText="Loading formulas">
                <TPEReactTable {...{
                    data: formattedRecords,
                    columnDefinitions,
                    className: "worksheetFormulasGrid",
                    sortable: false,
                    key:`groupFormulasGrid-${group.allocationGroupId}`,
                    emptyMessage: CONSTANTS.VIEW_MODE.EDITABLE === viewMode? "There are no formulas in this group yet. Click on Add formula button to start": "No results",
                    onRowClicked: onRowClicked,
                        editableOptions: {
                            defaultColumn: defaultCellEditor,
                            onRowUpdated: onRecordUpdated,
                            skipPageReset,
                            editorsData: {
                                shouldShowValues: showValues,
                                suggestionsList: [
                                    ...dataSources.map(x => ({name: x.description, type: "Dataset Items", value: x.description})),
                                    ...totals.map(x => ({name: x.description, type: "Totals", value: x.description}))
                                ],
                                onTextAreaCaretPositionChanged: () => {},
                                companiesList: sevenSegmentLOVMap.get(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI) || []
                            }
                        }
                }} />
            </TPELoadingSpinner>
            { isEditable && <Button data-class="subPrimaryButton" onClick={onAddFormulaClicked}>Add formula</Button>}
        </SpaceBetween>
    </>;
}

function onFormulaMenuClicked(detail: ButtonDropdownProps.ItemClickDetails, row: Row, group: AllocationGroup, dispatch: React.Dispatch<TPEAction>, services: ServiceCollection, parentEntityId: string): void {
    const formula = row.original as AllocationFormula;
    switch(detail.id) {
        case contextMenuActions.EDIT.id:
            dispatch(ACTIONS.SET_FORMULA_BEING_EDITED.withPayload({formula,group}));
        break;
        case contextMenuActions.DUPLICATE.id:            
            dispatch(ACTIONS.DUPLICATE_ALLOCATION_GROUP_FORMULA.withPayload({ index: row.index + 1, originalFormula: formula, group }));
        break;
        case contextMenuActions.REMOVE.id:
            dispatch(ACTIONS.SET_REMOVE_FORMULA_PAYLOAD.withPayload({ index: row.index, formula }))
        break;
    }
}