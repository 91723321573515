import React from 'react';
import 'src/assets/styles/react-table.scss';
import { Link } from '@amzn/awsui-components-react/polaris';
import { TPEReactTable, DefaultColumnFilter } from 'src/components/shared/TPEReactTable'
import CONSTANTS from 'src/utils/constants';
import TPENavigator from '../TPENavigator';



export default function ICRSGrid(params: { 
    data: any, 
    showFilters: boolean, 
    isSearching: boolean, 
    onSortBy: (e: Array<any>) => any, 
    onFilter: (e: Array<any>) => any,
    globalFilterValue: string,
 }) {
    const { data, showFilters, isSearching, globalFilterValue, onSortBy, onFilter } = params;
    const [navigateURL, setNavigateURL] = React.useState('');

    const navigateToCalcBuilder = (recordId: string) => {
        setNavigateURL(CONSTANTS.PAGE_NAV.ICRS_CALCULATION_BUILDER.URL + '/' + btoa(recordId));
    };

    
    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "registrationNumber",
            Header: (e: any) => <div className="cell-text">Registration Number</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <Link className="cell-link calcNameLink" onFollow={detail => navigateToCalcBuilder(e.row.original.registrationNumber)}>{e.value}</Link>
        },
        {
            accessor: "atpCalculation",
            Header: (e: any) => <div className="cell-text">TP Calculation</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value.toString()}</div>
        },
        {
            accessor: "effectiveFromDate",
            Header: (e: any) => <div className="cell-text">Effective From Date</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "workbookName",
            Header: (e: any) => <div className="cell-text">Workbook Name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className = "cell-text">{e.value}</div>
        },
        {
            accessor: "beat",
            Header: (e: any) => <div className="cell-text">Beat</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "providerCompanyCode",
            Header: (e: any) => <div className="cell-text">Provider Company Code</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "providerEntityName",
            Header: (e: any) => <div className="cell-text">Provider Entity Name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "providerJurisdiction",
            Header: (e: any) => <div className="cell-text">Provider Jurisdiction</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "recipientCompanyCode",
            Header: (e: any) => <div className="cell-text">Recipient Company Code</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "recipientEntityName",
            Header: (e: any) => <div className="cell-text">Recipient Entity Name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "recipientJurisdiction",
            Header: (e: any) => <div className="cell-text">Recipient Jurisdiction</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "description",
            Header: (e: any) => <div className="cell-text">Description</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "comments",
            Header: (e: any) => <div className="cell-text">Comments</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "providerTaxRegConsideration",
            Header: (e: any) => <div className="cell-text">Provider Tax Reg Consideration</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "recipientTaxRegConsideration",
            Header: (e: any) => <div className="cell-text">Recipient Tax Reg Consideration</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "accountingOwner",
            Header: (e: any) => <div className="cell-text">Accounting Tax Owner</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "taxOwner",
            Header: (e: any) => <div className="cell-text">TP Tax Owner</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "taxProductCategoryName",
            Header: (e: any) => <div className="cell-text">Tax Product Category Name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "taxProductCategoryId",
            Header: (e: any) => <div className="cell-text">Tax Product Category ID</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "taxProductName",
            Header: (e: any) => <div className="cell-text">Tax Product Name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "taxProductId",
            Header: (e: any) => <div className="cell-text">Tax Product ID</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "exportOfServices",
            Header: (e: any) => <div className="cell-text">Export of Services</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : e.value.toString()}</div>,
        },
        {
            accessor: "taxApprover",
            Header: (e: any) => <div className="cell-text">Tax Approver</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : e.value}</div>,
        },
        {
            accessor: "createdBy",
            Header: (e: any) => <div className="cell-text">Created By</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : e.value}</div>,
        },
        {
            accessor: "lastUpdatedByAccountingUser",
            Header: (e: any) => <div className="cell-text">Last Updated By</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : e.value}</div>,
        },
        {
            accessor: "createDateTime",
            Header: (e: any) => <div className="cell-text">Create Date Time</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : new Date(e.value).toLocaleString()}</div>,
        },
        {
            accessor: "lastUpdatedDateTime",
            Header: (e: any) => <div className="cell-text">Last Updated Date Time</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : new Date(e.value).toLocaleString()}</div>,
        },
        {
            accessor: "baseRegistrationNumber",
            Header: (e: any) => <div className="cell-text">Base Registration Number</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : e.value}</div>,
        }]
    ,[]);


    // Render the UI for your table
    return (
        <div>
            <TPENavigator path={navigateURL} />
            <TPEReactTable {...{ data, columnDefinitions, className: "ICRSWorklists", isSearching, showFilters, globalFilterValue, sortable: false }} />
        </div>
    )
}