import {  Button,
          SpaceBetween } from '@amzn/awsui-components-react';
import StringUtils from "src/utils/stringUtils";
import {useEffect, useState} from "react";
import React from "react";
import FileUploadModal from "../calculation-upload-template/FileUploadModal";
import { CalculationBuilderContext } from '../CalculationBuilderView';
import { ACTIONS } from '../../../services/calculation-builder/CalculationBuilderReducer';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import { TPEBasicModal, ACTION_TYPE } from 'src/components/shared/TPEBasicModal';
import { CalculationTemplateMetaData } from 'src/models/calculation-builder/CalculationTemplate';
import CONSTANTS from 'src/utils/constants';

export default function CalculationBuilderTemplateDownloader() {
    const { services, calcBuilderState, calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const { selectedWizardStep, calculationTemplateMetaData, dataSourceRecords} = calcBuilderState;
    const [startDownloadTime, setStartDownloadTime] = useState(null as unknown as string);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [errorDisplay, setErrorDisplay] = useState('');

    const [templateDownloadUrl, templateUrlLoading, templateUrlError] = services.calculationUploadService.downloadTemplate(startDownloadTime);

    const onSucessfulUploadHandler = () => {
            setShowUploadModal(false);
            calcBuilderDispatch(ACTIONS.SET_REFRESH_DATASOURCES_FLAG.withPayload(true));
            services.messageService.showSuccessAutoDismissBanner(`File successfully uploaded.`, 10000);
    }
    
    useEffect(() => {
            if (!StringUtils.isNullOrEmpty(templateDownloadUrl)) {
                window.open(templateDownloadUrl as string);
            }
        }, [templateDownloadUrl])

    useEffect(() => {
        if(selectedWizardStep == null || calculationTemplateMetaData == null)
        {
            return;
        }
        if (selectedWizardStep.number >= 2 && calculationTemplateMetaData?.templateId == null) {
            setShowButton(true);
        }
        else{
            setShowButton(false);
        }
    }, [selectedWizardStep, calculationTemplateMetaData])

    useEffect(() => {
        validate(showUploadModal, dataSourceRecords, calculationTemplateMetaData)
    }, [showUploadModal, dataSourceRecords, calculationTemplateMetaData])

    function validate(showUploadModal: boolean, dataSourceRecords: DataSourceRecord[], calculationTemplateMetadata: CalculationTemplateMetaData | undefined) {
        if(!showUploadModal){
            return;
        }
        setErrorDisplay('');
        setHasErrors(false);
        if(!(dataSourceRecords.length == 0 || (dataSourceRecords.length == 1 && dataSourceRecords[0].isNewRecord))){
            setErrorDisplay('Existing data sources must be removed before uploading a calculation.');
            setHasErrors(true);
        }
        if (calculationTemplateMetadata?.calculationStatus != CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS) {
            setErrorDisplay(`The calculation must be in '${CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS}' status to upload.`);
            setHasErrors(true);
        }
    }


    return (
        <div>
            {showButton && (
            <div>
                <div className="calcTemplateDownloadBtn">
                    <SpaceBetween direction="horizontal" size="m">
                        <Button iconName='download' 
                        iconAlign='left' 
                        variant="normal" 
                        onClick={() => setStartDownloadTime(new Date().toString())}
                        >
                            Download calculation spreadsheet template
                        </Button>
                    </SpaceBetween>
                </div>
               <div className="calcUploadBtn">
                    <SpaceBetween direction="horizontal" size="m">
                        <Button iconName='upload' 
                        iconAlign='left' 
                        variant="normal" 
                        onClick={()=>setShowUploadModal(true)}
                        >
                            Upload calculation spreadsheet
                        </Button>
                   </SpaceBetween>
               </div>
            </div>
            )}
        {hasErrors ? 
         <TPEBasicModal className="errorModal"
                            visible={showUploadModal}
                            action={ACTION_TYPE.OK_ONLY}
                            title="Cannot upload calculation"
                            onCancel={() => setShowUploadModal(false)}
                            onConfirm={() => setShowUploadModal(false)}>
                    <div>
                        {errorDisplay}
                    </div>
                </TPEBasicModal> :
           <FileUploadModal visible={showUploadModal} onCancel={() => setShowUploadModal(false)} onSuccessfulUpload={onSucessfulUploadHandler}/>
        }
       </div>
    )
}