import CalculationStep from "src/models/calculation-builder/CalculationStep";
import { CalculationTemplate, CalculationTemplateMetaData } from "src/models/calculation-builder/CalculationTemplate";
import { DataSourceRecord } from "src/models/common/DataSourceRecord";
import LinkedCLI from "src/models/calculation-builder/LinkedCLI";
import LinkToTemplate from "src/models/calculation-builder/LinkToTemplate";
import { CLI } from "src/models/common/CLI";
import TemporaryMessage from "src/models/common/TemporaryMessage";
import { getEmptyLOVs, TPLOV } from "src/models/common/TPLOV";
import CONSTANTS from "src/utils/constants";
import { WizardStep } from "../../models/common/WizardStep";
import { Journal, JournalLine } from "src/models/calculation-builder/Journal";
import { TPEUser } from "src/models/users/TPEUser";
import { CalculationSourceType } from "src/models/common/CalculationSource";
import { CalculationExecution } from "src/models/calculation-builder/CalculationExecution";

export interface CalculationBuilderState {
    wizardSteps: Array<WizardStep>,
    selectedWizardStep?: WizardStep,
    calculationUploadS3Key?: string,
    calculation?: CLI,
    newCalculationNumber?: string,
    dataSourceRecords: DataSourceRecord[],
    steps: CalculationStep[],
    calculationVersions: number[],
    hasSavedStagingVersion: boolean,
    selectedCalculationVersion?: number,
    calculationMetaDataNeedsRefresh: boolean,
    calculationVersionsNeedRefresh: boolean,
    cliDetailsNeedRefresh: boolean,
    tpLOV: TPLOV,
    sevenSegmentLOV: Map<string, string[]>,
    createRenameTemplatePayload?: CalculationTemplateMetaData,
    temporaryMessage?: TemporaryMessage,
    calculationTemplateMetaData?: CalculationTemplateMetaData,
    validateCalculationSteps?: boolean,
    areCalculationStepsValid?: boolean,
    linkedCLIs: LinkedCLI[],
    viewMode: string,
    isEditingTemplate: boolean,
    unlinkCalculationPayload?: CalculationTemplate,
    getTemplatesForWorkbook: boolean,
    availableTemplates?: CalculationTemplate[],
    linkCalculationPayload?: LinkToTemplate,
    linkedDataSources?: DataSourceRecord[],
    linkedCalcSteps?: CalculationStep[],
    linkedCustomCoaReferenceMap?: Map<string, string>,
    calculationConfigurationState: string,
    journalsNeedRefresh: boolean,
    reviewJournalsNeedRefresh: boolean,
    calcStepsNeedRefresh: boolean,
    executeCLIPayload?: any,
    executeCLITask?: any,
    showCLIExecutionStatus?: boolean,
    cliExecutionInProgress?: boolean,
    dataSourcesNeedRefresh?: boolean,
    executionPeriod?: string,
    executionPeriodWasTouched: boolean,
    calculationExecution?: CalculationExecution,
    generateReviewJournalsPayload?: any,
    generatingReviewJournalsStatus: string,
    executionResultLines: JournalLine[],
    reversalExecutionResultLines?: JournalLine[],
    reviewJournals: Journal[],
    calculationSource: CalculationSourceType
}

export const initialState :CalculationBuilderState  = { 
    dataSourceRecords: [],
    steps: [],
    wizardSteps: [],
    calculationVersions: [],
    hasSavedStagingVersion: false,
    calculationMetaDataNeedsRefresh: true,
    calculationVersionsNeedRefresh: true,
    cliDetailsNeedRefresh: true,
    tpLOV: getEmptyLOVs(),
    sevenSegmentLOV: new Map<string, string[]>(),
    temporaryMessage: undefined,
    linkedCLIs: [],
    reviewJournals: [],
    viewMode: CONSTANTS.VIEW_MODE.EDITABLE,
    isEditingTemplate: false,
    calculationConfigurationState: CONSTANTS.CALCULATION_CONFIGURATION_STATE.DATASOURCES_STEPS,
    journalsNeedRefresh: false,
    reviewJournalsNeedRefresh: false,
    calcStepsNeedRefresh: false,
    getTemplatesForWorkbook: false,
    generatingReviewJournalsStatus: '',
    executionResultLines: [],
    executionPeriodWasTouched: false,
    calculationSource: CalculationSourceType.UNKNOWN,
}


export const getInitialState = function (): CalculationBuilderState {
    const steps = [
        {
            number: 1,
            name: "Select TP Agreement",
            isEnabled: true,
            isSelected: true,
            scrollToId: "wizardStep1",
        },
        {
            number: 2,
            name: "Configure data sources",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep2",
        },
        {
            number: 3,
            name: "Configure calculation steps",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep3",
        },
        {
             number: 4,
             name: "Configure journal entries",
             isEnabled: false,
             isSelected: false,
             scrollToId: "wizardStep4",
         },
        {
            number: 5,
            name: "Review journal entries",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep5",
        }
    ];
    
    return {...initialState,
        selectedWizardStep: steps[0],
        wizardSteps: steps,
        calculationSource: CalculationSourceType.APTTUS
    };
}

export const getInitialICRSState = function (): CalculationBuilderState {
    const steps = [
        {
            number: 1,
            name: "Select ICRS Transaction",
            isEnabled: true,
            isSelected: true,
            scrollToId: "wizardStep1",
        },
        {
            number: 2,
            name: "Configure data sources",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep2",
        },
        {
            number: 3,
            name: "Configure calculation steps",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep3",
        },
        {
             number: 4,
             name: "Configure journal entries",
             isEnabled: false,
             isSelected: false,
             scrollToId: "wizardStep4",
         },
        {
            number: 5,
            name: "Review journal entries",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep5",
        }
    ];
    
    return {...initialState,
        selectedWizardStep: steps[0],
        wizardSteps: steps,
        calculationSource: CalculationSourceType.ICRS
    };
}