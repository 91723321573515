import { useState, useEffect } from "react";
import { DatasetRecord } from "src/models/tp-allocation/DatasetRecord";
import ErrorUtils from "src/utils/ErrorUtils";
import CONSTANTS from "src/utils/constants";
import CommonDataSourceOperations from "src/services/common/CommonDataSourceOperations"
import { COADataKey } from "src/models/calculation-builder/PullBalanceRequest";
import apiService from 'src/services/ApiCallService';
import Placeholder from "src/models/tp-allocation/Placeholder";
import DataSourcesService from "src/services/calculation-builder/DataSourcesService";

export default class TPAllocationDatasetService {
    saveDataset(customCoaReference: Map<any, any>, dataUpdateOnly: boolean, recordBeingEdited?: DatasetRecord, recordNeedsSaving?: string): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData($this: TPAllocationDatasetService, record: any, customCoaReference: Map<string, string>, dataUpdateOnly: boolean) {
                try {
                    setLoading(true);
                    const saveDataSourceReq = $this.convertToSaveDatasetRequest(record || {} as DatasetRecord, customCoaReference, 
                        null as unknown as Map<string, Placeholder[]>, dataUpdateOnly);
                    const response = await (record.isNewRecord?
                        apiService.createWorksheetDatasetRecord(record.worksheetId, saveDataSourceReq) :
                        apiService.updateWorksheetDatasetRecord(record.worksheetId, saveDataSourceReq));
                    const json = (await response.json()).dataSource;
                    const {datasourceType} = json;
                    const jsonCopy = {...json, datasource: datasourceType};
                    setResult(jsonCopy);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (recordNeedsSaving != null && recordBeingEdited != null) {
                postData(this, recordBeingEdited, customCoaReference, dataUpdateOnly);
            }
        }, [recordNeedsSaving]);
        return [result, loading, error]
    }
    
    saveTemplateDataset(customCoaReference: Map<any, any>, placeholdersMap: Map<string, Placeholder[]>, dataUpdateOnly: boolean, recordBeingEdited?: DatasetRecord, recordNeedsSaving?: string): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData($this: TPAllocationDatasetService, record: any, customCoaReference: Map<string, string>, 
                placeholdersMap: Map<string, Placeholder[]>, dataUpdateOnly: boolean) {
                try {
                    setLoading(true);
                    const saveDataSourceReq = $this.convertToSaveDatasetRequest(record || {} as DatasetRecord, customCoaReference, placeholdersMap, dataUpdateOnly);
                    const response = await (record.isNewRecord?
                        apiService.createWorksheetTemplateDatasetRecord(record.templateId, saveDataSourceReq) :
                        apiService.updateWorksheetTemplateDatasetRecord(record.templateId, saveDataSourceReq));
                    const json = (await response.json()).dataSource;
                    const {datasourceType} = json;
                    const jsonCopy = {...json, datasource: datasourceType};
                    setResult(jsonCopy);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (recordNeedsSaving != null && recordBeingEdited != null) {
                postData(this, recordBeingEdited, customCoaReference, placeholdersMap, dataUpdateOnly);
            }
        }, [recordNeedsSaving]);
        return [result, loading, error]
    }

    deleteDatasetItem(deleteDataSourceRequest: any) : [DatasetRecord, boolean, string] {
        const [result, setResult] = useState(null as unknown as DatasetRecord);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(worksheetId:string, datasourceId: string) {
                try {
                    const response = await apiService.deleteWorksheetDatasetRecord(worksheetId, datasourceId);
                    const json = await response.json()
                    setResult(json.dataSource);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (deleteDataSourceRequest != null) {
                fetchData(deleteDataSourceRequest.parentEntityId, deleteDataSourceRequest.datasourceId);
            }
        }, [deleteDataSourceRequest]);
        return [result, loading, error]
    }

    deleteTemplateDatasetItem(deleteDataSourceRequest: any) : [DatasetRecord, boolean, string] {
        const [result, setResult] = useState(null as unknown as DatasetRecord);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(templateId:string, datasourceId: string) {
                try {
                    const response = await apiService.deleteWorksheetTemplateDatasetRecord(templateId, datasourceId);
                    const json = await response.json()
                    setResult(json.dataSource);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (deleteDataSourceRequest != null) {
                fetchData(deleteDataSourceRequest.parentEntityId, deleteDataSourceRequest.datasourceId);
            }
        }, [deleteDataSourceRequest]);
        return [result, loading, error]
    }

    getDatasetRecords(worksheetId?: string, worsheetVersion?: number, executionPeriodId?: string, refreshRecordsFlag?: string): [DatasetRecord[], Map<string, string> | null, boolean, string] {
        const [result, setResult] = useState([] as DatasetRecord[]);
        const [referenceMap, setReferenceMap] = useState(null as Map<string, string> | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData($this: TPAllocationDatasetService, worksheetId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetDataSources(worksheetId, worsheetVersion == null? -1 : worsheetVersion, executionPeriodId);
                    const json = await response.json();
                    const {records, customCoaReferenceMap} = $this.convertToDatasetRecords(json.dataSources, worksheetId)
                    setResult(records);
                    setReferenceMap(customCoaReferenceMap);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (refreshRecordsFlag != null && worksheetId != null) {
                fetchData(this, worksheetId);
            }
        }, [worksheetId, refreshRecordsFlag]);
        return [result, referenceMap, loading, error]
    }

    getTemplateDatasetRecords(templateId?: string, templateVersion?: number): [DatasetRecord[], Map<string, string> | null, boolean, string] {
        const [result, setResult] = useState([] as DatasetRecord[]);
        const [referenceMap, setReferenceMap] = useState(null as Map<string, string> | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData($this: TPAllocationDatasetService, templateId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetTemplateDataSources(templateId, templateVersion == null? -1 : templateVersion);
                    const json = await response.json();
                    const {records, customCoaReferenceMap} = $this.convertToDatasetRecords(json.dataSources, undefined, templateId)
                    setResult(records);
                    setReferenceMap(customCoaReferenceMap);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (templateId != null) {
                fetchData(this, templateId);
            }
        }, [templateId]);
        return [result, referenceMap, loading, error]
    }

    convertToDatasetRecords(datasourceList: any[], worksheetId?: string, templateId?: string) : { records: DatasetRecord[], customCoaReferenceMap: Map<string, string> } {
      const records = [] as DatasetRecord[];
      const customCoaReferenceMap = new Map<string, string>();

      datasourceList.forEach(apiDataSource => {
          const dataSource = {} as DatasetRecord;
          dataSource.datasourceId = apiDataSource.datasourceId;
          dataSource.datasource = apiDataSource.datasourceType;
          dataSource.period = apiDataSource.period;
          dataSource.fxDate = apiDataSource.fxDate;
          dataSource.fxType = apiDataSource.fxType;
          dataSource.fxRate = apiDataSource.fxRate;
          dataSource.currency = apiDataSource.currency;
          dataSource.description = apiDataSource.description;
          dataSource.value = apiDataSource.value;
          if (apiDataSource.errors){
              dataSource.errors = new Map(Object.entries(apiDataSource.errors));
          }
          if (worksheetId != null) {
              dataSource.worksheetId = worksheetId;
              dataSource.worksheetVersion = apiDataSource.worksheetVersion;
          }
          if (templateId != null) {
              dataSource.templateId = templateId;
              dataSource.templateVersion = apiDataSource.templateVersion;
          }
          if (apiDataSource.startPeriod != null && apiDataSource.endPeriod != null) {
              dataSource.customPeriod = `${apiDataSource.startPeriod.replace('-', '/')} - ${apiDataSource.endPeriod.replace('-', '/')}`;
              dataSource.period = null as unknown as string;
          }
          if (dataSource.datasource === CONSTANTS.DATA_SOURCE_TYPES.GENERAL_LEDGER){
              let keyCount = 2;
              if (apiDataSource.dataKeys){
                  dataSource.dataKeyInput = {
                      selectedCompanies: CommonDataSourceOperations.convertToDataKeyJoinedString(apiDataSource.dataKeys.companies),
                      selectedAccounts: CommonDataSourceOperations.convertToDataKeyJoinedString(apiDataSource.dataKeys.glAccounts),
                      selectedCOA: new Map<string, string>()
                  }

                  for (const [key, value] of Object.entries(apiDataSource.dataKeys)) {
                      if (key != CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.WEB_API && key != CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.WEB_API) {
                          const coaSegmentArr = Object.values(CONSTANTS.COA_SEGMENT_MAPPING).filter(x => x.WEB_API == key);
                          const coaSegment = coaSegmentArr.length > 0 ? coaSegmentArr[0].UI : '';
                          dataSource.dataKeyInput.selectedCOA.set(coaSegment, CommonDataSourceOperations.convertToDataKeyJoinedString(value as COADataKey[]));
                          keyCount++;
                      }
                  }
              }
              dataSource.dataKey = keyCount + CONSTANTS.DATA_SOURCE_DATA_KEY_COUNT_SUFFIX;
              CommonDataSourceOperations.updateCustomCoaReferenceMap(apiDataSource.dataKeys, customCoaReferenceMap);
          }

          // On executing worksheet, the data sources will not have the last balance pull ID but will have the value
          if (apiDataSource.lastBalancePullId != null || apiDataSource.value != null) {
            dataSource.lastBalancePullRequestID = apiDataSource.lastBalancePullId;
            const { glBalances, glBalanceBreakdownKeys } = new DataSourcesService().convertToGLBalancesModel({ 
                balancePerCompanyAccount: apiDataSource.balancePerCompanyAccount, 
                balanceBreakdowns: apiDataSource.balanceBreakdowns,
                dataKeyInput: dataSource.dataKeyInput,
                totalBalance: apiDataSource.value
            });
            dataSource.glBalances = glBalances;
            dataSource.glBalanceDrillDownKeys = glBalanceBreakdownKeys;
        }
  
          if (apiDataSource.lastBalancePullDate != null) {
              dataSource.lastBalancePullDate = apiDataSource.lastBalancePullDate;
          }
          
          records.push(dataSource);
      })
      return { records, customCoaReferenceMap };
  }

  convertToSaveDatasetRequest(record: DatasetRecord, customCoaReference: Map<string, string>, placeholdersMap: Map<string, Placeholder[]>, dataUpdateOnly: boolean) : any {
        const saveDataSourceRequest: any = {
            worksheetId: record.worksheetId,
            dataSourceId: record.datasourceId,
            dataSourceType: record.datasource,
            period: record.period,
            description: record.description,
            currency: record.currency,
            lastBalancePullId: record.lastBalancePullRequestID,            
        }
        if (record.customPeriod != null) {
            if (record.customPeriod.indexOf('/') > 0) {
                const customPeriodArr = record.customPeriod.split('-').map(x => x.replace('/', '-'));
                saveDataSourceRequest.startPeriod = customPeriodArr[0].trim();
                saveDataSourceRequest.endPeriod = customPeriodArr[1].trim();
            } else {
                saveDataSourceRequest.startPeriod = record.customPeriod;
                saveDataSourceRequest.endPeriod = record.customPeriod;
            }
            
            saveDataSourceRequest.period = CONSTANTS.DATA_SOURCE_VALUES.CUSTOM_PERIOD;
        }
        if (record.fxDate != null) {
            saveDataSourceRequest.fxDate = record.fxDate;
        }
        if (record.fxType != null) {
            saveDataSourceRequest.fxType = record.fxType;
        }
        if (record.fxRate != null) {
            saveDataSourceRequest.fxRate = record.fxRate;
        }
        if (record.value != null && record.value.length > 0) {
            saveDataSourceRequest.value = Number(record.value);
        }

        if (record.datasource == CONSTANTS.DATA_SOURCE_TYPES.GENERAL_LEDGER){
            const dataKeyInput: any = {};
            dataKeyInput.companies = CommonDataSourceOperations.convertToCOADataKeyList(record.dataKeyInput?.selectedCompanies, customCoaReference,
                placeholdersMap != null ? placeholdersMap.get(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI) : undefined);
            dataKeyInput.glAccounts = CommonDataSourceOperations.convertToCOADataKeyList(record.dataKeyInput?.selectedAccounts, customCoaReference,
                placeholdersMap != null ? placeholdersMap.get(CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI) : undefined);
            record.dataKeyInput?.selectedCOA.forEach((value, key) => {
                const coaSegmentArr = Object.values(CONSTANTS.COA_SEGMENT_MAPPING).filter(x => x.UI == key);
                const coaSegment = coaSegmentArr.length > 0 ? coaSegmentArr[0].WEB_API : '';
                dataKeyInput[coaSegment] = CommonDataSourceOperations.convertToCOADataKeyList(value, customCoaReference, 
                    placeholdersMap != null ? placeholdersMap.get(coaSegmentArr[0].UI) : undefined);
            });
            saveDataSourceRequest.dataKeys = dataKeyInput;
        }

        if (dataUpdateOnly) {
            saveDataSourceRequest.dataUpdateOnly = true;
        }

        return saveDataSourceRequest;
    }
}