import TPEAction from 'src/models/common/TPEAction';
import { PlaceholdersState, initialState } from './PlaceholdersState';
import CONSTANTS from 'src/utils/constants';
import { newExpression } from '@babel/types';
import ArrayUtils from 'src/utils/arrayUtils';

/**
 * List here the actions supported by this reducer
 */
 export const ACTIONS = Object.freeze({
    RESET: new TPEAction('RESET'),
    SET_PLACEHOLDERS: new TPEAction('SET_PLACEHOLDERS'),
    SET_PLACEHOLDER_BEING_EDITED: new TPEAction('SET_PLACEHOLDER_BEING_EDITED'),
    SET_EDITOR_SUGGESTIONS: new TPEAction('SET_EDITOR_SUGGESTIONS'),
    SET_EDITOR_CARET_POSITION: new TPEAction('SET_EDITOR_CARET_POSITION'),
    APPEND_TO_FORMULA_EDITOR: new TPEAction('APPEND_TO_FORMULA_EDITOR'),
    ADD_PLACEHOLDER: new TPEAction('ADD_PLACEHOLDER'),
    ADD_NEW_COA_SEGMENT: new TPEAction('ADD_NEW_COA_SEGMENT'),
    UPDATE_COA_SEGMENT: new TPEAction('UPDATE_COA_SEGMENT'),
    REMOVE_PLACEHOLDER: new TPEAction('REMOVE_PLACEHOLDER'),
    DUPLICATE_PLACEHOLDER: new TPEAction('DUPLICATE_PLACEHOLDER'),
    SAVE_PLACEHOLDERS: new TPEAction('SAVE_PLACEHOLDERS'),
    TOGGLE_SHOW_VALUES: new TPEAction('TOGGLE_SHOW_VALUES'),
    UPDATE_PLACEHOLDER: new TPEAction('UPDATE_PLACEHOLDER'),
    EXIT_EDIT_MODE: new TPEAction('EXIT_EDIT_MODE'),
    SET_EXPANDED_STATE: new TPEAction('SET_EXPANDED_STATE'),
    REFRESH_PLACEHOLDER: new TPEAction('REFRESH_PLACEHOLDER'),
    UPDATE_PLACEHOLDER_ERROR: new TPEAction('UPDATE_PLACEHOLDER_ERROR'),
    SET_RUN_VALUES: new TPEAction('SET_RUN_VALUES'),
    SET_TOTALS_ARE_SAVED_AND_VALID: new TPEAction('SET_TOTALS_ARE_SAVED_AND_VALID'),
});


/**  
* This function is responsible for updating the state based on action type
* @param state The current dashboard state
* @param action The current dispatched action 
*/
export function placeholdersReducer(state: PlaceholdersState, action: TPEAction) : PlaceholdersState {
    switch (action.type) {
        case ACTIONS.RESET.type:
            return {...initialState};
        case ACTIONS.SET_PLACEHOLDERS.type: {
            const placeholdersMap = action.payload as Map<string,any[]>;
            const lengths = action.payload == null || action.payload.size == 0? [0] : Array.from(placeholdersMap.values()).map(a => a.length);

            return {
                ...state,
                error: '',
                isLoading: false,
                placeholdersMap: action.payload,
                maxPlaceholdersCount: Math.max(...lengths),
                alreadySelectedCOAs: Array.from(placeholdersMap.keys())
            };
        }
        case ACTIONS.SET_PLACEHOLDER_BEING_EDITED.type: {
            const { placeholdersMap: placeholders } = state;
            const placeholdersList = Array.from(placeholders.values()).flat();
            if (action.payload == null) {
                placeholdersList.forEach(x => x.isBeingEdited = false);
                return {
                    ...state,
                    placeholderBeingEdited: undefined,
                    placeholdersMap: new Map(placeholders),
                };
            }
            
            placeholdersList.forEach(x => x.isBeingEdited = false);

            const editingRecord = placeholdersList.find(x => x.placeholderId === action.payload.placeholderId);
            if (editingRecord){
                editingRecord.isBeingEdited = true;                
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                placeholderBeingEdited: editingRecord,
                placeholdersMap: new Map(placeholders),
            };
        }
        case ACTIONS.ADD_PLACEHOLDER.type: {
            const { placeholdersMap } = state;
            const { newPlaceholder, coaSegment } = action.payload;
            newPlaceholder.isNew = true;
            newPlaceholder.placeholderId = new Date().toISOString();
            if (placeholdersMap.has(coaSegment)){
                placeholdersMap.get(coaSegment)?.push(newPlaceholder);
            }
            else {
                placeholdersMap.set(coaSegment,[newPlaceholder]);
            }
            const lengths = Array.from(placeholdersMap.values()).map(a => a.length);
            return {
                ...state,
                error: '',
                isLoading: false,
                placeholdersMap: new Map(placeholdersMap),
                maxPlaceholdersCount: Math.max(...lengths)
            }
        }
        case ACTIONS.ADD_NEW_COA_SEGMENT.type: {
            const { placeholdersMap: placeholders } = state;
            if (!placeholders.has(CONSTANTS.NEW_COA_PLACEHOLDER_SEGMENT)){
                placeholders.set(CONSTANTS.NEW_COA_PLACEHOLDER_SEGMENT,[]);
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                placeholdersMap: new Map(placeholders),
            }
        }
        case ACTIONS.UPDATE_COA_SEGMENT.type: {
            const { placeholdersMap } = state;
            const { oldSegment, newSegment, newPlaceholderForNewSegment } = action.payload;
            const placeholders = placeholdersMap.get(oldSegment) || [];
            if (placeholders.some(x => !x.isNew)){
                return {
                    ...state,
                    error: `Cannot update COA Segment with placeholders in it. Delete all placeholders for ${oldSegment} and try again`,                    
                }
            }
            placeholdersMap.delete(oldSegment);
            placeholdersMap.set(newSegment, [newPlaceholderForNewSegment])
            return {
                ...state,
                error: '',
                isLoading: false,
                placeholdersMap: new Map(placeholdersMap),
                alreadySelectedCOAs: Array.from(placeholdersMap.keys())
            }
        }
        case ACTIONS.REMOVE_PLACEHOLDER.type: {
            let { placeholdersMap } = state;
            const { coaSegment, placeHolder } = action.payload;
            placeholdersMap.get(coaSegment)?.pop();
            if (ArrayUtils.isNullOrEmpty(placeholdersMap.get(coaSegment))){
                placeholdersMap.delete(coaSegment);
            }
            const lengths = Array.from(placeholdersMap.values()).map(a => a.length);
            return {
                ...state,
                error: '',
                isLoading: false,
                placeholdersMap: new Map(placeholdersMap),
                alreadySelectedCOAs: Array.from(placeholdersMap.keys()),
                maxPlaceholdersCount: Math.max(...lengths),
                //deleteStepPayload: {calculationNumber:step.calculationNumber, stepId: step.stepId},
            }
        }
        case ACTIONS.REFRESH_PLACEHOLDER.type: {
            const { placeholdersMap: placeholders, placeholderBeingEdited } = state;
            const { coaSegment, placeholder } = action.payload;
            const placeholderToRefresh = placeholderBeingEdited != null && placeholderBeingEdited.placeholderId === placeholder.placeholderId? 
                placeholderBeingEdited : 
                placeholders.get(coaSegment)?.find(x => x.placeholderId === (placeholder.unsavedId || placeholder.placeholderId));
            if (placeholderToRefresh != null){
                placeholderToRefresh.placeholderId = placeholder.placeholderId;
                placeholderToRefresh.coaSegment = placeholder.coaSegment;
                placeholderToRefresh.placeholderName = placeholder.placeholderName;
                placeholderToRefresh.parentEntityId = placeholder.parentEntityId;
                placeholderToRefresh.parentEntityVersion = placeholder.parentEntityVersion;
                placeholderToRefresh.isNew = false;
                placeholderToRefresh.error = placeholder.error;
                placeholderToRefresh.savingStatus = placeholder.savingStatus;
                placeholderToRefresh.isRecentlySaved = placeholder.isRecentlySaved;
            }
            else {
                console.warn("********* Could not refresh placeholder because it was not found");
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                placeholdersMap: new Map(placeholders),
                placeholderSavedId: placeholderToRefresh?.placeholderId,
            }
        }
        case ACTIONS.UPDATE_PLACEHOLDER_ERROR.type: {
            const { placeholdersMap: placeholders, placeholderBeingEdited } = state;
            const { coaSegment, placeholderId, error } = action.payload;
            const placeholderToUpdate = placeholderBeingEdited != null && placeholderBeingEdited.placeholderId === placeholderId? 
                placeholderBeingEdited : 
                placeholders.get(coaSegment)?.find(x => x.placeholderId === placeholderId);
            if (placeholderToUpdate != null){
                placeholderToUpdate.error = error;
            }
            else {
                console.warn("********* Could not update placeholder because it was not found");
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                placeholdersMap: new Map(placeholders),
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}




