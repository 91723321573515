import React from "react";
import ExecutionStatusIndicator from "src/components/shared/ExecutionStatusIndicator";
import {
    FILTERING_OPERATORS_FOR_NUMBER,
    FILTERING_OPERATORS_FOR_STRING,
    JE_STATUS_DASHBOARD_TABLE_COLUMN_NAMES,
    JEStatusDashboardTableColumns
} from "src/components/reports/jeStatusDashboard/Constants";
import StringUtils from "src/utils/stringUtils";

const getCustomPropertiesForColumnDefinitions = (column: string) => {
    let cell;
    switch (column) {
        case JE_STATUS_DASHBOARD_TABLE_COLUMN_NAMES.JE_STATUS_COLUMN:
            cell = (e: any) => e[column] !== "" ? <ExecutionStatusIndicator status={e[column]} /> : "";
            break
        default:
            cell = (e: any) => e[column] ?? "";
    }
    return { cell };
};

const getCustomPropertiesForColumnFiltering = (column: string) => {
    let properties = {};
    switch (column) {
        case JE_STATUS_DASHBOARD_TABLE_COLUMN_NAMES.BASE_AMOUNT_COLUMN:
        case JE_STATUS_DASHBOARD_TABLE_COLUMN_NAMES.MARKUP_AMOUNT_COLUMN:
            properties = {
                groupValuesLabel: column,
                operators: FILTERING_OPERATORS_FOR_NUMBER
            };
            break
        default:
            properties = {
                groupValuesLabel: column,
                operators: FILTERING_OPERATORS_FOR_STRING
            };

    }
    return properties;
};

export const prepareColumnDefinitions = () => {
    const columnDefinitions: any[] = [];

    JEStatusDashboardTableColumns.forEach((column: string) => {
        columnDefinitions.push({
            id: column,
            header: StringUtils.convertCamelCaseToTitleCase(column),
            sortingField: column,
            width: 300,
            ...getCustomPropertiesForColumnDefinitions(column)
        });
    });
    return columnDefinitions;
};

export const getJEStatusDashboardTableFilteringProperties = () => {
    const columnFilteringProperties: any[] = [];

    JEStatusDashboardTableColumns.forEach((column: string) => {
        columnFilteringProperties.push({
            propertyLabel: StringUtils.convertCamelCaseToTitleCase(column),
            key: column,
            ...getCustomPropertiesForColumnFiltering(column)
        });
    });
    return columnFilteringProperties;
}
