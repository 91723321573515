import TPEAction from "src/models/common/TPEAction";
import TPESearchRequest from "src/models/common/TPESearchRequest";
import { CustomDataTableRecordsState } from "src/services/custom-data-tables/CustomDataTableRecordsState";
import { SearchCustomDataTableRowsResult } from "src/models/custom-data-tables/SearchCustomDataTableRowsResult";
import CustomDataTableRowsSearchRequest from "src/models/custom-data-tables/CustomDataTableRowsSearchRequest";

export const ACTIONS = Object.freeze({
    SET_ERROR: new TPEAction('SET_ERROR'),
    SET_CUSTOM_DATA_TABLE_ROWS_INFO: new TPEAction('SET_CUSTOM_DATA_TABLE_ROWS_INFO'),
    SET_SEARCH_PAYLOAD: new TPEAction('SET_SEARCH_PAYLOAD'),
    SET_SEARCH_RESULT: new TPEAction('SET_SEARCH_RESULT'),
    SET_CURRENCY_CODES: new TPEAction('SET_CURRENCY_CODES'),
    ADD_NEW_ROW: new TPEAction('ADD_NEW_ROW'),
    UPDATE_CUSTOM_DATA_TABLE_RECORD: new TPEAction('UPDATE_CUSTOM_DATA_TABLE_RECORD'),
    SET_ACTIVE_CLIS: new TPEAction('SET_ACTIVE_CLIS'),
    RESET_UNSAVED_ROWS_COUNT: new TPEAction('RESET_UNSAVED_ROWS_COUNT')
});

export function customDataTableRecordsReducer(state: CustomDataTableRecordsState, action: TPEAction) : CustomDataTableRecordsState {
    switch(action.type) {
        case ACTIONS.SET_ERROR.type:
            return {
                ...state,
                error: action.payload,
            };
        case ACTIONS.SET_SEARCH_PAYLOAD.type:
            return {
                ...state,
                searchPayload: action.payload
            }
        case ACTIONS.SET_SEARCH_RESULT.type:
            return {
                ...state,
                searchResult: action.payload,
                unsavedRowsCount: 0
            }
        case ACTIONS.SET_CURRENCY_CODES.type:
            return {
                ...state,
                currencyCodes: action.payload
            }
        case ACTIONS.ADD_NEW_ROW.type: {
            if(state.searchResult) {
                const records = [...state.searchResult.rowsInfo.records, {isUpdated: true, isNewRecord: true}];
                const rowsInfo = {...state.searchResult.rowsInfo, records: records};
                return {
                    ...state,
                    searchResult: {...state.searchResult, rowsInfo: rowsInfo},
                    unsavedRowsCount: state.unsavedRowsCount + 1
                }
            }
            return state
        }
        case ACTIONS.UPDATE_CUSTOM_DATA_TABLE_RECORD.type: {
            if(state.searchResult) {
                const records = state.searchResult.rowsInfo.records;
                const isFirstUpdateInExistingRow = !records[action.payload.rowIndex].isNewRecord && !records[action.payload.rowIndex].isUpdated
                records[action.payload.rowIndex][action.payload.attribute] = action.payload.value;
                records[action.payload.rowIndex].isUpdated = true;
                const rowsInfo = {...state.searchResult.rowsInfo, records: records};
                return {
                    ...state,
                    searchResult: {...state.searchResult, rowsInfo: rowsInfo},
                    unsavedRowsCount: isFirstUpdateInExistingRow? state.unsavedRowsCount + 1: state.unsavedRowsCount
                }
            }
            return state
        }
        case ACTIONS.SET_ACTIVE_CLIS.type:
            return {
                ...state,
                cliLov: action.payload
            }
        case ACTIONS.RESET_UNSAVED_ROWS_COUNT.type:
            return {
                ...state,
                unsavedRowsCount: 0
            }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}