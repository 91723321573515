import React, { useEffect, useRef, useState } from 'react';
import { FormField, Select, Grid, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import ServiceCollection from 'src/services/ServiceCollection';

type ComponentProps = {
    services: ServiceCollection,
    resetNeeded?: any,
    selectedAgreementNumber?: string, 
    selectedAgreementLine?: string, 
    selectedCalculationLine?: string
    onAgreementNumberSelected: (number: string | undefined) => void,
    onAgreementLineSelected: (number: string | undefined) => void,
    onCalculationLineSelected: (number: string | undefined) => void
}

export default function CalculationLineSelector(props: ComponentProps) {
    const { services, 
        resetNeeded, 
        selectedAgreementNumber, 
        selectedAgreementLine, 
        selectedCalculationLine,
        onAgreementLineSelected, 
        onAgreementNumberSelected, 
        onCalculationLineSelected } = props;

    // State management hooks
    const [loadTime, setLoadTime] = React.useState('');
    const [agreementsResult, agreementsLoading, agreementsError] = services.agreementsService.getAgreements(loadTime);
    const [agreementLinesResult, agreementLinesLoading, agreementLinesError] = services.agreementsService.getAgreementLines(selectedAgreementNumber);
    const [calculationLinesResult, calculationLinesLoading, calculationLinesError] = services.calculationsService.getCalculationLines(selectedAgreementLine);

    React.useEffect(() => {
        let mounted = true;
        setLoadTime(new Date().toString());
        return () => { mounted = false };
    }, []);

    React.useEffect(() => {
        if (resetNeeded == null) {
            return;
        }
        onAgreementLineSelected(undefined);
        onAgreementNumberSelected(undefined);
        onCalculationLineSelected(undefined);
    }, [resetNeeded]);

    
    return (
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
            <FormField
                label="Select TP agreement"
                errorText={agreementsError}
            >
                <Select className="agreementsDropdown"
                    selectedOption={selectedAgreementNumber == null ? null : { label: selectedAgreementNumber, value: selectedAgreementNumber }}
                    options={agreementsResult == null ? [] : agreementsResult.map(x => ({ label: x, value: x }))}
                    onChange={({ detail }) => {
                        onAgreementNumberSelected(detail.selectedOption.value);
                    }}
                    loadingText="Loading agreements"
                    statusType={agreementsLoading ? "loading" : "finished"}
                    placeholder="Select TP agreement"
                    virtualScroll={true}
                    filteringType="auto"
                />
            </FormField>
            <SpaceBetween direction="vertical" size="xl">
                <FormField
                    label="Select TP agreement line"
                    errorText={agreementLinesError}
                >

                    <Select className="agreementLinesDropdown"
                        selectedOption={selectedAgreementLine == null ? null : { label: selectedAgreementLine, value: selectedAgreementLine }}
                        options={(agreementLinesResult).map((x: any) => ({ label: x, value: x }))}
                        onChange={({ detail }) => {
                            onAgreementLineSelected(detail.selectedOption.value);
                        }}
                        loadingText="Loading agreement lines"
                        statusType={agreementLinesLoading ? "loading" : "finished"}
                        placeholder="Select TP agreement line"
                        virtualScroll={true}
                        filteringType="auto"
                        disabled={selectedAgreementNumber == null}
                    />
                </FormField>
            </SpaceBetween>

            <FormField
                label="Select TP calculation line"
                errorText={calculationLinesError}
            >

                <Select className="calculationLinesDropdown"
                    selectedOption={selectedCalculationLine == null ? null : { label: selectedCalculationLine, value: selectedCalculationLine }}
                    options={calculationLinesResult.map((x: any) => ({ label: x, value: x }))}
                    onChange={({ detail }) => {
                        onCalculationLineSelected(detail.selectedOption.value);
                    }}
                    loadingText="Loading calculation lines"
                    statusType={calculationLinesLoading ? "loading" : "finished"}
                    placeholder="Select TP calculation line"
                    virtualScroll={true}
                    filteringType="auto"
                    disabled={selectedAgreementLine == null}
                />
            </FormField>
        </Grid>);
}