import React, { useState } from "react";
import { Autosuggest } from "@amzn/awsui-components-react";
import { Workbook } from "src/models/agreements/Workbook";
import CONSTANTS from "src/utils/constants";

export default function ReversalAutosuggest(props: {
    filterType: string, 
    clis: string[], 
    workbooks: Workbook[],
    onSelect: (value: string) => void,
    loading: boolean,
    disabled: boolean,
}) {
    const { filterType, clis, workbooks, onSelect, loading, disabled } = props;
    const [options, setOptions] = useState([] as any[]);
    const [value, setValue] = useState(null as unknown as string);
    
    const handleLoadItems = (event: any) => {
        let { filteringText } = event.detail;
        const originalFilteringText = filteringText;

        if (filteringText == null || filteringText == '') {
            setOptions([]);
            return;
        }

        filteringText = filteringText.trim().replace(/^0+/, '');
        if (filteringText.length < 3) {
            setOptions([]);
            return;
        }

        if (filterType == CONSTANTS.REVERSAL_SEARCH_OPTIONS.WORKBOOK) {
            const filteredOptions = workbooks.filter((x: Workbook) => x.workbookName.toLowerCase().includes(filteringText.toLowerCase()))
                .map((x: Workbook) => { return {value: `${x.workbookId || ''}: ${x.workbookName}` }});
            setOptions(filteredOptions || []);
        } else {
            const filteredOptions = clis.filter((x: string) => x.includes(originalFilteringText.trim())).map((x: string) => { return {value: x} });
            setOptions(filteredOptions || []);
        }
    }

    return <Autosuggest
                data-class="textFilterAutosuggest"
                onChange={({ detail }) => {
                    setValue(detail.value);
                }}
                onSelect={({ detail }) => onSelect(detail.selectedOption?.value || value || '')}
                options={options}
                onLoadItems={(event) => handleLoadItems(event)}
                value={value}
                filteringType="manual"
                placeholder="Search for CLI or workbook to reverse"
                empty="No matches found"
                statusType={loading ? 'loading' : 'finished'}
                loadingText="Loading"
                enteredTextLabel={val => `Use: "${val}"`}
                disabled={disabled}
            />
}