import TPESearchRequest from "src/models/common/TPESearchRequest"
import CustomDataTableRowsSearchRequest from "src/models/custom-data-tables/CustomDataTableRowsSearchRequest"
import { SearchCustomDataTableRowsResult } from "src/models/custom-data-tables/SearchCustomDataTableRowsResult"

export interface CustomDataTableRecordsState {
    currencyCodes?: string[],
    error?: string,
    searchPayload?: CustomDataTableRowsSearchRequest,
    searchResult?: SearchCustomDataTableRowsResult,
    cliLov?: string[],
    unsavedRowsCount: number,
}


export const initialState: CustomDataTableRecordsState = {
    unsavedRowsCount: 0
}