import React, { useEffect, useState } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import {Container, Grid, Box, SpaceBetween, Button} from '@amzn/awsui-components-react/polaris';
import TemplateAndCalculationsSelector from '../shared/TemplateAndCalculationsSelector'
import ArrayUtils from 'src/utils/arrayUtils';
import CalculationsConfirmationGrid from '../shared/CalculationsConfirmationGrid'
import { CalculationTemplate } from 'src/models/calculation-builder/CalculationTemplate';
import { CLI } from 'src/models/common/CLI';
import CONSTANTS from 'src/utils/constants';
import TPENavigator from 'src/components/TPENavigator';
import {BulkLinkToTemplate} from "src/models/calculation-builder/BulkLinkToTemplate";
import TPEErrorWatcher from "src/components/shared/TPEErrorWatcher";


export default function LinkToTemplateView (props: { services: ServiceCollection }) {
    const {services} = props;
    const [selectedTemplate, setSelectedTemplate] = useState(undefined as undefined | CalculationTemplate);
    const [selectedCalculations, setSelectedCalculations] = useState([] as CLI[]);
    const [showConfirmationGrid, setShowConfirmationGrid] = useState(false);
    const [bulkLinkCalculationPayload, setBulkLinkCalculationPayload] = useState(undefined as undefined | BulkLinkToTemplate);
    const [navigateURL, setNavigateURL] = useState('');
    const [linkedCalculations, setLinkedCalculations] = useState([] as CLI[]);

    const navigateToCalcBuilder = (recordId: string) => {
        const url = CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + '/' + btoa(recordId);
        setNavigateURL(url);
    };
    
    const [linkResult, isLinking, linkError] = services.calculationBuilderService.bulkLinkCalculationsToTemplate(bulkLinkCalculationPayload);

    useEffect(() => {
      if (linkResult == null) {
          return;
      }
        if (selectedCalculations.length < 2 && linkResult.linkedCalculations != null && linkResult.linkedCalculations.length == 1) {
            navigateToCalcBuilder(selectedCalculations[0].recordId);
        } else {
            if(linkResult.linkedCalculations != null && linkResult.linkedCalculations.length >= 1) {
                services.messageService.showSuccessAutoDismissBanner(`${linkResult.linkedCalculations?.length} calculation(s) were successfully linked to the template ${selectedTemplate?.templateName}
            : ${linkResult.linkedCalculations?.reduce((result, calculation) => result + ", " + calculation)}.`);
            }
            if(linkResult.errors != null && linkResult.errors.length >= 1) {
                services.messageService.showErrorBanner(`${linkResult.errors.length} calculation(s) could not be linked due to these errors: 
                ${linkResult.errors?.reduce((error, calculation) => error + ", " + calculation)}.`);
            }
            selectedCalculations.forEach((calculation) => {
                if(linkResult.linkedCalculations != null && linkResult.linkedCalculations.indexOf(calculation.calculationNumber) > -1) {
                    linkedCalculations.push(calculation);
                }
            });
            if(linkResult.linkedCalculations != null && linkResult.linkedCalculations.length == 0) {
                return;
            }
            setLinkedCalculations(linkedCalculations);
            setShowConfirmationGrid(true);
        }
    }, [linkResult])
    
    const onLinkClicked = function() {
      if (selectedTemplate == null || ArrayUtils.isNullOrEmpty(selectedCalculations)) {
        services.messageService.showErrorAutoDismissBanner("Please select a template and at least 1 calculation to link");
        return;
      }
      const calculations: string[] = []
      selectedCalculations.forEach((calculation) => calculations.push(calculation.calculationNumber));
      setBulkLinkCalculationPayload({
          templateId: selectedTemplate.templateId,
          calculationNumbers: calculations
      } as BulkLinkToTemplate)
    }

    return <Container className="polaris-content-container">
        <TPENavigator path={navigateURL} />
        {
          showConfirmationGrid?
            <CalculationsConfirmationGrid
              headerText={`${linkedCalculations.length} Calculation(s) linked to template "${selectedTemplate?.templateName}"`}
              calculations={linkedCalculations} />
          :
          <Grid
              gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}
            >
              <TemplateAndCalculationsSelector
                filterCLIsBySelectedTemplate
                label1='Select a Template'
                label2='Select New CLI(s) to link'
                services={services}
                onTemplateSelected={(templateId) => setSelectedTemplate(templateId)}
                onCalculationsSelected={(calculations) => setSelectedCalculations(calculations)} />
              <Box float="right" padding={{ top: "xl" }}>
              <SpaceBetween direction="horizontal" size="s">
                <Button href="/calculations">Cancel</Button>
                <Button onClick={onLinkClicked}
                  loading={isLinking}
                  variant="primary"
                  disabled={!selectedTemplate || selectedCalculations.length === 0}>
                    Link calculations
                </Button>
              </SpaceBetween>
            </Box>
          </Grid>
      }
        <TPEErrorWatcher services={services} errors={[linkError]} />
    </Container>;
}