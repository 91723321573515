import React from "react";
import TPEAction from '../../models/common/TPEAction';
import { TPAllocationState } from '../../services/tp-allocation/TPAllocationState';
import ServiceCollection from 'src/services/ServiceCollection';
import { UserProfile } from 'src/models/users/UserProfile';

export type TPAllocationContextType = {
    tpAllocationState: TPAllocationState,
    tpAllocationDispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    userProfile: UserProfile,
}


export const TPAllocationContext = React.createContext({} as TPAllocationContextType);