import GetMECPreValidationDataResult from 'src/models/mec/prevalidation/GetMECPreValidationDataResult';
import { IndirectTaxChangeRecord } from 'src/models/reports/FetchIndirectTaxChangeRecords';

export interface PreValidationState {
    category: string,
    preValidationResult: GetMECPreValidationDataResult,
    emptyTableMessage: string
    selectedPage: number,
    loadedPage: number,
    cache: {[key: number]: IndirectTaxChangeRecord[]},
    indirectTaxChangeRecordList: IndirectTaxChangeRecord[],
}

export const initialState: PreValidationState = {
    category: '',
    preValidationResult: {records: []},
    emptyTableMessage: '',
    selectedPage: 1,
    loadedPage: 1,
    cache: {},
    indirectTaxChangeRecordList: [],
}