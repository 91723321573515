import React from 'react';
import { Box, SpaceBetween, Modal, Button, Alert, Link, ColumnLayout } from '@amzn/awsui-components-react/polaris';
import CONSTANTS from 'src/utils/constants';
import LinkedCLI from 'src/models/calculation-builder/LinkedCLI';
import LinkedCLILink from './LinkedCLILink';


export default function EditTemplateConfirmationModal (props: {
        visible: boolean,
        templateName?: string,
        linkedCLIs: LinkedCLI[],
        onClose: () => void,
        onConfirmed: () => void,
    }) {
    const { visible, templateName, linkedCLIs, onClose: onModalClosed, onConfirmed } = props;
    
    
    return <Modal className="editTemplateConfirmationModal" visible={visible} header="Edit Calculation Template?"
        onDismiss={onModalClosed}
        footer={
            <Box float="right">                
                <Button variant="link" className="editTemplateCancelBtn" onClick={onModalClosed}>No</Button>
                <Button variant="primary" className="editTemplateConfirmBtn" onClick={onConfirmed}>Yes</Button>                
            </Box>
        }>
            <SpaceBetween direction="vertical" size="l"> 
                <Alert className='editTemplateModalAlert' type="warning">
                    Template <strong>{templateName}</strong> has {linkedCLIs.length} linked
                     CLI{linkedCLIs.length !== 1 && 's (listed below)'}. Editing this template will update it for all linked CLIs.<br /><br /><strong>Do you wish to edit this template?</strong></Alert>
                {linkedCLIs.length > 1 && <div>
                    Click CLI to see details
                    <div className='linkedCLIsContainer'>
                        <ColumnLayout columns={2} disableGutters>
                            {linkedCLIs.map(x => <div className='verticalPadding'><LinkedCLILink linkedCLI={x}></LinkedCLILink></div>)}
                        </ColumnLayout>
                    </div>
                </div>}
            </SpaceBetween>
    </Modal>;
}