import React, { useEffect, useState } from "react";
import { Alert, Box, Button, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import AllocationGroup from "src/models/tp-allocation/AllocationGroup";
import TemporaryMessage from "src/models/common/TemporaryMessage";
import StringUtils from 'src/utils/stringUtils';

export default function AllocationGroupEditorModal(props: {
    group: AllocationGroup, 
    visible: boolean, 
    modalMessage: TemporaryMessage | undefined,
    onModalSubmitted: (group:AllocationGroup) => void, 
    onModalDismissed: () => void,
    onClearModalMessage: () => void,
    primaryButtonDisabledLabel?: string
}){
    const { group, visible, onModalSubmitted, onModalDismissed, modalMessage, onClearModalMessage, primaryButtonDisabledLabel } = props;
    const [groupName, setGroupName] = useState('');
    const [groupNameError, setGroupNameError] = useState('');
    const [groupDescription, setGroupDescription] = useState('');
    const [groupDescriptionError, setGroupDescriptionError] = useState('');
    const [formIsValid, setFormIsValid] = useState(false);

    useEffect(() => {
        if (group == null){
            return;            
        }
        setGroupName(group.description);
        setGroupDescription(group.allocationGroupDescription);
        setGroupNameError("");
        setGroupDescriptionError("");
        setFormIsValid(!StringUtils.isNullOrEmpty(group.description) && !StringUtils.isNullOrEmpty(group.allocationGroupDescription));
    }, [group])

    const submitModal = () => {
        onClearModalMessage();
        
        if (validateInputs()){
            return;
        }
        group.description = groupName;
        group.allocationGroupDescription = groupDescription;
        onModalSubmitted(group);
    };

    const validateInputs = () => {
        setGroupNameError("");
        setGroupDescriptionError("");
        let hasError = false;
        if (StringUtils.isNullOrEmpty(groupName)){
            setGroupNameError("Group name is required");
            hasError = true;
        }
        if (StringUtils.isNullOrEmpty(groupDescription)){
            setGroupDescriptionError("Group description is required");
            hasError = true;
        }
        setFormIsValid(!hasError);
        return hasError;
    }

    return <Modal
        onDismiss={onModalDismissed}
        visible={visible}
        footer={
        <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={onModalDismissed}>Cancel</Button>
                <Button variant="primary" onClick={submitModal} disabled={!formIsValid || !StringUtils.isNullOrEmpty(primaryButtonDisabledLabel)}>{StringUtils.isNullOrEmpty(primaryButtonDisabledLabel)? "Save" : primaryButtonDisabledLabel}</Button>
            </SpaceBetween>
        </Box>
        }
        header={group.isNew? "Add new group" : "Edit group"}
    >
        <SpaceBetween direction="vertical" size="l">
            <FormField
            label="Group Name"
            description="This is the group header"
            errorText={groupNameError}
            >
                <Input onChange={({ detail }) => setGroupName(detail.value)} value={groupName} placeholder="Type group name" onKeyUp={validateInputs} />
            </FormField>
            <FormField
            label="Group Description"
            description="This is the group description"
            errorText={groupDescriptionError}
            >
                <Input onChange={({ detail }) => setGroupDescription(detail.value)} value={groupDescription} placeholder="Type group description" onKeyUp={validateInputs} />
            </FormField>
            { modalMessage && <Alert
                    dismissAriaLabel="Close alert"
                    type={modalMessage?.type as any}
                    header={modalMessage?.header}
                >
                    <div>{modalMessage?.message}</div>
                </Alert> 
            }
        </SpaceBetween>
    </Modal>;
}