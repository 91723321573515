import React from 'react';
import 'src/assets/styles/react-table.scss';
import { Link } from '@amzn/awsui-components-react/polaris';
import { TPEReactTable, DefaultColumnFilter } from 'src/components/shared/TPEReactTable'
import TPENavigator from '../TPENavigator';
import CustomTableMetadata from 'src/models/custom-tables-shared/CustomTableMetadata';
import CustomCOADefinition from 'src/models/custom-coa/CustomCOADefinition';

export default function TablesGrid(props: {loading: boolean, customTables: CustomTableMetadata[] | CustomCOADefinition[], tableDetailUrl: string, coa?: boolean, onSortBy?: (e: Array<any>) => void}) {
    const { loading, customTables = [], tableDetailUrl, coa = false, onSortBy } = props;
    const [navigateURL, setNavigateURL] = React.useState('');

    const navigatToTableDetail = (tableId: string) => {
        setNavigateURL(`${tableDetailUrl}/${btoa(tableId)}`)
    }

    const allColumnDefinitions = [
        {
            accessor: "tableName",
            Header: (e: any) => <div className="cell-text">Name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) =>
                <Link className="cell-link" onFollow={() => navigatToTableDetail(e.row.original.tableId)}>{e.value}</Link>
        },
        {
            accessor: "status",
            Header: (e: any) => <div className="cell-text">Status</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="textCell">{e.value}</div>
        },
        {
            accessor: "coaSegment",
            Header: (e: any) => <div className="cell-text">COA Segment</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "lastUpdateUser",
            Header: (e: any) => <div className="cell-text">Updated by</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="textCell">{e.value}</div>
        },
        {
            accessor: "lastUpdatedDate",
            Header: (e: any) => <div className="cell-text">Last updated timestamp</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.row.original.lastUpdatedDateDisplay}</div>
        },
    ];

    const getColDefs = () => {
        if (coa) {
            return allColumnDefinitions;
        } else {
            return allColumnDefinitions.filter(x => x.accessor != 'coaSegment');
        }
    }

    const columnDefinitions = React.useMemo(getColDefs, [coa])

    // Render the UI for your table
    return (
        <div>
            <TPENavigator path={navigateURL} />
            <TPEReactTable
                className={coa ? 'customCoaGrid' : 'customDataTablesGrid'}
                isSearching={loading}
                data={customTables}
                columnDefinitions={columnDefinitions}
                sortable={true}
                onSortBy={onSortBy}
            />
        </div>
    )
}