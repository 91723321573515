import React, { useContext, useEffect, useState } from 'react';
import { Alert, Box, Button, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import StringUtils from 'src/utils/stringUtils';
import { CustomTablesContext } from './TablesView';
import CONSTANTS from 'src/utils/constants';

export default function CreateTableModal(props: {visible: boolean, onCancel: () => any, onSubmit: (tableId: string) => any}) {

    const { visible, onCancel, onSubmit } = props;

    const { services } = useContext(CustomTablesContext);

    const [enteredTableName, setEnteredTableName] = useState<string>(CONSTANTS.CUSTOM_DATA_TABLE_PREFIX);
    const [createTableName, setCreateTableName] = useState('');
    const [errorDisplay, setErrorDisplay] = useState('');

    const [createResult, creating, createError] = services.customDataTablesService.createCustomDataTable(createTableName);

    useEffect(() => {
        // Reset modal
        if (visible) {
            setErrorDisplay('');
            setEnteredTableName(CONSTANTS.CUSTOM_DATA_TABLE_PREFIX);
        }
    }, [visible])

    useEffect(() => {
        if (createResult != null) {
            setCreateTableName('');
            services.messageService.showSuccessAutoDismissBanner(`${createResult.tableName} successfully created.`, 10000);
            onSubmit(createResult.tableId);
        }
    }, [createResult])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(createError)) {
            setErrorDisplay(createError);
        }
    }, [createError])

    return <Modal
                onDismiss={onCancel}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={onCancel}>Cancel</Button>
                            <Button
                                    variant="primary"
                                    disabled={enteredTableName == CONSTANTS.CUSTOM_DATA_TABLE_PREFIX || creating}
                                    onClick={() => setCreateTableName(enteredTableName)}
                            >
                                {creating ? 'Creating...' : 'Create'}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Create Custom Data Table"
            >
                <SpaceBetween direction="vertical" size="s">
                    <FormField label="Table Name" description="Enter a name for the new Custom Data Table">
                        <Input value={enteredTableName}
                            onChange={({ detail }) => {
                                if (detail.value.indexOf(CONSTANTS.CUSTOM_DATA_TABLE_PREFIX) == 0) {
                                    setEnteredTableName(detail.value)
                                }
                            }} />
                    </FormField>
                <Alert type="error" visible={!StringUtils.isNullOrEmpty(errorDisplay)}>{errorDisplay || ''}</Alert>
                </SpaceBetween>
            </Modal>
}