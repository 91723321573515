import React from 'react';
import 'src/assets/styles/react-table.scss';
import TPESelectableReactTable from 'src/components/shared/TPESelectableReactTable';
import CustomTableMetadata from 'src/models/custom-tables-shared/CustomTableMetadata';
import DateUtils from 'src/utils/dateUtils';

export default function CDTSelectorGrid(params: { 
    data: any, 
    loading: boolean,
    onSelect: (e: CustomTableMetadata | undefined) => any,
}) {
    const { data = [], loading, onSelect } = params;

    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "tableName",
            Header: (e: any) => <div className="cell-text">Name</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "lastUpdateUser",
            Header: (e: any) => <div className="cell-text">Updated By</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "lastUpdatedDate",
            Header: (e: any) => <div className="cell-text">Last Updated date</div>,
            Cell: (e: any) => <div className="cell-text">{DateUtils.formatTimestamp(e.value)}</div>,
        }]
    ,[data]);


    // Render the UI for your table
    return (
        <div>
            <TPESelectableReactTable {...{ 
                data, 
                columnDefinitions, 
                className: 'cdtTablesGrid', 
                isSearching: loading, 
                isSearchingMessage: 'Searching', 
                showFilters: false,
                onRowClicked:(isChecked, table: CustomTableMetadata, index) =>  onSelect(isChecked ? table : undefined)} 
        } />
        </div>
    )
}