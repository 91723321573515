import { useEffect, useState } from 'react';
import { TPEUser } from 'src/models/users/TPEUser';
import CONSTANTS from 'src/utils/constants';
import ErrorUtils from 'src/utils/ErrorUtils';
import { UserData, UserProfile } from '../../models/users/UserProfile';
import apiService from '../ApiCallService'

export default class UsersService {
    private userCache?: UserProfile;

    /**
    * Gets user profile using useState hooks
    * @param userName The logged in user name
    */
    getUserProfile(userName: string | null): [UserProfile, boolean, string] {
        const [result, setResult] = useState({} as UserProfile);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');
        const $this = this;

        useEffect(() => {
            async function fetchData($this: UsersService, alias:string) {
                try {
                    setLoading(true);
                    const response = await apiService.getUserProfile(alias);
                    const json = await response.json() as UserProfile;
                    $this.userCache = json;
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }

            if (userName != null && userName.trim().length > 0) {
                if ($this.userCache == null || this.userCache?.user !== userName) {
                    fetchData($this, userName.trim());
                }
                else {
                    setResult($this.userCache);
                }
            }
        }, [userName]);
        return [result, loading, error];
    }

    static getUserRoles(user: UserProfile): string[] {
        if (user == null || user.userData == null || user.userData === "") {
            return [];
        }
        const data = JSON.parse(user.userData) as UserData;
        return data.roles || [];
    }

    hasRole(role: string, userRoles: string[]) {
        if (userRoles == null) {
            return false;
        }
        return userRoles.find(x => x === role) != null;
    }

    getAtpUsers(pullUsers?: string): [TPEUser[], boolean, string] {
        const [result, setResult] = useState([] as TPEUser[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getTPEUsers();
                    const json = await response.json();
                    setResult(json.users);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (pullUsers != null) {
                fetchData();
            }
        }, [pullUsers]);
        
        return [result, loading, error];
    }

    findAtpUsersByName(users: TPEUser[], name:string): TPEUser[] {
        const trimmedName = name.trim().toLowerCase();
        return users.filter(x => x.fullName.trim().toLowerCase() === trimmedName)
    }

    saveUserProfile(userProfile?: UserProfile): [UserProfile | null, boolean, string] {
        const [result, setResult] = useState(null as UserProfile | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData($this: UsersService, user: UserProfile) {
                try {
                    setLoading(true);
                    $this.updateUserDataWithProfileRoles(user);
                    const response = await apiService.saveUserProfile(user);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (userProfile != null) {
                fetchData(this, userProfile);
            }
        }, [userProfile]);
        
        return [result, loading, error];
    }

    /**
     * Updates userData string with user profile roles
     * @param userProfile the user profile
     */
    updateUserDataWithProfileRoles(userProfile: UserProfile){
        if (userProfile.userData == null) {
            userProfile.userData = "{}";
        }
        const data = JSON.parse(userProfile.userData) as UserData;
        data.roles = userProfile.roles || [];
        userProfile.userData = JSON.stringify(data);
    }
}