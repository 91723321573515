import CalculationStepsRunItem from 'src/models/calculation-builder/CalculationStepsRunItem';
import WorksheetTotal from 'src/models/tp-allocation/WorksheetTotal';

export interface WorksheetTotalsState {
    totals: Array<WorksheetTotal>,
    totalBeingEdited?: WorksheetTotal,
    error: string,
    isLoading: boolean,
    isSaving: boolean,
    showValues: boolean,
    canAddNewTotal: boolean,
    expandedState?: any,
    updateTotalPayload?: any,
    lastRunValues?: Array<CalculationStepsRunItem>,
    totalsAreSavedAndValid: boolean,
    totalSavedId?: string,
    removeTotalModalPayload?: any,
}


export const initialState: WorksheetTotalsState = {
    totals: [],
    error: '',
    isLoading: true,
    isSaving: false,
    showValues: false,
    totalsAreSavedAndValid: false,
    canAddNewTotal: false,    
};