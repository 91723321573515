import ServiceCollection from "src/services/ServiceCollection";
import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Button,
    ButtonDropdown,
    ColumnLayout,
    Container,
    Pagination,
    SpaceBetween} from "@amzn/awsui-components-react/polaris";
import TablesGrid from "../custom-tables-shared/TablesGrid";
import 'src/components/CustomAppLayout.scss';
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import { GLOBAL_ACTIONS } from 'src/services/global/GlobalReducer';
import {ACTIONS, customDataTablesReducer} from "src/services/custom-data-tables/CustomTablesReducer";
import {CustomTablesState, initialState} from "src/services/custom-data-tables/CustomTablesState";
import TPEAction from "src/models/common/TPEAction";
import FileUploadModal from "./FileUploadModal";
import CreateTableModal from "./CreateTableModal";
import TPENavigator from "../TPENavigator";
import StringUtils from "src/utils/stringUtils";
import DuplicateRecordsView from "./DuplicateRecordsView";
import { GlobalAppContext } from "../App";
import DynamicBreadcrumb from "src/models/navigation/DynamicBreadcrumb";
import { useLocation } from "react-router-dom";
import CONSTANTS from "src/utils/constants";
import CustomDataTableRowsInfo from "src/models/custom-data-tables/CustomDataTableRowsInfo";
import SearchCDTBox, { CDT_SEARCH_MODES } from "./SearchCDTBox";
import '../custom-tables-shared/CustomTables.scss';
import TPESearchRequest from "src/models/common/TPESearchRequest";
import { addCalculationNumberFilter, addTableNameFilter, getCustomDataTablesSearchRequest, addSorting } from "../custom-tables-shared/CustomDataTableSearchHelper";
import { SortingRule } from "react-table";
import { SearchSortOrder } from "src/models/common/SearchSortingSpec";
import { AppModules, AppRoles } from "src/models/permissions/RolePermissions";
import { getPermissions } from "../AppPermissions";

export type ContextType = {
    state: CustomTablesState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const CustomTablesProvider = (props: any) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <CustomTablesContext.Provider value={providerValue}>
            {children}
        </CustomTablesContext.Provider>
    );
}
export const CustomTablesContext = React.createContext(null as unknown as ContextType);

export default function TablesView(props: { services: ServiceCollection }) {
    const { services } = props;
    const { globalDispatch, userProfile } = useContext(GlobalAppContext);
    const [state, dispatch] = useReducerWithLogger(customDataTablesReducer, initialState);
    const { duplicateRowsInfo, saveCustomDataTablePayload = null, searchPayload, searchResult } = state;
    
    const { pathname } = useLocation();
    const { canEdit, canFilter, canAddNew } = getPermissions(AppModules.CUSTOM_DATA_TABLES);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [navigateURL, setNavigateURL] = useState('');
    const [loadTime, setLoadTime] = useState(null as unknown as string);

    const [customDataTablesResponse, customDataTablesLoading, customDataTablesError] = services.customDataTablesService.searchCustomDataTables(searchPayload);
    const [customDataTableTemplateUrl, customDataTemplateUrlLoading, customDataTableTemplateUrlError] = services.customDataTablesService.downloadTemplate(loadTime);
    const [saveCustomDataTableResponse, saveCustomDataTableLoading, saveCustomDataTableError] = services.customDataTablesService.saveCustomDataTable(saveCustomDataTablePayload, false);
    
    useEffect(() => {
        if (userProfile == null){
            return;
        }
        const hasNafnRole = services.usersService.hasRole(AppRoles.NAFN, userProfile.roles);
        if (hasNafnRole){
            searchCDTs(CDT_SEARCH_MODES.TABLE_NAME,CONSTANTS.NAFN_ADMIN_ALLOWED_CDT_NAME);
        }
        else {
            dispatch(ACTIONS.RESET_SEARCH);
        }
    }, [userProfile]);

    useEffect(() => {
        // Reset the breadcrumb and page header when user navigates back to list from duplicate records component
        if (duplicateRowsInfo == null) {
            resetDynamicBreadcrumb();
        }
    }, [duplicateRowsInfo])

    useEffect(() => {
        if (customDataTablesResponse != null) {
            dispatch(ACTIONS.SET_SEARCH_RESULT.withPayload(customDataTablesResponse));
        }
    }, [customDataTablesResponse])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(customDataTablesError)) {
            services.messageService.showErrorBanner(customDataTablesError);
        }
    }, [customDataTablesError])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(customDataTableTemplateUrl)) {
            window.open(customDataTableTemplateUrl as string);
        }
    }, [customDataTableTemplateUrl])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(customDataTableTemplateUrlError)) {
            services.messageService.showErrorBanner(customDataTableTemplateUrlError);
        }
    }, [customDataTableTemplateUrlError])

    useEffect(() => {
        if (saveCustomDataTableResponse != null) {
            services.messageService.showSuccessAutoDismissBanner('You have successfully resolved all conflicts.', 10000);
            resetDynamicBreadcrumb();
            navigateToCustomDataTableDetail(saveCustomDataTableResponse.tableId);
        }
    }, [saveCustomDataTableResponse])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(saveCustomDataTableError)) {
            services.messageService.showErrorBanner(saveCustomDataTableError);
        }
    }, [saveCustomDataTableError])

    const renderModalBySelectedOption = (event: any) => {
        switch (event.detail.id) {
            case 'create':
                setShowCreateModal(true);
                break;
            case 'upload':
                setShowUploadModal(true);
                break;
        }
    }

    const resetDynamicBreadcrumb = () => {
        globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, CONSTANTS.PAGE_NAV.CUSTOM_TABLES.TITLE,
            CONSTANTS.PAGE_NAV.CUSTOM_TABLES.TITLE, 
            CONSTANTS.PAGE_NAV.CUSTOM_TABLES.DESCRIPTION)));
    }

    const navigateToCustomDataTableDetail = (tableId: string) => {
        setNavigateURL(`${CONSTANTS.PAGE_NAV.CUSTOM_DATA_TABLE_DETAILS.URL}/${btoa(tableId)}`);
    }

    const showUploadedCustomDataTable = (tableId: string) => {
        setShowUploadModal(false);
        navigateToCustomDataTableDetail(tableId);
    }

    const showCreatedCustomDataTable = (tableId: string) => {
        setShowCreateModal(false);
        navigateToCustomDataTableDetail(tableId);
    }

    const handleDuplicateRowSelection = (isChecked: boolean, row: any, index: number) => {
        dispatch(ACTIONS.TOGGLE_SELECT_DUPLICATE_ROW.withPayload({index, isChecked}));
    }

    const handleDuplicateRowBulkSelection = (selectNew: boolean) => {
        if (selectNew) {
            dispatch(ACTIONS.SELECT_ALL_NEW_RECORDS);
        } else {
            dispatch(ACTIONS.SELECT_ALL_EXISTING_RECORDS);
        }
    }

    const saveSelectedDuplicateRows = () => {
        if (duplicateRowsInfo != null) {
            const selectedRows = duplicateRowsInfo.records.filter(x => x.isSelected && x.isNewRecord);
            if (selectedRows != null && selectedRows.length > 0) {
                // Set isNewRecord to false to enable overwrite, otherwise the API will consider it a duplicate
                selectedRows.forEach(x => x.isNewRecord = false);
                const payload: CustomDataTableRowsInfo = {
                    tableId: duplicateRowsInfo.tableId,
                    tableName: duplicateRowsInfo.tableName,
                    records: selectedRows,
                    legend: duplicateRowsInfo.legend,
                    columnDefinitions: []
                }

                dispatch(ACTIONS.SET_SAVE_CUSTOM_DATA_TABLE_PAYLOAD.withPayload(payload));
            } else {
                services.messageService.showSuccessAutoDismissBanner('No new rows to save.', 10000);
                resetDynamicBreadcrumb();
                navigateToCustomDataTableDetail(duplicateRowsInfo.tableId);
            }
        }
    }

    const searchCDTs = (searchMode:any, searchText:string) => {
        const currentSorting = {sortOrder: searchPayload?.sortOrder, sortField: searchPayload?.sortField};
        if (StringUtils.isNullOrEmpty(searchText)){
            dispatch(ACTIONS.RESET_SEARCH.withPayload(currentSorting));
            return;
        }
        let newSearchPayload = {...currentSorting} as TPESearchRequest;
        if (searchMode === CDT_SEARCH_MODES.TABLE_NAME){
            if (searchText.length < 3){
                return;
            }            
            newSearchPayload = {...newSearchPayload,...getCustomDataTablesSearchRequest(false)};
            addTableNameFilter(newSearchPayload, searchText.toLowerCase());
        }
        else if (searchMode === CDT_SEARCH_MODES.TP_NUMBER){
            newSearchPayload = {...newSearchPayload,...getCustomDataTablesSearchRequest(true)};
            addCalculationNumberFilter(newSearchPayload, searchText);
        }
        dispatch(ACTIONS.SET_SEARCH_PAYLOAD.withPayload(newSearchPayload));
    }

    const onSortByChanged = function (sortBySpecs: Array<SortingRule<any>>) {
        if (sortBySpecs == null || sortBySpecs.length === 0) {
            return;
        }
        const sortBy = sortBySpecs[0];
        addSorting(searchPayload, sortBy.id, sortBy.desc === true);
        dispatch(ACTIONS.SET_SEARCH_PAYLOAD.withPayload({...searchPayload}));
    }

    return (
        <CustomTablesProvider services={services} state={state} dispatch={dispatch}>
            <TPENavigator path={navigateURL} />
            { duplicateRowsInfo == null ?
                <Container data-class="containerWithHeaderAndGrid">
                    <div>
                        <div className="customTableTableHeader">
                            <ColumnLayout columns={2} data-class="fullColumnLayout">
                                <Box variant="h2"><span>Custom Data Tables </span><span className="customTablesRecordCounter">({searchResult?.totalSize})</span></Box>
                                <Box float="right">
                                    { canAddNew &&
                                        <SpaceBetween direction="horizontal" size="m">
                                            <Button variant="normal" onClick={() => setLoadTime(new Date().toString())}>Download template</Button>
                                            <ButtonDropdown
                                                items={[
                                                    {text: "Create", id: "create"},
                                                    {text: "Upload data", id: "upload"},
                                                ]}
                                                variant="primary"
                                                onItemClick={
                                                    event => {
                                                        renderModalBySelectedOption(event);
                                                    }
                                                }
                                            >
                                                Add
                                            </ButtonDropdown>
                                        </SpaceBetween>
                                    }
                                </Box>
                                { canFilter && <SearchCDTBox onSearchSubmitted={searchCDTs} matchesCount={searchResult?.totalSize || 0} /> }
                                {searchResult != null && searchResult.pagesCount > 1 &&
                                    <Box float="right">
                                        <Pagination
                                            currentPageIndex={searchResult.page}
                                            pagesCount={searchResult.pagesCount}
                                            disabled={searchResult.pagesCount == 0 || customDataTablesLoading}
                                            onChange={({ detail }) => dispatch(ACTIONS.SET_CURRENT_PAGE.withPayload(detail.currentPageIndex))}
                                        />
                                    </Box>
                                }
                            </ColumnLayout>
                        </div>
                        <div className="tableContainer">
                            <TablesGrid
                                key="customDataTablesTableGrid"
                                loading={customDataTablesLoading}
                                customTables={searchResult?.tables || []}
                                tableDetailUrl={CONSTANTS.PAGE_NAV.CUSTOM_DATA_TABLE_DETAILS.URL}
                                onSortBy={onSortByChanged}
                            />
                        </div>
                        <FileUploadModal visible={showUploadModal} onCancel={() => setShowUploadModal(false)} onSubmit={showUploadedCustomDataTable} />
                        <CreateTableModal visible={showCreateModal} onCancel={() => setShowCreateModal(false)} onSubmit={showCreatedCustomDataTable} />
                    </div>
                </Container>
            :
                <DuplicateRecordsView 
                    duplicateRowsInfo={duplicateRowsInfo} 
                    services={services} 
                    handleRowSelect={handleDuplicateRowSelection}
                    handleBulkSelect={handleDuplicateRowBulkSelection}
                    handleSave={saveSelectedDuplicateRows}
                />
            }
        </CustomTablesProvider>
    );
}
