import React, {useEffect, useState} from 'react';
import {
    Alert,
    Autosuggest,
    Box,
    Button,
    FormField,
    Modal,
    Multiselect,
    SpaceBetween,
    TokenGroup
} from '@amzn/awsui-components-react/polaris';
import StringUtils from 'src/utils/stringUtils';
import {CalculationBuilderContext} from '../CalculationBuilderView';
import SaveStandardAllocationRequest
    from 'src/models/calculation-builder/standard-allocation/SaveStandardAllocationRequest';
import {TPELoadingSpinner} from 'src/components/shared/TPELoadingSpinner';
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import {
    CommonStandardAllocationActions,
    CommonStandardAllocationRecord
} from "../../../models/common/CommonStandardAllocationInterfaces";
import ArrayUtils from "src/utils/arrayUtils";


export default function SetupStandardAllocationModal (props: {
    services: ServiceCollection,
    showStandardAllocationModal: boolean,
    parentId: string | undefined,
    parentRecords: Array<CommonStandardAllocationRecord>,
    dispatch: React.Dispatch<TPEAction>,
    onStandardAllocationNeedSaving: (payload: SaveStandardAllocationRequest) => void,
    isSaving: boolean,
    ACTIONS: CommonStandardAllocationActions,
}) {

    const { services, showStandardAllocationModal, parentId, parentRecords, dispatch,  onStandardAllocationNeedSaving, isSaving, ACTIONS} = props;

    const { calcBuilderState, calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const { calculation, calculationTemplateMetaData, isEditingTemplate } = calcBuilderState;

    const [loadTime, setLoadTime] = useState(null as unknown as string);
    const [identifierName, setIdentifierName] = useState('');
    const [error, setError] = useState('');
    const [savePayload, setSavePayload] = useState(undefined as SaveStandardAllocationRequest | undefined);
    const [selectedOptions, setSelectedOptions] = React.useState([] as any[]);
    const [calculations, setCalculations] = useState([] as any[]);
    const [existingStandardAllocationIds, setExistingStandardAllocationIds] = useState([] as string[]);
    const [otherStandardAllocationCLIs, setOtherStandardAllocationCLIs] = useState(new Set<string>());
    const [selectedParentRecord, setSelectedParentRecord] = useState(undefined as CommonStandardAllocationRecord | undefined);
    const [cliResult, isGettingClis, clisError] = services.cliService.getCLIs(loadTime, undefined, calculation?.workbookId);

    useEffect(() => {
        if (showStandardAllocationModal){
            resetModal();
            const parentRecord = parentRecords.find(x => x.parentId === parentId);
            setSelectedParentRecord(parentRecord);
            setExistingStandardAllocationIds([...new Set(parentRecords.filter(x => x.standardAllocation != null).map(x => x.standardAllocation?.standardAllocationName || ''))]);
            const allOtherStandardAllocationCLIs = parentRecords.filter(x => x.parentId != parentRecord?.parentId).map(x => x.standardAllocation?.calculationNumbers || []).reduce(function(pre, cur) {
                return pre.concat(cur);
            }, []);
            setOtherStandardAllocationCLIs(new Set(allOtherStandardAllocationCLIs));
        }
    }, [showStandardAllocationModal]);

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(calculation?.workbookId)) {
            return;
        }
        setLoadTime((new Date()).toString());
    },[calculation?.workbookId])

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(cliResult)){
            return;
        }
        setCalculations(cliResult);
        if (selectedParentRecord && selectedParentRecord.standardAllocation) {
            setSelectedOptions(selectedParentRecord.standardAllocation.calculationNumbers.map(x => ({value: x})));
            setIdentifierName(selectedParentRecord.standardAllocation.standardAllocationName);
        }
    }, [selectedParentRecord, cliResult])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(clisError)){
            return;
        }
        setError(clisError);
    }, [clisError])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(clisError)){
            return;
        }
        setError(clisError);
    }, [clisError])


    const resetModal = () => {
        setIdentifierName('');
        setSelectedOptions([]);
        setError('');
        setSelectedParentRecord(undefined);
    }

    const saveStandardAllocation = () => {
        if(calculationTemplateMetaData == null) {
            return;
        }
        const selectedClis = selectedOptions.map(x => x.value);
        onStandardAllocationNeedSaving({
            templateId: calculationTemplateMetaData.templateId || "",
            calculationNumber: calculationTemplateMetaData.calculationNumber || "",
            dataSourceId: parentId || "",
            stepId: parentId || "",
            identifierName: identifierName,
            allocatedCalculationNumbers: selectedClis
        });
    }

    const closeModal = () => {
        resetModal();
        dispatch(ACTIONS.SHOW_STANDARD_ALLOCATION_MODAL.withPayload(false));
    }



    return <Modal className="setupStandardAllocationModal" visible={showStandardAllocationModal} size="medium"
                  header="Standard Allocation"
                  onDismiss={closeModal}
                  footer={
            <Box float="right">
                <Button variant={isEditingTemplate? "link" : "primary"} className="setupStandardAllocationCancelButton" onClick={closeModal}>{isEditingTemplate? "Cancel" : "Close"}</Button>
                {isEditingTemplate && <Button
                    variant="primary"
                    disabled={StringUtils.isNullOrEmpty(identifierName) || selectedOptions.length === 0 || isSaving}
                    className="setupStandardAllocationConfirmButton"
                    onClick={saveStandardAllocation}>
                        {isSaving? "Applying..." : "Apply"}
                </Button>
            }
            </Box>
        }>
            <TPELoadingSpinner loading={isGettingClis}>
            <div className="setupStandardAllocationContainer">
                <SpaceBetween direction="vertical" size = "m">
                    <FormField label="Standard Allocation Identifier">
                    <Autosuggest disabled={!isEditingTemplate}
                        onChange={({ detail }) => setIdentifierName(detail.value)}
                        value={identifierName}
                        options={existingStandardAllocationIds.map(x => ({value:x}))}
                        enteredTextLabel={value => `Use: "${value}"`}
                        ariaLabel="Autosuggest example with suggestions"
                        placeholder="Enter Standard Allocation Identifier"
                        empty="No matches found"
                        />
                    </FormField>
                    <FormField label="Select Calculation(s)">
                    <Multiselect
                        disabled={!isEditingTemplate}
                        loadingText='Searching CLIs...'
                        statusType={isGettingClis?'loading':'finished'}
                        selectedOptions={selectedOptions}
                        onChange={({ detail }) => {
                            const selected = [...detail.selectedOptions];
                            setSelectedOptions(selected);
                        }}
                        deselectAriaLabel={e => `Remove ${e.label}`}
                        options={calculations.map(x => ({...x,
                            label: x,
                            value: x
                        }))}
                        filteringType="auto"
                        hideTokens
                        placeholder={selectedOptions.length === 0?"Select Calculation(s)": `${selectedOptions.length} selected`}
                        selectedAriaLabel="Selected"
                        />
                    <TokenGroup
                        onDismiss={({ detail: { itemIndex } }) => {
                            setSelectedOptions([
                                ...selectedOptions.slice(0, itemIndex),
                                ...selectedOptions.slice(itemIndex + 1)
                            ]);
                        }}
                        items={(selectedOptions).map(x => ({ label: x.value, disabled:!isEditingTemplate }))}
                    />
                    </FormField>
                    <Alert visible={!StringUtils.isNullOrEmpty(error)} type="error">{error}</Alert>
                </SpaceBetween>
            </div>
            </TPELoadingSpinner>
    </Modal>
}