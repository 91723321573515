import React, { useEffect, useState } from 'react';
import { Box, Modal, Button, FormField, Select, Alert, SpaceBetween, Multiselect } from '@amzn/awsui-components-react/polaris';
import { TPELoadingSpinner } from 'src/components/shared/TPELoadingSpinner';
import StringUtils from 'src/utils/stringUtils';
import ServiceCollection from 'src/services/ServiceCollection';

export default function CustomCOASelectorModal (props: {
        visible: boolean,
        coaSegment: string,
        coaType: string,
        services: ServiceCollection,
        onSelectedCOAApplied: (payload:any) => void,
        onClose: () => any,
    }) {
    const { visible, coaSegment, coaType, services, onSelectedCOAApplied, onClose } = props;

    const [coaDefinitionOptions, setCoaDefinitionOptions] = useState([] as any);
    const [selectedCOADefinitionOption, setSelectedCOADefinitionOption] = useState(null as unknown as any);
    const [classificationOptions, setClassificationOptions] = useState([] as any);
    const [selectedClassificationOptions, setSelectedClassificationOptions] = useState([] as any);
    const [error, setError] = useState('');

    const [customCOADefinitionsResult, customCOADefinitionsLoading, customCOADefinitionsError] = services.customCOAService.getCustomCOADefinitionsForSegment(coaSegment);
    const [classificationsResult, classificationsLoading, classificationsError] = services.customCOAService.getClassifications(selectedCOADefinitionOption ? selectedCOADefinitionOption.value : '');

    useEffect(() => {
        if (visible) {
            resetModal();
        }
    }, [visible])

    useEffect(() => {
        if (customCOADefinitionsResult == null) {
            return;
        }
        if (customCOADefinitionsResult.length == 0) {
            setError(`No active custom COA definitions found for ${coaSegment}.`)
        } else {
            const options: {label: string, value: string}[] = [];
            customCOADefinitionsResult.forEach(x => options.push({label: x.tableName, value: x.tableId}));
            setCoaDefinitionOptions(options);
            setError('');
        }
    }, [customCOADefinitionsResult])

    useEffect(() => {
        if (classificationsResult == null) {
            return;
        }
        if (classificationsResult.length == 0) {
            setError(`No active classifications found in custom COA definition ${selectedCOADefinitionOption != null ? selectedCOADefinitionOption.label : ''}.`);
            return;
        } else {
            setError('');
        }
        const options: {label: string, value: string}[] = [];
        classificationsResult.forEach(x => options.push({label: x, value: x}));
        setClassificationOptions(options);
    }, [classificationsResult])

    const resetModal = () => {
        setCoaDefinitionOptions([]);
        setSelectedCOADefinitionOption(null);
        setClassificationOptions([]);
        setSelectedClassificationOptions([]);
        setError('');
    }

    const selectCOADefinition = (selectedOption: any) => {
        setSelectedClassificationOptions([]);
        setClassificationOptions([]);
        setSelectedCOADefinitionOption(selectedOption);
    }

    const applyCustomCOASelection = () => {
        const selectedValues: string[] = [];
        selectedClassificationOptions.forEach((x: any) => selectedValues.push(`[${selectedCOADefinitionOption.label}:${x.label}]`));
        const valueMap = new Map<string, string>();
        valueMap.set(coaType, selectedValues.join(', '));

        const referenceMap = new Map<string, string>();
        selectedValues.forEach((x: string) => referenceMap.set(x, selectedCOADefinitionOption.value));
        onSelectedCOAApplied({valueMap, referenceMap});
        onClose();
    }

    return <Modal className="customCoaModal" visible={visible} size="medium"
        header="Select custom COA"
        onDismiss={onClose}
        footer={
            <Box float="right">
                <Button variant="link" className="customCOACancelButton" onClick={onClose}>Cancel</Button>
                <Button 
                variant="primary"
                disabled={selectedCOADefinitionOption == null || selectedClassificationOptions.length == 0}
                className="customCOAApplyButton"
                onClick={applyCustomCOASelection}>
                    Apply
            </Button>
            </Box>
        }>
            <TPELoadingSpinner loading={customCOADefinitionsLoading}>
                <div className="coaSelectionContainer">
                    <SpaceBetween direction="vertical" size = "m">
                        {coaDefinitionOptions.length > 0 &&
                            <React.Fragment>
                                <FormField label="Custom COA definition">
                                    <Select
                                        selectedOption={selectedCOADefinitionOption}
                                        onChange={({ detail }) => selectCOADefinition(detail.selectedOption)}
                                        options={coaDefinitionOptions}
                                    />
                                </FormField>
                                <FormField label="Classification">
                                    <Multiselect
                                        selectedOptions={selectedClassificationOptions}
                                        onChange={({ detail }) => setSelectedClassificationOptions(detail.selectedOptions)}
                                        options={classificationOptions}
                                        filteringType="auto"
                                    />
                                </FormField>
                            </React.Fragment>
                        }
                        <Alert visible={!StringUtils.isNullOrEmpty(error)} type="error">{error}</Alert>
                    </SpaceBetween>
                </div>
            </TPELoadingSpinner>
    </Modal>
}