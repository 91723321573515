import React from 'react';
import 'src/assets/styles/react-table.scss';
import { useTable, useFilters, useSortBy, useExpanded, useGlobalFilter } from 'react-table';
import { Box, Icon, Popover, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import CONSTANTS from "src/utils/constants";

type ComponentProps = {
    data: any,
    columnDefinitions: any[],
    className: string,
    sortable?: boolean,
    showFilters?: boolean,
    showHeader?: boolean,
    highlightErrorCells?: boolean
    isSearching?: boolean,
    isSearchingMessage?: string,
    emptyMessage?: string,
    globalFilterValue?: string,
    onSortBy?: (e: Array<any>) => any,
    onFilter?: (e: Array<any>) => any,
    onRowBlur?: (...args : any[]) => any,
    onRowClicked?: (...args : any[]) => any,
    onExpandedChanged?: (e: any) => void,
    editableOptions?: { defaultColumn: any, onRowUpdated: any, skipPageReset: boolean, editorsData?: any, onCellUpdated?: any },
    initialState?: any,
    alternateBackgroundColor?: boolean,
    alternateCount?: number,
    highlightSelected?: boolean
};

// Define a default UI for filtering
export function DefaultColumnFilter(event: {
    column: { filterValue: any, preFilteredRows: any, setFilter: any },
}) {
    const { column } = event;
    const { filterValue, setFilter } = column;
    const value = filterValue || '';
    return (

        <input
            value={value}
            className={value.length > 0 && value.length < 2 ? "defaultFilter invalidFilter" : "defaultFilter"}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search records...`}
        />
    )
}

const doNothing = () => { }

export function TPEReactTable(props: ComponentProps) {
    const {
        className, data, columnDefinitions, sortable = true, showFilters = false, showHeader = true, highlightErrorCells = false, isSearching = false, 
        initialState, onSortBy = doNothing, onFilter = doNothing, emptyMessage = "No results", editableOptions, isSearchingMessage = "Searching...", globalFilterValue,
        onRowBlur, onRowClicked, onExpandedChanged = doNothing, highlightSelected = true, alternateBackgroundColor = false, alternateCount = 1
    } = props;
    const columns = React.useMemo(() => columnDefinitions, [columnDefinitions]);

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter
    } = useTable({
        columns,
        data,
        initialState: {
            globalFilter: globalFilterValue,
            autoResetGlobalFilter: true
        },
        manualSortBy: true,
        manualFilters: true,
        disableMultiSort: true,
        defaultColumn: editableOptions?.defaultColumn ? editableOptions.defaultColumn : {},
        onRowUpdated: editableOptions?.onRowUpdated ? editableOptions.onRowUpdated : doNothing,
        onCellUpdated: editableOptions?.onCellUpdated ? editableOptions.onCellUpdated : doNothing,
        autoResetPage: editableOptions?.skipPageReset != null ? !editableOptions.skipPageReset : true,
        editorsData: editableOptions ? editableOptions.editorsData : null,
    } as any, useFilters, useGlobalFilter, useSortBy, useExpanded );

    const { sortBy, filters, expanded } = state as any;
    React.useEffect(() => {
        onSortBy(sortBy);
    }, [sortBy]);

    React.useEffect(() => {
        onFilter(filters);
    }, [filters]);

    React.useEffect(() => {
        onExpandedChanged(expanded);
    }, [expanded]);

    React.useEffect(() => {
        if (globalFilterValue !== undefined) {
          setGlobalFilter(globalFilterValue);
        }
      }, [globalFilterValue, setGlobalFilter]);

    const handleBlur = (e:any, dataItem:any, index:number) => {
        const currentTarget = e.currentTarget;

        // Check the newly focused element in the next tick of the event loop
        setTimeout(() => {
          // Check if the new activeElement is a child of the original container
          if (!currentTarget.contains(document.activeElement)) {
            // You can invoke a callback or add custom logic here
            if (onRowBlur){
                onRowBlur(e,dataItem,index);
            }
          }
        }, 0);
      };

    const handleOnRowClick = (e:any, dataItem:any, index:number) => {
        if (onRowClicked){
            onRowClicked(e,dataItem,index);
        }
    }

    const getCellClass = (row: any, cell: any) => {
        return highlightErrorCells && row.original.errors != null && (row.original.errors as Map<string, string[]>).has(cell.column.id) ? 
            "errorCell" 
            : row.original.editableFields != null && (row.original.editableFields as string[]).includes(cell.column.id) ?
                "editableTd"
                : "" ;
    }

    const getRowClass = (index: number) => {
        if (alternateBackgroundColor) {
          const oneBasedIndex = index + 1;
          const toAlternate = alternateCount * 2;
          
          for (var i = 0; i < alternateCount; i++) {
            if ((oneBasedIndex + i) % toAlternate == 0) {
              return 'greyRow';
            }
          }
  
          return '';
        }
      }

    const hasOnRowBlurHandler = onRowBlur? true : false;
    const hasOnRowClickHandler = onRowClicked? true : false;

    // Render the UI for your table
    return (
        <div>
            <table className={className+CONSTANTS.ATP_CUSTOM_REACT_TABLE_CSS} {...getTableProps()}>
                {showHeader &&
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th {...column.getHeaderProps()} className={column.headerClass}>
                                    <div
                                        className="sorting-arrows"
                                        {...column.getSortByToggleProps()} >
                                        {column.render('Header')}
                                        {sortable && !column.suppressSorting &&
                                            <div>
                                                {column.isSorted ? column.isSortedDesc ?
                                                    <Icon name="caret-down-filled" /> :
                                                    <Icon name="caret-up-filled" /> :
                                                    <Icon name="caret-down" />}
                                            </div>
                                        }
                                    </div>
                                    {/* If we want to show filters and if column can filter and if we want tho show the filter with or without popover */}
                                    {showFilters ? (<div className="cell-filter">{column.canFilter && !column.suppressFiltering? 
                                        (column.hidePopover? column.render('Filter') : 
                                        <Popover className='react-table-filter-popover'
                                            position="bottom"
                                            size="medium"
                                            triggerType="custom"
                                            dismissButton={false}
                                            content={
                                                <StatusIndicator type="warning">
                                                    Search is case sensitive
                                                </StatusIndicator>
                                            }
                                        >{column.render('Filter')}</Popover>
                                        ) : null}</div>) : null}
                                </th>

                            ))}
                        </tr>

                    ))}
                </thead>
                }
                {isSearching || rows == null || rows.length == 0 ?
                    null :
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);

                            return (
                                <tr className={(row.original as any).isSelected == true && highlightSelected ? 'selected-row' : getRowClass(i)} 
                                    {...row.getRowProps()} 
                                    onBlur={(e) => hasOnRowBlurHandler && handleBlur(e, row.original, i)} 
                                    onClick={(e) => hasOnRowClickHandler && handleOnRowClick(e, row.original, i)}>
                                    {
                                        ((row.original as any).colspan) ?
                                            <td className="colspanRow" colSpan={row.cells.length} {...row.cells[0].getCellProps()}>{(row.original as any).content}</td>
                                        :
                                        row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} className={getCellClass(row, cell)}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                }

            </table>
            {isSearching ?
                <Box textAlign="center" padding="l"><StatusIndicator type="loading">{isSearchingMessage}</StatusIndicator></Box> : null}
            {!isSearching && (rows == null || rows.length == 0) ?
                <Box textAlign="center" padding="l" className="tpeTableEmptyMessage"><StatusIndicator type="info">{emptyMessage}</StatusIndicator></Box> : null}
        </div>
    )
}