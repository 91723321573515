export default class CDTSelectableValue {
    value: any
    valueKey: string
    formattedValue: string
    isSelected: boolean

    constructor(value:any, formattedValue: string, valueKey: string){
        this.value = value;
        this.isSelected = false;
        this.formattedValue = formattedValue;
        this.valueKey = valueKey;        
    }
}