import React, { useContext, useEffect, useState } from 'react';
import { Alert, Box, Button, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import StringUtils from 'src/utils/stringUtils';
import { CustomCOARowsContext } from './RowsView';
import CONSTANTS from 'src/utils/constants';

export default function RenameTableModal(props: {visible: boolean, onCancel: () => any, onSubmit: (newTableName: string) => any, tableId: string}) {

    const { visible, onCancel, onSubmit, tableId } = props;

    const { services } = useContext(CustomCOARowsContext);

    const [enteredTableName, setEnteredTableName] = useState<string>(CONSTANTS.CUSTOM_COA_DEFINITION_PREFIX);
    const [newTableName, setNewTableName] = useState('');
    const [errorDisplay, setErrorDisplay] = useState('');

    const [renameResult, renaming, renameError] = services.customCOAService.renameCustomCOADefinition(tableId, newTableName);

    useEffect(() => {
        // Reset modal
        if (visible) {
            setErrorDisplay('');
            setEnteredTableName(CONSTANTS.CUSTOM_COA_DEFINITION_PREFIX);
        }
    }, [visible])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(renameResult)) {
            setNewTableName('');
            onSubmit(renameResult);
        }
    }, [renameResult])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(renameError)) {
            setErrorDisplay(renameError);
        }
    }, [renameError])

    return <Modal
                onDismiss={onCancel}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={onCancel}>Cancel</Button>
                            <Button
                                    variant="primary"
                                    disabled={enteredTableName == CONSTANTS.CUSTOM_COA_DEFINITION_PREFIX || renaming}
                                    onClick={() => setNewTableName(enteredTableName)}
                            >
                                {renaming ? 'Renaming...' : 'Rename'}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Rename Custom COA Definition"
            >
                <SpaceBetween direction="vertical" size="s">
                    <FormField label="Table Name" description="Enter a new name for the Custom COA Definition">
                        <Input value={enteredTableName}
                            onChange={({ detail }) => {
                                if (detail.value.indexOf(CONSTANTS.CUSTOM_COA_DEFINITION_PREFIX) == 0) {
                                    setEnteredTableName(detail.value)
                                }
                            }} />
                    </FormField>
                <Alert type="error" visible={!StringUtils.isNullOrEmpty(errorDisplay)}>{errorDisplay || ''}</Alert>
                </SpaceBetween>
            </Modal>
}