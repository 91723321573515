export interface AccountingDayDropdownOption {
    label: string;
    value: string;
}

// Fixed list of Accounting Days to be support
export const accountingDaysList: AccountingDayDropdownOption[] = [
    { label: "Month End Close (Day-7)", value: "7" },
    { label: "Whiteboard (Day-11)", value: "11" }
];
