import { useEffect, useMemo, useRef, useReducer, Reducer, ReducerState, Dispatch, ReducerAction } from 'react';
import TPEAction from 'src/models/common/TPEAction';

const PRINT_REDUCER_LOGS = () => global.PRINT_REDUCER_LOGS !== false;


function enrichDispatchWithLogger(dispatch: any, reducerName: string) {
    return function (action: TPEAction) {
        if (PRINT_REDUCER_LOGS()) {
            console.groupEnd();
            console.groupCollapsed(`%c[${reducerName}] Action: ${action.type}`, `color:Green`);
            console.log('Payload: ', action.payload);
        }
        return dispatch(action);
    }
}



function useReducerWithLogger<R extends Reducer<any, any>, I>(
    reducer: R,
    initialState: ReducerState<R>,
    initializer?: (arg: I) => ReducerState<R>
): [ReducerState<R>, Dispatch<ReducerAction<R>>] {
    let prevState = useRef(initialState);
    const [state, dispatch] = initializer == null? useReducer(reducer, initialState) : useReducer(reducer, initialState, initializer as (arg: I) => ReducerState<R>);

    useEffect(() => {
        if (PRINT_REDUCER_LOGS()) {
            console.group(`%c[${reducer.prototype.constructor.name}] Initial State: `, `color:Green`);
            console.log(initialState);
            console.groupEnd();
        }
    }, [initialState]);

    const dispatchWithLogger = useMemo(() => {
        return enrichDispatchWithLogger(dispatch, reducer.prototype.constructor.name);
    }, [dispatch]);

    useEffect(() => {
        if (state !== initialState && PRINT_REDUCER_LOGS()) {
            console.log('Prev state: ', prevState.current);
            console.log('Next state: ', state);
        }
        else if (state === initialState && PRINT_REDUCER_LOGS()){
            console.log("No state change was performed!");
        }
        console.groupEnd();
        prevState.current = state;
    }, [state]);


    return [state, dispatchWithLogger];
}

export default useReducerWithLogger;