import CalculationStep from '../../models/calculation-builder/CalculationStep';
import CalculationTPEEntry from '../../models/calculation-builder/CalculationTPEntry';
import CalculationStepsRunItem from '../../models/calculation-builder/CalculationStepsRunItem';
import { SuggestionsListItem } from '../../models/calculation-builder/SuggestionsListItem';
import { CalcBuilderComment } from 'src/models/calculation-builder/CalcBuilderComment';

export interface CalculationStepsState {
    steps: Array<CalculationStep>,
    stepBeingEdited?: CalculationStep,
    tpeEntries: Array<CalculationTPEEntry>,
    error: string,
    isLoading: boolean,
    isSaving: boolean,
    showValues: boolean,
    suggestionsList: Array<SuggestionsListItem>,
    canAddNewStep: boolean,
    expandedState?: any,
    removeStepModalPayload?: any,
    commentsModalPayload?: any,
    addCommentPayload?: CalcBuilderComment,
    builtInFunctions: Array<string>,
    updateStepPayload?: any,
    deleteStepPayload?: any,
    lastRunValues?: Array<CalculationStepsRunItem>,
    stepsAreSavedAndValid: boolean,
    lastAddedCommentDate?: Date,
    tpeEntriesApplied: Set<string>,
    commentsHistory: Array<CalcBuilderComment>,
    commentsNeedRefresh: boolean,
    unsavedComments: Array<CalcBuilderComment>,
    stepSavedId?: string,
    showStandardAllocationModal: boolean,
    showStandardAllocationDeleteConfirmModal: boolean,
    stepIdForAction?: string,
}


export const initialState: CalculationStepsState = {
    steps: [],
    error: '',
    isLoading: true,
    isSaving: false,
    showValues: false,
    tpeEntries: [],
    suggestionsList: [],
    canAddNewStep: true,
    builtInFunctions: ["safeDivide()","getStandardAllocation()","getStandardAllocationTotal()"],
    stepsAreSavedAndValid: false,
    tpeEntriesApplied: new Set(),
    commentsHistory: [],
    commentsNeedRefresh: false,
    unsavedComments: [],
    showStandardAllocationModal: false,
    showStandardAllocationDeleteConfirmModal: false
}