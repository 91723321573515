import { Button } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { ACTION_TYPE, TPEBasicModal } from 'src/components/shared/TPEBasicModal';

export default function CancelEditing(props: {onCancelPostProcess: () => any}) {

    const { onCancelPostProcess = () => {} } = props;

    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    
    const cancelDataSource = () => {
        setShowCancelConfirmation(false);
        onCancelPostProcess();
    }

    return (
        <React.Fragment>
            <Button variant="link" className="smallSecondaryButton cancelDataSourceBtn" onClick={() => setShowCancelConfirmation(true)}>Cancel</Button>
            <TPEBasicModal
                    className="dataSourceEditCancelModal"
                    visible={showCancelConfirmation}
                    title="Cancel editing"
                    action={ACTION_TYPE.CONFIRM_CANCEL}
                    onConfirm={cancelDataSource}
                    onCancel={() => setShowCancelConfirmation(false)}
            >
                Are you sure you want to cancel editing this data source? Confirm to proceed.
            </TPEBasicModal>
        </React.Fragment>
    )
}