import {initialState, ReportsState} from "src/services/reports/ReportsState";
import React, {useEffect, useRef, useState} from "react";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import {reportsReducer} from "src/services/reports/ReportsReducer";
import {Box, Button, Container, SpaceBetween} from "@amzn/awsui-components-react";
import {Grid} from "@amzn/awsui-components-react/polaris";
import {CLIExecutionRecord, GetCLIExecutionRecordsRequest} from "src/models/reports/GetCLIExecutionRecords";
import JEStatusDashboardFilterSelector from "src/components/reports/jeStatusDashboard/JEStatusDashboardFilterSelector";
import {format, parse} from 'date-fns';
import TPPolarisTable from "src/components/shared/TPPolarisTable";
import {
    DEFAULT_COLLECTION_PREFERENCES,
    JEStatusDashboardTableColumns,
    PAGE_SIZE_OPTIONS,
    VISIBLE_CONTENT_OPTIONS
} from "src/components/reports/jeStatusDashboard/Constants";
import {
    getJEStatusDashboardTableFilteringProperties,
    prepareColumnDefinitions
} from "src/components/reports/jeStatusDashboard/TableUtils";
import {
    useJEPostingDashboardExcelBasedDownload
} from "src/components/reports/downloadReports/DownloadJEPostingStatusReport";

export type ContextType = {
    state: ReportsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const JEStatusDashboardProvider = (props: {
    state: ReportsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    children: JSX.Element
}) => {
    const {state, dispatch, services, children} = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);

    return (
        <JEStatusDashboardContext.Provider value={providerValue}>
            {children}
        </JEStatusDashboardContext.Provider>
    );
}

export const JEStatusDashboardContext = React.createContext(null as unknown as ContextType);

export default function JEStatusDashboardView(props: { services: ServiceCollection }) {
    const {services} = props;
    const [state, dispatch] = useReducerWithLogger(reportsReducer, initialState);
    const [resetNeededSignal, setResetNeededSignal] = React.useState(null as unknown as string);
    const [cliExecutionRecordList, setCLIExecutionRecordList] = React.useState([] as CLIExecutionRecord[]);
    const [selectedAccountingPeriod, setSelectedAccountingPeriod] = React.useState(null as null | any);
    const [searchResultsIsVisible, setSearchResultsIsVisible] = React.useState(false);
    const jeStatusDashboardColumnDefinitionsRef = useRef<any[]>([]);
    const jeStatusDashboardFilteringPropertiesRef = useRef<any[]>([]);
    const [fileName, setFileName] = useState('');

    const [cliExecutionRecordsRequest, setCLIExecutionRecordsRequest] = React.useState(null as unknown as GetCLIExecutionRecordsRequest);
    const [cliExecutionRecordsResponse, cliExecutionRecordsLoading, cliExecutionRecordsError] = services.reportsService.getCLIExecutionRecords(cliExecutionRecordsRequest);

    useEffect(() => {
        jeStatusDashboardColumnDefinitionsRef.current = prepareColumnDefinitions();
        jeStatusDashboardFilteringPropertiesRef.current = getJEStatusDashboardTableFilteringProperties();
        setFileName(selectedAccountingPeriod + "_" + (new Date()).getTime() + "_" + "JE_POSTING_STATUS.xlsx");
    }, [selectedAccountingPeriod]);

    useEffect(() => {
        if (!cliExecutionRecordsError) {
            return;
        }
        services.messageService.showErrorAutoDismissBanner(cliExecutionRecordsError);
    }, [cliExecutionRecordsError])

    useEffect(() => {
        if (cliExecutionRecordsResponse == null) {
            return;
        }
        setCLIExecutionRecordList(cliExecutionRecordsResponse.cliExecutionRecordsList)
    }, [cliExecutionRecordsResponse])

    const fetchCLIExecutionRecords = () => {
        if (!!selectedAccountingPeriod) {
            const formattedAccountingDate = format(parse(selectedAccountingPeriod, 'MMM-yy', new Date()), 'yyyyMM');
            setCLIExecutionRecordsRequest({
                periodId: formattedAccountingDate,
            })
        }
    }

    const handleRefresh = () => {
        setSearchResultsIsVisible(true)
        fetchCLIExecutionRecords()
    };

    const onResetClicked = () => {
        setSearchResultsIsVisible(false)
        setSelectedAccountingPeriod(null)
        setResetNeededSignal(new Date().toString());
    };

    return (<JEStatusDashboardProvider services={services} state={state} dispatch={dispatch}>
        <Container className="polaris-content-container">
            <SpaceBetween direction="vertical" size="xxl">
                <Grid gridDefinition={[{colspan: 8}, {colspan: 4}]}>
                    <JEStatusDashboardFilterSelector
                        selectedAccountingPeriod={selectedAccountingPeriod}
                        services={services}
                        onAccountingPeriodSelected={(periodId) => setSelectedAccountingPeriod(periodId)}
                    />
                    <Box float="right" padding={{top: "xl"}}>
                        <SpaceBetween direction="horizontal" size="s">
                            <Button
                                data-class={cliExecutionRecordsLoading ? "" : "refreshIconButton"}
                                disabled={ (!selectedAccountingPeriod) || (cliExecutionRecordsLoading) }
                                onClick={handleRefresh}
                                iconName="refresh"
                                variant="icon"
                            />
                            <Button onClick={onResetClicked}>Reset</Button>
                            <Button
                                className={"applyCriteriaButton"}
                                onClick={handleRefresh}
                                disabled = { (!selectedAccountingPeriod) || (cliExecutionRecordsLoading) }
                                variant="primary"
                            >
                                {cliExecutionRecordsLoading ? 'Loading CLI Journal Entries...' : 'View CLI Journal Entries' }
                            </Button>
                        </SpaceBetween>
                    </Box>
                </Grid>
                {
                    (searchResultsIsVisible && !!selectedAccountingPeriod) && <TPPolarisTable
                        sortingColumn="calculationNumber"
                        getDownloadFile={useJEPostingDashboardExcelBasedDownload}
                        sheetName="CLI Journal Entry Status"
                        fileName={fileName}
                        tableEmptyState="There are no CLI Execution Records for the selected accounting period."
                        headerTitle="CLI Journal Entry Status"
                        columnDefinations={jeStatusDashboardColumnDefinitionsRef.current}
                        tableItems={cliExecutionRecordList}
                        defaultPreferences={DEFAULT_COLLECTION_PREFERENCES}
                        preferencesStorageKey="jeStatusDashboardPreferencesStorageKey"
                        visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        filteringEmptyText="There are no CLI Execution Records for the selected filter conditions."
                        filteringLoadingText="Loading CLI Execution Records for the filter conditions applied."
                        filteringProperties={jeStatusDashboardFilteringPropertiesRef.current}
                        loadingText="Loading CLI Execution Records."
                        resizableColumns={true}
                        visibleColumns={JEStatusDashboardTableColumns}
                        isLoading={cliExecutionRecordsLoading}
                    />
                }
            </SpaceBetween>
        </Container>
    </JEStatusDashboardProvider>)

}