
import { DashboardState, DashboardAction } from 'src/models/dashboard'

/**
 * List here the actions supported by this reducer
 */
export const ACTIONS = Object.freeze({
  RESET: new DashboardAction('reset'),
  SET_NOTIFICATIONS: new DashboardAction('set-notifications'),
  FIELD: new DashboardAction('field'),
  ERROR: new DashboardAction('error'),
  SET_ENGINE_RUNTIME_BATCH: new DashboardAction('set-engine-runtime-batch'),
});


/**
 * This function is responsible for updating the state based on action type
 * @param state The current dashboard state
 * @param action The current dispatched action
 */
export function dashboardReducer(state: DashboardState, action: DashboardAction) {
  switch (action.type) {
    case ACTIONS.FIELD.type: {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case ACTIONS.RESET.type: {
      return {
        ...state,
        error: '',
        isLoading: true,
        notifications: [],
        initialLoadTime: new Date().toString()
      };
    }
    case ACTIONS.SET_NOTIFICATIONS.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        notifications: action.payload,
      };
    }
    case ACTIONS.SET_ENGINE_RUNTIME_BATCH.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        engineRuntimeSelectedBatch: action.payload,
      };
    }
    case ACTIONS.ERROR.type: {
      return {
        ...state,
        error: action.payload,
        isLoggedIn: false,
        isLoading: false,
        username: '',
        password: '',
      };
    }
    default:
      return state;
  }
}