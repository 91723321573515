import React, {useEffect, useMemo, useState} from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import StringUtils from 'src/utils/stringUtils';


export default function TPEErrorWatcher(props: { services: ServiceCollection, errors: string[] }) {
    const { services, errors } = props;

    const [refreshTime, setRefreshTime] = useState(new Date().toDateString());

    const currentErrorMessages = useMemo(() => { return [...errors]}, [refreshTime]);

    useEffect(() => {
        errors.forEach((value: string, index: number) => {
            if (value != currentErrorMessages[index] && !StringUtils.isNullOrEmpty(value)) {
                services.messageService.showErrorBanner(value);
            }
        })
        setRefreshTime(new Date().toDateString());
    }, [...errors])

    return null;
}
