import {TaxAuditRecordDetailsResponse} from "src/models/reports/TaxAuditRecordDetails";
import {Box, Container, Header} from "@amzn/awsui-components-react";
import {TPELoadingSpinner} from "src/components/shared/TPELoadingSpinner";
import React from "react";

function ValueWithLabel(props: any) {
    const { label } = props;
    return (
        <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                {label}
            </Box>
            <div>{props.children}</div>
        </div>
    );
}

export default function TaxAuditRecordReportDetails(props: { taxAuditRecordDetails: TaxAuditRecordDetailsResponse, loading: boolean}) {
    const {taxAuditRecordDetails, loading} = props;

    return <Container
        header={
            <Header variant="h2">Report Details</Header>
        }>
        <div className="polaris-content-container-tar-report-details">
            <TPELoadingSpinner loading={loading}>
                {taxAuditRecordDetails != null &&
                    <React.Fragment>
                        <div className="grid-item"><ValueWithLabel label="Transaction Id">{taxAuditRecordDetails.icTransactionId}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Transaction Date">{taxAuditRecordDetails.createDate}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Accounting Period">{taxAuditRecordDetails.accountingPeriod}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="CLI">{taxAuditRecordDetails.agreementLineNum}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Provider Company Code">{taxAuditRecordDetails.providerCompanyCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Provider Country">{taxAuditRecordDetails.providerCountryCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Recipient Company code">{taxAuditRecordDetails.recipientCompanyCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Recipient Country">{taxAuditRecordDetails.recipientCountryCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Workbook Id">{taxAuditRecordDetails.workbookId}</ValueWithLabel></div>
                    </React.Fragment>
                }
            </TPELoadingSpinner>
        </div>
    </Container>
}