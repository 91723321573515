import CalculationStepsRunItem from 'src/models/calculation-builder/CalculationStepsRunItem';
import AllocationGroup, { AllocationGroupFormula } from "src/models/tp-allocation/AllocationGroup";

export interface AllocationGroupState {
    groups: Array<AllocationGroup>,
    groupBeingEdited?: AllocationGroup,
    formulaBeingEdited?: AllocationGroupFormula,
    error: string,
    isLoading: boolean,
    isSaving: boolean,
    showValues: boolean,
    canAddNewGroup: boolean,
    expandedState?: any,
    updateGroupPayload?: any,
    updateFormulaPayload?: any,
    lastRunValues?: Array<CalculationStepsRunItem>,
    groupsAreSavedAndValid: boolean,
    groupSavedId?: string,
    groupFormulaSavedId?: string,
    removeGroupModalPayload?: AllocationGroup 
    removeGroupModalVisible: boolean,
    removeFormulaModalPayload?: any,
    groupIdToRefreshFormulas?: any,
}


export const initialState: AllocationGroupState = {
    groups: [],
    error: '',
    isLoading: true,
    isSaving: false,
    showValues: false,
    groupsAreSavedAndValid: false,
    canAddNewGroup: false,   
    removeGroupModalVisible: false 
};