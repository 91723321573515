import {CLI} from "src/models/common/CLI";
export class CLIDetailsResult {
    cli: CLI;

    constructor() {
        this.cli = {
            accountingOwner: '',
            accountingOwnerAlias: '',
            agreementCurrency: '',
            agreementLineNumber: '',
            agreementName: '',
            agreementNumber: '',
            agreementRecordId: '',
            agreementStatus: '',
            agreementStatusCategory: '',
            apttusCalculationStatus: '',
            assignee: '',
            assigneeAlias: '',
            beat: '',
            businessLine: '',
            businessSegment: '',
            calculationCurrency: '',
            calculationName: '',
            calculationNumber: '',
            calculationStartDate: '',
            calculationStatus: '',
            calculationTerminationDate: '',
            effectiveDate: 0,
            exportOfServices: '',
            functionalSegment: '',
            functionalSegmentCategory: '',
            inactiveReason: '',
            justification: '',
            lastModifiedDate: '',
            paymentTerms: '',
            providerCode: '',
            providerJurisdiction: '',
            providerLegalEntityName: '',
            recipientCode: '',
            recipientJurisdiction: '',
            recipientLegalEntityName: '',
            recordId: '',
            taskType: '',
            taxOwner: '',
            taxProduct: '',
            taxProductCategory: '',
            taxProductCategoryId: '',
            taxProductId: '',
            terminationDate: 0,
            tpBase: '',
            tpRate: '',
            workbookId: '',
            calculationBuilder: '',
            calculationBuilderAlias: '',
            workbookName: '', 
            workbookRecordId: '',
        };
    }
}