import {CLI} from "./CLI";
import ICRegistration from "../icrs/ICRegistration";

export enum CalculationSourceType {
    UNKNOWN,
    APTTUS,
    ICRS
} 

/**
 * Utility class to hold common fields between APTTUS and ICRS
 */
export default class CalculationSource {
    sourceKeyNumber: string
    exportOfServices: string
    providerCompanyCode: string
    providerEntityName: string
    providerJurisdiction: string
    recipientCompanyCode: string
    recipientEntityName: string
    recipientJurisdiction: string
    reviewerAlias?: string
    calcAsigneeAlias?: string
    calcBuilderAlias?: string
    sourceType: CalculationSourceType

    static fromCLI(seed: CLI): CalculationSource {
        const $this = new this();
        $this.sourceKeyNumber = seed.calculationNumber;
        $this.exportOfServices = seed.exportOfServices;
        $this.providerCompanyCode = seed.providerCode;
        $this.providerEntityName = seed.providerLegalEntityName;
        $this.providerJurisdiction = seed.providerJurisdiction;
        $this.recipientCompanyCode = seed.recipientCode;
        $this.recipientEntityName = seed.recipientLegalEntityName;
        $this.recipientJurisdiction = seed.recipientJurisdiction;
        $this.reviewerAlias = seed.accountingOwnerAlias;
        $this.calcAsigneeAlias = seed.assigneeAlias;
        $this.calcBuilderAlias = seed.calculationBuilderAlias
        $this.sourceType = CalculationSourceType.APTTUS;
        return $this;
    }

    static fromICRS(seed: ICRegistration){
        const $this = new this();
        $this.sourceKeyNumber = seed.registrationNumber;
        $this.exportOfServices = seed.exportOfServices? "True" : "False";
        $this.providerCompanyCode = seed.providerCompanyCode;
        $this.providerEntityName = seed.providerEntityName;
        $this.providerJurisdiction = seed.providerJurisdiction;
        $this.recipientCompanyCode = seed.recipientCompanyCode;
        $this.recipientEntityName = seed.recipientEntityName;
        $this.recipientJurisdiction = seed.recipientJurisdiction;
        $this.reviewerAlias = seed.accountingApprover;
        $this.calcAsigneeAlias = seed.lastUpdatedByAccountingUser;
        $this.sourceType = CalculationSourceType.ICRS;
        return $this;
    }

    constructor(){
        this.sourceKeyNumber = '';
        this.exportOfServices = '';
        this.providerCompanyCode = '';
        this.providerEntityName = '';
        this.providerJurisdiction = '';
        this.recipientCompanyCode = '';
        this.recipientEntityName = '';
        this.recipientJurisdiction = '';
        this.sourceType = CalculationSourceType.UNKNOWN;
    }
}