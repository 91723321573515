import React, { useMemo, useState } from 'react';
import 'src/assets/styles/react-table.scss';
import TPESelectableReactTable from 'src/components/shared/TPESelectableReactTable';
import CONSTANTS from 'src/utils/constants';
import { Reversal } from 'src/models/mec/status/Reversal';
import ExecutionStatusIndicator from '../../shared/ExecutionStatusIndicator';
import { Link } from '@amzn/awsui-components-react';
import { getPermissions } from 'src/components/AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';

export default function ReversalsGrid(props: {data: Reversal[], loading: boolean, onSelect: () => any, onDisplayGroupDetails: (group: string) => any}) {
    const { loading, data = [], onSelect, onDisplayGroupDetails } = props;
    const { canBulkReverse } = getPermissions(AppModules.MEC);
    
    const getColDefs = () => {
        const colDefs: any[] = [];
        Object.values(CONSTANTS.MEC_REVERSAL_COLUMN_HEADERS).forEach((column:any) => {
            switch(column.ACCESSOR) {
                case CONSTANTS.MEC_REVERSAL_COLUMN_HEADERS.NAME.ACCESSOR:
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => <ReversalName item={e.row.original as Reversal} onDisplayGroupDetails={onDisplayGroupDetails}/>
                    });
                    break;
                case CONSTANTS.MEC_REVERSAL_COLUMN_HEADERS.DRY_RUN.ACCESSOR:
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => <span className="textCell cell-text-wrap">{e.value? "Yes" : (e.value === false? "No" : "")}</span>
                    });
                    break;
                case CONSTANTS.MEC_REVERSAL_COLUMN_HEADERS.STATUS.ACCESSOR:
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => <div className="cell-text"><ExecutionStatusIndicator status={e.value} /></div>
                    })
                    break;
                default:
                    colDefs.push({
                        Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                        accessor: column.ACCESSOR,
                        Cell: (e: any) => <div className="cell-text">{e.value}</div>
                    });
            }
        });
        return colDefs;
    }

    const columnDefinitions = useMemo(getColDefs, [data]);

    return (
        <div className="reversalsGridContainer">
            <TPESelectableReactTable {...{
                data,
                columnDefinitions,
                className: "reversalsGrid",
                isSearching: loading,
                allowMultipleRowSelection: true,
                showFilters: false,
                sortable: false,
                onRowClicked:(isChecked, reversal: Reversal, index) =>  onSelect(),
                hideCheckboxes: !canBulkReverse
            }} />
        </div>
    );
}

function ReversalName(props: {item: Reversal, onDisplayGroupDetails: (group: string) => any}) {
    const { item, onDisplayGroupDetails } = props;

    if (item.recordId != null) {
        return <Link data-class="cell-link" external href={CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + '/' + btoa(item.recordId)}>{item.name}</Link>
    }
    if (item.calculationNumber != null) {
        return <Link data-class="cell-link" external href={CONSTANTS.PAGE_NAV.ICRS_CALCULATION_BUILDER.URL + '/' + btoa(item.calculationNumber)}>{item.name}</Link>
    }
    if (item.type == CONSTANTS.REVERSAL_ITEM_TYPES.GROUP) {
        return <Link data-class="cell-link" onFollow={() => onDisplayGroupDetails(item.name || '')}>{item.name}</Link>
    }
    return <div className="cell-text">{item.name}</div>
}
