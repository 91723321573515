import TPEAction from "src/models/common/TPEAction";
import TPESearchRequest from "src/models/common/TPESearchRequest";
import {CustomTablesState } from "src/services/custom-data-tables/CustomTablesState";
import { getDefaultSearchPayload } from "src/components/custom-tables-shared/CustomDataTableSearchHelper";

export const ACTIONS = Object.freeze({
    SET_ERROR: new TPEAction('SET_ERROR'),
    SET_S3_KEY: new TPEAction('SET_S3_KEY'),
    SET_SEARCH_RESULT: new TPEAction('SET_SEARCH_RESULT'),
    SET_SEARCH_PAYLOAD: new TPEAction('SET_SEARCH_PAYLOAD'),
    RESET_SEARCH: new TPEAction('RESET_SEARCH'),
    SET_DUPLICATE_ROWS_INFO: new TPEAction('SET_DUPLICATE_ROWS_INFO'),
    TOGGLE_SELECT_DUPLICATE_ROW: new TPEAction('TOGGLE_SELECT_DUPLICATE_ROW'),
    SELECT_ALL_EXISTING_RECORDS: new TPEAction('SELECT_ALL_EXISTING_RECORDS'),
    SELECT_ALL_NEW_RECORDS: new TPEAction('SELECT_ALL_NEW_RECORDS'),
    SET_SAVE_CUSTOM_DATA_TABLE_PAYLOAD: new TPEAction('SET_SAVE_CUSTOM_DATA_TABLE_PAYLOAD'),
    SET_CURRENT_PAGE: new TPEAction('SET_CURRENT_PAGE'),
});

export function customDataTablesReducer(state: CustomTablesState, action: TPEAction) : CustomTablesState {
    switch(action.type) {
        case ACTIONS.SET_ERROR.type:
            return {
                ...state,
                error: action.payload,
            };
        case ACTIONS.SET_S3_KEY.type:
            return{
                ...state,
                s3Key: action.payload,
            }
        case ACTIONS.SET_SEARCH_RESULT.type:
            return {
                ...state,
                searchResult: action.payload
            }
        case ACTIONS.SET_DUPLICATE_ROWS_INFO.type: {
            return {
                ...state,
                duplicateRowsInfo: action.payload
            }
        }
        case ACTIONS.TOGGLE_SELECT_DUPLICATE_ROW.type: {
            const { index, isChecked } = action.payload;
            if (state.duplicateRowsInfo != null) {
                let otherIndex = index;
                if (index == 0 || index % 2 == 0) {
                    otherIndex = index + 1;
                } else {
                    otherIndex = index - 1;
                }

                const records = [...state.duplicateRowsInfo?.records];
                records[index].isSelected = isChecked;
                records[otherIndex].isSelected = !isChecked;
                
                state.duplicateRowsInfo.records = records;
            }

            return {
                ...state
            }

        }
        case ACTIONS.SELECT_ALL_EXISTING_RECORDS.type: {
            if (state.duplicateRowsInfo != null) {
                const records = [...state.duplicateRowsInfo.records];
                records.forEach(x => x.isSelected = x.isNewRecord ? false : true);
                state.duplicateRowsInfo.records = records;
            }
            return {
                ...state
            }
        }
        case ACTIONS.SELECT_ALL_NEW_RECORDS.type: {
            if (state.duplicateRowsInfo != null) {
                const records = [...state.duplicateRowsInfo.records];
                records.forEach(x => x.isSelected = x.isNewRecord ? true : false);
                state.duplicateRowsInfo.records = records;
            }
            return {
                ...state
            }
        }
        case ACTIONS.SET_SAVE_CUSTOM_DATA_TABLE_PAYLOAD.type:
            return {
                ...state,
                saveCustomDataTablePayload: action.payload
            }
        case ACTIONS.SET_CURRENT_PAGE.type: {
            const searchPayload = {...state.searchPayload || {}, page: action.payload} as TPESearchRequest;
            return {
                ...state,
                searchPayload: searchPayload,
            }
        }
        case ACTIONS.SET_SEARCH_PAYLOAD.type: {
            return {
                ...state,
                searchPayload: action.payload,
            }
        }
        case ACTIONS.RESET_SEARCH.type: {
            return {
                ...state,
                searchPayload: {...getDefaultSearchPayload(),...action.payload},
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}