import React from 'react';
import { Link, Box } from '@amzn/awsui-components-react/polaris';
import {CLI} from 'src/models/common/CLI';
import 'src/assets/styles/react-table.scss';
import { DefaultColumnFilter, TPEReactTable } from '../../shared/TPEReactTable';
import CONSTANTS from 'src/utils/constants';
import TPENavigator from '../../TPENavigator';

type ComponentProps = {
    calculations: CLI[];
    headerText: string,    
};

export default function CalculationsConfirmationGrid(props: ComponentProps) {
    const { calculations, headerText } = props;

    const [tableKey, setTableKey] = React.useState(0);
    const [navigateURL, setNavigateURL] = React.useState('');

    const navigateToCalcBuilder = (recordId: string) => {
        const url = CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + '/' + btoa(recordId);
        setNavigateURL(url);
    };

    const columnDefinitions = [
        {
            accessor: "calculationNumber",
            Header: (e: any) => <div className="cell-text">Calculation Number</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => {
                return e.row.original.calculationStatus?.toLowerCase() !== CONSTANTS.CALCULATION_STATUS.YET_TO_START.toLowerCase() ?
                    <Link className="cell-link selectCalculationLink" onFollow={({ detail }) => navigateToCalcBuilder(e.row.original.recordId)}>{e.value}</Link> : <div className="cell-text">{e.value}</div>
            },
        },
        {
            accessor: "functionalSegment",
            Header: (e: any) => <div className="cell-text">Functional Segment</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "calculationName",
            Header: (e: any) => <div className="cell-text">Calculation Name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "agreementStatus",
            Header: (e: any) => <div className="cell-text">Agreement Status</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "calculationStatus",
            Header: (e: any) => <div className="cell-text">Calculation Status</div>,
            Filter: DefaultColumnFilter,
            Cell: (cellInfo: any) => {
                return cellInfo.value?.toLowerCase() === CONSTANTS.CALCULATION_STATUS.YET_TO_START.toLowerCase() ?
                    <Link className="cell-link"
                        onFollow={e => navigateToCalcBuilder(cellInfo.row.original.recordId)}>{cellInfo.value}</Link> : <span>{cellInfo.value}</span>
            },
        },
        {
            accessor: "taskType",
            Header: (e: any) => <div className="cell-text">Task Type</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "accountingOwner",
            Header: (e: any) => <div className="cell-text">TP Accounting Owner</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "taxOwner",
            Header: (e: any) => <div className="cell-text">TP Tax Owner</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "providerCode",
            Header: (e: any) => <div className="cell-text">First Party</div>,
            Filter: DefaultColumnFilter,
        },
        {
            accessor: "recipientCode",
            Header: (e: any) => <div className="cell-text">Second Party</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        }
    ];

    
    return (
        <React.Fragment>
            <TPENavigator path={navigateURL} />
            
            <Box fontWeight="bold">{headerText}</Box>
            <div className="tableContainer">
                <TPEReactTable key={tableKey} {...{
                    data: calculations,
                    columnDefinitions,
                    className: "calculations",
                    emptyMessage: "No calculations to display.",
                    sortable:true,
                }} />
            </div>
        </React.Fragment>
    );
}