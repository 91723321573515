import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Pagination, RadioGroup,
    SpaceBetween,
    TextContent
} from '@amzn/awsui-components-react';
import React, {useContext, useEffect, useState} from 'react';
import TPEAction from 'src/models/common/TPEAction';
import {FetchReportRequest} from "src/models/reports/FetchReport";
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import TPEErrorWatcher from '../../shared/TPEErrorWatcher';
import ReportRecordsGrid from "src/components/reports/prevalidation/ReportRecordsGrid";
import useLocalStorage from "src/utils/useLocalStorage";
import {GenerateReportRequest} from "src/models/reports/GenerateReport";
import {Modal} from "@amzn/awsui-components-react/polaris";
import CONSTANTS, {INDIRECT_TAX_CHANGE_RECORDS_PAGE_LIMIT} from "src/utils/constants";
import '../ReportsTabbedView.scss';
import {DownloadReportRequest} from "src/models/reports/DownloadReport";
import {PreValidationReportState, initialState} from "src/services/reports/prevalidation/PreValidationReportState";
import {ACTIONS, preValidationReportReducer} from "src/services/reports/prevalidation/PreValidationReportReducer";
import ReportStatus from "src/components/reports/ReportStatus";
import {DownloadIndirectTaxChangeRecordsRequest} from "src/models/reports/DownloadIndirectTaxChangeRecords";
import {FetchIndirectTaxChangeRecordsRequest} from "src/models/reports/FetchIndirectTaxChangeRecords";
import PreValidationIndirectTaxTableGrid from "src/components/reports/prevalidation/PreValidationIndirectTaxTableGrid";
import ATPHorizontalRadioGroup from "src/components/shared/ATPHorizontalRadioGroup";

export type ContextType = {
    state: PreValidationReportState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const PreValidationReportsProvider = (props: any) => {
    const {state, dispatch, services, children} = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <PreValidationReportsContext.Provider value={providerValue}>
            {children}
        </PreValidationReportsContext.Provider>
    );
}
export const PreValidationReportsContext = React.createContext(null as unknown as ContextType);

export default function ReportsTabbedView(props: { services: ServiceCollection }) {
    const {services} = props;
    const [syncReportRequest, setSyncReportRequest] = React.useState(null as unknown as FetchReportRequest);
    const [state, dispatch] = useReducerWithLogger(preValidationReportReducer, initialState);
    const {selectedTab, selectedPage, loadedPage, isVisible, disableForceGenerateButton, cache, indirectTaxChangeRecordList, lastReportStatusModal, preValIndirectTaxModal} = state;
    const [reportData, isLoading, reportFetchError] = services.reportsService.fetchReportData(syncReportRequest);
    const [generateReportRequest, setGenerateReportRequest] = React.useState(null as unknown as GenerateReportRequest);
    const [generateReportResponse, generateReportLoading, generateReportError] = services.reportsService.generateReport(generateReportRequest);
    const [downloadReportRequestPayload, setDownloadReportRequestPayload] = React.useState(null as unknown as DownloadReportRequest);
    const [downloadReportUrl, downloadReportLoading, downloadReportError] = services.reportsService.downloadReport(downloadReportRequestPayload);
    const [fetchIndirectTaxChangeRecordsRequestPayload, setFetchIndirectTaxChangeRecordsRequestPayload] = React.useState(null as unknown as FetchIndirectTaxChangeRecordsRequest);
    const [indirectTaxChangeRecords, indirectTaxChangeRecordsLoading, indirectTaxChangeRecordsError] = services.reportsService.fetchIndirectTaxChangeRecords(fetchIndirectTaxChangeRecordsRequestPayload);
    const [downloadIndirectTaxChangeRecordsRequestPayload, setDownloadIndirectTaxChangeRecordsRequestPayload] = React.useState(null as unknown as DownloadIndirectTaxChangeRecordsRequest);
    const [preValIndirectTaxReportUrl, preValIndirectTaxReportUrlLoading, preValIndirectTaxReportError] = services.reportsService.downloadIndirectTaxChangeRecords(downloadIndirectTaxChangeRecordsRequestPayload);

    const [selectedDetailTabReportId, setSelectedDetailTabReportId] = useState(null as unknown as string);
    const [taxRateChangeIndicator, setTaxRateChangeIndicator] = useState(true as unknown as boolean | undefined);
    const [selectedPreValIndirectTaxReportId, setSelectedPreValIndirectTaxReportId] = useState(null as unknown as string);
    const SHOW_ALL_VALUES: Array<{ value: string, label: string }> = [
        { value: "Show CLI's with Tax Change", label: "Show CLI's with Tax Rate Change" },
        { value: "Show All CLI's", label: "Show All CLI's" }
    ];

    useEffect(() => {
        if (!reportData) return;

        const isReportFailed = reportData.reportStatus === CONSTANTS.REPORT_STATUS.FAILED || reportData.reportStatus === CONSTANTS.REPORT_STATUS.VALIDATION_FAILED
        if (selectedTab === CONSTANTS.REPORT_TYPES.PRE_VALIDATION_INDIRECT_TAX && !isReportFailed && !!reportData.reportStatus) {
            setSelectedPreValIndirectTaxReportId(reportData.reportId);
            return;
        }
        if (reportData.reportStatus != CONSTANTS.REPORT_STATUS.SUCCESS) {
            dispatch(ACTIONS.SET_LAST_REPORT_STATUS_MODAL.withPayload(true));
            return;
        }
        setSelectedDetailTabReportId(reportData.reportId);
    }, [reportData, selectedTab]);

    useEffect( () => {
        handleRefresh();
    }, [selectedPreValIndirectTaxReportId]);

    useEffect(() => {
        if(downloadReportError) {
            services.messageService.showErrorBanner(downloadReportError);
        }
        else if (downloadReportUrl) {
            window.open(downloadReportUrl);
        }
    }, [downloadReportUrl, downloadReportError])

    useEffect(() => {
        if (preValIndirectTaxReportError) {
            services.messageService.showErrorBanner(preValIndirectTaxReportError);
        } else if (preValIndirectTaxReportUrl) {
            window.open(preValIndirectTaxReportUrl);
        }
    }, [preValIndirectTaxReportUrl, preValIndirectTaxReportError]);

    useEffect(() => {
        if (indirectTaxChangeRecords == null) {
            return;
        }
        dispatch(ACTIONS.SET_CACHE.withPayload({ ...cache, [selectedPage]: indirectTaxChangeRecords.indirectTaxChangeRecordList}));
        dispatch(ACTIONS.SET_INDIRECT_TAX_CHANGE_RECORD_LIST.withPayload(indirectTaxChangeRecords.indirectTaxChangeRecordList));
    }, [indirectTaxChangeRecords])

    useEffect( () => {
        handleRefresh();
    }, [taxRateChangeIndicator])

    const fetchIndirectTaxChangeRecordList = (startKey?: string) => {
        if (!!selectedPreValIndirectTaxReportId) {
            setFetchIndirectTaxChangeRecordsRequestPayload({
                reportId: selectedPreValIndirectTaxReportId,
                taxRateChangeIndicator: taxRateChangeIndicator,
                limit: INDIRECT_TAX_CHANGE_RECORDS_PAGE_LIMIT,
                startKey: startKey
            })
        }
    }

    const handleRefresh = () => {
        dispatch(ACTIONS.SET_CACHE.withPayload({}));
        dispatch(ACTIONS.SET_LOADED_PAGE.withPayload(1));
        dispatch(ACTIONS.SET_SELECTED_PAGE.withPayload(1));
        dispatch(ACTIONS.SET_IS_VISIBLE.withPayload(true));
        fetchIndirectTaxChangeRecordList();
    };

    const handleNextPageClick = (requestedPageIndex: number) => {
        dispatch(ACTIONS.SET_SELECTED_PAGE.withPayload(requestedPageIndex));

        if (cache.hasOwnProperty(requestedPageIndex)) {
            dispatch(ACTIONS.SET_INDIRECT_TAX_CHANGE_RECORD_LIST.withPayload(cache[requestedPageIndex]));
        } else if (indirectTaxChangeRecords.lastEvaluatedKey) {
            dispatch(ACTIONS.SET_LOADED_PAGE.withPayload(loadedPage + 1));
            fetchIndirectTaxChangeRecordList(indirectTaxChangeRecords.lastEvaluatedKey);
        }
    };

    return <PreValidationReportsProvider services={services} state={state} dispatch={dispatch}>
        <div className="preValidationReportsTopParentContainer">
            <div className="preValidationReportsTopContainer">
                <div className="preValidationReportsTopContainerTitle">
                    <Button
                        variant="link"
                        data-class={selectedTab == CONSTANTS.REPORT_TYPES.PRE_VALIDATION_INDIRECT_TAX ? 'activeTab' : 'inactiveTab'}
                        onClick={() => {
                            setSyncReportRequest({
                                reportName: CONSTANTS.REPORT_TYPES.PRE_VALIDATION_INDIRECT_TAX,
                                params: {}
                            });
                            dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.REPORT_TYPES.PRE_VALIDATION_INDIRECT_TAX))
                        }}
                    >Indirect Tax Changes</Button>
                    <Button
                        variant="link"
                        data-class={selectedTab == CONSTANTS.REPORT_TYPES.PRE_VALIDATION_COA ? 'activeTab' : 'inactiveTab'}
                        onClick={() => {
                            setSyncReportRequest({
                                reportName: CONSTANTS.REPORT_TYPES.PRE_VALIDATION_COA,
                                params: {}
                            });
                            dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.REPORT_TYPES.PRE_VALIDATION_COA));
                        }}
                    >COA Changes</Button>
                    <Button
                        variant="link"
                        data-class={selectedTab == CONSTANTS.REPORT_TYPES.PRE_VALIDATION_PTA ? 'activeTab' : 'inactiveTab'}
                        onClick={() => {
                            setSyncReportRequest({
                                reportName: CONSTANTS.REPORT_TYPES.PRE_VALIDATION_PTA,
                                params: {}
                            });
                            dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.REPORT_TYPES.PRE_VALIDATION_PTA));
                        }}
                    >CLI - PTA Data</Button>
                    <Button
                        variant="link"
                        data-class={selectedTab == CONSTANTS.REPORT_TYPES.PRE_VALIDATION_CDT ? 'activeTab' : 'inactiveTab'}
                        onClick={() => {
                            setSyncReportRequest({
                                reportName: CONSTANTS.REPORT_TYPES.PRE_VALIDATION_CDT,
                                params: {}
                            });
                            dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.REPORT_TYPES.PRE_VALIDATION_CDT));
                        }}
                    >CLI - CDT Data</Button>
                </div>
            </div>
        </div>
        <Container data-class="preValidationReportsTabContainer">
            <div>
                {selectedTab === CONSTANTS.REPORT_TYPES.PRE_VALIDATION_CDT || selectedTab === CONSTANTS.REPORT_TYPES.PRE_VALIDATION_PTA || selectedTab === CONSTANTS.REPORT_TYPES.PRE_VALIDATION_COA ?
                    <React.Fragment>
                        <div className="preValidationReportsTabTableHeader">
                            <ColumnLayout columns={1} data-class="fullColumnLayout">
                                <Box float="right">
                                    <SpaceBetween direction="horizontal" size="m">
                                        <TextContent>
                                            <small>Last report generated
                                                at {reportData != null ? reportData.createdAt : ''}</small>
                                        </TextContent>
                                        <Button variant="primary" disabled={generateReportLoading} onClick={() => {
                                            setGenerateReportRequest({
                                                userId: useLocalStorage.getUsernameFromLocalStorage(),
                                                reportName: selectedTab,
                                                params: {}
                                            });
                                        }}>{generateReportLoading ? 'Generating Report...' : 'Force Generate'}
                                        </Button>
                                        <Button variant="primary" disabled={isLoading || generateReportLoading || !selectedDetailTabReportId} onClick={ () => {
                                            setDownloadReportRequestPayload({
                                                reportId: selectedDetailTabReportId,
                                                userId: useLocalStorage.getUsernameFromLocalStorage()
                                            });
                                        }}>{downloadReportLoading ? 'Downloading...' : 'Download Report' }</Button>
                                    </SpaceBetween>
                                </Box>
                            </ColumnLayout>
                        </div>
                        <div className="tableContainer">
                            <ReportRecordsGrid loading={isLoading} reportData={reportData}/>
                        </div>
                    </React.Fragment>
                    : selectedTab === CONSTANTS.REPORT_TYPES.PRE_VALIDATION_INDIRECT_TAX ? (
                        <React.Fragment>
                            <div className="preValidationReportsTabTableHeader">
                                    <Box float="right">
                                        <SpaceBetween direction="horizontal" size="m">
                                            <TextContent>
                                                {reportData && CONSTANTS.REPORT_STATUS.SUCCESS == reportData.reportStatus && (
                                                    <small>Last report generated at: {reportData.createdAt}</small>
                                                )}
                                            </TextContent>
                                            <TextContent>
                                                {reportData && !!reportData.reportStatus && (
                                                    <small>Report Status: <ReportStatus reportStatus={reportData.reportStatus} /></small>
                                                )}
                                            </TextContent>
                                            <Button variant="primary"
                                                    disabled={!disableForceGenerateButton|| generateReportLoading || !reportData ||
                                                        (reportData.reportStatus != CONSTANTS.REPORT_STATUS.SUCCESS && reportData.reportStatus != CONSTANTS.REPORT_STATUS.FAILED)}
                                                    onClick={() => {
                                                        dispatch(ACTIONS.SET_DISABLE_FORCE_GENERATE_BUTTON.withPayload(false));
                                                        setGenerateReportRequest({
                                                            userId: useLocalStorage.getUsernameFromLocalStorage(),
                                                            reportName: selectedTab,
                                                            params: {}
                                                        });
                                                        dispatch(ACTIONS.SET_PRE_VAL_INDIRECT_TAX_MODAL.withPayload(true));
                                                    }}>{'Force Generate'}

                                            </Button>
                                            <Button variant="primary"
                                                    disabled={isLoading || !reportData || reportData.reportStatus != CONSTANTS.REPORT_STATUS.SUCCESS || !selectedPreValIndirectTaxReportId}
                                                    onClick={ () => {
                                                        setDownloadIndirectTaxChangeRecordsRequestPayload({
                                                            reportId: selectedPreValIndirectTaxReportId,
                                                        });
                                            }}>{preValIndirectTaxReportUrlLoading ? 'Downloading...' : 'Download Report' }</Button>
                                        </SpaceBetween>
                                    </Box>
                                <br />
                                <br />
                            </div>
                            {
                                isVisible &&
                                <div>
                                    <div className="preValIndirectTaxTableHeader">
                                        <h3 />
                                        {indirectTaxChangeRecords != null &&
                                            <Box float="right">
                                                <SpaceBetween direction="horizontal" size="m">
                                                    <ATPHorizontalRadioGroup
                                                        data-class="basicModalRadioGroup"
                                                        onChange={(value) => setTaxRateChangeIndicator(value === SHOW_ALL_VALUES[0].value ? true : undefined)}
                                                        value={taxRateChangeIndicator ? SHOW_ALL_VALUES[0].value : SHOW_ALL_VALUES[1].value}
                                                        items={SHOW_ALL_VALUES}
                                                    />
                                                    <Button
                                                        className={indirectTaxChangeRecordsLoading ? "" : "blueIcon"}
                                                        disabled={indirectTaxChangeRecordsLoading}
                                                        onClick={() => handleRefresh()}
                                                        iconName="refresh"
                                                        variant="icon"
                                                    />
                                                    <Pagination
                                                        currentPageIndex={selectedPage}
                                                        pagesCount={loadedPage}
                                                        onNextPageClick={({detail}) => {
                                                            handleNextPageClick(detail.requestedPageIndex);
                                                        }}
                                                        onChange={({detail}) => {
                                                            dispatch(ACTIONS.SET_SELECTED_PAGE.withPayload(detail.currentPageIndex));
                                                            dispatch(ACTIONS.SET_INDIRECT_TAX_CHANGE_RECORD_LIST.withPayload(cache[detail.currentPageIndex]));
                                                        }}
                                                        onPreviousPageClick={({detail}) => {
                                                            dispatch(ACTIONS.SET_SELECTED_PAGE.withPayload(detail.requestedPageIndex));
                                                            dispatch(ACTIONS.SET_INDIRECT_TAX_CHANGE_RECORD_LIST.withPayload(cache[detail.requestedPageIndex]));
                                                        }}
                                                        openEnd={!!indirectTaxChangeRecords.lastEvaluatedKey}
                                                    />
                                                </SpaceBetween>
                                            </Box>
                                        }
                                    </div>
                                    <div className="tableContainer">
                                        <PreValidationIndirectTaxTableGrid
                                            loading= {indirectTaxChangeRecordsLoading}
                                        />
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    ) : null
                }
                <TPEErrorWatcher services={services} errors={[reportFetchError]}/>

                <Modal
                    onDismiss={() =>  dispatch(ACTIONS.SET_LAST_REPORT_STATUS_MODAL.withPayload(false))}
                    visible={lastReportStatusModal}
                    header="Last report status"
                >
                    {reportData != null ? reportData.statusMessage : ''}
                </Modal>
                <Modal
                    onDismiss={() =>  dispatch(ACTIONS.SET_PRE_VAL_INDIRECT_TAX_MODAL.withPayload(false))}
                    visible={preValIndirectTaxModal}
                    header="Report Generation In Progress"
                >
                    Please refresh the page to see latest report.
                </Modal>
            </div>
        </Container>
    </PreValidationReportsProvider>
}