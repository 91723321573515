import apiService from "../ApiCallService";
import ErrorUtils from "src/utils/ErrorUtils";
import CalculationStep from "src/models/calculation-builder/CalculationStep";
import {useEffect, useState} from "react";
import {CalcBuilderComment} from "src/models/calculation-builder/CalcBuilderComment";
import FormattingService from "../utils/FormattingService";
import CalculationTPEEntry from "src/models/calculation-builder/CalculationTPEntry";
import {CLI} from "src/models/common/CLI";
import {SavingStatus} from "src/models/common/SavingStatus";

/**
 * This class should only contain the API calls related to Calculation Steps
 */
 export default class CalculationStepsService {

    createNewCalculationStep(sequence:number, calculationNumber: string, isSubstep: boolean) : CalculationStep {
        return {
            stepName: '',
            expression: '',
            isBeingEdited: false,
            savingStatus: SavingStatus.Unsaved,
            sequence: sequence,
            isSubstep: isSubstep,
            stepId: `newStep${sequence}`,
            calculationNumber: calculationNumber,
            isNew: true,
            valid: true,
        } as CalculationStep;
    }

    getCalculationSteps(calculationNumber?: string, version?: number, stepsNeedRefresh?: boolean, calculationExecutionId?: string) : [any[], boolean, string | null] {
        const [result, setResult] = useState([] as any[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as string | null);

        useEffect(() => {
            async function fetchData(calcNumber:string) {
                try {
                    setLoading(true);
                    const response = await apiService.getCalcSteps(calcNumber, version == null? -1 : version, calculationExecutionId);
                    const json = await response.json();
                    const steps = json.steps as any[];
                    setCalcNumberInSteps(calcNumber, steps);
                    copySubStepsToSubRows(steps);
                    setResult(steps);
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null && stepsNeedRefresh === true) {
                fetchData(calculationNumber);
            }
        }, [calculationNumber, stepsNeedRefresh]);
        return [result, loading, error]
    }

    saveCalculationStep(step?: CalculationStep): [any, boolean, string | null] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as string | null);

        useEffect(() => {
            async function postData(record:CalculationStep) {
                try {
                    setLoading(true);
                    setError(null);
                    const apiModel = {
                        calculationNumber: record.calculationNumber,
                        step: {
                            stepId: record.stepId,
                            sequence: record.sequence,
                            calculationVersion: -1, // The editing version should always be -1
                            expressionTokens: record.expressionTokens,
                            parentId: record.parentId,
                            stepName: record.stepName,
                            tpEntryType: record.tpEntryType,
                            substeps: record.subRows
                        }
                    }
                    const response = await (record.isNew?
                        apiService.createCalculationStep(record.calculationNumber, apiModel) :
                        apiService.updateCalculationStep(record.calculationNumber, record.stepId, apiModel));
                    const json = await response.json()
                    json.step.calculationNumber = record.calculationNumber;
                    setResult(json.step);
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (step != null) {
                postData(step);
            }
        }, [step]);
        return [result, loading, error]
    }

    deleteCalculationStep(payload?: any) : [any[], boolean, string | null] {
        const [result, setResult] = useState([] as any[]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null as string | null);

        useEffect(() => {
            async function fetchData(calculationNumber:string, stepId: string) {
                try {
                    setError(null);
                    const response = await apiService.deleteCalculationStep(calculationNumber, stepId);
                    const json = await response.json()
                    setResult(json);
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                fetchData(payload.calculationNumber, payload.stepId);
            }
        }, [payload]);
        return [result, loading, error]
    }
    
    
    validateCalculationSteps(calculationNumber?: string, stepsNeedValidation?: boolean) : [any, boolean, string | null] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as string | null);
        //console.log("validateCalculationSteps args: ", arguments);

        useEffect(() => {
            async function fetchData(calcNumber:string) {
                try {
                    setError(null);
                    const response = await apiService.validateCalcSteps(calcNumber);
                    const json = await response.json();
                    const steps = json.steps as any[];
                    setCalcNumberInSteps(calcNumber, steps);
                    copySubStepsToSubRows(steps);
                    setResult({...json, steps: steps});
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null && stepsNeedValidation === true) {
                setLoading(true);
                fetchData(calculationNumber);
            }
        }, [calculationNumber, stepsNeedValidation]);
        return [result, loading, error]
    }

    cancelStepsChanges(calculationNumber?: string, newCancelRequest?: string) : [any[], boolean, string | null] {
        const [result, setResult] = useState([] as any[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as string | null);

        useEffect(() => {
            async function fetchData(calcNumber:string) {
                try {
                    setError(null);
                    const response = await apiService.cancelCalcStepsChanges(calcNumber);
                    const json = await response.json();
                    const steps = json.steps as any[];
                    setCalcNumberInSteps(calcNumber, steps);
                    copySubStepsToSubRows(steps);
                    setResult(steps);
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null && newCancelRequest != null) {
                setLoading(true);
                fetchData(calculationNumber);
            }
        }, [calculationNumber, newCancelRequest]);
        return [result, loading, error]
    }
    
    runCalSteps(calculationNumber?: string, version?: number, generateJournals?: boolean, shouldDoRun?: string) : [any[], boolean, string | null] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as string | null);
        
        useEffect(() => {
            async function fetchData(calcNumber: string) {
                try {
                    setError(null);
                    const response = await apiService.runCalculationSteps(calcNumber, version == null ? -1 : version, generateJournals || false);
                    const json = await response.json();
                    formatBreakdownValues(json.results);
                    setResult(json.results);
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null && shouldDoRun != null) {
                setLoading(true);
                fetchData(calculationNumber);
            }
        }, [calculationNumber, shouldDoRun]);
        return [result, loading, error]    
    }

    getStepComments(calculationNumber?: string, version?: number, stepId?: string, pullStepComments?: boolean) : [CalcBuilderComment[], boolean, string | null] {
        const [result, setResult] = useState([] as CalcBuilderComment[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as string | null);

        useEffect(() => {
            async function fetchData(calcNumber:string) {
                try {
                    setLoading(true);
                    const response = await apiService.getCalcStepComments(calcNumber, version == null ? -1 : version, stepId || '');
                    const json = await response.json();
                    setResult(json.comments);
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null && pullStepComments === true) {
                fetchData(calculationNumber);
            }
        }, [calculationNumber, pullStepComments]);
        return [result, loading, error]
    }
    
    saveStepComment(comment?: CalcBuilderComment) : [CalcBuilderComment | null, boolean, string | null] {
        const [result, setResult] = useState(null as CalcBuilderComment | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as string | null);

        useEffect(() => {
            async function postData(payload: CalcBuilderComment) {
                try {
                    setError(null);
                    const response = await apiService.createCalcStepComment(payload.calculationNumber, payload.calculationVersion as number, payload.stepId || '', payload.comment);
                    const json = await response.json();
                    setResult(json.comment);
                }
                catch (ex) {       
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (comment != null) {
                setLoading(true);
                postData(comment);
            }
        }, [comment]);
        return [result, loading, error]
    }

    getTPEntries(calculation?: CLI, pullEntries?: boolean) : [CalculationTPEEntry[], boolean, string | null] {
        const [result, setResult] = useState([] as CalculationTPEEntry[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null as string | null);

        useEffect(() => {
            async function fetchData(cli: CLI) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPEntries(cli.calculationNumber, cli.providerJurisdiction === "India");
                    const json = await response.json();
                    setResult(json.tpEntries.map((x: any) => mapToCalculationTPEEntry(x, cli)));
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculation != null && pullEntries) {
                fetchData(calculation);
            }
        }, [calculation, pullEntries]);
        return [result, loading, error]
    }
 }

function setCalcNumberInSteps(calculationNumber: string, steps: any[]) {
    steps.forEach(s => {
        s.calculationNumber = calculationNumber;
    })
}

function copySubStepsToSubRows(steps: any[]) {
    // copying items from substeps to subRows since React-grid looks for "subRows" property when rendering nested rows
    steps.forEach(s => {
        s.subRows = s.substeps;
        if (s.subRows) {
            const subRows = s.subRows as any[];
            subRows.forEach(sub => {
                sub.isSubstep = true;
            })
        }
    })
}

function formatBreakdownValues(runResults: any[]) {
    const formattingService = new FormattingService();
    runResults.forEach((r) => {
        const formattedBreakdown: string[] = [];
        r.valueBreakdown.split(' ').forEach((b: string) => {
            if (b != '') {
                if (!isNaN(+b)) {
                    formattedBreakdown.push(formattingService.formatString(b, true, 10));
                } else {
                    formattedBreakdown.push(b);
                }
            }
        });
        r.valueBreakdown = formattedBreakdown.join(' ');
    })
}
function mapToCalculationTPEEntry(x: any, cli: CLI) : CalculationTPEEntry | null {
    if (x == null){
        return null;
    }
    return {
        beatQualifier: x.beatQualifier,
        name: x.name,
        value: getTPEntryValue(x,cli),
        category: 'TP Charge'
    }
}

function getTPEntryValue(entry: any, cli: CLI): string | undefined {
    switch(entry.name){
        case "Apttus Markup Rate": return cli.tpRate == null || isNaN(+cli.tpRate)? "0" : (parseFloat(cli.tpRate)/100).toString();
        default: return entry.value == null? undefined : "" + entry.value;
    }
}

