import { Checkbox } from '@amzn/awsui-components-react';
import React from 'react';
import 'src/assets/styles/react-table.scss';
import { TPEReactTable } from 'src/components/shared/TPEReactTable';
import { TPERowSelectableReactTable } from 'src/components/shared/TPERowSelectableReactTable'
import CDTSelectableValue from 'src/models/custom-data-tables/CDTSelectableValue';




export default function CDTRowValuesSelectorGrid(params: { 
    data: any[], 
    showFilters: boolean, 
    isSearching: boolean,
    isEditable: boolean,
    allowMultipleValueSelection: boolean,
    selectionCount: number,
    isSearchingMessage?: string
    className?: string
    columDefinitions: Array<any>,
    onSortBy: (e: Array<any>) => any, 
    onFilter: (e: Array<any>) => any,
    onValueSelectedChanged: (e: any | null, index: number) => any,
}) {
    const { data, columDefinitions, showFilters, isSearching, isSearchingMessage, className, allowMultipleValueSelection, selectionCount, isEditable,
        onSortBy, onFilter, onValueSelectedChanged } = params;
    const [navigateURL, setNavigateURL] = React.useState('');
    const [lastValueSelectionChangeTime, setLastValueSelectionChangeTime] = React.useState(new Date());

    // columnDefinitions get refreshed everytime lastValueSelectionChangeTime changes
    const columnDefs = React.useMemo(() => columDefinitions.map(x => {
        return {
            ...x,
            Header: (e: any) => <div className="cell-text">{x.Header}</div>,
            Cell: (e: any) => {
                const selectableValue = e.value as CDTSelectableValue;
                return <div className={`cell-text ${x.hasSelectableValue? "selectable-cell" : ""}`}>
                            {x.hasSelectableValue && selectableValue != null? 
                                <Checkbox checked={selectableValue.isSelected} onChange={({ detail }) => {
                                    if (!isEditable || (!allowMultipleValueSelection && detail.checked && selectionCount >= 1)){
                                        return;
                                    }
                                    selectableValue.isSelected = detail.checked;
                                    onValueSelectedChanged(e.row.original, e.row.index);
                                    // Refresh the grid
                                    setLastValueSelectionChangeTime(new Date());
                                }}>{e.value.formattedValue}</Checkbox> :
                                e.value
                            }
                        </div>
            }
        }
    }),[lastValueSelectionChangeTime, isEditable, columDefinitions]);

    const handleRowSelection = (isChecked:boolean, row:any, index:number) => {
        Object.keys(row).forEach(k => {
            if (row[k] instanceof CDTSelectableValue){
                const value = row[k] as CDTSelectableValue;
                value.isSelected = isChecked;
                row[k] = value;
            }
        })
        onValueSelectedChanged(row, index);
        // Refresh the grid
        setLastValueSelectionChangeTime(new Date());
    };


    // Render the UI for your table
    return (
        <div>
            {isEditable && allowMultipleValueSelection? 
                <TPERowSelectableReactTable {...{ 
                    data, columnDefinitions: columnDefs, className: `nonGridTable ${className??"cdtRowValuesTable"}`, isSearching, 
                    sortable: false, showFilters, onFilter, onRowClicked:handleRowSelection, 
                    allowMultipleRowSelection: allowMultipleValueSelection, isSearchingMessage
                    }} /> :
                <TPEReactTable {...{ 
                    data, columnDefinitions: columnDefs, className: `nonGridTable ${className??"cdtRowValuesTable"}`, isSearching, 
                    sortable: false, showFilters, isSearchingMessage
                    }} />
            }
        </div>
    )
}


