import { Journal } from 'src/models/calculation-builder/Journal';
import CONSTANTS from 'src/utils/constants';
import TPEAction from '../../models/common/TPEAction';
import { JESettingsState } from './JESettingsState';
import DateUtils from 'src/utils/dateUtils';

/**
 * List here the actions supported by this reducer
 */
export const ACTIONS = Object.freeze({
    SET_JOURNALS: new TPEAction('SET_JOURNALS'),
    UPDATE_JOURNAL_ATTRIBUTE: new TPEAction('UPDATE_JOURNAL_ATTRIBUTE'),
    SET_JOURNALS_NEED_VALIDATION: new TPEAction('SET_JOURNALS_NEED_VALIDATION'),
    SET_JOURNALS_ARE_VALID: new TPEAction('SET_JOURNALS_ARE_VALID'),
    SET_JOURNALS_ARE_VALID_IN_IPE: new TPEAction('SET_JOURNALS_ARE_VALID_IN_IPE'),
    SET_VALIDATION_ERRORS: new TPEAction('SET_VALIDATION_ERRORS'),
    EXECUTE_CLI: new TPEAction('EXECUTE_CLI'), // Marker action
    ADD_INDIRECT_TAX: new TPEAction('ADD_INDIRECT_TAX'), // Marker action
    GENERATE_REVIEW_JOURNALS: new TPEAction('GENERATE_REVIEW_JOURNALS'), // Marker action
    TRIGGER_WORKFLOW: new TPEAction('TRIGGER_WORKFLOW'),
    SAVE_JOURNALS: new TPEAction('SAVE_JOURNALS'),
    TRIGGER_DRY_RUN: new TPEAction('TRIGGER_DRY_RUN'),
    RESET_WORKFLOW: new TPEAction('RESET_WORKFLOW'),
    RESET_VALIDATION: new TPEAction('RESET_VALIDATION'),
});

/**  
* This function is responsible for updating the state based on action type
* @param state The current dashboard state
* @param action The current dispatched action 
*/
export function jeSettingsReducer(state: JESettingsState, action: TPEAction): JESettingsState {
    switch (action.type) {
        case ACTIONS.SET_JOURNALS.type: {
            const journals = action.payload as Journal[];
            const taxJournals = journals.filter(x => x.journalType == CONSTANTS.JOURNAL_TYPES.AUTO_INDIRECT_TAX);
            let lastModifiedUser = '', lastModifiedDate = '';
            if (journals.length > 0 && journals[0].journalLines.length > 0) {
                lastModifiedUser = journals[0].journalLines[0].lastModifiedUser || 'N/A';
                if (journals[0].journalLines[0].lastModifiedDate != null) {
                    lastModifiedDate = DateUtils.formatTimestamp(journals[0].journalLines[0].lastModifiedDate);
                } else {
                    lastModifiedDate = 'N/A';
                }
            }
            return {
                ...state,
                journals: action.payload,
                taxJournalsAvailable: taxJournals != null && taxJournals.length > 0,
                lastModifiedUser,
                lastModifiedDate
            }
        }
        case ACTIONS.UPDATE_JOURNAL_ATTRIBUTE.type: {
            const { journalId, journalLineId, attribute, value } = action.payload;
            const journal = state.journals.find(x => x.journalId == journalId );
            if (journal != null) {
                const journalLine = journal.journalLines.find(x => x.journalLineId == journalLineId);
                if (journalLine != null) {
                    if ((journalLine as any)[attribute] !== value){
                        journalLine.isDirty = true;
                        if (journalLine.dirtyAttributes == null){
                            journalLine.dirtyAttributes = new Set<string>();
                        }
                        journalLine.dirtyAttributes.add(attribute);
                    }
                    (journalLine as any)[attribute] = value;
                    return {
                        ...state,
                        journals: [...state.journals],
                    }
                }
            }
            return state;
        }
        case ACTIONS.SET_JOURNALS_NEED_VALIDATION.type: {
            return {
                ...state,
                needsValidation: action.payload,
            }
        }
        case ACTIONS.SET_JOURNALS_ARE_VALID.type: {
            return {
                ...state,
                journalsAreValid: action.payload,
            }
        }
        case ACTIONS.SET_JOURNALS_ARE_VALID_IN_IPE.type: {
            return {
                ...state,
                journalsAreValidInIPE: action.payload,
            }
        }
        case ACTIONS.SET_VALIDATION_ERRORS.type: {
            return {
                ...state,
                validationErrors: action.payload,
            }
        }
        case ACTIONS.TRIGGER_WORKFLOW.type: {
            // 1. Validate local
            // 2. Save
            // 3. Dry run
            // 4. Execute CLI if applicable
            return {
                ...state,
                needsValidation: true,
                journalsAreValid: false,
                journalsAreValidInIPE: false,
                validationErrors: null as unknown as string[],
                generateTaxJournals: action.payload == ACTIONS.ADD_INDIRECT_TAX ? true : false,
                workflowAction: action.payload,
            }
        }
        case ACTIONS.SAVE_JOURNALS.type: {
            return {
                ...state,
                updateJournalsPayload: [...state.journals]
            }
        }
        case ACTIONS.TRIGGER_DRY_RUN.type: {
            return {
                ...state,
                dryRunTrigger: new Date().toString(),
            }
        }
        case ACTIONS.RESET_WORKFLOW.type: {
            return {
                ...state,
                workflowAction: undefined,
            }
        }
        case ACTIONS.RESET_VALIDATION.type: {
            return {
                ...state,
                journalsAreValid: false,
                journalsAreValidInIPE: false,
                validationErrors: null as unknown as string[],
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}