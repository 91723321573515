import React, { useEffect, useState, useContext } from 'react';
import { Box, SpaceBetween, Modal, Button, Alert } from '@amzn/awsui-components-react/polaris';
import { CalculationBuilderContext } from '../CalculationBuilderView';

export default function UnlinkFromTemplateModal (props: {
        visible: boolean,
        isUnlinking: boolean,
        unlinkError: string,
        onClose: () => void,
        onSubmit: () => any,
    }) {
    const { visible, isUnlinking, unlinkError, onClose, onSubmit } = props;

    const { calcBuilderState } = useContext(CalculationBuilderContext);
    const { calculationTemplateMetaData } = calcBuilderState;
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (visible) {
            setShowError(false);
        }
    }, [visible])

    useEffect(() => {
        if (isUnlinking) {
            setShowError(true);
        }
    }, [isUnlinking])

    return <Modal
                className="unlinkConfirmationModal"
                visible={visible}
                onDismiss={onClose}
                size="medium"
                footer={
                    <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" disabled={isUnlinking} className="unlinkModalCancelBtn" onClick={onClose}>No</Button>
                        <Button variant="primary" disabled={isUnlinking} className="unlinkModalOkBtn" onClick={onSubmit}>{isUnlinking? "Unlinking..." : "Yes"}</Button>
                    </SpaceBetween>
                    </Box>
                }
                header="Unlink From Template"
            >
                <SpaceBetween direction="vertical" size="xs">
                    <div>Are you sure you want to unlink this calculation from template <strong>{calculationTemplateMetaData?.templateName}</strong>?</div>
                    <Alert type="error" className="unlinkModalAlert" visible={showError && unlinkError != null && unlinkError != ''}>{unlinkError}</Alert>
                </SpaceBetween>
            </Modal>
}