import { Popover, Icon, Link } from '@amzn/awsui-components-react/polaris';
import { Autosuggest, Input, Select } from '@amzn/awsui-components-react';
import { eachMonthOfInterval, format, addYears, subYears } from 'date-fns';
import React, {useState, useContext, useMemo} from 'react';
import 'src/assets/styles/react-table.scss';
import { DefaultColumnFilter, TPEReactTable } from 'src/components/shared/TPEReactTable'
import { ACTIONS } from 'src/services/custom-data-tables/CustomDataTableRecordsRecucer';
import CONSTANTS from 'src/utils/constants';
import StringUtils from 'src/utils/stringUtils';
import { CustomDataTableRecordsContext } from './RecordsView';
import ReactTableFilter from "src/models/common/ReactTableFilter";

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: { id },
    onRowUpdated, // This is a custom function that we supplied to our table instance
    editorsData, // Editors data contains data needed to render the editors
}: { value: any, initialValue: any, row: any, column: any, onRowUpdated: any, editorsData: any, onCellUpdated: any }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    const [cliOptions, setCLIOptions] = useState([]);

    const handleCLILoadItems = (event: any) => {
        let { filteringText } = event.detail;

        filteringText = trimLeadingZeros(filteringText).toUpperCase();

        if (StringUtils.isNullOrEmpty(filteringText) || filteringText.length < 2) {
            setCLIOptions([]);
            return;
        }

        const filteredOptions = editorsData.clis.filter((x: string) =>
            x.startsWith("0000" + filteringText) ||
            x.startsWith("000" + filteringText) ||
            x.startsWith("00" + filteringText) ||
            x.startsWith("0" + filteringText) ||
            x.startsWith(filteringText)
        ).map((val: string) => { return {value: val} });
        setCLIOptions(filteredOptions || []);
    }

    const trimLeadingZeros = (st: string) => {
        let firstNonzero = 0;
        while (firstNonzero < st.length && st[firstNonzero] == '0') {
            firstNonzero++;
        }

        return st.slice(firstNonzero);
    }

    const getCellClass = () => {
        let columnAccessor = id;
        if (id.startsWith(CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.VALUE.ACCESSOR.toLowerCase())) {
            columnAccessor = 'values';
        } else if (id == CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.PERIOD.ACCESSOR) {
            columnAccessor = 'period';
        }
        return original.errors != null && (original.errors as Map<string, string>).has(columnAccessor) ? " dataSourcesInvalid dataSourcesButton" : " editableCell";
    }

    const onChange = (e: any) => {
        setValue(e.detail.value);
        onRowUpdated(index, id, e.detail.value);
    }

    const onSelectChanged = (e: any) => {
        setValue(e.detail.selectedOption.value)
        onRowUpdated(index, id, e.detail.selectedOption.value);
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        onRowUpdated(index, id, value);
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])


    const defaultSelectedOption = value == null ? null : { label: value, value };
    switch(id) {
        case CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.SELECT.ACCESSOR:
            if (original.errors && (original.errors as Map<string, string>).size > 0) {
                return <div key={`${id}_${index}`} className="textCell">
                    <Popover
                        data-class="basicSmallPopover"
                        dismissButton={false}
                        position="top"
                        triggerType="custom"
                        content={
                            <ul>
                                {Array.from((original.errors as Map<string, string>).values()).map((x) => <li key={x}>{x}</li>)}
                            </ul>
                        }
                    >
                        <Link><Icon name="status-warning" size="small" variant="warning"/></Link>
                    </Popover>
                </div>
            } else {
                return ' '
            }
        case CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CALCULATION_NUMBER.ACCESSOR:
            if (original.isNewRecord) {
                return <Select
                        className={`dataSourcesButton ${id}_${index}_input ` + getCellClass()}
                        onChange={onSelectChanged}
                        onBlur={onBlur}
                        options={cliOptions}
                        selectedOption={defaultSelectedOption}
                        placeholder="Enter Calculation Number"
                        empty="No matches found"
                        loadingText="Loading"
                        onLoadItems={handleCLILoadItems}
                        filteringType="manual"
                        statusType={cliOptions == undefined ? 'loading' : 'finished'}
                    />
            } else {
                return <div key={`${id}_${index}`} className={`textCell ${id}_${index}_cell`}>{value}</div>
            }
        case CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CURRENCY.ACCESSOR:
            const currencyOptions = [...editorsData.currencyCodes.map((x: string) => ({ label: x, value: x }))];
            return <Select key={`${id}_${index}`} filteringType="auto" className={"dataSourcesButton " + `${id}_${index}_input` + getCellClass()} placeholder="Select" onChange={onSelectChanged} selectedOption={defaultSelectedOption} options={currencyOptions} />
        case CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CLASSIFICATION.ACCESSOR:
            return <Input key={`${id}_${index}`} className={getCellClass() + ` ${id}_${index}_input`} placeholder="Enter Classification" value={value} onChange={onChange} onBlur={onBlur} />
        case CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CADENCE.ACCESSOR:
            const cadenceOptions = [...editorsData.cadences.map((x: string) => ({ label: x, value: x }))];
            return <Select key={`${id}_${index}`} filteringType="auto" className={"dataSourcesButton " + `${id}_${index}_input` + getCellClass()} placeholder="Select" onChange={onSelectChanged} selectedOption={defaultSelectedOption} options={cadenceOptions} />
        case CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.PERIOD.ACCESSOR:
            if (original.isNewRecord) {
                const periodOptions = [...editorsData.periods.map((x: string) => ({ label: x, value: x }))];
                return <Select key={`${id}_${index}`} filteringType="auto" className={"dataSourcesButton " + `${id}_${index}_input` + getCellClass()} placeholder="Select" onChange={onSelectChanged} selectedOption={defaultSelectedOption} options={periodOptions} />
            } else {
                return <div key={`${id}_${index}`} className={`textCell ${id}_${index}_cell`}>{original.periodFormatted}</div>
            }
        case CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.ACTIVE.ACCESSOR:
            const statusOptions = [...editorsData.statuses.map((x: string) => ({ label: x, value: x }))];
            return <Select key={`${id}_${index}`} filteringType="auto" className={"dataSourcesButton " + `${id}_${index}_input` + getCellClass()} placeholder="Select" onChange={onSelectChanged} selectedOption={defaultSelectedOption} options={statusOptions} />
        case CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.UPDATED_BY.ACCESSOR:
            return <div key={`${id}_${index}`} className={`textCell ${id}_${index}_cell`}>{StringUtils.isNullOrEmpty(value) ? "N/A" : value}</div>
        case CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.LAST_UPDATED_DATE.ACCESSOR:
            return <div key={`${id}_${index}`} className={`textCell ${id}_${index}_cell`}>{StringUtils.isNullOrEmpty(original.lastUpdatedDateDisplay) ? "N/A" : original.lastUpdatedDateDisplay}</div>
        default:
            return <Input key={`${id}_${index}`} className={getCellClass() + ` ${id}_${index}}_input`} placeholder="Enter Value" value={value} onChange={onChange} onBlur={onBlur} />
}
    
}

// Set our editable cell renderer as the default Cell renderer
const defaultCellEditor = {
    Cell: EditableCell,
}

function CDTRecordColumnFilter(event: {
    column: { filterValue: any, preFilteredRows: any, setFilter: any, minimumFilterValueLength: number },
}) {
    const { column } = event;
    const { filterValue, setFilter } = column;
    const value = filterValue || '';
    return (

        <input
            value={value}
            className={value.length > 0 && value.length < column.minimumFilterValueLength ? "defaultFilter invalidFilter" : "defaultFilter"}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search records...`}
        />
    )
}

export default function RecordsGrid(props: {loading: boolean, editable: boolean, tableKey: number, showFilters: boolean, onSortBy?: (e: Array<any>) => void, onFilter: (e: Array<ReactTableFilter>) => void}) {
    const { loading, tableKey, showFilters, onSortBy, onFilter, editable } = props;
    const { state, dispatch } = useContext(CustomDataTableRecordsContext);
    const { searchResult, currencyCodes = [], cliLov } = state;

    const getColDefs = () => {
        const colDefs: any[] = [];
        searchResult?.rowsInfo.columnDefinitions.forEach(x => {
            const colDef = {
                ...x,
                Header: (e: any) => <div className="cell-text">{x.Header}</div>,
                suppressSorting: x.accessor == CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.SELECT.ACCESSOR || x.isUserDefined,
                suppressFiltering: [CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.SELECT.ACCESSOR, CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.LAST_UPDATED_DATE.ACCESSOR].includes(x.accessor) || x.isUserDefined,
                Filter: CDTRecordColumnFilter,
                hidePopover: x.accessor !== CONSTANTS.CUSTOM_DATA_TABLE_RECORD_FIELDS.CALCULATION_NUMBER.ACCESSOR
            }
            if (!editable) {
                (colDef as any)['Cell'] = (e: any) => <div className="textCell">{e.value}</div>
            }
            colDefs.push(colDef);
        });
        return colDefs;
    }

    const populatePeriods = () => {
        const periods: string[] = [];

        eachMonthOfInterval({
            start: subYears(new Date(), 1),
            end: addYears(new Date(), 1)
        }).reverse().forEach(x => (periods.push(format(x, CONSTANTS.CUSTOM_DATA_TABLE_PERIOD_DISPLAY_FORMAT).toUpperCase())));

        return periods;
    }

   const colDefs = useMemo(getColDefs, [searchResult?.rowsInfo, editable]);
   const periods = useMemo(populatePeriods, []);

    const updateCustomDataTableRow = (rowIndex: number, attribute: string, value: string) => {
        dispatch(ACTIONS.UPDATE_CUSTOM_DATA_TABLE_RECORD.withPayload({
            rowIndex, attribute, value
        }));
    }

    const onFilterChanged = function (filters: Array<ReactTableFilter>) {
        if (filters == null || filters.length === 0) {
            onFilter([]);
            return;
        }
        const allFiltersHaveValidValue = filters.every(x => {
            const column = colDefs.find(c => c.accessor === x.id);
            return x.value.length >= column?.minimumFilterValueLength;
        });
        if (!allFiltersHaveValidValue) {
            return;
        }
        onFilter(filters);
    }

    return (
        <div className="customDataTableRecordsGrid">
            <TPEReactTable {...{
                data: searchResult?.rowsInfo.records,
                columnDefinitions: colDefs,
                className: "nonGridTable customDataTableRecordsGrid",
                isSearching: loading,
                sortable: true,
                highlightErrorCells: false,
                showFilters: showFilters,
                onFilter: onFilterChanged,
                key:tableKey,
                onSortBy: onSortBy,
                editableOptions: editable ? {
                    defaultColumn: defaultCellEditor,
                    editorsData: {
                        currencyCodes: currencyCodes,
                        cadences: CONSTANTS.CUSTOM_DATA_TABLE_CADENCES,
                        periods: periods,
                        statuses: CONSTANTS.CUSTOM_DATA_TABLE_ACTIVE_FLAGS,
                        clis: cliLov
                    },
                    onRowUpdated: updateCustomDataTableRow,
                    skipPageReset: true,
                } : null as unknown as any
            }} />
        </div>
    );
}