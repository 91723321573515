import React, { useState } from 'react';
import {
    StatusIndicator,
    Link,
} from '@amzn/awsui-components-react/polaris';
import 'src/assets/styles/react-table.scss';
import { TPEReactTable } from 'src/components/shared/TPEReactTable';
import { ErrorPopover, getBalancePullingStatus, getValueDisplay, RowContextMenu } from './DatasetGridCommon';
import CONSTANTS from 'src/utils/constants';
import { DatasetContext } from './DatasetContainer';
import { ACTIONS } from "src/services/tp-allocation/data-set/DatasetReducer";
import { DatasetRecord } from 'src/models/tp-allocation/DatasetRecord';



export default function DatasetSplitGrid() {
    const { services, datasetState, datasetDispatch: dispatch, tpAllocationState } = React.useContext(DatasetContext);
    const { datasetRecords, recordBeingEdited, showDecimals } = datasetState;
    const { viewMode } = tpAllocationState;
    const [formattedRecords, setFormattedRecords] = useState([] as DatasetRecord[]);

    React.useEffect(() => {
        if (datasetRecords == null) {
            return;
        }
        let records = recordBeingEdited == undefined ? [] : datasetRecords.slice(datasetRecords.indexOf(recordBeingEdited) + 1);
        const startIndex = recordBeingEdited == undefined ? 0 : datasetRecords.indexOf(recordBeingEdited) + 2;
        setFormattedRecords(records.map((x, index) => {
            return {
                ...x,
                sequence: startIndex + index,
                fxRateFormatted: x.fxRate == null ? '-' : services.formattingService.formatString(x.fxRate, true), // Always show decimals for FX rate
                valueFormatted: x.value == null ? '-' : services.formattingService.formatString(String(x.value), showDecimals),
                enableDataKeyView: recordBeingEdited == null || (recordBeingEdited != null && !recordBeingEdited.isEditing),
            };
        }));
    }, [datasetRecords, showDecimals]);

    const toggleDataKeyView = (recordID: string) => {
        dispatch(ACTIONS.TOGGLE_DATA_KEY_VIEW.withPayload({recordID: recordID}));
    }

    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "sequence",
            Header: "#",
            Cell: (e: any) => <div className="cell-text sequenceCell">{e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DATA_SOURCE.ACCESSOR,
            Header: "Data source",
            Cell: (e: any) => <div className={"cell-text dataSourceCell"}>{e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR,
            Header: "Period",
            Cell: (e: any) => <div className={"cell-text periodCell"}>{e.row.original.customPeriod != null ? e.row.original.customPeriod : e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR,
            Header: "FX date",
            Cell: (e: any) => <div className={"cell-text fxDateCell"}>{e.value == null ? null : e.value.split('-').join('/')}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.FX_TYPE.ACCESSOR,
            Header: "FX type",
            Cell: (e: any) => <div className={"cell-text fxTypeCell"}>{e.value}</div>
        },
        {
            accessor: `${CONSTANTS.DATA_SOURCE_FIELDS.FX_RATE.ACCESSOR}Formatted`,
            Header: "FX rate",
            Cell: (e: any) => <div className={"cell-text fxRateCell"}>{e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.CURRENCY.ACCESSOR,
            Header: "Currency",
            Cell: (e: any) => <div className={"cell-text currencyCell"}>{e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR,
            Header: "Data keys",
            Cell: (e: any) => e.row.original.enableDataKeyView ?
                                <Link className={"cell-text dataKeyViewLink" + e.row.original.datasourceId} onFollow={() => toggleDataKeyView(e.row.original.datasourceId)}><div className={"cell-text dataKeyCell"}>{e.row.original[CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR]}</div></Link>
                                :
                                <div className={"cell-text dataKeyCell"}>{e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DESCRIPTION.ACCESSOR,
            Header: "Description",
            Cell: (e: any) => <div className={"cell-text descriptionCell"}>{e.value}</div>
        },
        {
            accessor: `${CONSTANTS.DATA_SOURCE_FIELDS.VALUE.ACCESSOR}Formatted`,
            Header: "Value",
            Cell: (e: any) => <div className={"cell-text valueCell"}>{
                e.row.original.balancePullInProgress ? 
                getBalancePullingStatus(e.row.original)
                :
                getValueDisplay(e.value, e.row.original.lastBalancePullDate)}
                </div>
        },
        {
            accessor: "actions",
            Header: "Actions",
            Cell: (e: any) => <div className="rowActions">
                { 
                    e.row.original.pullBalanceError != null || (e.row.original.errors != null && (e.row.original.errors as Map<string, string>).size > 0) ? 
                        <ErrorPopover error={e.row.original.pullBalanceError || e.row.original.errors} />
                    :
                    (e.row.original.showSavedIndicator ? 
                        <StatusIndicator data-class="smallStatusIndicator" type="success">Saved</StatusIndicator>
                        : 
                        <span>&nbsp;</span> 
                    )
                }
                { CONSTANTS.VIEW_MODE.EDITABLE === viewMode && <div className='contextMenuPosition'><RowContextMenu row={e.row.original} allowEditActions={true} dispatch={dispatch} recordBeingEdited={recordBeingEdited} /></div> }
            </div>
        }
    ], [showDecimals, recordBeingEdited, viewMode]);

    return (
        <div className="dataSourceTableContainer splitGrid">
            {formattedRecords.length > 0 &&
                <TPEReactTable {...{
                    data: formattedRecords,
                    columnDefinitions,
                    className: "dataSources",
                    sortable: false,
                    showHeader: false,
                }} />
            }
        </div>
    );
}