import React, { useEffect } from "react";
import { TPEReactTable } from "src/components/shared/TPEReactTable";
import { SpaceBetween } from "@amzn/awsui-components-react/polaris";
import { ReadOnlyCalculationContext } from "src/components/calculations/ReadOnlyCalculationView";
import { ReadOnlyCalculationRecord } from "src/models/calculations/ReadOnlyCalculationRecord";
import { ACTIONS } from "src/services/calculations/ReadOnlyCalculationReducer";
import ArrayUtils from "src/utils/arrayUtils";
import { ExpressionPart } from "src/models/calculations/CalculationDetailsResult";



function renderExpressionParts(expressionParts: ExpressionPart[]) {
    return <SpaceBetween size={"xxxs"} direction={"horizontal"}>
        {expressionParts.map(
            (part, index) => <div key={index} className={`readOnlyCalcExpression-${part.type}`}>{part.value}</div>
        )}
    </SpaceBetween>;
}




export default function ReadOnlyCalculationView_StepsGrid() {
    const { services, state, dispatch } = React.useContext(ReadOnlyCalculationContext);
    const { selectedCalculationNumber, selectedVersion, calculationSteps, isSearching } = state;
    const [formulas, isLoadingFormulas] = services.readOnlyCalculationService.getFormulas(selectedCalculationNumber, selectedVersion == null ? 0 : selectedVersion);

    useEffect(() => {
        if (selectedCalculationNumber == null || selectedVersion == null || ArrayUtils.isNullOrEmpty(formulas)) {
            return;
        }
        let data = [] as any[]
        formulas.forEach((f, index) => {
            const parts = services.readOnlyCalculationService.breakExpressionIntoParts(f.expression, [], [], new Set());
            const record = {
                sequence: index + 1,
                stepName: f.stepName,
                expression: f.expression,
                calculationOverview: renderExpressionParts(parts)
            } as ReadOnlyCalculationRecord
            data.push(record)
        })

        dispatch(ACTIONS.SET_CALCULATION_STEPS.withPayload(data))
    }, [formulas])

    const columnDefinitions = [
        {
            accessor: "sequence",
            Header: (e: any) => <div className="cell-text">Sequence</div>,
        },
        {
            accessor: "stepName",
            Header: (e: any) => <div className="cell-text">Step Name</div>,
        }, {
            accessor: "calculationOverview",
            Header: (e: any) => <div className="cell-text">Calculation Overview</div>,
            Cell: (e: any) => <div className="cell-expression-text">{e.value}</div>,

        },
    ];

    return (

        <TPEReactTable {...{
            isSearching: isLoadingFormulas,
            isSearchingMessage: "loading steps...",
            data: calculationSteps,
            columnDefinitions,
            className: "readOnlyCalculationSteps",
            sortable: false,
        }} />

    );
}


