import React, { useEffect, useState } from 'react';
import { Grid, Box, Select, SpaceBetween, Modal, FormField, Button, Input, DatePicker, Alert, Link } from '@amzn/awsui-components-react/polaris';
import { CustomWorklist, CustomWorklistStatuses, WorklistItem } from 'src/models/agreements/Worklist';
import { ACTIONS } from 'src/services/agreements/AgreementsReducer';
import { UserProfile } from 'src/models/users/UserProfile';
import CONSTANTS from 'src/utils/constants';
import KeyValuePair from 'src/models/common/KeyValuePair'
import ArrayUtils from 'src/utils/arrayUtils';
import { DbExpression, DbOperator, DbConjunctor } from 'src/models/common/TPESearchRequest';
import { AgreementsContext } from './AgreementsView';
import moment from 'moment';

export default function AgreementsWorklists(props: {
    userProfile: UserProfile,
    shouldShowAgreementsTab: boolean,
    onCustomWorklistSubmitted: (e: CustomWorklist | WorklistItem, status: CustomWorklistStatuses) => any,
    onWorklistSelected: (e?: WorklistItem) => any,
}) {
    const { services, state, dispatch } = React.useContext(AgreementsContext);
    const { userProfile, shouldShowAgreementsTab, onWorklistSelected, onCustomWorklistSubmitted } = props;
    const { defaultWorklists, customWorklists, selectedWorklist, customWorklistMessage, customWorklistAddedDate,
        customWorklistUpdatedDate, isLoading, isSearching, customWorklistDeletedDate, customWorklistsNeedSaving,
        calculationStatuses, accountingOwners, taxOwners, savingMode, agreementStatusCategories, selectedTab, workbooks
    } = state;
    const BLANK_WORKLIST_OPTION = { label: "BLANK", value: "BLANK" };
    const NEW_WORKLIST_OPTION = { label: "Add new worklist", value: "NEW_WORKLIST", iconName: "add-plus" };
    const { WORKLIST_FILTER_FIELDS } = CONSTANTS;

    const [isSaveWorklistsSuccessful, isSavingWorklists, saveWorklistsError] = services.worklistService.saveCustomWorklists(customWorklistsNeedSaving, customWorklists, userProfile);

    const [customWorklistsResult, worklistsResultLoading, worklistsResultError] = services.worklistService.getCustomWorklists(userProfile);
    const [defaultWorklistsResult, defaultWorklistsResultLoading, defaultWorklistsResultError] = services.worklistService.getDefaultWorklists();

    const [
        customWorklistSelected,
        setSelectedCustomWorklist
    ] = useState(BLANK_WORKLIST_OPTION);

    const [
        customWorklistModel,
        setCustomWorklistModel
    ] = useState({ status: CustomWorklistStatuses.NEW } as CustomWorklist);

    const [showCustomWorklistModal, setShowCustomWorklistModal] = useState(false);
    const [agreementStatusError, setAgreementStatusError] = useState("");
    const [worklistNameError, setWorklistNameError] = useState("");
    const [effectiveDateError, setEffectiveDateError] = useState("");

    useEffect(() => {
        if (selectedWorklist == null) {
            return;
        }
        if (!selectedWorklist.isCustom) {
            setSelectedCustomWorklist(BLANK_WORKLIST_OPTION);
        }
    }, [selectedWorklist]);

    
    useEffect(() => {
        if (!customWorklistsNeedSaving) {
            return;
        }
        if (savingMode === "ADDING") {
            dispatch(ACTIONS.ADDED_CUSTOM_WORKLIST);
        }
        else if (savingMode === "UPDATING") {
            dispatch(ACTIONS.UPDATED_CUSTOM_WORKLIST);
        }
        else if (savingMode === "DELETING") {
            dispatch(ACTIONS.DELETED_CUSTOM_WORKLIST);
        }
    }, [customWorklistsNeedSaving]);



    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(defaultWorklistsResult)) {
            return;
        }

        dispatch(ACTIONS.SET_DEFAULT_WORKLISTS.withPayload(defaultWorklistsResult));
    }, [defaultWorklistsResult]);

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(customWorklistsResult)) {
            return;
        }
        dispatch(ACTIONS.SET_CUSTOM_WORKLISTS.withPayload(customWorklistsResult));
    }, [customWorklistsResult]);

    

    useEffect(() => {
        if (customWorklistAddedDate == null) {
            return;
        }

        dispatch(ACTIONS.SET_CUSTOM_WORKLIST_MESSAGE.withPayload({
            visible: true,
            type: "success",
            message: "Worklist has been created"
        }));
        let model = { ...customWorklistModel, status: CustomWorklistStatuses.CREATED };
        setCustomWorklistModel(model);
    }, [customWorklistAddedDate]);

    useEffect(() => {
        if (customWorklistUpdatedDate == null) {
            return;
        }
        dispatch(ACTIONS.SET_CUSTOM_WORKLIST_MESSAGE.withPayload(
            {
                type: "success",
                message: "Your worklist has been updated."
            }));
        updateWorklistModel('status', CustomWorklistStatuses.UPDATED);
    }, [customWorklistUpdatedDate]);

    useEffect(() => {
        if (customWorklistDeletedDate == null) {
            return;
        }
        updateWorklistModel('status', CustomWorklistStatuses.DELETED);
        dispatch(ACTIONS.SET_CUSTOM_WORKLIST_MESSAGE.withPayload({
            visible: true,
            type: "success",
            message: "Worklist has been successfully deleted from your list."
        }));
    }, [customWorklistDeletedDate]);



    const hideModal = function () {
        setShowCustomWorklistModal(false);
        clearCustomWorklistMessage();
    }

    const hideAndResetModal = function () {
        setShowCustomWorklistModal(false);
        setSelectedCustomWorklist(BLANK_WORKLIST_OPTION);
        clearCustomWorklistMessage();
        dispatch(ACTIONS.SET_SELECTED_WORKLIST.withPayload(undefined))
    }

    const showErrorMessage = function (message: string, header?: string) {
        dispatch(ACTIONS.SET_CUSTOM_WORKLIST_MESSAGE.withPayload({
            visible: true,
            type: "error",
            header: header,
            message: message
        }))
    }



    const validateAndBuildFilters = function () {
        if (customWorklistModel.name == null || customWorklistModel.name === "") {
            showErrorMessage("Worklist name is required");
            setWorklistNameError("Worklist name is required");
            return null;
        }
        if (customWorklistModel.effectiveDateFrom_ms != null && customWorklistModel.effectiveDateTo_ms != null && customWorklistModel.effectiveDateFrom_ms > customWorklistModel.effectiveDateTo_ms) {
            showErrorMessage("Effective date range is invalid");
            setEffectiveDateError("Effective date range is invalid");
            return null;
        }
        const worklists = defaultWorklists.concat(customWorklists);

        if (worklists.find(x => x.id !== customWorklistModel.id && x.name === customWorklistModel.name) != null) {
            showErrorMessage("Duplicate worklist Name");
            setWorklistNameError("Duplicate worklist Name");
            return null;
        }

        const filters = new Array<DbExpression>();
        const model = customWorklistModel as any;
        Object.keys(WORKLIST_FILTER_FIELDS).forEach(fieldKey => {
            const field = (WORKLIST_FILTER_FIELDS as any)[fieldKey] as KeyValuePair;

            if (model[field.key] != null && model[field.key] !== "") {
                let value = model[field.key];
                if (field.key === WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE_FROM.key || field.key === WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE_TO.key){
                    value = '' + model[`${field.key}_ms`];
                    filters.push({
                        attributeName: WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE.key,
                        operator: field.key === WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE_FROM.key? DbOperator.GREATER_THAN_EQUALS : DbOperator.LESS_THAN_EQUALS,
                        values: [value],
                        date: true,
                    });
                }
                else {
                    filters.push({
                        attributeName: field.key,
                        operator: DbOperator.EQUALS,
                        values: [value]
                    });
                }
            }
        });
        if (filters.length === 0) {
            showErrorMessage("Please select at least one field")
            return null;
        }

        for (let i = 0; i < worklists.length; i++) {
            const worklist = worklists[i];
            let hasTheSameFilters = true;

            
            // Skiping itself
            if (worklist.id === model.id) {
                continue;
            }
            const worklistExpressions = worklist.filterDefinition.filterExpression.expressions;

            for (let j = 0; j < filters.length; j++) {
                const filter = filters[j];
                const found = worklistExpressions.find(x => (x as DbExpression).attributeName === filter.attributeName);
                if (found == null) {
                    hasTheSameFilters = false;
                    continue;
                }
                if (JSON.stringify((found as DbExpression).values) !== JSON.stringify(filter.values)) {
                    hasTheSameFilters = false;
                    continue;
                }
            }
            if (hasTheSameFilters && filters.length === worklistExpressions.length) {
                showErrorMessage(`Worklist with the selected parameters already available: "${worklist.name}"`, "Duplicate worklist");
                return null;
            }
        }

        

        return filters;
    }

    const dispathCreateWorklist = function () {

        const expressions = validateAndBuildFilters();
        if (expressions == null) {
            return;
        }
        const newWorklist = {
            createdDate: new Date(),
            name: customWorklistModel.name,
            id: `${userProfile.user}_worklist_${(customWorklists.length + 1)}`,
            isCustom: true,
            position: customWorklists.length + 1,
            filterDefinition: {
                filterExpression: {
                    conjunctor: DbConjunctor.AND,
                    expressions: expressions
                }
            },
        } as WorklistItem;
        userProfile.isSaved = false;
        onCustomWorklistSubmitted(newWorklist, CustomWorklistStatuses.NEW);
    }

    const dispathUpdateWorklist = function () {
        if (selectedWorklist == null) {
            return;
        }
        const expressions = validateAndBuildFilters();
        if (expressions == null) {
            return;
        }
        selectedWorklist.filterDefinition.filterExpression.expressions = expressions;
        selectedWorklist.name = customWorklistModel.name;
        userProfile.isSaved = false;

        onCustomWorklistSubmitted(selectedWorklist, CustomWorklistStatuses.UPDATING);
    }

    const dispathDeleteWorklist = function () {
        userProfile.isSaved = false;
        dispatch(ACTIONS.DELETE_CUSTOM_WORKLIST.withPayload(selectedWorklist?.id));
    }

    const updateWorklistModel = function (fieldName: string, value?: string) {
        const valueIsDate = fieldName === WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE_FROM.key || fieldName === WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE_TO.key;
        const updatedModel = {
            ...customWorklistModel,
            [fieldName]: value,
        };
        if (valueIsDate){
            const isStartDate = fieldName === WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE_FROM.key;
            const date = moment(value as string);
            (updatedModel as any)[`${fieldName}_ms`] = parseInt(isStartDate? date.startOf('day').format('x') : date.endOf('day').format('x'));
            setEffectiveDateError('');
            if (updatedModel.effectiveDateFrom_ms != null && updatedModel.effectiveDateTo_ms != null && updatedModel.effectiveDateFrom_ms > updatedModel.effectiveDateTo_ms) {
                setEffectiveDateError("Effective date range is invalid");
            }

        }
        // Clearing agreement status when status category is selected
        updatedModel.agreementStatus = fieldName === WORKLIST_FILTER_FIELDS.AGREEMENT_STATUS_CATEGORY.key ? undefined : updatedModel.agreementStatus
        setCustomWorklistModel(updatedModel);
        if (fieldName !== "status") {
            clearCustomWorklistMessage();
        }
    }

    const loadSelectedWorklistIntoModal = function () {
        if (selectedWorklist == null) {
            return;
        }
        const model = {
            id: selectedWorklist.id,
            name: selectedWorklist.name,
            status: CustomWorklistStatuses.UPDATING
        } as any;
        selectedWorklist.filterDefinition.filterExpression.expressions.forEach(exp => {
            const expression = exp as DbExpression
            // Custom worklists don't hold multiple values.
            // That's why we are getting the first value here.
            
            if (expression.date) {
                const rangePrefix = expression.operator === DbOperator.GREATER_THAN_EQUALS? 'From' : 'To';
                const attributeName = `${expression.attributeName}${rangePrefix}`;
                model[attributeName] = moment(expression.values[0], "x").format('YYYY-MM-DD');
                model[`${attributeName}_ms`] = parseInt(expression.values[0]);
            }
            else {
                model[expression.attributeName] = expression.values[0];
            }
        });
        clearCustomWorklistMessage();
        setCustomWorklistModel(model);
        setShowCustomWorklistModal(true);
    }


    const handleDeletingWorklist = function () {
        showErrorMessage("This action cannot be undone", "Are you sure you want to delete this worklist?");
        updateWorklistModel('status', CustomWorklistStatuses.DELETING);
    }


    const handleCancelDeleting = function () {
        clearCustomWorklistMessage()
        updateWorklistModel('status', CustomWorklistStatuses.UPDATING);
    }

    const clearCustomWorklistMessage = () => dispatch(ACTIONS.SET_CUSTOM_WORKLIST_MESSAGE.withPayload(null));

    const footerButtons = () => {
        switch (customWorklistModel.status) {
            case CustomWorklistStatuses.NEW:
                return <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" className="cancelButton" onClick={hideModal}>Cancel</Button>
                    <Button variant="primary" className="applyButton" loading={isSavingWorklists as boolean} onClick={dispathCreateWorklist}>Create Worklist</Button>
                </SpaceBetween>;
            case CustomWorklistStatuses.CREATED:
                return <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" className="cancelButton" onClick={hideModal}>Cancel</Button>
                    <Button variant="primary" className="applyButton" onClick={hideModal}>Close</Button>
                </SpaceBetween>;
            case CustomWorklistStatuses.UPDATING:
                return <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" className="cancelButton" onClick={hideModal}>Cancel</Button>
                    <Button variant="link" className="deleteButton" onClick={handleDeletingWorklist}>Delete</Button>
                    <Button variant="primary" className="applyButton" loading={isSavingWorklists as boolean} onClick={dispathUpdateWorklist}>Save</Button>
                </SpaceBetween>;
            case CustomWorklistStatuses.UPDATED:
                return <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" className="cancelButton" onClick={hideModal}>Cancel</Button>
                    <Button variant="link" className="deleteButton" onClick={handleDeletingWorklist}>Delete</Button>
                    <Button variant="primary" className="applyButton" onClick={hideModal}>Close</Button>
                </SpaceBetween>;
            case CustomWorklistStatuses.DELETING:
                return <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" className="cancelButton" onClick={handleCancelDeleting}>Cancel</Button>
                    <Button variant="primary" className="applyButton" onClick={hideModal}>Close</Button>
                </SpaceBetween>
            case CustomWorklistStatuses.DELETED:
                return <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" className="cancelButton" onClick={hideModal}>Cancel</Button>
                    <Button variant="primary" className="applyButton" onClick={hideAndResetModal}>Close</Button>
                </SpaceBetween>
        }
    };

    const customWorklistDdItems = [...customWorklists.map(x => ({ label: x.name, value: x.id })),
        NEW_WORKLIST_OPTION,
    ];

    const noneOption = { label: "(none, blank selection)", value: null as unknown as string };

    const agreementCategoriesDdOptions = [...agreementStatusCategories.map(x => ({ label: x.name, value: x.name }))];
    agreementCategoriesDdOptions.unshift(noneOption);

    const calculationStatusDdOptions = [...calculationStatuses.map(x => ({ label: x, value: x }))];
    calculationStatusDdOptions.unshift(noneOption);

    const agreementStatusDdOptions = [...agreementStatusCategories.find(x => x.name === customWorklistModel.agreementStatusCategory)?.subCategories.map(x => ({ label: x, value: x })) || []];
    agreementStatusDdOptions.unshift(noneOption);

    const workbooksDdOptions = [...workbooks.map(x => ({ label: x.workbookName, value: x.workbookName }))];
    workbooksDdOptions.unshift(noneOption);

    const taxOwnersDdOptions = [...taxOwners.map(x => ({ label: x, value: x }))];
    taxOwnersDdOptions.unshift(noneOption);

    const accountingOwnersDdOptions = [...accountingOwners.map(x => ({ label: x, value: x }))];
    accountingOwnersDdOptions.unshift(noneOption);

    
    return (
        <div className="topParentContainer">
            <div className="topContainer">
                <div className="topContainerTitle">
                    {shouldShowAgreementsTab ?
                    <Button
                        variant="normal"
                        data-class={selectedTab == CONSTANTS.WORKLIST_TABS.AGREEMENTS ? "activeTab" : "inactiveTab"}
                        onClick={e => {
                            dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.WORKLIST_TABS.AGREEMENTS));
                        }}
                    >{CONSTANTS.PAGE_NAV.AGREEMENTS.AGREEMENTS_TAB_NAME}</Button>
                    : null}
                    <Button
                        variant="normal"
                        data-class={selectedTab == CONSTANTS.WORKLIST_TABS.CALCULATIONS ? "activeTab" : "inactiveTab"}
                        onClick={e => {
                            dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.WORKLIST_TABS.CALCULATIONS));
                        }}
                    >{CONSTANTS.PAGE_NAV.AGREEMENTS.CALCULATIONS_TAB_NAME}</Button>
                    <Button
                        variant="normal"
                        data-class={selectedTab == CONSTANTS.WORKLIST_TABS.ICRS ? "activeTab" : "inactiveTab"}
                        onClick={e => {
                            dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.WORKLIST_TABS.ICRS));
                        }}
                    >{CONSTANTS.PAGE_NAV.AGREEMENTS.ICRS_TAB_NAME}</Button>
                </div>
                { selectedTab !== CONSTANTS.WORKLIST_TABS.ICRS && <Box className="boxLink">
                        <SpaceBetween size="l" direction="horizontal">
                            <Link className="launchSalesforce" external href={CONSTANTS.ENVIRONMENT_VARIABLES.SALESFORCE_HOME}>Launch Salesforce</Link>
                            {selectedWorklist == null || customWorklistSelected.label == BLANK_WORKLIST_OPTION.value || !selectedWorklist.isCustom ? null : <Link className="editCustomWorklist" onFollow={loadSelectedWorklistIntoModal}>Edit/Delete worklist</Link>}
                        </SpaceBetween>
                    </Box> 
                }
                { selectedTab !== CONSTANTS.WORKLIST_TABS.ICRS && <Select className="smallDropdown customWorklistsDropdown"
                        disabled={isLoading}
                        selectedOption={customWorklistSelected.label === BLANK_WORKLIST_OPTION.label ? null : { label: selectedWorklist?.name, value: selectedWorklist?.id }}
                        placeholder="My worklists"
                        options={customWorklistDdItems}
                        onChange={({ detail }) => {
                            const isNewWorklistOptionSelected = detail.selectedOption.value === NEW_WORKLIST_OPTION.value;

                            if (isNewWorklistOptionSelected) {
                                clearCustomWorklistMessage();
                                setCustomWorklistModel({ status: CustomWorklistStatuses.NEW } as CustomWorklist);
                            }
                            else {
                                const worklistItem = customWorklists.find(x => x.id === detail.selectedOption.value);
                                onWorklistSelected(worklistItem)
                                setSelectedCustomWorklist(detail.selectedOption as any);
                            }
                            setShowCustomWorklistModal(isNewWorklistOptionSelected);
                        }}
                        selectedAriaLabel="Selected"
                    /> 
                }
            </div>
            {isLoading ? null :
                <div className={selectedTab === CONSTANTS.WORKLIST_TABS.AGREEMENTS ? "worklistAgreementsWrapper" : "worklistCalculationsWrapper"}>
                    <div className="flexGap">
                        {
                            defaultWorklists.filter(x => x.type === selectedTab).map(w => (
                                <Button key={w.name}
                                    variant="normal"
                                    disabled={isSearching}
                                    className={w.isSelected == true ? "activeWorklist" : "inactiveWorklist"}
                                    onClick={e => {
                                        const worklistItem = defaultWorklists.find(x => w.name === x.name);
                                        onWorklistSelected(worklistItem);
                                    }}
                                >{w.name}</Button>
                            ))
                        }
                    </div>
                </div>
            }
            <Modal className="customWorklistModal"
                onDismiss={hideModal}
                visible={showCustomWorklistModal}
                closeAriaLabel="Close modal"
                size="large"
                footer={
                    <Box float="right">
                        {footerButtons()}
                    </Box>
                }
                header={customWorklistModel.status === CustomWorklistStatuses.NEW || customWorklistModel.status === CustomWorklistStatuses.CREATED ? "Create new worklist" : "Edit/Delete worklist"}
            >
                {customWorklistModel.status === CustomWorklistStatuses.DELETED ? <span /> : <div>
                    <Grid
                        gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                    >
                        <FormField label="Worklist name" errorText={worklistNameError}>
                            <Input className="worklistNameInput"
                                value={customWorklistModel.name || ''}
                                onChange={({ detail }) => {
                                    updateWorklistModel('name', detail.value);
                                    setWorklistNameError("");
                                }} />
                        </FormField>

                    </Grid>
                    <Grid
                        gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}
                    >
                        <SpaceBetween direction="vertical" size="l">

                            <FormField className="agreementStatusCategoryField" label={WORKLIST_FILTER_FIELDS.AGREEMENT_STATUS_CATEGORY.value}>
                                <Select className="agreementStatusCategoryDropdown"
                                    options={agreementCategoriesDdOptions}
                                    selectedOption={customWorklistModel.agreementStatusCategory == null ? null : { label: customWorklistModel.agreementStatusCategory, value: customWorklistModel.agreementStatusCategory }}
                                    onChange={({ detail }) => {
                                        updateWorklistModel(WORKLIST_FILTER_FIELDS.AGREEMENT_STATUS_CATEGORY.key, detail.selectedOption.value);
                                        setAgreementStatusError("");
                                    }}
                                ></Select>
                            </FormField>
                            <FormField className="agreementStatusField" label={WORKLIST_FILTER_FIELDS.AGREEMENT_STATUS.value} errorText={agreementStatusError}>
                                <Select className="agreementStatusDropdown"
                                    options={agreementStatusDdOptions}
                                    selectedOption={customWorklistModel.agreementStatus == null ? null : { label: customWorklistModel.agreementStatus, value: customWorklistModel.agreementStatus }}
                                    onChange={({ detail }) => updateWorklistModel(WORKLIST_FILTER_FIELDS.AGREEMENT_STATUS.key, detail.selectedOption.value)}
                                    onFocus={({ detail }) => {
                                        if (!customWorklistModel.agreementStatusCategory) {
                                            setAgreementStatusError("Choose an agreement status category")
                                        }
                                    }}
                                ></Select>
                            </FormField>
                            <FormField label={WORKLIST_FILTER_FIELDS.CALCULATION_STATUS.value}>
                                <Select
                                    selectedOption={customWorklistModel.calculationStatus == null ? null : { label: customWorklistModel.calculationStatus, value: customWorklistModel.calculationStatus }}
                                    onChange={({ detail }) => updateWorklistModel(WORKLIST_FILTER_FIELDS.CALCULATION_STATUS.key, detail.selectedOption.value)}
                                    options={calculationStatusDdOptions}
                                    selectedAriaLabel="Selected"
                                />
                            </FormField>
                            <FormField label={WORKLIST_FILTER_FIELDS.WORKBOOK_NAME.value}>
                                <Select
                                    selectedOption={customWorklistModel.workbookName == null ? null : { label: customWorklistModel.workbookName, value: customWorklistModel.workbookName }}
                                    onChange={({ detail }) => updateWorklistModel(WORKLIST_FILTER_FIELDS.WORKBOOK_NAME.key, detail.selectedOption.value)}
                                    options={workbooksDdOptions}
                                    filteringType="auto"
                                    virtualScroll
                                    selectedAriaLabel="Selected"
                                />
                            </FormField>
                        </SpaceBetween>
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label={WORKLIST_FILTER_FIELDS.TAX_OWNER.value}>
                                <Select
                                    selectedOption={customWorklistModel.taxOwner == null ? null : { label: customWorklistModel.taxOwner, value: customWorklistModel.taxOwner }}
                                    onChange={({ detail }) => updateWorklistModel(WORKLIST_FILTER_FIELDS.TAX_OWNER.key, detail.selectedOption.value)}
                                    options={taxOwnersDdOptions}
                                    selectedAriaLabel="Selected"
                                />
                            </FormField>
                            <FormField label={WORKLIST_FILTER_FIELDS.ACCOUNTING_OWNER.value}>
                                <Select
                                    selectedOption={customWorklistModel.accountingOwner == null ? null : { label: customWorklistModel.accountingOwner, value: customWorklistModel.accountingOwner }}
                                    onChange={({ detail }) => updateWorklistModel(WORKLIST_FILTER_FIELDS.ACCOUNTING_OWNER.key, detail.selectedOption.value)}
                                    options={accountingOwnersDdOptions}
                                    selectedAriaLabel="Selected"
                                />
                            </FormField>
                            <FormField className="effectiveDateError" label={WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE_FROM.value} stretch={true} errorText={effectiveDateError}>                            
                                <DatePicker
                                    className="fullWidthDatePicker effectiveDateFrom"
                                    onChange={({ detail }) => updateWorklistModel(WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE_FROM.key, detail.value)}
                                    value={customWorklistModel.effectiveDateFrom}
                                    openCalendarAriaLabel={selectedDate =>
                                        "Choose Date" +
                                        (customWorklistModel.effectiveDateFrom
                                            ? `, selected date is ${customWorklistModel.effectiveDateFrom}`
                                            : "")
                                    }
                                    nextMonthAriaLabel="Next month"
                                    placeholder="YYYY/MM/DD"
                                    previousMonthAriaLabel="Previous month"
                                    todayAriaLabel="Today"
                                />
                            </FormField>
                            <FormField label={WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE_TO.value} stretch={true} errorText={effectiveDateError}>                            
                                <DatePicker
                                    className="fullWidthDatePicker effectiveDateTo"
                                    onChange={({ detail }) => updateWorklistModel(WORKLIST_FILTER_FIELDS.EFFECTIVE_DATE_TO.key, detail.value) }
                                    value={customWorklistModel.effectiveDateTo}
                                    openCalendarAriaLabel={selectedDate =>
                                        "Choose Date" +
                                        (customWorklistModel.effectiveDateTo
                                            ? `, selected date is ${customWorklistModel.effectiveDateTo}`
                                            : "")
                                    }
                                    nextMonthAriaLabel="Next month"
                                    placeholder="YYYY/MM/DD"
                                    previousMonthAriaLabel="Previous month"
                                    todayAriaLabel="Today"
                                />
                            </FormField>
                        </SpaceBetween>
                    </Grid>
                </div>}
                <br />
                {(customWorklistMessage == null ? <br /> : <Alert

                    onDismiss={() => clearCustomWorklistMessage()}
                    visible={customWorklistMessage?.visible}
                    dismissAriaLabel="Close alert"
                    dismissible={customWorklistModel.status !== CustomWorklistStatuses.DELETING}
                    type={customWorklistMessage?.type as any}
                    header={customWorklistMessage?.header}
                >
                    <div>{customWorklistMessage?.message}
                        <br />
                        {customWorklistModel.status === CustomWorklistStatuses.DELETING ?
                            <div><br /><Button className="applyButton" loading={isSavingWorklists as boolean} onClick={dispathDeleteWorklist}>Delete</Button><br /></div> : null}
                    </div>
                </Alert>)}
                <br />
                <br />
                <br />
                <br />

            </Modal>
        </div>
    );
}