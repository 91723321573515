import { Box, Button, ColumnLayout, Container, Icon, Pagination, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import TPEAction from 'src/models/common/TPEAction';
import { CustomCOARow } from 'src/models/custom-coa/CustomCOADefinition';
import DynamicBreadcrumb from 'src/models/navigation/DynamicBreadcrumb';
import { ACTIONS, customCOARowsReducuer } from 'src/services/custom-coa/CustomCOARowsReducer';
import { CustomCOARowsState, defaultSearchPayload, initialState } from 'src/services/custom-coa/CustomCOARowsState';
import { GLOBAL_ACTIONS } from 'src/services/global/GlobalReducer';
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import CONSTANTS from 'src/utils/constants';
import StringUtils from 'src/utils/stringUtils';
import { GlobalAppContext } from '../App';
import TPEErrorWatcher from '../shared/TPEErrorWatcher';
import RowsGrid from './RowsGrid';
import RenameTableModal from './RenameTableModal';
import { TPELoadingSpinner } from '../shared/TPELoadingSpinner';
import { ACTION_TYPE, TPEBasicModal } from '../shared/TPEBasicModal';
import TPENavigator from '../TPENavigator';
import ReactTableFilter from "src/models/common/ReactTableFilter";
import { addCOARowsExpressionFilters, getDefaultSearchPayload } from "src/components/custom-coa/CustomCOASearchHelper";
import ArrayUtils from "src/utils/arrayUtils";
import { getPermissions } from '../AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';

export type ContextType = {
    state: CustomCOARowsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const CustomCOARowsProvider = (props: any) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <CustomCOARowsContext.Provider value={providerValue}>
            {children}
        </CustomCOARowsContext.Provider>
    );
}
export const CustomCOARowsContext = React.createContext(null as unknown as ContextType);


export default function RowsView(props: {services: ServiceCollection}) {
    const { services } = props;
    const [state, dispatch] = useReducerWithLogger(customCOARowsReducuer, initialState);
    const { searchPayload, searchResult } = state;

    const { pathname } = useLocation();
    const { encodedTableId } = useParams< { encodedTableId: string }>();
    const [tableId, setTableId] = useState(atob(encodedTableId));
    const [downloadTableId, setDownloadTableId] = useState('');
    
    const { globalDispatch } = useContext(GlobalAppContext);
    const { canEdit } = getPermissions(AppModules.CUSTOM_COA);

    const [selectedDetailTab, setSelectedDetailTab] = useState('details');
    const [tableName, setTableName] = useState('');
    const [tableStatus, setTableStatus] = useState('');
    const [coaSegment, setCoaSegment] = useState('');
    const [newRowsToSave, setNewRowsToSave] = useState<CustomCOARow[]>([]);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState(false);
    const [shouldDeleteCustomCOADefinition, setShouldDeleteCustomCOADefinition] = useState(false);
    const [shouldArchiveCustomCOADefinition, setShouldArchiveCustomCOADefinition] = useState(false);
    const [navigateUrl, setNavigateUrl] = useState('');

    const [searchCustomCoaRowsResult, searchCustomCoaRowsLoading, searchCustomCoaRowsError] = services.customCOAService.searchCustomCOADefinitionRows(searchPayload);
    const [coaLovResult, coaLovLoading, coaLovError] = services.dataSourcesService.getCoaData(coaSegment);
    const [downloadUrl, isDownloading, downloadError] = services.customCOAService.dowloadCustomCOADefinition(downloadTableId);
    const [saveResult, isSaving, saveError] = services.customCOAService.saveCustomCOADefinition(newRowsToSave, tableId);
    const [allowDeleteResult, isGettingFlag, allowDeleteFlagError] = services.customCOAService.getAllowDeleteFlag(tableId);
    const [deleteResult, isDeleting, deleteError] = services.customCOAService.deleteCustomCOADefinition(shouldDeleteCustomCOADefinition, tableId);
    const [archiveResult, isArchiving, archiveError] = services.customCOAService.archiveCustomCOADefinition(shouldArchiveCustomCOADefinition, tableId);

    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        const searchPayload = {...defaultSearchPayload, tableId: tableId};
        dispatch(ACTIONS.SET_SEARCH_PAYLOAD.withPayload(searchPayload));
    }, [tableId])

    useEffect(() => {
        if (searchCustomCoaRowsResult == null) {
            return;
        }
        setTableName(searchCustomCoaRowsResult.tableName);
        dispatch(ACTIONS.SET_SEARCH_RESULT.withPayload(searchCustomCoaRowsResult));
        globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, searchCustomCoaRowsResult.tableName, searchCustomCoaRowsResult.tableName)));
        const coaSegmentConstant = Object.values(CONSTANTS.COA_SEGMENT_MAPPING).find(x => x.UI == searchCustomCoaRowsResult.coaSegment);
        if (coaSegmentConstant != null) {
            setCoaSegment(coaSegmentConstant.WEB_API);
        }
        if (searchCustomCoaRowsResult.totalSize == 0) {
            dispatch(ACTIONS.ADD_NEW_ROW);
        }
    }, [searchCustomCoaRowsResult])

    useEffect(() => {
        if (coaLovResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_COA_LOV.withPayload(coaLovResult));
    }, [coaLovResult])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(downloadUrl)) {
            return;
        }
        window.open(downloadUrl);
        setDownloadTableId('');
    }, [downloadUrl])

    useEffect(() => {
        if (saveResult != null && !saveResult.errors && StringUtils.isNullOrEmpty(saveError)) {
            services.messageService.showSuccessAutoDismissBanner(`${searchResult?.tableName} successfully saved.`, 10000);
            const searchPayload = {...defaultSearchPayload, tableId: tableId};
            dispatch(ACTIONS.SET_SEARCH_PAYLOAD.withPayload(searchPayload));
        } else if (saveResult != null && saveResult.errors) {
            resetRowErrors();
        }
    }, [saveResult])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(saveError)) {
            services.messageService.showErrorAutoDismissBanner(saveError, 10000);
        }
    }, [saveError]);

    useEffect(() => {
        if (allowDeleteResult == null) {
            return;
        }
        setTableStatus(allowDeleteResult.status);
    }, [allowDeleteResult])

    useEffect(() => {
        if (deleteResult == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner(`Custom COA Definition ${tableName} successfully deleted.`, 10000);
        setNavigateUrl(CONSTANTS.PAGE_NAV.CUSTOM_COA.URL);
    }, [deleteResult])

    useEffect(() => {
        if (archiveResult == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner(`Custom COA Definition ${tableName} successfully archived.`, 10000);
        setTableStatus(CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED);
    }, [archiveResult])

    const saveEditedRows = () => {
        if (state.searchResult) {
            const rows = [...state.searchResult.rows];
            const rowsToSave = rows.filter(row => row.updatedRecord);
            if (rowsToSave.length > 0) {
                setNewRowsToSave(rowsToSave)
            }
        }
    }

    const resetRowErrors = () => {
        let currentSavedRow = 0;
        state.searchResult?.rows.forEach(row => {
            if (row.updatedRecord) {
                row.errors = new Map(Object.entries(saveResult.errors.get(currentSavedRow.toString()).errors));
                currentSavedRow++;
            } else {
                row.errors = undefined;
            }
        });
    }

    const getDeleteArchiveButtonLabel = () => {
        if (isGettingFlag) {
            return '';
        }
        if (isDeleting) {
            return 'Deleting...';
        }
        if (isArchiving) {
            return 'Archiving...';
        }
        
        return allowDeleteResult?.allowDeleteFlag ? 'Delete' : 'Archive'
    }

    const deleteOrArchive = () => {
        if (allowDeleteResult?.allowDeleteFlag) {
            setShowDeleteConfirmationModal(true);
        } else {
            setShowArchiveConfirmationModal(true);
        }
    }

    const showRenamedCustomCOADefinition = (newTableName: string) => {
        setShowRenameModal(false);
        setTableName(newTableName);
        globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, newTableName, newTableName)));
        services.messageService.showSuccessAutoDismissBanner(`Table successfully renamed to ${newTableName}.`, 10000);
    }

    // Using a local search state to be used as a temporary place to set the search filters before dispatching the API search
    const [localSearchFilter, setLocalSearchFilter] = React.useState([] as Array<ReactTableFilter>);
    React.useEffect(() => {
        if (!showFilters){
            return;
        }
        // Waiting 1.5 second before dispatching the API search
        const timeout = setTimeout(() => {
            const currentSorting = {sortOrder: searchPayload?.sortOrder, sortField: searchPayload?.sortField, tableId: searchPayload?.tableId};
            const newSearchPayload = {...getDefaultSearchPayload(), ...currentSorting};
            addCOARowsExpressionFilters(newSearchPayload, localSearchFilter);
            if (newSearchPayload.filterExpression != null && ArrayUtils.isNullOrEmpty(newSearchPayload.filterExpression?.expressions)){
                newSearchPayload.filterExpression = undefined;
            }
            dispatch(ACTIONS.SET_SEARCH_PAYLOAD.withPayload({...newSearchPayload}));
        }, 1500)

        return () => clearTimeout(timeout)
    }, [localSearchFilter]);

    return <CustomCOARowsProvider services={services} state={state} dispatch={dispatch}>
                <div className="customTableTopParentContainer">
                    <div className="customTableTopContainer">
                        <div className="customTableTopContainerTitle">
                            <Button
                                variant="link"
                                data-class={selectedDetailTab == 'details' ? 'activeTab' : 'inactiveTab'}
                                onClick={() => setSelectedDetailTab('details')}
                            >Details</Button>
                            <Button
                                variant="link"
                                data-class={selectedDetailTab == 'logs' ? 'activeTab' : 'inactiveTab'}
                                onClick={() => setSelectedDetailTab('logs')}
                            >Logs</Button>
                        </div>
                    </div>
                </div>

                <Container data-class="containerWithHeaderAndGrid">
                    <div>
                        { selectedDetailTab === 'details' ?
                            <React.Fragment>
                                <div className="customTableTableHeader">
                                    <ColumnLayout columns={2} data-class="fullColumnLayout">
                                        <Box variant="h2">
                                            {tableName == '' ?
                                                        ''
                                            :
                                                <SpaceBetween size="l" direction="horizontal">
                                                    <div>
                                                        <span>{tableName} </span>
                                                        <span className="customTablesRecordCounter">({searchResult?.totalSize})</span>
                                                    </div>
                                                    <div>
                                                        <span className="coaSegmentDisplay"><b> COA Segment: </b>{searchResult?.coaSegment}</span>
                                                    </div>
                                                </SpaceBetween>
                                            }
                                        </Box>
                                        <Box float="right">
                                            <SpaceBetween direction="horizontal" size="m">
                                               { canEdit && 
                                                    <React.Fragment>
                                                        <Button variant="normal" 
                                                            disabled={tableStatus == CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED || isGettingFlag || isDeleting || isArchiving} 
                                                            loading={isGettingFlag}
                                                            onClick={deleteOrArchive}
                                                        >
                                                            { getDeleteArchiveButtonLabel() }
                                                        </Button>
                                                        <Button variant="normal" disabled={tableStatus == CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED} onClick={() => setShowRenameModal(true)}>Rename</Button>
                                                    </React.Fragment>
                                                }
                                                <Button variant="normal" onClick={() => setDownloadTableId(searchResult?.tableId || '')} disabled={isDownloading}>
                                                    <SpaceBetween size="s" direction="horizontal">
                                                        <Icon name="download"/>
                                                        <span>{isDownloading ? 'Downloading...' : 'Download'}</span>
                                                    </SpaceBetween>
                                                </Button>
                                                { canEdit && <Button variant="primary"
                                                        onClick={saveEditedRows}
                                                        disabled={isSaving || tableStatus == CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED}>
                                                            {isSaving ? "Saving..." : "Save"}
                                                </Button> }
                                            </SpaceBetween>
                                        </Box>
                                        <Button
                                            className={showFilters ? "buttonIconActive" : "buttonIconInactive"}
                                            iconAlign="left"
                                            iconName="filter"
                                            onClick={() => {
                                                setShowFilters(!showFilters);
                                            }}
                                        >Filter rows</Button>
                                        {searchResult != null && searchResult.pagesCount > 1 && 
                                            <Box float="right">
                                                <Pagination
                                                    currentPageIndex={searchResult.page}
                                                    pagesCount={searchResult.pagesCount}
                                                    disabled={searchResult.pagesCount == 0 || searchCustomCoaRowsLoading}
                                                    onChange={({ detail }) => dispatch(ACTIONS.SET_CURRENT_PAGE.withPayload(detail.currentPageIndex))}
                                                />
                                            </Box>
                                        }
                                    </ColumnLayout>
                                </div>
                                <TPELoadingSpinner loading={isGettingFlag || coaLovLoading}>
                                    <div className="tableContainer">
                                        <RowsGrid loading={searchCustomCoaRowsLoading} editable={canEdit && tableStatus != CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED} showFilters={showFilters} onFilter={setLocalSearchFilter}/>
                                        {tableStatus != CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED && canEdit &&
                                            <div className="customTableTableFooter">
                                                <Button variant="normal" onClick={() => dispatch(ACTIONS.ADD_NEW_ROW)}>Add Row</Button>
                                            </div>
                                        }
                                    </div>
                                </TPELoadingSpinner>
                            </React.Fragment>
                        :
                            null
                        }
                        <TPEErrorWatcher services={services} errors={[downloadError, allowDeleteFlagError, deleteError, archiveError]} />
                        <TPENavigator path={navigateUrl} />
                        <TPEBasicModal
                            title="Confirm delete"
                            visible={showDeleteConfirmationModal}
                            action={ACTION_TYPE.CONFIRM_CANCEL}
                            onConfirm={() => { setShouldDeleteCustomCOADefinition(true); setShowDeleteConfirmationModal(false); } }
                            onCancel={() => setShowDeleteConfirmationModal(false)}
                        >
                            Are you sure you want to delete the Custom COA Definition {tableName}?
                        </TPEBasicModal>
                        <TPEBasicModal
                            title="Confirm archive"
                            visible={showArchiveConfirmationModal}
                            action={ACTION_TYPE.CONFIRM_CANCEL}
                            onConfirm={() => { setShouldArchiveCustomCOADefinition(true); setShowArchiveConfirmationModal(false); } }
                            onCancel={() => setShowArchiveConfirmationModal(false)}
                        >
                            Are you sure you want to archive the Custom COA Definition {tableName}?
                        </TPEBasicModal>
                        <RenameTableModal visible={showRenameModal} onCancel={() => setShowRenameModal(false)} onSubmit={showRenamedCustomCOADefinition} tableId={searchResult?.tableId || ''}/>
                    </div>
                </Container>
            </CustomCOARowsProvider>
}