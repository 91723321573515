import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Box, FormField, SpaceBetween } from '@amzn/awsui-components-react/polaris';
import TPEAction from '../../models/common/TPEAction';
import { ACTIONS } from '../../services/calculations/CalculationsReducer';
import SavedSearch from 'src/models/calculations/SavedSearch';

// This object acts as an interface to allow unit tests to mock behaviour
// or spy on these local functions
export const testUtil = { hideModal: () => { } };

export default function SaveSearchModal(props: { isVisible: boolean, savedSearches: SavedSearch[], onSearchSaved: any, dispatch: React.Dispatch<TPEAction> }) {
    const { isVisible, savedSearches, onSearchSaved, dispatch } = props;
    const [searchName, setSearchName] = useState('');
    const [localError, setLocalError] = useState('');
    useEffect(() => {
        setSearchName('');
    }, [isVisible]);

    testUtil.hideModal = function () {
        dispatch(ACTIONS.SET_SAVE_SEARCH_VISIBLE.withPayload(false));
    }

    const onSaveClicked = function () {
        if (searchName.trim().length === 0) {
            setLocalError("Search name is required");
            return;
        }
        if (savedSearches.find(x => x.name === searchName)) {
            setLocalError("Name already exists");
            return;
        }
        onSearchSaved(searchName);
    }

    return (
        <Modal className="saveSearchModal"
            onDismiss={testUtil.hideModal}
            visible={isVisible}
            closeAriaLabel="Close modal"
            size="medium"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" className="cancelButton" onClick={testUtil.hideModal}>Cancel</Button>
                        <Button variant="primary" className="saveButton" onClick={onSaveClicked}>Save</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Save search"
        >
            <FormField
                label="Search name"
                errorText={localError}
            >
                <Input value={searchName} onKeyUp={() => setLocalError('')} onChange={({ detail }) => setSearchName(detail.value)} />
            </FormField>
        </Modal>
    );
}