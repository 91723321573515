export class PullBalancePayload {
    requestType: PullBalanceRequestTypes;
    payload: any;

    constructor(requestType: PullBalanceRequestTypes, payload: any){
        this.requestType = requestType;
        this.payload = payload;
    }
}

export interface PullGLBalanceRequest {
    periodType: string,
    dataSourceId: string,
    coaDataKeys: {
        companies: COADataKey[],
        accounts: COADataKey[],
        costCenters?: COADataKey[],
        productLines?: COADataKey[],
        locations?: COADataKey[],
        geoMarkets?: COADataKey[],
        projects?: COADataKey[]
    }
    fxDate?: number,
    fxType?: string,
    fxRate?: number, // For later use (user-entered FX rate)
    currency?: string,
    breakdownByCOA: boolean,
    startPeriod?: string,
    endPeriod?: string,
    closePeriodsOnly?: boolean
}

export interface PullCDTValueRequest {
    dataSourceId: string,
    cdtTableId: string,
    cdtCalculationNumber: string,
    cdtPeriodId: string,
    cdtValueId: string,
}

export enum PullBalanceRequestTypes {
    PullGLBalance,
    PullCDTValue
}

export interface COADataKey {
    type: string,
    value?: string,
    tableId?: string,
    tableName?: string,
    classification?: string,
}