import React from "react";
import {useEffect, useRef} from "react";
import {FormField, Grid, Select, SelectProps} from "@amzn/awsui-components-react/polaris";
import ServiceCollection from "src/services/ServiceCollection";
import {accountingDaysList} from "src/models/reports/GetAccountingDayList";

type ComponentProps = {
    services: ServiceCollection,
    selectedWorkbook: SelectProps.Option | null,
    selectedAccountingPeriod: SelectProps.Option | null,
    selectedAccountingDay: SelectProps.Option | null,
    onWorkbookSelected: (option: SelectProps.Option | null) => void,
    onAccountingPeriodSelected: (option: SelectProps.Option | null) => void,
    onAccountingDaySelected: (option: SelectProps.Option | null) => void
}

export default function TPTaxCwbReportFilterSelector(props: ComponentProps) {
    const loadTime = useRef((new Date()).toString());
    const [workbookListResult, workbookListLoading, workbookListResultError] = props.services.mecService.getWorkbooks(loadTime.current);
    const [accountingPeriodResult, accountingPeriodLoading, accountingPeriodError] = props.services.reportsService.getReportingPeriods();

    useEffect(() => {
        if (!accountingPeriodError) {
            return;
        }
        props.services.messageService.showErrorAutoDismissBanner(accountingPeriodError);
    }, [accountingPeriodError]);

    useEffect(() => {
        if (!workbookListResultError) {
            return;
        }
        props.services.messageService.showErrorAutoDismissBanner(workbookListResultError);
    }, [workbookListResultError]);

    return (
        <Grid
            gridDefinition={[{colspan: 6}, {colspan: 3}, {colspan: 3}]}
        >
            <FormField label="Workbook">
                <Select
                    statusType={workbookListLoading ? "loading" : "finished"}
                    loadingText="Fetching Workbooks"
                    selectedOption={{
                        label: props.selectedWorkbook?.label,
                        value: props.selectedWorkbook?.value
                    }}
                    onChange={({detail}) =>
                        props.onWorkbookSelected(detail.selectedOption)
                    }
                    options={
                        workbookListResult == null
                            ? []
                            : workbookListResult.map(
                                (workbook) => ({
                                    label: workbook.workbookId + " - " + workbook.workbookName,
                                    value: workbook.workbookName,
                                })
                            )
                    }
                    placeholder="Select the workbook"
                    virtualScroll={true}
                    filteringType="auto"
                />
            </FormField>
            <FormField label="Period">
                <Select
                    statusType={accountingPeriodLoading ? "loading" : "finished"}
                    loadingText="Fetching Periods"
                    selectedOption={{
                        label: props.selectedAccountingPeriod?.label,
                        value: props.selectedAccountingPeriod?.value
                    }}
                    onChange={({detail}) =>
                        props.onAccountingPeriodSelected(detail.selectedOption)
                    }
                    options={
                        accountingPeriodResult == null
                            ? []
                            : accountingPeriodResult.ReportingPeriodList.map(
                                (accountingPeriodNameValue) => ({
                                    label: accountingPeriodNameValue,
                                    value: accountingPeriodNameValue,
                                })
                            )
                    }
                    placeholder="Select the accounting period"
                    virtualScroll={true}
                    filteringType="auto"
                />
            </FormField>
            <FormField label="Accounting Day">
                <Select
                    selectedOption={
                        {
                            label: props.selectedAccountingDay?.label,
                            value: props.selectedAccountingDay?.value
                        }
                    }
                    options={accountingDaysList}
                    onChange={({detail}) => {
                        props.onAccountingDaySelected(detail.selectedOption);
                    }}
                    selectedAriaLabel="Selected"
                    placeholder="Select Accounting Day"
                />
            </FormField>
        </Grid>
    )
}