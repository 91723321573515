export default class DynamicBreadcrumb {
    path: string;
    pageTitle?: string;
    pageDescription?: string;
    headerSubscript?: string;
    breadCrumb: string;

    constructor(path: string, breadCrumb: string, pageTitle?: string, pageDescription?: string, headerSubscript?: string) {
        this.path = path;
        this.pageTitle = pageTitle;
        this.breadCrumb = breadCrumb;
        this.pageDescription = pageDescription;
        this.headerSubscript = headerSubscript;
    }
}