import CONSTANTS from 'src/utils/constants';
import TPEAction from 'src/models/common/TPEAction';
import { WorksheetTotalsState, initialState } from './WorksheetTotalsState';
import WorksheetTotal from '../../../models/tp-allocation/WorksheetTotal';
import { SavingStatus } from 'src/models/common/SavingStatus';
import StringUtils from 'src/utils/stringUtils';

/**
 * List here the actions supported by this reducer
 */
 export const ACTIONS = Object.freeze({
    RESET: new TPEAction('RESET'),
    SET_WORKSHEET_TOTALS: new TPEAction('SET_WORKSHEET_TOTALS'),
    SET_TOTAL_BEING_EDITED: new TPEAction('SET_TOTAL_BEING_EDITED'),
    SET_EDITOR_SUGGESTIONS: new TPEAction('SET_EDITOR_SUGGESTIONS'),
    SET_EDITOR_CARET_POSITION: new TPEAction('SET_EDITOR_CARET_POSITION'),
    APPEND_TO_FORMULA_EDITOR: new TPEAction('APPEND_TO_FORMULA_EDITOR'),
    ADD_WORKSHEET_TOTAL: new TPEAction('ADD_WORKSHEET_TOTAL'),
    ADD_WORKSHEET_TOTAL_BEFORE: new TPEAction('ADD_WORKSHEET_TOTAL_BEFORE'),
    ADD_WORKSHEET_TOTAL_AFTER: new TPEAction('ADD_WORKSHEET_TOTAL_AFTER'),
    REMOVE_WORKSHEET_TOTAL: new TPEAction('REMOVE_WORKSHEET_TOTAL'),
    DUPLICATE_WORKSHEET_TOTAL: new TPEAction('DUPLICATE_WORKSHEET_TOTAL'),
    TOGGLE_SHOW_VALUES: new TPEAction('TOGGLE_SHOW_VALUES'),
    UPDATE_CURRENT_TOTAL: new TPEAction('UPDATE_CURRENT_TOTAL'),
    EXIT_EDIT_MODE: new TPEAction('EXIT_EDIT_MODE'),
    SET_EXPANDED_STATE: new TPEAction('SET_EXPANDED_STATE'),
    REFRESH_WORKSHEET_TOTAL: new TPEAction('REFRESH_WORKSHEET_TOTAL'),
    SET_RUN_VALUES: new TPEAction('SET_RUN_VALUES'),
    SET_TOTALS_ARE_SAVED_AND_VALID: new TPEAction('SET_TOTALS_ARE_SAVED_AND_VALID'),
    SET_REMOVE_TOTAL_MODAL_PAYLOAD: new TPEAction('SET_REMOVE_TOTAL_MODAL_PAYLOAD'),
    SET_REMOVE_TOTAL: new TPEAction('SET_REMOVE_TOTAL'),    
    SET_IS_SAVING_TOTAL: new TPEAction('SET_IS_SAVING_TOTAL'),
    SET_SAVE_TOTAL_PAYLOAD: new TPEAction('SET_SAVE_TOTAL_PAYLOAD'),
});

const VALIDATION_MESSAGES = Object.freeze({
    DESCRIPTION_REQUIRED: "Total name is required!",
    OVERVIEW_REQUIRED: "Calculation overview is required!",
});

/**  
* This function is responsible for updating the state based on action type
* @param state The current dashboard state
* @param action The current dispatched action 
*/
export function worksheetTotalsReducer(state: WorksheetTotalsState, action: TPEAction) : WorksheetTotalsState {
    switch (action.type) {
        case ACTIONS.RESET.type:
            return {...initialState};
        case ACTIONS.SET_WORKSHEET_TOTALS.type: {
            const totals = action.payload;
            return {
                ...state,
                error: '',
                isLoading: false,
                totals: totals,
            };
        }
        case ACTIONS.SET_TOTAL_BEING_EDITED.type: {
            const { totals } = state;

            if (action.payload == null){
                totals.forEach(x => x.isBeingEdited = false);
                return {
                    ...state,
                    totalBeingEdited: undefined,
                    totals: [...totals],
                };
            }
            
            totals.forEach(x => x.isBeingEdited = false);
            const editingRecord = totals.find(x => x.totalId === action.payload.totalId);
            if (editingRecord){
                editingRecord.isBeingEdited = true;                
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                totalBeingEdited: editingRecord,
                totals: [...totals],
            };
        }
        case ACTIONS.EXIT_EDIT_MODE.type: {
            const { totals } = state;
            totals.forEach(x => x.isBeingEdited = false);
            return {
                ...state,
                error: '',
                isLoading: false,
                totalBeingEdited: undefined,
                totals: [...totals],
            };
        }
        case ACTIONS.ADD_WORKSHEET_TOTAL.type: {
            const { totals } = state;
            totals.forEach(t => t.isBeingEdited = false);
            totals.push(action.payload);
            return {
                ...state,
                error: '',
                isLoading: false,
                totalsAreSavedAndValid: false,
                totalBeingEdited: action.payload,
                totals: [...totals],
            }
        }
        case ACTIONS.ADD_WORKSHEET_TOTAL_BEFORE.type: {
            const { totals } = state;
            const { index, newTotal } = action.payload;
            const totalsList = totals || [];
            newTotal.id = `${totals.length + 1}`;
            
            // Inserting new item
            totalsList.splice(index === 0? 0 : index, 0, newTotal);
            for (let i = index; i < totalsList.length; i++) {
                const total = totalsList[i];
                total.sequence++;
                total.savingStatus = SavingStatus.Unsaved;
            }
            return {
                ...state,
                totalsAreSavedAndValid: false,
                error: '',
                isLoading: false,
                totals: [...totals],
            }
        }
        case ACTIONS.ADD_WORKSHEET_TOTAL_AFTER.type: {
            const { totals } = state;
            let { index, newTotal } = action.payload;
            const totalsList = totals || [];
            newTotal.id = `${totalsList.length + 1}`;
            
            // Inserting new item
            totalsList.splice(index >= totalsList.length - 1? totalsList.length : index + 1, 0, newTotal);
            for (let i = index + 1; i < totalsList.length; i++) {
                const step = totalsList[i];
                step.sequence++;
                step.savingStatus = SavingStatus.Unsaved;
            }
            return {
                ...state,
                totalsAreSavedAndValid: false,
                error: '',
                isLoading: false,
                totals: [...totals],
            }
        }
        case ACTIONS.DUPLICATE_WORKSHEET_TOTAL.type: {
            const { totals } = state;
            const { index, originalTotal } = action.payload;
            const nameCopiesCount = totals.filter(x => x.description.endsWith(originalTotal.description)).length;
            const copyPrefix = `DUPLICATE${nameCopiesCount === 1? '' : '('+nameCopiesCount+')'} - `;
            const copyName = `${copyPrefix}${originalTotal.description}`;
            const totalCopy = {...originalTotal, description: copyName, totalId: `${totals.length + 1}`, savingStatus: SavingStatus.Unsaved, isNew: true} as WorksheetTotal;
            const totalsList = totals || [];
            
            // Inserting copy
            totalsList.splice(index === 0? 0 : index, 0, totalCopy);
            for (let i = index; i < totalsList.length; i++) {
                const total = totalsList[i];
                total.sequence++;
                total.savingStatus = SavingStatus.Unsaved;
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                totalsAreSavedAndValid: false,
                totals: [...totals],
                updateTotalPayload: totalCopy
            }
        }
        case ACTIONS.REMOVE_WORKSHEET_TOTAL.type: {
            let { totals } = state;
            const { index, total } = action.payload;
            if (total.isNew) {
                const totalsList = totals || [];
                for (let i = index; i < totalsList.length; i++) {
                    const s = totalsList[i];
                    s.sequence--;
                    s.savingStatus = SavingStatus.Unsaved;
                }
                totals = totals.filter(x => x.totalId !== total.totalId);
                return {
                    ...state,
                    error: '',
                    isLoading: false,
                    totalsAreSavedAndValid: false,
                    totals: [...totals],
                }
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                totalsAreSavedAndValid: false,
            }
        }
        case ACTIONS.TOGGLE_SHOW_VALUES.type: {
            return {
                ...state,
                error: '',
                isLoading: false,
                showValues: !state.showValues,
            }
        }
        case ACTIONS.SET_EXPANDED_STATE.type: {
            return {
                ...state,
                error: '',
                isLoading: false,
                expandedState: action.payload,
            }
        }
        case ACTIONS.SET_EDITOR_CARET_POSITION.type: {
            const { totalBeingEdited } = state;
            if (totalBeingEdited == null) {
                return {
                    ...state,
                    error: `No worksheet total is being edited. The value '${action.payload}' could not be set as caret position.`,
                    isLoading: false,
                };
            }
            totalBeingEdited.formulaEditorCaretPosition = action.payload;
            return {
                ...state,
                error: '',
                isLoading: false,                
            }
        }
        case ACTIONS.APPEND_TO_FORMULA_EDITOR.type: {
            const { totalBeingEdited, totals } = state;
            if (totalBeingEdited == null) {
                return {
                    ...state,
                    error: `No worksheet total is being edited. The value '${action.payload}' could not be added to the formula editor.`,
                    isLoading: false,
                };
            }
            const caretPosition = totalBeingEdited.formulaEditorCaretPosition || totalBeingEdited.expression.length - 1;
            const paddingBeforePosition = totalBeingEdited.expression.charAt(caretPosition - 1) === ' '? '' : ' ';
            const paddingAfterPosition = totalBeingEdited.expression.charAt(caretPosition) === ' '? '' : ' ';
            const str = `${paddingBeforePosition}${action.payload}${paddingAfterPosition}`;
            totalBeingEdited.expression = [totalBeingEdited.expression.slice(0, caretPosition), str, totalBeingEdited.expression.slice(caretPosition)].join('');
            const totalsList = totals || [];
            totalsList[totalsList.findIndex(x => x.totalId === totalBeingEdited.totalId)] = totalBeingEdited;
            return {
                ...state,
                error: '',
                isLoading: false,
                totalBeingEdited: {...totalBeingEdited}
            }
        }
        case ACTIONS.UPDATE_CURRENT_TOTAL.type: {
            if (state.totalBeingEdited == null) {
                return {
                    ...state,
                    error: `No worksheet total is being edited. Step ${action.payload.field} could not be updated.`,
                    isLoading: false,
                };
            }
            const total = (state.totalBeingEdited as any);
            if (total){
                const oldValue = total[action.payload.field];
                const newValue = action.payload.value;
                
                if (oldValue !== newValue){
                    total.savingStatus = SavingStatus.Unsaved;
                }

                total[action.payload.field] = action.payload.value;
                if (action.payload.field === CONSTANTS.CALCULATION_STEP_FIELDS.OVERVIEW) {
                    total[CONSTANTS.CALCULATION_STEP_FIELDS.EXPRESSION_TOKENS] = action.payload.extraValue;
                }

                if (total.validationErrors == null){
                    total.validationErrors = [];
                }

                total.validationErrors = total.validationErrors.filter((x:any) => x.message !== VALIDATION_MESSAGES.DESCRIPTION_REQUIRED && x.message !== VALIDATION_MESSAGES.OVERVIEW_REQUIRED)
                
                if (StringUtils.isNullOrEmpty(total.description)){
                    total.validationErrors.push({message:VALIDATION_MESSAGES.DESCRIPTION_REQUIRED, worksheetEntityId: total.totalId})
                }
                if (StringUtils.isNullOrEmpty(total.expression)){
                    total.validationErrors.push({message:VALIDATION_MESSAGES.OVERVIEW_REQUIRED, worksheetEntityId: total.totalId})
                }

                if ( total.validationErrors.length > 0 ) {
                    return {
                        ...state,
                        error: '',
                        isLoading: false,
                        totals: [...state.totals],
                        totalsAreSavedAndValid: false,
                    };
                }

                return {
                    ...state,
                    error: '',
                    isLoading: false,
                    totals: [...state.totals],
                    totalsAreSavedAndValid: false,
                    updateTotalPayload: state.totalBeingEdited?.savingStatus === SavingStatus.Unsaved? {...total} : null,
                }
            }

            return {
                ...state,                
            }
        }
        case ACTIONS.SET_TOTALS_ARE_SAVED_AND_VALID.type:
            return {
                ...state,
                error: '',
                totalsAreSavedAndValid: action.payload,
            }
        case ACTIONS.REFRESH_WORKSHEET_TOTAL.type: {
            const { totals, totalBeingEdited } = state;
            const totalToRefresh = totalBeingEdited != null && totalBeingEdited.sequence === action.payload.sequence? 
                totalBeingEdited : 
                totals.find(x => x.totalId === action.payload.totalId || x.totalId === action.payload.unsavedId);
            if (totalToRefresh != null){
                totalToRefresh.totalId = action.payload.totalId;
                totalToRefresh.valid = action.payload.valid;
                totalToRefresh.expression = action.payload.expression;
                totalToRefresh.isNew = totalToRefresh.totalId.startsWith("newTotal");
                totalToRefresh.validationErrors = action.payload.validationErrors;
                totalToRefresh.savingStatus = totalToRefresh.totalId.startsWith("newTotal")? SavingStatus.Unsaved : SavingStatus.Saved;
            }
            else {
                console.warn("********* Could not refresh total because total was not found");
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                totals: [...totals],
                totalSavedId: totalToRefresh?.totalId,
                updateTotalPayload: null
            }
        }
        case ACTIONS.SET_REMOVE_TOTAL_MODAL_PAYLOAD.type:
            const {total} = action.payload;
            if (total.isNew){
                return {
                    ...state,
                    error: '',
                    totals: [...state.totals.filter(x => x.totalId !== total.totalId) || []],
                }
            }
            return {
                ...state,
                error: '',
                removeTotalModalPayload: action.payload,
            }
        case ACTIONS.SET_REMOVE_TOTAL.type:
            const { totals } = state;
            const updatedTotals = totals.filter(t => t.totalId !== action.payload.totalId);
            return {
                ...state,
                error: '',
                totals: updatedTotals,
                removeTotalModalPayload: null,
            }
        case ACTIONS.SET_IS_SAVING_TOTAL.type:
            return {
                ...state,
                isSaving: action.payload,
            }
        case ACTIONS.SET_SAVE_TOTAL_PAYLOAD.type:
            return {
                ...state,
                updateTotalPayload: action.payload,
            }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}




