
import { SearchCalculationsState } from './SearchCalculationsState';
import TPEAction from '../../models/common/TPEAction';

/**
 * List here the actions supported by this reducer
 */
export const ACTIONS = Object.freeze({
  RESET: new TPEAction('reset'),
  SET_SELECTED_AGREEMENT: new TPEAction('set-agreement'),
  SET_AGREEMENT_LINES: new TPEAction('set-agreement-lines'),
  SET_SELECTED_AGREEMENT_LINE: new TPEAction('set-agreement-line'),
  SET_SELECTED_CALCULATION_LINE: new TPEAction('set-calculation-line'),
  SET_CALCULATION_LINES: new TPEAction('set-calculation-lines'),
  SET_SEARCH_PARAMS: new TPEAction('set-search-params'),
  START_SEARCH: new TPEAction('start-search'),
  SET_SEARCH_RESULTS: new TPEAction('set-search-results'),
  SET_MORE_OPTIONS_VISIBLE: new TPEAction('set-more-options-visible'),
  SET_MORE_OPTIONS: new TPEAction('set-more-options'),
  CLEAR_FILTERS: new TPEAction('clear-filters'),
  ADD_SAVED_SEARCH: new TPEAction('add-saved-search'),
  SET_SAVED_SEARCHES: new TPEAction('set-saved-searches'),
  SET_SELECTED_SAVED_SEARCH: new TPEAction('set-selected-saved-search'),
  SET_SELECTED_TAX_OWNER: new TPEAction('set-selected-tax-owner'),
  SET_SELECTED_CALCULATION_LINE_NUMBER: new TPEAction('set-selected-calculation-line-number'),
  SET_SELECTED_FIRST_PARTY: new TPEAction('set-selected-first-party'),
  SET_SELECTED_SECOND_PARTY: new TPEAction('set-selected-second-party'),
  SET_SELECTED_ACCOUNTING_OWNER: new TPEAction('set-selected-accounting-owner'),
  SET_SELECTED_AGREEMENT_STATUS: new TPEAction('set-selected-agreement-status'),
  SET_SELECTED_CALCULATION_STATUS: new TPEAction('set-selected-calculation-status'),
  SET_SELECTED_CALCULATION_NAME: new TPEAction('set-selected-calculation-name'),
  SET_SELECTED_LAST_UPDATED_ON: new TPEAction('set-selected-last-updated-on'),
  SET_SAVE_SEARCH_VISIBLE: new TPEAction('set-save-search-visible'),
  SET_TEMPORARY_MESSAGE: new TPEAction('set-temporary-message'),
  SET_CURRENT_PAGE: new TPEAction('set-current-page'),
  SET_PAGE_SIZE: new TPEAction('set-page-size'),
  SET_SEARCH_SORT: new TPEAction('set-search-sort'),
  SET_SEARCH_FILTERS: new TPEAction('set-search-filters'),
  ERROR: new TPEAction('error')
});


/**
 * This function is responsible for updating the state based on action type
 * @param state The current dashboard state
 * @param action The current dispatched action
 */
export function calculationsReducer(state: SearchCalculationsState, action: TPEAction) {
  switch (action.type) {
    case ACTIONS.RESET.type: {
      return {
        ...state,
        error: '',
        isLoading: true,
        selectedAgreement: undefined,
        selectedAgreementLine: undefined,
        selectedCalculationLine: undefined,
      };
    }
    case ACTIONS.SET_SELECTED_AGREEMENT.type: {
      const x = {
        ...state,
        error: '',
        isLoading: false,
        selectedAgreement: action.payload,
        selectedAgreementLine: undefined,
        selectedCalculationLine: undefined,
        agreementLines: [],
        calculationLines: [],
      };
      return x;
    }
    case ACTIONS.SET_AGREEMENT_LINES.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        agreementLines: action.payload,
      };
    }
    case ACTIONS.SET_SELECTED_AGREEMENT_STATUS.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        selectedAgreementStatus: action.payload,
      };
    }
    case ACTIONS.SET_SELECTED_CALCULATION_STATUS.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        selectedCalculationStatus: action.payload,
      };
    }
    case ACTIONS.SET_SELECTED_CALCULATION_NAME.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        selectedCalculationName: action.payload,
      };
    }
    case ACTIONS.SET_SELECTED_AGREEMENT_LINE.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        selectedAgreementLine: action.payload,
        selectedCalculationLine: undefined,
        calculationLines: [],
      };
    }
    case ACTIONS.SET_CALCULATION_LINES.type: {
      const x = {
        ...state,
        error: '',
        isLoading: false,
        calculationLines: action.payload,
      };
      return x;
    }
    case ACTIONS.SET_SELECTED_CALCULATION_LINE.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        selectedCalculationLine: action.payload,
      };
    }
    case ACTIONS.SET_SELECTED_FIRST_PARTY.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        selectedFirstParty: action.payload,
      };
    }
    case ACTIONS.SET_SELECTED_SECOND_PARTY.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        selectedSecondParty: action.payload,
      };
    }
    case ACTIONS.SET_SELECTED_ACCOUNTING_OWNER.type: {
      return {
        ...state,
        error: '',
        isLoading: false,
        selectedAccountingOwner: action.payload,
      };
    }
    case ACTIONS.SET_SEARCH_PARAMS.type: {
      return {
        ...state,
        error: '',
        searchParams: action.payload,
      };
    }
    case ACTIONS.START_SEARCH.type: {
      var x = {
        ...state,
        error: '',
        searchResultItems: [],
        searchParams: action.payload,
        searchResultsIsVisible: true,
      };
      return x;
    }
    case ACTIONS.SET_SEARCH_RESULTS.type: {
      const { clis, totalSize } = action.payload;
      return {
        ...state,
        error: '',
        isLoading: false,
        isSearching: false,
        searchResultItems: clis,
        searchTotalSize: totalSize,
      };
    }
    case ACTIONS.SET_MORE_OPTIONS_VISIBLE.type: {
      return {
        ...state,
        moreOptionsModalIsVisible: action.payload,
      };
    }
    case ACTIONS.SET_SAVE_SEARCH_VISIBLE.type: {
      return {
        ...state,
        isSaveSearchModalVisible: action.payload,
      };
    } case ACTIONS.SET_MORE_OPTIONS.type: {
      return {
        ...state,
        otherOptions: action.payload,
        moreOptionsModalIsVisible: false,
      };
    }
    case ACTIONS.SET_SAVED_SEARCHES.type: {
      return {
        ...state,
        savedSearches: action.payload,
        isSaveSearchModalVisible: false,
      };
    }
    case ACTIONS.ADD_SAVED_SEARCH.type: {
      return {
        ...state,
        isSaveSearchModalVisible: false,
        lastSavedSearchTimestamp: new Date(),
        savedSearches: [...state.savedSearches, action.payload]
      };
    }
    case ACTIONS.SET_SELECTED_SAVED_SEARCH.type: {
      return {
        ...state,
        selectedSavedSearch: action.payload,
      };
    }
    case ACTIONS.SET_SELECTED_TAX_OWNER.type: {
      return {
        ...state,
        selectedTaxOwner: action.payload,
      };
    }
    case ACTIONS.SET_SELECTED_CALCULATION_LINE_NUMBER.type: {
      return {
        ...state,
        selectedCalculationLineNumber: action.payload,
      };
    }
    case ACTIONS.SET_SELECTED_LAST_UPDATED_ON.type: {
      return {
        ...state,
        selectedLastUpdatedOn: action.payload,
      };
    }


    case ACTIONS.CLEAR_FILTERS.type: {
      return {
        ...state,
        selectedAgreement: undefined,
        selectedAgreementLine: undefined,
        selectedCalculationLine: undefined,
        agreementLines: [],
        calculationLines: [],
        otherOptions: [],
      };
    }
    case ACTIONS.SET_CURRENT_PAGE.type:
      return {
        ...state,
        searchCurrentPage: action.payload,
      };
    case ACTIONS.SET_PAGE_SIZE.type:
      return {
        ...state,
        searchCurrentPage: 1,
        searchPageSize: action.payload,
      };
    case ACTIONS.SET_SEARCH_SORT.type:
      return {
        ...state,
        searchSorting: action.payload,
      };
    case ACTIONS.SET_SEARCH_FILTERS.type:
      return {
        ...state,
        searchFilters: action.payload,
      };
    case ACTIONS.SET_TEMPORARY_MESSAGE.type: {
      return {
        ...state,
        temporaryMessage: action.payload,
      };
    }
    case ACTIONS.ERROR.type: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}