import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import { DatasetRecord, getNewDatasetRecord } from "src/models/tp-allocation/DatasetRecord";
import { PullBalancePayload } from 'src/models/calculation-builder/PullBalanceRequest';
import { PullBalanceWSMessage, PullCDTValueMessage } from 'src/models/calculation-builder/PullBalanceWebSocketMessage';
import CONSTANTS from 'src/utils/constants';
import StringUtils from 'src/utils/stringUtils';
import TPEAction from 'src/models/common/TPEAction';
import { DatasetState } from './DatasetState';
import { WorksheetMode } from 'src/models/tp-allocation/TPAllocationEnums';

/**
 * List here the actions supported by this reducer
 */
export const ACTIONS = Object.freeze({
    SET_RECORDS: new TPEAction('SET_RECORDS'),
    ADD_NEW_RECORD: new TPEAction('ADD_NEW_RECORD'),
    EDIT_RECORD: new TPEAction('EDIT_RECORD'),
    CANCEL_EDITING: new TPEAction('CANCEL_EDITING'),
    UPDATE_RECORD_FIELD: new TPEAction('UPDATE_RECORD_FIELD'),
    TOGGLE_SHOW_DECIMALS: new TPEAction('TOGGLE_SHOW_DECIMALS'),
    SHOW_DATA_KEY_SELECTION: new TPEAction('SHOW_DATA_KEY_SELECTION'),
    DELETE_RECORD: new TPEAction('DELETE_RECORD'),
    DUPLICATE_RECORD: new TPEAction('DUPLICATE_RECORD'),
    SAVE_RECORD: new TPEAction('SAVE_RECORD'),
    SET_COA_TYPES: new TPEAction('SET_COA_TYPES'),
    SET_DATA_KEY_INPUT: new TPEAction('SET_DATA_KEY_INPUT'),
    SET_DATA_KEY_COA_INPUT: new TPEAction('SET_DATA_KEY_COA_INPUT'),
    REMOVE_DATA_KEY_COA_INPUT: new TPEAction('REMOVE_DATA_KEY_COA_INPUT'),
    REMOVE_UNUSED_DATA_KEY_COA_INPUTS: new TPEAction('REMOVE_UNUSED_DATA_KEY_COA_INPUTS'),
    EDIT_COA: new TPEAction('EDIT_COA'),
    UPDATE_BALANCES: new TPEAction('UPDATE_BALANCES'),
    UPDATE_CDT_VALUE: new TPEAction('UPDATE_CDT_VALUE'),
    SET_RECORD_ID_FOR_ACTION: new TPEAction('SET_RECORD_ID_FOR_ACTION'),
    SET_ERRORS: new TPEAction('SET_ERRORS'),
    CLEAR_ERRORS: new TPEAction('CLEAR_ERRORS'),
    SHOW_EDIT_CONFIRM_MODAL: new TPEAction('SHOW_EDIT_CONFIRM_MODAL'),
    HIDE_EDIT_CONFIRM_MODAL: new TPEAction('HIDE_EDIT_CONFIRM_MODAL'),
    SHOW_DUPLICATE_CONFIRM_MODAL: new TPEAction('SHOW_DUPLICATE_CONFIRM_MODAL'),
    HIDE_DUPLICATE_CONFIRM_MODAL: new TPEAction('HIDE_DUPLICATE_CONFIRM_MODAL'),
    SHOW_DELETE_CONFIRM_MODAL: new TPEAction('SHOW_DELETE_CONFIRM_MODAL'),
    HIDE_DELETE_CONFIRM_MODAL: new TPEAction('HIDE_DELETE_CONFIRM_MODAL'),
    SHOW_STEP3_CONFIRM_MODAL: new TPEAction('SHOW_STEP3_CONFIRM_MODAL'),
    HIDE_STEP3_CONFIRM_MODAL: new TPEAction('HIDE_STEP3_CONFIRM_MODAL'),
    CLEAR_SAVED_RECORD_ID: new TPEAction('CLEAR_SAVED_RECORD_ID'),
    REQUEST_BALANCE_PULL: new TPEAction('REQUEST_BALANCE_PULL'),
    SET_BALANCE_PULL_IN_PROGRESS: new TPEAction('SET_BALANCE_PULL_IN_PROGRESS'),
    SET_PULL_BALANCE_ERROR: new TPEAction('SET_PULL_BALANCE_ERROR'),
    SET_BALANCE_DRILL_DOWN: new TPEAction('SET_BALANCE_DRILL_DOWN'),
    ADD_PULL_BALANCE_PAYLOAD: new TPEAction('ADD_PULL_BALANCE_PAYLOAD'),
    REMOVE_PULL_BALANCE_PAYLOAD: new TPEAction('REMOVE_PULL_BALANCE_PAYLOAD'),
    UPDATE_DATASET_RECORD_STATUS: new TPEAction('UPDATE_DATASET_RECORD_STATUS'),
    SHOW_UPDATE_ALL_BALANCES_CONFIRM_MODAL: new TPEAction('SHOW_UPDATE_ALL_BALANCES_CONFIRM_MODAL'),
    HIDE_UPDATE_ALL_BALANCES_CONFIRM_MODAL: new TPEAction('HIDE_UPDATE_ALL_BALANCES_CONFIRM_MODAL'),
    PROCESS_NEXT_BALANCE_PULL_REQUEST: new TPEAction('PROCESS_NEXT_BALANCE_PULL_REQUEST'),
    TOGGLE_DATA_KEY_VIEW: new TPEAction('TOGGLE_DATA_KEY_VIEW'),
    SET_FX_INPUTS: new TPEAction('SET_FX_INPUTS'),
    SET_SELECTED_CUSTOM_COA: new TPEAction('SET_SELECTED_CUSTOM_COA'),
    SET_CUSTOM_COA_REFERENCE: new TPEAction('SET_CUSTOM_COA_REFERENCE'),
    SET_SAVE_ERROR: new TPEAction('SET_SAVE_ERROR'),
    SET_TP_LOV: new TPEAction('SET_TP_LOV'),    
    TOGGLE_CATEGORY_EXPANSION: new TPEAction('TOGGLE_CATEGORY_EXPANSION'),    
    TOGGLE_ACCOUNT_EXPANSION: new TPEAction('TOGGLE_ACCOUNT_EXPANSION') 
});

function clearBalancesAndAbandonPullRequest(editingRecord: any) {
    const isInProgress = editingRecord['balancePullInProgress'] || false;
    if (isInProgress) {
        editingRecord['balancePullInProgress'] = false;
        const abandonedBalancePullRequests = editingRecord['abandonedBalancePullRequests'] || [] as string[];
        const balancePullingTask = editingRecord['balancePullingTask'] as PullBalanceWSMessage;
        if (balancePullingTask != null) {
            abandonedBalancePullRequests.push(balancePullingTask.requestId);
        } else if (editingRecord['unsavedBalancePullRequestID'] != null) {
            abandonedBalancePullRequests.push(editingRecord['unsavedBalancePullRequestID']);
        }
        editingRecord['abandonedBalancePullRequests'] = abandonedBalancePullRequests;
    }
    clearBalances(editingRecord);
}

function isBalancePullAbandoned(editingRecord: any, balancePullRequestID: string) {
    const abandonedBalancePullRequests = editingRecord['abandonedBalancePullRequests'] || [] as string[];
    const unsavedBalancePullRequestID = editingRecord['unsavedBalancePullRequestID'];

    return abandonedBalancePullRequests.includes(balancePullRequestID) || (unsavedBalancePullRequestID != null && abandonedBalancePullRequests.includes(unsavedBalancePullRequestID));
}

function clearBalances(editingRecord: any) {
    editingRecord['requestBalancePull'] = false;
    editingRecord['glBalances'] = null;
    editingRecord['glBalanceDrillDownKeys'] = null;
    editingRecord['glBalanceDrillDown'] = undefined;
    editingRecord['value'] = null;
    editingRecord['lastBalancePullRequestID'] = null as unknown as string;
    editingRecord['lastBalancePullDate'] = null as unknown as number;
}


function getSelectedRecordAndParams(payload: any, records: DatasetRecord[], checkUnsaved: boolean = false) {
    const { recordID, value = null } = payload;
    let selectedRecord = null;
    if (recordID != null) {
        selectedRecord = records.find(x => x.datasourceId == recordID);
        if (selectedRecord == null && checkUnsaved != null && checkUnsaved) {
            selectedRecord = records.find(x => x.unsavedDatasourceId == recordID);   
        }
    }
    return { selectedRecord: selectedRecord || null, value };
}

function getSelectedRecordByRequestID(requestID: string, records: DatasetRecord[]) {
    const selectedRecord = requestID != null ? records.find(x => x.balancePullingTask?.requestId == requestID) : null;
    return selectedRecord || null;
}

function backupBalanceData(editingRecord: any) {
    editingRecord['originalBalanceData'] = {
        value: editingRecord['value'],
        fxRate: editingRecord['fxRate'],
        glBalances: editingRecord['glBalances'],
        glBalanceDrillDownKeys: editingRecord['glBalanceDrillDownKeys'],
        lastBalancePullRequestID: editingRecord['lastBalancePullRequestID'],
        lastBalancePullDate: editingRecord['lastBalancePullDate']
    }
}

function restoreFromBackupBalanceData(editingRecord: any) {
    const originalBalanceData = editingRecord['originalBalanceData'];
    if (originalBalanceData != null) {
        editingRecord['value'] = originalBalanceData.value;
        editingRecord['fxRate'] = originalBalanceData.fxRate;
        editingRecord['glBalances'] = originalBalanceData.glBalances;
        editingRecord['glBalanceDrillDownKeys'] = originalBalanceData.glBalanceDrillDownKeys;
        editingRecord['lastBalancePullRequestID'] = originalBalanceData.lastBalancePullRequestID;
        editingRecord['lastBalancePullDate'] = originalBalanceData.lastBalancePullDate;
    }
    editingRecord['originalBalanceData'] = null;
}

/**  
* This function is responsible for updating the state based on action type
* @param state The current dashboard state
* @param action The current dispatched action 
*/
export function datasetReducer(state: DatasetState, action: TPEAction): DatasetState {
    switch (action.type) {
        case ACTIONS.SET_RECORDS.type: {
            const records = action.payload;
            return {
                ...state,
                loading: false,
                datasetRecords: records,
                recordBeingEdited: undefined,
            }
        }
        case ACTIONS.ADD_NEW_RECORD.type: {
            const newRecord = getNewDatasetRecord(state.recordIdCounter);
            const worksheetMode = action.payload.worksheetMode as WorksheetMode;
            newRecord.unsavedDatasourceId = newRecord.datasourceId;
            if (worksheetMode === WorksheetMode.STANDALONE){
                newRecord.worksheetId = action.payload.parentEntityId;
            }
            else if (worksheetMode === WorksheetMode.TEMPLATE) {
                newRecord.templateId = action.payload.parentEntityId;
            }
            return {
                ...state,
                loading: false,
                datasetRecords: [...state.datasetRecords, newRecord],
                recordIdCounter: state.recordIdCounter + 1,
                recordBeingEdited: newRecord, //New record is in edit mode
                savedRecordId: undefined,
            }
        }
        case ACTIONS.EDIT_RECORD.type: {
            const records = [...state.datasetRecords];
            const selectedRecordArr = records.filter(x => x.datasourceId == state.recordIdForAction);
            if (selectedRecordArr.length > 0) {
                const selectedRecord = selectedRecordArr[0];
                const selectedRecordCopy = {...selectedRecord, balancePullInProgress: false};
                restoreFromBackupBalanceData(selectedRecordCopy);
                selectedRecord.isEditing = true;
                selectedRecord.showDataKeySelection = true;
                return {
                    ...state,
                    recordBeingEdited: selectedRecord,
                    datasetRecords: records,
                    recordBeingEditedOriginal: selectedRecordCopy,
                    savedRecordId: undefined,
                }
            } else {
                return state;
            }
        }
        case ACTIONS.DUPLICATE_RECORD.type: {
            const records = [...state.datasetRecords];
            const selectedRecordArr = records.filter(x => x.datasourceId == state.recordIdForAction);
            if (selectedRecordArr.length > 0) {
                const selectedRecord = selectedRecordArr[0];
                const selectedRecordCopy = {...selectedRecord, 
                    datasourceId: String(state.recordIdCounter), 
                    description: CONSTANTS.DUPLICATE_DATA_SOURCE_DESC_PREFIX + selectedRecord.description,
                    isNewRecord: true,
                    isEditing: true,
                    showDataKeySelection: true,
                    requestBalancePull: false,
                    balancePullInProgress: false,
                    balancePullingTask: undefined,
                    abandonedBalancePullRequests: [],
                    errors: undefined,
                    pullBalanceError: undefined,
                };
                if (selectedRecord.dataKeyInput != null) {
                    selectedRecordCopy.dataKeyInput = {...selectedRecord.dataKeyInput};
                    if (selectedRecord.dataKeyInput.selectedCOA != null) {
                        selectedRecordCopy.dataKeyInput.selectedCOA = new Map(selectedRecord.dataKeyInput.selectedCOA);
                    }
                }
                clearBalances(selectedRecordCopy);
                
                records.push(selectedRecordCopy);
                return {
                    ...state,
                    recordBeingEdited: selectedRecordCopy,
                    datasetRecords: records,
                    recordIdCounter: state.recordIdCounter + 1,
                    savedRecordId: undefined,
                }
            } else {
                return state;
            }
        }
        case ACTIONS.DELETE_RECORD.type: {
            const records = state.datasetRecords.filter(x => x.datasourceId != state.recordIdForAction);
            if (state.recordBeingEdited != undefined && state.recordBeingEdited.datasourceId == state.recordIdForAction) {
                return {
                    ...state,
                    datasetRecords: records,
                    recordBeingEdited: undefined,
                    savedRecordId: undefined,
                };
            } else {
                return {
                    ...state,
                    datasetRecords: records,
                    savedRecordId: undefined,
                };
            }
        }
        case ACTIONS.CANCEL_EDITING.type: {
            const editingRecord = state.recordBeingEdited;
            
            if (editingRecord == null){
                return {...state};
            }

            if (editingRecord?.isNewRecord) {
                // Delete the record if it is a new record
                const records = state.datasetRecords.filter(x => x.datasourceId != editingRecord.datasourceId);
                return {
                    ...state,
                    datasetRecords: records,
                    recordBeingEdited: undefined,
                }
            } else {
                const index = state.datasetRecords.indexOf(editingRecord || {} as DatasetRecord);
                const records = [...state.datasetRecords];
                if (index >= 0 && state.recordBeingEditedOriginal != null) {
                    if(editingRecord?.datasource === CONSTANTS.DATA_SOURCE_TYPES.GENERAL_LEDGER) { // this might not be needed if we use the same pulling api
                        clearBalancesAndAbandonPullRequest(state.recordBeingEdited);
                        state.recordBeingEditedOriginal.abandonedBalancePullRequests = state.recordBeingEdited?.abandonedBalancePullRequests as string[];
                        state.recordBeingEditedOriginal.unsavedBalancePullRequestID = state.recordBeingEdited?.unsavedBalancePullRequestID as string;
                    }
                    records[index] = state.recordBeingEditedOriginal;
                }
                return {
                    ...state,
                    recordBeingEdited: undefined,
                    recordBeingEditedOriginal: undefined,
                    datasetRecords: records,
                }
            }
        }
        case ACTIONS.SAVE_RECORD.type: {
            const { recordBeingEdited } = state;

            // This is the object coming from the web API response
            const { recordID, updatedRecord } = action.payload;

            const records = [...state.datasetRecords];
            const { selectedRecord } = getSelectedRecordAndParams(action.payload, records);
            if (selectedRecord != null) {
                selectedRecord.datasourceId = updatedRecord.datasourceId;
                selectedRecord.currency = updatedRecord.currency;
                selectedRecord.isNewRecord = false;
                selectedRecord.isEditing = false;
                selectedRecord.showDataKeySelection = false;
                selectedRecord.dataKey = ((selectedRecord.dataKeyInput?.selectedCOA.size || 0) + 2) + CONSTANTS.DATA_SOURCE_DATA_KEY_COUNT_SUFFIX; // Add 2 for company and account which are mandatory
                selectedRecord.errors = undefined;
                selectedRecord.requestAutoSave = false;
                selectedRecord.originalBalanceData = undefined;
                
                if (recordBeingEdited != null && recordBeingEdited.datasourceId == selectedRecord.datasourceId) {
                    return {
                        ...state,
                        recordBeingEdited: undefined,
                        recordBeingEditedOriginal: undefined,
                        savedRecordId: selectedRecord.datasourceId,
                        datasetRecords: records,
                    }
                } else {
                    return {
                        ...state,
                        datasetRecords: records,
                    }
                }
                
            } else {
                return state;
            }

        }
        case ACTIONS.UPDATE_RECORD_FIELD.type:
            const editingRecord = state.recordBeingEdited as any;
            const { fieldName, value } = action.payload;

            if (fieldName != CONSTANTS.DATA_SOURCE_FIELDS.DESCRIPTION.ACCESSOR && editingRecord[fieldName] != value) {
                clearBalancesAndAbandonPullRequest(editingRecord);
            }
            // Clear the custom period value if a different period option is selected
            // Clear the period value if custom period is updated
            if (fieldName == CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR && value != CONSTANTS.DATA_SOURCE_VALUES.CUSTOM_PERIOD) {
                editingRecord['customPeriod'] = null;
            } else if (fieldName == CONSTANTS.DATA_SOURCE_FIELDS.CUSTOM_PERIOD.ACCESSOR) {
                editingRecord['period'] = null;
            }

            editingRecord[action.payload.fieldName] = action.payload.value;
            
            return {
                ...state,
                loading: false,
                datasetRecords: [...state.datasetRecords],
            };
        case ACTIONS.TOGGLE_SHOW_DECIMALS.type:
            return {
                ...state,
                showDecimals: !state.showDecimals,
            };
        case ACTIONS.SHOW_DATA_KEY_SELECTION.type: {
            if (state.recordBeingEdited != null){
                state.recordBeingEdited.showDataKeySelection = true;
            }
            return {
                ...state,
                datasetRecords: [...state.datasetRecords],
            };
        }
        case ACTIONS.SET_COA_TYPES.type: {
            return {
                ...state,
                coaTypes: action.payload,
            };
        }
        case ACTIONS.SET_DATA_KEY_INPUT.type: {
            const {key, value} = action.payload;
            const editingRecord = state.recordBeingEdited as any;
            editingRecord['dataKeyInput'][key] = value;
            clearBalancesAndAbandonPullRequest(editingRecord);

            return {
                ...state,
                datasetRecords: [...state.datasetRecords],
                selectedCustomCoaValues: undefined,
            }
        }
        case ACTIONS.SET_DATA_KEY_COA_INPUT.type: {
            const {key, value} = action.payload;
            const editingRecord = state.recordBeingEdited as any;
            (editingRecord['dataKeyInput']['selectedCOA'] as Map<string, string>).set(key, value);
            clearBalancesAndAbandonPullRequest(editingRecord);
            return {
                ...state,
                datasetRecords: [...state.datasetRecords],
                selectedCustomCoaValues: undefined,
            }
        }
        case ACTIONS.REMOVE_DATA_KEY_COA_INPUT.type: {
            const key = action.payload;
            const editingRecord = state.recordBeingEdited as any;
            (editingRecord['dataKeyInput']['selectedCOA'] as Map<string, string>).delete(key);
            clearBalancesAndAbandonPullRequest(editingRecord);
            return {
                ...state,
                datasetRecords: [...state.datasetRecords]
            }
        }
        case ACTIONS.REMOVE_UNUSED_DATA_KEY_COA_INPUTS.type: {
            if (state.recordBeingEdited != undefined && state.recordBeingEdited.dataKeyInput?.selectedCOA != undefined) {
                const coaInputMap = state.recordBeingEdited.dataKeyInput.selectedCOA;
                Array.from(coaInputMap.keys()).forEach(x => { if(coaInputMap.get(x)?.trim() == '') coaInputMap.delete(x); });
            }
            return {
                ...state,
                datasetRecords: [...state.datasetRecords],
            }
        }
        case ACTIONS.EDIT_COA.type: {
            const editingRecord = (state.recordBeingEdited as any);
            clearBalancesAndAbandonPullRequest(editingRecord);
            return {
                ...state,
                datasetRecords: [...state.datasetRecords],
            }
        }
        case ACTIONS.UPDATE_CDT_VALUE.type: {
            const {dataSourceId, currency, value} = action.payload as PullCDTValueMessage;
            const records = [...state.datasetRecords];

            let { selectedRecord } = getSelectedRecordAndParams({recordID: dataSourceId, value: value}, records);
            if (selectedRecord == null){
                return state;
            }
            
            selectedRecord.value = String(value);
            selectedRecord.currency = currency;
            selectedRecord.lastBalancePullDate = Date.now();
            selectedRecord.balancePullInProgress = false;
            selectedRecord.requestBalancePull = false;

            return {
                ...state,
                datasetRecords: records,
            }
        }
        case ACTIONS.UPDATE_BALANCES.type: {
            const { requestID, totalBalance, calculatedFxRate, ledgerCurrency, glBalances, glBalanceDrillDownKeys } = action.payload;
            const records = [...state.datasetRecords];
            
            let { selectedRecord } = getSelectedRecordAndParams(action.payload, records, true);
            if (selectedRecord == null) {
                selectedRecord = getSelectedRecordByRequestID(requestID, records);
            }

            if (selectedRecord != null  && !isBalancePullAbandoned(selectedRecord, requestID)) {
                selectedRecord.value = String(totalBalance);
                selectedRecord.fxRate = String(calculatedFxRate);
                selectedRecord.glBalanceDrillDownKeys = glBalanceDrillDownKeys;
                selectedRecord.balancePullInProgress = false;
                selectedRecord.requestBalancePull = false;
                selectedRecord.errors = undefined;

                if (glBalances == null || glBalances.length == 0) {
                    selectedRecord.glBalances = [{
                        company: selectedRecord.dataKeyInput?.selectedCompanies || '',
                        account: selectedRecord.dataKeyInput?.selectedAccounts || '',
                        balance: totalBalance
                    }]
                } else {
                    selectedRecord.glBalances = glBalances;
                }

                if (selectedRecord.balancePullingTask != null) {
                    selectedRecord.lastBalancePullRequestID = selectedRecord.balancePullingTask.requestId;
                }

                selectedRecord.lastBalancePullDate = Date.now();

                if (ledgerCurrency != null && ledgerCurrency != '' ) {
                    selectedRecord.currency = ledgerCurrency;
                    selectedRecord.fxType = CONSTANTS.FX_TYPE_PERIOD_AVERAGE;
                    const d = endOfMonth(subMonths(startOfMonth(new Date()), 1));
                    selectedRecord.fxDate = format(d, 'yyyy-MM-dd');
                }

                if (!selectedRecord.isEditing) {
                    selectedRecord.requestAutoSave = true;
                }
                return {
                    ...state,
                    datasetRecords: records,
                }
            } else {
                return state;
            }
        }
        case ACTIONS.SET_RECORD_ID_FOR_ACTION.type: {
            return {
                ...state,
                recordIdForAction: action.payload,
            }
        }
        case ACTIONS.SET_ERRORS.type: {
            const records = [...state.datasetRecords];
            const { value, selectedRecord } = getSelectedRecordAndParams(action.payload, records);
            if (selectedRecord != null) {
                selectedRecord.errors = value;
                return {
                    ...state,
                    datasetRecords: records,
                }
            } else {
                return state;
            }
        }
        case ACTIONS.CLEAR_ERRORS.type: {
            const records = [...state.datasetRecords];
            const { selectedRecord } = getSelectedRecordAndParams(action.payload, records);
            if (selectedRecord != null) {
                selectedRecord.errors = undefined;
                selectedRecord.saveError = undefined;
                return {
                    ...state,
                    datasetRecords: records,
                }
            } else {
                return state;
            }
        }
        case ACTIONS.SHOW_EDIT_CONFIRM_MODAL.type: {
            return {
                ...state,
                showEditActionConfirmModal: true,
            }
        }
        case ACTIONS.HIDE_EDIT_CONFIRM_MODAL.type: {
            return {
                ...state,
                showEditActionConfirmModal: false,
            }
        }
        case ACTIONS.SHOW_DUPLICATE_CONFIRM_MODAL.type: {
            return {
                ...state,
                showDuplicateActionConfirmModal: true,
            }
        }
        case ACTIONS.HIDE_DUPLICATE_CONFIRM_MODAL.type: {
            return {
                ...state,
                showDuplicateActionConfirmModal: false,
            }
        }
        case ACTIONS.SHOW_DELETE_CONFIRM_MODAL.type: {
            return {
                ...state,
                showDeleteActionConfirmModal: true,
            }
        }
        case ACTIONS.HIDE_DELETE_CONFIRM_MODAL.type: {
            return {
                ...state,
                showDeleteActionConfirmModal: false,
            }
        }
        case ACTIONS.SHOW_STEP3_CONFIRM_MODAL.type: {
            return {
                ...state,
                showStep3ConfirmModal: true,
            }
        }
        case ACTIONS.HIDE_STEP3_CONFIRM_MODAL.type: {
            return {
                ...state,
                showStep3ConfirmModal: false,
            }
        }
        case ACTIONS.ADD_PULL_BALANCE_PAYLOAD.type: {
            const records = [...state.datasetRecords];
            const requestPayload = action.payload as PullBalancePayload;
            const { selectedRecord } = getSelectedRecordAndParams({ recordID: requestPayload.payload.dataSourceId }, records);
            if (selectedRecord != null) {
                selectedRecord.unsavedBalancePullRequestID = String(state.pullBalanceRequestCounter);
                const pullBalancePayloads = [...state.pullBalanceRequestPayloads];
                pullBalancePayloads.push(requestPayload);
                return {
                    ...state,
                    balancePullingStateChangeTimestamp: new Date().toISOString(),
                    pullBalanceRequestPayloads: pullBalancePayloads,
                    pullBalanceRequestCounter: state.pullBalanceRequestCounter + 1
                }
            } else {
                return state;
            }
        }
        case ACTIONS.REQUEST_BALANCE_PULL.type: {
            const records = [...state.datasetRecords];
            const { selectedRecord, value } = getSelectedRecordAndParams(action.payload, records);
            if (selectedRecord != null) {
                if (value && !selectedRecord.balancePullInProgress || !value) {
                    selectedRecord.requestBalancePull = value;
                    if (!selectedRecord.balancePullInProgress) {
                        selectedRecord.balancePullingTask = undefined;
                    }
                }
            }
            return {
                ...state,
                balancePullingStateChangeTimestamp: new Date().toISOString(),
                savedRecordId: undefined,
            }
        }        
        case ACTIONS.REMOVE_PULL_BALANCE_PAYLOAD.type: {
            const payloads = [...state.pullBalanceRequestPayloads];
            payloads.shift();
            return {
                ...state,
                pullBalanceRequestPayloads: payloads,
            }
        }
        case ACTIONS.CLEAR_SAVED_RECORD_ID.type: {
            return {
                ...state,
                savedRecordId: undefined,
                datasetRecords: [...state.datasetRecords],
                recordBeingEdited: undefined,
            }
        }
        case ACTIONS.SET_BALANCE_PULL_IN_PROGRESS.type: {
            const records = [...state.datasetRecords];
            const { value, selectedRecord } = getSelectedRecordAndParams(action.payload, records);
            if (selectedRecord != null) {
                selectedRecord.balancePullInProgress = value;
                if (value) {
                    selectedRecord.requestBalancePull = false;
                    selectedRecord.pullBalanceError = undefined as unknown as string;
                    selectedRecord.balancePullingTask = undefined;
                    backupBalanceData(selectedRecord);
                    clearBalances(selectedRecord);
                }
                return {
                    ...state,
                    balancePullingStateChangeTimestamp: new Date().toISOString(),
                }
            } else {
                return state;
            }
        }
        case ACTIONS.SET_PULL_BALANCE_ERROR.type: {
            const { requestID } = action.payload;
            const records = [...state.datasetRecords];
            let { value, selectedRecord } = getSelectedRecordAndParams(action.payload, records, true);

            if (selectedRecord == null) {
                selectedRecord = getSelectedRecordByRequestID(requestID, records);
            }

            if (selectedRecord != null && !isBalancePullAbandoned(selectedRecord, requestID)) {
                selectedRecord.pullBalanceError = value;
                selectedRecord.balancePullInProgress = false;
                if (!selectedRecord.isEditing) {
                    restoreFromBackupBalanceData(selectedRecord);
                }
                return {
                    ...state,
                    balancePullingStateChangeTimestamp: new Date().toISOString(),
                }
            } else {
                return state;
            }
        }
        case ACTIONS.SET_BALANCE_DRILL_DOWN.type: {
            const editingRecord = (state.recordBeingEdited as any);
            editingRecord['glBalanceDrillDown'] = action.payload;
            return {
                ...state,
            }
        }
        case ACTIONS.UPDATE_DATASET_RECORD_STATUS.type: {
            const records = [...state.datasetRecords];
            const message = action.payload as PullBalanceWSMessage;
            // The initial message from the web socket will return the dataSourceId so we attemp to find the DS record by sataSourceId or unsavedDataSourceId
            let { selectedRecord } = getSelectedRecordAndParams({ recordID: message.dataSourceId }, records, true);
            if (selectedRecord == null) {
                // If record is not found then it means this message is an In Progress status update, those are found by the requestId
                selectedRecord = getSelectedRecordByRequestID(message.requestId, records);
            }
            if (selectedRecord != null){
                selectedRecord.balancePullingTask = message;
                if (selectedRecord.abandonedBalancePullRequests != null 
                    && selectedRecord.abandonedBalancePullRequests.includes(selectedRecord.unsavedBalancePullRequestID)
                    && !selectedRecord.abandonedBalancePullRequests.includes(message.requestId)) {
                    selectedRecord.abandonedBalancePullRequests.push(message.requestId);
                }
                if (message.status.percentage === 100){
                    selectedRecord.balancePullInProgress = false;
                }
            }
            return {
                ...state,
                datasetRecords: records,
            }
        }
        case ACTIONS.SHOW_UPDATE_ALL_BALANCES_CONFIRM_MODAL.type: {
            return {
                ...state,
                showUpdateAllBalancesConfirmModal: true,
            }
        }
        case ACTIONS.HIDE_UPDATE_ALL_BALANCES_CONFIRM_MODAL.type: {
            return {
                ...state,
                showUpdateAllBalancesConfirmModal: false,
            }
        }
        case ACTIONS.PROCESS_NEXT_BALANCE_PULL_REQUEST.type: {
            const records = [...state.datasetRecords];
            let selectedRecord = null;
            let startIndex = 0;

            if (action.payload != null) {
                selectedRecord = getSelectedRecordAndParams(action.payload, records).selectedRecord;
                if (selectedRecord != null) {
                    startIndex = state.datasetRecords.indexOf(selectedRecord) + 1;
                }
            }

            let index = -1;
            for (let i = startIndex; i < records.length; i++) {
                const record = records[i];
                if (!record.isEditing && !record.balancePullInProgress) {
                    index = i;
                    break;
                }
            }

            if (index != -1) {
                records[index].requestBalancePull = true;
                if (action.payload == null) {
                    return {
                        ...state,
                        balancePullingStateChangeTimestamp: new Date().toISOString(),
                        updateAllBalances: true,
                    }
                } else {
                    return {
                        ...state,
                        balancePullingStateChangeTimestamp: new Date().toISOString(),
                    }
                }
            } else {
                return {
                    ...state,
                    updateAllBalances: false,
                }   
            }
        }
        case ACTIONS.TOGGLE_DATA_KEY_VIEW.type: {
            const records = [...state.datasetRecords];
            const { selectedRecord } = getSelectedRecordAndParams(action.payload, records);
            if (selectedRecord != null) {
                if (selectedRecord == state.recordBeingEdited) {
                    selectedRecord.showDataKeySelection = false;
                    return {
                        ...state,
                        recordBeingEdited: undefined,
                        datasetRecords: records,
                    }
                } else {
                    if (state.recordBeingEdited != null) {
                        state.recordBeingEdited.showDataKeySelection = false;
                    }
                    selectedRecord.showDataKeySelection = true;
                    return {
                        ...state,
                        recordBeingEdited: selectedRecord,
                        datasetRecords: records,
                    }
                }
            } else {
                return state;
            }
        }
        case ACTIONS.SET_FX_INPUTS.type: {
            const { recordBeingEdited } = state;
            // Only currency can be provided - FX type is defaulted to 'Period Average' and FX date is defaulted to previous month end date
            // Only currency and FX type 'Period Average' or 'Period End' can be provided - the FX date is defaulted to previous month end date
            // For STAT currency, FX date and type provided are discarded
            // In all other cases, all 3 inputs are required - this is checked during validation in the DataSourcesService
            // If a non-month end date is provided for FX type 'Period Average' or 'Period End', it is set to the month end of the date provided
            if (recordBeingEdited?.currency != null) {
                if (recordBeingEdited.currency == CONSTANTS.STAT_CURRENCY) {
                    (recordBeingEdited as any).fxDate = undefined;
                    (recordBeingEdited as any).fxType = undefined;

                } else {
                    if (StringUtils.isNullOrEmpty(recordBeingEdited.fxType) 
                        || ((recordBeingEdited.fxType == CONSTANTS.FX_TYPE_PERIOD_AVERAGE || recordBeingEdited.fxType == CONSTANTS.FX_TYPE_PERIOD_END)
                            && StringUtils.isNullOrEmpty(recordBeingEdited.fxDate))) {
                        if (StringUtils.isNullOrEmpty(recordBeingEdited.fxType)) {
                            recordBeingEdited.fxType = CONSTANTS.FX_TYPE_PERIOD_AVERAGE;
                        }
                        const d = endOfMonth(subMonths(startOfMonth(new Date()), 1));
                        recordBeingEdited.fxDate = format(d, 'yyyy-MM-dd');
                    } else if (recordBeingEdited.fxType == CONSTANTS.FX_TYPE_PERIOD_AVERAGE || recordBeingEdited.fxType == CONSTANTS.FX_TYPE_PERIOD_END) {
                        const d = endOfMonth(Date.parse(recordBeingEdited.fxDate));
                        recordBeingEdited.fxDate = format(d, 'yyyy-MM-dd');
                    }
                }
            }
            return {
                ...state,
                datasetRecords: [...state.datasetRecords],
            }
        }
        case ACTIONS.SET_SELECTED_CUSTOM_COA.type: {
            const { valueMap, referenceMap } = action.payload;
            const existingReferenceMap = state.customCoaReference || new Map<string, string>();
            (referenceMap as Map<string, string>).forEach((value: string, key: string) => existingReferenceMap.set(key, value));

            return {
                ...state,
                selectedCustomCoaValues: new Map(valueMap),
                customCoaReference: existingReferenceMap,
            }
        }
        case ACTIONS.SET_CUSTOM_COA_REFERENCE.type: {
            return {
                ...state,
                customCoaReference: action.payload,
            }
        }
        case ACTIONS.SET_SAVE_ERROR.type: {
            const { recordBeingEdited } = state;
            if (recordBeingEdited != null) {
                recordBeingEdited.saveError = action.payload;
            }
            return {
                ...state,
                datasetRecords: [...state.datasetRecords],
            }
        }
        case ACTIONS.SET_TP_LOV.type: {
            return {
                ...state,
                tpLOV: action.payload,
            };
        }
        case ACTIONS.TOGGLE_CATEGORY_EXPANSION.type: {
            const editingRecord = (state.recordBeingEdited as any);
            if (editingRecord['expandedCategories'] == undefined) {
                editingRecord['expandedCategories'] = new Array<string>();
            }
            const expandedCategories = (editingRecord['expandedCategories'] as string[])
            if (expandedCategories.includes(action.payload)) {
                editingRecord['expandedCategories'] = expandedCategories.filter(x => x != action.payload);
            } else {
                expandedCategories.push(action.payload);
            }

            return {
                ...state,
            }
        }
        case ACTIONS.TOGGLE_ACCOUNT_EXPANSION.type: {
            const editingRecord = (state.recordBeingEdited as any);
            if (editingRecord['expandedAccount'] == action.payload) {
                editingRecord['expandedAccount'] = '';
            } else {
                editingRecord['expandedAccount'] = action.payload;
            }

            return {
                ...state,
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}
