import { DatasetRecord } from "src/models/tp-allocation/DatasetRecord";
import { PullBalancePayload } from "src/models/calculation-builder/PullBalanceRequest";
import { TPLOV, getEmptyLOVs } from "src/models/common/TPLOV";

export interface DatasetState {
    datasetRecords: Array<DatasetRecord>,
    loading: boolean,
    showDecimals: boolean,
    recordIdCounter: number,
    recordBeingEdited?: DatasetRecord,
    recordBeingEditedOriginal?: DatasetRecord,
    coaTypes: string[],
    recordIdForAction: string,
    showEditActionConfirmModal: boolean,
    showDuplicateActionConfirmModal: boolean,
    showDeleteActionConfirmModal: boolean,
    showStep3ConfirmModal: boolean,
    showUpdateAllBalancesConfirmModal: boolean,
    error?: string,
    savedRecordId?: string,
    deleteDataSourcePayload?: any,
    pullBalanceRequestPayloads: PullBalancePayload[],
    pullBalanceRequestCounter: number,
    updateAllBalances: boolean,
    selectedCustomCoaValues?: Map<string, string>,
    customCoaReference?: Map<string, string>,
    showTPAllocationModal: boolean,
    tpLOV: TPLOV,
    balancePullingStateChangeTimestamp?: string
}

export const initialState : DatasetState = {
    datasetRecords: [] as DatasetRecord[],
    loading: true,
    showDecimals: false,
    recordIdCounter: 0,
    coaTypes: [] as string[],
    recordIdForAction: "-1",
    showEditActionConfirmModal: false,
    showDuplicateActionConfirmModal: false,
    showDeleteActionConfirmModal: false,
    showStep3ConfirmModal: false,
    showUpdateAllBalancesConfirmModal: false,
    pullBalanceRequestPayloads: [] as PullBalancePayload[],
    pullBalanceRequestCounter: 0,
    updateAllBalances: false,
    showTPAllocationModal: false,
    tpLOV: getEmptyLOVs(),
}