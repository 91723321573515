import { ExpandableSection, SpaceBetween, Button, Alert, Box } from "@amzn/awsui-components-react";
import React, { useEffect } from "react";
import CONSTANTS from "src/utils/constants";
import { AllocationGroupsContext } from "./AllocationGroupsContainer";
import AllocationGroup from "src/models/tp-allocation/AllocationGroup";
import AllocationGroupFormulasGrid from "./AllocationGroupFormulasGrid";
import { ACTIONS } from "src/services/tp-allocation/allocation-groups/AllocationGroupsReducer";
import { TPAllocationContext } from "src/services/tp-allocation/TPAllocationContext";

export default function AllocationGroupContainer(props: { group: AllocationGroup }) {
    const { group } = props;
    const { tpAllocationState } = React.useContext(TPAllocationContext);
    const { groupsDispatch } = React.useContext(AllocationGroupsContext);
    const { viewMode } = tpAllocationState;
    const isEditable = CONSTANTS.VIEW_MODE.EDITABLE === viewMode;

    useEffect(() => {
        if (viewMode == CONSTANTS.VIEW_MODE.EDITABLE){
            return;
        }
        groupsDispatch(ACTIONS.SET_FORMULA_BEING_EDITED.withPayload({group, formula: null}));
    }, [viewMode])

    return <ExpandableSection data-class="polarisExpandableSection"
            key={`groupContainer-${group.allocationGroupId}`}
            variant="container"
            defaultExpanded={true}
            headerText={group.description}
            headerDescription={group.allocationGroupDescription}
            headerActions={
                isEditable && 
                <SpaceBetween
                    direction="horizontal"
                    size="xs"
                >
                    <Button data-class="subPrimaryButton" onClick={() => groupsDispatch(ACTIONS.SET_GROUP_BEING_EDITED.withPayload(group))}>Edit</Button>
                    <Button data-class="subPrimaryButton" onClick={() => groupsDispatch(ACTIONS.SET_REMOVE_GROUP_MODAL_PAYLOAD.withPayload(group))}>Delete Group</Button>
                </SpaceBetween> 
            } >
                <div className="dataSourceTableContainer">
                    <AllocationGroupFormulasGrid group={group}/>
                    { group.errorMessage && <Box padding={{ top: "l" }}>
                            <Alert
                                key={`groupErrorAlert-${group.allocationGroupId}`}
                                statusIconAriaLabel="Error"
                                type="error"
                                header="Allocation Group Error"
                            >
                                {group.errorMessage}
                            </Alert>
                        </Box>
                    }
                </div>
        </ExpandableSection>
}