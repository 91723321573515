import { MoreOptionsItem } from "./SavedSearchModel";


export default class SavedSearch {
    name: string;
    filters: Array<MoreOptionsItem>;

    constructor(name: string) {
        this.name = name;
        this.filters = [];
    }
}