import React from 'react';
import 'src/assets/styles/react-table.scss';
import TPESelectableReactTable from 'src/components/shared/TPESelectableReactTable';
import { Worksheet } from 'src/models/tp-allocation/WorksheetList';

export default function TPAllocationWorksheetSelectorGrid(props: {data: Worksheet[], loading: boolean, onSelect: (e: Worksheet | undefined) => any}) {

    const { data = [], loading, onSelect } = props;

    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "worksheetName",
            Header: (e: any) => <div className="cell-text">Name</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "lastExecutionTimeDisplay",
            Header: (e: any) => <div className="cell-text">Last executed time</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        }]
    ,[data]);

    return <div>
        <TPESelectableReactTable {...{ 
                data, 
                columnDefinitions, 
                className: 'worksheetsGrid', 
                isSearching: loading, 
                isSearchingMessage: 'Searching', 
                showFilters: false,
                onRowClicked:(isChecked, worksheet: Worksheet, index) =>  onSelect(isChecked ? worksheet : undefined)} 
        } />
    </div>
    
}