import React, { useEffect, useState } from 'react';
import ServiceCollection from 'src/services/ServiceCollection';
import {Container, Grid, Box, SpaceBetween, Button} from '@amzn/awsui-components-react/polaris';
import TemplateAndCalculationsSelector from '../shared/TemplateAndCalculationsSelector'
import ArrayUtils from 'src/utils/arrayUtils';
import CalculationsConfirmationGrid from '../shared/CalculationsConfirmationGrid'
import { CalculationTemplate } from 'src/models/calculation-builder/CalculationTemplate';
import { CLI } from 'src/models/common/CLI';
import CONSTANTS from 'src/utils/constants';
import TPENavigator from 'src/components/TPENavigator';
import TPEErrorWatcher from "src/components/shared/TPEErrorWatcher";
import {BulkCloneCalculation} from "src/models/calculation-builder/BulkCloneCalculation";


export default function CloneCalculationsView (props: { services: ServiceCollection }) {
    const {services} = props;
    const [selectedTemplate, setSelectedTemplate] = useState(undefined as undefined | CalculationTemplate);
    const [selectedCalculations, setSelectedCalculations] = useState([] as CLI[]);
    const [showConfirmationGrid, setShowConfirmationGrid] = useState(false);
    const [bulkCloneCalculationPayload, setBulkCloneCalculationPayload] = useState(undefined as undefined | BulkCloneCalculation);
    const [navigateURL, setNavigateURL] = useState('');
    const [clonedCalculations, setClonedCalculations] = useState([] as CLI[]);

    const navigateToCalcBuilder = (recordId: string) => {
        const url = CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + '/' + btoa(recordId);
        setNavigateURL(url);
    };

    const [cloneResult, isCloning, cloneError] = services.calculationBuilderService.bulkCloneCalculation(bulkCloneCalculationPayload);

    useEffect(() => {
        if (cloneResult == null) {
            return;
        }
        if (selectedCalculations.length < 2 && cloneResult.clonedCalculations != null && cloneResult.clonedCalculations.length == 1) {
            navigateToCalcBuilder(selectedCalculations[0].recordId);
        } else {
            if(cloneResult.clonedCalculations != null && cloneResult.clonedCalculations.length >= 1) {
                services.messageService.showSuccessAutoDismissBanner(`${cloneResult.clonedCalculations?.length} calculation(s) were successfully cloned from the template ${selectedTemplate?.templateName}
            : ${cloneResult.clonedCalculations?.reduce((result, calculation) => result + ", " + calculation)}.`);
            }
            if(cloneResult.errors != null && cloneResult.errors.length >= 1) {
                services.messageService.showErrorBanner(`${cloneResult.errors.length} calculation(s) could not be cloned due to these errors:
                ${cloneResult.errors?.reduce((error, calculation) => error + ", " + calculation)}.`);
            }
            selectedCalculations.forEach((calculation) => {
                if(cloneResult.clonedCalculations != null && cloneResult.clonedCalculations.indexOf(calculation.calculationNumber) > -1) {
                    clonedCalculations.push(calculation);
                }
            });
            if(cloneResult.clonedCalculations != null && cloneResult.clonedCalculations.length == 0) {
                return;
            }
            setClonedCalculations(clonedCalculations);
            setShowConfirmationGrid(true);
        }
    }, [cloneResult])

    const onCloneClicked = function() {
        if (selectedTemplate == null || ArrayUtils.isNullOrEmpty(selectedCalculations)) {
            services.messageService.showErrorAutoDismissBanner("Please select a template and at least 1 calculation to clone");
            return;
        }
        const calculations: string[] = []
        selectedCalculations.forEach((calculation) => calculations.push(calculation.calculationNumber));
        setBulkCloneCalculationPayload({
            templateId: selectedTemplate.templateId,
            calculationNumbers: calculations
        } as BulkCloneCalculation)
    }

    return <Container className="polaris-content-container">
    <TPENavigator path={navigateURL} />
    {
        showConfirmationGrid?
            <CalculationsConfirmationGrid
                headerText={`${clonedCalculations.length} Calculation(s) cloned from template "${selectedTemplate?.templateName}"`}
        calculations={clonedCalculations} />
    :
        <Grid
            gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}
    >
        <TemplateAndCalculationsSelector
            filterCLIsBySelectedTemplate
        label1='Select a Template'
        label2='Select New CLI(s) to clone'
        services={services}
        onTemplateSelected={(templateId) => setSelectedTemplate(templateId)}
        onCalculationsSelected={(calculations) => setSelectedCalculations(calculations)} />
    <Box float="right" padding={{ top: "xl" }}>
        <SpaceBetween direction="horizontal" size="s">
    <Button href="/calculations">Cancel</Button>
        <Button onClick={onCloneClicked}
        loading={isCloning}
        variant="primary"
        disabled={!selectedTemplate || selectedCalculations.length === 0}>
        Clone calculations
    </Button>
    </SpaceBetween>
    </Box>
    </Grid>
    }
    <TPEErrorWatcher services={services} errors={[cloneError]} />
    </Container>;
}