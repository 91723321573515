import React from 'react';
import 'src/assets/styles/react-table.scss';
import { Link } from '@amzn/awsui-components-react/polaris';
import { TPEReactTable, DefaultColumnFilter } from 'src/components/shared/TPEReactTable'
import TPENavigator from '../TPENavigator';



export default function AgreementsGrid(params: { data: any, showFilters: boolean, isSearching: boolean, onSortBy: (e: Array<any>) => any, onFilter: (e: Array<any>) => any }) {
    const { data, showFilters, isSearching, onSortBy, onFilter } = params;
    const [navigateURL, setNavigateURL] = React.useState('');

    const navigateToAgreementDetails = (agreementRecordId: string) => {
        setNavigateURL('/agreements/details/' + btoa(agreementRecordId));
    };

    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "agreementNumber",
            Header: (e: any) => <div className="cell-text">Agreement Number</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "agreementName",
            Header: (e: any) => <div className="cell-text">Agreement Name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) =>
                <Link className="cell-link agreementNameLink"
                    onFollow={detail => navigateToAgreementDetails(e.row.original.recordId)}>{e.value}</Link>,
        },
        {
            accessor: "agreementStatusCategory",
            Header: (e: any) => <div className="cell-text">Agreement Status Category</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "agreementStatus",
            Header: (e: any) => <div className="cell-text">Agreement Status</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "accountingOwner",
            Header: (e: any) => <div className="cell-text">TP Accounting Owner</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "taxOwner",
            Header: (e: any) => <div className="cell-text">TP Tax Owner</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "firstPartyCode",
            Header: (e: any) => <div className="cell-text">First Party</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : `${e.value} - ${e.row.original.firstParty}`}</div>,
        },
        {
            accessor: "secondPartyCode",
            Header: (e: any) => <div className="cell-text">Second Party</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : `${e.value} - ${e.row.original.secondParty}`}</div>,
        },
        {
            accessor: "lastUpdateDate",
            Header: (e: any) => <div className="cell-text">Last Updated</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : new Date(e.value).toLocaleString()}</div>,
        }]
    ,[]);


    // Render the UI for your table
    return (
        <div>
            <TPENavigator path={navigateURL}/>
            <TPEReactTable {...{ data, columnDefinitions, className: "agreementsWorklists", isSearching, showFilters, onFilter, onSortBy }} />
        </div>
    )
}