import React from "react";
import HelpPanel from "@amzn/awsui-components-react/polaris/help-panel";

export default function TpTaxVarianceReportHelpPanel() {
    return (
        <HelpPanel header={"Report Navigation"}>
            <div>
                <p>
                    The Variance Report (VAR) allows navigating to other pre-configured Transfer Pricing Reports through the hyperlinks contained in the following columns:
                    <ul>
                        <li><b>CWB ID:</b> Redirects users to a Calculation Workbook Report (CWB) displaying all Calculation Line Items (CLIs) tagged to the selected Calculation Workbook ID (CWB ID). </li>
                        <li><b>CLI ID:</b> Redirects users to a Margin Report (MAR) for the selected, individual CLI.</li>
                        <li><b>Actual YTD %:</b> Redirects users to a Margin Report (MAR) for the selected, individual CLI. </li>
                        <li><b>Provider:</b> Redirects users to a Segmented Legal Entity Financials report (LEF). </li>
                    </ul>
                </p>
                <p>
                    <b>Pro Tip: Use breadcrumbs to navigate back to the VAR page from linked reports.</b>
                </p>
            </div>
        </HelpPanel>
    );
};