import { ColumnLayout, FormField, Multiselect, Select, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import { CalculationTemplate } from 'src/models/calculation-builder/CalculationTemplate';
import { CLI } from 'src/models/common/CLI';
import TPESearchRequest, { DbConjunctor, DbExpression, DbOperator } from 'src/models/common/TPESearchRequest';
import ServiceCollection from 'src/services/ServiceCollection';
import TemplateSelector from './TemplateSelector'

type ComponentProps = {
    services: ServiceCollection,
    label1: string,
    label2: string,
    filterCLIsBySelectedTemplate: boolean,
    onTemplateSelected: (template?: CalculationTemplate) => void,
    onCalculationsSelected: (calculations: CLI[]) => void
}

export default function TemplateAndCalculationsSelector(props:ComponentProps){
    const {
        label1, 
        label2, 
        filterCLIsBySelectedTemplate,
        services, 
        onTemplateSelected, 
        onCalculationsSelected
    } = props;


    const [templateWorkbookId, setTemplateWorkbookId] = useState(undefined as string | undefined);
    const searchParams = useMemo(() => {
        const searchYetToStartRequest = new TPESearchRequest();
        searchYetToStartRequest.filterExpression = {
            conjunctor: DbConjunctor.AND,
            expressions: [
                {
                    attributeName: "atp_calculation_status",
                    operator: DbOperator.IN,
                    values: ["Yet to Start"]
                },
                {
                    attributeName: "workbook_id", 
                    operator: DbOperator.EQUALS, 
                    values: [templateWorkbookId || '']
                }
            ]
        };
        if (templateWorkbookId != null){
            searchYetToStartRequest.searchStartedDate = new Date();
        }
        return searchYetToStartRequest
    }, [templateWorkbookId])

    const [searchResult, isSearching, searchError] = services.calculationsService.searchCalculationLines(searchParams);

    useEffect(() => {
        if (searchResult == null){
            return;
        }
        setCalculations(searchResult.clis);
    }, [searchResult])
    
    
    const [calculations, setCalculations] = useState([] as CLI[]);
    const [selectedTemplate, setSelectedTemplate] = React.useState(undefined as undefined | CalculationTemplate);
    const [selectedOptions, setSelectedOptions] = React.useState([] as any[]);
    
    return <ColumnLayout className='templateAnCalculationSelectorLayout' columns={2}>
        <TemplateSelector services={services} label={label1} 
            onTemplateSelected={(template?:CalculationTemplate) => {
                setSelectedTemplate(template);
                setTemplateWorkbookId(template?.workbookId);
                onTemplateSelected(template);
                setSelectedOptions([]);
            } 
        } />
        <FormField
                    label={label2}
                    errorText={searchError}                
                >
            <Multiselect disabled={filterCLIsBySelectedTemplate && !selectedTemplate}
                loadingText='Searching CLIs...'
                statusType={isSearching?'loading':'finished'}
                selectedOptions={selectedOptions}
                onChange={({ detail }) => {
                    const selected = [...detail.selectedOptions];
                    setSelectedOptions(selected);
                    onCalculationsSelected(selected as CLI[]);
                }}
                deselectAriaLabel={e => `Remove ${e.label}`}
                options={calculations.map(x => ({...x,
                    label: x.calculationNumber,
                    value: x.calculationNumber,
                    description: `Agreement: ${x.agreementName}`
                  }))}
                filteringType="auto"
                hideTokens
                placeholder={selectedOptions.length === 0?"Choose CLIs": `${selectedOptions.length} selected`}
                selectedAriaLabel="Selected"
                />
        </FormField>
    </ColumnLayout>;
}