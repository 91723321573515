import { useState, useEffect } from "react";
import AllocationGroup, { AllocationGroupFormula } from "src/models/tp-allocation/AllocationGroup";
import apiService from 'src/services/ApiCallService';
import ErrorUtils from "src/utils/ErrorUtils";
import StringUtils from "src/utils/stringUtils";

export default class TPAllocationGroupsService {
    
    createNewFormula(group: AllocationGroup, sequence?: number) {
        return {
            allocationFormulaId: `newFormula${sequence}`,
            sequence: sequence == undefined? (group.formulas || []).length + 1 : sequence,
            allocationGroupId: group.allocationGroupId,
            description: '',
            mapping: {
                mappingType: "Company",
                values: [] as string[]
            },
            isBeingEdited: true,
            isNew: true,
            expression: '',
        } as AllocationGroupFormula;
    }
    getAllocationGroups(shouldPull: boolean, worksheetId?: string, worsheetVersion?: number): [AllocationGroup[], boolean, string] {
        const [result, setResult] = useState([] as AllocationGroup[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(worksheetId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetGroups(worksheetId, worsheetVersion == null? -1 : worsheetVersion);
                    const json = await response.json();
                    setResult(convertAllocationGroupsFromApi(json.allocationGroups, false));
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (shouldPull && worksheetId != null) {
                fetchData(worksheetId);
            }
        }, [shouldPull, worksheetId]);
        return [result, loading, error]
    }
    
    getTemplateAllocationGroups(templateId?: string, templateVersion?: number, shouldRefreshGroups?: boolean): [AllocationGroup[], boolean, string] {
        const [result, setResult] = useState([] as AllocationGroup[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(templateId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetTemplateGroups(templateId, templateVersion == null? -1 : templateVersion);
                    const json = await response.json();
                    setResult((json.allocationGroups as AllocationGroup[]).map(x => ({...x,parentEntityId: templateId, parentEntityVersion : templateVersion == null? -1 : templateVersion})));
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (templateId != null && shouldRefreshGroups === true) {
                fetchData(templateId);
            }
        }, [templateId, shouldRefreshGroups]);
        return [result, loading, error]
    }

    getAllocationGroupFormulas(worksheetId?: string, worsheetVersion?: number, allocationGroupId?: string, refreshFormulasFlag?:string, executionPeriodId?: string): [AllocationGroupFormula[], boolean, string] {
        const [result, setResult] = useState([] as AllocationGroupFormula[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(worksheetId: string, allocationGroupId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetGroupFormulas(worksheetId, worsheetVersion == null? -1 : worsheetVersion, allocationGroupId, executionPeriodId);
                    const json = await response.json();
                    setResult(json.allocationFormulas);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (worksheetId != null && allocationGroupId != null) {
                fetchData(worksheetId, allocationGroupId);
            }
        }, [worksheetId, refreshFormulasFlag]);
        return [result, loading, error]
    }

    getAllocationTemplateGroupFormulas(templateId?: string, templateVersion?: number, allocationGroupId?: string, shouldRefreshGroupFormulas?:boolean): [AllocationGroupFormula[], boolean, string] {
        const [result, setResult] = useState([] as AllocationGroupFormula[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(templateId: string, allocationGroupId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetTemplateGroupFormulas(templateId, templateVersion == null? -1 : templateVersion, allocationGroupId);
                    const json = await response.json();
                    setResult(json.allocationFormulas);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (shouldRefreshGroupFormulas && templateId != null && allocationGroupId != null) {
                fetchData(templateId, allocationGroupId);
            }
        }, [templateId, shouldRefreshGroupFormulas]);
        return [result, loading, error]
    }

    saveWorksheetAllocationGroup(payload?: AllocationGroup): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(group: AllocationGroup) {
                try {
                    setLoading(true);
                    const response = await (group.isNew?
                        apiService.createWorksheetAllocationGroup(group.parentEntityId, {...group, worksheetId: group.parentEntityId}) :
                        apiService.updateWorksheetAllocationGroup(group.parentEntityId, {...group, worksheetId: group.parentEntityId}));
                    const json = (await response.json()).allocationGroup;
                    json.unsavedId = group.allocationGroupId;
                    json.parentEntityId = json.worksheetId;
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    deleteAllocationGroup(deleteGroupRequest: any) : [AllocationGroup, boolean, string] {
        const [result, setResult] = useState(null as unknown as AllocationGroup);
        const [deleting, setDeleting] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(worksheetId:string, groupId: string) {
                try {
                    setError('');
                    setDeleting(true);
                    const response = await apiService.deleteWorksheetAllocationGroup(worksheetId, groupId);
                    const json = await response.json()
                    setResult(json.allocationGroup);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setDeleting(false);
                }
            }
            if (deleteGroupRequest != null) {
                fetchData(deleteGroupRequest.parentEntityId, deleteGroupRequest.allocationGroupId);
            }
        }, [deleteGroupRequest]);
        return [result, deleting, error]
    }

    saveWorksheetGroupFormula(worksheetId:string, payload?: AllocationGroupFormula): [AllocationGroupFormula, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(formula: AllocationGroupFormula) {
                try {
                    setLoading(true);
                    if (formula.mapping != null){
                        formula.mapping.values = (formula.mapping.values || []).map(x => x.trim()).filter(x => !StringUtils.isNullOrEmpty(x))
                    }
                    const response = await (formula.isNew?
                        apiService.createWorksheetAllocationGroupFormula(worksheetId, formula.allocationGroupId, {...formula, worksheetId}) :
                        apiService.updateWorksheetAllocationGroupFormula(worksheetId, formula.allocationGroupId, {...formula, worksheetId}));
                    const json = (await response.json()).allocationFormula;
                    json.unsavedId = formula.allocationFormulaId;
                    // Doing this since API won't save the formula if there were validation errors when creating
                    json.isNew = formula.isNew && json.valid === false;
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    deleteWorksheetGroupFormula(deleteFormulaRequest: any) : [AllocationGroup, boolean, string] {
        const [result, setResult] = useState(null as unknown as AllocationGroup);
        const [deleting, setDeleting] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(worksheetId:string, groupId: string, formulaId: string) {
                try {
                    setError('');
                    setDeleting(true);
                    const response = await apiService.deleteWorksheetAllocationGroupFormula(worksheetId, groupId, formulaId);
                    const json = await response.json()
                    setResult(json.allocationFormula);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setDeleting(false);
                }
            }
            if (deleteFormulaRequest != null) {
                fetchData(deleteFormulaRequest.worksheetId, deleteFormulaRequest.allocationGroupId, deleteFormulaRequest.allocationFormulaId);
            }
        }, [deleteFormulaRequest]);
        return [result, deleting, error]
    }
    
    saveTemplateAllocationGroup(payload?: AllocationGroup): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(group: AllocationGroup) {
                try {
                    setLoading(true);
                    const response = await (group.isNew?
                        apiService.createWorksheetTemplateAllocationGroup(group.parentEntityId, {...group, templateId: group.parentEntityId}) :
                        apiService.updateWorksheetTemplateAllocationGroup(group.parentEntityId, {...group, templateId: group.parentEntityId}));
                    const json = (await response.json()).allocationGroup;
                    json.unsavedId = group.allocationGroupId;
                    json.parentEntityId = json.templateId;
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    deleteTemplateAllocationGroup(deleteGroupRequest: any) : [AllocationGroup, boolean, string] {
        const [result, setResult] = useState(null as unknown as AllocationGroup);
        const [deleting, setDeleting] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(templateId:string, groupId: string) {
                try {
                    setError('');
                    setDeleting(true);
                    const response = await apiService.deleteWorksheetTemplateAllocationGroup(templateId, groupId);
                    const json = await response.json()
                    setResult(json.allocationGroup);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setDeleting(false);
                }
            }
            if (deleteGroupRequest != null) {
                fetchData(deleteGroupRequest.parentEntityId, deleteGroupRequest.allocationGroupId);
            }
        }, [deleteGroupRequest]);
        return [result, deleting, error]
    }

    saveTemplateGroupFormula(templateId:string, payload?: AllocationGroupFormula): [AllocationGroupFormula, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(formula: AllocationGroupFormula) {
                try {
                    setLoading(true);
                    if (formula.mapping != null){
                        formula.mapping.values = (formula.mapping.values || []).map(x => x.trim()).filter(x => !StringUtils.isNullOrEmpty(x))
                    }
                    const response = await (formula.isNew?
                        apiService.createWorksheetTemplateAllocationGroupFormula(templateId, formula.allocationGroupId, {...formula, templateId}) :
                        apiService.updateWorksheetTemplateAllocationGroupFormula(templateId, formula.allocationGroupId, {...formula, templateId}));
                    const json = (await response.json()).allocationFormula;
                    json.unsavedId = formula.allocationFormulaId;
                    // Doing this since API won't save the formula if there were validation errors when creating
                    json.isNew = formula.isNew && json.valid === false;
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    deleteTemplateGroupFormula(deleteFormulaRequest: any) : [AllocationGroup, boolean, string] {
        const [result, setResult] = useState(null as unknown as AllocationGroup);
        const [deleting, setDeleting] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(templateId:string, groupId: string, formulaId: string) {
                try {
                    setError('');
                    setDeleting(true);
                    const response = await apiService.deleteWorksheetTemplateAllocationGroupFormula(templateId, groupId, formulaId);
                    const json = await response.json()
                    setResult(json.allocationFormula);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setDeleting(false);
                }
            }
            if (deleteFormulaRequest != null) {
                fetchData(deleteFormulaRequest.templateId, deleteFormulaRequest.allocationGroupId, deleteFormulaRequest.allocationFormulaId);
            }
        }, [deleteFormulaRequest]);
        return [result, deleting, error]
    }

}

function convertAllocationGroupsFromApi(allocationGroups: any[], isTemplate: boolean): AllocationGroup[] {
    return allocationGroups.map(x => {
        return {...x, 
            parentEntityId: isTemplate? x.templateId : x.worksheetId, 
            parentEntityVersion: isTemplate? x.templateVersion:x.worksheetVersion}
    });
}
