import {useEffect, useState} from "react";
import WorksheetList, { Worksheet, WorksheetTemplate } from "src/models/tp-allocation/WorksheetList";
import ErrorUtils from "src/utils/ErrorUtils";
import StringUtils from "src/utils/stringUtils";
import apiService from '../ApiCallService';
import TPESearchRequest from "src/models/common/TPESearchRequest";
import { SearchWorksheetsResult } from "src/models/tp-allocation/SearchWorksheetsResult";
import FormattingService from "../utils/FormattingService";
import { SearchWorksheetAllocationFormulasRequest } from "src/models/tp-allocation/SearchWorksheetAllocationFormulasRequest";
import { SearchWorksheetAllocationFormulasResult } from "src/models/tp-allocation/SearchWorksheetAllocationFormulasResult";
import CONSTANTS from "src/utils/constants";
import TPAllocationUtils from "./TPAllocationUtils";
import CreateWorksheetRequest from "src/models/tp-allocation/CreateWorksheetRequest";
import moment from "moment";
import AllocationGroup, { AllocationGroupFormula } from "src/models/tp-allocation/AllocationGroup";
import { DatasetRecord } from "src/models/tp-allocation/DatasetRecord";
import WorksheetTotal from "src/models/tp-allocation/WorksheetTotal";
import { SavingStatus } from "src/models/common/SavingStatus";
import ValidateWorksheetResponse from "src/models/tp-allocation/ValidateWorksheetResponse";
import DateUtils from "src/utils/dateUtils";

export default class TPAllocationService {
    

    flattenGroupFormulas(groups: AllocationGroup[]) {
        return groups.reduce((a, b) => a.concat(b.formulas), [] as AllocationGroupFormula[]);
    }

    listWorksheets(query: string): [WorksheetList, boolean, string] {
        const [result, setResult] = useState(null as unknown as WorksheetList);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function getData() {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationTemplatesAndWorksheets();
                    const worksheetList: WorksheetList = await response.json();
                    worksheetList.templates?.forEach(x => x.placeholdersMap = TPAllocationUtils.convertToPlaceholdersMap(x.placeholders || []));
                    setResult(worksheetList);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query)) {
                getData();
            }
        }, [query]);
        return [result, loading, error]
    }
    
    getWorksheetDetails(query: string, worksheetId: string): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetDetails(worksheetId);
                    const json = await response.json();
                    const worksheet = json.worksheet as Worksheet;
                    worksheet.lastExecutionTimeDisplay = moment(worksheet?.lastExecutionTimestamp).format("YYYY/MM/DD hh:mm a");
                    updateDates(worksheet);
                    setResult(worksheet);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(worksheetId)) {
                fetchData();
            }
        }, [query, worksheetId]);
        return [result, loading, error]
    }

    getLatestWorksheetExecutionVersion(worksheetId: string | undefined, periodId: string | undefined): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(worksheetIdParam: string, periodIdParam: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getLatestWorksheetExecutionVersion(worksheetIdParam, periodIdParam);
                    const json = await response.json();
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(worksheetId) && !StringUtils.isNullOrEmpty(periodId)) {
                fetchData(worksheetId as string, periodId as string);
            }
        }, [worksheetId, periodId]);
        return [result, loading, error]
    }

    getWorksheetTemplateDetails(query: string, templateId: string): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetTemplateDetails(templateId);
                    const json = await response.json();
                    const template = json.template as WorksheetTemplate;
                    updateDates(template);
                    setResult(template);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(templateId)) {
                fetchData();
            }
        }, [query, templateId]);
        return [result, loading, error]
    }

    searchWorksheets(payload: TPESearchRequest): [SearchWorksheetsResult, boolean, string] {
        const formattingService: FormattingService = new FormattingService();
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchTPAllocationWorksheets(payload);
                    const json = await response.json() as SearchWorksheetsResult;
                    json.worksheets.forEach((x: any) => x.lastExecutionTimeDisplay = DateUtils.formatTimestamp(x.lastExecutionTimestamp));
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData();
            }
        }, [payload]);
        return [result, loading, error]
    }

    searchTPAllocationWorksheetFormulas(payload: SearchWorksheetAllocationFormulasRequest): [SearchWorksheetAllocationFormulasResult, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchTPAllocationWorksheetFormulas(payload);
                    const json = await response.json() as SearchWorksheetAllocationFormulasResult;
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData();
            }
        }, [payload]);
        return [result, loading, error]
    }

    getAllCoaSegments(query?: string) : [Map<string, string[]> | null, boolean, string] {
        // Using a local map so that callers don't get notified when the map is partially populated
        const localMap = new Map<string, string[]>();
        const [lovMap, setLOVMap] = useState(null as Map<string, string[]> | null);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        // Populates the map entries as responses arrive
        async function handleCoaResponse(value: Response, segmentKey: string){
            localMap.set(segmentKey, (await value.json()).coaData);
        }

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const [channels, companies, costCenters, accounts, locations, products, projects] = await Promise.all([
                        await apiService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.CHANNEL.WEB_API),
                        apiService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.WEB_API),
                        apiService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.COST_CENTER.WEB_API),
                        apiService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.WEB_API),
                        apiService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.LOCATION.WEB_API),
                        apiService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.PRODUCT_LINE.WEB_API),
                        apiService.getCoaData(CONSTANTS.COA_SEGMENT_MAPPING.PROJECT.WEB_API),
                    ])
                    await handleCoaResponse(channels, CONSTANTS.COA_SEGMENT_MAPPING.CHANNEL.UI);
                    await handleCoaResponse(companies, CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI);
                    await handleCoaResponse(costCenters, CONSTANTS.COA_SEGMENT_MAPPING.COST_CENTER.UI);
                    await handleCoaResponse(accounts, CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI);
                    await handleCoaResponse(locations, CONSTANTS.COA_SEGMENT_MAPPING.LOCATION.UI);
                    await handleCoaResponse(products, CONSTANTS.COA_SEGMENT_MAPPING.PRODUCT_LINE.UI);
                    await handleCoaResponse(projects, CONSTANTS.COA_SEGMENT_MAPPING.PROJECT.UI);
                    // Setting the return map here so that callers get notified
                    setLOVMap(localMap);
                }
                catch (ex) {
                    console.error(ex);
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query)) {
                fetchData();
            }
        }, [query]);
        return [lovMap, loading, error]
    }

    createWorksheet(payload: CreateWorksheetRequest): [Worksheet, boolean, string] {
        const [result, setResult] = useState(null as unknown as Worksheet);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(payload: CreateWorksheetRequest) {
                try {
                    setLoading(true);
                    const response = await apiService.createWorksheet(payload);
                    const json = await response.json();
                    setResult(json.worksheet);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    createWorksheetTemplate(query: string, templateName: string): [WorksheetTemplate, boolean, string] {
        const [result, setResult] = useState(null as unknown as WorksheetTemplate);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(templateName: string) {
                try {
                    setLoading(true);
                    const response = await apiService.createWorksheetTemplate(templateName);
                    const json = await response.json();
                    setResult(json.template);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(templateName)) {
                postData(templateName);
            }
        }, [query]);
        return [result, loading, error]
    }

    submitWorksheet(query: string, worksheetId: string, notes: string): [any, boolean, string] {
        const [result, setResult] = useState(null as unknown as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(worksheetId: string, notes: string) {
                try {
                    setLoading(true);
                    const response = await apiService.submitWorksheet(worksheetId, notes);
                    const json = await response.json();
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(worksheetId) && !StringUtils.isNullOrEmpty(notes)) {
                postData(worksheetId, notes);
            }
        }, [query]);
        return [result, loading, error]
    }

    approveWorksheet(query: string, worksheetId: string): [any, boolean, string] {
        const [result, setResult] = useState(null as unknown as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(worksheetId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.approveWorksheet(worksheetId);
                    const json = await response.json();
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(worksheetId)) {
                postData(worksheetId);
            }
        }, [query]);
        return [result, loading, error]
    }

    rejectWorksheet(query: string, worksheetId: string, notes: string): [any, boolean, string] {
        const [result, setResult] = useState(null as unknown as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(worksheetId: string, notes: string) {
                try {
                    setLoading(true);
                    const response = await apiService.rejectWorksheet(worksheetId, notes);
                    const json = await response.json();
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(worksheetId) && !StringUtils.isNullOrEmpty(notes)) {
                postData(worksheetId, notes);
            }
        }, [query]);
        return [result, loading, error]
    }

    validateWorksheet(validateWorksheetId: string | null): [ValidateWorksheetResponse | null, boolean, string] {
        const [result, setResult] = useState(null as ValidateWorksheetResponse | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(worksheetId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.validateWorksheet(worksheetId);
                    const json = await response.json();
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (validateWorksheetId != null) {
                postData(validateWorksheetId);
            }
        }, [validateWorksheetId]);
        return [result, loading, error]
    }

    submitWorksheetTemplate(query: string, templateId: string, notes: string): [any, boolean, string] {
        const [result, setResult] = useState(null as unknown as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(templateId: string, notes: string) {
                try {
                    setLoading(true);
                    const response = await apiService.submitWorksheetTemplate(templateId, notes);
                    const json = await response.json();
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(templateId) && !StringUtils.isNullOrEmpty(notes)) {
                postData(templateId, notes);
            }
        }, [query]);
        return [result, loading, error]
    }

    approveWorksheetTemplate(query: string, templateId: string): [any, boolean, string] {
        const [result, setResult] = useState(null as unknown as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(templateId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.approveWorksheetTemplate(templateId);
                    const json = await response.json();
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(templateId)) {
                postData(templateId);
            }
        }, [query]);
        return [result, loading, error]
    }

    rejectWorksheetTemplate(query: string, templateId: string, notes: string): [any, boolean, string] {
        const [result, setResult] = useState(null as unknown as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(templateId: string, notes: string) {
                try {
                    setLoading(true);
                    const response = await apiService.rejectWorksheetTemplate(templateId, notes);
                    const json = await response.json();
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query) && !StringUtils.isNullOrEmpty(templateId) && !StringUtils.isNullOrEmpty(notes)) {
                postData(templateId, notes);
            }
        }, [query]);
        return [result, loading, error];
    }

    getValidationMessagesForUnsavedItems(datasetRecords: DatasetRecord[], totals: WorksheetTotal[], allocationGroups: AllocationGroup[]): string[] {
        const hasUnsavedDatasetRecords = datasetRecords.some(x => x.isNewRecord);
        const hasUnsavedTotals = totals.some(x => x.savingStatus === SavingStatus.Unsaved);
        const allFormulas = this.flattenGroupFormulas(allocationGroups);
        const hasUnsavedFormulas = allFormulas.some(x => x.savingStatus === SavingStatus.Unsaved);

        const result = [];
        if (hasUnsavedDatasetRecords) result.push("There is an unsaved data source.");
        if (hasUnsavedTotals) result.push("There is an unsaved total.");
        if (hasUnsavedFormulas) result.push("There is an unsaved formula.");
        return result;
    }

    getValidationMessagesForMissingItems(datasetRecords: DatasetRecord[], totals: WorksheetTotal[], allocationGroups: AllocationGroup[]): string[] {
        const hasGroupWithNoFormulas = allocationGroups.some(x => x.formulas.length === 0);
        const result = [];
        if (datasetRecords.length === 0) result.push("At least one data source is required.");
        if (totals.length === 0) result.push("At least one total is required.");
        if (allocationGroups.length === 0) result.push("At least one allocation group is required.");
        if (hasGroupWithNoFormulas) result.push("Each allocation group must have at least one formula.");
        return result;
    }
}

function updateDates(entity: Worksheet | WorksheetTemplate) {
    entity.submitDateDisplay = moment(entity?.submitDate).format("YYYY/MM/DD");
    entity.reviewDateDisplay = moment(entity?.reviewDate).format("YYYY/MM/DD");
}