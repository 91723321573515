import React, { useEffect, useState } from "react";
import { Box, Button, ExpandableSection, SpaceBetween, Alert } from '@amzn/awsui-components-react';
import { TPAllocationContext } from "src/services/tp-allocation/TPAllocationContext";
import CONSTANTS from "src/utils/constants";
import { WorksheetTotalsState, initialState } from 'src/services/tp-allocation/totals/WorksheetTotalsState';
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import { worksheetTotalsReducer, ACTIONS } from "src/services/tp-allocation/totals/WorksheetTotalsReducer";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import ArrayUtils from "src/utils/arrayUtils";
import { TPAllocationState } from "src/services/tp-allocation/TPAllocationState";
import TotalsGrid from "./TotalsGrid";
import { DatasetRecord } from "src/models/tp-allocation/DatasetRecord";
import { ACTIONS as TP_ALLOCATION_ACTIONS } from "src/services/tp-allocation/TPAllocationReducer";
import { WorksheetMode } from "src/models/tp-allocation/TPAllocationEnums";
import { TPELoadingSpinner } from "src/components/shared/TPELoadingSpinner";
import { SavingStatus } from "src/models/common/SavingStatus";
import WorksheetTotal from "src/models/tp-allocation/WorksheetTotal";
import StringUtils from "src/utils/stringUtils";
import SimpleUseEffect from "src/components/shared/SimpleUseEffect";

export type TotalsContextType = {
    totalsState: WorksheetTotalsState,
    tpAllocationState: TPAllocationState,
    totalsDispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    dataSources: DatasetRecord[],
}

const TotalsProvider = (props: any) => {
    const { state, dispatch, services, children, tpAllocationState, dataSources } = props;
    const providerValue = React.useMemo(() => ({
        totalsState: state, totalsDispatch: dispatch, services, tpAllocationState, dataSources
    }), [state, dispatch, dataSources]);
    return (
        <TotalsContext.Provider value={providerValue}>
            {children}
        </TotalsContext.Provider>
    );
}
export const TotalsContext = React.createContext(null as unknown as TotalsContextType);

export default function TotalsContainer(props: { 
    containerTitle: string,
    expanded?: boolean,
    onCancel: () => void,    
}){
    const { containerTitle, expanded } = props;
    const { services, tpAllocationState, tpAllocationDispatch } = React.useContext(TPAllocationContext);
    const [totalsState, totalsDispatch] = useReducerWithLogger(worksheetTotalsReducer, initialState);
    const { viewMode, worksheet, datasetRecords, worksheetMode, worksheetTemplate, worksheetTotals, totalsNeedRefresh, totalsAreValid, isValidating } = tpAllocationState;
    const { totals, updateTotalPayload } = totalsState;
    
    const [containerLevelError, setContainerLevelError] = useState(undefined as string | undefined);
    const [refreshTotalsFlag, setRefreshTotalsFlag] = useState(undefined as string | undefined);
    const [wasSavingBeforeValidation, setWasSavingBeforeValidation] = useState(false);
    const [unsavedTotals, setUnsavedTotals] = useState(undefined as WorksheetTotal[] | undefined);
    const isTemplate = worksheetMode == WorksheetMode.TEMPLATE;
    const parentEntityId = isTemplate ? worksheetTemplate?.templateId : worksheet?.worksheetId;
    const parentEntityVersion = isTemplate ? worksheetTemplate?.templateVersion : worksheet?.worksheetVersion;

    const [totalsResult, totalsLoading, totalsError] = isTemplate?
        services.tpAllocationTotalsService.getWorksheetTemplateTotals(parentEntityId, parentEntityVersion, refreshTotalsFlag) :
        services.tpAllocationTotalsService.getWorksheetTotals(parentEntityId, parentEntityVersion, refreshTotalsFlag, worksheet?.executionPeriod?.id);

    const [saveTotalResult, isSavingTotal, savingError] = isTemplate? 
        services.tpAllocationTotalsService.saveTemplateTotal(updateTotalPayload): 
        services.tpAllocationTotalsService.saveTotal(updateTotalPayload);

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(totalsResult)){
            return;
        }
        totalsDispatch(ACTIONS.SET_WORKSHEET_TOTALS.withPayload(totalsResult));
    }, [totalsResult])

    useEffect(() => {
        if (saveTotalResult == null){
            return;
        }
        totalsDispatch(ACTIONS.REFRESH_WORKSHEET_TOTAL.withPayload(saveTotalResult));
        if (unsavedTotals != null && unsavedTotals.some(x => x.description === saveTotalResult.description)){
            unsavedTotals.shift();
            setUnsavedTotals(unsavedTotals);
        }
    },[saveTotalResult]) 

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(savingError)){
            setContainerLevelError(savingError);
            setUnsavedTotals([]);
        }
    }, [savingError])
    
    useEffect(() => {
        tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_TOTALS_ARE_VALID.withPayload(false));
    }, [updateTotalPayload])
    
    useEffect(() => {
        if (unsavedTotals == null){
            return;
        }
        if (unsavedTotals.length > 0){
            totalsDispatch(ACTIONS.SET_SAVE_TOTAL_PAYLOAD.withPayload(unsavedTotals[0]));
        }
        else if (wasSavingBeforeValidation && !isTemplate){
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_WORKSHEET_NEEDS_VALIDATION.withPayload(true))
        }
        else if (isTemplate) {
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_TOTALS_ARE_VALID.withPayload(totals.every(x => (x.validationErrors||[]).length === 0)))
        }
    }, [unsavedTotals])

    const onAddNewTotalClicked = function() {
        const newTotal = services.tpAllocationTotalsService.createNewTotal(worksheetTotals.length + 1, parentEntityId || '')
        totalsDispatch(ACTIONS.ADD_WORKSHEET_TOTAL.withPayload(newTotal))
    }

    useEffect(() => {
        tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_WORKSHEET_TOTALS.withPayload(totals))
    }, [totals])
    
    useEffect(() => {
        if (totalsNeedRefresh){
            setRefreshTotalsFlag(new Date().toISOString());
        }
    }, [totalsNeedRefresh])

    const saveAndValidate = () => {
        totalsDispatch(ACTIONS.SET_TOTAL_BEING_EDITED.withPayload(null))
        setContainerLevelError(undefined);
        const unsavedTotals = totals.filter(x => x.savingStatus === SavingStatus.Unsaved);
        if (unsavedTotals.length > 0 && !isSavingTotal){
            // Saving unsaved ones before validating!
            setWasSavingBeforeValidation(true);
            totalsDispatch(ACTIONS.SET_SAVE_TOTAL_PAYLOAD.withPayload(unsavedTotals[0]));
            setUnsavedTotals(unsavedTotals);
            return;
        }
        if (!isTemplate) {
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_WORKSHEET_NEEDS_VALIDATION.withPayload(true));
        }
        else {
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_IS_VALIDATING.withPayload(false));
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_TOTALS_ARE_VALID.withPayload(totals.every(x => (x.validationErrors||[]).length === 0)))
        }
    }

    const thereIsAtLeaset1TotalSaved = totals.length > 0 && totals[0].savingStatus !== SavingStatus.Unsaved;

    return (        
        <ExpandableSection data-class="polarisExpandableSection" 
            variant="container"
            defaultExpanded={expanded}
            headerText={containerTitle}            
        >
            <TotalsProvider services={services} state={totalsState} dispatch={totalsDispatch} tpAllocationState={tpAllocationState} dataSources={datasetRecords}>
                <div className="wizardBoxContentContainer">
                    <div className="totalsHeader">
                        <TPELoadingSpinner loading={totalsLoading} loadingText="Loading totals">
                            <SpaceBetween direction="vertical" size="l">
                                <TotalsGrid onTotalsLevelError={setContainerLevelError} />
                                { containerLevelError && <Alert
                                        key={`totalsErrorAlert`}
                                        statusIconAriaLabel="Error"
                                        type="error"
                                        header="Totals Error"
                                    >
                                        {containerLevelError}
                                    </Alert> 
                                }
                            </SpaceBetween>
                        </TPELoadingSpinner>
                    </div>
                    {viewMode === CONSTANTS.VIEW_MODE.EDITABLE && 
                        <div className="actionButtonsContainer">
                            <Box float="left">
                                <Button data-class="subPrimaryButton" onClick={onAddNewTotalClicked}>Add total</Button>
                            </Box>
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="m">
                                    <Button 
                                        loading={unsavedTotals != null && unsavedTotals.length > 0 || isValidating} 
                                        data-class={`saveButton ${totalsAreValid && !isValidating? 'button-valid' : 'button-invalid'}`}
                                        onClick={() => saveAndValidate()}
                                        iconName={totalsAreValid? "status-positive" : undefined}>
                                    {unsavedTotals != null && unsavedTotals.length > 0 || isValidating? "Saving..." : "Save"}
                                    </Button>
                                    <Button data-class="nextButton" disabled={!thereIsAtLeaset1TotalSaved} variant="primary" onClick={() => tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SELECT_NEXT_WIZARD_STEP)}>
                                        Next Step
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        </div>
                    }
                    <SimpleUseEffect 
                        useEffectVars={[saveTotalResult, savingError]} 
                        action={() => tpAllocationDispatch(TP_ALLOCATION_ACTIONS.CHECK_AND_REFRESH_ENTITY_DETAILS)}
                    />
                </div>
            </TotalsProvider>
        </ExpandableSection>
    );
}