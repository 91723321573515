import {useEffect, useState} from "react";
import ErrorUtils from "src/utils/ErrorUtils";
import apiService from '../ApiCallService';
import SaveStandardAllocationRequest from "src/models/calculation-builder/standard-allocation/SaveStandardAllocationRequest";
import StandardAllocation from "src/models/calculation-builder/standard-allocation/StandardAllocation";

export default class StandardAllocationService {
    /**
    * Saves a Standard Allocation Identifier.
    * @param payload The Standard Allocation to save.
     *
    * @returns An array with the state of the async operation.
    */
    saveStandardAllocation(request?: SaveStandardAllocationRequest): [StandardAllocation | null, boolean, string] {
        const [standardAllocation, setStandardAllocation] = useState(null as null | StandardAllocation);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function saveData(payload: SaveStandardAllocationRequest) {
                try {
                    setLoading(true);
                    const response = await apiService.saveStandardAllocation(payload.templateId, payload.calculationNumber, payload);
                    const json = await response.json();
                    setStandardAllocation(json.standardAllocation);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (request != null) {
                saveData(request);
            }
        }, [request]);
        return [standardAllocation, loading, error];
    }

    /**
     * Deletes a Standard Allocation Identifier linked to a data source.
     * @param payload The Standard Allocation to delete'.
     *
     * @returns An array with the state of the async operation.
     */
    deleteDatasourceStandardAllocation(payload: any) : [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function deleteData(templateId: string, datasourceId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.deleteDatasourceStandardAllocation(templateId, datasourceId);
                    const json = await response.json();
                    setResult(json.standardAllocation);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                deleteData(payload.templateId, payload.datasourceId);
            }
        }, [payload]);

        return [result, loading, error];
    }

    /**
     * Deletes a Standard Allocation Identifier linked to a step.
     * @param payload The Standard Allocation to delete.
     *
     * @returns An array with the state of the async operation.
     */

    deleteStepStandardAllocation(payload: any) : [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function deleteData(templateId: string, stepId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.deleteStepStandardAllocation(templateId, stepId);
                    const json = await response.json();
                    setResult(json.standardAllocation);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                deleteData(payload.templateId, payload.stepId);
            }
        }, [payload]);

        return [result, loading, error];
    }
}