export class MoreOptionsModel {
    accountingOwner?: string;
    agreement?: string;
    agreementLineNumber?: string;
    agreementStatus?: string;
    agreementStatusCategory?: string;
    calculationName?: string;
    calculationNumber?: string;
    calculationStatus?: string;
    lastUpdateDate?: string;
    providerCode?: string;
    recipientCode?: string;
    taxOwner?: string;

    otherFilters: Array<MoreOptionsItem>;
    searchStartedDate?: Date;


    constructor() {
        this.otherFilters = [];
    }
}

export class MoreOptionsItem {
    key: string = "";
    value: any;

    constructor(key: string, value: any) {
        this.key = key;
        this.value = value;
    }

    /**
     * getKeyFriendlyName
     */
    public getKeyFriendlyName(): string {
        var result = this.key.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
}