import { AgreementsState } from './AgreementsState';
import TPEAction from '../../models/common/TPEAction';
import { Workbook } from 'src/models/agreements/Workbook';
import CONSTANTS from 'src/utils/constants';
import { CLI } from 'src/models/common/CLI';

/**
 * List here the actions supported by this reducer
 */
export const ACTIONS = Object.freeze({
    RESET: new TPEAction('RESET'),
    SET_STATUS_CATEGORIES: new TPEAction('SET_STATUS_CATEGORIESs'),
    SET_CALCULATION_STATUSES: new TPEAction('SET_CALCULATION_STATUSES'),
    SET_ACCOUNTING_OWNERS: new TPEAction('SET_ACCOUNTING_OWNERS'),
    SET_TAX_OWNERS: new TPEAction('SET_TAX_OWNERS'),
    SET_SELECTED_WORKLIST: new TPEAction('SET_SELECTED_WORKLIST'),
    SET_DEFAULT_WORKLISTS: new TPEAction('SET_DEFAULT_WORKLISTS'),
    SET_CUSTOM_WORKLISTS: new TPEAction('SET_CUSTOM_WORKLISTS'),
    ADD_CUSTOM_WORKLIST: new TPEAction('ADD_CUSTOM_WORKLIST'),
    UPDATE_CUSTOM_WORKLIST: new TPEAction('UPDATE_CUSTOM_WORKLIST'),
    DELETE_CUSTOM_WORKLIST: new TPEAction('DELETE_CUSTOM_WORKLIST'),
    SET_SEARCH_CALCULATIONS_PARAMS: new TPEAction('SET_SEARCH_CALCULATIONS_PARAMS'),
    SET_SEARCH_AGREEMENTS_PARAMS: new TPEAction('SET_SEARCH_AGREEMENTS_PARAMS'),
    START_AGREEMENTS_SEARCH: new TPEAction('START_AGREEMENTS_SEARCH'),
    START_CALCULATIONS_SEARCH: new TPEAction('START_CALCULATIONS_SEARCH'),
    START_ICRS_SEARCH: new TPEAction('START_ICRS_SEARCH'),
    SET_SEARCH_AGREEMENTS_RESULTS: new TPEAction('SET_SEARCH_AGREEMENTS_RESULTS'),
    SET_SEARCH_CALCULATIONS_RESULTS: new TPEAction('SET_SEARCH_CALCULATIONS_RESULTS'),
    SET_SEARCH_ICRS_RESULTS: new TPEAction('SET_SEARCH_ICRS_RESULTS'),
    SET_NEW_WORKLIST_VISIBLE: new TPEAction('SET_NEW_WORKLIST_VISIBLE'),
    SET_CUSTOM_WORKLIST_MESSAGE: new TPEAction('SET_CUSTOM_WORKLIST_MESSAGE'),
    ADDED_CUSTOM_WORKLIST: new TPEAction('ADDED_CUSTOM_WORKLIST'),
    UPDATED_CUSTOM_WORKLIST: new TPEAction('UPDATED_CUSTOM_WORKLIST'),
    DELETED_CUSTOM_WORKLIST: new TPEAction('DELETED_CUSTOM_WORKLIST'),
    SET_CURRENT_PAGE: new TPEAction('SET_CURRENT_PAGE'),
    SET_PAGE_SIZE: new TPEAction('SET_PAGE_SIZE'),
    SET_SEARCH_SORT: new TPEAction('SET_SEARCH_SORT'),
    SET_SEARCH_FILTERS: new TPEAction('SET_SEARCH_FILTERS'),
    SET_SELECTED_TAB: new TPEAction('SET_SELECTED_TAB'),
    SET_WORKBOOKS: new TPEAction('SET_WORKBOOKS'),
    SET_USERS: new TPEAction('SET_USERS'),
    SET_CALCULATION_BEING_UPDATED: new TPEAction('SET_CALCULATION_BEING_UPDATED'),
    SET_WORKBOOK_NAME_CONFIRMATION_VISIBLE: new TPEAction('SET_WORKBOOK_NAME_CONFIRMATION_VISIBLE'),
    SET_ASSIGNEE_NAME_CONFIRMATION_VISIBLE: new TPEAction('SET_ASSIGNEE_NAME_CONFIRMATION_VISIBLE'),
    SET_CALC_BUILDER_NAME_CONFIRMATION_VISIBLE: new TPEAction('SET_CALC_BUILDER_NAME_CONFIRMATION_VISIBLE'),
    UPDATE_WORKBOOK_NAME: new TPEAction('UPDATE_WORKBOOK_NAME'),
    UPDATE_ASSIGNEE_NAME: new TPEAction('UPDATE_ASSIGNEE_NAME'),
    UPDATE_CALC_BUILDER_NAME: new TPEAction('UPDATE_CALC_BUILDER_NAME'),
    SET_ALL_CONFIRMATIONS_VISIBLE: new TPEAction('SET_ALL_CONFIRMATIONS_VISIBLE'),
    SET_SHOW_CLOSE_MODAL_BUTTON: new TPEAction('SET_SHOW_CLOSE_MODAL_BUTTON'),
    UPDATE_FIELD: new TPEAction('UPDATE_FIELD'),
    UPDATE_CLI: new TPEAction('UPDATE_CLI'),
    ERROR: new TPEAction('ERROR')
});


/**  
 * This function is responsible for updating the state based on action type
 * @param state The current agreements state
 * @param action The current dispatched action 
 */
export function agreementsReducer(state: AgreementsState, action: TPEAction): AgreementsState {
    switch (action.type) {
        case ACTIONS.UPDATE_FIELD.type: {
            return {
                ...state,
                [action.fieldName]: action.payload,
            };
        }
        case ACTIONS.START_AGREEMENTS_SEARCH.type:
            return {
                ...state,
                error: '',
                searchAgreementsItems: [],
                searchAgreementsParams: action.payload,
                isSearching: true,
            }
        case ACTIONS.START_CALCULATIONS_SEARCH.type:
            return {
                ...state,
                error: '',
                searchCalculationsItems: [],
                searchCalculationsParams: action.payload,
                isSearching: true,
            }
        case ACTIONS.START_ICRS_SEARCH.type:
            return {
                ...state,
                error: '',
                searchICRSItems: [],
                searchICRSParams: action.payload,
                isSearching: true,
            }
        case ACTIONS.SET_SELECTED_WORKLIST.type:
            if (state.selectedWorklist) {
                state.selectedWorklist.isSelected = false;
            }
            if (action.payload) {
                action.payload.isSelected = true;
            }
            return {
                ...state,
                searchCurrentPage: 1,
                selectedWorklist: action.payload,
            };
        case ACTIONS.SET_STATUS_CATEGORIES.type:
            return {
                ...state,
                agreementStatusCategories: action.payload,
            };
        case ACTIONS.SET_CALCULATION_STATUSES.type:
            return {
                ...state,
                calculationStatuses: action.payload,
            };
        case ACTIONS.SET_ACCOUNTING_OWNERS.type:
            return {
                ...state,
                accountingOwners: action.payload,
            };
        case ACTIONS.SET_TAX_OWNERS.type:
            return {
                ...state,
                taxOwners: action.payload,
            };
        case ACTIONS.SET_DEFAULT_WORKLISTS.type:
            return {
                ...state,
                defaultWorklists: action.payload,
                isLoading: false,
            };
        case ACTIONS.SET_CUSTOM_WORKLISTS.type:
            return {
                ...state,
                customWorklists: action.payload,
            };
        case ACTIONS.ADD_CUSTOM_WORKLIST.type:
            return {
                ...state,
                savingMode: "ADDING",
                customWorklists: [...state.customWorklists, action.payload],
                customWorklistsNeedSaving: true,
            };
        case ACTIONS.ADDED_CUSTOM_WORKLIST.type:
            return {
                ...state,
                customWorklistAddedDate: new Date(),
                customWorklistsNeedSaving: false,
            };
        case ACTIONS.SET_SEARCH_AGREEMENTS_RESULTS.type: {
            const { agreements, totalSize } = action.payload;
            return {
                ...state,
                searchAgreementsItems: agreements,
                searchTotalSize: totalSize,
                isLoading: false,
                isSearching: false,
            };
        }
        case ACTIONS.SET_SEARCH_CALCULATIONS_RESULTS.type: {
            const { clis, totalSize } = action.payload;
            return {
                ...state,
                searchCalculationsItems: clis,
                searchTotalSize: totalSize,
                isLoading: false,
                isSearching: false,
            };
        }
        case ACTIONS.SET_SEARCH_ICRS_RESULTS.type: {
            const { icRegistrationDataList, totalSize } = action.payload;
            return {
                ...state,
                searchICRSItems: icRegistrationDataList,
                searchTotalSize: totalSize,
                isLoading: false,
                isSearching: false,
            };
        }
        case ACTIONS.SET_CURRENT_PAGE.type:
            return {
                ...state,
                searchCurrentPage: action.payload,
            };
        case ACTIONS.SET_PAGE_SIZE.type:
            return {
                ...state,
                searchCurrentPage: 1,
                searchPageSize: action.payload,
            };
        case ACTIONS.SET_SEARCH_SORT.type:
            return {
                ...state,
                userTouchedSorting: true,
                searchSorting: action.payload,
            };
        case ACTIONS.SET_SEARCH_FILTERS.type:
            return {
                ...state,
                searchFilters: action.payload,
            };
        case ACTIONS.SET_CUSTOM_WORKLIST_MESSAGE.type:
            return {
                ...state,
                customWorklistMessage: action.payload,
            };
        case ACTIONS.UPDATE_CUSTOM_WORKLIST.type:
            const foundIndex = state.customWorklists.findIndex(x => x.id === action.payload.id);
            if (foundIndex >= 0) {
                state.customWorklists[foundIndex] = action.payload;
            }
            return {
                ...state,
                savingMode: "UPDATING",
                customWorklists: [...state.customWorklists],
                customWorklistsNeedSaving: true,
            };
        case ACTIONS.UPDATED_CUSTOM_WORKLIST.type:
            return {
                ...state,
                customWorklistUpdatedDate: new Date(),
                customWorklistsNeedSaving: false,
            };
        case ACTIONS.DELETE_CUSTOM_WORKLIST.type:
            const worklists = state.customWorklists.filter(x => x.id !== action.payload);
            return {
                ...state,
                savingMode: "DELETING",
                customWorklists: worklists,
                customWorklistsNeedSaving: true,
                selectedWorklist: undefined,
            };
        case ACTIONS.DELETED_CUSTOM_WORKLIST.type:
            return {
                ...state,
                customWorklistDeletedDate: new Date(),
                customWorklistsNeedSaving: false,
            };
        case ACTIONS.SET_SELECTED_TAB.type:
            return {
                ...state,
                userTouchedSorting: false,
                selectedTab: action.payload,
                searchSorting: undefined,
                selectedWorklist: undefined,
                searchCurrentPage: 1
            };
        case ACTIONS.SET_CALCULATION_BEING_UPDATED.type:
            return {
                ...state,
                calculationBeingUpdated: action.payload,
            };
        case ACTIONS.SET_WORKBOOKS.type:
            return {
                ...state,
                workbooks: action.payload
            };
        case ACTIONS.SET_USERS.type:
            return {
                ...state,
                users: action.payload
            };
        case ACTIONS.SET_WORKBOOK_NAME_CONFIRMATION_VISIBLE.type:
            return {
                ...state,
                showWorkbookNameConfirmation: action.payload,
                calculationBeingUpdated: action.payload ? state.calculationBeingUpdated : null,
            };
        case ACTIONS.UPDATE_WORKBOOK_NAME.type:
            if (state.calculationBeingUpdated == null) {
                return {
                    ...state,
                    error: "No calculation is being edited. Workbook name could not be updated",
                    isLoading: false,
                };
            }
            const workbook = action.payload as Workbook;
            state.calculationBeingUpdated.workbookName = workbook.workbookName;
            state.calculationBeingUpdated.accountingOwner = workbook.accountingOwner;
            return {
                ...state,
                searchCalculationsItems: [...state.searchCalculationsItems]
            };
        case ACTIONS.SET_ASSIGNEE_NAME_CONFIRMATION_VISIBLE.type:
            return {
                ...state,
                showAssigneeNameConfirmation: action.payload,
                calculationBeingUpdated: action.payload ? state.calculationBeingUpdated : null,
            };
        case ACTIONS.UPDATE_ASSIGNEE_NAME.type:
            if (state.calculationBeingUpdated == null) {
                return {
                    ...state,
                    error: "No calculation is being edited. Calculation assignee could not be updated",
                    isLoading: false,
                };
            }
            state.calculationBeingUpdated.assignee = action.payload;
            return {
                ...state,
                searchCalculationsItems: [...state.searchCalculationsItems]
            };
        case ACTIONS.SET_CALC_BUILDER_NAME_CONFIRMATION_VISIBLE.type:
            return {
                ...state,
                showCalcBuilderNameConfirmation: action.payload,
                calculationBeingUpdated: action.payload ? state.calculationBeingUpdated : null,
            };
        case ACTIONS.UPDATE_CALC_BUILDER_NAME.type:
            if (state.calculationBeingUpdated == null) {
                return {
                    ...state,
                    error: "No calculation is being edited. Calc builder name could not be updated",
                    isLoading: false,
                };
            }
            state.calculationBeingUpdated.calcBuilder = action.payload;
            return {
                ...state,
                searchCalculationsItems: [...state.searchCalculationsItems]
            };
        case ACTIONS.SET_ALL_CONFIRMATIONS_VISIBLE.type:
            return {
                ...state,
                showAssigneeNameConfirmation: action.payload,
                showCalcBuilderNameConfirmation: action.payload,
                showWorkbookNameConfirmation: action.payload,
                calculationBeingUpdated: action.payload ? state.calculationBeingUpdated : null,
            };
        case ACTIONS.SET_SHOW_CLOSE_MODAL_BUTTON.type:
            return {
                ...state,
                showCloseModalButton: action.payload,
            };
        case ACTIONS.UPDATE_CLI.type:
            const { selectedTab, searchCalculationsItems } = state;
            const updatedCLI = action.payload as CLI;
            if (selectedTab === CONSTANTS.WORKLIST_TABS.CALCULATIONS){
                const foundIndex = searchCalculationsItems.findIndex( x => x.recordId === updatedCLI.recordId);
                searchCalculationsItems[foundIndex] = updatedCLI;
            }
            return {
                ...state,
                searchCalculationsItems: [...searchCalculationsItems],
                showCloseModalButton: true,
            };
        case ACTIONS.ERROR.type:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}