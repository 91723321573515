import React, { useState } from 'react';
import {
    Toggle,
    Button,
    StatusIndicator,
    Link,
    Popover,
} from '@amzn/awsui-components-react/polaris';
import 'src/assets/styles/react-table.scss';
import { TPEReactTable } from 'src/components/shared/TPEReactTable';
import { DataSourcesContext } from './DataSourcesContainer';
import { ACTIONS } from 'src/services/calculation-builder/DataSourcesReducer';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import {
    ErrorPopover,
    getBalancePullingStatus,
    getValueDisplay,
    openCommentsModal, openStandardAllocationModal,
    renderInfo,
    renderPassThroughFlagIcon,
    RowContextMenu
} from './GridCommon';
import CONSTANTS from 'src/utils/constants';
import { CalculationBuilderContext } from '../CalculationBuilderView';



export default function DataSourcesSplitGrid() {
    // Calculation builder state needs to be accessed directly by the children
    const { calcBuilderState } = React.useContext(CalculationBuilderContext);
    const { services, state, dispatch } = React.useContext(DataSourcesContext);
    const { dataSourceRecords, recordBeingEdited, showDecimals, updateAllBalances, balancePullingStateChangeTimestamp } = state;
    const { calculation, selectedCalculationVersion, viewMode, calculationTemplateMetaData } = calcBuilderState;
    const [formattedRecords, setFormattedRecords] = useState([] as DataSourceRecord[]);

    React.useEffect(() => {
        if (dataSourceRecords == null || updateAllBalances) {
            return;
        }
        let records = recordBeingEdited == undefined ? [] : dataSourceRecords.slice(dataSourceRecords.indexOf(recordBeingEdited) + 1);
        const startIndex = recordBeingEdited == undefined ? 0 : dataSourceRecords.indexOf(recordBeingEdited) + 2;
        setFormattedRecords(records.map((x, index) => {
            return {
                ...x,
                sequence: startIndex + index,
                fxRateFormatted: x.fxRate == null ? '-' : services.formattingService.formatString(x.fxRate, true), // Always show decimals for FX rate
                valueFormatted: x.value == null ? '-' : services.formattingService.formatString(String(x.value), showDecimals),
                enableDataKeyView: recordBeingEdited == null || (recordBeingEdited != null && !recordBeingEdited.isEditing),
            };
        }));
    }, [dataSourceRecords, showDecimals, updateAllBalances, balancePullingStateChangeTimestamp]);

    const toggleDataKeyView = (recordID: string) => {
        dispatch(ACTIONS.TOGGLE_DATA_KEY_VIEW.withPayload({recordID: recordID}));
    }

    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "sequence",
            Header: "#",
            Cell: (e: any) => <div className="cell-text sequenceCell">{e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DATA_SOURCE.ACCESSOR,
            Header: "Data source",
            Cell: (e: any) => <div className={"cell-text dataSourceCell"}>{e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR,
            Header: "Period",
            Cell: (e: any) => <div className={"cell-text periodCell"}>{e.row.original.customPeriod != null ? e.row.original.customPeriod : e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR,
            Header: "FX date",
            Cell: (e: any) => <div className={"cell-text fxDateCell"}>{e.value == null ? null : e.value.split('-').join('/')}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.FX_TYPE.ACCESSOR,
            Header: "FX type",
            Cell: (e: any) => <div className={"cell-text fxTypeCell"}>{e.value}</div>
        },
        {
            accessor: `${CONSTANTS.DATA_SOURCE_FIELDS.FX_RATE.ACCESSOR}Formatted`,
            Header: "FX rate",
            Cell: (e: any) => <div className={"cell-text fxRateCell"}>{e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.CURRENCY.ACCESSOR,
            Header: "Currency",
            Cell: (e: any) => <div className={"cell-text currencyCell"}>{e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR,
            Header: "Data keys",
            Cell: (e: any) => e.row.original.enableDataKeyView ?
                                <Link className={"cell-text dataKeyViewLink" + e.row.original.datasourceId} onFollow={() => toggleDataKeyView(e.row.original.datasourceId)}><div className={"cell-text dataKeyCell"}>{e.row.original[CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR]}</div></Link>
                                :
                                <div className={"cell-text dataKeyCell"}>{e.value}</div>
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DESCRIPTION.ACCESSOR,
            Header: "Description",
            Cell: (e: any) => <div className={"cell-text descriptionCell"}>{e.value}</div>
        },
        {
            accessor: `${CONSTANTS.DATA_SOURCE_FIELDS.VALUE.ACCESSOR}Formatted`,
            Header: "Value",
            Cell: (e: any) => <div className={"cell-text valueCell"}>{
                e.row.original.balancePullInProgress ? 
                getBalancePullingStatus(e.row.original)
                :
                getValueDisplay(e.value, e.row.original.lastBalancePullDate)}
                </div>
        },
        {
            accessor: "actions",
            Header: "Actions",
            Cell: (e: any) => <div className="rowActions">
                { 
                    e.row.original.pullBalanceError != null || (e.row.original.errors != null && (e.row.original.errors as Map<string, string>).size > 0) ? 
                        <ErrorPopover error={e.row.original.pullBalanceError || e.row.original.errors} />
                    :
                    (e.row.original.showSavedIndicator ? 
                        <StatusIndicator data-class="smallStatusIndicator" type="success">Saved</StatusIndicator>
                        : 
                        <span>&nbsp;</span> 
                    )
                }
                { viewMode != CONSTANTS.VIEW_MODE.FROZEN && e.row.original.passThroughFlag ? renderPassThroughFlagIcon(e.row.original) : <span>&nbsp;</span>}
                { viewMode != CONSTANTS.VIEW_MODE.FROZEN && e.row.original.standardAllocation ? <span className='greenTextWrapper'><Button iconName="folder" variant="icon" onClick={() => { openStandardAllocationModal(dispatch, e.row.original.datasourceId); }} /></span> : <span>&nbsp;</span> }
                { viewMode != CONSTANTS.VIEW_MODE.FROZEN && e.row.original.hasComments ? <Button iconName="contact" variant="icon" onClick={() => { openCommentsModal(dispatch, e.row.original, calculation?.calculationNumber, selectedCalculationVersion); }} /> : <span>&nbsp;</span> }
                <Popover data-class="basicSmallPopover" position="top" dismissButton={false} triggerType="custom" content={renderInfo(e.row.original.lastModifiedUser, e.row.original.lastModifiedDate)}>
                    <Link><StatusIndicator data-class="smallStatusIndicator" type="info" colorOverride="grey"/></Link>
                </Popover>
                { viewMode != CONSTANTS.VIEW_MODE.FROZEN && <RowContextMenu row={e.row.original} allowEditActions={viewMode == CONSTANTS.VIEW_MODE.EDITABLE} dispatch={dispatch} recordBeingEdited={recordBeingEdited} calculationHasTemplate={calculationTemplateMetaData?.templateId != null} /> }
            </div>
        }
    ], [showDecimals, recordBeingEdited, viewMode]);

    return (
        <div className="dataSourceTableContainer splitGrid">
            {formattedRecords.length > 0 &&
                <TPEReactTable {...{
                    data: formattedRecords,
                    columnDefinitions,
                    className: "dataSources",
                    sortable: false,
                    showHeader: false,
                }} />
            }
        </div>
    );
}