import moment from "moment-timezone";
import CONSTANTS from "./constants";

const DateUtils = {
    formatTimestamp(t: number | string | null | undefined) : string{
        if (t == null) {
            return '';
        }
        try {
            return moment(t).tz(CONSTANTS.DEFAULT_TIMEZONE).format("YYYY/MM/DD hh:mm A zz");
        } catch (error) {
            console.log(`Error formatting timestamp ${t}`, error);
            return '';
        }
    },
    formatDate(d: number | string | null | undefined, outputFormat: string = 'YYYY/MM/DD') : string {
        if (d == null) {
            return '';
        }
        try {
            return moment(d).tz(CONSTANTS.DEFAULT_TIMEZONE).format(outputFormat);
        } catch (error) {
            console.log(`Error formatting date ${d}, error`);
            return '';
        }
    },
}

export default DateUtils;