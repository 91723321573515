import React from 'react';
import { Checkbox, Select, Button, Link, SpaceBetween, Box } from '@amzn/awsui-components-react/polaris';
import { ACTIONS } from 'src/services/calculation-builder/DataSourcesReducer';
import CONSTANTS from 'src/utils/constants';
import CancelEditing from './CancelEditing';
import TPEAction from 'src/models/common/TPEAction';
import { GLBalance } from 'src/models/common/DataSourceRecord';
import ServiceCollection from 'src/services/ServiceCollection';
import CommonDataSourceOperations from 'src/services/common/CommonDataSourceOperations';
import Placeholder from 'src/models/tp-allocation/Placeholder';

type ComponentProps = {
    showDrillDownSelect: boolean,
    recordBeingEdited: {
        entityId: string, 
        description?: string, 
        glBalances?: GLBalance[],
        glBalanceDrillDownKeys?: Map<string, string>,
        drillDownCOASelected?: string,
        isEditing?: boolean,
        balancePullInProgress?: boolean,
        datasource?: string, 
        period?: string, 
        customPeriod?: string, 
        fxDate?: string, 
        fxType?: string, 
        currency?: string,
        dataKeyInput? : {
            selectedCompanies: string,
            selectedAccounts: string,
            selectedCOA: Map<string, string>,
        }
    } | undefined,
    sevenSegmentLOV: Map<string, string[]>,
    templateId?:string,
    allRecordDescriptions: string[],
    originalRecordDescription: string,
    customCoaReference?: Map<string, string>,
    placeholdersMap?: Map<string, Placeholder[]>,
    coaTypes: string[],
    isSaving: boolean,
    saveButtonLabel: string,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    onRecordNeedsSaving: (x:string) => void
}
 
export default function DataSourcesSelectCOA(props: ComponentProps) {
    const {showDrillDownSelect, recordBeingEdited, sevenSegmentLOV, templateId, allRecordDescriptions, 
        originalRecordDescription, customCoaReference = new Map<string, string>(), placeholdersMap, coaTypes, dispatch, 
        isSaving, saveButtonLabel, services, onRecordNeedsSaving
    } = props;

    const {
        dataKeyInput = {selectedCompanies: '', selectedAccounts: '', selectedCOA: new Map<string, string>()},
        glBalances = null,
        glBalanceDrillDownKeys,
        drillDownCOASelected = '',
        isEditing = false
    } = recordBeingEdited || {};

    const { selectedCompanies, selectedAccounts, selectedCOA } = dataKeyInput;

    const coaTypeOptions = coaTypes.map((coaType: string) => ({ label: coaType, value: coaType }));

    const isCOASelected = (key: string) => {
        return selectedCOA.has(key);
    }

    const toggleCOA = (key: string) => {
        if (isCOASelected(key)) {
            dispatch(ACTIONS.REMOVE_DATA_KEY_COA_INPUT.withPayload(key));
        } else {
            dispatch(ACTIONS.SET_DATA_KEY_COA_INPUT.withPayload({key: key, value: ''}));
        }
    }

    const editSelectedCOA = () => {
        dispatch(ACTIONS.EDIT_COA.withPayload(null));
    }

    const selectDrilldownCOA = (coa: string) => {
        dispatch(ACTIONS.SELECT_DRILLDOWN_COA.withPayload(coa));
    }

    // TODO: Need to figure this code out
    const saveRecord = () => {
        const errorMap = services.dataSourcesService.validateDataSourceDescription(recordBeingEdited?.description, allRecordDescriptions);
        
        // Validate inputs if saved without pulling balance
        if (!recordBeingEdited?.balancePullInProgress) {
            dispatch(ACTIONS.REMOVE_UNUSED_DATA_KEY_COA_INPUTS);

            const inputsErrorMap = CommonDataSourceOperations.validateInputsForPullBalance(recordBeingEdited, templateId != null);
            CommonDataSourceOperations.validateCOAInput(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI, selectedCompanies, sevenSegmentLOV.get(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI) || [], customCoaReference, inputsErrorMap, placeholdersMap);
            CommonDataSourceOperations.validateCOAInput(CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI, selectedAccounts, sevenSegmentLOV.get(CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI) || [], customCoaReference, inputsErrorMap, placeholdersMap);
            selectedCOA.forEach((value, key) => CommonDataSourceOperations.validateCOAInput(key, value, sevenSegmentLOV.get(key) || [], customCoaReference, inputsErrorMap, placeholdersMap));

            if (inputsErrorMap.size > 0) {
                inputsErrorMap.forEach((value: string, key: string) => errorMap.set(key, value));
            }
        }
        
        if (errorMap.size > 0) {
            dispatch(ACTIONS.SET_ERRORS.withPayload({ recordID: recordBeingEdited?.entityId, value: errorMap }));
        } else {
            dispatch(ACTIONS.CLEAR_ERRORS.withPayload({ recordID: recordBeingEdited?.entityId }));
            dispatch(ACTIONS.SET_FX_INPUTS);
            onRecordNeedsSaving(new Date().toISOString());
        }
    }

    

    return (
        <div className="dataKeyNav">
            <h2>Select COAs to pull GL Balance</h2>
            <div className="coaContainer">
                {coaTypes.filter((coaType: string) => (coaType != CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI && coaType != CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI))
                         .map((coaType: string) => <div className="coaItemContainer">
                                <Checkbox className={'coaItem ' + coaType.replace(' ', '_') + 'COASelect'} disabled={glBalances != null || !isEditing} checked={isCOASelected(coaType)} onChange={() => toggleCOA(coaType)}>{coaType}</Checkbox>
                             </div>)}
            </div>

            {glBalances != null && 
                <React.Fragment>
                    {isEditing && 
                        <Box className="editCOALinkBox">
                            <Link onFollow={editSelectedCOA}>Edit COAs</Link>
                        </Box>
                    }
                    {(glBalanceDrillDownKeys != null && glBalanceDrillDownKeys.size > 0) ?
                    <React.Fragment>
                        <h2>Select COAs to see breakdown</h2>
                        <div>
                            <Select 
                                className="coaDrillDownSelect"
                                selectedOption={drillDownCOASelected == '' ? null : { label: drillDownCOASelected, value: drillDownCOASelected }} 
                                onChange={({ detail }) => selectDrilldownCOA(detail.selectedOption.value || '')} 
                                options={coaTypeOptions} 
                                placeholder='Select COA'
                            />
                        </div>
                    </React.Fragment>
                    :
                    <div className="noBreakdownsMessageDiv">No breakdowns available</div>
                    }
                </React.Fragment>
            }
            {isEditing &&
                <div>
                    <SpaceBetween className="actionButtonsContainer" direction="horizontal" size="xs">
                        <CancelEditing onCancelPostProcess={() => dispatch(ACTIONS.CANCEL_EDITING)} />
                        <Button className="smallPrimaryButton saveDataSourceBtn" loading={isSaving} onClick={saveRecord}>{isSaving? "Saving..." : saveButtonLabel}</Button>
                    </SpaceBetween>
                </div>
            }
        </div>
    )
}

