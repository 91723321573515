import {Autosuggest, Icon, Input, Link, Popover, Select, StatusIndicator} from "@amzn/awsui-components-react";
import React, {useContext, useMemo, useState} from "react";
import CONSTANTS from "src/utils/constants";
import {TPEReactTable} from "../shared/TPEReactTable";
import {ACTIONS} from "src/services/custom-coa/CustomCOARowsReducer";
import {CustomCOARowsContext} from "./RowsView";
import StringUtils from "src/utils/stringUtils";
import ReactTableFilter from "src/models/common/ReactTableFilter";

// Create an editable cell renderer
const EditableCell = ({
                          value: initialValue,
                          row: {index, original},
                          column: {id},
                          onRowUpdated, // This is a custom function that we supplied to our table instance
                          editorsData, // Editors data contains data needed to render the editors
                      }: {
    value: any,
    initialValue: any,
    row: any,
    column: any,
    onRowUpdated: any,
    editorsData: any,
    onCellUpdated: any
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    const [segmentValueOptions, setSegmentValueOptions] = useState([] as any[]);

    const handleSegmentValueLoadItems = (event: any) => {
        let {filteringText} = event.detail;
        if (filteringText == null || filteringText == '') {
            filteringText = value;
        }

        if (filteringText == null || filteringText == '') {
            setSegmentValueOptions([]);
            return;
        }

        const filteredOptions = editorsData.segmentValues.filter((x: string) => x.toLowerCase().startsWith(filteringText.toLowerCase())).map((val: string) => {
            return {value: val}
        });
        setSegmentValueOptions(filteredOptions || []);
    }

    const getCellClass = () => {
        return original.errors != null && (original.errors as Map<string, string[]>).has(id) ? " customCoaInvalid dataSourcesButton" : " editableCell";
    }

    const onChange = (e: any) => {
        setValue(e.detail.value);
        onRowUpdated(index, id, e.detail.value);
    }

    const onSelectChanged = (e: any) => {
        setValue(e.detail.selectedOption.value)
        onRowUpdated(index, id, e.detail.selectedOption.value);
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        onRowUpdated(index, id, value);
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])


    const defaultSelectedOption = value == null ? null : {label: value, value};
    switch (id) {
        case CONSTANTS.CUSTOM_COA_ROW_FIELDS.CLASSIFICATION.ACCESSOR:
            if (original.newRecord) {
                return <Input key={`${id}_${index}`} className={getCellClass() + ` ${id}_${index}_input`}
                              placeholder="Enter Classification" value={value} onChange={onChange} onBlur={onBlur}/>
            } else {
                return <div key={`${id}_${index}`} className={`textCell ${id}_${index}_cell`}>{value}</div>
            }
        case CONSTANTS.CUSTOM_COA_ROW_FIELDS.SEGMENT_VALUE.ACCESSOR:
            if (original.newRecord) {
                return <Autosuggest
                    className={"tpeGridAutosuggest " + getCellClass()}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={segmentValueOptions}
                    enteredTextLabel={val => `Use: "${val}"`}
                    placeholder="Select segment value"
                    empty="No matches found"
                    loadingText="Loading"
                    onLoadItems={handleSegmentValueLoadItems}
                    filteringType="manual"
                    statusType={segmentValueOptions == undefined ? 'loading' : 'finished'}
                />
            } else {
                return <div key={`${id}_${index}`} className={`textCell ${id}_${index}}_cell`}>{value}</div>
            }
        case CONSTANTS.CUSTOM_COA_ROW_FIELDS.ACTIVE.ACCESSOR:
            const statusOptions = [...editorsData.statuses.map((x: string) => ({label: x, value: x}))];
            return <Select key={`${id}_${index}`} filteringType="auto"
                           className={"dataSourcesButton " + `${id}_${index}_input` + getCellClass()}
                           placeholder="Select" onChange={onSelectChanged} selectedOption={defaultSelectedOption}
                           options={statusOptions}/>
        case CONSTANTS.CUSTOM_COA_ROW_FIELDS.UPDATED_BY.ACCESSOR:
            return <div key={`${id}_${index}`}
                        className={`textCell ${id}_${index}_cell`}>{StringUtils.isNullOrEmpty(value) ? "N/A" : value}</div>
        case CONSTANTS.CUSTOM_COA_ROW_FIELDS.LAST_UPDATED_DATE.ACCESSOR:
            return <div key={`${id}_${index}`}
                        className={`textCell ${id}_${index}_cell`}>{StringUtils.isNullOrEmpty(value) ? "N/A" : value}</div>
        default:
            return <Input key={`${id}_${index}`} className={getCellClass() + ` ${id}_${index}}_input`} value={value}
                          onChange={onChange} onBlur={onBlur}/>
    }
}

// Set our editable cell renderer as the default Cell renderer
const defaultCellEditor = {
    Cell: EditableCell,
}

function COARowColumnFilter(event: {
    column: { filterValue: any, preFilteredRows: any, setFilter: any, minimumFilterValueLength: number },
}) {
    const {column} = event;
    const {filterValue, setFilter} = column;
    const value = filterValue || '';
    return (

        <input
            value={value}
            className={value.length > 0 && value.length < column.minimumFilterValueLength ? "defaultFilter invalidFilter" : "defaultFilter"}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search records...`}
        />
    )
}

export default function RowsGrid(props: {
    loading: boolean,
    editable: boolean,
    showFilters: boolean,
    onFilter: (e: Array<ReactTableFilter>) => void
}) {
    const {loading, editable, showFilters, onFilter} = props;
    const {state, dispatch} = useContext(CustomCOARowsContext);
    const {searchResult, coaLov} = state;

    const getColDefs = () => {
        const colDefs: any[] = [];
        const errorIndicatorColDef = {
            accessor: 'errorIndicator',
            Header: (e: any) => <div className="cell-text"> </div>,
            suppressFiltering: true,
            suppressSorting: true,
            hidePopover: true,
            Cell: (e: any) => e.row.original.errors == null ? '' : 
                <div className="textCell">
                    <Popover data-class="basicSmallPopover" dismissButton={false} triggerType="custom" position="top" 
                    content={
                        <ul>
                            {Array.from((e.row.original.errors as Map<string, string>).values()).map((x) => <li key={x}>{x}</li>)}
                        </ul>
                    }>
                        <Link><Icon name="status-warning" size="small" variant="warning"/></Link>
                    </Popover>
                </div>
        }
        colDefs.push(errorIndicatorColDef);
        Object.values(CONSTANTS.CUSTOM_COA_ROW_FIELDS).forEach(x => {
            const colDef = {
                accessor: x.ACCESSOR,
                Header: (e: any) => <div className="cell-text">{x.DISPLAY}</div>,
                Filter: COARowColumnFilter,
                suppressFiltering: x.ACCESSOR == CONSTANTS.CUSTOM_COA_ROW_FIELDS.LAST_UPDATED_DATE.ACCESSOR,
                hidePopover: true,
            }
            if (!editable) {
                (colDef as any)['Cell'] = (e: any) => <div className={`textCell ${x.ACCESSOR}_${e.row.index}_cell`}>{e.value}</div>
            }
            colDefs.push(colDef);
        });
        return colDefs;
    }

    const colDefs = useMemo(getColDefs, [searchResult, editable]);

    const updateCustomCOARow = (rowIndex: number, attribute: string, value: string) => {
        dispatch(ACTIONS.UPDATE_CUSTOM_COA_RECORD.withPayload({
            rowIndex, attribute, value
        }));
    }

    const onFilterChanged = function (filters: Array<ReactTableFilter>) {
        if (filters == null || filters.length === 0) {
            onFilter([]);
            return;
        }
        onFilter(filters);
    }

    return (
        <div className="customCoaRowsGridContainer">
            <TPEReactTable {...{
                data: searchResult?.rows || [],
                columnDefinitions: colDefs,
                className: "customCoaRowsGrid",
                isSearching: loading,
                sortable: true,
                showFilters: showFilters,
                onFilter: onFilterChanged,
                highlightErrorCells: false,
                editableOptions: editable ? {
                    defaultColumn: defaultCellEditor,
                    editorsData: {
                        segmentValues: coaLov,
                        statuses: CONSTANTS.CUSTOM_DATA_TABLE_ACTIVE_FLAGS
                    },
                    onRowUpdated: updateCustomCOARow,
                    skipPageReset: true,
                } : null as unknown as any
            }} />
        </div>
    );
}