import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import StepsWizardBar from '../../shared/StepsWizardBar';
import 'src/assets/styles/steps-wizard.scss';
import 'src/components/tp-allocation/shared/TPAllocationBuilder.scss';
import { getStandaloneInitialState, initialState } from '../../../services/tp-allocation/TPAllocationState';
import { tpAllocationReducer, ACTIONS } from '../../../services/tp-allocation/TPAllocationReducer';
import ServiceCollection from 'src/services/ServiceCollection';

import useReducerWithLogger from '../../../services/utils/ReducerWithLogger';
import { GlobalAppContext } from '../../App';
import { TPEBasicModal, ACTION_TYPE } from '../../shared/TPEBasicModal';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { WizardStep } from '../../../models/common/WizardStep';
import StringUtils from 'src/utils/stringUtils';
import DynamicBreadcrumb from 'src/models/navigation/DynamicBreadcrumb';
import { GLOBAL_ACTIONS } from 'src/services/global/GlobalReducer';
import WizardStepContentWrapper from 'src/components/calculation-builder/WizardStepContentWrapper';
import { TPAllocationContext } from '../../../services/tp-allocation/TPAllocationContext';
import TPAllocationStatusBar from '../shared/TPAllocationStatusBar';
import DatasetContainer from '../shared/DatasetContainer';
import CONSTANTS from 'src/utils/constants';
import TotalsContainer from '../shared/TotalsContainer';
import AllocationGroupsContainer from '../shared/AllocationGroupsContainer';
import TPAllocationActionBar from '../shared/TPAllocationActionBar';
import TPEErrorWatcher from 'src/components/shared/TPEErrorWatcher';
import WorksheetValidator from './WorksheetValidator';
import { getPermissions } from 'src/components/AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';

export const TPAllocationProvider = (props: any) => {
    const { state, dispatch, services, userProfile, children } = props;
    const providerValue = React.useMemo(() => ({
        tpAllocationState: state, tpAllocationDispatch: dispatch, services, userProfile
    }), [state, dispatch]);
    return (
        <TPAllocationContext.Provider value={providerValue}>
            {children}
        </TPAllocationContext.Provider>
    );
}

export default function StandaloneWorksheetView(props: { services: ServiceCollection }) {
    const { pathname } = useLocation();
    const { encodedWorksheetId } = useParams< { encodedWorksheetId: string }>();
    const [worksheetId, setWorksheetId] = useState('');
    
    const [state, dispatch] = useReducerWithLogger(
        tpAllocationReducer, 
        initialState, 
        getStandaloneInitialState
    );
    const { globalDispatch, userProfile } = React.useContext(GlobalAppContext);
    const { services } = props;
    const { canEdit } = getPermissions(AppModules.TP_ALLOCATION);

    const { 
        wizardSteps, 
        selectedWizardStep,
        error:tpAllocationLevelError,
        viewMode,
        builderState,
        entityDetailsNeedRefresh
    } = state;

    const ALL_WIZARD_STEPS = {
        DATASET: wizardSteps[0],
        TOTALS: wizardSteps[1],
        GROUPS: wizardSteps[2]
    };

    const [doRefreshDetails, setDoRefreshDetails] = useState('initial');
    
    const [worksheetDetailsResult, isLoading, worksheetDetailsError] = services.tpAllocationService.getWorksheetDetails(doRefreshDetails, worksheetId);

    useEffect(() => {
        if (CONSTANTS.VIEW_MODE.READ_ONLY === viewMode){
            dispatch(ACTIONS.ENABLE_ALL_WIZARD_STEPS);
        }
    }, [viewMode])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(encodedWorksheetId)){
            return;
        }
        const id = atob(encodedWorksheetId);
        setWorksheetId(id)
        
    },[encodedWorksheetId])

    useEffect(() => {
        if (worksheetDetailsResult == null){
            return;
        }
        globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, worksheetDetailsResult.worksheetName, worksheetDetailsResult.worksheetName)));
        dispatch(ACTIONS.SET_WORKSHEET.withPayload(worksheetDetailsResult));
        dispatch(ACTIONS.SET_BUILDER_STATE.withPayload(worksheetDetailsResult.builderState || CONSTANTS.TP_ALLOCATION_BUILDER_STATE.DATASOURCES));
        if (canEdit) {
            if (worksheetDetailsResult.status === CONSTANTS.CALCULATION_STATUS.PENDING_REVIEW) {
                dispatch(ACTIONS.SET_VIEW_MODE.withPayload(CONSTANTS.VIEW_MODE.READ_ONLY));
            } else {
                dispatch(ACTIONS.SET_VIEW_MODE.withPayload(CONSTANTS.VIEW_MODE.EDITABLE));
            }
        } else {
            dispatch(ACTIONS.SET_VIEW_MODE.withPayload(CONSTANTS.VIEW_MODE.FROZEN));
        }
    }, [worksheetDetailsResult, canEdit])

    useEffect(() => {
        if (entityDetailsNeedRefresh) {
            setDoRefreshDetails((new Date()).toString());
        }
    }, [entityDetailsNeedRefresh])
    
    

    useEffect(() => {
        if (builderState === CONSTANTS.TP_ALLOCATION_BUILDER_STATE.DATASOURCES) {
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(ALL_WIZARD_STEPS.DATASET));
        } else if (builderState === CONSTANTS.TP_ALLOCATION_BUILDER_STATE.TOTALS){
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(ALL_WIZARD_STEPS.TOTALS));
        } else if (builderState === CONSTANTS.TP_ALLOCATION_BUILDER_STATE.ALLOCATION_GROUPS){
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(ALL_WIZARD_STEPS.GROUPS));
        }
    }, [builderState])

    const isStepNumberSelected = function (number: number): boolean {
        if (wizardSteps == null || wizardSteps.length === 0 || selectedWizardStep == null) {
            return false;
        }
        return selectedWizardStep.number === number;
    }

    const isStepNumberEnabled = function (number: number): boolean {
        if (wizardSteps == null || wizardSteps.length === 0 || selectedWizardStep == null) {
            return false;
        }
        const step = wizardSteps.find(x => x.number === number);

        return step != null && step.isEnabled;
    }

    const onStepChanged = function (step: WizardStep) {
        // If "Select TP Agreements" is selected
        if (step.number === 1 && CONSTANTS.VIEW_MODE.EDITABLE === viewMode) {
            dispatch(ACTIONS.RESET_STANDALONE_STATE);
        }
        else {
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(step));
        }
    }

    return (
        <Box padding={{top:"xxl"}}>
            <TPAllocationProvider services={services} state={state} dispatch={dispatch} userProfile={userProfile}>
                <div className='stepsWizard'>
                    <SpaceBetween direction="vertical" size="m">
                        <div key="stepsStripContent" className="stepsStripContainer">
                            <StepsWizardBar  {...{selectedWizardStep, wizardSteps, onStepChanged}} />
                        </div>
                        <TPAllocationStatusBar loading={isLoading} />
                        {isStepNumberEnabled(1) && 
                            <WizardStepContentWrapper wizardStep={wizardSteps[0]}>
                                <DatasetContainer expanded={isStepNumberSelected(1)} containerTitle={`Step 1: ${wizardSteps[0].name}`} onCancel={() => {}} />
                            </WizardStepContentWrapper>
                        }
                        {isStepNumberEnabled(2) && 
                            <WizardStepContentWrapper wizardStep={wizardSteps[1]}>
                                <TotalsContainer expanded={isStepNumberSelected(2)} containerTitle={`Step 2: ${wizardSteps[1].name}`} onCancel={() => {}} />
                            </WizardStepContentWrapper>
                        }
                        {isStepNumberEnabled(3) && 
                            <WizardStepContentWrapper wizardStep={wizardSteps[2]}>
                                <AllocationGroupsContainer expanded={isStepNumberSelected(3)} containerTitle={`Step 3: ${wizardSteps[2].name}`} onCancel={() => {}} />
                            </WizardStepContentWrapper>
                        }
                        {isStepNumberEnabled(3) && viewMode != CONSTANTS.VIEW_MODE.FROZEN && 
                            <TPAllocationActionBar />
                        }
                    </SpaceBetween>
                </div>
                <WorksheetValidator />
            </TPAllocationProvider>
            <TPEBasicModal
                className="tpAllocationLevelErrorModal"
                visible={tpAllocationLevelError != null}
                action={ACTION_TYPE.OK_ONLY}
                title="TP Allocation Error"
                onCancel={() => dispatch(ACTIONS.SET_ERROR.withPayload(null))}
                onConfirm={() => dispatch(ACTIONS.SET_ERROR.withPayload(null))}
            >
                <div className="calcBuilderLevelMessage">{tpAllocationLevelError}</div>
            </TPEBasicModal>
            <TPEErrorWatcher services={services} errors={[worksheetDetailsError]} />
        </Box>
    );
}