import CONSTANTS from "src/utils/constants";
import apiService from "src/services/ApiCallService";
import {useEffect, useState} from "react";
import ArrayUtils from "src/utils/arrayUtils";
import ErrorUtils from "src/utils/ErrorUtils";
import StringUtils from "src/utils/stringUtils";
import CustomCOADefinition, { CustomCOARow } from "src/models/custom-coa/CustomCOADefinition";
import TPESearchRequest from "src/models/common/TPESearchRequest";
import { SearchCustomCOAResult } from "src/models/custom-coa/SearchCustomCOAResult";
import { SearchCustomCOARowsResult } from "src/models/custom-coa/SearchCustomCOARowsResult";
import DateUtils from "src/utils/dateUtils";

export default class CustomCOAService {

    downloadTemplate(query: string): [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function downloadTemplate() {
                try {
                    setLoading(true);
                    const response = await apiService.downloadFile(CONSTANTS.ENVIRONMENT_VARIABLES.TEMPLATES_BUCKET, CONSTANTS.ENVIRONMENT_VARIABLES.CUSTOM_COA_DEFINITION_TEMPLATE);
                    const json = await response.json();
                    setResult(json.downloadUrl);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query)) {
                downloadTemplate();
            }
        }, [query]);
        return [result, loading, error]
    }

    uploadFile(key: string | null | undefined, file: File, coaSegment: string, newTable: boolean): [CustomCOADefinition, boolean, string] {
        const [result, setResult] = useState(null as unknown as CustomCOADefinition);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function uploadData(key: string, coaSegment: string, newTable: boolean) {
                try {
                    setLoading(true);
                    await apiService.uploadToS3(CONSTANTS.ENVIRONMENT_VARIABLES.CUSTOM_TABLE_UPLOAD_BUCKET, key || '', file);
                    const response = await apiService.uploadCustomCOADefinition(key, coaSegment, newTable);
                    const customCOADefinition: CustomCOADefinition = await response.json();
                    setResult(customCOADefinition);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(key) && !StringUtils.isNullOrEmpty(coaSegment)) {
                uploadData(key || '', coaSegment, newTable);
            }
        }, [key]);

        return [result, loading, error];
    }

    createCustomCOADefinition(tableName: string, coaSegment: string): [CustomCOADefinition, boolean, string] {
        const [result, setResult] = useState(null as unknown as CustomCOADefinition);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.createCustomCOADefinition(tableName, coaSegment);
                    const customCOADefinition: CustomCOADefinition = await response.json();
                    setResult(customCOADefinition);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(tableName) && !StringUtils.isNullOrEmpty(coaSegment)) {
                fetchData()
            }
        }, [tableName]);

        return [result, loading, error];
    }

    /**
     * Renames a Custom COA Definition
     * @param tableId the ID of the table to rename
     * @param newTableName the new name for the table
     */
    renameCustomCOADefinition(tableId: string, newTableName: string): [string, boolean, string] {
        const [results, setResults] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.renameCustomCOADefinition(tableId, newTableName);
                    const json = await response.json();
                    setResults(json.newTableName);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(newTableName)) {
                fetchData()
            }
        }, [newTableName]);
        return [results, loading, error];
    }

    saveCustomCOADefinition(rows: CustomCOARow[], tableId: string): [any, boolean, string] {
        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    setStatusesForSave(rows);
                    const response = await apiService.saveCustomCOADefinition(tableId, rows);
                    setStatusesForDisplay(rows);
                    const json = await response.json();
                    if (json.errors) {
                        json.errors = new Map(Object.entries(json.errors));
                    }
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!ArrayUtils.isNullOrEmpty(rows) && !StringUtils.isNullOrEmpty(tableId)) {
                fetchData()
            }
        }, [rows, tableId]);

        return [result, loading, error];
    }

    getCustomCOADefinitionsForSegment(coaSegment: string | null): [CustomCOADefinition[], boolean, string] {
        const [results, setResults] = useState([] as CustomCOADefinition[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(coaSegment: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getCustomCOADefinitionsForSegment(coaSegment);
                    const json = await response.json();
                    setResults(json.definitions);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }
            if (!StringUtils.isNullOrEmpty(coaSegment)){
                fetchData(coaSegment || '')
            }
        }, [coaSegment]);
        return [results, loading, error];
    }

    getClassifications(tableId: string | null): [string[], boolean, string] {
        const [results, setResults] = useState([] as string[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(tableId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getClassifications(tableId);
                    const json = await response.json();
                    setResults(json.classifications);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }
            if (!StringUtils.isNullOrEmpty(tableId)){
                fetchData(tableId || '')
            }
        }, [tableId]);
        return [results, loading, error];
    }

    searchCustomCOADefinitions(payload: TPESearchRequest) : [SearchCustomCOAResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as SearchCustomCOAResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchCustomCoaDefinitions(payload);
                    const json = await response.json() as SearchCustomCOAResult;
                    json.pagesCount = Math.ceil(json.totalSize / json.pageSize);
                    json.customCOADefinitions.forEach(x => addLastUpdatedDateAndTimeAttributes(x));
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }

            if (payload != null) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    searchCustomCOADefinitionRows(payload: any) : [SearchCustomCOARowsResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as SearchCustomCOARowsResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchCustomCoaDefinitionRows(payload);
                    const json = await response.json() as SearchCustomCOARowsResult;
                    json.pagesCount = Math.ceil(json.totalSize / json.pageSize);
                    json.rows.forEach(x => addLastUpdatedDateAndTimeAttributes(x));
                    setStatusesForDisplay(json.rows);
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }

            if (payload != null) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    dowloadCustomCOADefinition(tableId: string) : [string, boolean, string] {
        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(tableId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.downloadCustomCOADefinition(tableId);
                    const json = await response.json();
                    setResult(json.downloadUrl);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(tableId)) {
                fetchData(tableId);
            }
        }, [tableId]);

        return [result, loading, error];
    }

    getAllowDeleteFlag(tableId: string) : [{status: string, allowDeleteFlag: boolean}, boolean, string] {
        const [result, setResult] = useState(null as unknown as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(tableId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getCustomCOADefinitionAllowDeleteFlag(tableId);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(tableId)) {
                fetchData(tableId);
            }
        }, [tableId]);

        return [result, loading, error];
    }

    deleteCustomCOADefinition(shouldDelete: boolean, tableId: string) : [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function deleteData(tableId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.deleteCustomCOADefinition(tableId);
                    const json = await response.json();
                    setResult(json.tableId);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (shouldDelete && !StringUtils.isNullOrEmpty(tableId)) {
                deleteData(tableId);
            }
        }, [tableId, shouldDelete]);

        return [result, loading, error];
    }

    archiveCustomCOADefinition(shouldArchive: boolean, tableId: string) : [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(tableId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.archiveCustomCOADefinition(tableId);
                    const json = await response.json();
                    setResult(json.tableId);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (shouldArchive && !StringUtils.isNullOrEmpty(tableId)) {
                postData(tableId);
            }
        }, [tableId, shouldArchive]);

        return [result, loading, error];
    }
}

function addLastUpdatedDateAndTimeAttributes(record: CustomCOADefinition | CustomCOARow) {
    if (record.lastUpdatedDate != null) {
        record.lastUpdatedDateDisplay = DateUtils.formatTimestamp(record.lastUpdatedDate);
    }
}

function setStatusesForDisplay(rows: CustomCOARow[]) {
    rows.forEach(row => {
        if (row.status) {
            row.status = row.status == CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ACTIVE ? 'Y' : 'N';
        }
    });
}

function setStatusesForSave(rows: CustomCOARow[]) {
    rows.forEach(row => {
        if (row.status) {
            row.status = row.status == 'Y' ? CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ACTIVE : CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.INACTIVE;
        }
    });
}
