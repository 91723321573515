import { Badge, Box, Button, ColumnLayout, Container, Icon, Pagination, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import TPEAction from 'src/models/common/TPEAction';
import CustomDataTableRowsInfo from 'src/models/custom-data-tables/CustomDataTableRowsInfo';
import DynamicBreadcrumb from 'src/models/navigation/DynamicBreadcrumb';
import { ACTIONS, customDataTableRecordsReducer } from 'src/services/custom-data-tables/CustomDataTableRecordsRecucer';
import { CustomDataTableRecordsState, initialState } from 'src/services/custom-data-tables/CustomDataTableRecordsState';
import { GLOBAL_ACTIONS } from 'src/services/global/GlobalReducer';
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import CONSTANTS, { CUSTOM_DATA_TABLE_CONSTANTS } from 'src/utils/constants';
import ArrayUtils from 'src/utils/arrayUtils';
import StringUtils from 'src/utils/stringUtils';
import { GlobalAppContext } from '../App';
import TPEErrorWatcher from '../shared/TPEErrorWatcher';
import RecordsGrid from './RecordsGrid';
import RenameTableModal from './RenameTableModal';
import CustomDataTableRowsSearchRequest from 'src/models/custom-data-tables/CustomDataTableRowsSearchRequest';
import { TPELoadingSpinner } from '../shared/TPELoadingSpinner';
import { addCDTRowsExpressionFilters, addSorting, getDefaultSearchPayload } from '../custom-tables-shared/CustomDataTableSearchHelper';
import { SortingRule } from 'react-table';
import ReactTableFilter from 'src/models/common/ReactTableFilter';
import { TPEBasicModal, ACTION_TYPE } from '../shared/TPEBasicModal';
import TPENavigator from '../TPENavigator';
import { AppModules, AppRoles } from 'src/models/permissions/RolePermissions';
import { getPermissions } from '../AppPermissions';

export type ContextType = {
    state: CustomDataTableRecordsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const CustomDataTableRecordsProvider = (props: any) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <CustomDataTableRecordsContext.Provider value={providerValue}>
            {children}
        </CustomDataTableRecordsContext.Provider>
    );
}
export const CustomDataTableRecordsContext = React.createContext(null as unknown as ContextType);


export default function RecordsView(props: {services: ServiceCollection}) {
    const { services } = props;
    const [state, dispatch] = useReducerWithLogger(customDataTableRecordsReducer, initialState);
    const { searchPayload, searchResult, unsavedRowsCount } = state;

    const { pathname } = useLocation();
    const { encodedTableId } = useParams< { encodedTableId: string }>();
    const [tableId, setTableId] = useState(atob(encodedTableId));
    

    const { globalDispatch, userProfile } = useContext(GlobalAppContext);
    const { canEdit, canViewAll, canRename } = getPermissions(AppModules.CUSTOM_DATA_TABLES);

    const [selectedDetailTab, setSelectedDetailTab] = useState('details');
    const [tableName, setTableName] = useState('');
    const [tableStatus, setTableStatus] = useState('');
    const [loadTime, setLoadTime] = useState((new Date()).toString());
    const [newRowsToSave, setNewRowsToSave] = useState<CustomDataTableRowsInfo | null>(null);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [downloadTableId, setDownloadTableId] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [isInitialSearch, setIsInitialSearch] = useState(true);
    const [tableKey, setTableKey] = useState(0);
    const [pendingConfirmationSearchPayload, setPendingConfirmationSearchPayload] = useState(null as any);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState(false);
    const [shouldDeleteCustomDataTable, setShouldDeleteCustomDataTable] = useState(false);
    const [shouldArchiveCustomDataTable, setShouldArchiveCustomDataTable] = useState(false);
    const [navigateUrl, setNavigateUrl] = useState('');

    const [currencyCodes, isGettingCurrencyCodes, currencyCodesError] = services.customDataTablesService.getCurrencyCodeLOV(loadTime);
    const [clisResult, isGettingCLIs, cliError] = services.cliService.getCLIs(loadTime);
    const [customDataTableResult, customDataTableLoading, customDataTableError] = services.customDataTablesService.searchCustomDataTableRows(searchPayload as unknown as CustomDataTableRowsSearchRequest, CUSTOM_DATA_TABLE_CONSTANTS.CONFIG_VIEW_FIELDS);
    const [saveResult, isSaving, saveError] = services.customDataTablesService.saveCustomDataTable(newRowsToSave, true);
    const [downloadUrl, isDownloading, downloadError] = services.customDataTablesService.downloadCustomDataTable(downloadTableId);
    const [allowDeleteResult, isGettingFlag, allowDeleteFlagError] = services.customDataTablesService.getAllowDeleteFlag(tableId);
    const [deleteResult, isDeleting, deleteError] = services.customDataTablesService.deleteCustomDataTable(shouldDeleteCustomDataTable, tableId);
    const [archiveResult, isArchiving, archiveError] = services.customDataTablesService.archiveCustomDataTable(shouldArchiveCustomDataTable, tableId);
    
    useEffect(() => {
        if (userProfile == null || StringUtils.isNullOrEmpty(tableName)){
            return;
        }
        const hasNafnRole = services.usersService.hasRole(AppRoles.NAFN, userProfile.roles);
        if (hasNafnRole && tableName !== CONSTANTS.NAFN_ADMIN_ALLOWED_CDT_NAME){
            services.messageService.showErrorBanner(`Your role is not authorized to access Custom Data table '${tableName}'. Please contact support if you need assistance.`);
            setNavigateUrl(CONSTANTS.PAGE_NAV.CUSTOM_TABLES.URL);
            return;
        }
        
        if (!canViewAll && !hasNafnRole && tableName === CONSTANTS.NAFN_ADMIN_ALLOWED_CDT_NAME){
            services.messageService.showErrorBanner(`Custom Data table '${tableName}' is maintained only by members of a different role. Please contact support if you need assistance.`);
            setNavigateUrl(CONSTANTS.PAGE_NAV.CUSTOM_TABLES.URL);
            return;
        }
    }, [userProfile, tableName]);

    useEffect(() => {
        if (customDataTableResult == null) {
            return;
        }
        setIsInitialSearch(false);
        setTableName(customDataTableResult.tableName);
        dispatch(ACTIONS.SET_SEARCH_RESULT.withPayload(customDataTableResult));
        globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, customDataTableResult.tableName, customDataTableResult.tableName)));
        if (customDataTableResult.totalSize == 0 && ArrayUtils.isNullOrEmpty(searchPayload?.filterExpression?.expressions)) {
            dispatch(ACTIONS.ADD_NEW_ROW)
        }
    }, [customDataTableResult]);

    useEffect(() => {
        const searchPayload = {...getDefaultSearchPayload(), tableId: tableId};
        dispatch(ACTIONS.SET_SEARCH_PAYLOAD.withPayload(searchPayload));
    }, [tableId])

    useEffect(() => {
        if (currencyCodes == null) {
            return;
        }
        dispatch(ACTIONS.SET_CURRENCY_CODES.withPayload(currencyCodes));
    }, [currencyCodes])

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(clisResult)) {
            return;
        }
        dispatch(ACTIONS.SET_ACTIVE_CLIS.withPayload(clisResult));
    }, [clisResult])

    useEffect(() => {
        if (saveResult != null && !saveResult.errors && StringUtils.isNullOrEmpty(saveError)) {
            services.messageService.showSuccessAutoDismissBanner(`${searchResult?.tableName} successfully saved.`, 10000);
            const searchPayload = {...getDefaultSearchPayload(), tableId: tableId};
            dispatch(ACTIONS.RESET_UNSAVED_ROWS_COUNT);
            dispatch(ACTIONS.SET_SEARCH_PAYLOAD.withPayload(searchPayload));
        } else if (saveResult != null && saveResult.errors) {
            resetRowErrors();
        }
    }, [saveResult])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(saveError)) {
            services.messageService.showErrorAutoDismissBanner(saveError, 10000);
        }
    }, [saveError]);

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(downloadUrl)) {
            return;
        }
        window.open(downloadUrl);
        setDownloadTableId('');
    }, [downloadUrl])

    useEffect(() => {
        if (allowDeleteResult == null) {
            return;
        }
        setTableStatus(allowDeleteResult.status);
    }, [allowDeleteResult])

    useEffect(() => {
        if (deleteResult == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner(`Custom Data Table ${tableName} successfully deleted.`, 10000);
        setNavigateUrl(CONSTANTS.PAGE_NAV.CUSTOM_TABLES.URL);
    }, [deleteResult])

    useEffect(() => {
        if (archiveResult == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner(`Custom Data Table ${tableName} successfully archived.`, 10000);
        setTableStatus(CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED);
    }, [archiveResult])

    const saveEditedRows = () => {
        if(state.searchResult) {
            const rowsInfoToSave = {...state.searchResult.rowsInfo};
            rowsInfoToSave.records = rowsInfoToSave.records.filter(record => record.isUpdated);
            if(rowsInfoToSave.records.length > 0) {
                setNewRowsToSave(rowsInfoToSave);
            }
        }
    }

    const resetRowErrors = () => {
        let currentSavedRow = 0;
        state.searchResult?.rowsInfo.records.forEach(row => {
            if (row.isUpdated) {
                row.errors = new Map(Object.entries(saveResult.errors.get(currentSavedRow.toString()).errors));
                currentSavedRow++;
            } else {
                row.errors = undefined;
            }
        });
    }

    const onSortByChanged = function (sortBySpecs: Array<SortingRule<any>>) {
        if (sortBySpecs == null || sortBySpecs.length === 0 || searchPayload == null) {
            return;
        }
        const sortBy = sortBySpecs[0];
        addSorting(searchPayload, sortBy.id, sortBy.desc === true);
        
        dispatchSearchWithUnsavedRowsCheck(searchPayload);
    }


    function dispatchSearchWithUnsavedRowsCheck(searchPayload:any){
        if (unsavedRowsCount > 0){
            setPendingConfirmationSearchPayload(searchPayload);
        }
        else {
            dispatch(ACTIONS.SET_SEARCH_PAYLOAD.withPayload({...searchPayload}));
        }
    }

    function dispatchPendingConfirmationPayload(){
        dispatch(ACTIONS.SET_SEARCH_PAYLOAD.withPayload({...pendingConfirmationSearchPayload}));
        setPendingConfirmationSearchPayload(null);
    }

    // Using a local search state to be used as a temporary place to set the search filters before dispatching the API search
    const [localSearchFilter, setLocalSearchFilter] = React.useState([] as Array<ReactTableFilter>);
    React.useEffect(() => {
        if (isInitialSearch || !showFilters){
            return;
        }
        // Waiting 1.5 second before dispatching the API search
        const timeout = setTimeout(() => {
            const currentSorting = {sortOrder: searchPayload?.sortOrder, sortField: searchPayload?.sortField, tableId: searchPayload?.tableId};
            const newSearchPayload = {...getDefaultSearchPayload(), ...currentSorting};
            addCDTRowsExpressionFilters(newSearchPayload, localSearchFilter);
            if (newSearchPayload.filterExpression != null && ArrayUtils.isNullOrEmpty(newSearchPayload.filterExpression?.expressions)){
                newSearchPayload.filterExpression = undefined;
            }
            dispatchSearchWithUnsavedRowsCheck(newSearchPayload);
        }, 1500)
    
        return () => clearTimeout(timeout)    
    }, [localSearchFilter])

    const getDeleteArchiveButtonLabel = () => {
        if (isGettingFlag) {
            return '';
        }
        if (isDeleting) {
            return 'Deleting...';
        }
        if (isArchiving) {
            return 'Archiving...';
        }
        
        return allowDeleteResult?.allowDeleteFlag ? 'Delete' : 'Archive'
    }

    const deleteOrArchive = () => {
        if (allowDeleteResult?.allowDeleteFlag) {
            setShowDeleteConfirmationModal(true);
        } else {
            setShowArchiveConfirmationModal(true);
        }
    }

    const showRenamedCustomDataTable = (newTableName: string) => {
        setShowRenameModal(false);
        setTableName(newTableName);
        globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, newTableName, newTableName)));
        services.messageService.showSuccessAutoDismissBanner(`Table successfully renamed to ${newTableName}.`, 10000);
    }

    return <CustomDataTableRecordsProvider services={services} state={state} dispatch={dispatch}>
        <div className="customTableTopParentContainer">
            <div className="customTableTopContainer">
                <div className="customTableTopContainerTitle">
                    <Button
                        variant="link"
                        data-class={selectedDetailTab == 'details' ? 'activeTab' : 'inactiveTab'}
                        onClick={() => setSelectedDetailTab('details')}
                    >Details</Button>
                    <Button
                        variant="link"
                        data-class={selectedDetailTab == 'logs' ? 'activeTab' : 'inactiveTab'}
                        onClick={() => setSelectedDetailTab('logs')}
                    >Logs</Button>
                </div>
            </div>
        </div>
        <Container data-class="containerWithHeaderAndGrid">
            <div>
                { selectedDetailTab === 'details' ?
                    <TPELoadingSpinner loading={isInitialSearch && customDataTableLoading}>
                        <div className="customTableTableHeader">
                            <ColumnLayout columns={2} data-class="fullColumnLayout">
                                <Box variant="h2">
                                            {tableName == '' ? 
                                                ''
                                            :
                                                <React.Fragment>
                                                    <span>{tableName} </span><span className="customTablesRecordCounter">({searchResult?.totalSize})</span>&nbsp;{unsavedRowsCount > 0 && <Badge color="red">{`${unsavedRowsCount} unsaved`}</Badge>}
                                                </React.Fragment>
                                            }
                                        </Box>
                                <Box float="right">
                                    <SpaceBetween direction="horizontal" size="m">
                                        { canEdit &&
                                            <React.Fragment>
                                                <Button variant="normal" 
                                                    disabled={tableStatus == CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED || isGettingFlag || isDeleting || isArchiving} 
                                                    loading={isGettingFlag}
                                                    onClick={deleteOrArchive}
                                                >
                                                    { getDeleteArchiveButtonLabel() }
                                                </Button>
                                                { canRename && <Button variant="normal" disabled={tableStatus == CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED} onClick={() => setShowRenameModal(true)}>Rename</Button> }
                                            </React.Fragment>
                                        }
                                        <Button variant="normal" onClick={() => setDownloadTableId(searchResult?.tableId || '')} disabled={isDownloading}>
                                            <SpaceBetween size="s" direction="horizontal">
                                                <Icon name="download"/>
                                                <span>{isDownloading ? 'Downloading...' : 'Download'}</span>
                                            </SpaceBetween>
                                        </Button>
                                        { canEdit && <Button variant="primary" disabled={tableStatus == CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED || isSaving} onClick={saveEditedRows}>
                                            {isSaving ? 'Saving...' : 'Save'}
                                        </Button> }
                                        
                                    </SpaceBetween>
                                </Box>
                                <Button
                                    className={showFilters ? "buttonIconActive" : "buttonIconInactive"}
                                    iconAlign="left"
                                    iconName="filter"
                                    onClick={() => {
                                        if (showFilters) {
                                            // This resets the table filters state
                                            setTableKey(tableKey + 1);
                                        }
                                        setShowFilters(!showFilters);
                                    }}
                                >Filter rows</Button>
                                {searchResult != null && searchResult.pagesCount > 1 &&
                                    <Box float="right">
                                        <Pagination
                                            currentPageIndex={searchResult.page}
                                            pagesCount={searchResult.pagesCount}
                                            disabled={searchResult.pagesCount == 0 || customDataTableLoading}
                                            onChange={({ detail }) => dispatchSearchWithUnsavedRowsCheck({...searchPayload, page: detail.currentPageIndex})}
                                        />
                                    </Box>
                                }
                            </ColumnLayout>
                        </div>
                        <TPELoadingSpinner loading={isGettingFlag || isGettingCLIs || isGettingCurrencyCodes}>
                            <div className="tableContainer">
                                <RecordsGrid loading={customDataTableLoading} tableKey={tableKey} showFilters={showFilters} onSortBy={onSortByChanged} onFilter={setLocalSearchFilter} editable={canEdit && tableStatus != CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED} />
                                {canEdit && tableStatus != CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ARCHIVED &&
                                    <div className="customTableTableFooter">
                                        <Button variant="normal" onClick={() => dispatch(ACTIONS.ADD_NEW_ROW)}>Add Row</Button>
                                    </div>
                                }
                            </div>
                        </TPELoadingSpinner>
                    </TPELoadingSpinner>
                :
                    null
                } 
                <RenameTableModal visible={showRenameModal} onCancel={() => setShowRenameModal(false)} onSubmit={showRenamedCustomDataTable} tableId={searchResult?.tableId || ''}/>
                <TPEErrorWatcher services={services} errors={[downloadError, allowDeleteFlagError, deleteError, archiveError]} />
                <TPEBasicModal
                    className="calcStepsCancelEditConfirmModal"
                    visible={pendingConfirmationSearchPayload != null}
                    action={ACTION_TYPE.CONFIRM_CANCEL}
                    title="Unsaved updates"
                    onCancel={() => setPendingConfirmationSearchPayload(null)}
                    onConfirm={() => dispatchPendingConfirmationPayload()}
                >
                    There {unsavedRowsCount === 1? 'is one unsaved change': `are ${unsavedRowsCount} unsaved changes`} in the grid. To keep these changes, please cancel this dialog and save changes before continuing.
                    <br />
                    <br />
                    If you choose to proceed, these changes will be discarded. Select 'Confirm' to proceed.
                </TPEBasicModal>
                <TPENavigator path={navigateUrl} />
                <TPEBasicModal
                    title="Confirm delete"
                    visible={showDeleteConfirmationModal}
                    action={ACTION_TYPE.CONFIRM_CANCEL}
                    onConfirm={() => { setShouldDeleteCustomDataTable(true); setShowDeleteConfirmationModal(false); } }
                    onCancel={() => setShowDeleteConfirmationModal(false)}
                >
                    Are you sure you want to delete the Custom Data Table {tableName}?
                </TPEBasicModal>
                <TPEBasicModal
                    title="Confirm archive"
                    visible={showArchiveConfirmationModal}
                    action={ACTION_TYPE.CONFIRM_CANCEL}
                    onConfirm={() => { setShouldArchiveCustomDataTable(true); setShowArchiveConfirmationModal(false); } }
                    onCancel={() => setShowArchiveConfirmationModal(false)}
                >
                    Are you sure you want to archive the Custom Data Table {tableName}?
                </TPEBasicModal>
            </div>
        </Container>
    </CustomDataTableRecordsProvider>
}