import React from 'react';
import { Box, Link, StatusIndicator } from "@amzn/awsui-components-react";
import { FILTERING_OPERATORS_FOR_NUMBER, FILTERING_OPERATORS_FOR_STRING, VarianceReportTableColumns, VARIANCE_TABLE_COLUMN_NAMES } from "./Constants";
import StringUtils from 'src/utils/stringUtils';
import CONSTANTS, { REPORT_STATUS_TO_ICON_TYPE } from '../../../utils/constants';
import ExecutionStatusIndicator from 'src/components/shared/ExecutionStatusIndicator';

/**
 * Create url to for tp-tax report
 * @param accountingDay
 * @param periodName 
 * @param item
 * @returns 
 */
const navigateToTPTaxReport = (accountingDay: string | undefined,
  periodName: string | undefined,
  item: any) => {
  if (item.reportingStatus == "success") {
    const parsedCalculationNumber = item.calculationNumber.replace('.', '*')
    const newFilterString = `${item.providerCompanyCode}+${parsedCalculationNumber}+${periodName}+${accountingDay}`;
    let encodedFilter = encodeURIComponent(newFilterString);
    return CONSTANTS.PAGE_NAV.TP_TAX_REPORT.URL + `/${encodedFilter}`
  }
};

/**
 * Create url to for tp-tax lef report
 * @param accountingDay
 * @param periodName 
 * @param item
 * @returns 
 */
const navigateToTPTaxLefReport = (accountingDay: string | undefined,
  periodName: string | undefined,
  item: any) => {
  const newFilterString = `${item.providerCompanyCode}+${periodName}+${accountingDay}`;
  let encodedFilter = encodeURIComponent(newFilterString);
  return CONSTANTS.PAGE_NAV.TP_TAX_LEF_REPORT.URL + `/${encodedFilter}`
};

/**
 * Create url to for atp calcreport
 * @param item
 * @returns 
 */
const navigateToCalcFileReport = (item: any) => {
  if (item.apttusRecordID !== "-") {
    return CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + `/${btoa(item.apttusRecordID)}`
  }
};

/**
 * Create url to for tp-tax CWB report
 * @param accountingDay
 * @param periodName 
 * @param item
 * @returns 
 */
const navigateToTPTaxCwbReport = (accountingDay: string | undefined,
  periodName: string | undefined,
  item: any) => {
  const newFilterString = `${item.workbookId}+${periodName}+${accountingDay}`;
  let encodedFilter = encodeURIComponent(newFilterString);
  return CONSTANTS.PAGE_NAV.TP_TAX_CWB_REPORT.URL + `/${encodedFilter}`
};

/**
 * If variance equals 0 then green; if not 0.0000, then red; otherwise, leave as is
 * @param item
 * @returns 
 */
const prepareCell = (item: string) => {
  // Extract numeric value from the string and convert to number
  const numericValue = parseFloat(item.replace('%', ''));

  // Check if the value is exactly 0.0000
  if (numericValue === 0.0000) {
    return (
      <Box color="text-status-success">
        {item}
      </Box>
    );
  } 
  // Check if the value is a number and not equal to 0.0000
  else if (!isNaN(numericValue)) {
    return (
      <Box color="text-status-error">
        {item}
      </Box>
    );
  } 
  // If it's not a number (e.g., "Not Applicable"), display as is
  else {
    return (
      <Box>
        {item}
      </Box>
    );
  }
};


const isValidValueForHyperlink = (record: any, columnName: string) => {
  return record[columnName] !== null
    && record[columnName] !== undefined
    && record[columnName] !== "-"
    && record["reportingStatus"] === CONSTANTS.REPORT_STATUS.SUCCESS;
}

const getCustomPropertiesForColumnDefinitions = (column: string,
  accountingDay: string | undefined,
  periodName: string | undefined) => {
  let cell;
  switch (column) {
    case VARIANCE_TABLE_COLUMN_NAMES.TARGET_COLUMN:
      cell = (e: any) => e[column] !== null && e[column] !== undefined ? `${e[column].toFixed(4)}%` : ""
      break
    case VARIANCE_TABLE_COLUMN_NAMES.ACTUAL_YTD_COLUMN:
      cell = (e: any) => isValidValueForHyperlink(e, column) ? <Link
        href={navigateToTPTaxReport(accountingDay, periodName, e)}
        external>{e[column]}</Link> : ""
      break
    case VARIANCE_TABLE_COLUMN_NAMES.PROVIDER_COLUMN:
      cell = (e: any) => isValidValueForHyperlink(e, column) ? <Link
        href={navigateToTPTaxLefReport(accountingDay, periodName, e)}
        external>{e[column]}</Link> : ""
      break
    case VARIANCE_TABLE_COLUMN_NAMES.CALCULATION_NUMBER_COLUMN:
      cell = (e: any) => isValidValueForHyperlink(e, column) ? <Link
        href={navigateToCalcFileReport(e)}
        external>{e[column]}</Link> : e[column]
      break
    case VARIANCE_TABLE_COLUMN_NAMES.VARIANCE_COLUMN:
      cell = (e: any) => e[column] !== null && e[column] !== undefined ? prepareCell(e[column]) : ""
      break
    case VARIANCE_TABLE_COLUMN_NAMES.WORKBOOK_ID_COLUMN:
      cell = (e: any) => isValidValueForHyperlink(e, column) ? <Link
        href={navigateToTPTaxCwbReport(accountingDay, periodName, e)}
        external>{e[column]}</Link> : e[column]
      break
    case VARIANCE_TABLE_COLUMN_NAMES.ATP_STATUS_COLUMN:
      cell = (e: any) => e[column] !== "" ? <ExecutionStatusIndicator status={e[column]} /> : "";
      break
    case VARIANCE_TABLE_COLUMN_NAMES.REPORTING_STATUS_COLUMN:
      cell = (e: any) => e[column] !== "" ? <StatusIndicator type={REPORT_STATUS_TO_ICON_TYPE[e[column]]}> {StringUtils.convertCamelCaseToTitleCase(e[column])} </StatusIndicator> : "";
      break
    default:
      cell = (e: any) => e[column] ?? "";
  }
  return { cell };
};

export const getCustomHeaderValue = (columnName: string) => {
  let headerValue;
  switch (columnName) {
    case VARIANCE_TABLE_COLUMN_NAMES.WORKBOOK_ID_COLUMN:
      headerValue = "CWB ID";
      break
    case VARIANCE_TABLE_COLUMN_NAMES.PLI_COLUMN:
      headerValue = "PLI";
      break
    case VARIANCE_TABLE_COLUMN_NAMES.ACTUAL_YTD_COLUMN:
      headerValue = "Actual YTD";
      break
    case VARIANCE_TABLE_COLUMN_NAMES.ATP_STATUS_COLUMN:
      headerValue = "ATP Status";
      break
    default:
      headerValue = StringUtils.convertCamelCaseToTitleCase(columnName)
  }
  return headerValue;
}


export const prepareColumnDefinitions = (accountingDay: string | undefined,
  periodName: string | undefined) => {
  const columnDefinations: any[] = [];

  VarianceReportTableColumns.forEach((column: string) => {
    columnDefinations.push({
      id: column,
      header: getCustomHeaderValue(column),
      sortingField: column,
      width: 300,
      ...getCustomPropertiesForColumnDefinitions(column, accountingDay, periodName)
    });
  });
  return columnDefinations;
};

const getCustomPropertiesForColumnFlitering = (column: string) => {
  let properties = {};
  switch (column) {
    case VARIANCE_TABLE_COLUMN_NAMES.TARGET_COLUMN:
    case VARIANCE_TABLE_COLUMN_NAMES.VARIANCE_AMOUNT_COLUMN:
      properties = {
        groupValuesLabel: column,
        operators: FILTERING_OPERATORS_FOR_NUMBER
      };
      break
    default:
      properties = {
        groupValuesLabel: column,
        operators: FILTERING_OPERATORS_FOR_STRING
      };

  }
  return properties;
};

export const getVarianceTableFilteringProperties = () => {
  const columnFilteringProperties: any[] = [];

  VarianceReportTableColumns.forEach((column: string) => {
    columnFilteringProperties.push({
      propertyLabel: getCustomHeaderValue(column),
      key: column,
      ...getCustomPropertiesForColumnFlitering(column)
    });
  });
  return columnFilteringProperties;


}