import ServiceCollection from "src/services/ServiceCollection";
import React, {useEffect, useMemo, useState} from "react";
import {FormField, Input, Link, Select} from '@amzn/awsui-components-react/polaris';
import {ColumnLayout, Icon, Popover} from "@amzn/awsui-components-react";


type ComponentProps = {
    services: ServiceCollection,
    resetNeeded?: any,
    selectedAccountingPeriod?: string,
    onAccountingPeriodSelected: (number: string | undefined) => void,
    onCalculationLineSelected: (number: string | undefined) => void
}

export default function TaxAuditRecordFilterSelector(props: ComponentProps) {
    const {
        services,
        resetNeeded,
        selectedAccountingPeriod,
        onAccountingPeriodSelected,
        onCalculationLineSelected
    } = props;

    const [calculations, setCalculations] = useState(null as unknown as string);
    const [accountingPeriodResult, accountingPeriodLoading, accountingPeriodError] = services.reportsService.getReportingPeriods();

    useEffect(() => {
        if (!accountingPeriodError) {
            return;
        }
        services.messageService.showErrorAutoDismissBanner(accountingPeriodError);
    }, [accountingPeriodError])

    useEffect(() => {
       setCalculations('');
    }, [resetNeeded])

    return (
        <ColumnLayout className='taxAuditFilterSelectorLayout' columns={2}>
            <FormField
                label="Select Accounting Period"
                errorText={accountingPeriodError}
            >
                <Select className="accountingPeriodDropdown"
                        selectedOption={selectedAccountingPeriod == null ? null : {
                            label: selectedAccountingPeriod,
                            value: selectedAccountingPeriod
                        }}
                        options={accountingPeriodResult == null ? [] : accountingPeriodResult.ReportingPeriodList.map((x => ({
                            label: x,
                            value: x
                        })))}
                        onChange={({detail}) => {
                            onAccountingPeriodSelected(detail.selectedOption.value);
                        }}
                        loadingText="Loading accounting periods"
                        statusType={accountingPeriodLoading ? "loading" : "finished"}
                        placeholder="Select accounting period"
                        virtualScroll={true}
                        filteringType="auto"
                />
            </FormField>

            <FormField
                label= {
                    <div>
                        <span className="cliWrapper">Enter a CLI</span>
                        <Popover
                            className="cliPopover"
                            dismissButton={false}
                            position="right"
                            size="small"
                            triggerType="custom"
                            content="Type 'NULL' to fetch all the Tax Audit Records with no CLI for selected period."
                        >
                            <Link variant="info">Info</Link>
                        </Popover>
                    </div>
            }>
                <Input className="calculationLinesInput"
                       onChange={({detail}) => {
                           const cli = detail.value.replace(/\s/g, ''); // remove all spaces from the input
                           setCalculations(cli);
                           onCalculationLineSelected(cli);
                       }}
                       value={calculations}
                       placeholder="Enter a CLI"
                />
            </FormField>
        </ColumnLayout>);
}
