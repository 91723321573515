export interface CalculationDetails {
    calculationBuilderState: string,
    calculationNumber: string,
    calculationStatus: string,
    calculationVersion: number,
    createUser: string,
    dataSetName: string,
    lastModifiedDate: string,
    modelName: string,
    templateId: string,
    templateName: string,
    templateVersion: number,
    statusReason: string,
    submitUser: string,
    submitDate: string,
    submitDateDisplay: string,
    submitComment: string,
    reviewUser: string,
    reviewDate: string,
    reviewDateDisplay: string,
    reviewComment: string,
    lastModifiedUser: string,
}

export interface CalculationFormula {
    expression: string,
    output: boolean,
    stepName: string,
    stepNumber: number
}

export interface ExpressionPart {
    value: string,
    type: ExpressionPartTypes,
    displayValue?: string,
    subTokens?: ExpressionPart[]
}

export enum ExpressionPartTypes {
    OPERATOR = "O",
    PARAMETER = "P",
    UNKNOWN = "U",
    GROUPING = "G",
    LITERAL = "L",
    KEYWORD = "K",
    NEW_LINE = "N",
    STEP = "S",
    DATA_SOURCE = "D",
    TP_ALLOCATION_ID = "A",
    FUNCTION = "F",
}