import React from 'react';
import 'src/assets/styles/react-table.scss';
import TPESelectableReactTable from 'src/components/shared/TPESelectableReactTable';
import AllocationGroup from 'src/models/tp-allocation/AllocationGroup';

export default function TPAllocationGroupSelectorGrid(props: {data: AllocationGroup[], loading: boolean, onSelect: (e: AllocationGroup | undefined) => any}) {

    const { data = [], loading, onSelect } = props;

    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "description",
            Header: (e: any) => <div className="cell-text">Name</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "allocationGroupDescription",
            Header: (e: any) => <div className="cell-text">Description</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        }]
    ,[data]);

    return <div>
        <TPESelectableReactTable {...{ 
                data, 
                columnDefinitions, 
                className: 'worksheetsGrid', 
                isSearching: loading, 
                isSearchingMessage: 'Searching', 
                showFilters: false,
                sortable: false,
                onRowClicked:(isChecked, allocationGroup: AllocationGroup, index) =>  onSelect(isChecked ? allocationGroup : undefined)} 
        } />
    </div>
    
}