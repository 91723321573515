import CONSTANTS, { CUSTOM_DATA_TABLE_CONSTANTS } from "src/utils/constants";
import apiService from "src/services/ApiCallService";
import {useEffect, useState} from "react";
import ErrorUtils from "src/utils/ErrorUtils";
import moment from 'moment';
import StringUtils from "src/utils/stringUtils";
import CustomDataTableRecord from "src/models/custom-data-tables/CustomDataTableRecord";
import CustomDataTableRowsResponse from "src/models/custom-data-tables/CustomDataTablesResponse";
import CustomDataTableRowsInfo, { CustomDataTableColumnDefinition } from "src/models/custom-data-tables/CustomDataTableRowsInfo";
import CDTSelectableValue from "src/models/custom-data-tables/CDTSelectableValue";
import FormattingService from "../utils/FormattingService";
import TPESearchRequest from "src/models/common/TPESearchRequest";
import { SearchCustomDataTablesResult } from "src/models/custom-data-tables/SearchCustomDataTablesResult";
import { SearchCustomDataTableRowsResult } from "src/models/custom-data-tables/SearchCustomDataTableRowsResult";
import CustomDataTableRowsSearchRequest from "src/models/custom-data-tables/CustomDataTableRowsSearchRequest";
import DateUtils from "src/utils/dateUtils";

export default class CustomDataTablesService {

    downloadTemplate(query: string): [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function downloadTemplate() {
                try {
                    setLoading(true);
                    const response = await apiService.downloadFile(CONSTANTS.ENVIRONMENT_VARIABLES.TEMPLATES_BUCKET, CONSTANTS.ENVIRONMENT_VARIABLES.CUSTOM_DATA_TABLE_TEMPLATE);
                    const json = await response.json();
                    setResult(json.downloadUrl);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query)) {
                downloadTemplate();
            }
        }, [query]);
        return [result, loading, error]
    }

    uploadFile(key: string | null | undefined, file: File, newTable: boolean, requiredColumns: any[]): [CustomDataTableRowsInfo, boolean, string] {
        const [result, setResult] = useState(null as unknown as CustomDataTableRowsInfo);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function uploadData(key: string, newTable: boolean, requiredColumns: any[]) {
                try {
                    setLoading(true);
                    await apiService.uploadToS3(CONSTANTS.ENVIRONMENT_VARIABLES.CUSTOM_TABLE_UPLOAD_BUCKET, key || '', file);
                    const response = await apiService.uploadCustomDataTable(key, newTable);
                    const json = await response.json() as CustomDataTableRowsResponse;
                    const rowsInfo = convertToCustomDataTableRowsInfoModel(json, requiredColumns, false); 
                    processDuplicateRecords(rowsInfo);
                    setResult(rowsInfo);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(key)) {
                uploadData(key || '', newTable, requiredColumns);
            }
        }, [key]);

        return [result, loading, error];
    }

    /**
    * Searches all Custom Data Tables
    * @param payload A TPESearchRequest containing search parameters
    * @param status A CDT status to filter by
    */
    searchCustomDataTables(payload?: TPESearchRequest): [SearchCustomDataTablesResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as SearchCustomDataTablesResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchCustomDataTables(payload);
                    const json = await response.json() as SearchCustomDataTablesResult;
                    json.pagesCount = Math.ceil(json.totalSize / json.pageSize);
                    json.tables.forEach(record => formatRecord(record));
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }
            if (payload != null) {
                fetchData()
            }
        }, [payload]);
        return [results, loading, error];
    }

    /**
    * Searches data rows of a Custom Data Table
    * @param tableId The table id
    */
    searchCustomDataTableRows(payload: CustomDataTableRowsSearchRequest, requiredColumns: any[], useSelectableValues: boolean = false) : [SearchCustomDataTableRowsResult, boolean, string] {
        const [results, setResults] = useState(null as unknown as SearchCustomDataTableRowsResult);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchCustomDataTableRows(payload);
                    const json = await response.json() as SearchCustomDataTableRowsResult;
                    json.pagesCount = Math.ceil(json.totalSize / json.pageSize);
                    json.rows.forEach(x => formatRecord(x));
                    json.rowsInfo = convertToCustomDataTableRowsInfoModel(json, requiredColumns, useSelectableValues)
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }

            if (payload != null) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    /**
    * Creates a new Custom Data Table
    * @param tableName the name of the new table to create
    */
    createCustomDataTable(tableName: string): [CustomDataTableRowsInfo, boolean, string] {
        const [results, setResults] = useState(null as unknown as CustomDataTableRowsInfo);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.createCustomDataTable(tableName);
                    const json = await response.json() as SearchCustomDataTableRowsResult;
                    json.rows = [];
                    setResults(convertToCustomDataTableRowsInfoModel(json, [], false));
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(tableName)) {
                fetchData()
            }
        }, [tableName]);
        return [results, loading, error];
    }

    /**
    * Renames a Custom Data Table
    * @param tableId the ID of the table to rename
    * @param newTableName the new name for the table
    */
    renameCustomDataTable(tableId: string, newTableName: string): [string, boolean, string] {
        const [results, setResults] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.renameCustomDataTable(tableId, newTableName);
                    const json = await response.json();
                    setResults(json.newTableName);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(newTableName)) {
                fetchData()
            }
        }, [newTableName]);
        return [results, loading, error];
    }

    saveCustomDataTable(rowsInfo: CustomDataTableRowsInfo | null, validate: boolean): [any, boolean, string] {
        const [result, setResult] = useState(null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function saveData(rowsInfo: CustomDataTableRowsInfo) {
                try {
                    setLoading(true);
                    const rowsToSave = convertToSaveCustomDataTableRequest(rowsInfo);
                    const response = await apiService.saveCustomDataTable(rowsInfo.tableId, rowsToSave, validate);
                    const json = await response.json();
                    if (json.errors) {
                        json.errors = new Map(Object.entries(json.errors));
                    }
                    setResult(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (rowsInfo != null) {
                saveData(rowsInfo);
            }
        }, [rowsInfo]);

        return [result, loading, error];
    }

    getCurrencyCodeLOV(query: string) : [string[], boolean, string] {
        const [currencyCodes, setCurrencyCodes] = useState([] as string[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCurrencyCodes();
                    const json = await response.json();
                    setCurrencyCodes(json.currencyCodes);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(query)) {
                fetchData();
            }
        }, [query]);

        return [currencyCodes, loading, error];
    }

    downloadCustomDataTable(tableId: string) : [string, boolean, string] {
        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(tableId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.downloadCustomDataTable(tableId);
                    const json = await response.json();
                    setResult(json.downloadUrl);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(tableId)) {
                fetchData(tableId);
            }
        }, [tableId]);

        return [result, loading, error];
    }

    getAllowDeleteFlag(tableId: string) : [{status: string, allowDeleteFlag: boolean}, boolean, string] {
        const [result, setResult] = useState(null as unknown as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(tableId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getCustomDataTableAllowDeleteFlag(tableId);
                    const json = await response.json();
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (!StringUtils.isNullOrEmpty(tableId)) {
                fetchData(tableId);
            }
        }, [tableId]);

        return [result, loading, error];
    }

    deleteCustomDataTable(shouldDelete: boolean, tableId: string) : [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function deleteData(tableId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.deleteCustomDataTable(tableId);
                    const json = await response.json();
                    setResult(json.tableId);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (shouldDelete && !StringUtils.isNullOrEmpty(tableId)) {
                deleteData(tableId);
            }
        }, [tableId, shouldDelete]);

        return [result, loading, error];
    }

    archiveCustomDataTable(shouldArchive: boolean, tableId: string) : [string, boolean, string] {
        const [result, setResult] = useState(null as unknown as string);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(tableId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.archiveCustomDataTable(tableId);
                    const json = await response.json();
                    setResult(json.tableId);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (shouldArchive && !StringUtils.isNullOrEmpty(tableId)) {
                postData(tableId);
            }
        }, [tableId, shouldArchive]);

        return [result, loading, error];
    }
}

function convertToCustomDataTableRowsInfoModel(json: CustomDataTableRowsResponse, requiredColumns: any[], useSelectableValues: boolean) {
    const processedData = flattenCustomDataTableRecords(json.rows || json.duplicateRows || [], json.legend, requiredColumns, useSelectableValues);
    const info: CustomDataTableRowsInfo = {
        tableId: json.tableId,
        tableName: json.tableName,
        records: processedData.records,
        legend: json.legend,
        columnDefinitions: processedData.columnDefinitions
    }
    return info;
}

function flattenCustomDataTableRecords(apiRecords: CustomDataTableRecord[], legend: any, requiredCols: any[], useSelectableValues:boolean):
        {records: any[], columnDefinitions: CustomDataTableColumnDefinition[]} {
    const formattingService: FormattingService = new FormattingService();
    const records: any[] = [];
    apiRecords.forEach((apiRecord: CustomDataTableRecord) => {
        const record: any = {
            tableId: apiRecord.tableId,
            tableName: apiRecord.tableName,
            calculationNumber : apiRecord.calculationNumber,
            createdDate: apiRecord.createdDate,
            lastUpdateUser: apiRecord.lastUpdateUser,
            lastUpdatedDate: apiRecord.lastUpdatedDate,
            currency: apiRecord.currency,
            classification: apiRecord.classification,
            cadence: apiRecord.cadence,
            period: apiRecord.period,
            periodFormatted: moment(apiRecord.period, 'YYYYMM').format('MMM-YYYY').toUpperCase(),
            status: apiRecord.status == CONSTANTS.CALCULATION_STATUS.ACTIVE ? 'Y' : 'N',
            isNewRecord: apiRecord.newRecord,
            isUpdated: false,
        }


        if (apiRecord.values != null) {
            Object.keys(apiRecord.values).forEach(k => {
                record[k] = useSelectableValues?
                    new CDTSelectableValue(apiRecord.values[k],formattingService.formatNumber(apiRecord.values[k],true), k) :
                    formattingService.formatNumber(apiRecord.values[k], true, 15); // Show 15 decimals only in the CDT module
            });
        }

        formatRecord(record);

        records.push(record);
    });

    const colDefs: CustomDataTableColumnDefinition[] = [];
    const sortedLegend = sortLegend(legend);
    
    requiredCols.forEach((x) => {
        if (x.ACCESSOR == CUSTOM_DATA_TABLE_CONSTANTS.VALUE) {
            sortedLegend.forEach((v, k) => {
                colDefs.push({
                    Header: v,
                    accessor: k,
                    headerClass: `columnWidth${x.WIDTH}`,
                    hasSelectableValue: true,
                    isUserDefined: true,
                    minimumFilterValueLength: 0
                });
            })
        } else {
            colDefs.push({
                Header: x.DISPLAY,
                accessor: x.ACCESSOR,
                headerClass: `columnWidth${x.WIDTH}`,
                hasSelectableValue: false,
                isUserDefined: false,
                minimumFilterValueLength: x.MINIMUM_FILTER_VALUE_LENGTH
            });
        }
    });

    return {records: records, columnDefinitions: colDefs};
}

function sortLegend(legend: any): Map<string, string> {
    const sortedLegend: Map<string, string> = new Map<string, string>();

    const sortedValueNumbers = Object.keys(legend).map(x => Number(x.replace('value', '')));
    sortedValueNumbers.sort((a, b) => a - b);

    sortedValueNumbers.forEach(x => {
        let valueKey = `value${x}`;
        sortedLegend.set(valueKey, legend[valueKey]);
    });

    return sortedLegend;
}

function formatRecord(record: any) {
    if (record.lastUpdatedDate != null) {
        record.lastUpdatedDateDisplay = DateUtils.formatTimestamp(record.lastUpdatedDate);
    }
}

function processDuplicateRecords(rowsInfo: CustomDataTableRowsInfo) {
    if (rowsInfo.records != null) {
        rowsInfo.records.forEach(x => {
            if (x.isNewRecord) {
                x.lastUpdatedDateDisplay = 'NEW';
                x.isSelected = true;
            }
        })
    }
}

function convertToSaveCustomDataTableRequest(rowsInfo: CustomDataTableRowsInfo) {
    const rowsToSave: CustomDataTableRecord[] = [];
    rowsInfo.records.forEach(x => {
        const apiRecord: CustomDataTableRecord  = {
            tableId: rowsInfo.tableId,
            tableName: rowsInfo.tableName,
            calculationNumber: x.calculationNumber,
            currency: x.currency,
            classification: x.classification,
            cadence: x.cadence,
            period: x.period == null ? null : x.period.includes('-') ? moment(x.period, 'MMM-YYYY').format('YYYYMM') : x.period,
            status: x.status ?
                (x.status == 'Y' ? CONSTANTS.CALCULATION_STATUS.ACTIVE : CONSTANTS.CALCULATION_STATUS.INACTIVE) :
                x.status,
            values: {},
            newRecord: x.isNewRecord
        }

        for (var k in rowsInfo.legend) {
            const valueColumn = x[k];
            if (valueColumn != null) {
                apiRecord.values[k] = Number(valueColumn.replaceAll(',', ''));
            }
        }
        rowsToSave.push(apiRecord);
    })
    return rowsToSave;
}