import {ColumnDefinition, TPTaxReportData} from "src/models/reports/TPTaxReport";
import React from "react";
import ServiceCollection from "src/services/ServiceCollection";
import {TP_TAX_REPORT_FORMATTING_EXCLUSION_SET} from "src/utils/constants";
import {useCollection} from "@amzn/awsui-collection-hooks";
import Table from "@amzn/awsui-components-react/polaris/table";

export default function TPTaxReportTableGrid(props: { data: TPTaxReportData[], services: ServiceCollection, columnHeaders: string[] }) {

    const {data = [], services, columnHeaders} = props;

    function formatAttributeValue(attributeName: string, attributeValue: any): string {
        if (!attributeValue) {
            return attributeValue;
        } else if (TP_TAX_REPORT_FORMATTING_EXCLUSION_SET.has(attributeName)) {
            return attributeValue
        }
        return isNaN(attributeValue) ? attributeValue :
            services.formattingService.formatString(attributeValue, false);
    }

    function fetchColumnDefinitions(columnHeaders: string[]) : ColumnDefinition[] {
        let columnDefinitions: ColumnDefinition[] = [];
        columnDefinitions.push({
            id: 'attributeName',
            header: 'Attribute Name',
            cell: (item: any) => item.displayName,
        });

        columnHeaders.forEach(columnHeader => {
            columnDefinitions.push({
                id: columnHeader,
                header: columnHeader,
                cell: (item: any) => formatAttributeValue(item?.displayName, item?.attributeValue[columnHeader]),
            })
        })

        return columnDefinitions;
    }

    const { items: rootItems, collectionProps } = useCollection(data, {
        expandableRows: {
            getId: (item) => item.attributeNameHash,
            getParentId: (item) => item.attributeParentNameHash?? null,
        }
    });

    return (
        <Table
            items={rootItems}
            columnDefinitions={fetchColumnDefinitions(columnHeaders)}
            {...collectionProps}
        />
    );
}