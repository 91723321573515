import ServiceCollection from "src/services/ServiceCollection";
import React, { useEffect, useState } from "react";
import { Box, ExpandableSection, Header, Link, Select, SpaceBetween } from "@amzn/awsui-components-react";
import { initialState, ReadOnlyCalculationState } from "src/services/calculations/ReadOnlyCalculationState";
import ReadOnlyCalculationView_LineSelector from "src/components/calculations/ReadOnlyCalculationView_LineSelector";
import ReadOnlyCalculationView_StepsGrid from "src/components/calculations/ReadOnlyCalculationView_StepsGrid";
import { ACTIONS, readOnlyCalculationReducer } from "src/services/calculations/ReadOnlyCalculationReducer";
import TPEAction from "src/models/common/TPEAction";
import { GlobalAppContext } from "../App";
import CONSTANTS from "src/utils/constants";
import { useHistory } from 'react-router-dom';
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import ArrayUtils from "src/utils/arrayUtils";
import TPENavigator from "../TPENavigator";


export type ContextType = {
    state: ReadOnlyCalculationState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const ReadOnlyCalculationProvider = (props: any) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <ReadOnlyCalculationContext.Provider value={providerValue}>
            {children}
        </ReadOnlyCalculationContext.Provider>
    );
}
export const ReadOnlyCalculationContext = React.createContext(null as unknown as ContextType);

export default function ReadOnlyCalculationView(props: { expanded?: boolean, services: ServiceCollection }) {
    const { userProfile } = React.useContext(GlobalAppContext);
    const { services } = props;
    const [state, dispatch] = useReducerWithLogger(readOnlyCalculationReducer, initialState);
    const { expanded = true } = props;
    const { selectedCalculationNumber, versions, selectedVersion } = state;
    const history = useHistory();
    const [ navigateURL, setNavigateURL ] = useState('');
    const [ currentVersion, setCurrentVersion ] = useState(null as unknown as number);

    const [versionsResult, versionsResultLoading] = services.readOnlyCalculationService.getVersions(selectedCalculationNumber);

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(versionsResult)) {
            return;
        }
        dispatch(ACTIONS.SET_VERSIONS.withPayload(versionsResult));
        setCurrentVersion(Math.max(...versionsResult));
    }, [versionsResult]);

    useEffect(() => {
        if (versions == null) {
            return;
        }
        if (versions.length === 1) {
            const selectedVersion = versions[0].toString();
            dispatch(ACTIONS.SET_SELECTED_VERSION.withPayload(selectedVersion));
        }
    }, [versions])

    const VersionsOnSelect = (e: any) => {
        const selectedOptionValue = e.detail.selectedOption.value;
        if (selectedVersion?.toString() === selectedOptionValue){
            return;
        }
        dispatch(ACTIONS.SET_SELECTED_VERSION.withPayload(selectedOptionValue));
    }

    function formatVersion() {
        if (selectedVersion != null) {
            return "Calculation Version " + selectedVersion;
        } else {
            return "Select a Version"
        }
    }

    const selectedVersionFormatted = formatVersion();

    return (
        <React.Fragment>
            <TPENavigator path={navigateURL}/>
            <ReadOnlyCalculationProvider services={services} state={state} dispatch={dispatch}>
                <SpaceBetween size={"l"}>
                    <ReadOnlyCalculationView_LineSelector />
                    <ExpandableSection className="polarisExpandableSection" variant="container"
                        defaultExpanded={expanded}
                        header={
                            <Header
                                actions={
                                    <SpaceBetween direction="horizontal" size="l" className="containerHeaderActions">
                                        <Link className="view logs">View logs</Link>
                                        <span onClick={(event: any) => event.stopPropagation()}>
                                            <Select className="smallDropdown calculationVersionsDropdown"
                                                disabled={selectedCalculationNumber == null}
                                                selectedOption={{ label: selectedVersionFormatted, value: selectedVersionFormatted }}
                                                onChange={VersionsOnSelect}
                                                options={versions.map(x => ({ label: x == currentVersion ? `${x} (current)` : String(x), value: String(x) }))}
                                                placeholder="Choose an option"
                                                selectedAriaLabel="Selected"
                                                empty="No options"
                                            />
                                        </span>
                                    </SpaceBetween>
                                }
                            >
                                <h2>Calculation steps</h2>
                            </Header>
                        }
                    >

                        <div>
                            <div className="readOnlyCalculationStepsHeader">
                                <ReadOnlyCalculationView_StepsGrid />
                            </div>
                        </div>

                    </ExpandableSection>
                    <Box float="right"><Link onFollow={() => setNavigateURL('/calculations/customDataTables')}>View Service Rates</Link></Box>
            
                </SpaceBetween>
            </ReadOnlyCalculationProvider>
        </React.Fragment>
    );
}