export default interface CustomDataTableRowsInfo {
    tableId: string
    tableName: string
    records: any[]
    legend: Map<string, string>
    columnDefinitions: Array<CustomDataTableColumnDefinition>
}

export const emptyCustomDataTableRowsInfo: CustomDataTableRowsInfo = {
    tableId: '',
    tableName: '',
    records: [],
    legend: new Map<string, string>(),
    columnDefinitions: []
}

export interface CustomDataTableColumnDefinition {
    Header: string, 
    accessor: string, 
    headerClass: string, 
    hasSelectableValue: boolean,
    isUserDefined: boolean,
    minimumFilterValueLength: number,
}