import React from 'react';
import { Link, Pagination, SpaceBetween, Button } from '@amzn/awsui-components-react/polaris';
import {CLI} from '../../models/common/CLI';
import 'src/assets/styles/react-table.scss';
import { DefaultColumnFilter, TPEReactTable } from '../shared/TPEReactTable';
import CONSTANTS from '../../utils/constants';
import TPENavigator from '../TPENavigator';
import { getPermissions } from '../AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';

type ComponentProps = {
    searchResult: CLI[];
    isSearching: boolean;
    searchError: string;
    isVisible: boolean,
    searchTotalSize: number,
    searchCurrentPage: number,
    searchPageSize: number,
    onSortBy: (e: Array<any>) => any,
    onFilter: (e: Array<any>) => any,
    onPageChanged: (e: number) => any,
    showFiltering?: boolean,
};

export default function SearchCalculationsGrid(props: ComponentProps) {
    const { searchResult, isSearching, searchError, isVisible,
        searchTotalSize, searchCurrentPage, searchPageSize, onSortBy, onFilter, onPageChanged, showFiltering = false} = props;

    const { canEdit } = getPermissions(AppModules.CALCULATION_BUILDER);

    const [showFilters, setShowFilters] = React.useState(false);
    const [tableKey, setTableKey] = React.useState(0);
    const [navigateURL, setNavigateURL] = React.useState('');

    const navigateToCalcBuilder = (recordId: string) => {
        const url = CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + '/' + btoa(recordId);
        setNavigateURL(url);
    };

    const activeItemsCount =
        searchResult == null ? 0 :
            searchResult.reduce((t, { calculationStatus }) => t + (calculationStatus === 'Active' ? 1 : 0), 0);

    const columnDefinitions = [
        {
            accessor: "calculationNumber",
            Header: (e: any) => <div className="cell-text">Calculation Number</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => {
                return e.row.original.calculationStatus?.toLowerCase() !== CONSTANTS.CALCULATION_STATUS.YET_TO_START.toLowerCase() ?
                    <Link className="cell-link selectCalculationLink" onFollow={({ detail }) => navigateToCalcBuilder(e.row.original.recordId)}>{e.value}</Link> : <div className="cell-text">{e.value}</div>
            },
        },
        {
            accessor: "functionalSegment",
            Header: (e: any) => <div className="cell-text">Functional Segment</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "calculationName",
            Header: (e: any) => <div className="cell-text">Calculation Name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "agreementStatus",
            Header: (e: any) => <div className="cell-text">Agreement Status</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "calculationStatus",
            Header: (e: any) => <div className="cell-text">Calculation Status</div>,
            Filter: DefaultColumnFilter,
            Cell: (cellInfo: any) => {
                return canEdit && cellInfo.value?.toLowerCase() === CONSTANTS.CALCULATION_STATUS.YET_TO_START.toLowerCase() ?
                    <Link className="cell-link"
                        onFollow={e => navigateToCalcBuilder(cellInfo.row.original.recordId)}>{cellInfo.value}</Link> 
                    : 
                    <div className="cell-text">{cellInfo.value}</div>
            },
        },
        {
            accessor: "apttusCalculationStatus",
            Header: (e: any) => <div className="cell-text">APTTUS Status</div>,
            Filter: DefaultColumnFilter,
            Cell: (cellInfo: any) => <div className="cell-text">{cellInfo.value}</div>
        },
        {
            accessor: "taskType",
            Header: (e: any) => <div className="cell-text">Task Type</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "accountingOwner",
            Header: (e: any) => <div className="cell-text">TP Accounting Owner</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "taxOwner",
            Header: (e: any) => <div className="cell-text">TP Tax Owner</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "providerCode",
            Header: (e: any) => <div className="cell-text">First Party</div>,
            Filter: DefaultColumnFilter,
        },
        {
            accessor: "recipientCode",
            Header: (e: any) => <div className="cell-text">Second Party</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        }
    ];

    const pagesCount = parseInt(Math.ceil(searchResult == null ? 0 : searchTotalSize / searchPageSize).toString());

    return (
        <React.Fragment>
            <TPENavigator path={navigateURL} />
            {
                isVisible ?
                    <div>
                        <div className="tableHeader">
                            <SpaceBetween direction="horizontal" size="m">
                                {showFiltering ?
                                    <React.Fragment>
                                        <Button
                                            className={showFilters ? "buttonIconActive" : "buttonIconInactive"}
                                            iconAlign="left"
                                            iconName="filter"
                                            onClick={() => {
                                                if (showFilters) {
                                                    // This resets the table filters state
                                                    setTableKey(tableKey + 1);
                                                }
                                                setShowFilters(!showFilters);
                                            }}
                                        >Filter rows</Button>
                                        <div className="recordCounter">Total records: {searchTotalSize}</div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <h3>TP Calculations</h3>
                                        <div className="recordCounter searchCalcRecordCounter">
                                            {
                                                activeItemsCount
                                                    ? `(${activeItemsCount}/${searchResult != null ? searchResult.length : 0} active)`
                                                    : `(${searchResult != null ? searchResult.length : 0})`
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                            </SpaceBetween>
                            {
                                pagesCount > 1 &&
                                <Pagination
                                    currentPageIndex={searchCurrentPage}
                                    pagesCount={pagesCount}
                                    disabled={searchResult == null || searchResult.length === 0 || (isSearching)}
                                    onChange={({ detail }) => onPageChanged(detail.currentPageIndex)}
                                ></Pagination>
                            }
                        </div>
                        <div className="tableContainer">
                            <TPEReactTable key={tableKey} {...{
                                data: searchResult,
                                columnDefinitions,
                                className: "calculations",
                                isSearching,
                                showFilters: showFilters,
                                emptyMessage: "We can’t find a match.",
                                onFilter,
                                onSortBy
                            }} />
                        </div>
                    </div>
                    : <span></span>
                }
            </React.Fragment>
    );
}