import React from 'react';
import { ExpandableSection, Header, SpaceBetween, ButtonDropdown, Link, Box, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import { Agreement } from '../../models/agreements/AgreementDetailsResult';
import { AgreementVersionItem } from '../../models/agreements/AgreementVersionsResult';
import { TPELoadingSpinner } from '../shared/TPELoadingSpinner';
import CONSTANTS from 'src/utils/constants';

function ValueWithLabel(props: any) {
    const { label } = props;
    return (
        <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                {label}
            </Box>
            <div>{props.children ? props.children : '-'}</div>
        </div>
    );
}


function selectVersion(event: any, agreement: Agreement, setSelectedVersion: (version: string) => any) {
    event.stopPropagation();
    if (agreement.agreementNumber !== event.detail.text) {
        setSelectedVersion(event.detail.id);
    }
}

export default function TPAgreementDetails(props: { agreement: Agreement, versions: AgreementVersionItem[], expanded?: boolean, setSelectedVersion: (version: string) => any, loading: boolean }) {
    const { agreement, versions, expanded = false, setSelectedVersion, loading } = props;
    const agreementVersions = new Array<any>();
    versions.forEach(version => { agreementVersions.push({ text: version.agreementNumber, id: version.recordId }) });

    return (
        <ExpandableSection className="polarisExpandableSection" variant="container"
            defaultExpanded={expanded}
            header={
                <Header
                    actions={
                        <SpaceBetween direction="horizontal" size="l" className="containerHeaderActions">
                            <Link className="apttusLink" onFollow={(event: any) => event.stopPropagation()} external href={CONSTANTS.ENVIRONMENT_VARIABLES.APTTUS_AGREEMENT_VIEW_URL?.replace('(recordId)', agreement.recordId)} target="_blank">View in APTTUS</Link>
                            <span onClick={(event: any) => event.stopPropagation()}>
                                <ButtonDropdown className="polarisSmallButtonDropdown" onItemClick={(event: any) => selectVersion(event, agreement, setSelectedVersion)}
                                    items={agreementVersions}>
                                    Version {agreement.agreementNumber}
                                </ButtonDropdown>
                            </span>
                        </SpaceBetween>
                    }
                >
                    <h2>TP Agreement details - {agreement.agreementName} ({agreement.agreementNumber})</h2>
                </Header>
            }
        >
            <div className="agreementContentContainer">
                <TPELoadingSpinner loading={loading}>
                    <React.Fragment>
                        <div className="grid-item"><ValueWithLabel label="Effective Date">{agreement.effectiveDate ? new Date(agreement.effectiveDate).toLocaleString() : '-'}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Agreement Number">{agreement.agreementNumber}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="First Party">{agreement.firstParty}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Second Party">{agreement.secondParty}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Participating Party">{agreement.participatingParty}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Termination Date">{agreement.terminationDate ? new Date(agreement.terminationDate).toLocaleString() : '-'}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Status category">{agreement.agreementStatusCategory}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Entity Type (1st Party)">{agreement.firstPartyEntityType}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Entity Type (2nd Party)">{agreement.secondPartyEntityType}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Entity Type (Participating Party)">{agreement.participatingPartyEntityType}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Accounting Principles (GAAP)">{agreement.accountingPrinciples}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Status">{agreement.agreementStatus}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Jurisdiction (1st Party)">{agreement.firstPartyJurisdiction}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Jurisdiction (2nd Party)">{agreement.secondPartyJurisdiction}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Jurisdiction (Participating Party)">{agreement.participatingPartyJurisdiction}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Agreement Currency">{agreement.agreementCurrency}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="TP Tax Owner">{agreement.taxOwner}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Co. Code (1st Party)">{agreement.firstPartyCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Co. Code (2nd Party)">{agreement.secondPartyCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Co. Code (Participating Party)">{agreement.participatingPartyCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Business Reason for Request">{agreement.businessReason}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Accounting Owner">{agreement.accountingOwner}</ValueWithLabel></div>
                        <div className="grid-item grid-item-3"><ValueWithLabel label="Justification for the Agreement">{agreement.justification}</ValueWithLabel></div>
                    </React.Fragment>
                </TPELoadingSpinner>
            </div>
        </ExpandableSection>
    )
}
