import TPEAction from '../../models/common/TPEAction';
import { ReviewJournalsState } from './ReviewJournalsState';

/**
 * List here the actions supported by this reducer
 */
export const ACTIONS = Object.freeze({
    SET_REVIEW_JOURNALS: new TPEAction('SET_REVIEW_JOURNALS'),
    SET_EXECUTION_JOURNALS: new TPEAction('SET_EXECUTION_JOURNALS'),
    SET_EXECUTION_GROUP_DETAILS: new TPEAction('SET_EXECUTION_GROUP_DETAILS')  
});

/**  
* This function is responsible for updating the state based on action type
* @param state The current dashboard state
* @param action The current dispatched action 
*/
export function reviewJournalsReducer(state: ReviewJournalsState, action: TPEAction): ReviewJournalsState {
    switch (action.type) {
        case ACTIONS.SET_REVIEW_JOURNALS.type: {
            return {
                ...state,
                reviewJournals: action.payload,
            }
        }
        case ACTIONS.SET_EXECUTION_JOURNALS.type: {
            return {
                ...state,
                executionJournals: action.payload,
            }
        }
        case ACTIONS.SET_EXECUTION_GROUP_DETAILS.type: {
            return {
                ...state,
                executionGroupDetails: action.payload
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}