import { Alert, Box, Button, FormField, Input, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { GlobalAppContext } from 'src/components/App';
import ATPUserCommentModal from 'src/components/shared/ATPUserCommentModal';
import { ACTION_TYPE, DEFAULT_BUTTON_LABEL, TPEBasicModal } from 'src/components/shared/TPEBasicModal';
import TPEErrorWatcher from 'src/components/shared/TPEErrorWatcher';
import { WorksheetMode } from 'src/models/tp-allocation/TPAllocationEnums';
import { TPAllocationContext } from 'src/services/tp-allocation/TPAllocationContext';
import { ACTIONS } from 'src/services/tp-allocation/TPAllocationReducer';
import CONSTANTS from 'src/utils/constants';

export default function TPAllocationActionBar() {
    const { services, tpAllocationState, tpAllocationDispatch } = React.useContext(TPAllocationContext);
    const { userProfile } = React.useContext(GlobalAppContext);
    const { worksheetMode, 
            worksheet, 
            worksheetTemplate, 
            datasetRecords, 
            worksheetTotals, 
            allocationGroups } = tpAllocationState;

    const isTemplate = worksheetMode === WorksheetMode.TEMPLATE;
    const entity = isTemplate ? worksheetTemplate : worksheet;
    const entityType = isTemplate ? "worksheet template" : "worksheet";

    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [notes, setNotes] = useState('');
    const [doSubmitWorksheet, setDoSubmitWorksheet] = useState('');
    const [doApproveWorksheet, setDoApproveWorksheet] = useState('');
    const [doRejectWorksheet, setDoRejectWorksheet] = useState('');
    const [doSubmitTemplate, setDoSubmitTemplate] = useState('');
    const [doApproveTemplate, setDoApproveTemplate] = useState('');
    const [doRejectTemplate, setDoRejectTemplate] = useState('');
    const [submitRequirements, setSubmitRequirements] = useState([] as string[]);

    const [submitWorksheetResult, isSubmittingWorksheet, submitWorksheetError] = services.tpAllocationService.submitWorksheet(doSubmitWorksheet, worksheet?.worksheetId || '', notes.trim());
    const [approveWorksheetResult, isApprovingWorksheet, approveWorksheetError] = services.tpAllocationService.approveWorksheet(doApproveWorksheet, worksheet?.worksheetId || '');
    const [rejectWorksheetResult, isRejectingWorksheet, rejectWorksheetError] = services.tpAllocationService.rejectWorksheet(doRejectWorksheet, worksheet?.worksheetId || '', notes.trim());

    const [submitTemplateResult, isSubmittingTemplate, submitTemplateError] = services.tpAllocationService.submitWorksheetTemplate(doSubmitTemplate, worksheetTemplate?.templateId || '', notes.trim());
    const [approveTemplateResult, isApprovingTemplate, approveTemplateError] = services.tpAllocationService.approveWorksheetTemplate(doApproveTemplate, worksheetTemplate?.templateId || '');
    const [rejectTemplateResult, isRejectingTemplate, rejectTemplateError] = services.tpAllocationService.rejectWorksheetTemplate(doRejectTemplate, worksheetTemplate?.templateId || '', notes.trim());

    useEffect(() => {
        if (submitWorksheetResult == null) {
            return;
        }
        setShowSubmitModal(false);
        services.messageService.showSuccessAutoDismissBanner('Worksheet successfully submitted for review.');
        tpAllocationDispatch(ACTIONS.SET_ENTITY_DETAILS_NEED_REFRESH.withPayload(true));
    }, [submitWorksheetResult])

    useEffect(() => {
        if (approveWorksheetResult == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner('Worksheet successfully approved.');
        tpAllocationDispatch(ACTIONS.SET_ENTITY_DETAILS_NEED_REFRESH.withPayload(true));
    }, [approveWorksheetResult])

    useEffect(() => {
        if (rejectWorksheetResult == null) {
            return;
        }
        setShowRejectModal(false);
        services.messageService.showSuccessAutoDismissBanner('Worksheet successfully rejected.');
        tpAllocationDispatch(ACTIONS.SET_ENTITY_DETAILS_NEED_REFRESH.withPayload(true));
    }, [rejectWorksheetResult])

    useEffect(() => {
        if (submitTemplateResult == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner('Worksheet template successfully submitted for review.');
        tpAllocationDispatch(ACTIONS.SET_ENTITY_DETAILS_NEED_REFRESH.withPayload(true));
    }, [submitTemplateResult])

    useEffect(() => {
        if (approveTemplateResult == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner('Worksheet template successfully approved.');
        tpAllocationDispatch(ACTIONS.SET_ENTITY_DETAILS_NEED_REFRESH.withPayload(true));
    }, [approveTemplateResult])

    useEffect(() => {
        if (rejectTemplateResult == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner('Worksheet template successfully rejected.');
        tpAllocationDispatch(ACTIONS.SET_ENTITY_DETAILS_NEED_REFRESH.withPayload(true));
    }, [rejectTemplateResult])

    const disableActions = () => {
        return !isTemplate && worksheet?.executionStatus == CONSTANTS.PROCESSING_STATUS.IN_PROGRESS;
    }
    
    const checkAndSubmitForReview = () => {
        const submitRequirements = [
            ...services.tpAllocationService.getValidationMessagesForUnsavedItems(datasetRecords, worksheetTotals, allocationGroups),
            ...services.tpAllocationService.getValidationMessagesForMissingItems(datasetRecords, worksheetTotals, allocationGroups)
        ];
        if (!isTemplate && worksheet?.lastExecutionTimestamp == null) {
            submitRequirements.push('Worksheet must be executed before submitting for review.');
        }
        setSubmitRequirements(submitRequirements);
        if (submitRequirements.length > 0) {
            return;
        }

        setShowSubmitModal(true);
    }

    const processSubmit = (comment: string) => {
        setNotes(comment);
        if (isTemplate) {
            setDoSubmitTemplate((new Date()).toString())
        } else {
            setDoSubmitWorksheet((new Date()).toString());
        }
    }

    const processApprove = () => {
        if (isTemplate) {
            setDoApproveTemplate((new Date()).toString());
        } else {
            setDoApproveWorksheet((new Date()).toString());
        }
        setShowApproveModal(false);
    }

    const processReject = (comment: string) => {
        setNotes(comment);
        if (isTemplate) {
            setDoRejectTemplate((new Date()).toString());
        } else {
            setDoRejectWorksheet((new Date()).toString());
        }
    }
    
    return <Box float="right">
        <SpaceBetween size="s" direction="horizontal">
            {entity?.status == CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS && 
                <Button variant="primary" disabled={disableActions()} onClick={checkAndSubmitForReview}>Submit for review</Button>
            }
            {entity?.status == CONSTANTS.CALCULATION_STATUS.PENDING_REVIEW && 
                <React.Fragment>
                    <Button variant="primary" disabled={disableActions() || userProfile.user == entity?.submitUser} onClick={() => setShowApproveModal(true)}>
                        Approve {entityType}
                    </Button>
                    <Button variant="primary" disabled={disableActions() || userProfile.user == entity?.submitUser} onClick={() => setShowRejectModal(true)}>
                        Reject {entityType}
                    </Button>
                </React.Fragment>
            }
        </SpaceBetween>
        <ATPUserCommentModal
            key="tpAllocationSubmitModal"
            visible={showSubmitModal} 
            header={`Submit ${entityType} for review`}
            content={ <SpaceBetween size="s" direction="vertical">
                         <span>Provide a brief comment and click on <b>Confirm</b> to submit this {entityType} for review.</span>
                         <Alert type="info" dismissible={false}>
                            <strong>Note: </strong>Make sure the worksheet has been executed with the latest changes before submitting for review.
                        </Alert>
                      </SpaceBetween>
                    }
            formFieldLabel="Submit comment"
            isSaving={isSubmittingWorksheet || isSubmittingTemplate}
            saveError={isTemplate ? submitTemplateError : submitWorksheetError}
            onCancel={() => setShowSubmitModal(false)}
            onSubmit={(comment) => processSubmit(comment)}
        />
        <TPEBasicModal
            className="tpAllocationApproveModal"
            visible={showApproveModal}
            title={`Approve ${entityType} confirmation`}
            action={ACTION_TYPE.CONFIRM_CANCEL}
            onConfirm={processApprove}
            onCancel={(() => setShowApproveModal(false))}
        >
            Click on <b>Confirm</b> to approve this {entityType}.
        </TPEBasicModal>
        <ATPUserCommentModal
            key="tpAllocationRejectModal"
            visible={showRejectModal} 
            header={`Reject ${entityType}`}
            content={<span>Provide a reason and click on <b>Confirm</b> to reject this {entityType}.</span>}
            formFieldLabel="Rejection reason"
            isSaving={isRejectingWorksheet || isRejectingTemplate}
            saveError={isTemplate ? rejectTemplateError : rejectWorksheetError}
            onCancel={() => setShowRejectModal(false)}
            onSubmit={(rejectReason) => processReject(rejectReason)}
        />
        <TPEBasicModal
                title="Submission requirements not met"
                action={ACTION_TYPE.OK_ONLY}
                visible={submitRequirements.length > 0}
                onConfirm={() => setSubmitRequirements([])} onCancel={() => setSubmitRequirements([])} >
            The following requirements need to be fulfilled before submitting this {entityType}:
            <br />
            <ul>
                {submitRequirements.map(x => <li>{x}</li>)}
            </ul>
        </TPEBasicModal>
        <TPEErrorWatcher services={services} errors={[approveWorksheetError, approveTemplateError]} />
    </Box>
}