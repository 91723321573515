import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, ColumnLayout, FormField, Input, Modal, Select, SpaceBetween } from '@amzn/awsui-components-react';
import StringUtils from 'src/utils/stringUtils';
import { CustomCOAContext } from './TablesView';
import CONSTANTS from 'src/utils/constants';

export default function CreateTableModal(props: {visible: boolean, onCancel: () => any, onSubmit: (tableId: string) => any}) {

    const { visible, onCancel, onSubmit } = props;

    const { services } = useContext(CustomCOAContext);

    const [coaSegment, setCoaSegment] = useState({ label: CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI, value: CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI } as any);
    const [enteredTableName, setEnteredTableName] = useState<string>(CONSTANTS.CUSTOM_COA_DEFINITION_PREFIX);
    const [createTableName, setCreateTableName] = useState('');
    const [errorDisplay, setErrorDisplay] = useState('');

    const [createResult, creating, createError] = services.customCOAService.createCustomCOADefinition(createTableName, coaSegment.value);

    useEffect(() => {
        // Reset modal
        if (visible) {
            setErrorDisplay('');
            setEnteredTableName(CONSTANTS.CUSTOM_COA_DEFINITION_PREFIX);
            setCreateTableName('');
            setCoaSegment({ label: CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI, value: CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI } as any);
        }
    }, [visible])

    useEffect(() => {
        if (createResult != null) {
            setCreateTableName('');
            services.messageService.showSuccessAutoDismissBanner(`${createResult.tableName} successfully created.`, 10000);
            onSubmit(createResult.tableId);
        }
    }, [createResult])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(createError)) {
            setErrorDisplay(createError);
        }
    }, [createError])

    const getCOASegmentOptions = () => {
        const coaSegments: any[] = [];
        Object.values(CONSTANTS.COA_SEGMENT_MAPPING).forEach(x => coaSegments.push({ label: x.UI, value: x.UI }));
        return coaSegments;
    }

    const coaSegmentOptions = useMemo(getCOASegmentOptions, []);

    return <Modal
                onDismiss={onCancel}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={onCancel}>Cancel</Button>
                            <Button
                                    variant="primary"
                                    disabled={enteredTableName == CONSTANTS.CUSTOM_COA_DEFINITION_PREFIX || creating}
                                    onClick={() => setCreateTableName(enteredTableName)}
                            >
                                {creating ? 'Creating...' : 'Create'}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Create Custom COA Definition"
            >
                <SpaceBetween direction="vertical" size="s">
                    <ColumnLayout columns={2}>
                        <FormField label="Table Name" description="Enter a name for the new Custom COA Definition">
                            <Input value={enteredTableName}
                                onChange={({ detail }) => {
                                    if (detail.value.indexOf(CONSTANTS.CUSTOM_COA_DEFINITION_PREFIX) == 0) {
                                        setEnteredTableName(detail.value)
                                    }
                                }} />
                        </FormField>
                        <FormField
                            description="Choose the COA segment for the custom rollups"
                            label="COA Segment"
                        >
                            <Select
                                selectedOption={coaSegment}
                                options={coaSegmentOptions}
                                onChange={(event) => setCoaSegment(event.detail.selectedOption)}
                            />
                        </FormField>
                    </ColumnLayout>
                    <Alert type="error" visible={!StringUtils.isNullOrEmpty(errorDisplay)}>{errorDisplay || ''}</Alert>
                </SpaceBetween>
            </Modal>
}