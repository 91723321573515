const DomUtils = {
    isDescendantOfClassName(element: HTMLElement | null, className: string): boolean {
        if (element == null){
            return false;            
        }
        if (element.className?.includes(className)){
            return true;
        }
        return DomUtils.isDescendantOfClassName(element.parentElement, className);
    }
};

export default DomUtils;