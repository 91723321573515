import React, { useEffect } from 'react';
import {
    FormField,
    Button,
    Modal,
    Box,
    SpaceBetween,
    Textarea,
    Alert
} from '@amzn/awsui-components-react/polaris';
import StringUtils from 'src/utils/stringUtils';

type ComponentProps = {
    header: string,
    visible: boolean,
    content: any,
    formFieldLabel: string,
    isSaving: boolean,
    saveError: string,
    onCancel: () => any,
    onSubmit: (comment: string) => any,
}

export default function ATPUserCommentModal(props: ComponentProps) {
    const { header, visible, content, formFieldLabel, isSaving, saveError = "test", onCancel, onSubmit } = props;
    const [comment, setComment] = React.useState(undefined as unknown as string);
    const [commentCharCount, setCommentCharCount] = React.useState(0);
    const MAX_REASON_LENGTH_ALLOWED: number = 200;

    useEffect(() => {
        setCommentCharCount(comment?.length || 0);
    }, [comment])

    useEffect(() => {
        if (commentCharCount >= MAX_REASON_LENGTH_ALLOWED){
            setComment(comment.substring(0, MAX_REASON_LENGTH_ALLOWED));
        }
    }, [commentCharCount])

    const cancelModal = () => {
        setComment('');    
        onCancel();
    }

    return (
        <Modal
            onDismiss={cancelModal}
            visible={visible}
            closeAriaLabel="Close modal"
            size="medium"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" disabled={isSaving} className='cancelButton' onClick={cancelModal}>Cancel</Button>
                        <Button variant="primary" disabled={isSaving || StringUtils.isNullOrEmpty(comment)} onClick={() => onSubmit(comment)}>Confirm</Button>
                    </SpaceBetween>
                </Box>
            }
            header={header}
        >
            <SpaceBetween size="m" direction="vertical">
                <div>{content}</div>
                <FormField label={formFieldLabel} description={`(${commentCharCount} of ${MAX_REASON_LENGTH_ALLOWED} characters allowed)`} stretch>
                    <Textarea
                    onChange={({ detail }) => setComment(detail.value)}
                    value={comment}
                    placeholder="Enter comment here"
                    rows={3}
                    />
                </FormField>
                {!StringUtils.isNullOrEmpty(saveError) && <Alert type="error" dismissible={false}>{saveError}</Alert>} 
            </SpaceBetween>   
        </Modal>
    )
}