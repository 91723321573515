import React, { useEffect, useState } from 'react';
import { Box, SpaceBetween, Modal, FormField, Button, Alert, Select, Checkbox } from '@amzn/awsui-components-react/polaris';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import { TPELoadingSpinner } from 'src/components/shared/TPELoadingSpinner';
import StringUtils from 'src/utils/stringUtils';
import { ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';


export default function CloneFromTemplateModal (props: {
        visible: boolean,
        loading: boolean,
        isCloning: boolean,
        cloneError: string,
        onClose: () => void,
        onSubmit: (templateId: string, templateName: string) => any,
    }) {
    const { visible, loading, isCloning, cloneError, onClose: onModalClosed, onSubmit } = props;

    const { calcBuilderState, calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const { calculation, availableTemplates = [], sevenSegmentLOV = new Map<string, string[]>(), tpLOV } = calcBuilderState;

    const [selectedTemplate, setSelectedTemplate] = useState(null as unknown as any);
    const [filterWorkbookFlag, setFilterWorkbookFlag] = useState(false);
    const [templateList, setTemplateList] = useState([] as any[]);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (visible) {
            setSelectedTemplate(null);
            setShowError(false);
        }
    }, [visible])

    useEffect(() => {
        if (isCloning) {
            setShowError(true);
        }
    }, [isCloning])

    useEffect(() => {
        setTemplateList([...availableTemplates.map(x => ({ label: x.templateName, value: x.templateId }))])
    }, [availableTemplates]);

    useEffect(() => {
        setSelectedTemplate(null);
        calcBuilderDispatch(ACTIONS.SET_GET_TEMPLATES_FOR_WORKBOOK.withPayload(filterWorkbookFlag));
    }, [filterWorkbookFlag])

    const validateAndSubmit = () => {
        onSubmit(selectedTemplate.value, selectedTemplate.label);
    }

    return <Modal className="cloneFromTemplateModal" visible={visible}
        header="Clone from Calculation Template"
        onDismiss={onModalClosed}
        footer={
            !loading &&
            <Box float="right">
                    <Button variant="link" className="cancelButton" disabled={isCloning} onClick={onModalClosed}>Cancel</Button>
                    <Button variant="primary" className="saveButton" disabled={selectedTemplate == null || isCloning} onClick={validateAndSubmit}>{isCloning ? 'Cloning...' : 'Apply'}</Button>
            </Box>
        }>
            <TPELoadingSpinner loading={loading} loadingText="">
                <SpaceBetween direction="vertical" size="m">
                        <FormField  className="templateNameField"
                                    label="Select calculation template*"
                                    description="Clone calculation from selected template"
                                    stretch={true}
                        >
                            <Select className="templateDropdown"
                                selectedOption={selectedTemplate}
                                options={templateList}
                                onChange={({ detail }) => {
                                    setSelectedTemplate(detail.selectedOption);
                                }}
                                placeholder="Calculation template"
                                virtualScroll={true}
                                filteringType="auto"
                            />
                        </FormField>
                        <FormField className="workbookFilterField" stretch={true}>
                            <Checkbox
                                checked={filterWorkbookFlag}
                                className="filterWorkbookFlag"
                                disabled={StringUtils.isNullOrEmpty(calculation?.workbookId)}
                                onChange={({ detail }) => setFilterWorkbookFlag(detail.checked)}
                            >
                                Only display templates within the workbook.
                            </Checkbox>                         
                        </FormField>
                        <Alert className="linkModalInfoAlert" type="info">Any existing data sources and/or calculation steps present in this calculation will be deleted on cloning from a template.</Alert>
                        <Alert className="linkModalAlert" visible={showError && !StringUtils.isNullOrEmpty(cloneError)} type="error">{cloneError}</Alert>
                </SpaceBetween>
            </TPELoadingSpinner>
    </Modal>
}