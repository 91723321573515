import CalculationStepsRunItem from 'src/models/calculation-builder/CalculationStepsRunItem';
import CONSTANTS from 'src/utils/constants';
import TPEAction from 'src/models/common/TPEAction';
import { AllocationGroupState, initialState } from './AllocationGroupsState';
import AllocationGroup, { AllocationGroupFormula } from "src/models/tp-allocation/AllocationGroup";
import { SavingStatus } from 'src/models/common/SavingStatus';
import StringUtils from 'src/utils/stringUtils';
import ArrayUtils from 'src/utils/arrayUtils';

/**
 * List here the actions supported by this reducer
 */
 export const ACTIONS = Object.freeze({
    RESET: new TPEAction('RESET'),
    SET_ALLOCATION_GROUPS: new TPEAction('SET_ALLOCATION_GROUPS'),
    SET_GROUP_BEING_EDITED: new TPEAction('SET_GROUP_BEING_EDITED'),
    SET_EDITOR_SUGGESTIONS: new TPEAction('SET_EDITOR_SUGGESTIONS'),
    SET_EDITOR_CARET_POSITION: new TPEAction('SET_EDITOR_CARET_POSITION'),
    APPEND_TO_FORMULA_EDITOR: new TPEAction('APPEND_TO_FORMULA_EDITOR'),
    ADD_OR_UPDATE_ALLOCATION_GROUP: new TPEAction('ADD_OR_UPDATE_ALLOCATION_GROUP'),
    ADD_ALLOCATION_GROUP_FORMULA: new TPEAction('ADD_ALLOCATION_GROUP_FORMULA'),
    ADD_ALLOCATION_GROUP_FORMULA_BEFORE: new TPEAction('ADD_ALLOCATION_GROUP_FORMULA_BEFORE'),
    ADD_ALLOCATION_GROUP_FORMULA_AFTER: new TPEAction('ADD_ALLOCATION_GROUP_FORMULA_AFTER'),
    REMOVE_ALLOCATION_GROUP_FORMULA: new TPEAction('REMOVE_ALLOCATION_GROUP_FORMULA'),
    DUPLICATE_ALLOCATION_GROUP_FORMULA: new TPEAction('DUPLICATE_ALLOCATION_GROUP_FORMULA'),
    SAVE_ALLOCATION_GROUPS: new TPEAction('SAVE_ALLOCATION_GROUPS'),
    TOGGLE_SHOW_VALUES: new TPEAction('TOGGLE_SHOW_VALUES'),
    UPDATE_CURRENT_FORMULA: new TPEAction('UPDATE_CURRENT_FORMULA'),
    EXIT_EDIT_MODE: new TPEAction('EXIT_EDIT_MODE'),
    SET_EXPANDED_STATE: new TPEAction('SET_EXPANDED_STATE'),
    REFRESH_ALLOCATION_GROUP_FORMULA: new TPEAction('REFRESH_ALLOCATION_GROUP_FORMULA'),
    SET_RUN_VALUES: new TPEAction('SET_RUN_VALUES'),
    SET_TOTALS_ARE_SAVED_AND_VALID: new TPEAction('SET_TOTALS_ARE_SAVED_AND_VALID'),
    SET_REMOVE_GROUP_MODAL_PAYLOAD: new TPEAction('SET_REMOVE_GROUP_MODAL_PAYLOAD'),    
    SET_REMOVE_GROUP_MODAL_VISIBLE: new TPEAction('SET_REMOVE_GROUP_MODAL_VISIBLE'),    
    SET_FORMULA_BEING_EDITED: new TPEAction('SET_FORMULA_BEING_EDITED'),
    SET_REMOVE_FORMULA_PAYLOAD: new TPEAction('SET_REMOVE_FORMULA_PAYLOAD'),
    SET_GROUP_ID_TO_REFRESH_FORMULAS: new TPEAction('SET_GROUP_ID_TO_REFRESH_FORMULAS'),
    SET_GROUP_LEVEL_ERROR: new TPEAction('SET_GROUP_LEVEL_ERROR'),
    SET_UPDATE_FORMULA_PAYLOAD: new TPEAction('SET_UPDATE_FORMULA_PAYLOAD'),
    CLEAR_ALL_GROUP_LEVEL_ERRORS: new TPEAction('CLEAR_ALL_GROUP_LEVEL_ERRORS'),
});

const VALIDATION_MESSAGES = Object.freeze({
    DESCRIPTION_REQUIRED: "Formula name is required!",
    OVERVIEW_REQUIRED: "Formula overview is required!",
});

/**  
* This function is responsible for updating the state based on action type
* @param state The current dashboard state
* @param action The current dispatched action 
*/
export function allocationGroupsReducer(state: AllocationGroupState, action: TPEAction) : AllocationGroupState {
    switch (action.type) {
        case ACTIONS.RESET.type:
            return {...initialState};
        case ACTIONS.SET_ALLOCATION_GROUPS.type: {
            const groups = action.payload;
            return {
                ...state,
                error: '',
                isLoading: false,
                groups: groups,
            };
        }
        case ACTIONS.SET_GROUP_BEING_EDITED.type: {
            const { groups } = state;

            groups.forEach(x => x.isBeingEdited = false);
            if (action.payload == null){
                return {
                    ...state,
                    groupBeingEdited: undefined,
                    groups: [...groups],
                };
            }
            
            const editingRecord = groups.find(x => x.allocationGroupId === action.payload.allocationGroupId) || action.payload;
            if (editingRecord){
                editingRecord.isBeingEdited = true;                
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                groupBeingEdited: {...editingRecord},
                groups: [...groups],
            };
        }
        case ACTIONS.EXIT_EDIT_MODE.type: {
            const { groups } = state;
            groups.forEach(g => {
                g.isBeingEdited = false;
                g.formulas.forEach(f => f.isBeingEdited = false);
                g.formulas = [...g.formulas];
            });
            return {
                ...state,
                error: '',
                isLoading: false,
                groupBeingEdited: undefined,
                formulaBeingEdited: undefined,
                groups: [...groups],
            };
        }
        case ACTIONS.ADD_OR_UPDATE_ALLOCATION_GROUP.type: {
            const { groups, groupBeingEdited } = state;
            if (groupBeingEdited?.isNew){
                groups.push(action.payload)
            }
            else {
                groups[groups.findIndex(x => x.allocationGroupId === action.payload.allocationGroupId)] = action.payload;
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                groupsAreSavedAndValid: false,
                groups: [...groups],
            }
        }
        case ACTIONS.SET_FORMULA_BEING_EDITED.type: {
            const { groups } = state;
            const { group, formula } = action.payload;
            
            if (formula == null){
                group.formulas = [...group.formulas];
                return {
                    ...state,
                    groupBeingEdited: undefined,
                    groups: [...groups],
                };
            }
            if (formula.allocationFormulaId === state.formulaBeingEdited?.allocationFormulaId && state.formulaBeingEdited?.isBeingEdited){
                // Nothing changed
                return {...state};
            }
            group.formulas.forEach((x: any) => x.isBeingEdited = false);
            
            const editingRecordIndex = group.formulas.findIndex((x:any) => x.allocationFormulaId === formula.allocationFormulaId);
            
            if (editingRecordIndex >= 0){
                group.formulas[editingRecordIndex].isBeingEdited = true;                
            }
            group.formulas = [...group.formulas];
            return {
                ...state,
                error: '',
                isLoading: false,
                formulaBeingEdited: {...group.formulas[editingRecordIndex]},
                groups: [...groups],
            };
        }
        case ACTIONS.UPDATE_CURRENT_FORMULA.type: {
            const { groups } = state;
            const { field, value, extraValue } = action.payload;
            if (state.formulaBeingEdited == null) {
                return {
                    ...state,
                    error: `No formula is being edited. Formula's ${action.payload.field} could not be updated.`,
                    isLoading: false,
                    updateFormulaPayload: null
                };
            }
            const formula = (state.formulaBeingEdited as any);
            if (formula){
                const oldValue = formula[field];
                const newValue = value;
                
                if (oldValue !== newValue){
                    formula.savingStatus = SavingStatus.Unsaved;
                }

                formula[field] = newValue;
                if (field === CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.OVERVIEW) {
                    formula[CONSTANTS.WORKSHEET_ALLOCATION_FORMULA_FIELDS.EXPRESSION_TOKENS] = extraValue;
                }
                formula.validationErrors = [];
                if (StringUtils.isNullOrEmpty(formula.description)){
                    formula.validationErrors.push({message:VALIDATION_MESSAGES.DESCRIPTION_REQUIRED, worksheetEntityId: formula.allocationFormulaId})
                }
                if (StringUtils.isNullOrEmpty(formula.expression)){
                    formula.validationErrors.push({message:VALIDATION_MESSAGES.OVERVIEW_REQUIRED, worksheetEntityId: formula.allocationFormulaId})
                }
                const group = groups.find(g => g.allocationGroupId === formula.allocationGroupId);
                if ( group != null ){
                    const formulaIndex = (group.formulas || []).findIndex(f => f.allocationFormulaId === formula.allocationFormulaId);
                    if (formulaIndex >= 0 ) {
                        group.formulas[formulaIndex] = formula;
                    }
                }
                if ( formula.validationErrors.length > 0 ) {
                    return {
                        ...state,
                        error: '',
                        isLoading: false,
                        groups: [...state.groups],
                        groupsAreSavedAndValid: false,
                        updateFormulaPayload: null
                    };
                }

                return {
                    ...state,
                    error: '',
                    isLoading: false,
                    groups: [...state.groups],
                    groupsAreSavedAndValid: false,
                    updateFormulaPayload: formula.savingStatus === SavingStatus.Unsaved? {...formula} : null,
                }
            }
            return {
                ...state,
                updateFormulaPayload: null         
            }
        }
        case ACTIONS.REFRESH_ALLOCATION_GROUP_FORMULA.type: {
            const { groups, formulaBeingEdited } = state;
            const group = groups.find(x => x.allocationGroupId === action.payload.allocationGroupId);
            if (group == null){
                return {
                    ...state,
                    error: 'Could not refresh formula because group was not found',
                    groupsAreSavedAndValid: false,
                }
            }
            
            const formulaIndex = group.formulas.findIndex((x:any) => x.allocationFormulaId === action.payload.allocationFormulaId || x.allocationFormulaId === action.payload.unsavedId);
            if (formulaIndex >= 0){
                const formulaToRefresh = group.formulas[formulaIndex];
                formulaToRefresh.allocationGroupId = action.payload.allocationGroupId;
                formulaToRefresh.allocationFormulaId = action.payload.allocationFormulaId;
                formulaToRefresh.valid = action.payload.valid;
                formulaToRefresh.expression = action.payload.expression;
                formulaToRefresh.isNew = action.payload.isNew;
                formulaToRefresh.validationErrors = action.payload.validationErrors;
                formulaToRefresh.savingStatus = SavingStatus.Saved;
                group.formulas = [...group.formulas];
            }
            else {
                console.warn("********* Could not refresh formula because formula was not found");
            }

            return {
                ...state,
                error: '',
                isLoading: false,
                groups: [...groups],
            }
        }
        case ACTIONS.ADD_ALLOCATION_GROUP_FORMULA.type: {
            const { groups } = state;
            const { group, newFormula } = action.payload;
            const formulasList = (group.formulas || []) as AllocationGroupFormula[];
            formulasList.forEach(x => x.isBeingEdited = false);
            formulasList.push(newFormula);
            group.formulas = [...formulasList];
            return {
                ...state,
                groupsAreSavedAndValid: false,
                error: '',
                isLoading: false,
                groups: [...groups],
                formulaBeingEdited: newFormula
            }
        }
        case ACTIONS.ADD_ALLOCATION_GROUP_FORMULA_BEFORE.type: {
            const { groups } = state;
            const { index, newFormula, group } = action.payload;
            const formulasList = group?.formulas || [];
            newFormula.id = `${formulasList.length + 1}`;
            
            // Inserting new item
            formulasList.splice(index === 0? 0 : index, 0, newFormula);
            for (let i = index + 1; i < formulasList.length; i++) {
                const formula = formulasList[i];
                formula.sequence++;
                formula.savingStatus = SavingStatus.Unsaved;
            }
            if (group != null){
                group.formulas = [...formulasList];
            }
            return {
                ...state,
                groupsAreSavedAndValid: false,
                error: '',
                isLoading: false,
                groups: [...groups],
            }
        }
        case ACTIONS.ADD_ALLOCATION_GROUP_FORMULA_AFTER.type: {
            const { groups } = state;
            let { index, newFormula, group } = action.payload;
            const formulasList = group?.formulas || [];
            newFormula.id = `${formulasList.length + 1}`;
            
            // Inserting new item
            formulasList.splice(index >= formulasList.length - 1? formulasList.length : index + 1, 0, newFormula);
            for (let i = index + 1; i < formulasList.length; i++) {
                const formula = formulasList[i];
                formula.sequence++;
                formula.savingStatus = SavingStatus.Unsaved;
            }
            if (group != null){
                group.formulas = formulasList;
            }
            return {
                ...state,
                groupsAreSavedAndValid: false,
                error: '',
                isLoading: false,
                groups: [...groups],
            }
        }
        case ACTIONS.DUPLICATE_ALLOCATION_GROUP_FORMULA.type: {
            const { groups } = state;
            const { index, originalFormula, group } = action.payload as {index:number, originalFormula: AllocationGroupFormula, group: AllocationGroup};
            const nameCopiesCount = group.formulas.filter(x => x.description.endsWith(originalFormula.description)).length;
            const copyPrefix = `DUPLICATE${nameCopiesCount === 1? '' : '('+nameCopiesCount+')'} - `;
            const copyName = `${copyPrefix}${originalFormula.description}`;
            const formulaCopy = {...originalFormula, description: copyName, allocationFormulaId: `${groups.length + 1}`, savingStatus: SavingStatus.Unsaved, isNew: true} as AllocationGroupFormula;
            const formulasList = group?.formulas || [];
            
            // Inserting copy
            formulasList.push(formulaCopy);
            
            if (group != null){
                group.formulas = [...formulasList];
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                groupsAreSavedAndValid: false,
                groups: [...groups],
                updateFormulaPayload: formulaCopy
            }
        }
        case ACTIONS.SET_UPDATE_FORMULA_PAYLOAD.type: {
            return {
                ...state,
                error: '',
                isLoading: false,
                groupsAreSavedAndValid: false,
                updateFormulaPayload: action.payload
            }
        }
        case ACTIONS.REMOVE_ALLOCATION_GROUP_FORMULA.type: {
            const { groups, groupBeingEdited } = state;
            const { index, formula } = action.payload;
            if (formula.isNew) {
                let formulasList = groupBeingEdited?.formulas || [];
                for (let i = index; i < formulasList.length; i++) {
                    const s = formulasList[i];
                    s.sequence--;
                    s.savingStatus = SavingStatus.Unsaved;
                }
                formulasList = formulasList.filter(x => x.allocationFormulaId !== formula.allocationFormulaId);
                if (groupBeingEdited != null){
                    groupBeingEdited.formulas = formulasList;
                }
                return {
                    ...state,
                    error: '',
                    isLoading: false,
                    //removeStepModalPayload: null,
                    groupsAreSavedAndValid: false,
                    groups: [...groups],
                }
            }
            return {
                ...state,
                error: '',
                isLoading: false,
                groupsAreSavedAndValid: false,
                //deleteStepPayload: {calculationNumber:step.calculationNumber, stepId: step.stepId},
            }
        }
        case ACTIONS.TOGGLE_SHOW_VALUES.type: {
            return {
                ...state,
                error: '',
                isLoading: false,
                showValues: !state.showValues,
            }
        }
        case ACTIONS.SET_EXPANDED_STATE.type: {
            return {
                ...state,
                error: '',
                isLoading: false,
                expandedState: action.payload,
            }
        }
        case ACTIONS.SET_EDITOR_CARET_POSITION.type: {
            const { formulaBeingEdited } = state;
            if (formulaBeingEdited == null) {
                return {
                    ...state,
                    error: `No allocation group formula is being edited. The value '${action.payload}' could not be set as caret position.`,
                    isLoading: false,
                };
            }
            formulaBeingEdited.formulaEditorCaretPosition = action.payload;
            return {
                ...state,
                error: '',
                isLoading: false,                
            }
        }
        case ACTIONS.APPEND_TO_FORMULA_EDITOR.type: {
            const { formulaBeingEdited, groupBeingEdited, groups } = state;
            if (formulaBeingEdited == null) {
                return {
                    ...state,
                    error: `No allocation group formula is being edited. The value '${action.payload}' could not be added to the formula editor.`,
                    isLoading: false,
                };
            }
            const caretPosition = formulaBeingEdited.formulaEditorCaretPosition || formulaBeingEdited.expression.length - 1;
            const paddingBeforePosition = formulaBeingEdited.expression.charAt(caretPosition - 1) === ' '? '' : ' ';
            const paddingAfterPosition = formulaBeingEdited.expression.charAt(caretPosition) === ' '? '' : ' ';
            const str = `${paddingBeforePosition}${action.payload}${paddingAfterPosition}`;
            formulaBeingEdited.expression = [formulaBeingEdited.expression.slice(0, caretPosition), str, formulaBeingEdited.expression.slice(caretPosition)].join('');
            const formulasList = groupBeingEdited?.formulas || [];
            formulasList[formulasList.findIndex(x => x.allocationFormulaId === formulaBeingEdited.allocationGroupId)] = formulaBeingEdited;
            return {
                ...state,
                error: '',
                isLoading: false,
                formulaBeingEdited: {...formulaBeingEdited}
            }
        }
        case ACTIONS.SET_TOTALS_ARE_SAVED_AND_VALID.type:
            return {
                ...state,
                error: '',
                groupsAreSavedAndValid: action.payload,
            }
        case ACTIONS.SET_REMOVE_GROUP_MODAL_PAYLOAD.type:
            return {
                ...state,
                error: '',
                removeGroupModalVisible: action.payload != null,
                removeGroupModalPayload: action.payload,
            }
        case ACTIONS.SET_REMOVE_GROUP_MODAL_VISIBLE.type:
            return {
                ...state,
                error: '',
                removeGroupModalVisible: action.payload,
            }
        case ACTIONS.SET_REMOVE_FORMULA_PAYLOAD.type:
            if (action.payload){
                const {formula} = action.payload;
                if (formula.isNew){
                    const group = state.groups.find(x => x.allocationGroupId === formula.allocationGroupId) || {formulas:[]};
                    group.formulas = group?.formulas.filter(x => x.allocationFormulaId !== formula.allocationFormulaId) || [];
                    return {
                        ...state,
                        error: '',
                        groups: [...state.groups],
                    }
                }
            }
            return {
                ...state,
                error: '',
                removeFormulaModalPayload: action.payload,
            }
        case ACTIONS.SET_GROUP_ID_TO_REFRESH_FORMULAS.type:
            return {
                ...state,
                error: '',
                groupIdToRefreshFormulas: action.payload,
            }
        case ACTIONS.CLEAR_ALL_GROUP_LEVEL_ERRORS.type: {
            const { groups } = state;
            groups.forEach(g => g.errorMessage = undefined);
            return {
                ...state,
                error: '',
                groups: [...groups],
            }
        }
        case ACTIONS.SET_GROUP_LEVEL_ERROR.type:
            const {groupId, message} = action.payload;
            const { groups } = state;
            const group = groups.find(x => x.allocationGroupId === groupId);
            if ( group != null ){
                group.errorMessage = message;
            }
            return {
                ...state,
                error: '',
                groups: [...groups],
            }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}




