import apiService from "src/services/ApiCallService";
import CalculationUploadResponse from "src/models/calculation-builder/CalculationUploadResponse";
import {useEffect, useState} from "react";
import StringUtils from "src/utils/stringUtils";
import ErrorUtils from "src/utils/ErrorUtils";
import CONSTANTS from "src/utils/constants";
export default class CalculationUploadService {

        downloadTemplate(query: string): [string, boolean, string] {
            const [result, setResult] = useState(null as unknown as string);
            const [loading, setLoading] = useState(false);
            const [error, setError] = useState('');

            useEffect(() => {
                async function downloadTemplate() {
                    try {
                        setLoading(true);
                        const response = await apiService.downloadFile(CONSTANTS.ENVIRONMENT_VARIABLES.TEMPLATES_BUCKET, CONSTANTS.ENVIRONMENT_VARIABLES.CALCULATION_UPLOAD_TEMPLATE);
                        const json = await response.json();
                        setResult(json.downloadUrl);
                    } catch (ex) {
                        setError(ErrorUtils.getMessage(ex));
                    } finally {
                        setLoading(false);
                    }
                }
                if (!StringUtils.isNullOrEmpty(query)) {
                    downloadTemplate();
                }
            }, [query]);
            return [result, loading, error]
        }

        uploadFile(key: string | undefined, file: File, calculationNumber: string | undefined): [string, boolean, string] {
                const [result, setResult] = useState(null as unknown as string);
                const [loading, setLoading] = useState(false);
                const [error, setError] = useState('');
                useEffect(() => {
                    async function uploadData(key: string, calculationNumber: string) {
                        try {
                            setLoading(true);
                            await apiService.uploadToS3(CONSTANTS.ENVIRONMENT_VARIABLES.CALCULATION_UPLOAD_BUCKET, key || '', file);
                            const response = await apiService.uploadCalculation(key, calculationNumber);
                            const json = await response.json() as CalculationUploadResponse;
                            setResult(json.key as string); 
                        } catch (ex) {
                            setError(ErrorUtils.getMessage(ex));
                        } finally {
                            setLoading(false);
                        }
                    }
                    if (!StringUtils.isNullOrEmpty(key) && !StringUtils.isNullOrEmpty(calculationNumber)) {
                        uploadData(key || '', calculationNumber || '');
                    }
                }, [key]);
                return [result, loading, error];
        }
    }
