import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';

export default function ATPHorizontalRadioGroup(props: {items: {label: string, value: string}[], value: string, onChange: (v: string) => any}) {
    const { items, value, onChange } = props;

    return <div className="atpRadioGroupContainer">
                {items.map(item => <div className="atpRadioGroupItem">
                        <input 
                            className="atpRadio"
                            id={item.value}
                            type="radio"
                            value={item.label}
                            checked={item.value == value}
                            onChange={() => onChange(item.value)}
                        />
                        <label className="atpRadioLabel">{item.label}</label>
                    </div>
                )}
            </div>

}