import {TaxAuditRecordDetailsResponse} from "src/models/reports/TaxAuditRecordDetails";
import {Box, Container, Header} from "@amzn/awsui-components-react";
import {TPELoadingSpinner} from "src/components/shared/TPELoadingSpinner";
import React from "react";

function ValueWithLabel(props: any) {
    const { label } = props;
    return (
        <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                {label}
            </Box>
            <div>{props.children}</div>
        </div>
    );
}

export default function TaxAuditRecordAgreementDetails(props: { taxAuditRecordDetails: TaxAuditRecordDetailsResponse, loading: boolean}) {
    const {taxAuditRecordDetails, loading} = props;

    return <Container
        header={
            <Header variant="h2">Agreement Details</Header>
        }>
        <div className="polaris-content-container-tar-report-details">
            <TPELoadingSpinner loading={loading}>
                {taxAuditRecordDetails != null &&
                    <React.Fragment>
                        <div className="grid-item"><ValueWithLabel label="Agreement Name">{taxAuditRecordDetails.agreementName}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Agreement Number">{taxAuditRecordDetails.agreementNumber}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Agreement Line Number">{taxAuditRecordDetails.agreementLineNum}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Agreement Status">{taxAuditRecordDetails.agreementStatus}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Provider Jurisdiction ">{taxAuditRecordDetails.providerJurisdiction}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Recipient Jurisdiction ">{taxAuditRecordDetails.recipientJurisdiction}</ValueWithLabel></div>
                    </React.Fragment>
                }
            </TPELoadingSpinner>
        </div>
    </Container>
}