import { CalcBuilderComment } from "src/models/calculation-builder/CalcBuilderComment";
import { DataSourceRecord } from "src/models/common/DataSourceRecord";
import { PullBalancePayload } from "src/models/calculation-builder/PullBalanceRequest";
import TPESearchRequest, { DbConjunctor, DbOperator } from "src/models/common/TPESearchRequest";
import CONSTANTS from "src/utils/constants";

export interface DataSourcesState {
    dataSourceRecords: Array<DataSourceRecord>,
    loading: boolean,
    showDecimals: boolean,
    pullGLBalancesWithBreakdowns: boolean,
    recordIdCounter: number,
    recordBeingEdited?: DataSourceRecord,
    recordBeingEditedOriginal?: DataSourceRecord,
    coaTypes: string[],
    recordIdForAction: string,
    showEditActionConfirmModal: boolean,
    showDuplicateActionConfirmModal: boolean,
    showDeleteActionConfirmModal: boolean,
    showStep3ConfirmModal: boolean,
    showUpdateAllBalancesConfirmModal: boolean,
    showCDTSelector: boolean,
    error?: string,
    savedRecordId?: string,
    deleteDataSourcePayload?: any,
    commentsHistory: Array<CalcBuilderComment>,
    unsavedComments: Array<CalcBuilderComment>,
    newCommentPayload?: CalcBuilderComment,
    commentsNeedRefresh: boolean,
    pullBalanceRequestPayloads: PullBalancePayload[],
    pullBalanceRequestCounter: number,
    updateAllBalances: boolean,
    selectedCustomCoaValues?: Map<string, string>,
    customCoaReference?: Map<string, string>,
    showStandardAllocationModal: boolean,
    showStandardAllocationDeleteConfirmModal: boolean,
    recordForActionNeedsSaving?: string,
    balancePullingStateChangeTimestamp?: string
    balancePullUpdatedRecord?: DataSourceRecord,
    recordsCountEligibleForPullingBalance: number
}

export const initialState : DataSourcesState = {
    dataSourceRecords: [] as DataSourceRecord[],
    loading: true,
    showDecimals: false,
    pullGLBalancesWithBreakdowns: false,
    recordIdCounter: 0,
    coaTypes: [] as string[],
    recordIdForAction: "-1",
    showEditActionConfirmModal: false,
    showDuplicateActionConfirmModal: false,
    showDeleteActionConfirmModal: false,
    showStep3ConfirmModal: false,
    showUpdateAllBalancesConfirmModal: false,
    showCDTSelector: false,
    commentsHistory: [],
    unsavedComments: [],
    commentsNeedRefresh: false,
    pullBalanceRequestPayloads: [] as PullBalancePayload[],
    pullBalanceRequestCounter: 0,
    updateAllBalances: false,
    showStandardAllocationModal: false,
    showStandardAllocationDeleteConfirmModal: false,
    recordsCountEligibleForPullingBalance: 0
}

export const defaultSearchPayload: TPESearchRequest = {
    page: 1,
    pageSize: 20,
    filterExpression: {
        conjunctor: DbConjunctor.AND,
        expressions: [
            {
                attributeName: CONSTANTS.STATUS_ATTRIBUTE,
                operator: DbOperator.EQUALS,
                values: [CONSTANTS.ACTIVE_STATUS]
            }
        ]
    }
}