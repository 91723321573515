import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import Chart from 'chart.js';


// Holds a dataset with the common settings set
export const defaultDataSet = {
    data: [],
    showLine: false,
    fill: true,
    borderWidth: 1,
    backgroundColor: [],
    borderColor: [],
    datalabels: {
        anchor: 'end',
        align: 'top',
        font: { weight: 'bold' },
    }
}

// Holds a barchart settings with the common settings set
export const defaultBarchartSettings = {
    type: "bar",
    data: {
        labels: [],
        datasets: []
    },
    options: {
        tooltips: { enabled: false },
        layout: {
            padding: { top: 20 }
        },
        legend: { display: false },
        scales: {
            xAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        fontStyle: 'bold',
                    }
                }],
        }
    },
    plugins: [
        ChartDataLabels,
        {
            beforeInit: function (chart: Chart) {
                if (chart == null || chart.data == null || chart.data.labels == null) {
                    return;
                }
                chart.data.labels.forEach(function (e, i, a) {
                    if (/\n/.test(e.toString())) {
                        a[i] = e.toString().split(/\n/);
                    }
                });
            },
        }
    ]
}

/**
 * Creates a `Chart.ChartConfiguration` object using the default settings and 
 * overriding them with provided parameters
 * @param dataItems The items for the barchar
 * @param labels The label for each dataItem
 * @param backgoundColors The color for each dataItem
 * @param borderColors The border color for each dataItem
 * @param dataLabelsFormatter A function callback to format each bar label
 */
export function getBarchartSettings(
    dataItems: any[],
    labels: any[],
    backgoundColors: any[],
    borderColors: any[],
    dataLabelsFormatter: ((value: any, context: Context) => any | null)
): Chart.ChartConfiguration {

    let dataSet: Chart.ChartDataSets = {
        ...defaultDataSet,
        data: dataItems,
        backgroundColor: backgoundColors,
        borderColor: borderColors,
        datalabels: {
            anchor: 'end',
            align: 'top',
            font: { weight: 'bold' },
            formatter: dataLabelsFormatter
        }
    };
    return {
        ...defaultBarchartSettings,
        data: {
            labels: labels,
            datasets: [dataSet]
        }
    }
}

/**
 * Updates the provided chart with the provided dataitems
 * @param chart The chart object
 * @param dataItems The new dataItems
 */
export function updateBarchart(chart: Chart, dataItems: any[]) {
    if (chart.data.datasets && chart.data.datasets.length > 0) {
        chart.data.datasets[0].data = dataItems;
        chart.update();
    }
}

/// Utility functions ////
export function getRandomIntInclusive(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    //The maximum is inclusive and the minimum is inclusive
    return Math.floor(Math.random() * (max - min + 1) + min);
}

export function thousandFormatter(value: number) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}