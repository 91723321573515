import { PullBalanceWSMessage } from "src/models/calculation-builder/PullBalanceWebSocketMessage";
import { WorksheetMode } from "./TPAllocationEnums";

export interface DatasetRecord {
    datasourceId: string,
    unsavedDatasourceId: string,
    worksheetId: string,
    worksheetVersion: number,
    templateId: string,
    templateVersion: number,
    datasource: string,
    period: string,
    customPeriod: string,
    fxDate: string,
    fxType: string,
    fxRate: string,
    currency: string,
    dataKey: string,
    description: string,
    value: string,
    dataKeyInput?: {
        selectedCompanies: string,
        selectedAccounts: string,
        selectedCOA: Map<string, string>,
    },
    isNewRecord: boolean,
    errors: Map<string, string> | undefined,
    isEditing: boolean,
    showDataKeySelection: boolean,
    requestBalancePull: boolean,
    lastBalancePullRequestID: string,
    lastBalancePullDate: number,
    unsavedBalancePullRequestID: string,
    abandonedBalancePullRequests: string[],
    balancePullInProgress: boolean,
    pullBalanceError?: string,
    saveError?: string,
    glBalances: GLBalance[],
    glBalanceDrillDownKeys: Map<string, string>,
    glBalanceDrillDown?: Map<string, COADrillDown>,
    originalBalanceData?: {
        value: string,
        fxRate: string,
        currency: string,
        glBalances: GLBalance[],
        glBalanceDrillDownKeys: Map<string, string>,
        lastBalancePullRequestID: string,
        lastBalancePullDate: number,
    }
    expandedCategories: string[],
    expandedAccount: string,
    drillDownCOASelected: string,
    showSavedIndicator: boolean,
    balancePullingTask?: PullBalanceWSMessage,
    requestAutoSave: boolean,
    worksheetMode: WorksheetMode,
}

export interface GLBalance {
    company: string,
    account: string,
    balance: number
}

export interface COADrillDown {
    coa: string,
    coaCategoryDrillDowns: COACategoryDrillDown[],
}

export interface COACategoryDrillDown {
    coaCategory: string,
    description: string,
    balance: number,
    subCategories?: COACategoryDrillDown[]
}

export const getNewDatasetRecord = (recordIdCounter: number) => {
    return {
        datasourceId: String(recordIdCounter),
        isEditing: true,
        balancePullInProgress: false,
        dataKeyInput: {
            selectedCompanies: '',
            selectedAccounts: '',
            selectedCOA: new Map<string, string>(),
        },
        isNewRecord: true,
    } as unknown as DatasetRecord
}

export const emptyGLBalance: GLBalance = {
    company: '',
    account: '',
    balance: 0,
}
