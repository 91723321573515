import TPEAction from "src/models/common/TPEAction";
import {PreValidationReportState} from "src/services/reports/prevalidation/PreValidationReportState";

export const ACTIONS = Object.freeze({
    SET_SELECTED_TAB: new TPEAction('SET_SELECTED_TAB'),
    SET_SELECTED_PAGE: new TPEAction('SET_SELECTED_PAGE'),
    SET_LOADED_PAGE: new TPEAction('SET_LOADED_PAGE'),
    SET_IS_VISIBLE: new TPEAction('SET_IS_VISIBLE'),
    SET_DISABLE_FORCE_GENERATE_BUTTON: new TPEAction('SET_DISABLE_FORCE_GENERATE_BUTTON'),
    SET_CACHE: new TPEAction('SET_CACHE'),
    SET_INDIRECT_TAX_CHANGE_RECORD_LIST: new TPEAction('SET_INDIRECT_TAX_CHANGE_RECORD_LIST'),
    SET_LAST_REPORT_STATUS_MODAL: new TPEAction('SET_LAST_REPORT_STATUS_MODAL'),
    SET_PRE_VAL_INDIRECT_TAX_MODAL: new TPEAction('SET_PRE_VAL_INDIRECT_TAX_MODAL'),
});

export function preValidationReportReducer(state: PreValidationReportState, action: TPEAction) : PreValidationReportState {
    switch(action.type) {
        case ACTIONS.SET_SELECTED_TAB.type:
            return {
                ...state,
                selectedTab: action.payload,
            };
        case ACTIONS.SET_SELECTED_PAGE.type:
            return {
                ...state,
                selectedPage: action.payload,
            };
        case ACTIONS.SET_LOADED_PAGE.type:
            return {
                ...state,
                loadedPage: action.payload,
            };
        case ACTIONS.SET_IS_VISIBLE.type:
            return {
                ...state,
                isVisible: action.payload,
            };
        case ACTIONS.SET_DISABLE_FORCE_GENERATE_BUTTON.type:
            return {
                ...state,
                disableForceGenerateButton: action.payload,
            };
        case ACTIONS.SET_CACHE.type:
            return {
                ...state,
                cache: action.payload,
            };
        case ACTIONS.SET_INDIRECT_TAX_CHANGE_RECORD_LIST.type:
            return {
                ...state,
                indirectTaxChangeRecordList: action.payload,
            };
        case ACTIONS.SET_LAST_REPORT_STATUS_MODAL.type:
            return {
                ...state,
                lastReportStatusModal: action.payload,
            };
        case ACTIONS.SET_PRE_VAL_INDIRECT_TAX_MODAL.type:
            return {
                ...state,
                preValIndirectTaxModal: action.payload,
            };
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}