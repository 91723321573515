import React from 'react';
import { Autosuggest } from '@amzn/awsui-components-react';

export default function StepsTPEntriesSelector(props:{value: any, options: any, onValueChanged: any, onBlur: any}) {
    const {value, options, onValueChanged, onBlur} = props;
    return <Autosuggest
                    data-class="coaAutosuggest"
                    onChange={onValueChanged}
                    onBlur={onBlur}
                    value={value}
                    options={options}
                    enteredTextLabel={value => `Custom name: "${value}"`}
                    ariaLabel="Step name editor with suggestions"
                    filteringType="none"
                    placeholder="Enter step name or choose TP entry type"
                    empty="No matches found"
                />
}