import React, { useEffect, useState } from 'react';
import { ExpandableSection, Header, SpaceBetween, ButtonDropdown, Link, Box, ColumnLayout } from '@amzn/awsui-components-react/polaris';
import { CLI } from "src/models/common/CLI";
import { TPELoadingSpinner } from "src/components/shared/TPELoadingSpinner";
import { AgreementVersionItem } from "src/models/agreements/AgreementVersionsResult";
import CONSTANTS from 'src/utils/constants';
import DateUtils from 'src/utils/dateUtils';

function ValueWithLabel(props: any) {
    const { label } = props;
    return (
        <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                {label}
            </Box>
            <div>{props.children}</div>
        </div>
    );
}

function showAgreementInApttus(event: any) {
    event.stopPropagation();
}

function getApttusURL(calculation: CLI) {
    return CONSTANTS.ENVIRONMENT_VARIABLES.APTTUS_AGREEMENT_VIEW_URL?.replace('(recordId)', calculation.agreementRecordId)
}


function selectVersion(event: any, setSelectedAgreement: any) {
    event.stopPropagation();
    setSelectedAgreement(event.detail['id']);
    console.log(event.detail);
}

export default function TPAgreementCLIDetails(props: { cli: CLI, versions: AgreementVersionItem[], expanded?: boolean, loading: boolean }) {
    const [selectedAgreement, setSelectedAgreement] = useState('');
    const { cli, versions, expanded = false, loading } = props;
    const agreementVersions = new Array<any>();
    versions.forEach(version => { agreementVersions.push({ text: version.agreementNumber, ids: version.recordId }) });

    useEffect(() => {
        setSelectedAgreement('Version 3');
    }, []);

    return (
        <ExpandableSection className="polarisExpandableSection agreementDetailsSection" variant="container"
            defaultExpanded={expanded}
            header={
                <Header
                    actions={
                        <SpaceBetween direction="horizontal" size="l" className="containerHeaderActions">
                            {/* To prevent link showing with no agreementRecordId parameter in the URL */}
                            {cli.agreementRecordId != null && cli.agreementRecordId.length > 0 && <Link className="viewInApttusLink" onFollow={(event: any) => showAgreementInApttus(event)} external href={getApttusURL(cli)}>View in APTTUS</Link> }
                            <span onClick={(event: any) => event.stopPropagation()}>
                                <ButtonDropdown className="polarisSmallButtonDropdown" onItemClick={(event: any) => selectVersion(event, setSelectedAgreement)}
                                    items={agreementVersions}>
                                    Version {cli.agreementNumber}
                                </ButtonDropdown>
                            </span>
                        </SpaceBetween>
                    }
                >
                    <h2>Step 1: TP Agreement CLI details - {cli.agreementName} ({cli.calculationNumber})</h2>
                </Header>
            }
        >
            <div className="calculationContentContainer">
                <TPELoadingSpinner loading={loading}>
                    <React.Fragment>
                        <div className="grid-item"><ValueWithLabel label="Effective date">{DateUtils.formatDate(cli.effectiveDate, 'ddd MMM DD yyyy')}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Agreement Line Number">{cli.agreementLineNumber}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Calculation Start Date">{DateUtils.formatDate(cli.calculationStartDate, 'ddd MMM DD yyyy')}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Calculation Line Number">{cli.calculationNumber}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Termination date">{DateUtils.formatDate(cli.terminationDate, 'ddd MMM DD yyyy')}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Agreement Status Category">{cli.agreementStatusCategory}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Calculation Termination Date">{DateUtils.formatDate(cli.calculationTerminationDate, 'ddd MMM DD yyyy')}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="APTTUS Calculation Status">{cli.apttusCalculationStatus}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="TP Tax Owner">{cli.taxOwner}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Agreement Status">{cli.agreementStatus}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Task Type">{cli.taskType}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Inactive Reason">{cli.inactiveReason}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Provider">{cli.providerLegalEntityName}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Recipient">{cli.recipientLegalEntityName}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Provider Co. Code">{cli.providerCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Recipient Co. Code">{cli.recipientCode}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="TP Base">{cli.tpBase}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="TP Rate">{cli.tpRate}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Workbook Reviewer">{cli.accountingOwner}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Calculation File Assignee">{cli.assignee}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Payment Terms">{cli.paymentTerms}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Agreement Currency">{cli.agreementCurrency}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Accounting Output (BEAT)">{cli.beat}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Export of Services">{cli.exportOfServices}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Business Segment">{cli.businessSegment}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Functional Segment Category">{cli.functionalSegmentCategory}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Tax Product Category ID">{cli.taxProductCategoryId}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Tax Product Category Name">{cli.taxProductCategory}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Business Line">{cli.businessLine}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Functional Segment">{cli.functionalSegment}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Tax Product ID">{cli.taxProductId}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Tax Product Name">{cli.taxProduct}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Jurisdiction (1st Party)">{cli.providerJurisdiction}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Jurisdiction (2nd Party)">{cli.recipientJurisdiction}</ValueWithLabel></div>
                        <div className="grid-item-2"><ValueWithLabel label="Justification for the Agreement">{cli.justification}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Workbook ID">{cli.workbookId}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Calculation Currency">{cli.calculationCurrency}</ValueWithLabel></div>
                    </React.Fragment>
                </TPELoadingSpinner>
            </div>
        </ExpandableSection>
    )
}
