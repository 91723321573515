import CONSTANTS from "src/utils/constants";
import { CalcBuilderComment } from "../calculation-builder/CalcBuilderComment";
import { PullBalanceWSMessage } from "../calculation-builder/PullBalanceWebSocketMessage";
import StandardAllocation from "../calculation-builder/standard-allocation/StandardAllocation";

export interface DataSourceRecord {
    standardAllocation?: StandardAllocation;
    datasourceId: string,
    unsavedDatasourceId: string,
    calculationNumber: string,
    datasource: string,
    period: string,
    customPeriod: string,
    fxDate: string,
    fxType: string,
    fxRate: string,
    currency: string,
    dataKey: string,
    description: string,
    value: string,
    dataKeyInput?: DataKeyInput,
    isNewRecord: boolean,
    errors: Map<string, string> | undefined,
    isEditing: boolean,
    showDataKeySelection: boolean,
    requestBalancePull: boolean,
    lastBalancePullRequestID: string,
    lastBalancePullDate: number,
    unsavedBalancePullRequestID: string,
    abandonedBalancePullRequests: string[],
    balancePullInProgress: boolean,
    pullBalanceError?: string,
    saveError?: string,
    glBalances: GLBalance[],
    glBalanceDrillDownKeys: Map<string, string>,
    glBalanceDrillDown?: Map<string, COADrillDown>,
    originalBalanceData?: {
        value: string,
        fxRate: string,
        currency: string,
        glBalances: GLBalance[],
        glBalanceDrillDownKeys: Map<string, string>,
        lastBalancePullRequestID: string,
        lastBalancePullDate: number,
    }
    expandedCategories: string[],
    expandedAccount: string,
    drillDownCOASelected: string,
    showSavedIndicator: boolean,
    hasComments: boolean,
    comments: CalcBuilderComment[] | undefined,
    balancePullingTask?: PullBalanceWSMessage,
    requestAutoSave: boolean,
    customDatasourceTableId: string,
    customDatasourceTableName: string,
    customDatasourceCalculationNumber: string,
    customDatasourceValueId: string,
    customDatasourceValueLegend: string,
    tpAllocationWorksheetId: string,
    tpAllocationWorksheetName: string,
    tpAllocationGroupId: string,
    tpAllocationGroupName: string,
    tpAllocationFormulaId: string,
    tpAllocationFormulaName: string,
    tpAllocationMappingType: string,
    passThroughFlag: boolean,
    lastModifiedUser: string,
    lastModifiedDate: string
}

export interface GLBalance {
    company: string,
    account: string,
    balance: number
}

export interface COADrillDown {
    coa: string,
    coaCategoryDrillDowns: COACategoryDrillDown[],
}

export interface COACategoryDrillDown {
    coaCategory: string,
    description: string,
    balance: number,
    subCategories?: COACategoryDrillDown[]
}

export const getNewDataSourceRecord = (recordIdCounter: number) => {
    return {
        datasourceId: String(recordIdCounter),
        isEditing: true,
        balancePullInProgress: false,
        dataKeyInput: {
            selectedCompanies: '',
            selectedAccounts: '',
            selectedCOA: new Map<string, string>(),
        },
        isNewRecord: true,
    } as unknown as DataSourceRecord
}

export const emptyGLBalance: GLBalance = {
    company: '',
    account: '',
    balance: 0,
}

export interface DataKeyInput {
    selectedCompanies: string,
    selectedAccounts: string,
    selectedCOA: Map<string, string>,
}
