import { useState, useEffect } from "react";
import apiService from '../../../services/ApiCallService';
import ErrorUtils from "src/utils/ErrorUtils";
import Placeholder from "../../../models/tp-allocation/Placeholder";
import { SavingStatus } from "src/models/common/SavingStatus";
import TPAllocationUtils from "../TPAllocationUtils";

export default class PlaceholdersService {
    
    createNewPlaceholder(segment: string, sequence: number, parentEntityId?: string): Placeholder {
        return {
            placeholderId: `new${segment}Placeholder${sequence}`,
            placeholderName: '',
            coaSegment: segment,
            isNew: true,
            isBeingEdited: true,
            sequence: sequence,
            savingStatus: SavingStatus.Unsaved,
            parentEntityId: parentEntityId,
            isRecentlySaved: false
        };
    }

    getWorksheetTemplatePlaceholders(templateId?: string, templateVersion?: number): [Map<string, Placeholder[]>, boolean, string] {
        const [result, setResult] = useState(null as unknown as Map<string,Placeholder[]>);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(templateId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetTemplatePlaceholders(templateId, templateVersion == null? -1 : templateVersion);
                    const json = await response.json();
                    setResult(TPAllocationUtils.convertToPlaceholdersMap(json.placeholders || []));
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (templateId != null) {
                fetchData(templateId);
            }
        }, [templateId]);
        return [result, loading, error]
    }
    
    getLinkedWorksheetPlaceholders(worksheetId?: string, worksheetVersion?: number): [Map<string,Placeholder[]> | null, boolean, string] {
        const [result, setResult] = useState(null as Map<string,Placeholder[]> | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(worksheetId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetPlaceholders(worksheetId, worksheetVersion == null? -1 : worksheetVersion);
                    const json = await response.json();
                    setResult(TPAllocationUtils.convertToPlaceholdersMap(json.placeholders));
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (worksheetId != null) {
                fetchData(worksheetId);
            }
        }, [worksheetId]);
        return [result, loading, error]
    }

    saveTemplatePlaceholder(payload?: Placeholder): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(record: Placeholder) {
                try {
                    setLoading(true);
                    setError('');
                    const payload = {
                        templateId: record.parentEntityId, 
                        placeholderId: record.placeholderId, 
                        placeholderName: record.placeholderName, 
                        coaSegment: record.coaSegment
                    };
                    const response = await (record.isNew?
                        apiService.createTPAllocationWorksheetTemplatePlaceholder(record.parentEntityId || '', payload) :
                        apiService.updateTPAllocationWorksheetTemplatePlaceholder(record.parentEntityId || '', payload.placeholderId, payload));
                    const json = (await response.json()).placeholder;
                    json.unsavedId = record.placeholderId;
                    json.parentEntityId = json.templateId;
                    json.parentEntityVersion = json.templateVersion;
                    json.isRecentlySaved = true;
                    if (json.validationErrors && json.validationErrors.length > 0){
                        json.error = json.validationErrors[0].message;
                        json.isRecentlySaved = false;
                    }
                    setResult(json);
                }
                catch (ex) {
                    // Adding ID to the error so that useEffect listeners get triggered on components
                    // This ID gets removed before displaying to the user
                    setError(`${record.placeholderId}:${ErrorUtils.getMessage(ex)}`);
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error]
    }
    
    deleteTemplatePlaceholder(payload?: Placeholder): [any, boolean, string] {
        const [result, setResult] = useState(null as any);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(record: Placeholder) {
                try {
                    setLoading(true);
                    const response = await apiService.deleteTPAllocationWorksheetTemplatePlaceholder(record.parentEntityId || '', record.placeholderId);
                    const json = (await response.json()).placeholder;
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error]
    }
}

