import React, { useEffect, useState } from 'react';
import { Box, SpaceBetween, Button, Alert, Container, Grid, Link, ColumnLayout, Modal, Popover } from '@amzn/awsui-components-react/polaris';
import { CalculationTemplateMetaData } from 'src/models/calculation-builder/CalculationTemplate';
import LinkedCLILink from './LinkedCLILink';
import { ACTION_TYPE, TPEBasicModal } from 'src/components/shared/TPEBasicModal';
import LinkToTemplateModal from './LinkToTemplateModal';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import { ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import ArrayUtils from 'src/utils/arrayUtils';
import CreateRenameTemplateModal from './CreateRenameTemplateModal';
import EditTemplateConfirmationModal from './EditTemplateConfirmationModal';
import CalculationStep from 'src/models/calculation-builder/CalculationStep';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import ReadOnlyCalculationService from 'src/services/calculations/ReadOnlyCalculationService';
import { ExpressionPartTypes } from 'src/models/calculations/CalculationDetailsResult';
import { TPELoadingSpinner } from 'src/components/shared/TPELoadingSpinner';
import LinkToTemplate from 'src/models/calculation-builder/LinkToTemplate';
import UnlinkFromTemplateModal from './UnlinkFromTemplateModal';
import CloneFromTemplateModal from './CloneFromTemplateModal';
import TPEErrorWatcher from 'src/components/shared/TPEErrorWatcher';
import StringUtils from 'src/utils/stringUtils';
import CONSTANTS from 'src/utils/constants';

interface TemplateErrors {
    header: string,
    message: string,
    errors: string[]
}

export default function TemplatesContainer(props: { loading: boolean }){
    const { loading } = props;

    const { services, calcBuilderState, calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const { calculation,
            dataSourceRecords,
            steps,
            createRenameTemplatePayload,
            linkCalculationPayload,
            unlinkCalculationPayload,
            calculationTemplateMetaData,
            temporaryMessage,
            linkedCLIs,
            isEditingTemplate,
            getTemplatesForWorkbook,
            validateCalculationSteps,
            areCalculationStepsValid,
            viewMode
          } = calcBuilderState;

    const calculationHasTemplate = calculationTemplateMetaData?.templateId != null;
    
    const [showLinkedCLIsModal, setShowLinkedCLIsModal] = useState(false);
    const [showLinkToTemplateModal, setShowLinkToTemplateModal] = useState(false);
    const [showCloneFromTemplateModal, setShowCloneFromTemplateModal] = useState(false);
    const [showUnlinkConfirmModal, setShowUnlinkConfirmModal] = React.useState(false);
    const [showCreateRenameTemplateModal, setShowCreateRenameTemplateModal] = React.useState(false);
    const [showEditTemplateConfirmModal, setShowEditTemplateConfirmModal] = React.useState(false);
    const [showCancelTemplateConfirmModal, setShowCancelTemplateConfirmModal] = React.useState(false);
    const [unusedDataSourcesWarning, setUnusedDataSourcesWarning] = React.useState(null as unknown as string);
    const [templatesNeedRefresh, setTemplatesNeedRefresh] = useState(false);
    const [templateErrors, setTemplateErrors] = useState(null as unknown as TemplateErrors);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null as unknown as string);
    const [selectedTemplateName, setSelectedTemplateName] = useState(null as unknown as any);
    const [loadTime, setLoadTime] = useState(null as unknown as string);
    const [shouldEditTemplate, setShouldEditTemplate] = useState(false);
    const [shouldSaveTemplate, setShouldSaveTemplate] = useState(false);
    const [shouldCancelTemplate, setShouldCancelTemplate] = useState(false);
    const [saveAction, setSaveAction] = useState('');
    const [isProcessingSave, setIsProcessingSave] = useState(false);

    const SAVE_ACTION_CREATE = 'create';
    const SAVE_ACTION_EDIT = 'edit';

    const [unlinkResult, isUnlinking, unlinkError] = services.calculationBuilderService.unlinkCalculationFromTemplate(unlinkCalculationPayload);
    const [createRenameTemplateResult, newTemplateIsSaving, newTemplateError] = services.calculationBuilderService.createRenameCalculationTemplate(createRenameTemplatePayload);
    const [templatesResult, templatesLoading, templatesError] = services.calculationBuilderService.getTemplates(templatesNeedRefresh, getTemplatesForWorkbook ? calculation?.workbookId : undefined);
    const [linkResult, isLinking, linkError] = services.calculationBuilderService.linkCalculationToTemplate(linkCalculationPayload);
    const [cloneResult, isCloning, cloneError] = services.calculationBuilderService.cloneCalculation(loadTime, calculation?.calculationNumber as unknown as string, selectedTemplateId)
    const [editResult, isEditing, editError] = services.calculationBuilderService.editTemplate(shouldEditTemplate, calculationTemplateMetaData?.templateId || '', calculation?.calculationNumber || '');
    const [saveResult, isSaving, saveError] = services.calculationBuilderService.saveTemplate(shouldSaveTemplate, calculationTemplateMetaData?.templateId || '');
    const [cancelResult, isCanceling, cancelError] = services.calculationBuilderService.cancelTemplateChanges(shouldCancelTemplate, calculationTemplateMetaData?.templateId || '');
    
    useEffect(() => {
        if (templatesResult != null) {
            calcBuilderDispatch(ACTIONS.SET_AVAILABLE_TEMPLATES.withPayload(templatesResult));
            setTemplatesNeedRefresh(false);
        }
    }, [templatesResult])

    useEffect(() => {
        setTemplatesNeedRefresh(true);
    }, [getTemplatesForWorkbook])

    useEffect(() => {
        if (linkResult == null) {
            return;
        }
        setShowLinkToTemplateModal(false);
        calcBuilderDispatch(ACTIONS.REFRESH_ALL_CALCULATION_INFORMATION);
        calcBuilderDispatch(ACTIONS.SET_REFRESH_DATASOURCES_FLAG.withPayload(true));
        calcBuilderDispatch(ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.withPayload(true));
        services.messageService.showSuccessAutoDismissBanner(`Calculation successfully linked to template ${selectedTemplateName}.`,10000);
        if (linkResult.currencyCodeOverrideSkipped) {
            services.messageService.showInfoBanner(`The currency override specified was not applied since the calculation template ${selectedTemplateName} has multiple currencies.`);
        }
    }, [linkResult])

    useEffect((() => {
        if (StringUtils.isNullOrEmpty(linkError)) {
            return;
        }
        calcBuilderDispatch(ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }), [linkError])

    useEffect(() => {
        if (cloneResult == null) {
            return;
        }
        setShowCloneFromTemplateModal(false);
        calcBuilderDispatch(ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
        calcBuilderDispatch(ACTIONS.SET_REFRESH_DATASOURCES_FLAG.withPayload(true));
        calcBuilderDispatch(ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.withPayload(true));
        services.messageService.showSuccessAutoDismissBanner(`Calculation successfully cloned from template ${selectedTemplateName}.`, 10000);
    }, [cloneResult])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(cloneError)) {
            return;
        }
        calcBuilderDispatch(ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [cloneError])

    useEffect(() => {
        if (unlinkResult == null) {
            return;
        }
        setShowUnlinkConfirmModal(false);
        services.messageService.showSuccessAutoDismissBanner(`This calculation was successfully unlinked from template ${calculationTemplateMetaData?.templateName}.`,10000);
        calcBuilderDispatch(ACTIONS.REFRESH_ALL_CALCULATION_INFORMATION);
    }, [unlinkResult])

    useEffect((() => {
        if (StringUtils.isNullOrEmpty(unlinkError)) {
            return;
        }
        calcBuilderDispatch(ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }), [unlinkError])

    useEffect(() => {
        if (editResult == null) {
            return;
        }
        calcBuilderDispatch(ACTIONS.REFRESH_ALL_CALCULATION_INFORMATION);
        setShouldEditTemplate(false);
    }, [editResult])

    useEffect((() => {
        if (StringUtils.isNullOrEmpty(editError)) {
            return;
        }
        calcBuilderDispatch(ACTIONS.REFRESH_ALL_CALCULATION_INFORMATION);
    }), [editError])
 
    useEffect(() => {
        if (saveResult == null) {
            return;
        }
        calcBuilderDispatch(ACTIONS.SET_REFRESH_CALCULATION_METADATA_FLAG.withPayload(true));
        setShouldSaveTemplate(false);
        let successMsg = 'Calculation template changes saved successfully.'
        successMsg += ` ${saveResult.autoDraftsForActiveCalcsCreated} active calculation(s) and `
        successMsg += `${saveResult.autoDraftsForPendingReviewCalcsCreated} pending review calculation(s) `
        successMsg += 'linked to the template were automatically moved to draft status due to this update.'
        
        services.messageService.showSuccessAutoDismissBanner(successMsg, 10000);
        setIsProcessingSave(false);
    }, [saveResult])
 
    useEffect(() => {
        if(cancelResult == null) {
            return;
        }
        calcBuilderDispatch(ACTIONS.REFRESH_ALL_CALCULATION_INFORMATION);
        setShouldCancelTemplate(false);
        calcBuilderDispatch(ACTIONS.SET_REFRESH_DATASOURCES_FLAG.withPayload(true));
        calcBuilderDispatch(ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.withPayload(true));
    }, [cancelResult])

    useEffect(() => {
        if (newTemplateError == null || newTemplateError == ''){
            return;
        }
        services.messageService.showError(newTemplateError);
    }, [newTemplateError])

    useEffect(() => {
        if (createRenameTemplateResult == null){
            return;
        }
        setShowCreateRenameTemplateModal(false);
        services.messageService.showSuccessAutoDismissBanner(`Calculation template successfully ${createRenameTemplatePayload?.templateId == null? "created" : "renamed"}.`);
        calcBuilderDispatch(ACTIONS.SET_REFRESH_CALCULATION_METADATA_FLAG.withPayload(true));
        setIsProcessingSave(false);
    }, [createRenameTemplateResult])

    useEffect(() => {
        if (validateCalculationSteps) {
            if (areCalculationStepsValid) {
                checkForUnusedDataSources();
            } else {
                services.messageService.showErrorBanner('Please correct errors in calculation steps before creating a calculation template.');
                setIsProcessingSave(false);
            }
            calcBuilderDispatch(ACTIONS.SET_VALIDATE_CALC_STEPS.withPayload(false));
            calcBuilderDispatch(ACTIONS.SET_ARE_CALC_STEPS_VALID.withPayload(undefined));
        }
    }, [areCalculationStepsValid])

    const confirmUnlinkFromTemplate = () => {
        setShowUnlinkConfirmModal(true);
    }

    const unlinkFromTemplate = () => {
        calcBuilderDispatch(ACTIONS.SET_UNLINK_CALCULATION_PAYLOAD.withPayload({
            calculationNumber: calculation?.calculationNumber,
            templateId: calculationTemplateMetaData?.templateId
        }))
    }

    const getLinkToTemplateInputs = () => {
        if (calculation != null && calculation.workbookId == null) {
            setTemplateErrors({
                header: 'Link to Template Error',
                message: 'Please fix the following error(s) to link to a calculation template.',
                errors: ['A workbook must be assigned to the CLI.']
            });
            return;
        }
        calcBuilderDispatch(ACTIONS.SET_GET_TEMPLATES_FOR_WORKBOOK.withPayload(true));
        setTemplatesNeedRefresh(true);
        setShowLinkToTemplateModal(true);
    }

    const getCloneFromTemplateInputs = () => {
        calcBuilderDispatch(ACTIONS.SET_GET_TEMPLATES_FOR_WORKBOOK.withPayload(false));
        setTemplatesNeedRefresh(true);
        setShowCloneFromTemplateModal(true);
    }

    const linkToTemplate = (l: LinkToTemplate) => {
        setSelectedTemplateName(l.templateName);
        calcBuilderDispatch(ACTIONS.SET_LINK_CALCULATION_PAYLOAD.withPayload(l));
    }

    const cloneFromTemplate = (templateId: string, templateName: string) => {
        setSelectedTemplateId(templateId);
        setSelectedTemplateName(templateName);
        setLoadTime(new Date().toString());
    }

    const createTemplate = () => {
        setSaveAction(SAVE_ACTION_CREATE);
        validateTemplate();
    }

    const validateTemplate = () => {
        const errorList: string[] = [];
        if (ArrayUtils.isNullOrEmpty(dataSourceRecords) || (dataSourceRecords.length == 1 && dataSourceRecords[0].isNewRecord)) {
            errorList.push('At least one data source is required.');
        }
        if (ArrayUtils.isNullOrEmpty(steps) || (steps.length == 1 && steps[0].isNew)) {
            errorList.push('At least one calculation step is required.');
        }
        if (!ArrayUtils.isNullOrEmpty(dataSourceRecords) && dataSourceRecords.find(x => x.isEditing && !x.isNewRecord) != undefined) {
            errorList.push('Unsaved changes to data sources must be saved.');
        }
        if (!ArrayUtils.isNullOrEmpty(steps) && steps.find(x => x.isBeingEdited && !x.isNew)) {
            errorList.push('Unsaved changes to calculation steps must be saved.');
        }
        if (!ArrayUtils.isNullOrEmpty(dataSourceRecords)) {
            const dataSourcesMissingCurrency = dataSourceRecords
                .filter(x => x.datasource != CONSTANTS.DATA_SOURCE_TYPES.TP_ALLOCATION_WORKSHEET && (x.currency == null || x.currency == ''))
                .map(y => y.description);
            if (!ArrayUtils.isNullOrEmpty(dataSourcesMissingCurrency)) {
                errorList.push(`Currency must be defined for the following data source(s): ${dataSourcesMissingCurrency.join(', ')}.`);
            }
        }
        if (calculation != null && calculation.workbookId == null) {
            errorList.push('A workbook must be assigned to the CLI.');
        }

        if (errorList.length > 0) {
            setTemplateErrors({
                header: saveAction == SAVE_ACTION_CREATE ? 'Create Template Error' : 'Edit Template Error',
                message: `Please fix the following error(s)${saveAction == SAVE_ACTION_CREATE ? ' to create a calculation template.' : '.'}`,
                errors: errorList
            });
            return;
        }

        setIsProcessingSave(true);
        calcBuilderDispatch(ACTIONS.SET_VALIDATE_CALC_STEPS.withPayload(true));
    }

    const checkForUnusedDataSources = () => {
        const unusedDataSources = findUnusedDataSources(steps, dataSourceRecords);
        if (!ArrayUtils.isNullOrEmpty(unusedDataSources)) {
            setUnusedDataSourcesWarning(`This calculation has ${unusedDataSources.length} unused data source${unusedDataSources.length === 1? '' : 's'}. Unused datasources: ${unusedDataSources.join(", ")}`);
            return;
        }
        proceedSaveAction();
    }

    const proceedSaveAction = () => {
        setUnusedDataSourcesWarning(null as unknown as string);
        if (saveAction == SAVE_ACTION_CREATE) {
            setShowCreateRenameTemplateModal(true);
        } else if (saveAction == SAVE_ACTION_EDIT) {
            exitEditMode();
        }
        setSaveAction('');
    }

    const exitEditMode = () => {
        setShouldSaveTemplate(true);
    }

    const enterEditMode = () => {
        setShowEditTemplateConfirmModal(true);
    }

    const doneEditingTemplate = () => {
        setSaveAction(SAVE_ACTION_EDIT);
        validateTemplate();
    }

    const cancelEditingTemplate = () => {
        setShowCancelTemplateConfirmModal(true);
    }

    const renameTemplate = () => {
        setShowCreateRenameTemplateModal(true);
    }

    return (
    <Container className="templatesContainer">
        <TPELoadingSpinner loading={loading}>
            <React.Fragment>
                <SpaceBetween direction='vertical' size='m'>
                    {calculationTemplateMetaData?.templateId != null && viewMode != CONSTANTS.VIEW_MODE.FROZEN && <Alert className='linkedToTemplateAlert' type={isEditingTemplate? 'warning':'info'}>
                            This calculation is linked to template "{calculationTemplateMetaData.templateName}" and it is {isEditingTemplate? "now in " : "in "}
                            <strong>{isEditingTemplate? "template editing" : "template viewing"}</strong> mode.
                            {calculationTemplateMetaData?.templateEditCalculation != null &&
                                (calculationTemplateMetaData.templateEditCalculation == calculation?.calculationNumber ?
                                    ` This template is being edited by ${calculationTemplateMetaData.templateEditUser}.`
                                :
                                ` This template is being edited by ${calculationTemplateMetaData.templateEditUser} from calculation ${calculationTemplateMetaData.templateEditCalculation}.`
                                )
                            }
                        </Alert>
                    }
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                        <div className="templateNameDiv">
                            <SpaceBetween direction="vertical" size="xs">
                                <SpaceBetween direction='horizontal' size='s'>
                                    <strong>{isEditingTemplate? "Editing template:": "Template:"}</strong>
                                    {calculationTemplateMetaData?.templateId == null? <span>N/A</span> : <Link className='templateNameLink' onFollow={() => setShowLinkedCLIsModal(true)}>{calculationTemplateMetaData.templateName}</Link>}
                                    {calculationTemplateMetaData?.templateId != null && 
                                        <React.Fragment>
                                            <strong>Template currency:</strong>
                                            <span>
                                                <Popover
                                                    data-class="basicSmallPopover"
                                                    dismissButton={false}
                                                    position="top"
                                                    content="This is the currency used for all non-STAT GL data sources."
                                                >
                                                    {calculationTemplateMetaData?.templateCurrency || 'Multiple'}
                                                </Popover>
                                            </span>
                                        </React.Fragment>
                                    }
                                </SpaceBetween>
                                {calculationTemplateMetaData?.templateId != null && 
                                    <SpaceBetween direction="horizontal" size="s">
                                        <span className="smallTextSpan"><b>Provider override:</b></span>
                                        <span className="smallTextSpan">
                                            {calculationTemplateMetaData?.providerCodeOverride == null ? 'None'
                                            :
                                            <Popover
                                                data-class="basicSmallPopover"
                                                dismissButton={false} 
                                                position="top" 
                                                content={`"${calculationTemplateMetaData?.providerCodeOverride}" will replace the provider company placeholder in the data source COA. When a user saves a data source, "${calculationTemplateMetaData?.providerCodeOverride}" will be replaced by the provider company placeholder.`}
                                            >
                                                {calculationTemplateMetaData?.providerCodeOverride}
                                            </Popover>
                                            }
                                        </span>
                                        <span className="smallTextSpan"><b>Recipient override:</b></span>
                                        <span className="smallTextSpan">
                                            {calculationTemplateMetaData?.recipientCodeOverride == null ? 'None'
                                            :
                                            <Popover 
                                                data-class="basicSmallPopover"
                                                dismissButton={false} 
                                                position="top" 
                                                content={`"${calculationTemplateMetaData?.recipientCodeOverride}" will replace the recipient company placeholder in the data source COA. When a user saves a data source, "${calculationTemplateMetaData?.recipientCodeOverride}" will be replaced by the recipient company placeholder.`}
                                            >
                                                {calculationTemplateMetaData?.recipientCodeOverride}
                                            </Popover>
                                            }
                                        </span>
                                        <span className="smallTextSpan"><b>Currency override:</b></span>
                                        <span className="smallTextSpan">
                                            {calculationTemplateMetaData?.currencyCodeOverride == null ? 'None'
                                            :
                                            <Popover 
                                                data-class="basicSmallPopover"
                                                dismissButton={false} 
                                                position="top" 
                                                content={calculationTemplateMetaData?.templateCurrency == null ? 'Currency override cannot be applied to multi-currency templates.' : `"${calculationTemplateMetaData.currencyCodeOverride}" will replace ${calculationTemplateMetaData.templateCurrency} as the currency for non-STAT GL data sources.`}
                                            >
                                                {calculationTemplateMetaData?.templateCurrency == null ? `${calculationTemplateMetaData?.currencyCodeOverride} (Ignored)` : calculationTemplateMetaData?.currencyCodeOverride} 
                                            </Popover>
                                            }
                                        </span>
                                    </SpaceBetween>
                                }
                            </SpaceBetween>
                        </div>
                        { viewMode != CONSTANTS.VIEW_MODE.READ_ONLY && viewMode != CONSTANTS.VIEW_MODE.FROZEN && 
                            <Box float='right'>
                                <SpaceBetween direction='horizontal' size='m'>
                                    { isEditingTemplate?
                                        <>
                                            <Button onClick={cancelEditingTemplate} disabled={isProcessingSave || isCanceling} loading={isCanceling} className='templateActionButton templates_cancelEditingButton'>{isCanceling ? 'Canceling...' : 'Cancel and Revert'}</Button>
                                            <Button variant='primary' disabled={isProcessingSave || isCanceling} loading={isProcessingSave} onClick={doneEditingTemplate} className='templateActionButton templates_saveChangesButton'>{isProcessingSave ? 'Saving...' : 'Confirm Changes' }</Button>
                                        </>
                                        :
                                        calculationHasTemplate?
                                            <>
                                                <Button loading={newTemplateIsSaving} onClick={renameTemplate} disabled={calculationTemplateMetaData?.templateEditCalculation != null || newTemplateIsSaving || isUnlinking || isEditing} 
                                                        className='templateActionButton templates_renameButton'>
                                                            {newTemplateIsSaving ? 'Renaming...' : 'Rename'}
                                                </Button>
                                                <Button disabled={newTemplateIsSaving || isUnlinking || isEditing} loading={isUnlinking} onClick={confirmUnlinkFromTemplate} className='templateActionButton templates_unlinkButton'>{isUnlinking ? 'Unlinking...' : 'Unlink from template'}</Button>
                                                <Button loading={isEditing} variant='primary' onClick={enterEditMode} disabled={calculationTemplateMetaData?.templateEditCalculation != null || newTemplateIsSaving || isUnlinking || isEditing} className='templateActionButton templates_editButton'>{isEditing ? 'Entering edit mode...': 'Edit template'}</Button>
                                            </>
                                            :
                                            <>
                                                <Button disabled={isProcessingSave || isCloning || isLinking} loading={isLinking} onClick={getLinkToTemplateInputs} className='templateActionButton templates_linkButton'>{isLinking ? 'Linking...' : 'Link to template'}</Button>
                                                <Button disabled={isProcessingSave || isCloning || isLinking} loading={isCloning} onClick={getCloneFromTemplateInputs} className='templateActionButton templates_cloneButton'>{isCloning ? 'Cloning...' : 'Clone from template'}</Button>   
                                                <Button variant='primary' disabled={isProcessingSave} loading={isProcessingSave} onClick={createTemplate} className='templateActionButton templates_createButton'>{isProcessingSave ? 'Saving...' : 'Save as template'}</Button>                                                     
                                            </>
                                    }
                                </SpaceBetween>
                            </Box>
                        }
                    </Grid>
                </SpaceBetween>

                <TPEBasicModal
                    className="linkedCLIsModal"
                    visible={showLinkedCLIsModal}
                    action={ACTION_TYPE.OK_ONLY}
                    title={`${linkedCLIs.length} CLI${linkedCLIs.length === 1? "" : "s"} linked to Template`}
                    onCancel={() => setShowLinkedCLIsModal(false)}
                    onConfirm={() => setShowLinkedCLIsModal(false)}
                >
                    <div className='linkedCLIsContainer'>
                        <ColumnLayout columns={2} disableGutters>
                            {linkedCLIs.map(x => <div key={x.calculationNumber} className='verticalPadding'><LinkedCLILink linkedCLI={x}></LinkedCLILink></div>)}
                        </ColumnLayout>
                    </div>
                </TPEBasicModal>
                <LinkToTemplateModal 
                    visible={showLinkToTemplateModal}
                    loading={templatesLoading}
                    isLinking={isLinking}
                    linkError={linkError}
                    onClose={() => setShowLinkToTemplateModal(false)}
                    onSubmit={(l: LinkToTemplate) => linkToTemplate(l)}
                />
                <CloneFromTemplateModal
                    visible={showCloneFromTemplateModal}
                    loading={templatesLoading}
                    isCloning={isCloning}
                    cloneError={cloneError}
                    onClose={() => setShowCloneFromTemplateModal(false)}
                    onSubmit={(templateId: string, templateName: string) => cloneFromTemplate(templateId, templateName)}
                />
                <UnlinkFromTemplateModal
                    visible={showUnlinkConfirmModal}
                    isUnlinking={isUnlinking}
                    unlinkError={unlinkError}
                    onSubmit={unlinkFromTemplate}
                    onClose={() => setShowUnlinkConfirmModal(false)}
                />
                <CreateRenameTemplateModal
                    visible={showCreateRenameTemplateModal}
                    workbookName={calculation?.workbookName || ''}
                    isSaving={newTemplateIsSaving}
                    alertMessage={temporaryMessage}
                    templateMetaData={calculationTemplateMetaData}
                    templateName={calculationTemplateMetaData?.templateName}
                    linkedCLIs={linkedCLIs}
                    onClose={() => setShowCreateRenameTemplateModal(false)}
                    onSubmit={(c: CalculationTemplateMetaData) => {
                        calcBuilderDispatch(ACTIONS.SET_CREATE_RENAME_CALCULATION_TEMPLATE_PAYLOAD.withPayload({
                            ...c,
                            calculationNumber: calculation?.calculationNumber
                        }))
                    }}
                ></CreateRenameTemplateModal>
                <EditTemplateConfirmationModal
                    visible={showEditTemplateConfirmModal}
                    templateName={calculationTemplateMetaData?.templateName}
                    linkedCLIs={linkedCLIs}
                    onClose={() => setShowEditTemplateConfirmModal(false)}
                    onConfirmed={() => {setShouldEditTemplate(true); setShowEditTemplateConfirmModal(false);}}
                />
                <TPEBasicModal
                    className="unusedDataSourcesWarningModal"
                    visible={unusedDataSourcesWarning != null}
                    action={ACTION_TYPE.YES_NO}
                    title="Unused data sources warning"
                    onCancel={() => { setIsProcessingSave(false); setUnusedDataSourcesWarning(null as unknown as string); }}
                    onConfirm={proceedSaveAction}
                >
                    <span className='unusedDatasourcesMessage'>{unusedDataSourcesWarning}</span>
                    <br/>
                    <br/>
                    <strong>Are you sure you want to continue?</strong>
                </TPEBasicModal>
                <TPEBasicModal
                    className="cancelTemplateChangesConfirmationModal"
                    visible={showCancelTemplateConfirmModal}
                    action={ACTION_TYPE.YES_NO}
                    title="Cancel Template Changes"
                    onCancel={() => setShowCancelTemplateConfirmModal(false)}
                    onConfirm={() => {setShouldCancelTemplate(true); setShowCancelTemplateConfirmModal(false);}}
                >
                    By cancelling, the template will be reverted back to the last saved status. Do you wish to cancel?
                </TPEBasicModal>
                <TPEBasicModal
                    className="templateErrorsModal"
                    visible={templateErrors != null}
                    action={ACTION_TYPE.OK_ONLY}
                    title={templateErrors?.header}
                    onCancel={() => {}}
                    onConfirm={() => setTemplateErrors(null as unknown as TemplateErrors)}
                >
                    {templateErrors?.message}
                    <ul>
                        {templateErrors?.errors.map((x, index) => <li className='templatesErrorListItem' key={`templateErrorItem_${index}`}>{x}</li>)}
                    </ul>
                </TPEBasicModal>
                <TPEErrorWatcher services={services} errors={[editError, saveError, cancelError]} />
            </React.Fragment>
        </TPELoadingSpinner>
    </Container>
    )
}

   /**
 * Finds a list of data sources not referenced by the calculation step's formula expressions
 * @param steps The list of calculation steps
 * @param dataSources The list of data sources
 * @returns A list with all data sources not referenced by the calculation step formulas
 */
    function findUnusedDataSources(steps: CalculationStep[], dataSources: DataSourceRecord[]) {
        const flatSteps = ReadOnlyCalculationService.flattenSteps(steps);
        const usedDataSources = {} as any;
        flatSteps.forEach(s => s.expressionTokens.filter(x => x.type === ExpressionPartTypes.DATA_SOURCE).forEach(t => {
            const dsName = t.displayValue || 'UNKNOWN';
            usedDataSources[dsName] = (usedDataSources[dsName] || 0) + 1
        }))
        const usedDataSourceNames = Object.keys(usedDataSources);
        const dataSourceNames = dataSources.map(x => x.description);
        const unusedDataSources: string[] = [];
        dataSourceNames.forEach(key => {
            if (!usedDataSourceNames.includes(key)){
                unusedDataSources.push(key)
            }
        })
        return unusedDataSources;
    }