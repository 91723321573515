import React from 'react';
import { useEffect, useState, useMemo } from "react";
import 'src/assets/styles/react-table.scss';
import 'src/components/CustomAppLayout.scss';
import ServiceCollection from "src/services/ServiceCollection";
import { TPReportsSummaryRequest } from "src/models/reports/TPReportsSummary";
import { Popover, SelectProps } from '@amzn/awsui-components-react/polaris';
import TPPieChart from '../../shared/TPPieChart';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    SpaceBetween,
    Grid,
    Header,
} from '@amzn/awsui-components-react';
import ReportMetricsDashboardFilterSelector from './ReportMetricsDashboardFilterSelector';
import { convertCamelCaseToTitleCase, generatePieChartData } from './util';
import { set } from 'date-fns';

type ComponentProps = {
    services: ServiceCollection
}

export default function ReportMetricsDashboard(props: ComponentProps) {

    const { services } = props;
    const [tpVarianceDetails, setTpVarianceDetails] = useState<Map<string, number>>();
    const [reportingCLIExecutionStatusDetails, setReportingCLIExecutionStatusDetails] = useState<Map<string, number>>();
    const [mecCliExecutionStatusDetails, setMecCliExecutionStatusDetails] = useState<Map<string, number>>();
    const [totalCli, setTotalCli] = useState<number>(0);
    const [totalICRSRecords, setTotalICRSRecords] = useState<number>(0);
    const [totalTPRecords, setTotalTPRecords] = useState<number>(0);
    const [rootCauseCoverage, setRootCauseCoverage] = useState<number>(0);
    const [selectedPeriodNameOption, setSelectedPeriodNameOption] = React.useState(null as unknown as SelectProps.Option | null);
    const [selectedAccountingDayOption, setSelectedAccountingDayOption] = React.useState(null as unknown as SelectProps.Option | null);
    const [tpReportsSummaryRequest, setTpReportsSummaryRequest] = React.useState(null as unknown as TPReportsSummaryRequest);
    const [fetchTPReportSummaryResponse, fetchTpReportsSummaryLoading, tpReportsSummaryFetchError] = services.reportsService.fetchCompleteTPReportsSummary(tpReportsSummaryRequest);

    useEffect(() => {
        if (!tpReportsSummaryFetchError) {
            return;
        }
        services.messageService.showErrorAutoDismissBanner(tpReportsSummaryFetchError);
    }, [tpReportsSummaryFetchError]);

    useEffect(() => {
        if (!fetchTPReportSummaryResponse) {
            return;
        }
        setTpVarianceDetails(fetchTPReportSummaryResponse.varianceAnalysis);
        setReportingCLIExecutionStatusDetails(fetchTPReportSummaryResponse.reportingServiceStatus);
        setMecCliExecutionStatusDetails(fetchTPReportSummaryResponse.atpCliStatus);
        setTotalCli(fetchTPReportSummaryResponse.atpExecutionDetailsByCount.totalNumberOfCLIs);
        setTotalICRSRecords(fetchTPReportSummaryResponse.atpExecutionDetailsByCount.totalNumberOfICRSEntries);
        setTotalTPRecords(fetchTPReportSummaryResponse.atpExecutionDetailsByCount.totalNumberOfEntriesExecutedByATP);
        setRootCauseCoverage(fetchTPReportSummaryResponse.rootCauseCoverage);
    }, [fetchTPReportSummaryResponse]);

    const fetchTPTaxReportMetricsData = () => {
        if (!!selectedAccountingDayOption && !!selectedPeriodNameOption) {
            setTpReportsSummaryRequest({
                period: selectedPeriodNameOption?.value as string,
                accountingDay: selectedAccountingDayOption?.value as string
            })
        }
    }

    const reportingServiceStatusData = useMemo(() => generatePieChartData(reportingCLIExecutionStatusDetails, totalCli), [reportingCLIExecutionStatusDetails, totalCli]);
    const varianceAnalysisData = useMemo(() => generatePieChartData(tpVarianceDetails, totalCli), [tpVarianceDetails, totalCli]);
    const atpCliStatusData = useMemo(() => generatePieChartData(mecCliExecutionStatusDetails, totalCli), [mecCliExecutionStatusDetails, totalCli]);


    return (
        <Container header={<Header variant="h2"> Transfer Pricing Reporting Dashboard</Header>}>
            <SpaceBetween direction="vertical" size="m">
                <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                    <ReportMetricsDashboardFilterSelector
                        selectedAccountingPeriod={selectedPeriodNameOption}
                        selectedAccountingDay={selectedAccountingDayOption}
                        services={services}
                        onAccountingPeriodSelected={(periodOption) => setSelectedPeriodNameOption({
                            label: periodOption?.label,
                            value: periodOption?.value
                        })}
                        onAccountingDaySelected={(accountingDayOption) => setSelectedAccountingDayOption({
                            label: accountingDayOption?.label,
                            value: accountingDayOption?.value
                        })}
                    />
                    <Box float="left" padding={{ top: "xl" }}>
                        <SpaceBetween direction="horizontal" size="l">
                            <Button
                                onClick={fetchTPTaxReportMetricsData}
                                disabled={(!selectedAccountingDayOption || !selectedPeriodNameOption)}
                                loading={fetchTpReportsSummaryLoading}
                                variant="primary"
                            >
                                View Report
                            </Button>
                        </SpaceBetween>
                    </Box>
                </Grid>
                <Grid gridDefinition={[{ colspan: { default: 7, xl: 8 } }, { colspan: { default: 5, xl: 4 } }]}>
                    <Container header={
                        <Header variant="h2"
                            description="Status summary for all CLIs, excluding ICRS, ingested by the reporting service during the selected period.">
                            Reporting Service Status
                        </Header>}>
                        {reportingCLIExecutionStatusDetails ? (
                            <ColumnLayout columns={4} variant="text-grid">
                                {Object.entries(reportingCLIExecutionStatusDetails).map(([label, value], index) => (
                                    <div key={index}>
                                        <Box variant="awsui-key-label">{convertCamelCaseToTitleCase(label)}</Box>
                                        <Box variant='h1'>{value}</Box>
                                    </div>
                                ))}
                                <div>
                                    <Box variant="awsui-key-label">Total CLIs Processed</Box>
                                    <Box variant='h1'>{totalCli}</Box>
                                </div>
                            </ColumnLayout>
                        ) : (
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    No reporting status details available to display
                                </Box>
                            </Box>
                        )}
                    </Container>
                    <TPPieChart
                        data={reportingServiceStatusData}
                        totalUnits={totalCli}
                        ariaDescription="Donut chart showing ATP reporting dashboard data."
                        ariaLabel="ATP reporting Donut chart"
                        emptyDataDescription='No report status data available to display on the chart'
                        noMatchDataDescription='No report status data available for the selected filter criteria'
                    />
                </Grid>
                <Grid gridDefinition={[{ colspan: { default: 7, xl: 8 } }, { colspan: { default: 5, xl: 4 } }]}>
                    <Container header={
                        <Header variant="h2" description="Summary of variances identified between targeted and actual results for CLIs during the selected period.">
                            Variance Analysis
                        </Header>}>
                        {tpVarianceDetails ? (
                            <ColumnLayout columns={4} variant="text-grid">
                                {Object.entries(tpVarianceDetails).map(([label, value], index) => (
                                    <div key={index}>
                                        <Box variant="awsui-key-label">{convertCamelCaseToTitleCase(label)}</Box>
                                        <Box variant='h1'>{value}</Box>
                                    </div>
                                ))}
                                <div>
                                    <Popover
                                        header='Root Cause Coverage Metric'
                                        content='This metric captures the % of CLIs where root cause was systematically determined'
                                    >
                                        <Box variant="awsui-key-label" color='text-status-info'>% Coverage with Root Cause</Box>
                                    </Popover>
                                    <Box variant='h1'>{rootCauseCoverage}%</Box>
                                </div>
                            </ColumnLayout>
                        ) : (
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    No Variance data available to display
                                </Box>
                            </Box>
                        )}
                    </Container>
                    <div>
                        <TPPieChart
                            data={varianceAnalysisData}
                            totalUnits={totalCli}
                            ariaDescription="Donut chart showing ATP reporting dashboard data."
                            ariaLabel="ATP reporting Donut chart"
                            emptyDataDescription='No variance data available to display on the chart'
                            noMatchDataDescription='No variance data available for the selected filter criteria'
                        />
                    </div>
                </Grid>
                <Grid gridDefinition={[{ colspan: { default: 7, xl: 8 } }, { colspan: { default: 5, xl: 4 } }]}>
                    <Container header={
                        <Header variant="h2" description="Status of all CLIs processed in ATP during the selected period.">
                            Month End Close Status
                        </Header>}>
                        {mecCliExecutionStatusDetails ? (
                            <ColumnLayout columns={4} variant="text-grid">
                                <div>
                                    <Box variant="awsui-key-label">Total Records Processed</Box>
                                    <Box variant='h1'>{totalTPRecords}</Box>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Total CLIs Processed</Box>
                                    <Box variant='h1'>{totalCli}</Box>
                                </div>
                                <div>
                                    <Box variant="awsui-key-label">Total ICRS Records Processed</Box>
                                    <Box variant='h1'>{totalICRSRecords}</Box>
                                </div>
                                {Object.entries(mecCliExecutionStatusDetails).map(([label, value], index) => (
                                    <div key={index}>
                                        <Box variant="awsui-key-label">{convertCamelCaseToTitleCase(label)}</Box>
                                        <Box variant='h1'>{value}</Box>
                                    </div>
                                ))}
                            </ColumnLayout>
                        ) : (
                            <Box textAlign="center" color="inherit">
                                <b>No data available</b>
                                <Box variant="p" color="inherit">
                                    No MEC CLI Execution Summary data available to display
                                </Box>
                            </Box>
                        )}
                    </Container>
                    <div>
                        <TPPieChart
                            data={atpCliStatusData}
                            totalUnits={totalCli}
                            ariaDescription="Donut chart showing ATP reporting dashboard data."
                            ariaLabel="ATP reporting Donut chart"
                            emptyDataDescription='No month end close data available to display on the chart'
                            noMatchDataDescription='No month end close data available for the selected filter criteria'
                        />
                    </div>
                </Grid>
            </SpaceBetween>
        </Container>
    )

};