import { StatusIndicator } from '@amzn/awsui-components-react';
import React, { useMemo } from 'react';
import { FAILED_EXECUTION_STATUSES, IN_PROGRESS_EXECUTION_STATUSES, PENDING_EXECUTION_STATUSES, SUCCESSFUL_EXECUTION_STATUSES } from 'src/utils/constants';

export default function ExecutionStatusIndicator(props: {status: string}) {
    const { status = '' } = props;

    const getStatusIcon = () => {
        if (SUCCESSFUL_EXECUTION_STATUSES.includes(status)) {
            return 'success';
        }
        if (FAILED_EXECUTION_STATUSES.includes(status)) {
            return 'error';
        }
        if (IN_PROGRESS_EXECUTION_STATUSES.includes(status)) {
            return 'loading';
        }
        if (PENDING_EXECUTION_STATUSES.includes(status)) {
            return 'pending';
        }
        return null;
    }

    const statusIcon = useMemo(getStatusIcon, [status]);

    return  statusIcon == null
            ?
                <span>{status || ' '}</span>
            :
                <StatusIndicator data-class="smallStatusIndicator" type={statusIcon}>{status}</StatusIndicator>;
}