import React, { useEffect, useState } from 'react';
import { SpaceBetween, Button, Alert, Header, Container, Pagination } from '@amzn/awsui-components-react/polaris';
import CONSTANTS, { CUSTOM_DATA_TABLE_CONSTANTS } from 'src/utils/constants';
import { DataSourcesContext } from './DataSourcesContainer';
import CDTSelectorGrid from './CDTSelectorGrid'
import CDTRowValuesSelectorGrid from './CDTRowValuesSelectorGrid'
import { ACTIONS } from 'src/services/calculation-builder/DataSourcesReducer';
import CustomTableMetadata from 'src/models/custom-tables-shared/CustomTableMetadata';
import CustomDataTableRecord from 'src/models/custom-data-tables/CustomDataTableRecord';
import CDTSelectableValue from 'src/models/custom-data-tables/CDTSelectableValue';
import stringUtils from 'src/utils/stringUtils';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import CDTSelection from "src/models/custom-data-tables/CDTSelection"
import moment from 'moment';
import StringUtils from 'src/utils/stringUtils';
import { ACTION_TYPE, TPEBasicModal } from 'src/components/shared/TPEBasicModal';
import CustomDataTableRowsInfo, { CustomDataTableColumnDefinition } from 'src/models/custom-data-tables/CustomDataTableRowsInfo';
import TPESearchRequest from 'src/models/common/TPESearchRequest';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import CustomDataTableRowsSearchRequest from 'src/models/custom-data-tables/CustomDataTableRowsSearchRequest';
import { addCalculationNumberFilter, getCustomDataTableRowsSearchRequest, getCustomDataTablesSearchRequest, setResultPageNumber, setSortByPeriod } from 'src/components/custom-tables-shared/CustomDataTableSearchHelper';
import KeyValuePair from 'src/models/common/KeyValuePair';
import DataKeySummaryView from './DataKeySummaryView';
import CancelEditing from './CancelEditing';


export function CDTSelector(props: { visible: boolean, onClose: () => void, onSubmit: () => void }) {
    const { visible, onSubmit } = props;

    const { calcBuilderState } = React.useContext(CalculationBuilderContext);
    const { calculation } = calcBuilderState;

    const { state, services, dispatch } = React.useContext(DataSourcesContext);
    const { 
        recordBeingEdited,
        dataSourceRecords,
        customCoaReference = new Map<string, string>()
    } = state;
    const {
        isEditing = false,
        customDatasourceTableName,
        customDatasourceValueId,
        customDatasourceValueLegend,
        customDatasourceCalculationNumber,
        customPeriod,
        errors: dataSourceRecordErrors
    } = recordBeingEdited || {};

    const DATA_TABLES_GRID_STEP = 1;
    const DATA_TABLE_ROWS_GRID_STEP = 2;

    const [showCancelConfirmation, setShowCancelConfirmation] = React.useState(false);
    const [error, setError] = useState(null as string | null);
    const [selectedTable, setSelectedTable] = useState(undefined as CustomTableMetadata | undefined);
    const [selectedValuesMap, setSelectedValuesMap] = useState(new Map<string,CDTSelection>());
    const [activeGridStep, setActiveGridStep] = useState(DATA_TABLES_GRID_STEP);
    const [valuesSelectedCount, setValuesSelectedCount] = useState(0);
    const [searchCustomDataTablesPayload, setSearchCustomDataTablesPayload] = useState(null as unknown as TPESearchRequest);
    const [searchCustomDataTableRowsPayload, setSearchCustomDataTableRowsPayload] = useState(null as unknown as CustomDataTableRowsSearchRequest);
    const [viewMode, setViewMode] = useState(false);
    const [startSavingCustomDatasources, setStartSavingCustomDatasources] = useState(false);
    const [unsavedDataSourcePayloadNeedsSaving, setUnsavedDataSourcePayloadNeedsSaving] = useState(undefined as string | undefined);
    const [unsavedDataSourcePayload, setUnsavedDataSourcePayload] = useState(undefined as DataSourceRecord | undefined);
    const [saveDataSourcePayloadList, setSaveDataSourcePayloadList] = useState([] as DataSourceRecord[]);
    const [newDataSourcePayloadIndex, setNewDataSourcePayloadIndex] = useState(-1);

    const [searchCustomDataTablesResult, searchingCustomDataTables, searchCustomDataTablesError] = services.customDataTablesService.searchCustomDataTables(searchCustomDataTablesPayload as unknown as TPESearchRequest);
    const [searchCustomDataTableRowsResult, searchingCustomDataTableRows, searchCustomDataTableRowsError] = services.customDataTablesService.searchCustomDataTableRows(searchCustomDataTableRowsPayload as unknown as CustomDataTableRowsSearchRequest, CUSTOM_DATA_TABLE_CONSTANTS.CDT_VALUES_SELECTOR_FIELDS, true);
    const [saveDataSourceRecordResponse, isSaving, savingError] = services.dataSourcesService.saveDataSource(customCoaReference, false, unsavedDataSourcePayload, unsavedDataSourcePayloadNeedsSaving);
    
    useEffect(() => {
        if (recordBeingEdited?.customDatasourceTableId != null) {
            setViewMode(true);
        } else {
            initiateEditMode(); 
        }
    }, [])

    useEffect(() => {
        if (startSavingCustomDatasources){
            setUnsavedDataSourcePayload(saveDataSourcePayloadList[0]);
            setNewDataSourcePayloadIndex(1);
            setUnsavedDataSourcePayloadNeedsSaving(new Date().toISOString())
        }
    }, [startSavingCustomDatasources])
    
    useEffect(() => {
        if ( saveDataSourceRecordResponse == null ){
            return;
        }
        if (newDataSourcePayloadIndex !== -1){
            const newPayload: DataSourceRecord = saveDataSourcePayloadList[newDataSourcePayloadIndex];
            setUnsavedDataSourcePayload(newPayload);
            setUnsavedDataSourcePayloadNeedsSaving(new Date().toISOString())
            setSaveDataSourcePayloadList([...saveDataSourcePayloadList]);
            setNewDataSourcePayloadIndex(newDataSourcePayloadIndex+1);

            if (newDataSourcePayloadIndex >= saveDataSourcePayloadList.length){
                setUnsavedDataSourcePayload(undefined);
                setUnsavedDataSourcePayloadNeedsSaving(undefined);        
                setSaveDataSourcePayloadList([]);
                setNewDataSourcePayloadIndex(-1);
                setStartSavingCustomDatasources(false);
                onSubmit();
                dispatch(ACTIONS.SAVE_RECORD.withPayload({ recordID: recordBeingEdited?.datasourceId, updatedRecord: saveDataSourceRecordResponse }));
            }
        }
        else {
            dispatch(ACTIONS.SAVE_RECORD.withPayload({ recordID: recordBeingEdited?.datasourceId, updatedRecord: saveDataSourceRecordResponse }));
        }
    }, [saveDataSourceRecordResponse])

    useEffect(() => {
        if (selectedTable == null || selectedTable.tableId == null) {
            return
        }
        const searchPayload = getCustomDataTableRowsSearchRequest(selectedTable.tableId, true);
        addCalculationNumberFilter(searchPayload, calculation?.calculationNumber || '');
        setSortByPeriod(searchPayload, true);
        setSearchCustomDataTableRowsPayload(searchPayload);
    }, [selectedTable, isEditing])
   
   
    useEffect( () => {
       if (StringUtils.isNullOrEmpty(searchCustomDataTablesError)){
           return;
       }
       setError(`There was a problem loading Custom Data tables: ${searchCustomDataTablesError}. Please try again later`);
   }, [searchCustomDataTablesError]);
   
   useEffect( () => {
       if (StringUtils.isNullOrEmpty(searchCustomDataTableRowsError)){
           return;
       }
       setError(`There was a problem loading Custom Data table rows: ${searchCustomDataTableRowsError}. Please try again later`);
    }, [searchCustomDataTableRowsError]);

    useEffect( () => {
       if (StringUtils.isNullOrEmpty(savingError)){
           return;
       }
       setError(savingError);
    }, [savingError]);

    const goToResultsPage = (pageNumber: number) => {
        if (activeGridStep === DATA_TABLES_GRID_STEP) {
            const searchPayload = setResultPageNumber(searchCustomDataTablesPayload, pageNumber);
            setSearchCustomDataTablesPayload(searchPayload);
        } else {
            const searchPayload = setResultPageNumber(searchCustomDataTableRowsPayload, pageNumber);
            setSearchCustomDataTableRowsPayload(searchPayload);
        }
    }

    const goBack = () => {
        setValuesSelectedCount(0);
        setActiveGridStep(DATA_TABLES_GRID_STEP);
    }

    const onCancelPostProcess = () => {
        setSelectedTable(undefined);
        dispatch(ACTIONS.CANCEL_EDITING);
    }

    const onConfirmClicked = () => {
        setError(null);
        if (activeGridStep === DATA_TABLES_GRID_STEP) {
            if (selectedTable?.tableId == null){
                setError("Please select a table using the leftmost column checkboxes above");
                return;
            }
            setActiveGridStep(DATA_TABLE_ROWS_GRID_STEP);
        }
        else {
            if (recordBeingEdited == null){
                return;
            }

            const mapSorted = [...selectedValuesMap].sort(); 
            
            // There is a value already selected and user didn't change the selected value. Only updating description        
            if (selectedValuesMap.size === 0 && customDatasourceValueId != null) {
                const errorMap = services.dataSourcesService.validateDataSourceDescription(recordBeingEdited.description, dataSourceRecords.map(x => x.description));        
                if (errorMap.size > 0) {
                    dispatch(ACTIONS.SET_ERRORS.withPayload({ recordID: recordBeingEdited.datasourceId, value: errorMap }));
                    return;
                }
                const customPeriod = moment(recordBeingEdited.customPeriod, 'MMM-YYYY').format('MMM/YYYY').toUpperCase();
                saveDataSourcePayloadList.push({...recordBeingEdited, 
                    period: CONSTANTS.DATA_SOURCE_VALUES.CUSTOM_PERIOD, 
                    customPeriod: `${customPeriod} - ${customPeriod}`,
                    dataKeyInput: undefined,
                });
                setStartSavingCustomDatasources(true);
                return;
            }
            const tableName = selectedTable?.tableName || recordBeingEdited.customDatasourceTableName;
            mapSorted.forEach(keyValue => {
                const calculationNumber = keyValue[0].substring(keyValue[0].indexOf(':') + 1);
                const selectedRow = keyValue[1].row;
                const firstSelectedValue = keyValue[1].selection[0];
                const customPeriod = moment(selectedRow.period, 'YYYYMM').format('MMM/YYYY').toUpperCase();
                const updatedDataSource: DataSourceRecord = {...recordBeingEdited,
                    currency: selectedRow.currency,
                    period: CONSTANTS.DATA_SOURCE_VALUES.CUSTOM_PERIOD,
                    customPeriod: `${customPeriod} - ${customPeriod}`,
                    dataKey: calculationNumber,
                    dataKeyInput: undefined,
                    description: recordBeingEdited.description || `${tableName}_${selectedRow.calculationNumber}_${selectedRow.period}_${(searchCustomDataTableRowsResult.rowsInfo.legend as any)[firstSelectedValue.valueKey]}`,
                    showDataKeySelection: false,
                    customDatasourceTableId: selectedRow.tableId,
                    customDatasourceCalculationNumber: selectedRow.calculationNumber,
                    customDatasourceValueId: firstSelectedValue.valueKey,
                    value: '',
                };

                const errorMap = services.dataSourcesService.validateDataSourceDescription(updatedDataSource.description, dataSourceRecords.map(x => x.description));
        
                if (errorMap.size > 0) {
                    dispatch(ACTIONS.SET_ERRORS.withPayload({ recordID: recordBeingEdited.datasourceId, value: errorMap }));
                    return;
                }

                // Populate the list of saveDataSource payloads
                addSaveDatasourcePayloadsToList(saveDataSourcePayloadList, keyValue,updatedDataSource,0, selectedRow, searchCustomDataTableRowsResult.rowsInfo.legend as any, selectedTable);
            });
            dispatch(ACTIONS.CLEAR_ERRORS.withPayload({ recordID: recordBeingEdited.datasourceId }));
            dispatch(ACTIONS.SET_FX_INPUTS);
            setStartSavingCustomDatasources(true);
        }
    }
    
    const onValueSelectedChanged = (row: any, index: number) => {
        const selectedValues: Array<CDTSelectableValue> = [];
        const newMap = new Map(selectedValuesMap);
        Object.keys(row).forEach(k => {
            if (row[k] instanceof CDTSelectableValue){
                const value = row[k] as CDTSelectableValue;
                if (value.isSelected){
                    selectedValues.push(value);
                }
            }
        })
        const paddedIndex = stringUtils.padWithLeadingZeros(index,4);
        // Using the row index in the map key to allow sorting later when creating the datasources
        if (selectedValues.length === 0){
            newMap.delete(`${paddedIndex}:${row.calculationNumber}`);
        }
        else {
            newMap.set(`${paddedIndex}:${row.calculationNumber}`,{row, selection: selectedValues});
        }
        // Updating selection count
        let selectedCount = 0;
        newMap.forEach(keyValue => {            
            selectedCount += keyValue.selection.length;
        });
        setValuesSelectedCount(selectedCount);
        setSelectedValuesMap(newMap);
    }

    const initiateEditMode = () => {
        const searchPayload = getCustomDataTablesSearchRequest(true);
        addCalculationNumberFilter(searchPayload, calculation?.calculationNumber || '');
        setSearchCustomDataTablesPayload(searchPayload);
        setActiveGridStep(DATA_TABLES_GRID_STEP);
    }

    const getSummary = () => {
        const items: KeyValuePair[] = [];
        items.push(new KeyValuePair("Custom Data Table", customDatasourceTableName || ''));
        items.push(new KeyValuePair("Calculation Number", customDatasourceCalculationNumber || ''));
        items.push(new KeyValuePair("Value", customDatasourceValueLegend || ''));
        return items;
    }

    const editFromSummary = () => {
        setViewMode(false);
        initiateEditMode();
    }

    return (
        viewMode ? 
            <DataKeySummaryView 
                header="Selected Custom Data Table value" 
                summaryItems={getSummary()} 
                showEdit={recordBeingEdited?.isEditing || false} 
                onEdit={editFromSummary}/>
        :
            <Container data-class="dataKeyContentContainer"
                footer={
                    <SpaceBetween data-class="dataKeyTableFooter" direction="horizontal" size="s">
                        <CancelEditing onCancelPostProcess={onCancelPostProcess} />
                        {activeGridStep === DATA_TABLES_GRID_STEP ?
                            <React.Fragment>
                                <Button className="smallPrimaryButton confirmCDTBtn" disabled={selectedTable == null} onClick={onConfirmClicked}>Next</Button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Button variant="link" className="smallSecondaryButton backBtn" onClick={goBack}>Back</Button>
                                <Button className="smallPrimaryButton saveDataSourceBtn" disabled={selectedValuesMap.size === 0} onClick={onConfirmClicked}>Save data source</Button>
                            </React.Fragment>
                        }
                    </SpaceBetween>            
                }
            >
                <div className="dataKeyTableHeader">
                    {activeGridStep === DATA_TABLES_GRID_STEP? 
                        <Header
                            variant="h3"
                            counter={'('+(searchingCustomDataTables? '...' : searchCustomDataTablesResult?.totalSize)+')'}
                            actions={searchCustomDataTablesResult != null && searchCustomDataTablesResult.pagesCount > 1 &&
                                    <Pagination
                                        currentPageIndex={searchCustomDataTablesResult.page}
                                        pagesCount={searchCustomDataTablesResult.pagesCount}
                                        disabled={searchCustomDataTablesResult.pagesCount == 0 || searchingCustomDataTables}
                                        onChange={({ detail }) => goToResultsPage(detail.currentPageIndex)}
                                    />
                            }
                        >
                            <span className="datKeyTableHeaderText">Active Custom Data Tables</span>
                        </Header>
                    :
                        <Header
                            variant="h3"
                            actions={searchCustomDataTableRowsResult != null && searchCustomDataTableRowsResult.pagesCount > 1 &&
                                    <Pagination
                                        currentPageIndex={searchCustomDataTableRowsResult.page}
                                        pagesCount={searchCustomDataTableRowsResult.pagesCount}
                                        disabled={searchCustomDataTableRowsResult.pagesCount == 0 || searchingCustomDataTableRows}
                                        onChange={({ detail }) => goToResultsPage(detail.currentPageIndex)}
                                    />
                            }
                        >
                            <span className="datKeyTableHeaderText">Select value from {selectedTable?.tableName}</span>
                        </Header>
                    }
                </div>
                <div className="tableContainer">
                    { activeGridStep === DATA_TABLES_GRID_STEP &&
                        <CDTSelectorGrid 
                            data={searchCustomDataTablesResult?.tables}
                            loading={searchingCustomDataTables}
                            onSelect={setSelectedTable}
                        />
                    }
                    { activeGridStep === DATA_TABLE_ROWS_GRID_STEP &&
                        <CDTRowValuesSelectorGrid 
                            className="cdtRowValuesGrid"
                            data={searchCustomDataTableRowsResult?.rowsInfo.records} 
                            columDefinitions={searchCustomDataTableRowsResult?.rowsInfo.columnDefinitions}
                            showFilters={false}
                            isEditable={isEditing}
                            isSearching={isSaving || searchingCustomDataTables || searchingCustomDataTableRows} 
                            isSearchingMessage={isSaving? 'Saving changes...' : 'Loading table records'}
                            allowMultipleValueSelection={recordBeingEdited?.isNewRecord || false}
                            selectionCount={valuesSelectedCount}
                            onValueSelectedChanged={onValueSelectedChanged}
                            onSortBy={function () {
                                console.log('Function not implemented.');
                            } } 
                            onFilter={function () {
                                console.log('Function not implemented.');
                            } } />
                    }
                    <Alert
                        className='cdtSelectorErrorAlert'
                        onDismiss={() => setError(null)}
                        visible={!StringUtils.isNullOrEmpty(error)}
                        dismissAriaLabel="Close alert"
                        dismissible
                        type="error"
                        >
                        {error}
                    </Alert>
                </div>
            </Container>
    )
}

function addSaveDatasourcePayloadsToList(list: DataSourceRecord[], keyValue: [string, CDTSelection], dataSource: DataSourceRecord, startIndex: number, selectedRow: CustomDataTableRecord, legendMap: any, selectedTable?: CustomTableMetadata){
    for (let i = startIndex; i < keyValue[1].selection.length; i++) {
        const isNewRecord = dataSource.isNewRecord || list.length > 1;
        const newDescription = `${selectedTable?.tableName}_${selectedRow.calculationNumber}_${selectedRow.period}_${legendMap[keyValue[1].selection[i].valueKey]}`;
        dataSource.value = keyValue[1].selection[i].value;
        dataSource.customDatasourceValueId = keyValue[1].selection[i].valueKey;
        dataSource.description = isNewRecord? newDescription : dataSource.description || newDescription,
        list.push({...dataSource, isNewRecord });
    }
}