import StringUtils from "src/utils/stringUtils";

export const FILTERING_OPERATORS_FOR_STRING = [":", "!:", "=", "!="];
export const FILTERING_OPERATORS_FOR_NUMBER = ["=", "!=", ">", "<", ">=", "<="];

export const JEStatusDashboardTableColumns = [
    "calculationNumber",
    "jeStatus",
    "groupNumber",
    "periodId",
    "apttusWorkbookName",
    "journalName",
    "journalCurrency",
    "baseAmount",
    "markupAmount",
    "accountingDate",
    "processedDate",
    "calculationStartDate",
    "calculationEndDate",
    "jeError"
];

export const JE_STATUS_DASHBOARD_TABLE_COLUMN_NAMES: {
    [key: string]: string;
} = Object.freeze({
    CALCULATION_NUMBER_COLUMN: "calculationNumber",
    JE_STATUS_COLUMN: "jeStatus",
    GROUP_NUMBER_COLUMN: "groupNumber",
    PERIOD_ID_COLUMN: "periodId",
    APTTUS_WORKBOOK_NAME_COLUMN: "apttusWorkbookName",
    JOURNAL_NAME_COLUMN: "journalName",
    JOURNAL_CURRENCY_COLUMN: "journalCurrency",
    BASE_AMOUNT_COLUMN: "baseAmount",
    MARKUP_AMOUNT_COLUMN: "markupAmount",
    ACCOUNTING_DATE_COLUMN: "accountingDate",
    PROCESSED_DATE_COLUMN: "processedDate",
    CALCULATION_START_DATE_COLUMN: "calculationStartDate",
    CALCULATION_END_DATE_COLUMN: "calculationEndDate",
    JE_ERROR_COLUMN: "jeError",
});


export const DEFAULT_COLLECTION_PREFERENCES = {
    pageSize: 100,
    visibleContent: JEStatusDashboardTableColumns,
    wrapLines: true,
    stickyColumns: { first: 0, last: 0 }
};

export const VISIBLE_CONTENT_OPTIONS = JEStatusDashboardTableColumns.map((column) => ({
    id: column,
    label: StringUtils.convertCamelCaseToTitleCase(column)
}));

export const PAGE_SIZE_OPTIONS = [
    { value: 100, label: "100 Requests" },
    { value: 300, label: "300 Requests" },
    { value: 500, label: "500 Requests" },
];


