const StringUtils = {
    isNullOrEmpty(s: string | null | undefined) {
        return s == null || s.trim() == '';
    },
    padWithLeadingZeros(num: number, targetLength: number) {
        return String(num).padStart(targetLength, '0');
    },
    convertCamelCaseToTitleCase(text: string) {
        const result = text.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

}

export default StringUtils;