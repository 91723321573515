import { useState, useEffect } from "react";
import WorksheetTotal from "../../../models/tp-allocation/WorksheetTotal";
import apiService from 'src/services/ApiCallService';
import ErrorUtils from "src/utils/ErrorUtils";
import CONSTANTS from "src/utils/constants";

export default class TPAllocationTotalsService {
    saveTotal(payload?: WorksheetTotal): [WorksheetTotal | null, boolean, string] {
        const [result, setResult] = useState(null as WorksheetTotal | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(record: WorksheetTotal) {
                try {
                    setLoading(true);
                    const response = await (record.isNew?
                        apiService.createWorksheetTotal(record.parentEntityId, record) :
                        apiService.updateWorksheetTotal(record.parentEntityId, record));
                    const json = (await response.json()).total;
                    json.unsavedId = record.totalId;
                    json.parentEntityId = json.worksheetId;
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    saveTemplateTotal(payload?: WorksheetTotal): [WorksheetTotal | null, boolean, string] {
        const [result, setResult] = useState(null as WorksheetTotal | null);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData(record: WorksheetTotal) {
                try {
                    setLoading(true);
                    const response = await (record.isNew?
                        apiService.createWorksheetTemplateTotal(record.parentEntityId, record) :
                        apiService.updateWorksheetTemplateTotal(record.parentEntityId, record));
                    const json = (await response.json()).total;
                    json.unsavedId = record.totalId;
                    json.parentEntityId = json.worksheetId;
                    setResult(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (payload != null) {
                postData(payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    createNewTotal(sequence:number, parentEntityId: string) : WorksheetTotal {
        return {
            totalId: `newTotal${sequence}`,
            sequence, 
            parentEntityId, 
            isBeingEdited: true, 
            isEditable: true, 
            isNew: true, 
            expression: '', 
            description: ''} as WorksheetTotal
    }

    getWorksheetTotals(worksheetId?: string, worksheetVersion?: number, needRefreshFlag?: string, executionPeriodId?: string): [WorksheetTotal[], boolean, string] {
        const [result, setResult] = useState([] as WorksheetTotal[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(worksheetId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetTotals(worksheetId, worksheetVersion == null? -1 : worksheetVersion, executionPeriodId);
                    const totals = (await response.json()).totals as any[];
                    totals.forEach( (t,index) => {
                        t.parentEntityId = t.worksheetId;
                        t.sequence = index + 1;
                    })
                    setResult(totals);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (worksheetId != null) {
                fetchData(worksheetId);
            }
            
        }, [worksheetId, needRefreshFlag]);
        return [result, loading, error]
    }

    getWorksheetTemplateTotals(templateId?: string, templateVersion?: number, needRefreshFlag?: string): [WorksheetTotal[], boolean, string] {
        const [result, setResult] = useState([] as WorksheetTotal[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(templateId: string) {
                try {
                    setLoading(true);
                    const response = await apiService.getTPAllocationWorksheetTemplateTotals(templateId, templateVersion == null? -1 : templateVersion);
                    const totals = (await response.json()).totals as any[];
                    totals.forEach( t => {
                        t.parentEntityId = t.templateId;
                    })
                    setResult(totals);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (templateId != null) {
                fetchData(templateId);
            }
        }, [templateId, needRefreshFlag]);
        return [result, loading, error]
    }

    deleteWorksheetTotal(deleteTotalRequest: any) : [WorksheetTotal, boolean, string] {
        const [result, setResult] = useState(null as unknown as WorksheetTotal);
        const [deleting, setDeleting] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(worksheetId:string, totalId: string) {
                try {
                    setDeleting(true);
                    const response = await apiService.deleteWorksheetTotal(worksheetId, totalId);
                    const json = await response.json()
                    setResult(json.total);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setDeleting(false);
                }
            }
            if (deleteTotalRequest != null) {
                fetchData(deleteTotalRequest.parentEntityId, deleteTotalRequest.totalId);
            }
        }, [deleteTotalRequest]);
        return [result, deleting, error]
    }

    deleteTemplateTotal(deleteTotalRequest: any) : [WorksheetTotal, boolean, string] {
        const [result, setResult] = useState(null as unknown as WorksheetTotal);
        const [deleting, setDeleting] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData(templateId:string, totalId: string) {
                try {
                    setDeleting(true);
                    const response = await apiService.deleteWorksheetTemplateTotal(templateId, totalId);
                    const json = await response.json()
                    setResult(json.total);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setDeleting(false);
                }
            }
            if (deleteTotalRequest != null) {
                fetchData(deleteTotalRequest.parentEntityId, deleteTotalRequest.totalId);
            }
        }, [deleteTotalRequest]);
        return [result, deleting, error]
    }

}