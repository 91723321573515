import React, { useEffect, useState } from 'react';
import { Box, SpaceBetween, Modal, FormField, Button, Input, Alert, ColumnLayout, Select } from '@amzn/awsui-components-react/polaris';
import { CalculationTemplateMetaData } from 'src/models/calculation-builder/CalculationTemplate';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import CONSTANTS from 'src/utils/constants';
import { TPELoadingSpinner } from 'src/components/shared/TPELoadingSpinner';
import ArrayUtils from 'src/utils/arrayUtils';
import LinkToTemplate from 'src/models/calculation-builder/LinkToTemplate';


export default function LinkToTemplateModal (props: {
        visible: boolean,
        loading: boolean,
        isLinking: boolean,
        linkError: string,
        onClose: () => void,
        onSubmit: (l: LinkToTemplate) => any,
    }) {
    const { visible, loading, isLinking, linkError, onClose: onModalClosed, onSubmit } = props;

    const { calcBuilderState } = React.useContext(CalculationBuilderContext);
    const { calculation, availableTemplates = [], sevenSegmentLOV = new Map<string, string[]>(), tpLOV } = calcBuilderState;
    const companyCodes = sevenSegmentLOV.get(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI) || [];
    const { currencyCodes } = tpLOV;

    const companyCodeOptions = [{ label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION }, ...companyCodes.map(x => ({ label: x, value: x }))];
    const currencyOptions = [{ label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION }, ...currencyCodes.map(x => ({ label: x, value: x }))];

    const [selectedTemplate, setSelectedTemplate] = useState(null as unknown as any);
    const [selectedProviderCode, setSelectedProviderCode] = useState(null as unknown as any);
    const [selectedRecipientCode, setSelectedRecipientCode] = useState(null as unknown as any);
    const [selectedCurrency, setSelectedCurrency] = useState(null as unknown as any);
    const [templateRequiredError, setTemplateRequiredError] = useState(false);
    const [templateList, setTemplateList] = useState([] as any[]);
    const [showError, setShowError] = useState(false);
    const templateErrorMsg = "Calculation template is required."

    useEffect(() => {
        if (visible) {
            setTemplateRequiredError(false);
            setSelectedTemplate(null);
            setSelectedProviderCode(null);
            setSelectedRecipientCode(null);
            setSelectedCurrency(null);
            setShowError(false);
        }
    }, [visible])

    useEffect(() => {
        if (isLinking) {
            setShowError(true);
        }
    }, [isLinking])

    useEffect(() => {
        setTemplateList([...availableTemplates.map(x => ({ label: x.templateName, value: x.templateId }))])
    }, [availableTemplates]);

    const validateAndSubmit = () => {
        if (selectedTemplate == null) {
            setTemplateRequiredError(true);
            return;
        }
        const l: LinkToTemplate = {
            templateId: selectedTemplate['value'],
            templateName: selectedTemplate['label'],
            calculationNumber: calculation?.calculationNumber as string,
            providerCodeOverride: selectedProviderCode != null ? selectedProviderCode['value'] : undefined,
            recipientCodeOverride: selectedRecipientCode != null ? selectedRecipientCode['value'] : undefined,
            currencyCodeOverride: selectedCurrency != null ? selectedCurrency['value'] : undefined,
        }
        onSubmit(l);
    }

    return <Modal className="linkToTemplateModal" visible={visible}
        header="Link to Calculation Template"
        onDismiss={onModalClosed}
        footer={
            !loading &&
            <Box float="right">
                    <Button variant="link" className="cancelButton" disabled={isLinking} onClick={onModalClosed}>Cancel</Button>
                    <Button variant="primary" className="saveButton" disabled={selectedTemplate == null || isLinking} onClick={validateAndSubmit}>{isLinking ? 'Linking...' : 'Apply'}</Button>
            </Box>
        }>
            <TPELoadingSpinner loading={loading} loadingText="">
                <SpaceBetween direction="vertical" size="m">
                        <FormField  className="templateNameField"
                                    label="Select calculation template*"
                                    description="Link selected template to calculation"
                                    stretch={true}
                                    errorText={templateRequiredError ? templateErrorMsg: null}
                        >
                            <Select className="templateDropdown"
                                selectedOption={selectedTemplate}
                                options={templateList}
                                onChange={({ detail }) => {
                                    setSelectedTemplate(detail.selectedOption);
                                    setTemplateRequiredError(false);
                                }}
                                placeholder="Calculation template"
                                virtualScroll={true}
                                filteringType="auto"
                            />
                        </FormField>
                        <FormField  className="providerCompanyCodeField"
                                    label="Select provider company code"
                                    description="If not provided, this will remain the same as the CLI provider company code" 
                                    stretch={true} 
                                    errorText="">
                            <Select className="providerCompanyCodeDropdown"
                                selectedOption={selectedProviderCode}
                                options={companyCodeOptions}
                                onChange={({ detail }) => {
                                    setSelectedProviderCode(detail.selectedOption.label == CONSTANTS.CLEAR_SELECTION ? null : detail.selectedOption)
                                }}
                                placeholder="Company code"
                                virtualScroll={true}
                                filteringType="auto"
                            />
                        </FormField>
                        <FormField  className="recipientCompanyCodeField"
                                    label="Select recipient company code"
                                    description="If not provided, this will remain the same as the CLI recipient company code"
                                    stretch={true}
                                    errorText="">
                            <Select className="recipientCompanyCodeDropdown"
                                selectedOption={selectedRecipientCode}
                                options={companyCodeOptions}
                                onChange={({ detail }) => {
                                    setSelectedRecipientCode(detail.selectedOption.label == CONSTANTS.CLEAR_SELECTION ? null : detail.selectedOption)
                                }}
                                placeholder="Company code"
                                virtualScroll={true}
                                filteringType="auto"
                            />
                        </FormField>
                        <FormField  className="currencyField"
                                    label="Select currency"
                                    description="If not provided, this will remain the same as the template currency. Note that this is ignored in case of multi-currency template."
                                    stretch={true}
                                    errorText="">
                            <Select className="currencyDropdown"
                                selectedOption={selectedCurrency}
                                options={currencyOptions}
                                onChange={({ detail }) => {
                                    setSelectedCurrency(detail.selectedOption.label == CONSTANTS.CLEAR_SELECTION ? null : detail.selectedOption)
                                }}
                                placeholder="Currency"
                                virtualScroll={true}
                                filteringType="auto"
                            />
                        </FormField>
                        <Alert className="linkModalInfoAlert" type="info">Any existing data sources and/or calculation steps present in this calculation will be deleted on linking to a template.</Alert>
                        <Alert className="linkModalAlert" visible={showError && linkError != null && linkError != ''} type="error">{linkError}</Alert>
                </SpaceBetween>
            </TPELoadingSpinner>
    </Modal>
}