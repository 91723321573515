import React from "react";
import {
  Modal,
  Input,
  Select,
  Button,
  Box,
  FormField,
  SpaceBetween,
  Grid,
  DatePicker,
} from "@amzn/awsui-components-react/polaris";
import TPEAction from "src/models/common/TPEAction";
import { ACTIONS } from "src/services/calculations/CalculationsReducer";
import SavedSearch from "src/models/calculations/SavedSearch";
import {
  MoreOptionsItem,
  MoreOptionsModel,
} from "src/models/calculations/SavedSearchModel";
import { SearchCalculationsState } from "src/services/calculations/SearchCalculationsState";
import CONSTANTS from "src/utils/constants";

type ComponentProps = {
  isVisible: boolean;
  model: MoreOptionsModel;
  savedSearches: SavedSearch[];
  selectedSavedSearch?: SavedSearch;
  atpUsers: any[];
  calculationLineNumber: string[];
  companies: string[];
  onModelChanged: any;
  accountingOwner: any[];
  agreementStatus: string[];
  calculationStatus: string[];
  searchState: SearchCalculationsState;
  dispatch: React.Dispatch<TPEAction>;
};

export default function SearchMoreOptionsModal(props: ComponentProps) {
  const {
    isVisible,
    dispatch,
    model,
    onModelChanged,
    savedSearches,
    selectedSavedSearch,
    atpUsers,
    calculationLineNumber,
    companies,
    accountingOwner,
    agreementStatus,
    calculationStatus,
    searchState,
  } = props;
  const effectiveDateFrom = new Date().toString();
  const [lastUpdated, setLastUpdated] = React.useState("");
  const updateModel = function (fieldName: string, value?: string) {
    onModelChanged({
      ...model,
      [fieldName]: value,
    });
  };

  enum FieldType {
    "FIRST_PARTY" = 0,
    "SECOND_PARTY" = 1,
    "AGREEMENT_STATUS" = 2,
    "CALCULATION_NAME" = 3,
    "CALCULATION_STATUS" = 4,
    "CALCULATION_LINE_NUMBER" = 5,
    "TAX_OWNER" = 6,
    "LAST_UPDATED_ON" = 7,
    "ACCOUNTING_OWNER" = 8,
  }

  const dispathOtherOptionsUpdate = function () {
    const otherOptions: Array<MoreOptionsItem> = [];
    const fieldMap = [
      { key: "taxOwner", value: searchState.selectedTaxOwner },
      { key: "recipientCode", value: searchState.selectedSecondParty },
      { key: "providerCode", value: searchState.selectedFirstParty },
      { key: "lastUpdateDate", value: searchState.selectedLastUpdatedOn },
      {
        key: "calculationStatus",
        value: searchState.selectedCalculationStatus,
      },
      {
        key: "calculationNumber",
        value: searchState.selectedCalculationLineNumber,
      },
      { key: "calculationName", value: searchState.selectedCalculationName },
      { key: "agreementStatus", value: searchState.selectedAgreementStatus },
      { key: "accountingOwner", value: searchState.selectedAccountingOwner },
    ];

    fieldMap.forEach(({ key, value }) => {
      if (value != null && value?.toString().trim().length > 0) {
        otherOptions.push(new MoreOptionsItem(key, value));
        console.log("key ", key, " value", value);
      }
    });
    dispatch(ACTIONS.SET_MORE_OPTIONS.withPayload(otherOptions));
  };

  const hideModal = function () {
    dispatch(ACTIONS.SET_MORE_OPTIONS_VISIBLE.withPayload(false));
  };

  const onSavedSearchSelected = function (detail: any) {
    dispatch(
      ACTIONS.SET_SELECTED_SAVED_SEARCH.withPayload(
        savedSearches.find((x) => x.name === detail.selectedOption.value)
      )
    );
  };
  const onSelectChanged = (e: any, fieldName: FieldType) => {
    let isClearSelection =
      e?.detail?.selectedOption?.value === CONSTANTS.CLEAR_SELECTION;
    switch (fieldName) {
      case FieldType.FIRST_PARTY:
        dispatch(
          ACTIONS.SET_SELECTED_FIRST_PARTY.withPayload(
            isClearSelection
              ? undefined
              : companies.find((x) => x === e.detail.selectedOption.value)
          )
        );
        break;
      case FieldType.SECOND_PARTY:
        dispatch(
          ACTIONS.SET_SELECTED_SECOND_PARTY.withPayload(
            isClearSelection
              ? undefined
              : companies.find((x) => x === e.detail.selectedOption.value)
          )
        );
        break;
      case FieldType.AGREEMENT_STATUS:
        dispatch(
          ACTIONS.SET_SELECTED_AGREEMENT_STATUS.withPayload(
            isClearSelection
              ? undefined
              : agreementStatus.find((x) => x === e.detail.selectedOption.value)
          )
        );
        break;
      case FieldType.CALCULATION_NAME:
        dispatch(ACTIONS.SET_SELECTED_CALCULATION_NAME.withPayload(e));
        break;
      case FieldType.CALCULATION_STATUS:
        dispatch(
          ACTIONS.SET_SELECTED_CALCULATION_STATUS.withPayload(
            isClearSelection
              ? undefined
              : calculationStatus.find(
                  (x) => x === e.detail.selectedOption.value
                )
          )
        );
        break;
      case FieldType.CALCULATION_LINE_NUMBER:
        dispatch(
          ACTIONS.SET_SELECTED_CALCULATION_LINE_NUMBER.withPayload(
            isClearSelection
              ? undefined
              : calculationLineNumber.find(
                  (x) => x === e.detail.selectedOption.value
                )
          )
        );
        break;
      case FieldType.TAX_OWNER:
        dispatch(
          ACTIONS.SET_SELECTED_TAX_OWNER.withPayload(
            isClearSelection
              ? undefined
              : atpUsers.find((x) => x.employeeId === e.detail.selectedOption.value)
          )
        );
        break;
      case FieldType.LAST_UPDATED_ON:
        dispatch(ACTIONS.SET_SELECTED_LAST_UPDATED_ON.withPayload(e));
        break;
      case FieldType.ACCOUNTING_OWNER:
        dispatch(
          ACTIONS.SET_SELECTED_ACCOUNTING_OWNER.withPayload(
            isClearSelection
              ? undefined
              : atpUsers.find((x) => x.employeeId === e.detail.selectedOption.value)
          )
        );
        break;
    }
  };
  const savedSearchesDdOptions =
    savedSearches == null
      ? []
      : savedSearches.map((x) => ({ label: x.name, value: x.name }));

  const agreementStatusOptions = [
    { label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION },
    ...agreementStatus?.map((x: string) => ({ label: x, value: x })),
  ];

  const calculationStatusOptions = [
    { label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION },
    ...calculationStatus?.map((x: string) => ({ label: x, value: x })),
  ];

  const userOptions = [
    { label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION },
    ...atpUsers?.map((x) => ({ label: x.alias, value: x.employeeId })),
  ];

  const calculationLineNumberOptions = [
    { label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION },
    ...calculationLineNumber?.map((x: string) => ({ label: x, value: x })),
  ];

  const companyOptions = [
    { label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION },
    ...companies?.map((x: string) => ({ label: x, value: x })),
  ];

  return (
    <Modal
      className="moreOptionsModal"
      onDismiss={hideModal}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="large"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" className="cancelButton" onClick={hideModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="applyButton"
              onClick={dispathOtherOptionsUpdate}
            >
              Apply
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="More search options"
    >
      <FormField label="Saved searches" stretch={true}>
        <Select
          className="savedSearchesDropdown"
          options={savedSearchesDdOptions}
          selectedOption={
            selectedSavedSearch == null
              ? null
              : {
                  label: selectedSavedSearch.name,
                  value: selectedSavedSearch.name,
                }
          }
          onChange={({ detail }) => onSavedSearchSelected(detail)}
        ></Select>
      </FormField>
      <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
        <SpaceBetween direction="vertical" size="l">
          <FormField label="TP calculation line number">
            <Select
              filteringType="auto"
              className="calculationNumberDropdown"
              placeholder="Select"
              onChange={(e) => onSelectChanged(e, FieldType.CALCULATION_LINE_NUMBER)}
              selectedOption={{
                label: searchState.selectedCalculationLineNumber,
                value: searchState.selectedCalculationLineNumber,
              }}
              options={calculationLineNumberOptions}
            />
          </FormField>
          <FormField label="Calculation name">
            <Input
              className="calculationNameInput"
              value={searchState?.selectedCalculationName || ""}
              onChange={({ detail }) => onSelectChanged(detail.value,FieldType.CALCULATION_NAME)}
            />
          </FormField>
          <FormField label="Agreement status">
            <Select
              filteringType="auto"
              className="agreementStatusDropdown"
              placeholder="Select"
              onChange={(e) => onSelectChanged(e, FieldType.AGREEMENT_STATUS)}
              selectedOption={{
                label: searchState.selectedAgreementStatus,
                value: searchState.selectedCalculationLineNumber,
              }}
              options={agreementStatusOptions}
            />
          </FormField>
          <FormField label="TP calculation status">
            <Select
              filteringType="auto"
              className="calculationStatusDropdown"
              placeholder="Select"
              onChange={(e) => onSelectChanged(e, FieldType.CALCULATION_STATUS)}
              selectedOption={{
                label: searchState.selectedCalculationStatus,
                value: searchState.selectedCalculationStatus,
              }}
              options={calculationStatusOptions}
            />
          </FormField>
          <FormField label="TP accounting owner">
            <Select
              filteringType="auto"
              className="accountingOwnerDropdown"
              placeholder="Select"
              onChange={(e) => onSelectChanged(e, FieldType.ACCOUNTING_OWNER)}
              selectedOption={{
                label: searchState?.selectedAccountingOwner?.alias,
                value: searchState?.selectedAccountingOwner?.employeeId,
              }}
              options={userOptions}
            />
          </FormField>
        </SpaceBetween>
        <SpaceBetween direction="vertical" size="l">
          <FormField label="TP tax owner" stretch={true}>
            <Select
              filteringType="auto"
              className="taxOwnerDropdown"
              placeholder="Select"
              onChange={(e) => onSelectChanged(e, FieldType.TAX_OWNER)}
              selectedOption={{
                label: searchState?.selectedTaxOwner?.alias,
                value: searchState?.selectedTaxOwner?.employeeId,
              }}
              options={userOptions}
            />
          </FormField>
          <FormField label="First party">
            <Select
              filteringType="auto"
              className="firstPartyDropdown"
              placeholder="Select"
              onChange={(e) => onSelectChanged(e, FieldType.FIRST_PARTY)}
              selectedOption={{
                label: searchState.selectedFirstParty,
                value: searchState.selectedFirstParty,
              }}
              options={companyOptions}
            />
          </FormField>
          <FormField label="Second party">
            <Select
              filteringType="auto"
              className="secondPartyDropdown"
              placeholder="Select"
              onChange={(e) => onSelectChanged(e, FieldType.SECOND_PARTY)}
              selectedOption={{
                label: searchState.selectedSecondParty,
                value: searchState.selectedSecondParty,
              }}
              options={companyOptions}
            />
          </FormField>
          <FormField label="Last updated">
            <DatePicker
              className="fullWidthDatePicker effectiveDateFrom"
              onChange={(e)=>onSelectChanged(e.detail.value,FieldType.LAST_UPDATED_ON)}
              value={searchState?.selectedLastUpdatedOn || ""}
              openCalendarAriaLabel={(selectedDate) =>
                "Choose Date" +
                (selectedDate ? `, selected date is ${selectedDate}` : "")
              }
              nextMonthAriaLabel="Next month"
              placeholder="YYYY/MM/DD"
              previousMonthAriaLabel="Previous month"
              todayAriaLabel="Today"
            />
          </FormField>
        </SpaceBetween>
      </Grid>
    </Modal>
  );
}