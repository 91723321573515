import ServiceCollection from "src/services/ServiceCollection";
import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Button,
    ButtonDropdown,
    ColumnLayout,
    Container,
    Pagination,
    SpaceBetween,
    TextFilter
} from "@amzn/awsui-components-react/polaris";
import TablesGrid from "../custom-tables-shared/TablesGrid";
import 'src/components/CustomAppLayout.scss';
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import {CustomCOAState, initialState} from "src/services/custom-coa/CustomCOAState";
import TPENavigator from "../TPENavigator";
import StringUtils from "src/utils/stringUtils";
import CONSTANTS from "src/utils/constants";
import { ACTIONS, customCOAReducer } from "src/services/custom-coa/CustomCOAReducer";
import '../custom-tables-shared/CustomTables.scss';
import TPEAction from "src/models/common/TPEAction";
import FileUploadModal from "./FileUploadModal";
import CreateTableModal from "./CreateTableModal";
import TPESearchRequest from "src/models/common/TPESearchRequest";
import {addTableNameFilter, getCustomCOAsSearchRequest} from "src/components/custom-coa/CustomCOASearchHelper";
import { getPermissions } from "../AppPermissions";
import { AppModules } from "src/models/permissions/RolePermissions";

export type ContextType = {
    state: CustomCOAState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const CustomCOAProvider = (props: any) => {
    const { state, dispatch, services, children } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <CustomCOAContext.Provider value={providerValue}>
            {children}
        </CustomCOAContext.Provider>
    );
}
export const CustomCOAContext = React.createContext(null as unknown as ContextType);

export default function TablesView(props: { services: ServiceCollection }) {
    const { services } = props;
    const [state, dispatch] = useReducerWithLogger(customCOAReducer, initialState);
    const { searchPayload, searchResult } = state;
    const { canEdit } = getPermissions(AppModules.CUSTOM_COA);

    const pagesCount = 1;
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [navigateURL, setNavigateURL] = useState('');
    const [loadTime, setLoadTime] = useState(null as unknown as string);

    const [searchCustomCoaResponse, searchCustomCoaLoading, searchCustomCoaError] = services.customCOAService.searchCustomCOADefinitions(searchPayload);
    const [templateDownloadUrl, templateUrlLoading, templateUrlError] = services.customCOAService.downloadTemplate(loadTime);

    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(templateDownloadUrl)) {
            window.open(templateDownloadUrl as string);
        }
    }, [templateDownloadUrl])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(templateUrlError)) {
            services.messageService.showErrorBanner(templateUrlError);
        }
    }, [templateUrlError])

    useEffect(() => {
        if (searchCustomCoaResponse == null) {
            return;
        }
        dispatch(ACTIONS.SET_SEARCH_RESULT.withPayload(searchCustomCoaResponse));
    }, [searchCustomCoaResponse])

    const renderModalBySelectedOption = (event: any) => {
        switch (event.detail.id) {
            case 'create':
                setShowCreateModal(true);
                break;
            case 'upload':
                setShowUploadModal(true);
                break;
        }
    }

    const navigateToCustomCOADetail = (tableId: string) => {
        setNavigateURL(`${CONSTANTS.PAGE_NAV.CUSTOM_COA_DETAILS.URL}/${btoa(tableId)}`);
    }

    const showUploadedCustomCOADefinition = (tableId: string) => {
        setShowUploadModal(false);
        navigateToCustomCOADetail(tableId);
    }

    const showCreatedCustomCOADefinition = (tableId: string) => {
        setShowCreateModal(false);
        navigateToCustomCOADetail(tableId);
    }

    const searchCOAs = (searchText:string) => {
        const currentSorting = {sortOrder: searchPayload?.sortOrder, sortField: searchPayload?.sortField};
        if (StringUtils.isNullOrEmpty(searchText)){
            dispatch(ACTIONS.RESET_SEARCH.withPayload(currentSorting));
            return;
        }
        let newSearchPayload = {...currentSorting} as TPESearchRequest;

        if (searchText.length < 3) {
            return;
        }
        newSearchPayload = {...newSearchPayload,...getCustomCOAsSearchRequest(false)};
        addTableNameFilter(newSearchPayload, searchText.toLowerCase());

        dispatch(ACTIONS.SET_SEARCH_PAYLOAD.withPayload(newSearchPayload));
    }

    return (
        <CustomCOAProvider services={services} state={state} dispatch={dispatch}>
            <Container data-class="containerWithHeaderAndGrid">
                <div>
                    <div className="customTableTableHeader">
                        <ColumnLayout columns={2} data-class="fullColumnLayout">
                            <Box variant="h2"><span>Custom COA Definitions </span><span className="customTablesRecordCounter">({searchResult?.totalSize})</span></Box>
                            <Box float="right">
                                {canEdit && <SpaceBetween direction="horizontal" size="m">
                                    <Button variant="normal" onClick={() => setLoadTime(new Date().toString())}>Download template</Button>
                                    <ButtonDropdown
                                        items={[
                                            {text: "Create", id: "create"},
                                            {text: "Upload data", id: "upload"},
                                        ]}
                                        variant="primary"
                                        onItemClick={
                                            event => {
                                                renderModalBySelectedOption(event);
                                            }
                                        }
                                    >
                                        Add
                                    </ButtonDropdown>
                                </SpaceBetween> }
                            </Box>
                            <TextFilter data-class="basicTextFilter"
                                        filteringText={searchText}
                                        filteringPlaceholder="Find Custom COA Definition"
                                        filteringAriaLabel="Filter instances"
                                        onChange={({ detail }) => {
                                            setSearchText(detail.filteringText)
                                        }}
                                        onDelayedChange={({ detail }) => {
                                            searchCOAs(searchText);
                                        }
                                        }
                            />
                            {searchResult != null && searchResult.pagesCount > 1 && 
                                <Box float="right">
                                    <Pagination
                                        currentPageIndex={searchResult.page}
                                        pagesCount={searchResult.pagesCount}
                                        disabled={searchResult.pagesCount == 0 || searchCustomCoaLoading}
                                        onChange={({ detail }) => dispatch(ACTIONS.SET_CURRENT_PAGE.withPayload(detail.currentPageIndex))}
                                    />
                                </Box>
                            }
                        </ColumnLayout>
                    </div>
                    <div className="tableContainer">
                        <TablesGrid
                            key="customCoaTablesGrid"
                            loading={searchCustomCoaLoading}
                            customTables={searchResult?.customCOADefinitions || []}
                            tableDetailUrl={CONSTANTS.PAGE_NAV.CUSTOM_COA_DETAILS.URL}
                            coa={true}
                        />
                    </div>
                    <FileUploadModal visible={showUploadModal} onCancel={() => setShowUploadModal(false)} onSubmit={showUploadedCustomCOADefinition}/>
                    <CreateTableModal visible={showCreateModal} onCancel={() => setShowCreateModal(false)} onSubmit={showCreatedCustomCOADefinition}/>
                    <TPENavigator path={navigateURL} />
                </div>
            </Container>
        </CustomCOAProvider>
    );
}
