import React from 'react';
import {ButtonDropdown, Icon, Popover, SpaceBetween, StatusIndicator} from '@amzn/awsui-components-react/polaris';
import {CalcBuilderComment} from 'src/models/calculation-builder/CalcBuilderComment';
import {DataSourceRecord} from 'src/models/common/DataSourceRecord';
import {ACTIONS} from 'src/services/calculation-builder/DataSourcesReducer';
import {format} from 'date-fns';
import CONSTANTS from 'src/utils/constants';
import {ACTIONS as STEP_ACTIONS} from 'src/services/calculation-builder/CalculationStepsReducer';
import DateUtils from 'src/utils/dateUtils';

export enum RowActions {
        EDIT,
        DUPLICATE,
        REMOVE,
        ADD_COMMENT,
        UPDATE_BALANCE,
        STANDARD_ALLOCATION,
        DELETE_STANDARD_ALLOCATION,
        PASS_THROUGH_FLAG
    }

export function ErrorPopover(props: {error: string|Map<string, string>}) {
    const { error } = props;

    let errorStr: any;
    if (error instanceof Map){
        error.forEach((value, key) => errorStr = errorStr + <li>{key}: {value}</li>)
    }
    else {
        errorStr = error;
    }

    const getErrorKeyDisplay = (errorKey: string) => {
        const fieldObj = Object.values(CONSTANTS.DATA_SOURCE_FIELDS).find((x) => x.ACCESSOR == errorKey);
        if (fieldObj != null) {
            return (fieldObj as any)['DISPLAY'] || errorKey;
        } else {
            return errorKey;
        }
    }

    const getErrorDisplay = (errorValue: string | undefined) => {
        if (errorValue == null) {
            return '';
        }
        const errorValArray = errorValue.split(':');
        return errorValArray.length > 0 ? errorValArray[0] : '';
    }
    
    return (
        <div className="stepErrorContainer">
            <Popover
                data-class="basicSmallPopover"
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={
                    error instanceof Map ? <ul>{
                        Array.from(error.keys()).map((x) => <li key={x}>{getErrorKeyDisplay(x)}: {getErrorDisplay(error.get(x))}</li>)
                    }</ul> : errorStr }
            >
                <Icon
                name="status-warning"
                size="small"
                variant="warning"
                />
            </Popover>
        </div>
    )
}

export function RowContextMenu(props: {row: any, allowEditActions: boolean, dispatch: any, recordBeingEdited: DataSourceRecord | undefined, calculationHasTemplate: boolean }) {
    const { row, allowEditActions, dispatch, recordBeingEdited, calculationHasTemplate } = props;
    if (!allowEditActions) {
        return (
            <ButtonDropdown
                className={"rowContextMenu dataSourceRowAction" + row.datasourceId}
                items={[
                    { text: "Add comment", id: String(RowActions.ADD_COMMENT) },
                    { text: "Update value", id: String(RowActions.UPDATE_BALANCE), disabled: row.balancePullInProgress || row.datasource === CONSTANTS.DATA_SOURCE_TYPES.TP_ALLOCATION_WORKSHEET },
                ]}
                onItemClick={({detail}) => {performRowAction(dispatch, recordBeingEdited, row.datasourceId, detail.id); }}
            >
            </ButtonDropdown>
        )
    }
    if (!row.isEditing) {
        const menuItems = [
                    { text: "Edit", id: String(RowActions.EDIT) },
                    { text: "Duplicate", id: String(RowActions.DUPLICATE) },
                    { text: "Add comment", id: String(RowActions.ADD_COMMENT) },
                    { text: "Remove", id: String(RowActions.REMOVE) },
                    { text: "Update value", id: String(RowActions.UPDATE_BALANCE), disabled: row.balancePullInProgress || row.datasource === CONSTANTS.DATA_SOURCE_TYPES.TP_ALLOCATION_WORKSHEET},
                    { text: row.passThroughFlag? "Remove Pass Through" : "Flag As Pass Through", id: String(RowActions.PASS_THROUGH_FLAG)},
                ];
        if (calculationHasTemplate){
            menuItems.push({ text: row.standardAllocation? "View Standard Allocation" : "Set up Standard Allocation", id: String(RowActions.STANDARD_ALLOCATION) });
        }
        
        if (row.standardAllocation) {
            menuItems.push({ text: "Delete Standard Allocation", id: String(RowActions.DELETE_STANDARD_ALLOCATION) });
        }
        return (
            <ButtonDropdown
                className={"rowContextMenu dataSourceRowAction" + row.datasourceId}
                items={menuItems}
                onItemClick={({detail}) => {performRowAction(dispatch, recordBeingEdited, row.datasourceId, detail.id); }}
            >
            </ButtonDropdown>
        )
    } else {
        return (
            <ButtonDropdown
                className={"rowContextMenu dataSourceRowAction" + row.datasourceId}
                items={[
                    { text: "Add comment", id: String(RowActions.ADD_COMMENT) },
                    { text: "Remove", id: String(RowActions.REMOVE) },
                ]}
                onItemClick={({detail}) => { performRowAction(dispatch, recordBeingEdited, row.datasourceId, detail.id); }}
            >
            </ButtonDropdown>
        )
    }
}

export const performRowAction = (dispatch: any, recordBeingEdited: DataSourceRecord | undefined, dataSourceId: string, action: string) => {
    dispatch(ACTIONS.SET_RECORD_ID_FOR_ACTION.withPayload(dataSourceId));
    switch (Number(action)) {
        case RowActions.EDIT: {
            if (recordBeingEdited != null && recordBeingEdited.isEditing) {
                if (recordBeingEdited.datasourceId !== dataSourceId) {
                    dispatch(ACTIONS.SHOW_EDIT_CONFIRM_MODAL);
                } else {
                    return;
                }
            } else {
                editRecord(dispatch, recordBeingEdited);
            }
            break;
        }
        case RowActions.REMOVE: {
            dispatch(ACTIONS.SHOW_DELETE_CONFIRM_MODAL);
            break;
        }
        case RowActions.ADD_COMMENT: {
            dispatch(ACTIONS.SET_NEW_COMMENT_PAYLOAD.withPayload({
                datasourceId: dataSourceId,
                comment: '',
                ownerIsUnsaved: (recordBeingEdited != null && recordBeingEdited.isEditing && recordBeingEdited.datasourceId == dataSourceId) ? recordBeingEdited.isNewRecord : false
            }));
            break;
        }
        case RowActions.DUPLICATE: {
            if (recordBeingEdited != undefined && recordBeingEdited.isEditing) {
                dispatch(ACTIONS.SHOW_DUPLICATE_CONFIRM_MODAL);
            } else {
                duplicateRecord(dispatch, recordBeingEdited);
            }
            break;
        }
        case RowActions.UPDATE_BALANCE: {
            dispatch(ACTIONS.REQUEST_BALANCE_PULL.withPayload({ recordID: dataSourceId, value: true }));
            break;
        }
        case RowActions.STANDARD_ALLOCATION: {
            dispatch(ACTIONS.SHOW_STANDARD_ALLOCATION_MODAL.withPayload(true));
            break;
        }
        case RowActions.DELETE_STANDARD_ALLOCATION: {
            dispatch(ACTIONS.SHOW_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL);
            break;
        }
        case RowActions.PASS_THROUGH_FLAG: {
            dispatch(ACTIONS.TOGGLE_PASS_THROUGH_FLAG);
            break;
        }
    }
}

export const editRecord = (dispatch: any, recordBeingEdited: DataSourceRecord | undefined) => {
    if (recordBeingEdited != undefined && recordBeingEdited.isEditing) {
        dispatch(ACTIONS.CANCEL_EDITING);
    }
    dispatch(ACTIONS.EDIT_RECORD);
}

export const duplicateRecord = (dispatch: any, recordBeingEdited: DataSourceRecord | undefined) => {
    if (recordBeingEdited != undefined && recordBeingEdited.isEditing) {
        dispatch(ACTIONS.CANCEL_EDITING);
    }
    dispatch(ACTIONS.DUPLICATE_RECORD);
}

export const openCommentsModal = (dispatch: any, record: DataSourceRecord, calculationNumber: any, selectedCalculationVersion: any) => {
    dispatch(ACTIONS.SET_NEW_COMMENT_PAYLOAD.withPayload({
        datasourceId: record.datasourceId,
        calculationNumber: calculationNumber,
        calculationVersion: selectedCalculationVersion,
        comment: '',
        ownerIsUnsaved: record.isNewRecord,
    } as CalcBuilderComment));        
}

export const openStandardAllocationModal = (dispatch: any, recordId: String) => {
    dispatch(ACTIONS.SET_RECORD_ID_FOR_ACTION.withPayload(recordId));
    dispatch(ACTIONS.SHOW_STANDARD_ALLOCATION_MODAL.withPayload(true));
    dispatch(STEP_ACTIONS.SET_STEP_ID_FOR_ACTION.withPayload(recordId));
}

/**
 * Utility function to render balance pull status information
 * @param dsRecord The data source record 
 * @returns A loading Polaris StatusIndicator with balance pull status information
 */
export function getBalancePullingStatus(dsRecord: any): React.ReactNode {
    //const text = dsRecord.balancePullingTask?.status.statusType.toLowerCase().replace('_','') || 'requesting';
    //const progress = dsRecord.balancePullingTask?.status.percentage || -1;
    //return <StatusIndicator className="smallStatusIndicator" type="loading">{`${text}... ${progress === -1? '' : progress+'%'}`}</StatusIndicator>
    return <StatusIndicator data-class="smallStatusIndicator" type="loading"/>
}

export function getValueDisplay(value: string, asOfDate: number) {
    if (value != '-' && asOfDate != null) {
        const displayDate = DateUtils.formatTimestamp(asOfDate);
        return <Popover className="smallPopover valuePopover" position="top" size="small" dismissButton={false} content={`Last updated on ${displayDate}`}>{value}</Popover>
    } else {
        return value;
    }
}

export function renderInfo(lastModifiedUser: string, lastModifiedDate: string) {
    return <SpaceBetween direction="vertical" size="xs">
        <div><b>Last updated by: </b><span>{lastModifiedUser}</span></div>
        <div><b>Last updated on: </b><span>{lastModifiedDate}</span></div>
    </SpaceBetween>
}

export function renderPassThroughFlagIcon(record: DataSourceRecord) {
    return record.passThroughFlag ?
        <Popover
            dismissButton={false}
            position="top"
            size="small"
            triggerType="custom"
            content={ "Flagged as Pass Through" }> <span className='blueTextWrapper'><Icon name="flag"/></span> </Popover>: <span>&nbsp;</span>
}