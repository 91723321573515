import { StatusIndicatorProps } from "@amzn/awsui-components-react";
import CONSTANTS from "src/utils/constants";

const EntityStatusUtil = {
     mapToStatusIndicatorBy: function(status?: string) :StatusIndicatorProps.Type {
        switch(status){
            case CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS:
                return "in-progress";
            case CONSTANTS.CALCULATION_STATUS.ACTIVE:
                return "success";
            case CONSTANTS.CALCULATION_STATUS.INACTIVE:
                return "stopped";
            case CONSTANTS.CALCULATION_STATUS.PENDING_REVIEW:
                return "pending";
            case CONSTANTS.CALCULATION_STATUS.TERMINATED:
                return "stopped";
            default:
                return "loading";
        }
    },
    
    getAlertText: function(entity: string, isDryRun?: boolean, executionPeriod?: string, status?: string) {
        if (executionPeriod != null) {
            return `Viewing ${entity} execution${isDryRun? " (dry run)": ""} results for ${executionPeriod}. This page is read only.`;
        }
        if (status === CONSTANTS.CALCULATION_STATUS.PENDING_REVIEW) {
            return `This ${entity} is under review and cannot be modified.`;
        }
        return null;
    }
}

export default EntityStatusUtil;