import React, {  } from 'react';
import { Button } from '@amzn/awsui-components-react/polaris';
import { WizardStep } from '../../models/common/WizardStep';


export default function StepsWizardBar(props: { selectedWizardStep?: WizardStep, wizardSteps: WizardStep[], onStepChanged: (step: WizardStep) => void }) {
    const { onStepChanged, selectedWizardStep, wizardSteps } = props;
    
    return (
        <div className="stepsStripContent">
            <div className="flexGapCalculations">
                {wizardSteps?.map(s => {
                    const stepIsEnabled = s.isEnabled;
                    const stepIsActive = selectedWizardStep != null && s.number == selectedWizardStep.number;
                    return <Button key={s.number}
                        className="stepsStripButton"
                        variant="normal"
                        disabled={!stepIsEnabled}
                        onClick={() => onStepChanged(s)}
                    >Step {s.number}<br /><div className={stepIsEnabled ? (stepIsActive ? "btn-description-active" : "btn-description-blue") : "btn-description"}>{s.name}</div></Button>
                }
                )}
            </div>
        </div>
    );
}