import { WorksheetMode } from "../../models/tp-allocation/TPAllocationEnums";
import { WizardStep } from "../../models/common/WizardStep";
import { Worksheet, WorksheetTemplate } from '../../models/tp-allocation/WorksheetList';
import CONSTANTS from "src/utils/constants";
import { DatasetRecord } from "src/models/tp-allocation/DatasetRecord";
import WorksheetTotal from "src/models/tp-allocation/WorksheetTotal";
import AllocationGroup from "src/models/tp-allocation/AllocationGroup";
import Placeholder from "src/models/tp-allocation/Placeholder";
import { WorksheetValidationError } from "src/models/tp-allocation/ValidateWorksheetResponse";

export interface TPAllocationState {
    isLoading: boolean;
    error?: string;
    worksheetTemplate?: WorksheetTemplate,
    worksheet?: Worksheet,
    originalWorksheetVersion?: number,
    viewMode: string,
    worksheetMode: WorksheetMode,
    wizardSteps: Array<WizardStep>,
    selectedWizardStep?: WizardStep,
    placeholdersMap?: Map<string, Placeholder[]>,
    datasetRecords: DatasetRecord[],
    worksheetTotals: WorksheetTotal[],
    builderState: string,
    sevenSegmentLOVMap: Map<string, string[]>,
    entityDetailsNeedRefresh: boolean,
    totalsNeedRefresh: boolean,
    allocationGroups: AllocationGroup[],
    allocationGroupsNeedRefresh: boolean,
    previousWorksheetExecutionStatus?: string,
    totalsAreValid?: boolean,
    allocationGroupFormulasAreValid?: boolean,
    worksheetNeedsValidation: boolean,
    isValidating: boolean,
    totalValidationErrors: Array<WorksheetValidationError>,
    allocationFormulaValidationErrors: Array<WorksheetValidationError>,
    dataSourcesNeedRefresh: boolean
}

export const initialState :TPAllocationState  = {
    isLoading: false,
    viewMode: CONSTANTS.VIEW_MODE.FROZEN,
    builderState: CONSTANTS.TP_ALLOCATION_BUILDER_STATE.UNKNOWN,
    wizardSteps: [],
    datasetRecords: [],
    worksheetTotals: [],
    allocationGroups: [],
    worksheetMode: WorksheetMode.STANDALONE,
    sevenSegmentLOVMap: new Map(),
    entityDetailsNeedRefresh: false,
    totalsNeedRefresh: false,
    allocationGroupsNeedRefresh: false,
    isValidating: false,
    worksheetNeedsValidation: false,
    totalValidationErrors: [],
    allocationFormulaValidationErrors: [],
    dataSourcesNeedRefresh: false
}

export const getTemplateInitialState = function (): TPAllocationState {
    const steps = [
        {
            number: 1,
            name: "Create placeholders",
            isEnabled: true,
            isSelected: true,
            scrollToId: "wizardStep1",
        },
        {
            number: 2,
            name: "Configure data set",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep2",
        },
        {
            number: 3,
            name: "Configure totals",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep3",
        },
        {
             number: 4,
             name: "Configure allocation groups",
             isEnabled: false,
             isSelected: false,
             scrollToId: "wizardStep4",
         },
    ];
    
    return {...initialState,
        selectedWizardStep: steps[0],
        wizardSteps: steps,
        worksheetMode: WorksheetMode.TEMPLATE
    };
}

export const getStandaloneInitialState = function (): TPAllocationState {
    const steps = [
        {
            number: 1,
            name: "Configure data set",
            isEnabled: true,
            isSelected: true,
            scrollToId: "wizardStep2",
        },
        {
            number: 2,
            name: "Configure totals",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep3",
        },
        {
             number: 3,
             name: "Configure allocation groups",
             isEnabled: false,
             isSelected: false,
             scrollToId: "wizardStep4",
         },
    ];
    
    return {...initialState,
        selectedWizardStep: steps[0],
        wizardSteps: steps,
    };
}

export const getLinkedWorksheetInitialState = function (): TPAllocationState {
    const steps = [
        {
            number: 1,
            name: "Provide placeholder values",
            isEnabled: true,
            isSelected: true,
            scrollToId: "wizardStep1",
        },
        {
            number: 2,
            name: "Configure data set",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep2",
        },
        {
            number: 3,
            name: "Configure totals",
            isEnabled: false,
            isSelected: false,
            scrollToId: "wizardStep3",
        },
        {
             number: 4,
             name: "Configure allocation groups",
             isEnabled: false,
             isSelected: false,
             scrollToId: "wizardStep4",
         },
    ];
    
    return {...initialState,
        selectedWizardStep: steps[0],
        wizardSteps: steps,
        worksheetMode: WorksheetMode.LINKED
    };
}