// to sort list of records on basis of date in descending order
export function sortRecordsOnDate(listOfRecords: any[], dateAttribute: string){
    return listOfRecords.sort((a, b) => {
        const dateA = new Date(`${a[dateAttribute]}`).valueOf();
        const dateB = new Date(`${b[dateAttribute]}`).valueOf();
        if (dateA > dateB) {
            return -1;
        }
        return 1
    });
}
export interface TpTaxUIFilter {
    entity?: string;
    cli?: string;
    periodName?: string;
    accountingDay?:string;

};
export const parseTpTaxUIFilter = (filter: string) => {
    const filterObject: TpTaxUIFilter = {};
    if(filter === 'undefined' || filter === '' || filter === '++') {
        return filterObject
    } 
    else {
        const filterArray = filter.split('+');
        let cliValue = filterArray[1];
        cliValue = cliValue.replace('*','.');     
        filterObject["periodName"] = filterArray[2];
        filterObject["cli"] = cliValue;
        filterObject["entity"] = filterArray[0];
        filterObject["accountingDay"] = filterArray[3];
        return filterObject;
    }
};