// THIS FILE IS TO BE DELETED WHEN ReadOnlyCalculationService.ts HAS API CALLS IMPLEMENTED.
const READ_ONLY_CONSTANTS = Object.freeze({
    AGREEMENT_NUMBERS: ["00008015.1", "00008042.1", "00001651.0"],
    AGREEMENT_TO_CALCULATION_NUMBERS: {
        "00008015.1": ["00008015.1_01_MR-MS"],
        "00008042.1": ["00008042.1_01_2D-MR", "00008042.1_02_2D-MR"],
        "00001651.0": ["00001651.0_01_2D-75"],
    }
});

export default READ_ONLY_CONSTANTS;