import TPEAction from "src/models/common/TPEAction";
import TPESearchRequest from "src/models/common/TPESearchRequest";
import { CustomCOAState, defaultSearchPayload } from "./CustomCOAState";
import {getDefaultSearchPayload} from "src/components/custom-tables-shared/CustomDataTableSearchHelper";

export const ACTIONS = Object.freeze({
    SET_S3_KEY: new TPEAction('SET_S3_KEY'),
    SET_SEARCH_RESULT: new TPEAction('SET_SEARCH_RESULT'),
    SET_SEARCH_PAYLOAD: new TPEAction('SET_SEARCH_PAYLOAD'),
    RESET_SEARCH: new TPEAction('RESET_SEARCH'),
    SET_CURRENT_PAGE: new TPEAction('SET_CURRENT_PAGE'),
});

export function customCOAReducer(state: CustomCOAState, action: TPEAction) : CustomCOAState {
    switch(action.type) {
        case ACTIONS.SET_S3_KEY.type:
            return{
                ...state,
                s3Key: action.payload,
            }
        case ACTIONS.SET_SEARCH_RESULT.type: {
            return {
                ...state,
                searchResult: action.payload,
            }
        }
        case ACTIONS.SET_CURRENT_PAGE.type: {
            const searchPayload: TPESearchRequest = {...state.searchPayload, page: action.payload}
            return {
                ...state,
                searchPayload: searchPayload,
            }
        }
        case ACTIONS.RESET_SEARCH.type: {
            return {
                ...state,
                searchPayload: {...getDefaultSearchPayload(),...action.payload},
            }
        }
        case ACTIONS.SET_SEARCH_PAYLOAD.type: {
            return {
                ...state,
                searchPayload: action.payload
            }
        }
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}