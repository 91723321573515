import TPESearchRequest from "src/models/common/TPESearchRequest"
import { ListReportsResponse} from "src/models/reports/ListReportResponse";
import {FetchReportResponse} from "src/models/reports/FetchReport";

export interface ReportsState {
    error?: string,
    searchPayload: TPESearchRequest,
    searchResult?: ListReportsResponse,
    reportData?: FetchReportResponse
}

const defaultSearchPayload = {
    page: 1,
    pageSize: 20,
}

export const initialState: ReportsState = {
    searchPayload: defaultSearchPayload
}