import React, { useEffect } from 'react';
import {
    FormField,
    Button,
    Modal,
    Box,
    SpaceBetween,
    Textarea,
    Table,
    Alert,
    AlertProps,
} from '@amzn/awsui-components-react/polaris';
import '../../assets/styles/react-table.scss';
import { CalcBuilderComment } from 'src/models/calculation-builder/CalcBuilderComment';
import { TPELoadingSpinner } from '../shared/TPELoadingSpinner';
import TemporaryMessage from 'src/models/common/TemporaryMessage';

type ComponentProps = {
    header: string,
    visible: boolean,
    isSaving: boolean,
    onCancel: () => any,
    onAddComment: (calcBuilderComment: CalcBuilderComment) => any,
    modalMessage?: TemporaryMessage,
    calcNumber: string,
    commentsHistory: Array<CalcBuilderComment>,
}

export default function CommentsModal(props: ComponentProps) {
    const { header, visible, onCancel, onAddComment, calcNumber, commentsHistory, modalMessage, isSaving } = props;
    const [newComment, setNewComment] = React.useState(undefined as unknown as string);
    const [commentsList, setCommentsList] = React.useState(commentsHistory || [])
    const [internalMessage, setInternalMessage] = React.useState(modalMessage);

    useEffect(() =>{
        if (commentsHistory == null){
            return;
        }
        setCommentsList(commentsHistory);
    }, [commentsHistory])
    
    
    const addComment = () => {
        if (newComment == null || newComment === ''){
            setInternalMessage({
                message: "Comment is required",
                type: "error",
                visible: true,
            })
            return;
        }
        setInternalMessage(undefined);
        const request: CalcBuilderComment = {
            calculationNumber: calcNumber,
            comment: newComment,
            createdDate: (new Date()).getTime(),
            ownerIsUnsaved: false, // Actually we don't know. This value should be overwriten by the consumer
        }
        setNewComment('');
        onAddComment(request);
    }

    const cancelModal = () => {        
        onCancel();
    }

    const alertType: AlertProps.Type = internalMessage == null? "error" : internalMessage.type as AlertProps.Type;

    return (
        <Modal
            onDismiss={cancelModal}
            visible={visible}
            closeAriaLabel="Close modal"
            size="large"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" className='cancelButton' onClick={cancelModal}>Cancel</Button>
                        <Button variant="primary" className='addCommentButton' onClick={addComment}>Add comment</Button>
                    </SpaceBetween>
                </Box>
            }
            header={header}
        >
            <FormField label="Comment" stretch>
                <Textarea  className='commentTextarea'
                onChange={({ detail }) => setNewComment(detail.value)}
                value={newComment}
                placeholder="Enter comment here"
                />
            </FormField>
            <TPELoadingSpinner loading={isSaving}>
                <React.Fragment>
                    {commentsList.length > 0 && 
                        <React.Fragment>
                            <br />
                            <br />
                            <FormField
                            stretch
                            label="Comments log"
                            >
                                <Table sortingDisabled className="commentsTable"
                                    columnDefinitions={[
                                        {
                                            id: "variable",
                                            header: "Date",
                                            cell: (item: CalcBuilderComment) => item.createdDate != undefined ? new Date(item.createdDate).toLocaleString() : "-",
                                            sortingField: "name"
                                        },
                                        {
                                            id: "alt",
                                            header: "Author",
                                            cell: item => item.createUser || "-",
                                            sortingField: "alt"
                                        },
                                        {
                                            id: "description",
                                            header: "Message",
                                            cell: item => <div className="text-cell-wrap">{item.comment || "-"}</div>
                                        }
                                    ]}
                                    items={commentsList}
                                />
                            </FormField>
                        </React.Fragment>
                    }
                    <br />
                    <Alert className='messageAlert' visible={internalMessage != null} header={internalMessage?.header} type={alertType}>{internalMessage?.message}</Alert>
                </React.Fragment>
            </TPELoadingSpinner>
        </Modal>
    )
}