import React, {useContext, useMemo} from "react";
import {TPEReactTable} from "src/components/shared/TPEReactTable";
import {PreValidationReportsContext} from "src/components/reports/prevalidation/ReportsTabbedView";
import {FetchReportResponse} from "src/models/reports/FetchReport";
import CONSTANTS from "src/utils/constants";
import {PRE_VALIDATIONS_CONSTANTS} from "src/components/reports/prevalidation/constants";

export default function ReportRecordsGrid(props: { loading: boolean, reportData: FetchReportResponse }) {
    const {loading, reportData} = props;
    const {state} = useContext(PreValidationReportsContext);
    const {selectedTab} = state;

    const [tableRecords, setTableRecords] = React.useState([])

    React.useEffect(() => {
        if (reportData && reportData.payload) {
            setTableRecords(JSON.parse(reportData.payload))
        }
    }, [reportData])

    const getColumnHeaders = () => {
        if (selectedTab == CONSTANTS.REPORT_TYPES.PRE_VALIDATION_COA) {
            return PRE_VALIDATIONS_CONSTANTS.PRE_VALIDATIONS_COA_CHANGES_COLUMN_HEADERS
        } else {
            return PRE_VALIDATIONS_CONSTANTS.PRE_VALIDATIONS_CLI_CDT_PTA_COLUMN_HEADERS
        }
    }

    const columnHeaders = useMemo(getColumnHeaders, [selectedTab]);

    const getColDefs = () => {
        const colDefs: any[] = [];
        columnHeaders.forEach(column => {
            colDefs.push({
                headerClass: `columnWidth${column.WIDTH}`,
                Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                accessor: column.ACCESSOR
            });
        });
        return colDefs;
    }

    return (
        <div className="preValidationReportGrid">
            {reportData && reportData.payload && (<TPEReactTable {...{
                data: tableRecords,
                columnDefinitions: getColDefs(),
                className: "nonGridTable preValidationReportGrid",
                isSearching: loading,
                sortable: false,
                showFilters: false
            }}/>)}
        </div>
    );
}