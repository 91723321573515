import { String } from "aws-sdk/clients/appstream";
import { Journal } from "src/models/calculation-builder/Journal"
import TPEAction from "src/models/common/TPEAction";

export interface JESettingsState {
    journals: Journal[],
    updateJournalsPayload?: Journal[],
    validationErrors: any[],
    needsValidation: boolean,
    journalsAreValid: boolean,
    journalsAreValidInIPE: boolean,
    generateTaxJournals: boolean,
    taxJournalsAvailable: boolean,
    workflowAction?: TPEAction,
    dryRunTrigger?: string,
    lastModifiedUser?: string,
    lastModifiedDate?: string
}

export const initialState: JESettingsState  = {
    journals: [],
    validationErrors: [],
    needsValidation: false,
    journalsAreValid: false,
    journalsAreValidInIPE: false,
    generateTaxJournals: false,
    taxJournalsAvailable: true,
}
