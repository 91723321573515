import CalculationStep from "./CalculationStep";
import { DataSourceRecord } from "../common/DataSourceRecord";

export class CalculationTemplateMetaData {
    calculationNumber?: string;
    templateId?: string;
    templateName: string;
    templateVersion?: number;
    dataSetName: string;
    modelName: string;
    templateEditUser?: string;
    templateEditCalculation?: string;
    statusReason?: string;
    templateCurrency?: string;
    currencyCodeOverride?: string;
    providerCodeOverride?: string;
    recipientCodeOverride?: string;
    submitUser?: string;
    submitDate?: string;
    submitDateDisplay?: string;
    submitComment?: string;
    reviewUser?: string;
    reviewDate?: string;
    reviewDateDisplay?: string;
    reviewComment?: string;
    blockReversalFlag?: boolean;
    suppressNegativeChargeFlag?: boolean;
    lastModifiedUser?: string;
    lastModifiedDate?: string;
    calculationStatus?: string;

    constructor(){
        this.dataSetName = '';
        this.modelName = '';
        this.templateName = '';
    }
}

export interface CalculationTemplate {
    calculationNumber: string,
    createdDate: number,
    createUser: string,
    dataSet: DataSet,
    lastModifiedDate: number,
    model: TPModel,
    templateId: string,
    templateName: string,
    templateVersion: number,
    workbookId: string,
    templateEditUser?: string,
    templateEditCalculation?: string
}

export interface DataSet {
    dataSetName: string,
    datasources: DataSourceRecord[]
}

export interface TPModel {
    modelName: string,
    steps: CalculationStep[]
}