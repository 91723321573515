import React, { useEffect } from 'react';
import ArrayUtils from 'src/utils/arrayUtils';
import StringUtils from 'src/utils/stringUtils';

export default function SimpleUseEffect(props: {useEffectVars: Array<any>, action: (triggerVars:Array<any>) => any}) {
    const { useEffectVars, action } = props;

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(useEffectVars)) {
            return;
        }
        const triggerVars = [];
        for (let i = 0; i < useEffectVars.length; i++) {
            const x = useEffectVars[i];
            if (x == null || (typeof x === 'string' && StringUtils.isNullOrEmpty(x as string))){
                continue;
            }
            triggerVars.push(x);
        }
        if (triggerVars.length === 0){
            return;
        }
        action(triggerVars);
    }, useEffectVars)

    return null;
}