import { Button, Select } from '@amzn/awsui-components-react';
import React from 'react';
import { ACTIONS } from 'src/services/calculation-builder/CalculationStepsReducer';
import 'src/assets/styles/CalculationSteps.scss';
import TPEAction from 'src/models/common/TPEAction';

export default function FormulaExpressionToolbar({ dispatch, showBuiltInFunctions = true, builtInFunctions = []  }: { dispatch: React.Dispatch<TPEAction>, showBuiltInFunctions?:boolean, builtInFunctions?: string[] }) {
    
    const defaultFunctionDdOption = { label: "Functions", value: '' };

    
    const onOperatorButtonClicked = function(text:string) {
      dispatch(ACTIONS.APPEND_TO_FORMULA_EDITOR.withPayload(text));
    }
    return <div className="toolbarContainer">
    <div className="toolbarButtonContainer">
      <div className="toolbarDescription">Operations</div>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked('+')} variant="normal">+</Button>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked('-')} variant="normal">-</Button>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked('/')} variant="normal">/</Button>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked('*')} variant="normal">X</Button>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked('%')} variant="normal">%</Button>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked('<')} variant="normal">&lt;</Button>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked('>')} variant="normal">&gt;</Button>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked('<>')} variant="normal">&lt;&gt;</Button>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked('=')} variant="normal">=</Button>
    </div>

    <div className="toolbarButtonContainer">
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked('\nif (condition, result_if_condition_is_true, result_if_condition_is_false)')} variant="normal">IF</Button>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked(' & ')} variant="normal">AND</Button>
      <Button data-class="toolbarActionButtonPrimary" onClick={() => onOperatorButtonClicked(' | ')} variant="normal">OR</Button>
    </div>
    { showBuiltInFunctions &&
      <div className="toolbarButtonContainer">
          <div className="toolbarFunctions">
            <Select className="dataSourcesButton"
              selectedOption={defaultFunctionDdOption}
              onChange={({ detail }) => {
                onOperatorButtonClicked(detail.selectedOption.value || '');
              }}
              options={builtInFunctions.map(x => ({label: x, value: x}))}
              selectedAriaLabel="Selected"
            />
          </div>
      </div>
    }
  </div>;
}