import { Box, Button, ExpandableSection, Header, SpaceBetween, Toggle } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import TPEAction from 'src/models/common/TPEAction';
import { dataSourcesReducer, ACTIONS } from 'src/services/calculation-builder/DataSourcesReducer';
import { ACTIONS as CALC_BUILDER_ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import { DataSourcesState, initialState } from 'src/services/calculation-builder/DataSourcesState';
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import { TPELoadingSpinner } from '../../shared/TPELoadingSpinner';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import DataSourcesMagicBox from './MagicBox';
import DataSourcesMainGrid from './MainGrid';
import DataSourcesSelectCOA from './SelectCOA';
import ArrayUtils from "src/utils/arrayUtils";
import DataSourcesSplitGrid from './SplitGrid';
import CONSTANTS from 'src/utils/constants';
import { ACTION_TYPE, TPEBasicModal } from '../../shared/TPEBasicModal';
import BalancePuller from './BalancePuller';
import { PullBalanceWSMessage, PullCDTValueMessage } from 'src/models/calculation-builder/PullBalanceWebSocketMessage';
import { CDTSelector } from './CDTSelector';
import { PullBalanceRequestTypes } from 'src/models/calculation-builder/PullBalanceRequest';
import SetupStandardAllocationModal from '../standard-allocation/SetupStandardAllocationModal';
import { TPAllocationWorksheetSelector } from './TPAllocationWorksheetSelector';
import StringUtils from 'src/utils/stringUtils';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import {
    CommonStandardAllocationRecord,
} from "../../../models/common/CommonStandardAllocationInterfaces";
import SaveStandardAllocationRequest
    from "src/models/calculation-builder/standard-allocation/SaveStandardAllocationRequest";
import TPEErrorWatcher from "src/components/shared/TPEErrorWatcher";
import WebSocketApi from 'src/services/WebSocketApi';

export type ContextType = {
    state: DataSourcesState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

const DataSourcesProvider = (props: any) => {
    const { state, dispatch, services, children, calculationBuilderState } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services
    }), [state, dispatch]);
    return (
        <DataSourcesContext.Provider value={providerValue}>
            {children}
        </DataSourcesContext.Provider>
    );
}
export const DataSourcesContext = React.createContext(null as unknown as ContextType);

export default function DataSourcesContainer(props: { expanded?: boolean, onCancel: () => void, onNext: () => void }) {
    const { services, calcBuilderState, calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const { expanded = false, onCancel, onNext } = props;
    const [dataSourcesState, dataSourcesDispatch] = useReducerWithLogger(dataSourcesReducer, initialState);
    const [tableKey, setTableKey] = React.useState(0);
    const [disableUpdateAllBalances, setDisableUpdateAllBalances] = React.useState(false);
    const [loadTime, setLoadTime] = useState('');
    const [commonStandardAllocationRecords, setCommonStandardAllocationRecords] = React.useState([] as CommonStandardAllocationRecord[]);
    const [saveStandardAllocationPayload, setSaveStandardAllocationPayload] = useState(undefined as SaveStandardAllocationRequest | undefined);
    const {
        recordBeingEdited,
        dataSourceRecords,
        showStep3ConfirmModal,
        showUpdateAllBalancesConfirmModal,
        pullBalanceRequestPayloads,
        showCDTSelector,
        customCoaReference = new Map(),
        recordBeingEditedOriginal,
        showDecimals,
        pullGLBalancesWithBreakdowns,
        selectedCustomCoaValues,
        showStandardAllocationModal,
        recordIdForAction,
        recordForActionNeedsSaving,
        pullBalanceRequestCounter,
        recordsCountEligibleForPullingBalance,
        updateAllBalances
    } = dataSourcesState as DataSourcesState;
    const { showDataKeySelection = false } = recordBeingEdited || {};
    const { calculation, selectedCalculationVersion, viewMode, linkedDataSources, linkedCustomCoaReferenceMap, 
        wizardSteps, dataSourcesNeedRefresh, calculationExecution, sevenSegmentLOV, calculationTemplateMetaData } = calcBuilderState;

    useEffect(() => {
        setLoadTime(new Date().toDateString());
        const webSocketListener = setupWebSocketEvents(services, dataSourcesDispatch);
        setTableKey(tableKey + 1);
        return () => {
            // Anything in here is fired on component unmount.
            services.atpWebSocketApiService.removeListener('',webSocketListener);
        }
    }, []);
    const [shouldRefreshDataSources, setShouldRefreshDataSources] = useState(true);
    const [dataSourcesResult, dataSourcesCustomCoaReferenceMap, dataSourcesIsLoading, dataSourcesError] = services.dataSourcesService.getDataSources(shouldRefreshDataSources, calculation?.calculationNumber, calculationExecution?.calculationVersion == null ? selectedCalculationVersion : calculationExecution?.calculationVersion, calculationExecution?.calculationExecutionId);
    const [saveStandardAllocationResult, isSavingStandardAllocation, saveStandardAllocationError] = services.standardAllocationService.saveStandardAllocation(saveStandardAllocationPayload);
    
    const [coaTypes, loadingCoa, errorCoa] = services.dataSourcesService.getCoaTypes(loadTime);

    useEffect(() => {
        if (dataSourcesNeedRefresh) {
            setShouldRefreshDataSources(true);
        }
    }), [dataSourcesNeedRefresh]

    useEffect(() => {
        if (dataSourcesResult == null){
            return;
        }
        if (dataSourcesResult.length === 0 && !dataSourcesIsLoading) {
            dataSourcesDispatch(ACTIONS.ADD_NEW_RECORD.withPayload(calculation?.calculationNumber));
            return;
        }
        setShouldRefreshDataSources(false);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_REFRESH_DATASOURCES_FLAG.withPayload(false));
        dataSourcesDispatch(ACTIONS.SET_RECORDS.withPayload(dataSourcesResult));
    }, [dataSourcesResult, dataSourcesIsLoading]);

    useEffect(() => {
        if (dataSourcesCustomCoaReferenceMap == null) {
            return;
        }
        dataSourcesDispatch(ACTIONS.SET_CUSTOM_COA_REFERENCE.withPayload(dataSourcesCustomCoaReferenceMap));
    }, [dataSourcesCustomCoaReferenceMap])

    useEffect( () => {
        if (ArrayUtils.isNullOrEmpty(coaTypes)) {
            return;
        }
        dataSourcesDispatch(ACTIONS.SET_COA_TYPES.withPayload(coaTypes as string[]))
   }, [coaTypes]);

    useEffect(() => {
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_DATA_SOURCES.withPayload(dataSourceRecords));
    }, [dataSourceRecords]);
    
    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(pullBalanceRequestPayloads)){
            return;
        }
        const currentPayload = pullBalanceRequestPayloads[0];
        if (currentPayload.requestType === PullBalanceRequestTypes.PullGLBalance) {
                services.atpWebSocketApiService.requestBalancePull(currentPayload.payload);
        }
        else if (currentPayload.requestType === PullBalanceRequestTypes.PullCDTValue) {
                services.atpWebSocketApiService.pullCDTValue(currentPayload.payload);
        }
        else {
                console.warn("Unknown request type found for: ",currentPayload);
        }        
        dataSourcesDispatch(ACTIONS.REMOVE_PULL_BALANCE_PAYLOAD);
    }, [pullBalanceRequestPayloads]);

    useEffect(() => {
        if (!disableUpdateAllBalances) {
            dataSourcesDispatch(ACTIONS.CLEAR_ALL_BALANCE_PULL_REQUESTS);
        }
    }, [disableUpdateAllBalances]);
    
    useEffect(() => {
        if (!ArrayUtils.isNullOrEmpty(linkedDataSources)) {
            dataSourcesDispatch(ACTIONS.SET_RECORDS.withPayload(linkedDataSources));
        }
    }, [linkedDataSources]);

    useEffect(() => {
        if (linkedCustomCoaReferenceMap !=  null) {
            dataSourcesDispatch(ACTIONS.SET_CUSTOM_COA_REFERENCE.withPayload(linkedCustomCoaReferenceMap));
        }
    }, [linkedCustomCoaReferenceMap])

    useEffect(() => {
        if (recordBeingEdited?.datasource === CONSTANTS.DATA_SOURCE_TYPES.CUSTOM_DATA_TABLE) {
            dataSourcesDispatch(ACTIONS.SET_SHOW_CDT_SELECTOR.withPayload(true));
        }
    }, [recordBeingEdited?.datasourceId]);

    useEffect(() => {
        let timeout: any;
        if (pullBalanceRequestCounter >= recordsCountEligibleForPullingBalance) {
            dataSourcesDispatch(ACTIONS.HIDE_UPDATE_ALL_BALANCES_CONFIRM_MODAL);
            timeout = setTimeout(() => setDisableUpdateAllBalances(false), CONSTANTS.TIME_TO_DISABLE_UPDATE_ALL_BALANCES);
        }
        return () => clearTimeout(timeout)
    }, [pullBalanceRequestCounter, recordsCountEligibleForPullingBalance]);

    const goToStep3 = () => {
        if (recordBeingEdited != undefined && recordBeingEdited.isEditing) {
            dataSourcesDispatch(ACTIONS.SHOW_STEP3_CONFIRM_MODAL);
        } else {
            dataSourcesDispatch(ACTIONS.CLEAR_SAVED_RECORD_ID);
            onNext();
        }
    }

    const proceedToStep3 = () => {
        dataSourcesDispatch(ACTIONS.HIDE_STEP3_CONFIRM_MODAL);
        dataSourcesDispatch(ACTIONS.CANCEL_EDITING);
        onNext();
    }

    const onUpdateAllBalancesConfirmed = () => {
        setDisableUpdateAllBalances(true);
        // Intentionally setting payload to null to clear out previous payload
        dataSourcesDispatch(ACTIONS.PROCESS_NEXT_BALANCE_PULL_REQUEST.withPayload(null));
    }

    const [recordNeedsSaving, setRecordNeedsSaving] = React.useState(undefined as string | undefined);
    const [saveDataSourceRecordResponse, isSaving, savingError] = services.dataSourcesService.saveDataSource(customCoaReference, false, recordBeingEdited, recordNeedsSaving);
    const [saveActionRecordResponse, isSavingActionRecord, savingActionRecordError] = services.dataSourcesService.saveDataSource(customCoaReference, false,
        dataSourceRecords.find(x => x.datasourceId == recordIdForAction), recordForActionNeedsSaving);
    useEffect(() => {
        if (saveDataSourceRecordResponse == null){
            return;
        }
        if (recordBeingEditedOriginal?.description != recordBeingEdited?.description) {
            calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.withPayload(true));
        }
        dataSourcesDispatch(ACTIONS.SAVE_RECORD.withPayload({ recordID: recordBeingEdited?.datasourceId, updatedRecord: saveDataSourceRecordResponse }));
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [saveDataSourceRecordResponse])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(savingError)) {
            return;
        }
        dataSourcesDispatch(ACTIONS.SET_SAVE_ERROR.withPayload(savingError));
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [savingError])

    useEffect(() => {
        if (saveActionRecordResponse == null){
            return;
        }
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [saveActionRecordResponse])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(savingActionRecordError)) {
            return;
        }
        dataSourcesDispatch(ACTIONS.SET_SAVE_ERROR.withPayload(savingActionRecordError));
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [savingActionRecordError])

    React.useEffect(() => {
        if (recordBeingEdited == null){
            return;
        }
        // This is needed since the saveDatasource service pulls the calculation number from the DS record
        recordBeingEdited.calculationNumber = calculation?.calculationNumber || '???';
    }, [recordBeingEdited])

    useEffect(() => {
        if (dataSourceRecords == null){
            return;
        }
        setCommonStandardAllocationRecords(dataSourceRecords.map(x => ({
            standardAllocation: x.standardAllocation,
            parentId: x.datasourceId,
            description: x.description
        })));
    }, [dataSourceRecords])

    useEffect(() => {
        if (saveStandardAllocationResult == null){
            return;
        }
        dataSourcesDispatch(ACTIONS.SHOW_STANDARD_ALLOCATION_MODAL.withPayload(false))
        const currentDataSource = dataSourceRecords.find(x => x.datasourceId === saveStandardAllocationPayload?.dataSourceId)
        services.messageService.showSuccessAutoDismissBanner(`Standard Allocation has been successfully updated for ${StringUtils.isNullOrEmpty(currentDataSource?.description)? 'the': currentDataSource?.description} datasource.`);
        if(currentDataSource != null) {
            currentDataSource.standardAllocation = saveStandardAllocationResult;
            dataSourcesDispatch(ACTIONS.SET_RECORDS.withPayload([...dataSourceRecords]));
        }

    }, [saveStandardAllocationResult])


    // If the balance pull was initiated for a new data source and save was clicked when the balance pull was in progress,
    // then the record has to be completely saved to save the currency in the DB.
    // This could happen only in case of draft versions.
    // So in this case to update the currency returned by the balance pull, we have to save the data source.
    const [balancePullingRecord,setBalancePullingRecord] = useState(undefined as DataSourceRecord | undefined);
    const [balancePullingRecordNeedsSaving, setBalancePullingRecordNeedsSaving] = React.useState(undefined as string | undefined);
    const [saveBalancePullingRecordResponse] = services.dataSourcesService.saveDataSource(
        customCoaReference, 
        selectedCalculationVersion !== CONSTANTS.CALCULATION_VERSIONS.DRAFT_VERSION && (balancePullingRecord?.requestAutoSave || false), 
        balancePullingRecord, balancePullingRecordNeedsSaving);
    useEffect(() => {
        if ( saveBalancePullingRecordResponse == null ){
            return;
        }
        dataSourcesDispatch(ACTIONS.SAVE_RECORD.withPayload({ recordID: balancePullingRecord?.datasourceId, updatedRecord: saveBalancePullingRecordResponse }));
    }, [saveBalancePullingRecordResponse])

    const triggerBalancePullingRecordSave = function(record: DataSourceRecord){
        setBalancePullingRecord(record);
        setBalancePullingRecordNeedsSaving(new Date().toISOString());
    }

    return (
        <ExpandableSection className="polarisExpandableSection dataSourcesSection" variant="container"
            defaultExpanded={expanded}
            header={
                <Header><h2>Step 2: Configure data sources</h2></Header>
            }
        >
            <DataSourcesProvider services={services} state={dataSourcesState} dispatch={dataSourcesDispatch} calculationBuilderState={calcBuilderState}>
                <TPELoadingSpinner loading={dataSourcesIsLoading}>
                    <div className="calcBuilderContentContainer">
                        <div className="datasourceHeader">
                            <div className="dataSourceSettingsBar">
                                {viewMode != CONSTANTS.VIEW_MODE.FROZEN && <Toggle data-class="dataSourceSettingToggle" onChange={() => dataSourcesDispatch(ACTIONS.TOGGLE_PULL_BALANCE_BREAKDOWNS)} checked={pullGLBalancesWithBreakdowns}>Pull GL balances with breakdowns</Toggle> }
                                <Toggle data-class="dataSourceSettingToggle" onChange={() => dataSourcesDispatch(ACTIONS.TOGGLE_SHOW_DECIMALS)} checked={showDecimals}>Show decimals</Toggle>
                            </div>
                            <DataSourcesMainGrid key={tableKey} />
                        </div>
                        {showDataKeySelection && (recordBeingEdited?.datasource === CONSTANTS.DATA_SOURCE_TYPES.CUSTOM_DATA_TABLE?
                                <Box>
                                    <CDTSelector 
                                        visible={showCDTSelector}
                                        onSubmit={() => {
                                            dataSourcesDispatch(ACTIONS.SET_SHOW_CDT_SELECTOR.withPayload(false))
                                            setShouldRefreshDataSources(true);
                                            calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.withPayload(true));
                                        }}
                                        onClose={() => dataSourcesDispatch(ACTIONS.SET_SHOW_CDT_SELECTOR.withPayload(false))}
                                    ></CDTSelector>
                                </Box>
                                : recordBeingEdited?.datasource === CONSTANTS.DATA_SOURCE_TYPES.TP_ALLOCATION_WORKSHEET ?
                                    <Box>
                                        <TPAllocationWorksheetSelector />
                                    </Box>
                                    :
                                    <div className="dataKeyContainer">
                                        <DataSourcesSelectCOA {...{
                                                allRecordDescriptions:dataSourceRecords.map(x => x.description),
                                                coaTypes,
                                                customCoaReference: customCoaReference,
                                                dispatch:dataSourcesDispatch,
                                                isSaving,
                                                onRecordNeedsSaving: (x:string)=> setRecordNeedsSaving(x),
                                                originalRecordDescription: recordBeingEditedOriginal?.description || '',
                                                recordBeingEdited: {...recordBeingEdited, entityId: recordBeingEdited?.datasourceId || ''},
                                                services,
                                                sevenSegmentLOV,
                                                showDrillDownSelect: true,
                                                templateId: calculationTemplateMetaData?.templateId,
                                                saveButtonLabel: "Save data source"
                                            }}
                                            />
                                        <DataSourcesMagicBox 
                                            key={recordBeingEdited?.datasourceId}
                                            recordBeingEdited={recordBeingEdited} 
                                            services={services} 
                                            dispatch={dataSourcesDispatch} 
                                            ACTIONS={ACTIONS}
                                            showDecimals={showDecimals} 
                                            coaTypes={coaTypes} 
                                            sevenSegmentLOV={sevenSegmentLOV} 
                                            selectedCustomCoaValues={selectedCustomCoaValues} />
                                    </div>
                            )
                        }
                        {recordBeingEdited != undefined && dataSourceRecords.indexOf(recordBeingEdited) != dataSourceRecords.length - 1 && 
                            <div>
                                <DataSourcesSplitGrid key={tableKey} />
                            </div>
                        }
                        <BalancePuller 
                            records={dataSourceRecords} 
                            sevenSegmentLOV={sevenSegmentLOV} 
                            state={dataSourcesState}
                            dispatch={dataSourcesDispatch}
                            onRecordNeedsSaving={triggerBalancePullingRecordSave} />
                        {viewMode != CONSTANTS.VIEW_MODE.FROZEN && 
                            <div className="actionButtonsContainer">
                                <Box float="left">
                                    <SpaceBetween direction="horizontal" size="m">
                                        { viewMode == CONSTANTS.VIEW_MODE.EDITABLE &&
                                            <Button className="subPrimaryButton addDataSourceBtn" disabled={recordBeingEdited != undefined && recordBeingEdited.isEditing} onClick={() => dataSourcesDispatch(ACTIONS.ADD_NEW_RECORD.withPayload(calculation?.calculationNumber))}>Add data source</Button> 
                                        }
                                        <Button className="subPrimaryButton updateAllBalancesButton" disabled={ArrayUtils.isNullOrEmpty(dataSourceRecords) || disableUpdateAllBalances || recordBeingEdited != null} onClick={() => dataSourcesDispatch(ACTIONS.SHOW_UPDATE_ALL_BALANCES_CONFIRM_MODAL)}>Update all values</Button>
                                    </SpaceBetween>
                                </Box>
                                {!wizardSteps[2].isEnabled &&
                                    <Box float="right">
                                        <SpaceBetween direction="horizontal" size="m">
                                            <Button className="dataSourcesSaveButton" variant="primary" onClick={goToStep3}>Next</Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                            </div>
                        }

                        <TPEBasicModal
                            className="dataSourcesStep3ConfirmModal"
                            visible={showStep3ConfirmModal}
                            action={ACTION_TYPE.OK_CANCEL}
                            title="Unsaved changes"
                            onCancel={() => dataSourcesDispatch(ACTIONS.HIDE_STEP3_CONFIRM_MODAL)}
                            onConfirm={proceedToStep3}
                        >
                            You have unsaved changes. Click <b>OK</b> to lose unsaved changes and continue, or <b>Cancel</b> to save changes.
                        </TPEBasicModal>

                        <TPEBasicModal
                            className="dataSourcesUpdateAllBalancesConfirmModal"
                            visible={showUpdateAllBalancesConfirmModal}
                            action={ACTION_TYPE.YES_NO}
                            title="Update all balances"
                            onCancel={() => dataSourcesDispatch(ACTIONS.HIDE_UPDATE_ALL_BALANCES_CONFIRM_MODAL)}
                            onConfirm={() => { onUpdateAllBalancesConfirmed(); }}
                            primaryButtonDisabledLabel={ updateAllBalances && pullBalanceRequestCounter < recordsCountEligibleForPullingBalance? `Queuing ${recordsCountEligibleForPullingBalance} requests...`: undefined}
                        >
                            Are you sure you want to run balance updates for all data sources?
                        </TPEBasicModal>
                        <SetupStandardAllocationModal
                            services={services}
                            showStandardAllocationModal={showStandardAllocationModal}
                            parentId={recordIdForAction}
                            parentRecords={commonStandardAllocationRecords}
                            dispatch={dataSourcesDispatch}
                            onStandardAllocationNeedSaving={(payload) => setSaveStandardAllocationPayload({...payload, stepId: null})}
                            isSaving={isSavingStandardAllocation}
                            ACTIONS={ACTIONS}
                        />
                        <TPEErrorWatcher services={services} errors={[saveStandardAllocationError]} />
                    </div>
                </TPELoadingSpinner>
            </DataSourcesProvider>
        </ExpandableSection>
    )
}

/**
 * Appends a listener for the balance pull WS (Web Socket) messages. It checks the messages and
 * dispatches actions on the dataSource reducer base on status and balance pull data.
 * @param services The list of services
 * @param dataSourcesDispatch The dataSources dispatcher function
 */
function setupWebSocketEvents(services: ServiceCollection, dataSourcesDispatch: React.Dispatch<TPEAction>) {
    const listener = (x:any) => {
        const webSocketMessage = JSON.parse(x);
        switch (webSocketMessage.messageType) {
            case CONSTANTS.WEB_SOCKET_API_ROUTES.PULL_CDT_VALUE: {
                const message = webSocketMessage as PullCDTValueMessage;
                if ( message.status === "SUCCEEDED" ){
                    dataSourcesDispatch(ACTIONS.UPDATE_CDT_VALUE.withPayload(message));
                }
                else {
                    dataSourcesDispatch(ACTIONS.SET_PULL_BALANCE_ERROR.withPayload({ recordID: message.dataSourceId, value: StringUtils.isNullOrEmpty(message.error)? "There was a problem pulling balance": message.error }));
                }
            }
            case CONSTANTS.WEB_SOCKET_API_ROUTES.EXECUTE_CLI: // TODO: Remove this once PullGLBalance response has a messageType set
                break;
            default: // TODO: Change PullGLBalance WebSocket response to include the messageType field (Using this default temporarily)
                const message = webSocketMessage as PullBalanceWSMessage;
                if ( message.status.statusType === "SUCCEEDED" ){
                    const balanceData = message.balance;
                    try {
                        const { glBalances, glBalanceBreakdownKeys } = services.dataSourcesService.convertToGLBalancesModel(balanceData);
                        dataSourcesDispatch(
                            ACTIONS.UPDATE_BALANCES.withPayload(
                                { 
                                    recordID: message.dataSourceId, 
                                    requestID: message.requestId,
                                    totalBalance: balanceData.balance, 
                                    calculatedFxRate: balanceData.fxRate, 
                                    ledgerCurrency: balanceData.ledgerCurrency || '', 
                                    glBalances: glBalances,
                                    glBalanceDrillDownKeys: glBalanceBreakdownKeys
                                }
                            )
                        )
                    }
                    catch(error){
                        console.error(error);
                        dataSourcesDispatch(ACTIONS.UPDATE_DATASOURCE_RECORD_STATUS.withPayload(message));
                        dataSourcesDispatch(ACTIONS.SET_PULL_BALANCE_ERROR.withPayload({ recordID: message.dataSourceId, value: 'There was a problem parsing the results. Please try again later', requestID: message.requestId }));
                    }
                }
                else if ( message.status.statusType === "FAILED" ) {
                    dataSourcesDispatch(ACTIONS.UPDATE_DATASOURCE_RECORD_STATUS.withPayload(message));
                    dataSourcesDispatch(ACTIONS.SET_PULL_BALANCE_ERROR.withPayload({ recordID: message.dataSourceId, value: message.balance.error, requestID: message.requestId }));
                }
                else {
                    dataSourcesDispatch(ACTIONS.UPDATE_DATASOURCE_RECORD_STATUS.withPayload(message));
                }
                break;
        }        
    };
    const errorHandler = (x:any) => {
        services.messageService.showErrorBanner("There was a problem connecting to the web socket API. Please contact technical support.");
        // Adding a little delay before clearing since some request could still be queuing
        setTimeout(() => dataSourcesDispatch(ACTIONS.CLEAR_ALL_BALANCE_PULL_REQUESTS),500);
    }
    services.atpWebSocketApiService.addListener(listener);
    services.atpWebSocketApiService.addKeyListener(WebSocketApi.ConnectionEvent.ON_ERROR, errorHandler);
    return listener;
}
