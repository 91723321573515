import React from 'react';
import {
    Popover,
    Icon,
    StatusIndicator,
    ButtonDropdown
} from '@amzn/awsui-components-react/polaris';
import { DatasetRecord } from 'src/models/tp-allocation/DatasetRecord';
import { ACTIONS } from 'src/services/tp-allocation/data-set/DatasetReducer';
import { format } from 'date-fns';
import CONSTANTS from 'src/utils/constants';

    export enum RowActions {
        EDIT,
        DUPLICATE,
        REMOVE,
        UPDATE_BALANCE,
        TP_ALLOCATION
    }

export function ErrorPopover(props: {error: string|Map<string, string>}) {
    const { error } = props;

    let errorStr: any;
    if (typeof error === 'string') {
        errorStr = error;
    } else {
        error.forEach((value, key) => errorStr = errorStr + <li>{key}: {value}</li>)
    }

    const getErrorKeyDisplay = (errorKey: string) => {
        const fieldObj = Object.values(CONSTANTS.DATA_SOURCE_FIELDS).find((x) => x.ACCESSOR == errorKey);
        if (fieldObj != null) {
            return (fieldObj as any)['DISPLAY'] || errorKey;
        } else {
            return errorKey;
        }
    }

    const getErrorDisplay = (errorValue: string | undefined) => {
        if (errorValue == null) {
            return '';
        }
        const errorValArray = errorValue.split(':');
        return errorValArray.length > 0 ? errorValArray[0] : '';
    }
    
    return (
        <div className="stepErrorContainer">
            <Popover
                className="errorMessagePopover"
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={
                    typeof error === 'string' ? error :
                        <ul>
                            {Array.from(error.keys()).map((x) => <li key={x}>{getErrorKeyDisplay(x)}: {getErrorDisplay(error.get(x))}</li>)}
                        </ul>
                }
            >
                <Icon
                name="status-warning"
                size="small"
                variant="warning"
                />
            </Popover>
        </div>
    )
}

export function RowContextMenu(props: {row: any, allowEditActions: boolean, allowBalancePull?: boolean, dispatch: any, recordBeingEdited: DatasetRecord | undefined}) {
    const { row, dispatch, recordBeingEdited, allowEditActions, allowBalancePull = true } = props;

    const menuItems = row.isEditing? 
        [{ text: "Remove", id: String(RowActions.REMOVE) }]
        : 
        (
            allowEditActions?
            [
                { text: "Edit", id: String(RowActions.EDIT) },
                { text: "Duplicate", id: String(RowActions.DUPLICATE) },
                { text: "Remove", id: String(RowActions.REMOVE) }
            ] : []
        );
        if (!row.isEditing && allowBalancePull && row.datasource !== CONSTANTS.DATA_SOURCE_TYPES.TP_ALLOCATION_WORKSHEET) {
            menuItems.push({ text: "Update value", id: String(RowActions.UPDATE_BALANCE) });
        }
    return (
            <ButtonDropdown
                className={"rowContextMenu dataSourceRowAction" + row.datasourceId}
                items={menuItems}
                onItemClick={({detail}) => {performRowAction(dispatch, recordBeingEdited, row.datasourceId, detail.id); }}
            >
            </ButtonDropdown>
        )
    }

export const performRowAction = (dispatch: any, recordBeingEdited: DatasetRecord | undefined, dataSourceId: string, action: string) => {
    dispatch(ACTIONS.SET_RECORD_ID_FOR_ACTION.withPayload(dataSourceId));
    switch (Number(action)) {
        case RowActions.EDIT: {
            if (recordBeingEdited != null && recordBeingEdited.isEditing) {
                if (recordBeingEdited.datasourceId !== dataSourceId) {
                    dispatch(ACTIONS.SHOW_EDIT_CONFIRM_MODAL);
                } else {
                    return;
                }
            } else {
                editRecord(dispatch, recordBeingEdited);
            }
            break;
        }
        case RowActions.REMOVE: {
            dispatch(ACTIONS.SHOW_DELETE_CONFIRM_MODAL);
            break;
        }
        case RowActions.DUPLICATE: {
            if (recordBeingEdited != undefined && recordBeingEdited.isEditing) {
                dispatch(ACTIONS.SHOW_DUPLICATE_CONFIRM_MODAL);
            } else {
                duplicateRecord(dispatch, recordBeingEdited);
            }
            break;
        }
        case RowActions.UPDATE_BALANCE: {
            dispatch(ACTIONS.REQUEST_BALANCE_PULL.withPayload({ recordID: dataSourceId, value: true }));
            break;
        }
    }
}

export const editRecord = (dispatch: any, recordBeingEdited: DatasetRecord | undefined) => {
    if (recordBeingEdited != undefined && recordBeingEdited.isEditing) {
        dispatch(ACTIONS.CANCEL_EDITING);
    }
    dispatch(ACTIONS.EDIT_RECORD);
}

export const duplicateRecord = (dispatch: any, recordBeingEdited: DatasetRecord | undefined) => {
    if (recordBeingEdited != undefined && recordBeingEdited.isEditing) {
        dispatch(ACTIONS.CANCEL_EDITING);
    }
    dispatch(ACTIONS.DUPLICATE_RECORD);
}


/**
 * Utility function to render balance pull status information
 * @param dsRecord The data source record 
 * @returns A loading Polaris StatusIndicator with balance pull status information
 */
export function getBalancePullingStatus(dsRecord: any): React.ReactNode {
    //const text = dsRecord.balancePullingTask?.status.statusType.toLowerCase().replace('_','') || 'requesting';
    //const progress = dsRecord.balancePullingTask?.status.percentage || -1;
    //return <StatusIndicator className="smallStatusIndicator" type="loading">{`${text}... ${progress === -1? '' : progress+'%'}`}</StatusIndicator>
    return <StatusIndicator data-class="smallStatusIndicator" type="loading"/>
}

export function getValueDisplay(value: string, asOfDate: number) {
    if (value != '-' && asOfDate != null) {
        const displayDate = format(new Date(asOfDate), "yyyy/MM/dd hh:mm b");
        return <Popover className="smallPopover valuePopover" position="top" size="small" dismissButton={false} content={`Last updated on ${displayDate}`}>{value}</Popover>
    } else {
        return value;
    }
}