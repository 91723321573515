import React, { useEffect, useState } from 'react';
import {
    Toggle,
    Select,
    Button,
    Input,
    DatePicker,
    StatusIndicator,
    Link,
    Box,
} from '@amzn/awsui-components-react/polaris';
import 'src/assets/styles/react-table.scss';
import { TPEReactTable } from 'src/components/shared/TPEReactTable';
import CONSTANTS from 'src/utils/constants';
import { ACTION_TYPE, TPEBasicModal } from 'src/components/shared/TPEBasicModal';
import { duplicateRecord, editRecord, ErrorPopover, getBalancePullingStatus, getValueDisplay, RowContextMenu } from './DatasetGridCommon';
import DataKeyModal from 'src/components/shared/DataKeyModal';
import StringUtils from 'src/utils/stringUtils';
import { DatasetContext } from './DatasetContainer';
import { ACTIONS } from "src/services/tp-allocation/data-set/DatasetReducer";
import { ACTIONS as TP_ALLOCATION_ACTIONS } from "src/services/tp-allocation/TPAllocationReducer";
import { DatasetRecord } from 'src/models/tp-allocation/DatasetRecord';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import { TPAllocationContext } from 'src/services/tp-allocation/TPAllocationContext';
import { WorksheetMode } from 'src/models/tp-allocation/TPAllocationEnums';
import SimpleUseEffect from 'src/components/shared/SimpleUseEffect';
import { CustomPeriodModal } from 'src/components/calculation-builder/data-sources/CustomPeriodModal';

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: { id },
    onRowUpdated, // This is a custom function that we supplied to our table instance
    editorsData, // Editors data contains data needed to render the editors
    onCellUpdated, // pass a onPeriodUpdated into editable cell.
}: { value: any, initialValue: any, row: any, column: any, onRowUpdated: any, editorsData: any, onCellUpdated: any }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const [dataKeyButtonClass, setDataKeyButtonClass] = React.useState('dataSourcesButtonPrimary');
    const errorMap = (original.errors as Map<string, string>) || new Map<string, string>();

    const onChange = (e: any) => {
        setValue(e.detail.value);
    }

    const onSelectChanged = (e: any) => {
        if (e.detail.selectedOption.value == CONSTANTS.CLEAR_SELECTION) {
            setValue(undefined);
            onRowUpdated(index, id, undefined);
        } else {
            setValue(e.detail.selectedOption.value)
            onRowUpdated(index, id, e.detail.selectedOption.value);
        }
    }

    const onPeriodSelectChanged = (e: any) => {
        const period = e.detail.selectedOption.value
        onCellUpdated(index, id, period)
    }
    // We'll only update the external data when the input is blurred
    // Removing this for dropdowns as state needs to be updated immediately after changing for pull balance validation
    const onBlur = () => {
        onRowUpdated(index, id, value)
    }

    const selectButton = () => {
        setDataKeyButtonClass('dataSourcesButtonPrimarySelected');
        onRowUpdated(index, id, value);
    }

    const getInvalidClass = () => {
        return errorMap.has(id) ? 'dataSourcesInvalid' : '';
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const getDataSourceTypeEditor = (defaultSelectedOption: any | null) => {
        // Enabling General Ledger
        const dataSourceTypeOptions = editorsData.dataSourceTypes
            .filter((x: string) => x == CONSTANTS.DATA_SOURCE_TYPES.GENERAL_LEDGER)
            .map((x: string) => ({ label: x, value: x }));
        return <Select 
                key={`${id}_${index}`} className={"dataSourcesButton " + id + "Editor " + getInvalidClass()} 
                invalid={true} placeholder="Select" 
                onChange={onSelectChanged} 
                selectedOption={defaultSelectedOption} 
                options={dataSourceTypeOptions} />;
    }

    if (original.isEditing) {
        const defaultSelectedOption = value == null ? null : { label: value, value };

        switch (id) {
            case CONSTANTS.DATA_SOURCE_FIELDS.DATA_SOURCE.ACCESSOR:
                return getDataSourceTypeEditor(defaultSelectedOption);
            case CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR:
                const periodsOptions = editorsData.periods.map((x: string) => ({ label: x, value: x }));
                return <Select key={`${id}_${index}`} className={"dataSourcesButton " + id + "Editor " + getInvalidClass()} placeholder={original.customPeriod || "Select"} onChange={onPeriodSelectChanged} selectedOption={defaultSelectedOption} options={periodsOptions} />
            case CONSTANTS.DATA_SOURCE_FIELDS.FX_TYPE.ACCESSOR:
                const fxTypeOptions = [{ label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION }, ...editorsData.currencyConversionTypes.map((x: string) => ({ label: x, value: x }))];
                return <Select key={`${id}_${index}`} filteringType="auto" className={"dataSourcesButton " + id + "Editor " + getInvalidClass()} placeholder="Select" onChange={onSelectChanged} selectedOption={defaultSelectedOption} options={fxTypeOptions} />
            case CONSTANTS.DATA_SOURCE_FIELDS.CURRENCY.ACCESSOR:
                const currencyOptions = [{ label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION }, ...editorsData.currencyCodes.map((x: string) => ({ label: x, value: x }))];
                return <Select key={`${id}_${index}`} filteringType="auto" className={"dataSourcesButton " + id + "Editor " + getInvalidClass()} placeholder="Select" onChange={onSelectChanged} selectedOption={defaultSelectedOption} options={currencyOptions} />
            case CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR:
                return <div key={`${id}_${index}`} className={dataKeyButtonClass}><Button className="showCOA" onClick={selectButton}>{value || 'Select and configure COA data'}</Button></div>
            case `${CONSTANTS.DATA_SOURCE_FIELDS.VALUE.ACCESSOR}Formatted`:
                return (
                    original.balancePullInProgress ?
                    <div key={`${id}_${index}`} className={`textCell ${id}Cell ${id}Cell_${index}`}>{getBalancePullingStatus(original)}</div>
                    :
                    <div key={`${id}_${index}`} className={`textCell ${id}Cell ${id}Cell_${index}`}>{getValueDisplay(value, original.lastBalancePullDate)}</div>
                )
            case CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR:
                return (
                        <DatePicker key={`${id}_${index}`} className={"dataSourcesDatePicker " + getInvalidClass()}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            openCalendarAriaLabel={selectedDate =>
                                "Choose Date" +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                )
            case `${CONSTANTS.DATA_SOURCE_FIELDS.FX_RATE.ACCESSOR}Formatted`:
                return  <div key={`${id}_${index}`} className={"textCell " + id + "Cell"}>{value}</div>
            case 'sequence':
                return <div key={`sequence_${index}`} className="textCell sequenceCell">{value}</div>
        }
        return <Input key={`${id}_${index}`} className={"dataSourcesButton " + id + "Editor" + original.datasourceId + " " + getInvalidClass()} placeholder="Enter value" value={value} onChange={onChange} onBlur={onBlur} />
    }
    if (id == `${CONSTANTS.DATA_SOURCE_FIELDS.VALUE.ACCESSOR}Formatted`) {
        if (original.balancePullInProgress) {
            return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{getBalancePullingStatus(original)}</div>
        } else {
            return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{getValueDisplay(value, original.lastBalancePullDate)}</div>
        }
    } else if (id == CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR && original.enableDataKeyView) {
        return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}><Link className={"cell-text dataKeyViewLink" + original.datasourceId} onFollow={() => onRowUpdated(index, id, 'view')}>{value}</Link></div>
    } else if (id == CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR && original.customPeriod != null) {
        return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{original.customPeriod}</div>;
    } else if (id == CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR && value != null) {
        return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{value.split('-').join('/')}</div>;
    }
    return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{value}</div>;
}

// Set our editable cell renderer as the default Cell renderer
const defaultCellEditor = {
    Cell: EditableCell,
}

export default function DatasetMainGrid() {
    const { tpAllocationState, tpAllocationDispatch } = React.useContext(TPAllocationContext);
    const { services, datasetState, datasetDispatch, } = React.useContext(DatasetContext);
    const { datasetRecords,
            recordBeingEdited,
            recordIdForAction,
            showDecimals,
            showEditActionConfirmModal,
            showDuplicateActionConfirmModal,
            showDeleteActionConfirmModal,
            savedRecordId,
            tpLOV,         
        } = datasetState;
    const { viewMode, worksheetMode, worksheetTemplate, worksheet } = tpAllocationState;
    const [formattedRecords, setFormattedRecords] = useState([] as DatasetRecord[]);
    

    const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false);
    const [deleteDatasetItemRequest, setDeleteDatasetItemRequest] = useState(null as any);
    const [showDataKeyModal, setShowDataKeyModal] = useState(false);
    const isTemplate = worksheetMode === WorksheetMode.TEMPLATE;
    const parentEntityId = isTemplate ? worksheetTemplate?.templateId : worksheet?.worksheetId;
    const parentEntityVersion = isTemplate ? worksheetTemplate?.templateVersion : worksheet?.worksheetVersion;

    const [deleteDataSourceResult, deleteDataSourceLoading, deleteDataSourceError] = isTemplate? 
        services.tpAllocationDatasetService.deleteTemplateDatasetItem(deleteDatasetItemRequest) : 
        services.tpAllocationDatasetService.deleteDatasetItem(deleteDatasetItemRequest);

    

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const onRecordUpdated = (rowIndex: number, columnId: any, value: any) => {
        if (columnId == CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR) {
            if (value == 'view') {
                if (datasetRecords.length > rowIndex) {
                    datasetDispatch(ACTIONS.TOGGLE_DATA_KEY_VIEW.withPayload({recordID: datasetRecords[rowIndex].datasourceId}));
                }
            } else {
                datasetDispatch(ACTIONS.SHOW_DATA_KEY_SELECTION);
            }
        } else {
            datasetDispatch(ACTIONS.UPDATE_RECORD_FIELD.withPayload({ rowIndex, fieldName: columnId, value }));
        }
    }

    const onPeriodUpdated = (rowIndex: number, columnId: any, value: any) => {
        if (columnId == CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR && value == CONSTANTS.DATA_SOURCE_VALUES.CUSTOM_PERIOD) {
            setShowCustomPeriodModal(true);
        
        } else {
            datasetDispatch(ACTIONS.UPDATE_RECORD_FIELD.withPayload({ rowIndex, fieldName: columnId, value }));
        }
    }

    useEffect(() => {
        if (datasetRecords == null) {
            return;
        }

        const records = recordBeingEdited == undefined ? datasetRecords : datasetRecords.slice(0, datasetRecords.indexOf(recordBeingEdited) + 1);
        setFormattedRecords(records.map((x, index) => {
            return {
                ...x,
                sequence: index + 1,
                fxRateFormatted: x.fxRate == null ? '-' : services.formattingService.formatString(x.fxRate, true), // Always show decimals for FX rate
                valueFormatted: x.value == null ? '-' : services.formattingService.formatString(String(x.value), showDecimals),
                showSavedIndicator: savedRecordId == x.datasourceId,
                enableDataKeyView: recordBeingEdited == null || (recordBeingEdited != null && !recordBeingEdited.isEditing),
            };
        }));
    }, [datasetRecords, showDecimals, viewMode]);

    useEffect(() => {
        if (viewMode == CONSTANTS.VIEW_MODE.EDITABLE){
            return;
        }
        datasetDispatch(ACTIONS.CANCEL_EDITING)
    }, [viewMode])

    React.useEffect(() => {
        if (deleteDataSourceResult == null) {
            return;
        }
        datasetDispatch(ACTIONS.DELETE_RECORD);
        setDeleteDatasetItemRequest(null);
        //TODO: calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [deleteDataSourceResult])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(deleteDataSourceError)) {
            return;
        }
        services.messageService.showErrorBanner(deleteDataSourceError)
        setDeleteDatasetItemRequest(null);
        //TODO: calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [deleteDataSourceError])

    const setCustomPeriod = (customPeriodStart: string, customPeriodEnd: string) => {
        setShowCustomPeriodModal(false);
        const customPeriod = customPeriodStart + " - " + customPeriodEnd
        if (recordBeingEdited != null) {
            datasetDispatch(ACTIONS.UPDATE_RECORD_FIELD.withPayload({ rowIndex: recordBeingEdited.datasourceId, fieldName: CONSTANTS.DATA_SOURCE_FIELDS.CUSTOM_PERIOD.ACCESSOR, value: customPeriod }));
        }
    };
    
    const processRecord = () => {
        if (showEditActionConfirmModal) {
            datasetDispatch(ACTIONS.HIDE_EDIT_CONFIRM_MODAL);
            editRecord(datasetDispatch, recordBeingEdited);
        } else if (showDuplicateActionConfirmModal) {
            datasetDispatch(ACTIONS.HIDE_DUPLICATE_CONFIRM_MODAL);
            duplicateRecord(datasetDispatch, recordBeingEdited);
        }
    }

    const deleteRecordConfirmed = () => {
        datasetDispatch(ACTIONS.HIDE_DELETE_CONFIRM_MODAL);
        if (recordBeingEdited != null && recordBeingEdited.datasourceId == recordIdForAction && recordBeingEdited.isNewRecord) {
            datasetDispatch(ACTIONS.DELETE_RECORD);
        } else {
            setDeleteDatasetItemRequest({parentEntityId, datasourceId: recordIdForAction});
        }
    }

    
    const columnDefinitions = React.useMemo(() => [
        {
            accessor: 'sequence',
            Header: (e: any) => <div className="cell-text-non-sortable">#</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DATA_SOURCE.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">Data source*</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">Period*</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">FX date</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.FX_TYPE.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">FX type</div>,
        },
        {
            accessor: `${CONSTANTS.DATA_SOURCE_FIELDS.FX_RATE.ACCESSOR}Formatted`,
            Header: (e: any) => <div className="cell-text-non-sortable">FX rate</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.CURRENCY.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">Currency</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">Data key* <Link className="dataKeyViewAllLink cell-link" onFollow={() => setShowDataKeyModal(true)}>View All</Link></div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DESCRIPTION.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">Description*</div>,
        },
        {
            accessor: `${CONSTANTS.DATA_SOURCE_FIELDS.VALUE.ACCESSOR}Formatted`,
            Header: (e: any) => <div className="cell-text-non-sortable">Value</div>,
        },
        {
            accessor: "actions",
            Header: (e: any) => <div className="headerToggle"><Toggle className="showDecimalsToggle" onChange={() => datasetDispatch(ACTIONS.TOGGLE_SHOW_DECIMALS)} checked={showDecimals}><div className="cell-text-toggle">Show decimals</div></Toggle></div>,
            Cell: (e: any) => <div className="rowActions">
                { 
                    e.row.original.pullBalanceError != null || e.row.original.saveError != null || (e.row.original.errors != null && (e.row.original.errors as Map<string, string>).size > 0) ? 
                        <ErrorPopover error={e.row.original.pullBalanceError || e.row.original.saveError || e.row.original.errors} />
                    :
                    (e.row.original.showSavedIndicator ? 
                        <StatusIndicator data-class="smallStatusIndicator" type="success">Saved</StatusIndicator>
                        : 
                        <span>&nbsp;</span> 
                    )
                }
                { CONSTANTS.VIEW_MODE.FROZEN !== viewMode && 
                    <div className='contextMenuPosition'>
                        <RowContextMenu row={e.row.original} allowEditActions={CONSTANTS.VIEW_MODE.EDITABLE === viewMode} allowBalancePull={!isTemplate} dispatch={datasetDispatch} recordBeingEdited={recordBeingEdited} />
                    </div> 
                }
            </div>
        }
    ], [showDecimals, recordBeingEdited, datasetRecords, viewMode]);

    return (
        <div className="dataSourceTableContainer">
            <TPEReactTable {...{
                data: formattedRecords,
                columnDefinitions,
                className: "dataSources",
                sortable: false,
                emptyMessage: "No records found",
                editableOptions: {
                    defaultColumn: defaultCellEditor,
                    onRowUpdated: onRecordUpdated,
                    onCellUpdated: onPeriodUpdated,
                    editorsData: tpLOV,
                    skipPageReset: true,
                }
            }} />

            
            <TPEBasicModal
                className="dataSourceStopEditConfirmModal"
                visible={showEditActionConfirmModal || showDuplicateActionConfirmModal}
                action={ACTION_TYPE.YES_NO}
                title="Cancel editing"
                onCancel={() => { datasetDispatch(ACTIONS.HIDE_EDIT_CONFIRM_MODAL); datasetDispatch(ACTIONS.HIDE_DUPLICATE_CONFIRM_MODAL) }}
                onConfirm={processRecord}
            >
                Are you sure you want to stop editing your current dataset item?
            </TPEBasicModal>

            <TPEBasicModal
                className="dataSourceDeleteConfirmModal"
                visible={showDeleteActionConfirmModal}
                action={ACTION_TYPE.YES_NO}
                title="Delete dataset item"
                onCancel={() => datasetDispatch(ACTIONS.HIDE_DELETE_CONFIRM_MODAL)}
                onConfirm={deleteRecordConfirmed}
            >
                Are you sure you want to delete this dataset item?
            </TPEBasicModal>
            <CustomPeriodModal
                visible={showCustomPeriodModal}
                onClose={() => setShowCustomPeriodModal(false)}
                onSubmit={setCustomPeriod}
                recordBeingEdited={recordBeingEdited || {} as DatasetRecord}
            />
            <DataKeyModal 
                visible={showDataKeyModal} 
                onClose={() => setShowDataKeyModal(false)} 
                dataSourceRecords={datasetRecords.map(x => ({currency: x.currency, customPeriod: x.customPeriod, dataKey: x.dataKey, datasource: x.datasource, datasourceId: x.datasourceId, description: x.description, fxDate: x.fxDate, fxRate: x.fxRate, fxType: x.fxType, period: x.period, value: x.value, dataKeyInput: x.dataKeyInput} as DataSourceRecord))} 
                recordBeingEdited={{} as DataSourceRecord}
                dataSourcesAreEditable={CONSTANTS.VIEW_MODE.EDITABLE === viewMode}
                showGLOnly={true} />
            <SimpleUseEffect 
                useEffectVars={[deleteDataSourceResult, deleteDataSourceError]} 
                action={() => tpAllocationDispatch(TP_ALLOCATION_ACTIONS.CHECK_AND_REFRESH_ENTITY_DETAILS)}
            />
        </div>
    );
}