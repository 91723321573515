import { Alert, Box, Button, ButtonDropdown, ExpandableSection, Header, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { TPELoadingSpinner } from '../../shared/TPELoadingSpinner';
import 'src/assets/styles/CalculationSteps.scss';
import './text-area-autocomplete.css';

import TPEAction from 'src/models/common/TPEAction';
import ServiceCollection from 'src/services/ServiceCollection';
import { CalculationStepsState, initialState } from '../../../services/calculation-builder/CalculationStepsState';
import CalculationStepsGrid from './CalculationStepsGrid';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import { ACTIONS, calculationStepsReducer } from 'src/services/calculation-builder/CalculationStepsReducer';
import { ACTIONS as CALC_BUILDER_ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import { UserProfile } from 'src/models/users/UserProfile';
import { CalculationBuilderState } from 'src/services/calculation-builder/CalculationBuilderState';
import CONSTANTS from 'src/utils/constants';
import { ACTION_TYPE, TPEBasicModal } from 'src/components/shared/TPEBasicModal';
import ArrayUtils from 'src/utils/arrayUtils';
import { ExpressionPartTypes } from 'src/models/calculations/CalculationDetailsResult';
import StringUtils from 'src/utils/stringUtils';
import SetupStandardAllocationModal
    from "src/components/calculation-builder/standard-allocation/SetupStandardAllocationModal";
import {CommonStandardAllocationRecord} from "../../../models/common/CommonStandardAllocationInterfaces";
import SaveStandardAllocationRequest
    from "src/models/calculation-builder/standard-allocation/SaveStandardAllocationRequest";
import TPEErrorWatcher from "src/components/shared/TPEErrorWatcher";

export type ContextType = {
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    state: CalculationStepsState,
    calcBuilderState: CalculationBuilderState,
    userProfile: UserProfile,
}

const StepsProvider = (props: any) => {
    const { state, dispatch, services, userProfile, children, calcBuilderState } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services, userProfile, calcBuilderState
    }), [state, dispatch]);
    return (
        <StepsContext.Provider value={providerValue}>
            {children}
        </StepsContext.Provider>
    );
}
export const StepsContext = React.createContext(null as unknown as ContextType);

type InvalidRunModalPayload = {
    dataSourcesInProgress: string [],
    dataSourcesWithNoBalance: string [],
}

export default function StepsContainer(props: { expanded?: boolean, onCancel: () => void, onSave: (e: any) => void }) {
    const { services, userProfile, calcBuilderState, calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const [state, dispatch] = useReducerWithLogger(calculationStepsReducer, initialState);
    const { expanded = true, onCancel, onSave } = props;

    const [stepsNeedRefresh, setStepsNeedRefresh] = useState(true);
    const [stepsNeedValidation, setStepsNeedValidation] = useState(false);
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const [invalidRunModalPayload, setInvalidRunModalPayload] = useState(null as InvalidRunModalPayload | null);
    const [stepsNeedCancelling, setStepsNeedCancelling] = useState(undefined as string | undefined);
    const [stepsNeedRunning, setStepsNeedRunning] = useState(undefined as string | undefined);
    const [generateJournals, setGenerateJournals] = useState(false);
    const [stepsLevelError, setStepsLevelError] = useState(null as string | null);
    const [saveStandardAllocationPayload, setSaveStandardAllocationPayload] = useState(undefined as SaveStandardAllocationRequest | undefined);
    const { canAddNewStep, steps, stepsAreSavedAndValid, updateStepPayload, deleteStepPayload } = state;
    const { calculationVersions, selectedCalculationVersion, calculation, hasSavedStagingVersion, dataSourceRecords,
            isEditingTemplate, validateCalculationSteps, linkedCalcSteps, wizardSteps, calcStepsNeedRefresh, viewMode, calculationExecution } = calcBuilderState;
    const [calcStepsResult, calcStepsIsLoading, calcStepsError] = services.calculationStepsService.getCalculationSteps(calculation?.calculationNumber, calculationExecution?.calculationVersion == null ? selectedCalculationVersion : calculationExecution?.calculationVersion, stepsNeedRefresh, calculationExecution?.calculationExecutionId)
    const [validateStepsResult, calcStepsIsValidating, validateStepsError] = services.calculationStepsService.validateCalculationSteps(calculation?.calculationNumber, stepsNeedValidation)
    const [cancelStepsResult, calcStepsIsCanceling, cancelStepsError] = services.calculationStepsService.cancelStepsChanges(calculation?.calculationNumber, stepsNeedCancelling)
    const [runStepsResult, calcStepsIsRunning, runStepsError] = services.calculationStepsService.runCalSteps(calculation?.calculationNumber, selectedCalculationVersion, generateJournals, stepsNeedRunning)
    const [saveStepResult, isSavingStep, savingStepError] = services.calculationStepsService.saveCalculationStep(updateStepPayload);
    const [deleteStepResult, isDeletingStep, deletingStepError] = services.calculationStepsService.deleteCalculationStep(deleteStepPayload);
    const [tpEntriesResult, tpEntriesLoading, tpEntriesError] = services.calculationStepsService.getTPEntries(calculation, stepsNeedRefresh);
    const [saveStandardAllocationResult, isSavingStandardAllocation, saveStandardAllocationError] = services.standardAllocationService.saveStandardAllocation(saveStandardAllocationPayload);
    const [commonStandardAllocationRecords, setCommonStandardAllocationRecords] = React.useState([] as CommonStandardAllocationRecord[]);

    useEffect( () => {
        if (ArrayUtils.isNullOrEmpty(tpEntriesResult)) {
            return;
        }
        dispatch(ACTIONS.SET_TPE_ENTRIES.withPayload(tpEntriesResult));
    }, [tpEntriesResult]);
    
    useEffect(() => {
        if (calcStepsResult == null){
            return;
        }
        dispatch(ACTIONS.SET_CALCULATION_STEPS.withPayload(calcStepsResult));
        if (!calcStepsIsLoading && calcStepsResult.length === 0){
            addNewStepHandler(1);
        }
        setStepsNeedRefresh(false);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.withPayload(false));       
    }, [calcStepsResult, calcStepsIsLoading])

    useEffect(() => {
        if (calcStepsNeedRefresh) {
            setStepsNeedRefresh(true);
        }
    }, [calcStepsNeedRefresh])

    useEffect(() => {
        if (validateCalculationSteps) {
            setStepsNeedValidation(true);
        }
    }, [validateCalculationSteps])

    useEffect(() => {
        if (validateStepsResult == null){
            return;
        }
        setStepsNeedValidation(false);
        dispatch(ACTIONS.SET_CALCULATION_STEPS.withPayload(validateStepsResult.steps));
        dispatch(ACTIONS.SET_STEPS_ARE_SAVED_AND_VALID.withPayload(validateStepsResult.valid === true));
        if (validateCalculationSteps) {
            calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_ARE_CALC_STEPS_VALID.withPayload(validateStepsResult.valid == true));
        } else {
            //calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_CALCULATION_VERSIONS_NEED_REFRESH.withPayload(true));
        }
        const calculationLevelErrors:any[] = validateStepsResult.calculationLevelErrors || [];
        if (calculationLevelErrors.length > 0) {
            setStepsLevelError(calculationLevelErrors.map(x => `${x.message}`).join(" "));
        }
    }, [validateStepsResult])

    useEffect(() => {
        if (deleteStepResult == null){
            return;
        }              
        setStepsNeedRefresh(true);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [deleteStepResult])
    
    
    useEffect(() => {
        //If stepsNeedRefresh flag is on then we want the useEffect for calcStepsResult to handle updating the calculation steps
        if (ArrayUtils.isNullOrEmpty(cancelStepsResult) || stepsNeedRefresh){
            return;
        }
        dispatch(ACTIONS.SET_CALCULATION_STEPS.withPayload(cancelStepsResult));
        //calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_CALCULATION_VERSIONS_NEED_REFRESH.withPayload(true));
    }, [cancelStepsResult, stepsNeedRefresh])
    
    
    useEffect(() => {
        if (saveStepResult == null){
            return;
        }
        dispatch(ACTIONS.REFRESH_STEP.withPayload(saveStepResult));
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [saveStepResult])
    
    
    useEffect(() => {
        if (updateStepPayload == null){
            return;
        }
        setStepsLevelError(null);
    }, [updateStepPayload])

    useEffect(() => {
        if (runStepsResult == null){
            return;
        }
        setStepsNeedRunning(undefined);
        dispatch(ACTIONS.SET_RUN_VALUES.withPayload(runStepsResult));
        if (generateJournals) {
            calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
            if (wizardSteps[3].isEnabled) {
                calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_REFRESH_JOURNALS_FLAG.withPayload(true));
            }
            calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(wizardSteps[3]));
        }
    }, [runStepsResult])

    useEffect(() => {
        const allErrors = (deletingStepError || savingStepError || calcStepsError || cancelStepsError || runStepsError || validateStepsError);
        if (allErrors == null || allErrors === ''){
            setStepsLevelError(null);
            return;            
        }
        setStepsNeedRefresh(false); 
        setStepsNeedValidation(false);
        setStepsLevelError(allErrors);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.withPayload(false));
    },[deletingStepError,savingStepError,calcStepsError, runStepsError, validateStepsError])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(deletingStepError)) {
            return;
        }
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [deletingStepError])

    useEffect((() => {
        if (StringUtils.isNullOrEmpty(runStepsError)) {
            return;
        }
        if (generateJournals) {
            calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
        }
    }), [runStepsError])

    useEffect((() => {
        if (StringUtils.isNullOrEmpty(savingStepError)) {
            return;
        }
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }), [savingStepError])

    useEffect(() => {
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_CALCULATION_STEPS.withPayload(steps));
    }, [steps]);

    useEffect(() => {
        if (viewMode != CONSTANTS.VIEW_MODE.EDITABLE){
            dispatch(ACTIONS.SET_STEP_BEING_EDITED.withPayload(null));
        }
        else {
            dispatch(ACTIONS.SET_CALCULATION_STEPS.withPayload([...steps]));
        }
    }, [viewMode]);

    useEffect(() => {
        if (!ArrayUtils.isNullOrEmpty(linkedCalcSteps)) {
            dispatch(ACTIONS.SET_CALCULATION_STEPS.withPayload(linkedCalcSteps));
        }
    }, [linkedCalcSteps]);

    React.useEffect(() => {
        if (steps == null){
            return;
        }
        setCommonStandardAllocationRecords(steps.map(x => ({
            standardAllocation: x.standardAllocation,
            parentId: x.stepId,
            description: x.stepName
        })));
    }, [steps])

    useEffect(() => {
        if (saveStandardAllocationResult == null){
            return;
        }
        dispatch(ACTIONS.SHOW_STANDARD_ALLOCATION_MODAL.withPayload(false))
        const currentStep = steps.find(x => x.stepId === saveStandardAllocationPayload?.stepId)
        services.messageService.showSuccessAutoDismissBanner(`Standard Allocation has been successfully updated for ${StringUtils.isNullOrEmpty(currentStep?.stepName)? 'the': currentStep?.stepName} step.`);
        if (currentStep != null) {
            currentStep.standardAllocation = saveStandardAllocationResult;
            dispatch(ACTIONS.SET_CALCULATION_STEPS.withPayload([...steps]));
        }

    }, [saveStandardAllocationResult])

    const addNewStepHandler = function(sequence?: number){
        const newStep = services.calculationStepsService.createNewCalculationStep(sequence != null ? sequence : steps.length + 1, calculation?.calculationNumber || '', false);
        dispatch(ACTIONS.ADD_STEP.withPayload(newStep))
    }

    const onSaveHandler = function(e:any) {
        onSave(e);
        dispatch(ACTIONS.EXIT_EDIT_MODE)
        setStepsLevelError(null);
        setStepsNeedValidation(true);        
    }

    const onCancelHandler = function(){
        onCancel();
        setStepsLevelError(null);
        setShowConfirmCancel(true);        
    }

    const onRunHandler = function(generateJournals: boolean) {
        setInvalidRunModalPayload(null);
        const invalidDataSources = dataSourceRecords.filter(x => x.balancePullInProgress || x.value == null);
        if (!ArrayUtils.isNullOrEmpty(invalidDataSources)) {        
            const invalidRunModalPayload: InvalidRunModalPayload = {dataSourcesInProgress: [], dataSourcesWithNoBalance: []};
            steps.forEach(s => {
                s.expressionTokens
                    .filter(t => t.type === ExpressionPartTypes.DATA_SOURCE)
                    .forEach(t => {

                        if (invalidDataSources.some(x => x.description === t.value && x.balancePullInProgress)){
                            if (!invalidRunModalPayload.dataSourcesInProgress.includes(t.value)) {
                                invalidRunModalPayload.dataSourcesInProgress.push(t.value);
                            }
                        }
                        else if (invalidDataSources.some(x => x.description === t.value && x.value == null)){
                            if (!invalidRunModalPayload.dataSourcesWithNoBalance.includes(t.value)) {
                                invalidRunModalPayload.dataSourcesWithNoBalance.push(t.value);
                            }
                        }
                    });
            })
            if (!ArrayUtils.isNullOrEmpty(invalidRunModalPayload.dataSourcesInProgress) || !ArrayUtils.isNullOrEmpty(invalidRunModalPayload.dataSourcesWithNoBalance)) {
                setInvalidRunModalPayload(invalidRunModalPayload);
                return;        
            }
        }
        setGenerateJournals(generateJournals);
        setStepsNeedRunning(new Date().toISOString());
    }

    const selectCalculationVersion = function(e:any){
        e.stopPropagation();
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_SELECTED_CALCULATION_VERSION.withPayload(parseInt(e.detail.id)))
    }

    const configureJournals = () => {
        onRunHandler(true);
    }

    return (
        <ExpandableSection className="polarisExpandableSection calculationStepsSection" variant="container"
            defaultExpanded={expanded} 
            header={
                <Header>
                    <h2>Step 3: Configure calculation steps</h2>
                </Header>
            }
        >
            <TPELoadingSpinner loading={calcStepsIsLoading}>
                <StepsProvider services={services} state={state} dispatch={dispatch} userProfile={userProfile} calcBuilderState={calcBuilderState}>
                    <div className="calcBuilderContentContainer">
                        <CalculationStepsGrid />
                        {stepsLevelError != null && <>
                            <br />
                            <div className="dismissibleAlertContainer">
                                <span>&nbsp;</span>
                                <Alert
                                className="dismissibleAlert"
                                dismissible
                                onDismiss={() => setStepsLevelError(null)}
                                visible={stepsLevelError != null}
                                dismissAriaLabel="Close alert"
                                type="error"
                                >
                                {stepsLevelError}
                                </Alert>
                                <span>&nbsp;</span>
                            </div>
                        </>
                        }
                        {viewMode != CONSTANTS.VIEW_MODE.FROZEN &&
                            <div className="actionButtonsContainer">
                                <Box float="left">
                                    {viewMode == CONSTANTS.VIEW_MODE.EDITABLE && <Button className="subPrimaryButton" disabled={!canAddNewStep} onClick={() => addNewStepHandler()}>Add new step</Button>}
                                </Box>
                                <Box float="right">
                                    <SpaceBetween direction="horizontal" size="m">
                                        {   
                                            //This cancel button should only be visible if this calculation is not linked to a template
                                            viewMode == CONSTANTS.VIEW_MODE.EDITABLE && !isEditingTemplate &&
                                            <Button onClick={onCancelHandler} loading={calcStepsIsCanceling} className="cancelButton">
                                                {calcStepsIsCanceling? "Cancelling" : "Cancel"}
                                            </Button>
                                        }
                                        <Button onClick={() => onRunHandler(false)} loading={calcStepsIsRunning} className="runButton">
                                            {calcStepsIsRunning? "Running" : "Run"}
                                        </Button>
                                        {   viewMode == CONSTANTS.VIEW_MODE.EDITABLE &&
                                            <Button
                                            loading={(!validateCalculationSteps && calcStepsIsValidating) || isSavingStep}
                                            className={`saveCalculationStepsButton ${stepsAreSavedAndValid && !calcStepsIsValidating? 'button-valid' : 'button-invalid'}`} 
                                            onClick={onSaveHandler}
                                            iconName={stepsAreSavedAndValid? "status-positive" : undefined}>
                                                {(!validateCalculationSteps && calcStepsIsValidating) || isSavingStep? "Saving" : (stepsAreSavedAndValid? "Saved" : "Save")}
                                            </Button>
                                        }
                                        {viewMode != CONSTANTS.VIEW_MODE.READ_ONLY && viewMode != CONSTANTS.VIEW_MODE.FROZEN && 
                                            <Button variant="primary" disabled={calcStepsIsRunning} onClick={configureJournals}>Configure journal entries</Button>
                                        }
                                    </SpaceBetween>
                                </Box>
                            </div>
                        }
                        <TPEBasicModal
                            className="calcStepsCancelEditConfirmModal"
                            visible={showConfirmCancel}
                            action={ACTION_TYPE.YES_NO}
                            title="Cancel Calculation Steps Confirmation"
                            onCancel={() => setShowConfirmCancel(false)}
                            onConfirm={() => { setShowConfirmCancel(false); setStepsNeedCancelling(new Date().toISOString()); }}
                        >
                            {hasSavedStagingVersion? 
                                <span>By cancelling, the configuration will be reverted back to the last saved status. Do you wish to cancel?</span> :
                                <span>There is no previous saved version stored. <StatusIndicator type="warning">Cancelling will delete ALL CALCULATION STEPS!</StatusIndicator><br /><br />Are you sure you want to cancel?</span>
                            }
                        </TPEBasicModal>
                    </div>
                </StepsProvider>
            </TPELoadingSpinner>
            <TPEBasicModal
                className="calcStepInvalidRunModal"
                visible={invalidRunModalPayload != null}
                action={ACTION_TYPE.OK_ONLY}
                title="Cannot run calculation"
                onCancel={() => setInvalidRunModalPayload(null)}
                onConfirm={() => setInvalidRunModalPayload(null)}
            >
                <div>
                    {ArrayUtils.isNullOrEmpty(invalidRunModalPayload?.dataSourcesInProgress)? null : 
                        <span className='inProgressSpan'>
                            Balance updates are in-progress for following data source(s):
                            <ul>{invalidRunModalPayload?.dataSourcesInProgress.map(x => <li key={x}>{x}</li>)}</ul>
                        </span>
                    }
                    {ArrayUtils.isNullOrEmpty(invalidRunModalPayload?.dataSourcesWithNoBalance)? null : 
                        <span className='noBalanceSpan'>
                            Balance updates are needed for the following data source(s):
                            <ul>{invalidRunModalPayload?.dataSourcesWithNoBalance.map(x => <li key={x}>{x}</li>)}</ul>
                        </span>
                    }
                </div>
            </TPEBasicModal>
            <SetupStandardAllocationModal
                services={services}
                showStandardAllocationModal={state.showStandardAllocationModal}
                parentId={state.stepIdForAction}
                parentRecords={commonStandardAllocationRecords}
                dispatch={dispatch}
                onStandardAllocationNeedSaving={(payload) => setSaveStandardAllocationPayload({...payload, dataSourceId: null})}
                isSaving={isSavingStandardAllocation}
                ACTIONS={ACTIONS}
            />
            <TPEErrorWatcher services={services} errors={[saveStandardAllocationError]} />
        </ExpandableSection>
    )

}

