import {TaxAuditRecordDetailsResponse} from "src/models/reports/TaxAuditRecordDetails";
import {Box, Container, Header} from "@amzn/awsui-components-react";
import {TPELoadingSpinner} from "src/components/shared/TPELoadingSpinner";
import React from "react";
import ServiceCollection from "src/services/ServiceCollection";

function ValueWithLabel(props: any) {
    const { label } = props;
    let child;

    if (typeof props.children === 'boolean') {
        child = props.children.toString().toUpperCase();
    } else {
        child = props.children;
    }

    return (
        <div>
            <Box margin={{ bottom: 'xxxs' }} color="text-label">
                {label}
            </Box>
            <div>{child}</div>
        </div>
    );
}

export default function TaxAuditRecordTransactionDetails(props: { taxAuditRecordDetails: TaxAuditRecordDetailsResponse, loading: boolean, services: ServiceCollection}) {
    const {taxAuditRecordDetails, loading, services} = props;

    function formatAttributeValue(attributeValue: string): string {
        return !attributeValue ? attributeValue : services.formattingService.formatString(attributeValue, true, 5);
    }

    return <Container
        header={
            <Header variant="h2">Transaction Details</Header>
        }>
        <div className="polaris-content-container-tar-report-details">
            <TPELoadingSpinner loading={loading}>
                {taxAuditRecordDetails != null &&
                    <React.Fragment>
                        <div className="grid-item"><ValueWithLabel label="Indirect Tax Amount">{formatAttributeValue(taxAuditRecordDetails.indirectTaxAmount)}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Indirect Tax Rate">{taxAuditRecordDetails.indirectTaxRate}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Tax Type">{taxAuditRecordDetails.taxType}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Driver Amount">{formatAttributeValue(taxAuditRecordDetails.driverAmount)}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Intercompany Reverse Charge">{taxAuditRecordDetails.interCompanyReverseCharge}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Intracompany Reverse Charge">{taxAuditRecordDetails.intraCompanyReverseCharge}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Intercompany Indicator">{taxAuditRecordDetails.interCompanyIndicator}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Intracompany Indicator">{taxAuditRecordDetails.intraCompanyReverseCharge}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Reverse Charge Indicator">{taxAuditRecordDetails.reverseChargeIndicator}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Domestic Indicator">{taxAuditRecordDetails.domesticIndicator}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Cross Border Indicator">{taxAuditRecordDetails.crossBorderIndicator}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Related Party Exemption Indicator">{taxAuditRecordDetails.relatedPartyExemptionIndicator}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Place of Supply">{taxAuditRecordDetails.placeOfSupply}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Provider Foreign Recovery Allowed">{taxAuditRecordDetails.providerForeignRecoveryAllowed}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Factor Z Applicable">{taxAuditRecordDetails.factorZApplicable}</ValueWithLabel></div>
                        <div className="grid-item"><ValueWithLabel label="Factor Z">{taxAuditRecordDetails.factorZ}</ValueWithLabel></div>
                    </React.Fragment>
                }
            </TPELoadingSpinner>
        </div>
    </Container>
}