import React, { useEffect, useState } from 'react';
import {
    Select,
    Button,
    Input,
    DatePicker,
    StatusIndicator,
    Link,
    Popover,
} from '@amzn/awsui-components-react/polaris';
import 'src/assets/styles/react-table.scss';
import { TPEReactTable } from 'src/components/shared/TPEReactTable';
import { DataSourcesContext } from './DataSourcesContainer';
import { ACTIONS } from 'src/services/calculation-builder/DataSourcesReducer';
import { ACTIONS as CALC_BUILDER_ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import CONSTANTS from 'src/utils/constants';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import { ACTION_TYPE, TPEBasicModal } from 'src/components/shared/TPEBasicModal';
import ArrayUtils from 'src/utils/arrayUtils';
import {
    duplicateRecord,
    editRecord,
    ErrorPopover,
    openCommentsModal,
    openStandardAllocationModal,
    getBalancePullingStatus,
    getValueDisplay,
    RowContextMenu,
    performRowAction,
    renderPassThroughFlagIcon,
    RowActions,
    renderInfo
} from './GridCommon';
import CommentsModal from '../CommentsModal';
import { CalcBuilderComment } from 'src/models/calculation-builder/CalcBuilderComment';
import TemporaryMessage from 'src/models/common/TemporaryMessage';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import DataKeyModal from '../../shared/DataKeyModal';
import { CustomPeriodModal } from './CustomPeriodModal';
import StringUtils from 'src/utils/stringUtils';

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: { id },
    onRowUpdated, // This is a custom function that we supplied to our table instance
    editorsData, // Editors data contains data needed to render the editors
    onCellUpdated, // pass a onPeriodUpdated into editable cell.
}: { value: any, initialValue: any, row: any, column: any, onRowUpdated: any, editorsData: any, onCellUpdated: any }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const [dataKeyButtonClass, setDataKeyButtonClass] = React.useState('dataSourcesButtonPrimary');
    const errorMap = (original.errors as Map<string, string>) || new Map<string, string>();

    const onChange = (e: any) => {
        setValue(e.detail.value);
    }

    const onSelectChanged = (e: any) => {
        if (e.detail.selectedOption.value == CONSTANTS.CLEAR_SELECTION) {
            setValue(undefined);
            onRowUpdated(index, id, undefined);
        } else {
            setValue(e.detail.selectedOption.value)
            onRowUpdated(index, id, e.detail.selectedOption.value);
        }
    }

    const onPeriodSelectChanged = (e: any) => {
        const period = e.detail.selectedOption.value
        onCellUpdated(index, id, period)
    }
    // We'll only update the external data when the input is blurred
    // Removing this for dropdowns as state needs to be updated immediately after changing for pull balance validation
    const onBlur = () => {
        onRowUpdated(index, id, value)
    }

    const selectButton = () => {
        setDataKeyButtonClass('dataSourcesButtonPrimarySelected');
        onRowUpdated(index, id, value);
    }

    const getInvalidClass = () => {
        return errorMap.has(id) ? 'dataSourcesInvalid' : '';
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    const getDataSourceTypeEditor = (defaultSelectedOption: any | null) => {
        // Enabling General Ledger, Custom Data Table and TP Allocation Worksheet
        const dataSourceTypeOptions = editorsData.dataSourceTypes
            .filter((x: string) => Object.values(CONSTANTS.DATA_SOURCE_TYPES).includes(x))
            .map((x: string) => ({ label: x, value: x }));
        return !original.isNewRecord ? <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{defaultSelectedOption.value}</div> :
            <Select
                key={`${id}_${index}`} className={"dataSourcesButton " + id + "Editor " + getInvalidClass()} 
                invalid={true} placeholder="Select" 
                onChange={onSelectChanged}
                selectedOption={defaultSelectedOption}
                options={dataSourceTypeOptions} />;
    }

    if (original.isEditing) {
        const defaultSelectedOption = value == null ? null : { label: value, value };

        if (original.datasource === CONSTANTS.DATA_SOURCE_TYPES.CUSTOM_DATA_TABLE){
            switch (id) {
                case CONSTANTS.DATA_SOURCE_FIELDS.DATA_SOURCE.ACCESSOR:
                    return getDataSourceTypeEditor(defaultSelectedOption);
                case CONSTANTS.DATA_SOURCE_FIELDS.DESCRIPTION.ACCESSOR:
                    return <Input key={`${id}_${index}`} 
                        className={"dataSourcesButton " + id + "Editor" + original.datasourceId + " " + getInvalidClass()} 
                        placeholder="Enter value" value={value} onChange={onChange} onBlur={onBlur} />;
                case CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR:
                    return <div key={`${id}_${index}`} className={dataKeyButtonClass}><Button className="showCOA" onClick={selectButton}>{original.customDatasourceCalculationNumber || 'Select a CDT value'}</Button></div>;
                case CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR:
                    return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{original.customPeriod}</div>;
                default:
                    return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{value}</div>;
            }
        }
        if (original.datasource === CONSTANTS.DATA_SOURCE_TYPES.TP_ALLOCATION_WORKSHEET){
            switch (id) {
                case CONSTANTS.DATA_SOURCE_FIELDS.DATA_SOURCE.ACCESSOR:
                    return getDataSourceTypeEditor(defaultSelectedOption);
                case CONSTANTS.DATA_SOURCE_FIELDS.DESCRIPTION.ACCESSOR:
                    return <Input key={`${id}_${index}`} 
                        className={"dataSourcesButton " + id + "Editor" + original.datasourceId + " " + getInvalidClass()} 
                        placeholder="Enter value" value={value} onChange={onChange} onBlur={onBlur} />;
                case CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR:
                    return <div key={`${id}_${index}`} className={dataKeyButtonClass}><Button className="showCOA" onClick={selectButton}>{original.tpAllocationFormulaName || 'Select an allocation formula'}</Button></div>;
                default:
                    return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{value}</div>;
            }
        }
        switch (id) {
            case CONSTANTS.DATA_SOURCE_FIELDS.DATA_SOURCE.ACCESSOR:
                return getDataSourceTypeEditor(defaultSelectedOption);
            case CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR:
                const periodsOptions = editorsData.periods.map((x: string) => ({ label: x, value: x }));
                return <Select key={`${id}_${index}`} className={"dataSourcesButton " + id + "Editor " + getInvalidClass()} placeholder={original.customPeriod || "Select"} onChange={onPeriodSelectChanged} selectedOption={defaultSelectedOption} options={periodsOptions} />
            case CONSTANTS.DATA_SOURCE_FIELDS.FX_TYPE.ACCESSOR:
                const fxTypeOptions = [{ label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION }, ...editorsData.currencyConversionTypes.map((x: string) => ({ label: x, value: x }))];
                return <Select key={`${id}_${index}`} filteringType="auto" className={"dataSourcesButton " + id + "Editor " + getInvalidClass()} placeholder="Select" onChange={onSelectChanged} selectedOption={defaultSelectedOption} options={fxTypeOptions} />
            case CONSTANTS.DATA_SOURCE_FIELDS.CURRENCY.ACCESSOR:
                const currencyOptions = [{ label: CONSTANTS.CLEAR_SELECTION, value: CONSTANTS.CLEAR_SELECTION }, ...editorsData.currencyCodes.map((x: string) => ({ label: x, value: x }))];
                return <Select key={`${id}_${index}`} filteringType="auto" className={"dataSourcesButton " + id + "Editor " + getInvalidClass()} placeholder="Select" onChange={onSelectChanged} selectedOption={defaultSelectedOption} options={currencyOptions} />
            case CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR:
                return <div key={`${id}_${index}`} className={dataKeyButtonClass}><Button className="showCOA" onClick={selectButton}>{value || 'Select and configure COA data'}</Button></div>
            case `${CONSTANTS.DATA_SOURCE_FIELDS.VALUE.ACCESSOR}Formatted`:
                return (
                    original.balancePullInProgress ?
                    <div key={`${id}_${index}`} className={`textCell ${id}Cell ${id}Cell_${index}`}>{getBalancePullingStatus(original)}</div>
                    :
                    <div key={`${id}_${index}`} className={`textCell ${id}Cell ${id}Cell_${index}`}>{getValueDisplay(value, original.lastBalancePullDate)}</div>
                )
            case CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR:
                return (
                        <DatePicker key={`${id}_${index}`} className={"dataSourcesDatePicker " + getInvalidClass()}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            openCalendarAriaLabel={selectedDate =>
                                "Choose Date" +
                                (selectedDate
                                    ? `, selected date is ${selectedDate}`
                                    : "")
                            }
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                )
            case `${CONSTANTS.DATA_SOURCE_FIELDS.FX_RATE.ACCESSOR}Formatted`:
                return  <div key={`${id}_${index}`} className={"textCell " + id + "Cell"}>{value}</div>
            case 'sequence':
                return <div key={`sequence_${index}`} className="textCell sequenceCell">{value}</div>
        }
        return <Input key={`${id}_${index}`} className={"dataSourcesButton " + id + "Editor" + original.datasourceId + " " + getInvalidClass()} placeholder="Enter value" value={value} onChange={onChange} onBlur={onBlur} />
    }
    if (id == `${CONSTANTS.DATA_SOURCE_FIELDS.VALUE.ACCESSOR}Formatted`) {
        if (original.balancePullInProgress) {
            return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{getBalancePullingStatus(original)}</div>
        } else {
            return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{getValueDisplay(value, original.lastBalancePullDate)}</div>
        }
    } else if (id == CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR && original.enableDataKeyView) {
        return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}><Link className={"cell-text dataKeyViewLink" + original.datasourceId} onFollow={() => onRowUpdated(index, id, 'view')}>{value}</Link></div>
    } else if (id == CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR && original.customPeriod != null) {
        return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{original.customPeriod}</div>;
    } else if (id == CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR && value != null) {
        return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{value.split('-').join('/')}</div>;
    }
    return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{value}</div>;
}

// Set our editable cell renderer as the default Cell renderer
const defaultCellEditor = {
    Cell: EditableCell,
}

export default function DataSourcesMainGrid() {
    // Calculation builder state needs to be access directly by the children
    const { calcBuilderState, calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const { services, state, dispatch } = React.useContext(DataSourcesContext);
    const { dataSourceRecords,
            recordBeingEdited,
            recordIdForAction,
            showDecimals,
            showEditActionConfirmModal,
            showDuplicateActionConfirmModal,
            showDeleteActionConfirmModal,
            newCommentPayload,
            savedRecordId,
            commentsHistory,
            unsavedComments,
            commentsNeedRefresh,
            showStandardAllocationDeleteConfirmModal,
            updateAllBalances,
            balancePullingStateChangeTimestamp
        } = state;
    const { calculation, selectedCalculationVersion, calculationTemplateMetaData, viewMode, tpLOV } = calcBuilderState;
    const [formattedRecords, setFormattedRecords] = useState([] as DataSourceRecord[]);

    const [showCustomPeriodModal, setShowCustomPeriodModal] = useState(false);
    const [deleteDataSourceRequest, setDeleteDataSourceRequest] = useState(null as any);
    const [showDataKeyModal, setShowDataKeyModal] = useState(false);
    const [deleteStandardAllocationRequest, setDeleteStandardAllocationRequest] = useState(null as any);
    
    const [deleteDataSourceResult, deleteDataSourceLoading, deleteDataSourceError] = services.dataSourcesService.deleteDataSource(deleteDataSourceRequest);
    const [deleteStandardAllocationResult, deleteStandardAllocationLoading, deleteStandardAllocationError] = services.standardAllocationService.deleteDatasourceStandardAllocation(deleteStandardAllocationRequest);

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const onRecordUpdated = (rowIndex: number, columnId: any, value: any) => {
        if (columnId == CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR) {
            if (value == 'view') {
                if (dataSourceRecords.length > rowIndex) {
                    dispatch(ACTIONS.TOGGLE_DATA_KEY_VIEW.withPayload({recordID: dataSourceRecords[rowIndex].datasourceId}));
                }
            } else {
                dispatch(ACTIONS.SHOW_DATA_KEY_SELECTION);
            }
        } else {
            dispatch(ACTIONS.UPDATE_RECORD_FIELD.withPayload({ rowIndex, fieldName: columnId, value }));
        }
    }

    const onPeriodUpdated = (rowIndex: number, columnId: any, value: any) => {
        if (columnId == CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR && value == CONSTANTS.DATA_SOURCE_VALUES.CUSTOM_PERIOD) {
            setShowCustomPeriodModal(true);
        
        } else {
            dispatch(ACTIONS.UPDATE_RECORD_FIELD.withPayload({ rowIndex, fieldName: columnId, value }));
        }
    }

    React.useEffect(() => {
        if (dataSourceRecords == null || updateAllBalances) {
            return;
        }

        const records = recordBeingEdited == undefined ? dataSourceRecords : dataSourceRecords.slice(0, dataSourceRecords.indexOf(recordBeingEdited) + 1);
        setFormattedRecords(records.map((x, index) => {
            return {
                ...x,
                sequence: index + 1,
                fxRateFormatted: x.fxRate == null ? '-' : services.formattingService.formatString(x.fxRate, true), // Always show decimals for FX rate
                valueFormatted: x.value == null ? '-' : services.formattingService.formatString(String(x.value), showDecimals),
                showSavedIndicator: savedRecordId == x.datasourceId,
                enableDataKeyView: recordBeingEdited == null || (recordBeingEdited != null && !recordBeingEdited.isEditing),
            };
        }));
    }, [dataSourceRecords, showDecimals, updateAllBalances, balancePullingStateChangeTimestamp]);

    React.useEffect(() => {
        if (deleteDataSourceResult == null) {
            return;
        }
        dispatch(ACTIONS.DELETE_RECORD);
        setDeleteDataSourceRequest(null);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [deleteDataSourceResult])

    React.useEffect(() => {
        if (deleteStandardAllocationResult == null) {
            return;
        }
        dispatch(ACTIONS.DELETE_STANDARD_ALLOCATION_RECORD);
        const currentDatasourceRecord = dataSourceRecords.find(x => x.datasourceId === recordIdForAction)
        services.messageService.showSuccessAutoDismissBanner(`Standard Allocation has been successfully deleted for datasource: ${currentDatasourceRecord?.description}.`);
        setDeleteStandardAllocationRequest(null);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [deleteStandardAllocationResult])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(deleteDataSourceError)) {
            return;
        }
        services.messageService.showErrorBanner(deleteDataSourceError)
        setDeleteDataSourceRequest(null);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [deleteDataSourceError])

    const setCustomPeriod = (customPeriodStart: string, customPeriodEnd: string) => {
        setShowCustomPeriodModal(false);
        const customPeriod = customPeriodStart + " - " + customPeriodEnd
        if (recordBeingEdited != null) {
            dispatch(ACTIONS.UPDATE_RECORD_FIELD.withPayload({ rowIndex: recordBeingEdited.datasourceId, fieldName: CONSTANTS.DATA_SOURCE_FIELDS.CUSTOM_PERIOD.ACCESSOR, value: customPeriod }));
        }
    };
    
    const processRecord = () => {
        if (showEditActionConfirmModal) {
            dispatch(ACTIONS.HIDE_EDIT_CONFIRM_MODAL);
            editRecord(dispatch, recordBeingEdited);
        } else if (showDuplicateActionConfirmModal) {
            dispatch(ACTIONS.HIDE_DUPLICATE_CONFIRM_MODAL);
            duplicateRecord(dispatch, recordBeingEdited);
        } else if (showDeleteActionConfirmModal) {
            dispatch(ACTIONS.HIDE_DELETE_CONFIRM_MODAL);
            if (recordBeingEdited != null && recordBeingEdited.datasourceId == recordIdForAction && recordBeingEdited.isNewRecord) {
                dispatch(ACTIONS.DELETE_RECORD);
            } else {
                setDeleteDataSourceRequest({calculationNumber: calculation?.calculationNumber, datasourceId: recordIdForAction});
            }
        }
    }

    const postDeleteStandardAllocation = () => {
        dispatch(ACTIONS.HIDE_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL);
        setDeleteStandardAllocationRequest({
            templateId: calculationTemplateMetaData?.templateId,
            datasourceId: recordIdForAction
        });
    }

    const postAddComment = (calcBuilderComment: CalcBuilderComment) => {
        calcBuilderComment.calculationVersion = selectedCalculationVersion;
        if (newCommentPayload?.ownerIsUnsaved){
            setCommentsModalMessage({
                message: "The comment was added successfully",
                type: "success",
                visible: true,
            });
            calcBuilderComment.ownerIsUnsaved = newCommentPayload.ownerIsUnsaved;
            calcBuilderComment.datasourceId = newCommentPayload.datasourceId;
            dispatch(ACTIONS.ADD_COMMENT.withPayload(calcBuilderComment));
        }
        else {
            calcBuilderComment.datasourceId = newCommentPayload?.datasourceId;
            setSaveCommentRequest(calcBuilderComment);
        }
    }

    const [currentUnsavedCommentIndex, setCurrentUnsavedCommentIndex] = React.useState(-1);
    const [saveCommentRequest, setSaveCommentRequest] = React.useState(undefined as CalcBuilderComment | undefined);
    const [unsavedCommentRequest, setUnsavedCommentRequest] = React.useState(undefined as CalcBuilderComment | undefined);
    const [commentsModalMessage, setCommentsModalMessage] = React.useState(undefined as TemporaryMessage | undefined);
    const [getDataSourceCommentsResult, isLoadingComments, getCommentsError] = services.dataSourcesService.getDataSourceComments(calculation?.calculationNumber, selectedCalculationVersion, newCommentPayload?.datasourceId, commentsNeedRefresh);
    const [saveCommentResult, saveCommentLoading, saveCommentError] = services.dataSourcesService.saveDataSourceComment(saveCommentRequest);
    const [unsavedCommentResult, unsavedCommentLoading, unsavedCommentError] = services.dataSourcesService.saveDataSourceComment(unsavedCommentRequest);

    useEffect(() => {
        if (getDataSourceCommentsResult == null){
            return;
        }
        dispatch(ACTIONS.SET_COMMENTS_HISTORY.withPayload(getDataSourceCommentsResult));
    }, [getDataSourceCommentsResult])
    
    useEffect(() => {
        if (savedRecordId == null){
            return;
        }
        if (!ArrayUtils.isNullOrEmpty(unsavedComments)){
            setCurrentUnsavedCommentIndex(0);
        }
    }, [savedRecordId])

    
    useEffect(() => {
        if (currentUnsavedCommentIndex < 0 || currentUnsavedCommentIndex >= unsavedComments.length){
            return;
        }
        setUnsavedCommentRequest(unsavedComments[currentUnsavedCommentIndex]);
    }, [currentUnsavedCommentIndex])
    
    useEffect(() => {
        if (unsavedCommentResult == null){
            return;
        }
        const nextIndex = currentUnsavedCommentIndex+1;
        if (nextIndex >= unsavedComments.length){
            setCurrentUnsavedCommentIndex(-1);
            dispatch(ACTIONS.CLEAR_UNSAVED_COMMENTS);
        }
        else {
            setCurrentUnsavedCommentIndex(nextIndex);
        }
    }, [unsavedCommentResult])

    useEffect(() => {
        if (saveCommentResult == null){
            return;
        }
        
        setCommentsModalMessage({
            message: "The comment was added successfully",
            type: "success",
            visible: true,
        });
        dispatch(ACTIONS.ADD_COMMENT.withPayload(saveCommentResult));
    }, [saveCommentResult])

    useEffect(() => {
        if (saveCommentError == null){
            return;
        }
        setCommentsModalMessage({
            message: saveCommentError,
            type: "error",
            visible: true,
        })
    }, [saveCommentError])


    
    const columnDefinitions = React.useMemo(() => [
        {
            accessor: 'sequence',
            Header: (e: any) => <div className="cell-text-non-sortable">#</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DATA_SOURCE.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">Data source*</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.PERIOD.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">Period*</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.FX_DATE.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">FX date</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.FX_TYPE.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">FX type</div>,
        },
        {
            accessor: `${CONSTANTS.DATA_SOURCE_FIELDS.FX_RATE.ACCESSOR}Formatted`,
            Header: (e: any) => <div className="cell-text-non-sortable">FX rate</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.CURRENCY.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">Currency</div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DATA_KEY.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">Data key* <Link className="dataKeyViewAllLink cell-link" onFollow={() => setShowDataKeyModal(true)}>View All</Link></div>,
        },
        {
            accessor: CONSTANTS.DATA_SOURCE_FIELDS.DESCRIPTION.ACCESSOR,
            Header: (e: any) => <div className="cell-text-non-sortable">Description*</div>,
        },
        {
            accessor: `${CONSTANTS.DATA_SOURCE_FIELDS.VALUE.ACCESSOR}Formatted`,
            Header: (e: any) => <div className="cell-text-non-sortable">Value</div>,
        },
        {
            accessor: "actions",
            Header: (e: any) => <div className="cell-text-non-sortable">Actions</div>,
            Cell: (e: any) => <div className="rowActions">
                { 
                    e.row.original.pullBalanceError != null || e.row.original.saveError != null || (e.row.original.errors != null && (e.row.original.errors as Map<string, string>).size > 0) ? 
                        <ErrorPopover error={e.row.original.pullBalanceError || e.row.original.saveError || e.row.original.errors} />
                    :
                    (e.row.original.showSavedIndicator ? 
                        <StatusIndicator data-class="smallStatusIndicator" type="success">Saved</StatusIndicator>
                        : 
                        <span>&nbsp;</span> 
                    )
                }
                { viewMode != CONSTANTS.VIEW_MODE.FROZEN && e.row.original.passThroughFlag ? renderPassThroughFlagIcon(e.row.original) : <span>&nbsp;</span>}
                { viewMode != CONSTANTS.VIEW_MODE.FROZEN && e.row.original.standardAllocation ? <span className='greenTextWrapper'><Button iconName="folder" variant="icon" onClick={() => { openStandardAllocationModal(dispatch, e.row.original.datasourceId); }} /></span> : <span>&nbsp;</span> }
                { viewMode != CONSTANTS.VIEW_MODE.FROZEN && e.row.original.hasComments ? <Button iconName="contact" variant="icon" onClick={() => { openCommentsModal(dispatch, e.row.original, calculation?.calculationNumber, selectedCalculationVersion); }} /> : <span>&nbsp;</span> }
                { e.row.original.isNewRecord ? <span>&nbsp;</span> : <Popover data-class="basicSmallPopover" dismissButton={false} position="top" triggerType="custom" content={renderInfo(e.row.original.lastModifiedUser, e.row.original.lastModifiedDate)}>
                        <Link><StatusIndicator data-class="smallStatusIndicator" type="info" colorOverride="grey"/></Link>
                    </Popover>
                }
                { viewMode != CONSTANTS.VIEW_MODE.FROZEN && <RowContextMenu row={e.row.original} allowEditActions={viewMode == CONSTANTS.VIEW_MODE.EDITABLE} dispatch={dispatch} recordBeingEdited={recordBeingEdited} calculationHasTemplate={calculationTemplateMetaData?.templateId != null} /> }
            </div>
        }
    ], [showDecimals, recordBeingEdited, dataSourceRecords, viewMode, calculationTemplateMetaData]);

    return (
        <div className="dataSourceTableContainer">
            { calculationTemplateMetaData?.templateId != null && <div className="tableGroupHeader"><span>Data Set - {calculationTemplateMetaData.dataSetName}</span></div> }
            <TPEReactTable {...{
                data: formattedRecords,
                columnDefinitions,
                className: "dataSources",
                sortable: false,
                emptyMessage: "No records found",
                editableOptions: {
                    defaultColumn: defaultCellEditor,
                    onRowUpdated: onRecordUpdated,
                    onCellUpdated: onPeriodUpdated,
                    editorsData: tpLOV,
                    skipPageReset: true,
                }
            }} />

            <CommentsModal
                header="Data source comments"
                visible={newCommentPayload != null}
                onCancel={() => dispatch(ACTIONS.SET_NEW_COMMENT_PAYLOAD.withPayload(undefined))}
                onAddComment={postAddComment}
                calcNumber={calculation?.calculationNumber as string}
                commentsHistory={newCommentPayload?.ownerIsUnsaved? unsavedComments:  commentsHistory}
                modalMessage={commentsModalMessage}
                isSaving={saveCommentLoading}
            />

            <TPEBasicModal
                className="dataSourceStopEditConfirmModal"
                visible={showEditActionConfirmModal || showDuplicateActionConfirmModal}
                action={ACTION_TYPE.CONFIRM_CANCEL}
                title="Cancel editing"
                onCancel={() => { dispatch(ACTIONS.HIDE_EDIT_CONFIRM_MODAL); dispatch(ACTIONS.HIDE_DUPLICATE_CONFIRM_MODAL) }}
                onConfirm={processRecord}
            >
                Are you sure you want to stop editing your current data source? Confirm to proceed.
            </TPEBasicModal>

            <TPEBasicModal
                className="dataSourceDeleteConfirmModal"
                visible={showDeleteActionConfirmModal}
                action={ACTION_TYPE.YES_NO}
                title="Delete data source"
                onCancel={() => dispatch(ACTIONS.HIDE_DELETE_CONFIRM_MODAL)}
                onConfirm={processRecord}
            >
                Are you sure you want to delete this data source?
            </TPEBasicModal>
            <TPEBasicModal
                className="standardAllocationDeleteConfirmModal"
                visible={showStandardAllocationDeleteConfirmModal}
                action={ACTION_TYPE.YES_NO}
                title="Delete standard allocation"
                onCancel={() => dispatch(ACTIONS.HIDE_STANDARD_ALLOCATION_DELETE_CONFIRM_MODAL)}
                onConfirm={postDeleteStandardAllocation}
            >
                Are you sure you want to delete this standard allocation?
            </TPEBasicModal>
            <CustomPeriodModal
                visible={showCustomPeriodModal}
                onClose={() => setShowCustomPeriodModal(false)}
                onSubmit={setCustomPeriod}
                recordBeingEdited={recordBeingEdited || {} as DataSourceRecord}
            />
            <DataKeyModal 
                visible={showDataKeyModal} 
                onClose={() => setShowDataKeyModal(false)} 
                dataSourceRecords={dataSourceRecords} 
                recordBeingEdited={recordBeingEdited}
                dataSourcesAreEditable={viewMode == CONSTANTS.VIEW_MODE.EDITABLE}
                onEditRecord={(dataSourceId, recordBeingEdited) => performRowAction(dispatch, recordBeingEdited, dataSourceId, String(RowActions.EDIT))} />
        </div>
    );
}