import React, { useEffect, useState } from "react";
import { FormField, Grid, } from '@amzn/awsui-components-react/polaris';
import { Select, SpaceBetween } from "@amzn/awsui-components-react";
import { ReadOnlyCalculationContext } from "src/components/calculations/ReadOnlyCalculationView";
import { ACTIONS } from "src/services/calculations/ReadOnlyCalculationReducer"
import ArrayUtils from "src/utils/arrayUtils";

export default function readOnlyCalculationView_LineSelector() {
    const { services, state, dispatch } = React.useContext(ReadOnlyCalculationContext);
    const { selectedAgreementNumber, selectedCalculationNumber, calculationNumbers } = state;
    const [agreementNumbers, agreementNumbersLoading] = services.readOnlyCalculationService.getAgreementNumbers();
    const [calculationNumbersResult, calculationNumbersLoading] = services.readOnlyCalculationService.getCalculationNumbers(selectedAgreementNumber);


    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(calculationNumbersResult)) {
            return;
        }
        dispatch(ACTIONS.SET_CALCULATION_NUMBERS.withPayload(calculationNumbersResult))
    }, [calculationNumbersResult]);

    return (
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }]}>
            <FormField
                label="Select TP agreement"
            >
                <Select
                    className="ddSelectAgreementNumber"
                    selectedOption={{ label: selectedAgreementNumber, value: selectedAgreementNumber }}
                    onChange={(e: any) => { dispatch(ACTIONS.SET_SELECTED_AGREEMENT_NUMBER.withPayload(e.detail.selectedOption.value)) }}
                    options={agreementNumbers.map(x => ({ label: x, value: x }))}
                    placeholder="Choose an option"
                    selectedAriaLabel="Selected"
                    empty="No options"
                />
            </FormField>
            <SpaceBetween direction="vertical" size="xl">
                <FormField
                    label="Select TP calculation line"
                >
                    <Select
                        className="ddSelectCLI"
                        disabled={selectedAgreementNumber == null}
                        selectedOption={{ label: selectedCalculationNumber, value: selectedCalculationNumber }}
                        onChange={(e: any) => { dispatch(ACTIONS.SET_SELECTED_CALCULATION_NUMBER.withPayload(e.detail.selectedOption.value)) }}
                        options={calculationNumbers.map(x => ({ label: x, value: x }))}
                        placeholder="Choose an option"
                        selectedAriaLabel="Selected"
                        empty="No options"
                    />
                </FormField>
            </SpaceBetween>
        </Grid>
    );
}