import React, { useEffect, useState } from 'react';
import { Button, Container, SpaceBetween } from '@amzn/awsui-components-react';
import CalculationLineSelector from '../calculations/CalculationLineSelector';
import { CalculationBuilderContext } from './CalculationBuilderView';
import { ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import 'src/assets/styles/polaris-container.scss';
import TPENavigator from '../TPENavigator';
import CONSTANTS from 'src/utils/constants';

export default function CalculationBuilderSelectAgreement(props: { isCreating: boolean }) {
    const { isCreating } = props;
    const { calcBuilderDispatch, services, calcBuilderState } = React.useContext(CalculationBuilderContext);
    const { calculation } = calcBuilderState;
    const [selectedAgreementNumber, setSelectedAgreementNumber] = useState(undefined as string | undefined);
    const [selectedAgreementLine, setSelectedAgreementLine] = useState(undefined as string | undefined);
    const [selectedCalculationLine, setSelectedCalculationLine] = useState(undefined as string | undefined);
    const [navigateURL, setNavigateURL] = useState('');
    
    useEffect(() => {
        if (calculation == null){
            return;
        }
        setSelectedAgreementNumber(calculation.agreementNumber);
        setSelectedAgreementLine(calculation.agreementLineNumber);
        setSelectedCalculationLine(calculation.calculationNumber);
    }, [calculation])

    const dispatchCreateCalculation = function () {
        calcBuilderDispatch(ACTIONS.SET_NEW_CALCULATION_NUMBER.withPayload(selectedCalculationLine));        
    };

    const createButtonIsEnabled = selectedAgreementNumber !== '' && selectedAgreementLine !== '' && selectedCalculationLine !== '';

    return (
        <Container className="polaris-content-container">
            <CalculationLineSelector
                services={services}
                selectedAgreementNumber={selectedAgreementNumber}
                selectedAgreementLine={selectedAgreementLine}
                selectedCalculationLine={selectedCalculationLine}
                onAgreementNumberSelected={(number) => setSelectedAgreementNumber(number || '')}
                onAgreementLineSelected={(line) => setSelectedAgreementLine(line || '')}
                onCalculationLineSelected={(line) => setSelectedCalculationLine(line || '')} />
            <div className="calcSearchButtonsContainer">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button className="cancelButton" variant="link" onClick={() => setNavigateURL(CONSTANTS.PAGE_NAV.CALCULATIONS.URL)}>Cancel</Button>
                    <Button className="createCalculationButton"
                        variant="primary"
                        onClick={dispatchCreateCalculation}
                        disabled={!createButtonIsEnabled}
                        loading={isCreating}
                    >
                        Create calculation
                    </Button>
                </SpaceBetween>
            </div>
            <TPENavigator path={navigateURL} />
        </Container>
    )

}