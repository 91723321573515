import StringUtils from "src/utils/stringUtils";

export function generatePieChartData(items: Map<string, number> | undefined, totalCli: number) {
  if (totalCli === 0) {
    return [];
  } else {
    if (items) {
      // Convert the map entries to an array and iterate over them
      return Object.entries(items).map(([label, value]) => ({
        title: convertCamelCaseToTitleCase(label),
        percentage: calculatePercentage(value, totalCli),
        value: value,
      }));
    } else {
      return [];
    }
  }

}

function calculatePercentage(value: number | undefined, total: number): number {
  if (value !== undefined) {
    return (value / total) * 100;
  }
  return 0;
}

export function convertCamelCaseToTitleCase(input: string): string {
  switch (input) {
    case 'failed':
    case 'success':
    case 'ZeroVariance':
      return StringUtils.convertCamelCaseToTitleCase(input);
    case 'Apttus data Missing':
      return 'Apttus Data Missing';
    default:
      return input;
  }
}