import React, { useEffect, useReducer, useContext } from 'react';
import { Grid, SpaceBetween, Container, Header, Select, Alert, Box, Spinner, Icon, Link, Button } from '@amzn/awsui-components-react/polaris';
import AgreementsOverview from './AgreementsOverview';
import CalcsOverview from './CalcsOverview';
import EngineRuntime from './EngineRuntime';
import DashboardService from 'src/services/dashboard/DashboardService';
import { initialState, DashboardNotification } from 'src/models/dashboard';
import { dashboardReducer, ACTIONS } from 'src/services/dashboard/DashboardReducer';
import './Dashboard.scss';
import { DashboardContext } from 'src/components/App';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import TPENavigator from '../TPENavigator';




export default function DashboardView() {
    const [state, dispatch] = useReducerWithLogger(dashboardReducer, initialState);
    const { notifications, quarters, quarter, isLoading, engineRuntimeBatches, engineRuntimeSelectedBatch, error, } = state;
    const service: DashboardService | undefined = useContext(DashboardContext);
    const imagePath = '/assets/images/';
    const [ navigateURL, setNavigateURL ] = React.useState('');


    // Equivalent to componentDidMount
    useEffect(() => {
        (async function loadNotifications() {
            try {
                if (!service) {
                    return;
                }
                const results = await (await service.loadData()).json();
                /* Temporarily mapping to match notification shape */
                const notifications = results.map(
                    ((x: { title: any; id: any }) => ({ id: x.id, text: x.title, date: new Date().toDateString() }))
                );
                // Updating notifications in the state
                dispatch(ACTIONS.SET_NOTIFICATIONS
                    .withPayload(notifications)
                )
            }
            catch (err) {
                dispatch(ACTIONS.ERROR
                    .withPayload(err)
                );
            }
        })();
    }, []);



    return (
        <div className="dashboardPageContainer">
            <TPENavigator path={navigateURL}/>
            <div className="dashboardSectionContainer">
                <Container
                    media={{
                        content: (
                            <img
                                src={imagePath + 'dashboard-calculation.svg'}
                                alt="Calculation Management"
                                className="intercompanyCalculationManagementImage"
                            />
                        ),
                        position: "side",
                        width: "53%"
                    }}>
                        <Box variant="small">
                            Accountant
                        </Box>
                        <Box variant="h2" className="intercompanyCalculationManagementTitleText">
                            <Box variant="p" fontSize="heading-m">
                                Intercompany Calculation Management
                            </Box>
                        </Box>
                        <Box variant="p">
                            Unlock seamless transfer pricing and intercompany calculation capabilities with ATP
                        </Box>
                    <Link onFollow={() => setNavigateURL('/agreements')}>
                        <Button className="tpWorklistsButton">
                            Go to TP Worklists
                        </Button>
                    </Link>
                </Container>
            </div>
            <div className="dashboardSectionContainer">
                <Container
                    media={{
                        content: (
                            <img
                                src={imagePath + 'dashboard-reporting.svg'}
                                alt="ATP Reporting"
                                className="atpReportingImage"
                            />
                        ),
                        position: "side",
                        width: "53%"
                    }}>
                    <Box variant="small">
                        Tax Analyst
                    </Box>
                    <Box variant="h2" className="atpReportingTitleText">
                        <Box variant="p" fontSize="heading-m">
                            ATP Reporting
                        </Box>
                    </Box>
                    <Box variant="p" className="atpReportingDescriptionText">
                        Gain comprehensive visibility into transfer pricing and intercompany transactions with ATP reporting
                    </Box>
                    <Link onFollow={() => setNavigateURL('/reports')}>
                        <Button className="atpReportsButton">
                            Go to ATP Reports
                        </Button>
                    </Link>
                </Container>
            </div>
        </div>
    );

        /* <Grid gridDefinition={[{ colspan: { default: 9, xl: 10 } }, { colspan: { default: 3, xl: 2 } }]}>
            <SpaceBetween direction="vertical" size="l">
                <Container
                    header={
                        <Header
                            variant="h2"
                            actions={
                                <Select
                                    selectedOption={quarter}
                                    onChange={({ detail }) =>
                                        dispatch(ACTIONS.FIELD
                                            .withFieldName('quarter')
                                            .withPayload(detail.selectedOption)
                                        )
                                    }
                                    options={quarters.map(x => ({ label: x, value: x }))}
                                    selectedAriaLabel="Selected"
                                />
                            }
                        >
                            My overview
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <AgreementsOverview selectedQuarter={quarter} dispatch={dispatch}></AgreementsOverview>
                        <CalcsOverview selectedQuarter={quarter}></CalcsOverview>
                        <EngineRuntime selectedQuarter={quarter} dispatch={dispatch} batches={engineRuntimeBatches} selectedBatch={engineRuntimeSelectedBatch}></EngineRuntime>
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
            <SpaceBetween direction="vertical" size="l">
                <Header variant="h1" description="Recent updates on items that need your attention.">My Notifications</Header>
                <Container>
                    {isLoading ? <Spinner size="large" variant="normal" /> :
                        <div className="notifications-container">
                            <SpaceBetween direction="vertical" size="xxs">
                                {(notifications).map((x: DashboardNotification) => (
                                    <Alert
                                        key={x.id}
                                        type="warning">
                                        {x.text}
                                        <br /><small>{x.date}</small>
                                    </Alert>))}
                            </SpaceBetween>
                        </div>
                    }
                </Container>
            </SpaceBetween>
        </Grid>
    ); */

}