import TPEAction from "src/models/common/TPEAction";
import { PreValidationState } from "src/services/mec/prevalidation/PreValidationState";

export const ACTIONS = Object.freeze({
    SET_CATEGORY: new TPEAction('SET_CATEGORY'),
    SET_PREVALIDATION_RESULT: new TPEAction('SET_PREVALIDATION_RESULT'),
    SET_EMPTY_TABLE_MESSAGE: new TPEAction('SET_EMPTY_TABLE_MESSAGE'),
    SET_SELECTED_PAGE: new TPEAction('SET_SELECTED_PAGE'),
    SET_LOADED_PAGE: new TPEAction('SET_LOADED_PAGE'),
    SET_CACHE: new TPEAction('SET_CACHE'),
    SET_INDIRECT_TAX_CHANGE_RECORD_LIST: new TPEAction('SET_INDIRECT_TAX_CHANGE_RECORD_LIST'),
});

export function preValidationReducer(state: PreValidationState, action: TPEAction) : PreValidationState {
    switch(action.type) {
        case ACTIONS.SET_CATEGORY.type:
            return {
                ...state,
                category: action.payload,
            };
        case ACTIONS.SET_PREVALIDATION_RESULT.type:
            return {
                ...state,
                preValidationResult: {records: JSON.parse(action.payload)},
            };
        case ACTIONS.SET_EMPTY_TABLE_MESSAGE.type:
            return {
                ...state,
                emptyTableMessage: action.emptyTableMessage,
            };
        case ACTIONS.SET_SELECTED_PAGE.type:
            return {
                ...state,
                selectedPage: action.payload,
            };
        case ACTIONS.SET_LOADED_PAGE.type:
            return {
                ...state,
                loadedPage: action.payload,
            };
        case ACTIONS.SET_CACHE.type:
            return {
                ...state,
                cache: action.payload,
            };
        case ACTIONS.SET_INDIRECT_TAX_CHANGE_RECORD_LIST.type:
            return {
                ...state,
                indirectTaxChangeRecordList: action.payload,
            };
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}