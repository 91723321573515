import {ReportsState} from "src/services/reports/ReportsState";
import React, {useEffect, useState} from "react";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import {useParams} from "react-router-dom";
import TaxAuditRecordTransactionDetails from "src/components/reports/taxAuditRecord/taxAuditRecordDetails/TaxAuditRecordTransactionDetails";
import {Container, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import TaxAuditRecordCalculationsDetails from "src/components/reports/taxAuditRecord/taxAuditRecordDetails/TaxAuditRecordCalculationsDetails";
import TaxAuditRecordReportDetails from "src/components/reports/taxAuditRecord/taxAuditRecordDetails/TaxAuditRecordReportDetails";
import TaxAuditRecordAgreementDetails from "src/components/reports/taxAuditRecord/taxAuditRecordDetails/TaxAuditRecordAgreementDetails";
import TaxAuditRecordTaxProductDetails from "src/components/reports/taxAuditRecord/taxAuditRecordDetails/TaxAuditRecordTaxProductDetails";
import TaxAuditRecordInvoiceDetails from "src/components/reports/taxAuditRecord/taxAuditRecordDetails/TaxAuditRecordInvoiceDetails";
import TaxAuditRecordEntityDetails from "src/components/reports/taxAuditRecord/taxAuditRecordDetails/TaxAuditRecordEntityDetails";
import {Button, Header, Icon} from "@amzn/awsui-components-react";
import useLocalStorage from "src/utils/useLocalStorage";
import {DownloadReportRequest} from "src/models/reports/DownloadReport";
import {DownloadTaxAuditRecordRequest} from "src/models/reports/DownloadTaxAuditRecord";
import StringUtils from "src/utils/stringUtils";

export type ContextType = {
    state: ReportsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
}

export default function TaxAuditRecordDetailsView(props: { services: ServiceCollection }) {
    const {services} = props;
    const {
        encodedTransactionId,
        encodedTaxJournalHeaderId
    } = useParams<{ encodedTransactionId: string, encodedTaxJournalHeaderId: string }>();
    const [downloadTaxAuditRecordRequestPayload, setDownloadTaxAuditRecordRequestPayload] = useState(null as unknown as DownloadTaxAuditRecordRequest);

    const [taxAuditRecordDetailsResponse, taxAuditRecordDetailsLoading, taxAuditRecordDetailsError] = services.reportsService.getTaxAuditRecordDetails(encodedTaxJournalHeaderId);
    const [downloadTaxAuditRecordUrl, downloadTaxAuditRecordLoading, downloadTaxAuditRecordError] = services.reportsService.downloadTaxAuditRecord(downloadTaxAuditRecordRequestPayload);

    useEffect(() => {
        if (!taxAuditRecordDetailsError) {
            return;
        }
        services.messageService.showErrorBanner(taxAuditRecordDetailsError);
    }, [taxAuditRecordDetailsError])

    useEffect(() => {
        if (!downloadTaxAuditRecordError) {
            return;
        }
        services.messageService.showErrorBanner(downloadTaxAuditRecordError);
    }, [downloadTaxAuditRecordError])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(downloadTaxAuditRecordUrl)) {
            return;
        }
        window.open(downloadTaxAuditRecordUrl);
    }, [downloadTaxAuditRecordUrl])

    const onDownloadClicked = () => {
        setDownloadTaxAuditRecordRequestPayload({
            taxJournalHeaderId: taxAuditRecordDetailsResponse.taxJournalHeaderId
        })
    }

    return <Container className= "polaris-content-container"
        header={
            <Header
                variant="h3"
                actions={
                    <Button
                        className={"downloadButton"}
                        onClick={onDownloadClicked}
                        disabled = {downloadTaxAuditRecordLoading || taxAuditRecordDetailsLoading}
                        variant={"primary"}
                    >
                        <SpaceBetween size="s" direction="horizontal">
                            <Icon name="download"/>
                            <span>{ downloadTaxAuditRecordLoading ? 'Downloading...' : 'Download' }</span>
                        </SpaceBetween>
                    </Button>
            }>
                TAR Report - {encodedTransactionId}
            </Header>
        }
    >
    <SpaceBetween direction="vertical" size="xxl">
        <TaxAuditRecordTransactionDetails taxAuditRecordDetails={taxAuditRecordDetailsResponse} loading={taxAuditRecordDetailsLoading} services={services}/>
        <TaxAuditRecordCalculationsDetails taxAuditRecordDetails={taxAuditRecordDetailsResponse} loading={taxAuditRecordDetailsLoading}/>
        <TaxAuditRecordReportDetails taxAuditRecordDetails={taxAuditRecordDetailsResponse} loading={taxAuditRecordDetailsLoading}/>
        <TaxAuditRecordEntityDetails taxAuditRecordDetails={taxAuditRecordDetailsResponse} loading={taxAuditRecordDetailsLoading}/>
        <TaxAuditRecordAgreementDetails taxAuditRecordDetails={taxAuditRecordDetailsResponse} loading={taxAuditRecordDetailsLoading}/>
        <TaxAuditRecordTaxProductDetails taxAuditRecordDetails={taxAuditRecordDetailsResponse} loading={taxAuditRecordDetailsLoading}/>
        <TaxAuditRecordInvoiceDetails taxAuditRecordDetails={taxAuditRecordDetailsResponse} loading={taxAuditRecordDetailsLoading}/>
    </SpaceBetween>
    </Container>
}