import React, { useState } from 'react';
import {
    Grid,
    Select,
    TextFilter
} from "@amzn/awsui-components-react/polaris";

export const CDT_SEARCH_MODES = {
    TABLE_NAME: {value: "table", label: "Table Name"},
    TP_NUMBER: {value: "calculationNumber", label: "Calculation Number"}
}

export default function SearchCDTBox(props:{matchesCount:number, onSearchSubmitted:(searchMode:any, searchText:string) => void}) {
    const{onSearchSubmitted, matchesCount} = props;
    const [searchMode, setSearchMode] = useState(CDT_SEARCH_MODES.TABLE_NAME);
    const [searchText, setSearchText] = useState("");

    function getSearchBoxPlaceholder(): string | undefined {
        if (searchMode === CDT_SEARCH_MODES.TABLE_NAME){
            return "Search custom data tables";
        }
        if (searchMode === CDT_SEARCH_MODES.TP_NUMBER){
            return "Provide a full calculation number to search custom data tables";
        }
        return "Select a search mode from the dropdown";
    }

    return <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]} disableGutters>
        <Select selectedOption={searchMode} 
            onChange={({detail}) => {
                setSearchMode(detail.selectedOption as any);
                setSearchText("");
            }} 
            options={[CDT_SEARCH_MODES.TABLE_NAME, CDT_SEARCH_MODES.TP_NUMBER]}></Select>
        <TextFilter data-class="basicTextFilter" 
                filteringText={searchText}
                onDelayedChange={({ detail }) => {
                    onSearchSubmitted(searchMode,detail.filteringText);
                }
                }
                onChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                }
                }
                filteringPlaceholder={getSearchBoxPlaceholder()}
                filteringAriaLabel={getSearchBoxPlaceholder()}
                countText={`${matchesCount} matches found`}
        />
    </Grid>
}