import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { GlobalAppContext } from './App';
import { navigateTo } from './AppBreadcrumbs';

export default function TPENavigator(props: { path: string, fromLeftNav?: boolean }) {
    const { path, fromLeftNav = false } = props;
    const history = useHistory();
    const location: any = useLocation();
    const { globalState } = useContext(GlobalAppContext);

    useEffect(() => {
        if (path == '') {
            return;
        }
        navigateTo(history, location.pathname, path, location.state, globalState, fromLeftNav);
    }, [path]);

    return null;
}