import React from 'react';
import { ExpandableSection, Header, Link } from '@amzn/awsui-components-react/polaris';
import SearchCalculationsGrid from '../calculations/SearchCalculations_Grid';
import useReducerWithLogger from '../../services/utils/ReducerWithLogger';
import ServiceCollection from '../../services/ServiceCollection';
import { calculationsReducer, ACTIONS } from '../../services/calculations/CalculationsReducer';
import { initialState, SearchCalculationsState } from '../../services/calculations/SearchCalculationsState';
import TPESearchRequest, { DbConjunctor, DbExpression, DbOperator } from '../../models/common/TPESearchRequest';
import CONSTANTS from '../../utils/constants';
import { SearchSortOrder } from '../../models/common/SearchSortingSpec';
import { SortingRule } from 'react-table';
import { useEffect } from 'react';

export default function TPCalculationList(props: { agreementNumber: string, services: ServiceCollection, expanded?: boolean }) {
    const { agreementNumber, services, expanded = false } = props;
    const [state, dispatch] = useReducerWithLogger(calculationsReducer, initialState);
    const {
        isLoading,
        searchParams,
        searchResultItems,
        searchCurrentPage,
        searchPageSize,
        searchTotalSize,
        searchSorting,
        searchFilters,
    } = state as SearchCalculationsState;

    const [searchResult, isSearching, searchError] = services.calculationsService.searchCalculationLines(searchParams as TPESearchRequest);

    useEffect(() => {
        if (agreementNumber != '') {
            dispatchSearch();
        }
    }, [agreementNumber]);

    // Triggered when the list of calculation lines changes
    useEffect(() => {
        if (searchResult == null) {
            return;
        }
        // Sets calculation lines on the reducer's state
        dispatch(ACTIONS.SET_SEARCH_RESULTS
            .withPayload(searchResult)
        )
    }, [searchResult]);

    useEffect(() => {
        if (isLoading || isSearching) {
            return;
        }
        dispatchSearch();
    }, [searchCurrentPage, searchSorting, searchFilters]);

    const dispatchSearch = function () {
        const payload = {
            page: searchCurrentPage,
            pageSize: parseInt(searchPageSize.toString()),
            searchStartedDate: new Date(),
        } as any;
        let filterExpression = {
            conjunctor: DbConjunctor.AND,
            expressions: [] as DbExpression[],
        };
        filterExpression.expressions.push({
            attributeName: CONSTANTS.CALCULATIONS_SEARCH_FIELDS.AGREEMENT_NUMBER.key,
            operator: DbOperator.EQUALS,
            values: [agreementNumber]
        })
        if (searchFilters != null) {
            searchFilters.forEach(filter => {
                filterExpression?.expressions.push({
                    attributeName: filter.id,
                    operator: DbOperator.CONTAINS,
                    values: [filter.value]
                })
            })
        }
        if (searchSorting != null) {
            payload.sortField = searchSorting.sortField;
            payload.sortOrder = searchSorting.sortOrder;
        }
        payload.filterExpression = filterExpression;

        dispatch(ACTIONS.START_SEARCH.withPayload(payload));
    }

    const dispatchSortByChanged = function (sortBySpecs: Array<SortingRule<any>>) {
        if (sortBySpecs == null || sortBySpecs.length === 0) {
            dispatch(ACTIONS.SET_SEARCH_SORT.withPayload(undefined));
            return;
        }
        const sortBy = sortBySpecs[0];
        dispatch(ACTIONS.SET_SEARCH_SORT.withPayload({
            sortOrder: sortBy.desc ? SearchSortOrder.DESCENDING : SearchSortOrder.ASCENDING,
            sortField: sortBy.id
        }))

    }

    const dispatchFilterChanged = function (filters: Array<{ id: string, value: any }>) {
        if (filters == null || filters.length === 0) {
            dispatch(ACTIONS.SET_SEARCH_FILTERS.withPayload(undefined));
            return;
        }

        const allFiltersHaveValidValue = filters.every(x => x.value.length > 2);
        if (!allFiltersHaveValidValue) {
            return;
        }

        dispatch(ACTIONS.SET_SEARCH_FILTERS.withPayload(filters));
    }

    return (
        <ExpandableSection className="polarisExpandableSection" variant="container"
            defaultExpanded={expanded}
            header={
                <Header><h2>TP Calculations</h2></Header>
            }
        >
            <div className="contentContainer">
                <SearchCalculationsGrid
                    isVisible={true}
                    searchResult={searchResultItems || []}
                    isSearching={isSearching as boolean}
                    searchError={searchError as string}
                    searchCurrentPage={searchCurrentPage}
                    searchPageSize={searchPageSize}
                    searchTotalSize={searchTotalSize}
                    onSortBy={(x: Array<any>) => dispatchSortByChanged(x)}
                    onFilter={(x: Array<any>) => dispatchFilterChanged(x)}
                    onPageChanged={(page: number) => dispatch(ACTIONS.SET_CURRENT_PAGE.withPayload(page))}
                    showFiltering={true}
                />
            </div>
        </ExpandableSection>
    )
}
