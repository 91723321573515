import React, { useMemo, useState } from "react";
import { TPEReactTable } from "./TPEReactTable";

type ComponentProps = {
    data: any[],
    columnDefinitions: any[],
    className: string,
    sortable?: boolean,
    showFilters?: boolean,
    showHeader?: boolean,
    highlightErrorCells?: boolean
    isSearching?: boolean,
    isSearchingMessage?: string,
    emptyMessage?: string,
    onSortBy?: (e: Array<any>) => any,
    onFilter?: (e: Array<any>) => any,
    onRowBlur?: (...args : any[]) => any,
    onRowClicked?: (...args : any[]) => any,
    onExpandedChanged?: (e: any) => void,
    editableOptions?: { defaultColumn: any, onRowUpdated: any, skipPageReset: boolean, editorsData?: any, onCellUpdated?: any },
    initialState?: any,
    allowMultipleRowSelection?: boolean,
    alternateBackgroundColor?: boolean,
    alternateCount?: number,
    highlightSelected?: boolean,
    hideCheckboxes?: boolean
};

const doNothing = () => { }

export default function TPESelectableReactTable(props: ComponentProps) {
    const {
        className, data, columnDefinitions, sortable = true, showFilters = false, showHeader = true, highlightErrorCells = false, isSearching = false, 
        onSortBy = doNothing, onFilter = doNothing, emptyMessage = "No results", editableOptions, initialState, isSearchingMessage = "Searching...",
        onRowBlur, onRowClicked = doNothing, onExpandedChanged = doNothing, allowMultipleRowSelection = false,
        alternateBackgroundColor = false, alternateCount = 1, highlightSelected = true, hideCheckboxes = false
    } = props;

    const handleOnChange = (checked: boolean, row: any, index: number) => {
        if (!allowMultipleRowSelection) {
            data.forEach(x => x.isSelected = false);
        }
        row.isSelected = checked;
        // A brief wait to allow the state to set
        setTimeout(() => onRowClicked(checked, row, index), 10);
    }

    const addSelector = () => {
        const updatedColDefs: any[] = [{
            accessor: "isSelected",
            Header: (e: any) => <div className="cell-text"></div>,
            Cell: (e: any) => <div className="cell-text">
                { hideCheckboxes ? '' : 
                    allowMultipleRowSelection ?
                        <input type="checkbox" title={e.row.original.tooltip} className="reactTableCheckbox" disabled={e.row.original.isDisabled} checked={e.row.original.isSelected} onChange={() => handleOnChange(!e.row.original.isSelected, e.row.original, e.row.index)} />
                    :
                        <input type="radio" className="reactTableRadioButton" checked={e.row.original.isSelected} onChange={() => handleOnChange(true, e.row.original, e.row.index)}/>
                }
            </div>,
            suppressSorting: true,
        }];

        columnDefinitions.forEach(x => updatedColDefs.push(x));
        return updatedColDefs;
    }
    const updatedColumnDefinitions = useMemo(addSelector, [columnDefinitions, data]);

    return <TPEReactTable {...{
        data,
        columnDefinitions: updatedColumnDefinitions,
        className,
        sortable,
        showFilters,
        showHeader,
        highlightErrorCells,
        isSearching,
        isSearchingMessage,
        emptyMessage,
        onSortBy,
        onFilter,
        onRowBlur,
        onRowClicked,
        onExpandedChanged,
        editableOptions,
        initialState,
        alternateBackgroundColor,
        alternateCount,
        highlightSelected
        }
    } />

}