import { getCustomHeaderValue } from "./TableUtils";

export const PAGE_SIZE = 100;

export const VarianceReportTableColumns = [
    "workbookId",
    "workbookName",
    "calculationNumber",
    "calculationStatus",
    "templateName",
    "pli",
    "target",
    "actualYTD",
    "variance",
    "varianceAmount",
    "potentialRootCause",
    "rootCauseExplanation",
    "currency",
    "agreementName",
    "provider",
    "providerCompanyCode",
    "recipient",
    "recipientCompanyCode",
    "workbookAssignee",
    "accountingApprover1",
    "accountingApprover2",
    "taxApprover1",
    "taxApprover2",
    "atpStatus",
    "reportingStatus"
];

export const FILTERING_OPERATORS_FOR_STRING = [":", "!:", "=", "!="];
export const FILTERING_OPERATORS_FOR_NUMBER = ["=", "!=", ">", "<", ">=", "<="];

export const DEFAULT_COLLECTION_PREFERENCES = {
    pageSize: 100,
    visibleContent: VarianceReportTableColumns,
    wrapLines: true,
    stickyColumns: { first: 0, last: 0 }
};

export const PAGE_SIZE_OPTIONS = [
    { value: 100, label: "100 Requests" },
    { value: 300, label: "300 Requests" },
    { value: 500, label: "500 Requests" },
];

export const VARIANCE_TABLE_COLUMN_NAMES: {
    [key: string]: string;
} = Object.freeze({
    WORKBOOK_ID_COLUMN: "workbookId",
    WORKBOOK_NAME_COLUMN: "workbookName",
    CALCULATION_NUMBER_COLUMN: "calculationNumber",
    CALCULATION_STATUS_COLUMN: "calculationStatus",
    TEMPLATE_NAME_COLUMN: "templateName",
    PLI_COLUMN: "pli",
    TARGET_COLUMN: "target",
    ACTUAL_YTD_COLUMN: "actualYTD",
    VARIANCE_COLUMN: "variance",
    VARIANCE_AMOUNT_COLUMN: "varianceAmount",
    POTENTIAL_ROOT_CAUSE_COLUMN:"potentialRootCause",
    ROOT_CAUSE_EXPLANATION_COLUMN:"rootCauseExplanation",
    CURRNECY_COLUMN: "currency",
    AGREEMENT_NAME_COLUMN: "agreementName",
    PROVIDER_COLUMN: "provider",
    PROVIDER_COMPANY_CODE_COLUMN: "providerCompanyCode",
    RECIPIENT_COLUMN: "recipient",
    RECIPIENT_COMPANY_CODE_COLUMN: "recipientCompanyCode",
    WORKBOOK_ASSIGNEE_COLUMN: "workbookAssignee",
    ACCOUNTING_APPROVER_1_COLUMN: "accountingApprover1",
    ACCOUNTING_APPROVER_2_COLUMN: "accountingApprover2",
    TAX_APPROVER_1_COLUMN: "taxApprover1",
    ATP_STATUS_COLUMN: "atpStatus",
    REPORTING_STATUS_COLUMN: "reportingStatus"
});

export const VARIANCE_TABLE_COLUMN_CELL_ADDRESS: {
    [key: string]: string;
} = Object.freeze({
    WORKBOOK_ID_COLUMN: "A",
    WORKBOOK_NAME_COLUMN: "B",
    CALCULATION_NUMBER_COLUMN: "C",
    CALCULATION_STATUS_COLUMN: "D",
    TEMPLATE_NAME_COLUMN: "E",
    PLI_COLUMN: "F",
    TARGET_COLUMN: "G",
    ACTUAL_YTD_COLUMN: "H",
    VARIANCE_COLUMN: "I",
    VARIANCE_AMOUNT_COLUMN: "J",
    POTENTIAL_ROOT_CAUSE_COLUMN:"K",
    ROOT_CAUSE_EXPLANATION_COLUMN:"L",
    CURRNECY_COLUMN: "M",
    AGREEMENT_NAME_COLUMN: "N",
    PROVIDER_COLUMN: "O",
    PROVIDER_COMPANY_CODE_COLUMN: "P",
    RECIPIENT_COLUMN: "Q",
    RECIPIENT_COMPANY_CODE_COLUMN: "R",
    WORKBOOK_ASSIGNEE_COLUMN: "S",
    ACCOUNTING_APPROVER_1_COLUMN: "T",
    ACCOUNTING_APPROVER_2_COLUMN: "U",
    TAX_APPROVER_1_COLUMN: "V",
    ATP_STATUS_COLUMN: "W",
    REPORTING_STATUS_COLUMN: "X"
});

export const VISIBLE_CONTENT_OPTIONS = VarianceReportTableColumns.map((column) => ({
    id: column,
    label: getCustomHeaderValue(column),
}));