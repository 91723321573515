import TPESearchRequest, {DbConjunctor, DbOperator} from "src/models/common/TPESearchRequest";
import CONSTANTS from "src/utils/constants";
import CustomCOARowsSearchRequest from "src/models/custom-coa/CustomCOARowsSearchRequest";
import ReactTableFilter from "src/models/common/ReactTableFilter";

export function getCustomCOAsSearchRequest(activeOnly: boolean = false): TPESearchRequest {
    const searchRequest: TPESearchRequest = {
        page: 1,
        pageSize: 20
    }

    if (activeOnly) {
        searchRequest.filterExpression = {
            conjunctor: DbConjunctor.AND,
            expressions: [
                {
                    attributeName: CONSTANTS.CUSTOM_COA_ROW_FIELDS.ACTIVE.SEARCH_ATTRIBUTE,
                    operator: DbOperator.EQUALS,
                    values: [CONSTANTS.CUSTOM_DATA_TABLE_STATUSES.ACTIVE]
                }
            ]
        }
    }

    return searchRequest;
}

export function getDefaultSearchPayload(): TPESearchRequest {
    return {
        page: 1,
        pageSize: 20,
    };
}

export function addCOARowsExpressionFilters(searchRequest: TPESearchRequest | CustomCOARowsSearchRequest, reactTableFilters: Array<ReactTableFilter>) {
    checkAndAddFilterExpression(searchRequest);
    reactTableFilters.forEach(filter => {
        const searchFilter = formatFilterValueBy(filter);
        searchRequest.filterExpression?.expressions.push({
            attributeName: searchFilter.id,
            operator: DbOperator.CONTAINS,
            values: [searchFilter.value]
        })
    });
}

function formatFilterValueBy(filter: ReactTableFilter): ReactTableFilter {
    const coaFields = CONSTANTS.CUSTOM_COA_ROW_FIELDS;
    const stringFilterValue = filter.value as string;
    switch (filter.id) {
        case coaFields.CLASSIFICATION.ACCESSOR:
            return {id: coaFields.CLASSIFICATION.SEARCH_ATTRIBUTE, value: stringFilterValue.toLowerCase()}
        case coaFields.UPDATED_BY.ACCESSOR:
            return {id: coaFields.UPDATED_BY.SEARCH_ATTRIBUTE, value: stringFilterValue.toLowerCase()}
        case coaFields.SEGMENT_VALUE.ACCESSOR:
            return {id: coaFields.SEGMENT_VALUE.SEARCH_ATTRIBUTE, value: stringFilterValue.toUpperCase()}
        case coaFields.ACTIVE.ACCESSOR:
            return {
                id: coaFields.ACTIVE.SEARCH_ATTRIBUTE,
                value: stringFilterValue.toUpperCase() === "Y" ? "Active" : (stringFilterValue.toUpperCase() === "N" ? "Inactive" : "Invalid")
            }
    }
    throw new Error(`Unsupported filter type: ${filter.id}`);
}

export function addTableNameFilter(searchRequest: TPESearchRequest | CustomCOARowsSearchRequest, tableName: string) {
    checkAndAddFilterExpression(searchRequest);
    searchRequest.filterExpression?.expressions.push({
        attributeName: CONSTANTS.CUSTOM_COA_RECORD_FIELDS.TABLE_NAME.SEARCH_ATTRIBUTE,
        operator: DbOperator.CONTAINS,
        values: [tableName]
    });
}

function checkAndAddFilterExpression(searchRequest: TPESearchRequest | CustomCOARowsSearchRequest) {
    if (searchRequest.filterExpression == null) {
        searchRequest.filterExpression = {
            conjunctor: DbConjunctor.AND,
            expressions: []
        }
    }
}