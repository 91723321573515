export type BreadcrumbItem = {
    text: string; href: string
}

export class PageHeaderMapping {
    pathName: string;
    pageDescription: string;
    pageTitle: string;
    defaultBreadcrumbs: BreadcrumbItem[];
    pageSidebarKey?: string;

    constructor(pathName: string, pageTitle: string, pageDescription: string, defaultBreadcrumbs: BreadcrumbItem[], pageSidebarKey?: string) {
        this.pathName = pathName;
        this.pageDescription = pageDescription;
        this.pageTitle = pageTitle;
        this.defaultBreadcrumbs = defaultBreadcrumbs;
        this.pageSidebarKey = pageSidebarKey;
    }
}
