import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Box, Button, ColumnLayout, FormField, Modal, RadioGroup, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import StringUtils from 'src/utils/stringUtils';
import { GlobalAppContext } from '../../App';
import { CalculationBuilderContext } from '../CalculationBuilderView';


export default function FileUploadModal(props: {visible: boolean, onCancel: () => void, onSuccessfulUpload: () => void}) {

    const { visible, onCancel, onSuccessfulUpload } = props;

    const { userProfile } = React.useContext(GlobalAppContext);
    const { services, calcBuilderState, calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const { calculationUploadS3Key, calculation, dataSourceRecords } = calcBuilderState;
    const hiddenFileInput = useRef(null);
    const [uploadFile, setUploadFile] = useState();
    const [fileInfo, setFileInfo] = useState(null as unknown as any);
    const [tableOption, setTableOption] = useState('new');
    const [uploadFileValid, setUploadFileValid] = useState(false);
    const [errorDisplay, setErrorDisplay] = useState('');
    const [draftInProgress, setDraftInProgress] = useState(true);
    const [uploadButtonClicked, setUploadButtonClicked] = useState(false);

    const [uploadFileResult, uploading, uploadError] = services.calculationUploadService.uploadFile(calculationUploadS3Key, uploadFile as unknown as File,
        calculation?.calculationNumber);

    useEffect(() => {
        if (uploadFileResult == null) {
            return
        }
        onSuccessfulUpload();
    }, [uploadFileResult])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(uploadError)) {
            setErrorDisplay(uploadError);
        }
    }, [uploadError])


    useEffect(() => {
        resetModal(visible, uploadButtonClicked)
    }, [visible, dataSourceRecords, calculation, uploadButtonClicked])

    function resetModal(visible: boolean, uploadButtonClicked: boolean) {
        if (!visible && !uploadButtonClicked) {
            return;
        }
        setErrorDisplay('');
        setUploadFile(undefined);
        setFileInfo(null);
        setTableOption('new');
        setUploadFileValid(false);
        setUploadButtonClicked(false);
    }

    const onChooseFileButtonClick = () => {
        // @ts-ignore
        hiddenFileInput.current.click();
        setUploadButtonClicked(true);
    }

    const uploadToS3Handler = () => {
        if (uploadFile != null) {
            //@ts-ignore
            const key = new Date().getTime() + "_" + userProfile.user + "_" + uploadFile.name.replace(' ', '_');
            calcBuilderDispatch(ACTIONS.SET_CALCULATION_UPLOAD_S3_KEY.withPayload(key));
        }
    }

    const handleInputFileChange = (e: any) => {
        setUploadFile(e.target.files[0]);
        const fileName = e.target.files[0].name;
        setFileInfo({
            name: fileName,
            size: e.target.files[0].size,
            lastModifiedDate: String(e.target.files[0].lastModifiedDate)
        });
        if (fileName.substring(fileName.lastIndexOf('.')) == '.xlsx') {
            setUploadFileValid(true);
        } else {
            setUploadFileValid(false);
        }
        e.target.value = null;
    }
    return <Modal className="fileUploadModal"
                onDismiss={onCancel}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={onCancel}>Cancel</Button>
                            <Button className="calculationUploadButton"
                                    variant="primary"
                                    disabled={!uploadFileValid || uploading || !StringUtils.isNullOrEmpty(errorDisplay) || !draftInProgress}
                                    onClick={() => uploadToS3Handler()}
                            >
                                {uploading ? 'Uploading...' : 'Upload'}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Upload Calculation">

                <SpaceBetween direction="vertical" size="s">
                    <ColumnLayout columns={2}>
                        <FormField
                            description="Upload calculation in .xlsx format."
                            label="Select file to upload"
                        >
                            <SpaceBetween direction="vertical" size="xs">
                                <Button iconName='upload' 
                                iconAlign='left' 
                                onClick={onChooseFileButtonClick}>Choose File</Button>
                                <input type='file' ref={hiddenFileInput} accept={'.xlsx'} className='hiddenFileUploadInput' onChange={handleInputFileChange} />
                                <div>
                                    {fileInfo != null &&
                                        <React.Fragment>
                                            <StatusIndicator type={uploadFileValid ? 'success' : 'error'}></StatusIndicator>
                                            <span>{fileInfo.name}</span>
                                        </React.Fragment>
                                    }
                                </div>
                            </SpaceBetween>
                        </FormField>
                    </ColumnLayout>
                {!StringUtils.isNullOrEmpty(errorDisplay) &&
                <Alert type="error">{errorDisplay || ''}</Alert>}
                </SpaceBetween>
            </Modal>
}