import React from "react";
import {Box, Button, Link, Popover, SpaceBetween, StatusIndicator, Modal} from "@amzn/awsui-components-react";
import CopyToClipboard from "src/components/CopyToClipboard";
import TPENavigator from "src/components/TPENavigator";

export default function GenerateReportsModal(props: {
    visible: boolean,
    onClose: () => void,
    reportId: string
}) {
    const {visible, onClose: onModalClose, reportId} = props;
    const [ navigateURL, setNavigateURL ] = React.useState('');

    return <Modal
        onDismiss={onModalClose}
        visible={visible}
        closeAriaLabel="Close modal"
        footer={
            <Box float="right">
                <TPENavigator path={navigateURL}/>
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" className="cancelButton" onClick={onModalClose}>Cancel</Button>
                    <Button variant="primary" className="historyButton" onClick={() => setNavigateURL('/reports/history')}>History</Button>
                </SpaceBetween>
            </Box>
        }
        header="Report Running"
    >
        Report successfully initiated with reportID:
        <CopyToClipboard
            textToBeCopied = {reportId}
            popoverMessage = {"Report Id Copied"}
        ></CopyToClipboard>
        <Link>{reportId}</Link>. Please reference your reporting history for report availability.
    </Modal>
}