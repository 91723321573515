import { Box, Icon } from '@amzn/awsui-components-react';
import React from 'react';
import { getPermissions } from '../AppPermissions';

export function ATPViewRestrictor(props: { children: any, module: string, canViewOverride?: boolean }) {
    const { module, canViewOverride } = props;
    const { canView } = getPermissions(module);

    const displayModule = canViewOverride == null ? canView : canViewOverride;

    return displayModule ? props.children : 
        <Box textAlign="center" padding="l">
            <span><Icon name="status-warning" variant="error"/>Your role is not authorized to view this page.</span>
        </Box>
}