import React, { useContext, useState } from 'react';
import {
    ButtonDropdown,
    Input, SpaceBetween,
} from '@amzn/awsui-components-react/polaris';
import 'src/assets/styles/react-table.scss';
import { TPEReactTable } from 'src/components/shared/TPEReactTable';
import CONSTANTS from 'src/utils/constants';
import ArrayUtils from 'src/utils/arrayUtils';
import { JESettingsContext } from './JESettingsContainer';
import { ACTIONS } from 'src/services/calculation-builder/JESettingsReducer';
import ReactTooltip from "react-tooltip";
import { CalculationSourceType } from 'src/models/common/CalculationSource';

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column,
    onRowUpdated, // This is a custom function that we supplied to our table instance
}: { value: any, initialValue: any, row: any, column: any, onRowUpdated: any, editorsData: any, onCellUpdated: any }) => {
    const { id } = column;
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    const [previousValue, setPreviousValue] = useState(initialValue);

    const getCellClass = (errors: Map<string, string>) => {
        const className = (original.dirtyAttributes as Set<string>)?.has(id) && previousValue !== value? "dirtyCell " : "";
        return  className + (errors.has(id)? "invalidEditableCell" : "editableCell");
    }

    const onChange = (e: any) => {
        setValue(e.detail.value);
    }
    
    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        onRowUpdated(original.journalId, original.journalLineId, id, value);
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    
    if (!(original.editableFields || []).includes(id)) {
        return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{value}</div>;
    }
    const errors = original.errors == null? new Map<string, string>() : original.errors as Map<string, string>;
    let errorText = '';
    if (errors.size > 0){
        errorText = errors.get(id) || '';
    }
    
    return <span data-tip={errorText}>
            <Input key={`${id}_${index}`} className={getCellClass(errors) + ` ${id}_${original.journalId}_${original.journalLineId}_input`} value={value} onChange={onChange} onBlur={onBlur} />
        </span>;
}

const ReadOnlyCell = ({
    value: initialValue,
    row: { index, original },
    column,
}: { value: any, initialValue: any, row: any, column: any }) => {
    const { id } = column;
    // We need to keep and update the state of the cell normally
    
    return <div key={`${id}_${index}`} className={"cell-text " + id + "Cell"}>{initialValue}</div>;    
}

// Set our editable cell renderer as the default Cell renderer
const defaultCellEditor = {
    Cell: EditableCell,
}

const readOnlyCellRenderer = {
    Cell: ReadOnlyCell,
}

export default function JournalEntriesGrid(props: { editable: boolean }) {
    const { state, dispatch, calculationSource } = useContext(JESettingsContext);
    const { journals = [] } = state;
    const {editable} = props;


    const getColumnDefinitions = () => {
        const colDefs: any[] = [];
        Object.values(CONSTANTS.JE_FIELDS).forEach((x) => {
            if (
                (x.ACCESSOR === CONSTANTS.JE_FIELDS.AGREEMENT_LINE_NUM.ACCESSOR && calculationSource === CalculationSourceType.ICRS) || 
                (x.ACCESSOR === CONSTANTS.JE_FIELDS.ICRS_REGISTRATION_NUM.ACCESSOR && calculationSource === CalculationSourceType.APTTUS)){
                // Do nothing
            }
            else {
                colDefs.push({
                    accessor: x.ACCESSOR,
                    Header: (e: any) => <div className="cell-text-non-sortable">{x.DISPLAY}</div>,
                    headerClass: x.MANDATORY ? "" : "greenTableHeader"
                });
            }
        });
        return colDefs;
    }

    const getJournalLines = () => {
        const jeRows: any[] = [];
        const journalCurrencyAccessor = CONSTANTS.JE_FIELDS.JOURNAL_CURRENCY.ACCESSOR;
        const editableFields = Object.values(CONSTANTS.JE_FIELDS).filter((x) => 
            x.SOURCE == CONSTANTS.JE_FIELD_SOURCE_TYPE.USER_ENTERED && (x.ACCESSOR != journalCurrencyAccessor || 
                (x.ACCESSOR == journalCurrencyAccessor && calculationSource === CalculationSourceType.ICRS))
        ).map((y) => y.ACCESSOR);
        journals.forEach((x) => {
            jeRows.push({ colspan: true, content: <JESectionHeader journalType={x.journalType} />});
            if (x.journalType == CONSTANTS.JOURNAL_TYPES.AUTO_INDIRECT_TAX) {
                x.journalLines.forEach(y => jeRows.push(y));
            } else {
                x.journalLines.forEach(y => jeRows.push({...y, editableFields }))
            }
        });
        return jeRows;
    }

    const updateJournalAttribute = (journalId: number, journalLineId: number, attribute: string, value: string) => {
        dispatch(ACTIONS.UPDATE_JOURNAL_ATTRIBUTE.withPayload({ journalId, journalLineId, attribute, value}));
    }

    const columnDefinitions = React.useMemo(getColumnDefinitions, [journals, editable]);
    const data = React.useMemo(getJournalLines, [journals]);
    
    return (
        <div className="dataSourceTableContainer">
            <ReactTooltip place="top" type="error" effect="solid" event="mouseenter" eventOff="mouseleave" />
            <TPEReactTable {...{
                data,
                columnDefinitions,
                className: "nonGridTable jeSettings",
                sortable: false,
                highlightErrorCells: true,
                editableOptions:  {
                    defaultColumn: editable? defaultCellEditor : readOnlyCellRenderer,
                    onRowUpdated: updateJournalAttribute,
                    skipPageReset: true,
                },
            }} />
        </div>
    );
}

function JESectionHeader(props: {journalType: string}) {
    const {journalType} = props;
    switch (journalType) {
        case CONSTANTS.JOURNAL_TYPES.TP_CHARGE:
            return <div className="tpChargeHeader">Current Month TP Charge</div>
        case CONSTANTS.JOURNAL_TYPES.TP_CHARGE_BASE:
            return <div className="tpChargeBaseHeader">Current Month TP Charge - Base</div>
        case CONSTANTS.JOURNAL_TYPES.TP_CHARGE_MARKUP:
            return <div className="tpChargeMarkupHeader">Current Month TP Charge - Markup</div>
        case CONSTANTS.JOURNAL_TYPES.AUTO_INDIRECT_TAX:
            return <div className="autoIndirectTaxHeader">Auto Indirect Tax</div>
        case CONSTANTS.JOURNAL_TYPES.MANUAL_INDIRECT_TAX:
            return (
                <SpaceBetween direction="horizontal" size="xxl">
                    <div className="manualIndirectTaxHeader">Manual Indirect Tax</div>
                    <SpaceBetween direction="horizontal" size="xs">
                        <ButtonDropdown className="polarisSmallerButtonDropdown" onItemClick={(event: any) => {}}
                            items={[{text: 'VAT', id: '1'}, {text: 'Tax Type 2', id: '2'}]}>
                            VAT
                        </ButtonDropdown>
                        <ButtonDropdown className="polarisSmallerButtonDropdown" onItemClick={(event: any) => {}}
                            items={[{text: '9%', id: '9'}, {text: '12%', id: '12'}]}>
                            9%
                        </ButtonDropdown>
                    </SpaceBetween>
                </SpaceBetween>
            )
        default:
            return <div></div>
    }
}