import SearchAgreementLOVsResult from "src/models/search-agreements/SearchAgreementLOVsResult";
import {CLI} from '../../models/common/CLI';
import SavedSearch from "../../models/calculations/SavedSearch"
import TemporaryMessage from "src/models/common/TemporaryMessage"
import { MoreOptionsItem } from "src/models/calculations/SavedSearchModel";
import SearchSortingSpec from "src/models/common/SearchSortingSpec";
import TPESearchRequest from "src/models/common/TPESearchRequest";
import {TPEUser} from "../../models/users/TPEUser"


export interface SearchCalculationsState {
    selectedAgreement?: string,
    selectedAgreementLine?: string,
    selectedCalculationLine?: string,
    agreementLines: Array<SearchAgreementLOVsResult>,
    calculationLines: Array<SearchAgreementLOVsResult>,
    searchResultItems?: Array<CLI>
    isLoading: boolean,
    searchParams?: TPESearchRequest,
    error: string,
    searchResultsIsVisible: boolean,
    moreOptionsModalIsVisible: boolean,
    otherOptions: Array<MoreOptionsItem>,
    savedSearches: Array<SavedSearch>,
    isSaveSearchModalVisible: boolean,
    selectedSavedSearch?: SavedSearch,
    lastSavedSearchTimestamp?: Date;
    temporaryMessage?: TemporaryMessage,
    selectedTaxOwner: TPEUser,
    selectedCalculationLineNumber: string,
    selectedFirstParty: string,
    selectedSecondParty: string,
    selectedAccountingOwner: TPEUser | null,
    selectedAgreementStatus: string,
    selectedCalculationStatus: string,
    selectedCalculationName: string,
    selectedLastUpdatedOn: string,
    searchCurrentPage: number;
    searchPageSize: number;
    searchTotalSize: number;
    searchSorting?: SearchSortingSpec;
    searchFilters?: Array<{ id: string, value: any }>;
}

export const initialState: SearchCalculationsState = {
    isLoading: true,
    agreementLines: [],
    calculationLines: [],
    error: '',
    searchResultsIsVisible: false,
    moreOptionsModalIsVisible: false,
    searchParams: new TPESearchRequest(),
    otherOptions: [],
    savedSearches: [],
    isSaveSearchModalVisible: false,
    searchCurrentPage: 1,
    searchPageSize: 20,
    searchTotalSize: 0,
    selectedFirstParty: '',
    selectedSecondParty: '',
    selectedAccountingOwner: null,
    selectedAgreementStatus: '',
    selectedCalculationStatus: '',
    selectedCalculationName: '',
    selectedCalculationLineNumber: '',
    selectedTaxOwner: {} as TPEUser,
    selectedLastUpdatedOn: ''
};