import { Select } from "@amzn/awsui-components-react";
import React from "react";
import CONSTANTS from "../../../utils/constants";

export const COA_SEGMENTS_OPTIONS = Object.keys(CONSTANTS.COA_SEGMENT_MAPPING).map(x => {
    var segment = (CONSTANTS.COA_SEGMENT_MAPPING as any)[x];
    return { label: segment.UI, value: segment.UI }
});

export default function CoaSegmentSelector(props:
    {
        segment:string, 
        isEditable:boolean, 
        alreadySelected: string[], 
        onSegmentChanged: (oldSegment:string, newSegment?: string) => void
    }){
    const {segment, alreadySelected, onSegmentChanged} = props;
    const [
        selectedOption,
        setSelectedOption
      ] = React.useState(COA_SEGMENTS_OPTIONS.find(x => x.value === segment) || null as unknown as any);
    
    return <div className="placeholderCell placeholderCoaSelector">
        <Select data-class="basicDropdown"
            selectedOption={selectedOption}
            onChange={({ detail }) => {
                setSelectedOption(detail.selectedOption);
                onSegmentChanged(segment, detail.selectedOption.value);
            }}
            placeholder="Select COA segment"
            options={COA_SEGMENTS_OPTIONS.filter(x => !alreadySelected.includes(x.value))}            
        />
    </div>
}