import './polyfills';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import AppRestrict from './components/AppRestrict';
import { ensureAuthenticated, getUserIdentityFromCognitoSession } from './utils/auth/authenticate';
import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
import './index.scss';
import './assets/styles/polaris-alert.css';
import './assets/styles/polaris-grid.css';
import './assets/styles/polaris-expandable-section.scss';
import './assets/styles/polaris-small-button-dropdown.scss';
import './assets/styles/polaris-small-popover.scss';
import { BrowserRouter } from 'react-router-dom';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import '@amzn/awsui-global-styles/polaris.css';
import ServiceCollection from './services/ServiceCollection';
import CONSTANTS from './utils/constants';
import AWS from 'aws-sdk';


function startApp() {
  // The Suspense component causes React to display the fallback KatSpinner until
  // the i18n translation strings have been loaded.
  ensureAuthenticated()
    .then((auth: any) => {
      AWS.config.update({
        region: CONSTANTS.ENVIRONMENT_VARIABLES.AWS_REGION,
      });
      const userIdentity = getUserIdentityFromCognitoSession(auth.getSignInUserSession());
      const session = auth.getSignInUserSession();
      const jwtToken = session.getIdToken().getJwtToken();
      const loginProvider = `cognito-idp.${CONSTANTS.ENVIRONMENT_VARIABLES.AWS_REGION}.amazonaws.com/${CONSTANTS.ENVIRONMENT_VARIABLES.USER_POOL_ID}`;
      const credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: CONSTANTS.ENVIRONMENT_VARIABLES.IDENTITY_POOL_ID,
          Logins: {
              [loginProvider]: jwtToken,
          },
      });
      AWS.config.update({
        credentials: credentials
      });
      (AWS.config.credentials as AWS.Credentials)?.get(x => {
        console.log("AWS Credentials are set!");
      })
      ReactDOM.render(
        <AppInitWrapper>
          <Suspense fallback={<Spinner size='large' />}>
            <BrowserRouter>
              <App serviceCollection={new ServiceCollection()} userIdentity={userIdentity} />
            </BrowserRouter>
          </Suspense>
        </AppInitWrapper>,
        document.getElementById('root')
      );
    })
    .catch((e: any) => {
      console.log('ensureAuthenticated threw an exception: ', e);
      ReactDOM.render(
        <BrowserRouter>
          <AppRestrict />
        </BrowserRouter>,
        document.getElementById('root'),
      );
    });
}

startApp();
