import React, { useState } from "react";
import { Autosuggest } from "@amzn/awsui-components-react";

export default function ATPCompaniesAutosuggest(props: { className: string, 
    companies: string[] | undefined,
    selectedCompanies: string[],
    initialValue: string,
    isEditing: boolean,
    onBlur: (value: string) => any 
}) {
    const { className, companies, initialValue, onBlur, isEditing } = props;
    const [options, setOptions] = useState([] as any[]);
    const [value, setValue] = useState(initialValue);

    const handleLoadItems = (event: any) => {
        let { filteringText } = event.detail;

        if (filteringText != null && filteringText.includes(',')) {
            filteringText = filteringText.substring(filteringText.lastIndexOf(',')).replace(',', '').replace(' ', '');
        }

        if (filteringText == null || filteringText == '') {
            return;
        }

        const filteredOptions = companies?.filter(x => x.toLowerCase().startsWith(filteringText.toLowerCase())).map((val) => { return {value: val} }) || [];
        setOptions([...filteredOptions]);
    }

    const beautifyCOAValue = () => {
        if (value == null) {
            return '';
        }
        const valueArr = value.split(',');
        // Trim and remove duplicates
        const beautifiedArr = [...new Set(valueArr.filter(x => x.trim() != '').map(x => x.trim()))];
        const beautifiedString = beautifiedArr.join(', ');
        setValue(beautifiedString);
        return beautifiedString;
    }

    const handleChange = (selectedValue: any) => {
        setValue(selectedValue);
    }

    const handleSelect = (selectedValue: string) => {
        if (value.includes(',')) {
            const newValue = value.substring(0, value.lastIndexOf(',')) + ', ' +  selectedValue;
            setValue(newValue);
        } else {
            setValue(selectedValue);
        }
    }

    return (
        <Autosuggest
            data-class={className}
            onChange={({ detail }) => handleChange(detail.value)}
            onBlur={() => { onBlur(beautifyCOAValue()); }}
            onSelect={({ detail }) => handleSelect(detail.value)}
            value={value}
            options={options}
            enteredTextLabel={val => `Use: "${val}"`}
            placeholder="Select or enter comma-separated values"
            empty="No matches found"
            loadingText="Loading"
            onLoadItems={handleLoadItems}
            filteringType="manual"
            statusType={companies == undefined ? 'loading' : 'finished'}
            disabled={!isEditing}
        />
    )
}