import TPESearchRequest from "src/models/common/TPESearchRequest"
import { SearchCustomCOAResult } from "src/models/custom-coa/SearchCustomCOAResult"

export interface CustomCOAState {
    error?: string,
    s3Key?: string,
    searchPayload: TPESearchRequest,
    searchResult?: SearchCustomCOAResult,
}

export const defaultSearchPayload = {
    page: 1,
    pageSize: 20,
}

export const initialState: CustomCOAState = {
    searchPayload: defaultSearchPayload
}
