import React, {useContext, useMemo} from 'react';
import {TPEReactTable} from 'src/components/shared/TPEReactTable';
import {PreValidationContext} from './PreValidationView';
import CONSTANTS from 'src/utils/constants';

export default function PreValidationGrid(props: {loading: boolean}) {
    const { loading } = props;
    const { state } = useContext(PreValidationContext);
    const { category, preValidationResult, emptyTableMessage} = state;

    const getColumnHeaders = () => {
        switch (category) {
            case CONSTANTS.MEC_PREVALIDATION_CATEGORIES.COA_CHANGES:
                return CONSTANTS.MEC_COA_CHANGES_COLUMN_HEADERS;

            case CONSTANTS.MEC_PREVALIDATION_CATEGORIES.CLI_PTA_DATA:
            case CONSTANTS.MEC_PREVALIDATION_CATEGORIES.CLI_CDT_DATA:
                return CONSTANTS.MEC_CLI_PTA_CDT_DATA_COLUMN_HEADERS;

            case CONSTANTS.MEC_PREVALIDATION_CATEGORIES.INDIRECT_TAX_CHANGES:
                return CONSTANTS.MEC_INDIRECT_TAX_CHANGES_COLUMN_HEADERS;

            default:
                return [];
        }
    };

    const columnHeaders = useMemo(getColumnHeaders, [category]);

    const getColDefs = () => {
        return useMemo(() => {
            return columnHeaders.map(column => {
                // For each character, 10 pixels are assigned, but the minimum width will be 200 pixels.
                const dynamicWidth = Math.max(column.DISPLAY.length * 10, 200);
                return {
                    headerClass: `columnWidth${dynamicWidth}`,
                    Header: (e: any) => <div className="cell-text">{column.DISPLAY}</div>,
                    accessor: column.ACCESSOR
                };
            });
        }, [category]); // Memoize based on report name
    };

    return (
        <div className="mecPreValidationReportGrid">
            {preValidationResult && <TPEReactTable {...{
                data: preValidationResult.records,
                emptyMessage: emptyTableMessage,
                columnDefinitions: getColDefs(),
                className: "nonGridTable mecPreValidationReportGrid",
                isSearching: loading,
                editableOptions: null as unknown as any
            }} />}
        </div>
    );
}