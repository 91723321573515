import React, { useEffect } from "react";
import { Journal } from "src/models/calculation-builder/Journal";
import CONSTANTS from "src/utils/constants";
import { CalculationBuilderContext } from "../CalculationBuilderView";
import { JESettingsContext } from "./JESettingsContainer";
import { ACTIONS } from "src/services/calculation-builder/JESettingsReducer";
import { Alert, Box } from "@amzn/awsui-components-react";
import { isValid } from "date-fns";


export default function JE7SegmentsValidator() {
    const { calcBuilderState } = React.useContext(CalculationBuilderContext);
    const { sevenSegmentLOV, calculation, tpLOV } = calcBuilderState;
    const { state, dispatch } = React.useContext(JESettingsContext);
    const { journals, needsValidation, validationErrors } = state;

    const validateJournalCurrency = (value: string, errorsMap: Map<string, string>) => {
        if (value == null || value.trim().length === 0) {
            errorsMap.set(CONSTANTS.JE_FIELDS.JOURNAL_CURRENCY.ACCESSOR, `${CONSTANTS.JE_FIELDS.JOURNAL_CURRENCY.DISPLAY} is required`);
            return false;
        }
        if (!tpLOV.currencyCodes.includes(value)) {
            errorsMap.set(CONSTANTS.JE_FIELDS.JOURNAL_CURRENCY.ACCESSOR, `Invalid ${CONSTANTS.JE_FIELDS.JOURNAL_CURRENCY.DISPLAY}`);
            return false;
        }
        return true;
    }

    const validateDate = (attribute: string, attributeKey: string, value: string, errorsMap: Map<string, string>) => {
        if (value == null || value.trim().length === 0) {
            errorsMap.set(attribute, `${attributeKey} is required`);
            return false;
        }
        if (!isValid(new Date(value.trim()))) {
            errorsMap.set(attribute, 'Invalid date');
            return false;
        }
        return true;
    }

    const validateRequiredAttribute = (attribute:string, lovKey: string, value: string, errorsMap: Map<string, string>) => {
        if (value == null || value.trim().length === 0){
            errorsMap.set(attribute,`${lovKey} is required`);
            return false;        
        }
        return true;
    };

    const validateSegmentAttribute = (attribute:string, lovKey: string, line: any, errorsMap: Map<string, string>) => {
        if (!sevenSegmentLOV.get(lovKey)?.includes(line[attribute])) {
            errorsMap.set(attribute,`Invalid ${lovKey}`);
            return false;
        }
        return true;
    };

    const validateProjectCode = (attribute:string, lovKey: string, journal: Journal) : number => {
        // If calculation is Non-BEAT there is no restriction on Project Code
        const beat = calculation?.beat;
        if (beat == null || beat === CONSTANTS.BEAT_TYPES.NA){
            return 0;
        }
        const journalTypeMapping = (CONSTANTS.BEAT_JOUNAL_TYPE_TO_PROJECT_CODE_MAPPING as any)[beat];
        
        if (journalTypeMapping == null){
            return 0;
        }
        const projectCodeAllowed = journalTypeMapping[journal.journalType];
        
        if (projectCodeAllowed == null){
            return 0;
        }
        let invalidProjectCodeCount = 0;
        journal.journalLines.forEach(l => {
            const hasValidProject = !l.errors.has(attribute);
            
            if (hasValidProject && l.projectCode !== projectCodeAllowed){
                l.errors.set(attribute,`Invalid ${lovKey} for Accounting Output "${beat}"`);
                l.isValid = false;
                invalidProjectCodeCount++;
            }
        })
        return invalidProjectCodeCount;
    };

    useEffect(() => {
        if (!needsValidation){
            return;
        }
        let allJournalsAreValid = true;
        let localErrorsCount = 0;
        journals.forEach(j => {
            j.journalLines.forEach(l => {
                const errorsMap = new Map<string, string>();            
                const mapping   = (CONSTANTS.COA_SEGMENT_MAPPING as any);
                let lineIsValid = true;
                Object.keys(mapping).forEach(mappingKey => {
                    const lovKey     = mapping[mappingKey]?.UI;
                    const attribute  = mapping[mappingKey]?.VALIDATION;
                    const value      = (l as any)[attribute];
                    if (!validateRequiredAttribute(attribute, lovKey, value, errorsMap)){
                        lineIsValid = false;
                        localErrorsCount++;
                    }
                    else if (!validateSegmentAttribute(attribute, lovKey, l, errorsMap)) {
                        lineIsValid = false;
                        localErrorsCount++;
                    }
                })

                // Validate journal currency
                if (!validateJournalCurrency((l as any)[CONSTANTS.JE_FIELDS.JOURNAL_CURRENCY.ACCESSOR], errorsMap)) {
                    lineIsValid = false;
                    localErrorsCount++;
                }

                // Validate accounting and currency conversion dates
                if (!validateDate(CONSTANTS.JE_FIELDS.ACCOUNTING_DATE.ACCESSOR,
                                  CONSTANTS.JE_FIELDS.ACCOUNTING_DATE.DISPLAY,
                                  (l as any)[CONSTANTS.JE_FIELDS.ACCOUNTING_DATE.ACCESSOR],
                                  errorsMap)) {
                    lineIsValid = false;
                    localErrorsCount++;
                }

                if (!validateDate(CONSTANTS.JE_FIELDS.CURRENCY_CONV_DATE.ACCESSOR,
                    CONSTANTS.JE_FIELDS.CURRENCY_CONV_DATE.DISPLAY,
                    (l as any)[CONSTANTS.JE_FIELDS.CURRENCY_CONV_DATE.ACCESSOR],
                                    errorsMap)) {
                    lineIsValid = false;
                    localErrorsCount++;
                }

                l.isValid = lineIsValid;
                l.errors = errorsMap;
                if (lineIsValid){
                    l.dirtyAttributes = new Set();
                    l.isDirty = false;
                }
                else {
                    allJournalsAreValid = false;
                }
            })
            
            const projectAttribute       = CONSTANTS.COA_SEGMENT_MAPPING.PROJECT.VALIDATION;
            const projectLovKey          = CONSTANTS.COA_SEGMENT_MAPPING.PROJECT.UI;
            const projectCodeErrorsCount = validateProjectCode(projectAttribute, projectLovKey, j);            
            if (projectCodeErrorsCount > 0){
                allJournalsAreValid = false;
                localErrorsCount += projectCodeErrorsCount;
            }
        })

        dispatch(ACTIONS.SET_JOURNALS.withPayload([...journals]));
        // Resetting back to false after validating
        dispatch(ACTIONS.SET_JOURNALS_NEED_VALIDATION.withPayload(false));
        dispatch(ACTIONS.SET_JOURNALS_ARE_VALID.withPayload(allJournalsAreValid));
        if (localErrorsCount > 0) {
            const errorMessage = `${localErrorsCount} error${localErrorsCount > 1? "s" : ""} found. Please fix errors and try again.`;
            dispatch(ACTIONS.SET_VALIDATION_ERRORS.withPayload([errorMessage]))
        } else {
            dispatch(ACTIONS.SET_VALIDATION_ERRORS.withPayload(null));
        }
    }, [needsValidation]);


    return validationErrors != null && validationErrors.length > 0 ? 
        <Box padding={{top:"m", left:"xxxl", right:"xxxl", bottom:"xxl"}} margin={{left:"xxxl", right:"xxxl"}}>
            <Alert
                dismissAriaLabel="Close alert"
                type="error"
                header="Journal validation failed"
            >
                <ul>
                    {validationErrors.map(x => <li className="validation-error-list-item">{x}</li>)}
                </ul>
            </Alert>
        </Box>
        :
        <span></span>;
}