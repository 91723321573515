import moment from "moment";

export default class FormattingService {
    public formatString(number: string, showDecimals: boolean = true, numberOfDecimals: number = 2): string {
        const numberFormatInput = number == null ? 0 : Math.abs(Number(number));
        const isShowDecimals = showDecimals || (numberFormatInput > 0 && numberFormatInput < 1)
        const decimalsToShow = isShowDecimals ? numberOfDecimals : 0;
        return Intl.NumberFormat(navigator.language, {
            minimumFractionDigits: decimalsToShow < 2? decimalsToShow: 2,
            maximumFractionDigits: decimalsToShow,
        }).format(Number.parseFloat(number))
    }

    public formatNumber(number: number, showDecimals: boolean = true, numberOfDecimals: number = 2): string {
        return this.formatString(number.toString(), showDecimals, numberOfDecimals);
    }

    public formatTimestamp(timestamp: number | string): string {
        return timestamp == null ? '' : moment(timestamp).format("YYYY-MM-DD HH:mm");
    }
}