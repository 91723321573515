import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import StepsWizardBar from '../../shared/StepsWizardBar';
import 'src/assets/styles/steps-wizard.scss';
import 'src/components/tp-allocation/shared/TPAllocationBuilder.scss';
import { getTemplateInitialState, initialState } from '../../../services/tp-allocation/TPAllocationState';
import { tpAllocationReducer, ACTIONS } from '../../../services/tp-allocation/TPAllocationReducer';
import ServiceCollection from 'src/services/ServiceCollection';

import useReducerWithLogger from '../../../services/utils/ReducerWithLogger';
import { GlobalAppContext } from '../../App';
import { TPEBasicModal, ACTION_TYPE } from '../../shared/TPEBasicModal';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { WizardStep } from '../../../models/common/WizardStep';
import StringUtils from 'src/utils/stringUtils';
import DynamicBreadcrumb from 'src/models/navigation/DynamicBreadcrumb';
import { GLOBAL_ACTIONS } from 'src/services/global/GlobalReducer';
import WizardStepContentWrapper from 'src/components/calculation-builder/WizardStepContentWrapper';
import { TPAllocationContext } from '../../../services/tp-allocation/TPAllocationContext';
import TPAllocationStatusBar from '../shared/TPAllocationStatusBar';
import DatasetContainer from '../shared/DatasetContainer';
import CONSTANTS from 'src/utils/constants';
import TotalsContainer from '../shared/TotalsContainer';
import AllocationGroupsContainer from '../shared/AllocationGroupsContainer';
import PlaceholdersContainer from '../shared/PlaceholdersContainer';
import TPAllocationActionBar from '../shared/TPAllocationActionBar';
import TPEErrorWatcher from 'src/components/shared/TPEErrorWatcher';
import { getPermissions } from 'src/components/AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';

export const TPAllocationProvider = (props: any) => {
    const { state, dispatch, services, userProfile, children } = props;
    const providerValue = React.useMemo(() => ({
        tpAllocationState: state, tpAllocationDispatch: dispatch, services, userProfile
    }), [state, dispatch]);
    return (
        <TPAllocationContext.Provider value={providerValue}>
            {children}
        </TPAllocationContext.Provider>
    );
}

export default function WorksheetTemplateView(props: { services: ServiceCollection }) {
    const { pathname } = useLocation();
    const { encodedTemplateId } = useParams< { encodedTemplateId: string }>();
    const [templateId, setTemplateId] = useState('');

    const [state, dispatch] = useReducerWithLogger(tpAllocationReducer, initialState, getTemplateInitialState);
    const { globalDispatch, userProfile } = React.useContext(GlobalAppContext);
    const { services } = props;
    const { canEdit } = getPermissions(AppModules.TP_ALLOCATION);

    const {
        wizardSteps,
        selectedWizardStep,
        error:tpAllocationLevelError,
        viewMode,
        builderState,
        entityDetailsNeedRefresh
    } = state;
    const ALL_WIZARD_STEPS = {
        PLACEHOLDERS: wizardSteps[0],
        DATASET: wizardSteps[1],
        TOTALS: wizardSteps[2],
        GROUPS: wizardSteps[3]
    }

    //global.PRINT_REDUCER_LOGS = false;
    const [doRefreshDetails, setDoRefreshDetails] = useState('initial');
    useEffect(() => {
        if (CONSTANTS.VIEW_MODE.READ_ONLY === viewMode){
            dispatch(ACTIONS.ENABLE_ALL_WIZARD_STEPS);
        }
    }, [viewMode])

    const [templateDetailsResult, isLoading, templateDetailsError] = services.tpAllocationService.getWorksheetTemplateDetails(doRefreshDetails, templateId);

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(encodedTemplateId)){
            return;
        }
        const id = atob(encodedTemplateId);
        setTemplateId(id)

    },[encodedTemplateId])

    useEffect(() => {
        if (templateDetailsResult == null){
            return;
        }
        globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, templateDetailsResult.templateName, templateDetailsResult.templateName)));
        dispatch(ACTIONS.SET_WORKSHEET_TEMPLATE.withPayload(templateDetailsResult));
        dispatch(ACTIONS.SET_BUILDER_STATE.withPayload(templateDetailsResult.builderState || CONSTANTS.TP_ALLOCATION_BUILDER_STATE.PLACEHOLDERS));
        if (canEdit) {
            if (templateDetailsResult.status == CONSTANTS.CALCULATION_STATUS.PENDING_REVIEW) {
                dispatch(ACTIONS.SET_VIEW_MODE.withPayload(CONSTANTS.VIEW_MODE.READ_ONLY));
            } else {
                dispatch(ACTIONS.SET_VIEW_MODE.withPayload(CONSTANTS.VIEW_MODE.EDITABLE));
            }
        } else {
            dispatch(ACTIONS.SET_VIEW_MODE.withPayload(CONSTANTS.VIEW_MODE.FROZEN));
        }
    }, [templateDetailsResult, canEdit])

    useEffect(() => {
        if (entityDetailsNeedRefresh) {
            setDoRefreshDetails((new Date()).toString());
        }
    }, [entityDetailsNeedRefresh])

    useEffect(() => {
        if (builderState === CONSTANTS.TP_ALLOCATION_BUILDER_STATE.DATASOURCES) {
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(ALL_WIZARD_STEPS.DATASET));
        } else if (builderState === CONSTANTS.TP_ALLOCATION_BUILDER_STATE.TOTALS){
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(ALL_WIZARD_STEPS.TOTALS));
        } else if (builderState === CONSTANTS.TP_ALLOCATION_BUILDER_STATE.ALLOCATION_GROUPS){
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(ALL_WIZARD_STEPS.GROUPS));
        }
    }, [builderState])

    const isStepNumberSelected = function (number: number): boolean {
        if (wizardSteps == null || wizardSteps.length === 0 || selectedWizardStep == null) {
            return false;
        }
        return selectedWizardStep.number === number;
    }

    const isStepNumberEnabled = function (number: number): boolean {
        if (wizardSteps == null || wizardSteps.length === 0 || selectedWizardStep == null) {
            return false;
        }
        const step = wizardSteps.find(x => x.number === number);

        return step != null && step.isEnabled;
    }

    const onStepChanged = function (step: WizardStep) {
        // If "Select TP Agreements" is selected
        if (step.number === 1 && CONSTANTS.VIEW_MODE.EDITABLE === viewMode) {
            dispatch(ACTIONS.RESET_TEMPLATE_STATE);
        }
        else {
            dispatch(ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(step));
        }
    }

    return (
        <Box padding={{top:"xxl"}}>
            <TPAllocationProvider services={services} state={state} dispatch={dispatch} userProfile={userProfile}>
                <div className='stepsWizard'>
                    <SpaceBetween direction="vertical" size="m">
                        <div key="stepsStripContent" className="stepsStripContainer">
                            <StepsWizardBar  {...{selectedWizardStep, wizardSteps, onStepChanged}} />
                        </div>
                        <TPAllocationStatusBar loading={isLoading} />
                        {isStepNumberEnabled(1) &&
                            <WizardStepContentWrapper wizardStep={wizardSteps[0]}>
                                <PlaceholdersContainer expanded={isStepNumberSelected(1)} containerTitle={`Step 1: ${wizardSteps[0].name}`} onCancel={() => {}} isForLinkedWorksheet={false} />
                            </WizardStepContentWrapper>
                        }
                        {isStepNumberEnabled(2) &&
                            <WizardStepContentWrapper wizardStep={wizardSteps[1]}>
                                <DatasetContainer expanded={isStepNumberSelected(2)} containerTitle={`Step 2: ${wizardSteps[1].name}`} onCancel={() => {}} />
                            </WizardStepContentWrapper>
                        }
                        {isStepNumberEnabled(3) &&
                            <WizardStepContentWrapper wizardStep={wizardSteps[2]}>
                                <TotalsContainer expanded={isStepNumberSelected(3)} containerTitle={`Step 3: ${wizardSteps[2].name}`} onCancel={() => {}} />
                            </WizardStepContentWrapper>
                        }
                        {isStepNumberEnabled(4) &&
                            <WizardStepContentWrapper wizardStep={wizardSteps[3]}>
                                <AllocationGroupsContainer expanded={isStepNumberSelected(4)} containerTitle={`Step 4: ${wizardSteps[3].name}`} onCancel={() => {}} />
                            </WizardStepContentWrapper>
                        }
                        {isStepNumberEnabled(4) && viewMode != CONSTANTS.VIEW_MODE.FROZEN && 
                            <TPAllocationActionBar />
                        }
                    </SpaceBetween>
                </div>
            </TPAllocationProvider>
            <TPEBasicModal
                className="tpAllocationLevelErrorModal"
                visible={tpAllocationLevelError != null}
                action={ACTION_TYPE.OK_ONLY}
                title="TP Allocation Error"
                onCancel={() => dispatch(ACTIONS.SET_ERROR.withPayload(null))}
                onConfirm={() => dispatch(ACTIONS.SET_ERROR.withPayload(null))}
            >
                <div className="calcBuilderLevelMessage">{tpAllocationLevelError}</div>
            </TPEBasicModal>
            <TPEErrorWatcher services={services} errors={[templateDetailsError]} />
        </Box>
    );
}