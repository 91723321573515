import { Popover, Icon, Input, ButtonDropdown, ButtonDropdownProps, Alert } from "@amzn/awsui-components-react";
import React, { useEffect } from "react";
import FormulaExpressionToolbar from "src/components/shared/FormulaExpressionToolbar";
import { TPEReactTable } from "src/components/shared/TPEReactTable";
import CONSTANTS from "src/utils/constants";
import { TotalsContext } from "./TotalsContainer";
import WorksheetTotal from "src/models/tp-allocation/WorksheetTotal"
import { renderExpressionParts } from "src/components/shared/FormulaExpressionFunctionComponents";
import { SavingStatus } from "src/models/common/SavingStatus";
import { ACTIONS } from "src/services/tp-allocation/totals/WorksheetTotalsReducer";
import { ACTIONS as TP_ALLOCATION_ACTIONS } from "src/services/tp-allocation/TPAllocationReducer";
import DomUtils from "src/utils/DomUtils";
import { WorksheetMode } from "src/models/tp-allocation/TPAllocationEnums";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import { Row } from "react-table";
import { ACTION_TYPE, TPEBasicModal } from "src/components/shared/TPEBasicModal";
import StringUtils from "src/utils/stringUtils";
import ATPTextAreaAutoComplete from "src/components/shared/ATPTextAreaAutoComplete";
import SimpleUseEffect from "src/components/shared/SimpleUseEffect";
import { TPAllocationContext } from "src/services/tp-allocation/TPAllocationContext";



// Cell Editors
const EditableCell = ({
    value: initialValue,
    row: { index, original },
    column: { id },
    onRowUpdated, // This is a custom function that we supplied to our table instance
    editorsData, // Editors data contains data needed to render the editors
}: { value: any, initialValue: any, row: any, column: any, onRowUpdated: any, editorsData: any}) => {
    const [value, setValue] = React.useState(initialValue);
    
    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    
    const onTextAreaBlur = (e: any) => {
        onRowUpdated(index, id, e.textAreaValue);
    }

    const onBlur = () => {
        onRowUpdated(index, id, value)
    }

    const onChange = (e: any) => {
        setValue(e.detail.value);
    }

    const total = original as WorksheetTotal;
    const allTotals = editorsData.totalsList as WorksheetTotal [];

    if (total.isBeingEdited){
        switch (id) {
            case CONSTANTS.WORKSHEET_TOTAL_FIELDS.OVERVIEW: {
                const totalsAbove = allTotals.filter(x => parseFloat(x.visibleSequence || '0') < parseFloat(total.visibleSequence || '0'))
                    .map(x => ({name: `S${x.visibleSequence}.${x.description}`, value: x.description, type: 'Previous Totals'}))
                    editorsData.suggestionsList = totalsAbove.concat(editorsData.suggestionsList);
                return <ATPTextAreaAutoComplete 
                            key={`${id}_${index}`}
                            editorsData={editorsData} 
                            initialValue={initialValue}
                            onTextAreaBlur={onTextAreaBlur} />    
            }
            case CONSTANTS.WORKSHEET_TOTAL_FIELDS.DESCRIPTION:
                return <div key={`${id}_${index}`} className={`save-status-${total.savingStatus} cell-text ${id}Cell`}>
                    <Input key={`${id}_${index}`} className={"dataSourcesButton " + id + "Editor" + original.allocationGroupId} placeholder="Enter value" value={value} onChange={onChange} onBlur={onBlur} />
                    {original.errorIndicator && <span className="inlineErrorContainer">{original.errorIndicator}</span>}
                </div>;
            default:
                return <Input key={`${id}_${index}`} className={"dataSourcesButton " + id + "Editor" + original.totalId} placeholder="Enter value" value={value} onChange={onChange} onBlur={onBlur} />;
        }
    }
    else {
        switch (id) {
            case CONSTANTS.WORKSHEET_TOTAL_FIELDS.DESCRIPTION:
                return <div key={`${id}_${index}`} className={`save-status-${total.savingStatus} cell-text ${id}Cell`}>
                    <span>{value}</span>
                    <div className="inlineErrorContainer">{original.errorIndicator && <div className="inlineErrorContainer">{original.errorIndicator}</div>}</div>
                </div>;
            case CONSTANTS.WORKSHEET_TOTAL_FIELDS.OVERVIEW: {
                return <div key={`${id}_${index}`} className={`save-status-${total.savingStatus}`}>
                        {total.expression == null || total.expression === ""? "Click to enter TP calculation formula" : total.expressionFormatted}
                        {editorsData.shouldShowValues && total.outputBreakdown? <div className="greyBadge">{total.outputBreakdown}</div> : null }
                    </div>;
            }
        }
    }
    return <div key={`${id}_${index}`} className={`save-status-${total.savingStatus} cell-text ${id}Cell`}>{value}</div>;
};

// Set our editable cell renderer as the default Cell renderer
const defaultCellEditor = {
    Cell: EditableCell,
}

const contextMenuActions = {
    EDIT: { text: "Edit", id: "Edit" },
    DUPLICATE: { text: "Duplicate", id: "Duplicate" },
    REMOVE: { text: "Remove", id: "Remove" },
    ADD_TOTAL_BEFORE: { text: "Add total before", id: "Add total before" },
    ADD_TOTAL_AFTER: { text: "Add total after", id: "Add total after" }
}

const totalsMenuItems = [
    contextMenuActions.EDIT,
    contextMenuActions.DUPLICATE,
    contextMenuActions.ADD_TOTAL_BEFORE,
    contextMenuActions.ADD_TOTAL_AFTER,
    contextMenuActions.REMOVE
];

export default function TotalsGrid(props: {onTotalsLevelError: (x?:string) => void}) {
    const {onTotalsLevelError} = props;
    const { services, totalsState, totalsDispatch, dataSources } = React.useContext(TotalsContext);
    const { tpAllocationDispatch, tpAllocationState } = React.useContext(TPAllocationContext);
    const { totals, showValues, updateTotalPayload, removeTotalModalPayload, totalBeingEdited } = totalsState;
    const { viewMode, worksheet, datasetRecords, worksheetMode, totalValidationErrors } = tpAllocationState;
    
    const [formattedRecords, setFormattedRecords] = React.useState([] as WorksheetTotal[]);
    const [deleteTotalPayload, setDeleteTotalPayload] = React.useState(undefined as any | undefined)

    useEffect(() => {
        if (totals == null) {
            return;
        }
        totals.forEach((t, index) => { 
            t.visibleSequence = `${index + 1}`;
            t.validationErrors = [...(t.validationErrors||[]), ...totalValidationErrors.filter(x => x.worksheetEntityId === t.totalId)];
        });
        setFormattedRecords(totals.map(x => {
            const parts = services.readOnlyCalculationService.breakExpressionIntoParts(
                x.expression, 
                totals.map(x => ({name: x.description, visibleSequence: x.visibleSequence})), 
                dataSources.map(x => ({name: x.description})), 
                new Set()
            );
            x.expressionTokens = parts;
            const errorsList = (x.validationErrors || []).map(x => <li>{x.message}</li>);
            const errorsContent = errorsList.length > 0? <React.Fragment><b>Validation Errors</b><ul>{errorsList}</ul></React.Fragment> : null;
            return {
                ...x,
                isEditable: CONSTANTS.VIEW_MODE.EDITABLE === viewMode,
                expression: x.expression,
                expressionFormatted: renderExpressionParts(parts),
                formulaOutputFormatted: isNaN(x.formulaOutput as any)? x.value : services.formattingService.formatString(x.value || '', true),
                savingStatus: worksheet?.worksheetVersion == CONSTANTS.STAGING_VERSION_NUMBER? SavingStatus.Unsaved : x.savingStatus,
                errorIndicator: errorsContent? <Popover key={`popFormulaError_${x.visibleSequence}`}
                    className="errorMessagePopover"
                    dismissButton={false}
                    position="top"
                    size="small"
                    triggerType="custom"
                    content={
                        errorsContent
                    }
                >
                    <Icon
                    name="status-warning"
                    size="normal"
                    variant="warning"
                    />
                </Popover> : null
            }
        }))
    }, [totals, dataSources, totalValidationErrors])

    
    const [deleteTotalResult, isDeleting, deleteError] = worksheetMode === WorksheetMode.TEMPLATE? 
        services.tpAllocationTotalsService.deleteTemplateTotal(deleteTotalPayload):
        services.tpAllocationTotalsService.deleteWorksheetTotal(deleteTotalPayload);

    
    
    useEffect(() => {
        if (deleteTotalResult == null){
            return;
        }
        totalsDispatch(ACTIONS.SET_REMOVE_TOTAL.withPayload(deleteTotalResult))
    },[deleteTotalResult])

    useEffect(() => {
        
        if (!StringUtils.isNullOrEmpty(deleteError)){
            totalsDispatch(ACTIONS.SET_REMOVE_TOTAL_MODAL_PAYLOAD.withPayload(null))
            onTotalsLevelError(deleteError);
        }
    }, [deleteError])

    useEffect(() => {
        if (viewMode == CONSTANTS.VIEW_MODE.EDITABLE){
            return;
        }
        totalsDispatch(ACTIONS.SET_TOTAL_BEING_EDITED.withPayload(null));
    }, [viewMode])

    // Columns without a Cell property have rendering handled by the EditableCell renderer
    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "visibleSequence",
            Header: (e: any) => <div className="cell-text-non-sortable">#</div>,
            Cell: ({ row }: { row: any }) => { 
                const worksheetTotal = row.original as WorksheetTotal;
                return (
                    <div className={`save-status-${worksheetTotal.savingStatus} totalSequenceCell textCell`}>
                        <span className="sequenceCellValue">{worksheetTotal.visibleSequence}</span>                        
                    </div>
                );
            },
        },
        {
            accessor: "description",
            Header: (e: any) => <div className="cell-text-non-sortable">Total name</div>,
        },
        {
            accessor: CONSTANTS.WORKSHEET_TOTAL_FIELDS.OVERVIEW,
            Header: <div className="headerToggle">Calculation overview &nbsp;
                        <Popover
                            className="calcOverviewPopover"
                            dismissButton={false}
                            position="right"
                            size="small"
                            triggerType="custom"
                            content="Click the expression to edit. Type '@' to display the autocomplete menu."
                        >
                            <Icon
                            name="status-info"
                            size="small"
                            variant="link"
                            />
                        </Popover>
                    </div>
        },
        {
            accessor: `${CONSTANTS.WORKSHEET_TOTAL_FIELDS.OUTPUT}Formatted`,
            Header: (e: any) => <div className="cell-text-non-sortable">Formula output</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "actions",
            Header: (e: any) => <div className="cell-text-non-sortable"></div>,
            Cell: (e: any) => <div className="rowActions formulaOutputCell">
                { viewMode === CONSTANTS.VIEW_MODE.EDITABLE &&
                    <ButtonDropdown className="rowContextMenu gridContextMenu" onItemClick={(bde) => { 
                        onTotalMenuClicked(bde.detail, e.row, totalsDispatch, services, e.row.original.parentEntityId);
                    }}
                        items={totalsMenuItems}
                    >
                    </ButtonDropdown>
                }
            </div>
        } 
    ], [showValues, totals, viewMode]);

    const [skipPageReset, setSkipPageReset] = React.useState(false);

    const onRecordUpdated = (rowIndex: number, columnId: any, value: any) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
        
        if (columnId === CONSTANTS.WORKSHEET_TOTAL_FIELDS.OVERVIEW) {
            totalsDispatch(
                ACTIONS.UPDATE_CURRENT_TOTAL.withPayload({
                    field: columnId, 
                    value: value, 
                    extraValue: services.readOnlyCalculationService.breakExpressionIntoParts(
                        value, 
                        totals.map(x => ({name: x.description, visibleSequence: x.visibleSequence})), 
                        datasetRecords.map(x => ({name: x.description})), 
                        new Set()
                    )
                })
            );
        }
        else {
            totalsDispatch(ACTIONS.UPDATE_CURRENT_TOTAL.withPayload({field: columnId, value: value}));
        }
    }


    const onFormulaEditorCaretPositionChanged = (position: number) => {
        totalsDispatch(ACTIONS.SET_EDITOR_CARET_POSITION.withPayload(position));
    }

    const onRowClicked = (e: any, total: WorksheetTotal, index:number) => {
        if (CONSTANTS.VIEW_MODE.EDITABLE !== viewMode) {
            return;
        }
        // We don't do anything if user clicks on the formula output, error container tooltip or sequence cell
        if (
            !total.isEditable || 
            DomUtils.isDescendantOfClassName(e.target, 'formulaOutputCell') || 
            DomUtils.isDescendantOfClassName(e.target, 'totalSequenceCell') || 
            DomUtils.isDescendantOfClassName(e.target, 'inlineErrorContainer') 
        ){
            return;
        }
        totalsDispatch(ACTIONS.SET_TOTAL_BEING_EDITED.withPayload(total));
    }

   return <div className="dataSourceTableContainer">        
            { CONSTANTS.VIEW_MODE.EDITABLE === viewMode  && <FormulaExpressionToolbar showBuiltInFunctions={false} dispatch={totalsDispatch} /> }
            <TPEReactTable {...{
                data: formattedRecords,
                columnDefinitions,
                className: "worksheetTotalsGrid",
                sortable: false,
                key: "worksheetTotalsGrid1",
                onRowClicked: onRowClicked,
                emptyMessage: CONSTANTS.VIEW_MODE.EDITABLE === viewMode? "There are no totals yet. Click on Add total button to start": "No results",
                editableOptions: {
                    defaultColumn: defaultCellEditor,
                    onRowUpdated: onRecordUpdated,
                    skipPageReset,
                    editorsData: {
                        shouldShowValues: showValues,
                        suggestionsList: datasetRecords.map(x => ({name: x.description, type: "Dataset Items", value: x.description})),
                        totalsList: totals,
                        onTextAreaCaretPositionChanged: onFormulaEditorCaretPositionChanged,
                    }
                }
            }} />

            <TPEBasicModal
                className="totalsDeleteConfirmModal"
                visible={removeTotalModalPayload != null}
                action={ACTION_TYPE.YES_NO}
                title="Remove Total Confirmation"
                onCancel={() => totalsDispatch(ACTIONS.SET_REMOVE_TOTAL_MODAL_PAYLOAD.withPayload(null))}
                onConfirm={() => {
                    setDeleteTotalPayload(removeTotalModalPayload?.total)
                    onTotalsLevelError(undefined);
                }}
                primaryButtonDisabledLabel={isDeleting? "Deleting...": undefined}
            >
                <span>Are you sure you want to remove step #{removeTotalModalPayload?.total.visibleSequence} {removeTotalModalPayload?.total.description}?</span>
            </TPEBasicModal>
            <SimpleUseEffect 
                useEffectVars={[deleteTotalResult, deleteError]} 
                action={() => tpAllocationDispatch(TP_ALLOCATION_ACTIONS.CHECK_AND_REFRESH_ENTITY_DETAILS)}
            />
        </div>
}

function onTotalMenuClicked(detail: ButtonDropdownProps.ItemClickDetails, row: Row, dispatch: React.Dispatch<TPEAction>, services: ServiceCollection, parentEntityId: string): void {
    const total = row.original as WorksheetTotal;
    switch(detail.id) {
        case contextMenuActions.EDIT.id:
            dispatch(ACTIONS.SET_TOTAL_BEING_EDITED.withPayload(total));
        break;
        case contextMenuActions.DUPLICATE.id:            
            dispatch(ACTIONS.DUPLICATE_WORKSHEET_TOTAL.withPayload({ index: row.index + 1, originalTotal: total }));
        break;
        case contextMenuActions.ADD_TOTAL_BEFORE.id: {
            const newTotal = services.tpAllocationTotalsService.createNewTotal(total.sequence - 1, parentEntityId);
            dispatch(ACTIONS.ADD_WORKSHEET_TOTAL_BEFORE.withPayload({ index: row.index, newTotal }));
            break;
        }        
        case contextMenuActions.ADD_TOTAL_AFTER.id: {
            const newTotal = services.tpAllocationTotalsService.createNewTotal(total.sequence, parentEntityId);
            dispatch(ACTIONS.ADD_WORKSHEET_TOTAL_AFTER.withPayload({ index: row.index, newTotal }))
            break;
        }
        case contextMenuActions.REMOVE.id:
            dispatch(ACTIONS.SET_REMOVE_TOTAL_MODAL_PAYLOAD.withPayload({ index: row.index, total }))
        break;
    }
}