import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Box, Button, ColumnLayout, FormField, Modal, RadioGroup, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { ACTIONS } from 'src/services/custom-data-tables/CustomTablesReducer';
import StringUtils from 'src/utils/stringUtils';
import { GlobalAppContext } from '../App';
import { CustomTablesContext } from './TablesView';
import { CUSTOM_DATA_TABLE_CONSTANTS } from 'src/utils/constants';

export default function FileUploadModal(props: {visible: boolean, onCancel: () => any, onSubmit: (tableId: string) => any}) {

    const { visible, onCancel, onSubmit } = props;

    const { userProfile } = React.useContext(GlobalAppContext);
    const { state, dispatch, services } = useContext(CustomTablesContext);
    const { s3Key } = state;
    
    const hiddenFileInput = useRef(null);
    const [uploadFile, setUploadFile] = useState();
    const [fileInfo, setFileInfo] = useState(null as unknown as any);
    const [tableOption, setTableOption] = useState('new');
    const [uploadFileValid, setUploadFileValid] = useState(false);
    const [errorDisplay, setErrorDisplay] = useState('');

    const [uploadResult, uploading, uploadError] = services.customDataTablesService.uploadFile(s3Key, uploadFile as unknown as File, 
        tableOption == 'new', CUSTOM_DATA_TABLE_CONSTANTS.DUPLICATE_ROWS_VIEW_FIELDS);

    useEffect(() => {
        // Reset modal
        if (visible) {
            setErrorDisplay('');
            setUploadFile(undefined);
            setFileInfo(null);
            setTableOption('new');
            setUploadFileValid(false);
        }
    }, [visible])

    useEffect(() => {
        if (uploadResult != null) {
            dispatch(ACTIONS.SET_S3_KEY.withPayload(null));
            if (uploadResult.records != null && uploadResult.records.length > 0) {
                // Show duplicates view
                dispatch(ACTIONS.SET_DUPLICATE_ROWS_INFO.withPayload(uploadResult));
                onCancel();
            } else {
                // Show banner and navigate to CDT details page
                services.messageService.showSuccessAutoDismissBanner(`${fileInfo.name} successfully uploaded.`, 10000);
                onSubmit(uploadResult.tableId);
            }
        }
    }, [uploadResult])

    useEffect(() => {
        if (!StringUtils.isNullOrEmpty(uploadError)) {
            setErrorDisplay(uploadError);
        }
    }, [uploadError])

    const onChooseFileButtonClick = () => {
        // @ts-ignore
        hiddenFileInput.current.click();
    }

    const uploadToS3Handler = () => {
        if (uploadFile != null) {
            //@ts-ignore
            const key = new Date().getTime() + "_" + userProfile.user + "_" + uploadFile.name.replace(' ', '_');
            dispatch(ACTIONS.SET_S3_KEY.withPayload(key));
        }
    }

    const handleInputFileChange = (e: any) => {
        setUploadFile(e.target.files[0]);
        const fileName = e.target.files[0].name;
        setFileInfo({
            name: fileName,
            size: e.target.files[0].size,
            lastModifiedDate: String(e.target.files[0].lastModifiedDate)
        });
        if (fileName.substring(fileName.lastIndexOf('.')) == '.xlsx') {
            setUploadFileValid(true);
        } else {
            setUploadFileValid(false);
        }
        e.target.value = null;
    }

    return <Modal className="fileUploadModal"
                onDismiss={onCancel}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={onCancel}>Cancel</Button>
                            <Button className="customDataTableUploadButton" 
                                    variant="primary" 
                                    disabled={!uploadFileValid || uploading}
                                    onClick={() => uploadToS3Handler()}
                            >
                                {uploading ? 'Uploading...' : 'Upload'}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="Upload Custom Data Table"
            >
            
                <SpaceBetween direction="vertical" size="s">
                    <ColumnLayout columns={2}>
                        <FormField
                            description="Upload Custom Table in .xlsx format."
                            label="Select file to upload"
                        >
                            <SpaceBetween direction="vertical" size="xs">
                                <Button data-class="fileUploadButton" iconName='upload' iconAlign='left' onClick={onChooseFileButtonClick}>Choose File</Button>
                                <input type='file' ref={hiddenFileInput} accept={'.xlsx'} className='hiddenFileUploadInput' onChange={handleInputFileChange} />
                                <div>
                                    {fileInfo != null && 
                                        <React.Fragment>
                                            <StatusIndicator type={uploadFileValid ? 'success' : 'error'}></StatusIndicator>
                                            <span>{fileInfo.name}</span>
                                        </React.Fragment>
                                    }
                                </div>
                            </SpaceBetween>
                        </FormField>
                        <FormField
                            label="Select where to upload"
                        >
                            <RadioGroup
                                data-class="basicModalRadioGroup"
                                value={tableOption}
                                items={[
                                    { value: 'new', label: 'Create a new table'},
                                    { value: 'existing', label: 'Upload data to an existing table'}
                                ]}
                                onChange={(e) => setTableOption(e.detail.value)}
                            />
                        </FormField>
                    </ColumnLayout>
                <Alert type="error" visible={!StringUtils.isNullOrEmpty(errorDisplay)}>{errorDisplay || ''}</Alert>
                </SpaceBetween>
            </Modal>
}