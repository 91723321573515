import TPEAction from '../../models/common/TPEAction';
import { ReadOnlyCalculationState } from "src/services/calculations/ReadOnlyCalculationState";

/**
 * List here the actions supported by this reducer
 */
export const ACTIONS = Object.freeze({
    SET_SELECTED_AGREEMENT_NUMBER: new TPEAction('SET_SELECTED_AGREEMENT_NUMBER'),
    SET_CALCULATION_NUMBERS: new TPEAction('SET_CALCULATION_NUMBERS'),
    SET_SELECTED_CALCULATION_NUMBER: new TPEAction('SET_SELECTED_CALCULATION_NUMBER'),
    SET_VERSIONS: new TPEAction('SET_VERSIONS'),
    SET_SELECTED_VERSION: new TPEAction('SET_SELECTED_VERSION'),
    SET_CALCULATION_STEPS: new TPEAction('SET_CALCULATION_STEPS')
});

/**
 * This function is responsible for updating the state based on action type
 * @param state The current dashboard state
 * @param action The current dispatched action
 */
export function readOnlyCalculationReducer(state: ReadOnlyCalculationState, action: TPEAction): ReadOnlyCalculationState {
    switch (action.type) {
        case ACTIONS.SET_SELECTED_AGREEMENT_NUMBER.type:
            return {
                ...state,
                selectedAgreementNumber: action.payload,
                selectedCalculationNumber: undefined,
                selectedVersion: undefined,
                calculationNumbers: [],
                versions: [],
                calculationSteps: [],
            };
        case ACTIONS.SET_CALCULATION_NUMBERS.type:
            return {
                ...state,
                calculationNumbers: action.payload,
            }
        case ACTIONS.SET_SELECTED_CALCULATION_NUMBER.type:
            return {
                ...state,
                selectedCalculationNumber: action.payload,
                selectedVersion: undefined,
                versions: [],
                calculationSteps: [],
                isSearching: true,
            };
        case ACTIONS.SET_VERSIONS.type:
            return {
                ...state,
                versions: action.payload,
                // When there is only one version we inmedially pull the calculation details of that one version, 
                // so the isSearching flag continues on to avoid flickering of loading animation on grid
                isSearching: action.payload.length === 1,
            };
        case ACTIONS.SET_SELECTED_VERSION.type:
            return {
                ...state,
                selectedVersion: parseInt(action.payload),
                calculationSteps: []
            };
        case ACTIONS.SET_CALCULATION_STEPS.type:
            return {
                ...state,
                calculationSteps: action.payload,
                isSearching: false,
            };
        default:
            console.warn(`No action found for ${action.type}. Returning unchanged state`)
            return state;
    }
}