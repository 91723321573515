import { FormField, Select } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { CalculationTemplate } from 'src/models/calculation-builder/CalculationTemplate';
import ServiceCollection from 'src/services/ServiceCollection';

type ComponentProps = {
    services: ServiceCollection,
    label: string,
    onTemplateSelected: (template?: CalculationTemplate) => void,
}

export default function TemplateSelector(props:ComponentProps){
    const {
        label, 
        services, 
        onTemplateSelected,
    } = props;

    const [templates,setTemplates] = useState([] as CalculationTemplate[]);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null as null | any);
    const [templatesNeedRefresh, setTemplatesNeedRefresh] = useState(true);
    
    const [templatesResult, isLoadingTemplates, templatesError] = services.calculationBuilderService.getTemplates(templatesNeedRefresh);


    useEffect(() => {
        if (templatesResult == null){
            return;
        }
        setTemplates(templatesResult);
        setTemplatesNeedRefresh(false);
    }, [templatesResult])

    return <FormField
        label={label}
        errorText={templatesError}>
        <Select
        selectedOption={selectedTemplate}
        onChange={({ detail }) => {
            const template = templates.find(x => x.templateId === detail.selectedOption.value);
            setSelectedTemplate(detail.selectedOption);
            onTemplateSelected(template);
        }}
        statusType={isLoadingTemplates?'loading':'finished'}
        loadingText='Loading templates...'
        options={templates.map(x => ({label:x.templateName, value:x.templateId, workbookId: x.workbookId}))}
        filteringType="auto"
        virtualScroll={true}
        selectedAriaLabel="Selected"
        />
    </FormField>
}