import React from 'react';
import 'src/assets/styles/react-table.scss';
import { useTable, useFilters, useSortBy, useExpanded } from 'react-table';
import { Box, Icon, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import CONSTANTS from "src/utils/constants";

type ComponentProps = {
    data: any,
    columnDefinitions: any[],
    className: string,
    expandingColumn: number,
    onVisibilityToggle?: (index: number) => any,
    canSelectHeaders?: boolean,
    canSelectNonheaders?: boolean,
    onSelectChanged?: (index: number) => any,
    sortable?: boolean,
    showHeader?: boolean,
    isSearching?: boolean,
    isSearchingMessage?: string,
    emptyMessage?: string,
    onSortBy?: (e: Array<any>) => any,
    initialState?: any
};

const doNothing = () => { }

export function TPERowExpandableReactTable(props: ComponentProps) {
    const {
        className, data, columnDefinitions, expandingColumn, onVisibilityToggle, canSelectHeaders, canSelectNonheaders, onSelectChanged, sortable = true,
        showHeader = true, isSearching = false, initialState, onSortBy = doNothing, emptyMessage = "No results", isSearchingMessage = "Searching..."
    } = props;
    const columns = React.useMemo(() => columnDefinitions, [columnDefinitions]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable({
        columns,
        data,
        initialState,
        manualSortBy: true,
        disableMultiSort: true
    } as any, useFilters, useSortBy, useExpanded);

    const { sortBy } = state as any;
    React.useEffect(() => {
        onSortBy(sortBy);
    }, [sortBy]);

    const hasCheckbox = (row: number, column: number) => {
        return column == 0 && ((canSelectHeaders && data[row].headerRow == row) || (canSelectNonheaders && data[row].headerRow != row));
    }

    return (
        <div>
            <table className={className+CONSTANTS.ATP_CUSTOM_REACT_TABLE_CSS} {...getTableProps()}>
                {showHeader &&
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th {...column.getHeaderProps()} className={column.headerClass}>
                                    <div
                                        className="sorting-arrows"
                                        {...column.getSortByToggleProps()} >
                                        {column.render('Header')}
                                        {sortable && !column.suppressSorting &&
                                            <div>
                                                {column.isSorted ? column.isSortedDesc ?
                                                    <Icon name="caret-down-filled" /> :
                                                    <Icon name="caret-up-filled" /> :
                                                    <Icon name="caret-down" />}
                                            </div>
                                        }
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                }
                {isSearching || rows == null || rows.length == 0 ?
                    null :
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);

                            const headerRow = data[i].headerRow;

                            return headerRow == i || data[headerRow].visible ?
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, j) => {
                                        return <td {...cell.getCellProps()} onClick={(e) => j == expandingColumn && onVisibilityToggle && onVisibilityToggle(i)}>
                                                    <SpaceBetween direction="horizontal" size="xxxs">
                                                        {hasCheckbox(i, j) && <input type="checkbox" className="reactTableCheckbox" checked={data[i].isSelected} onChange={() => onSelectChanged && onSelectChanged(i)} />}
                                                        {headerRow == i && j == expandingColumn && <Icon name={data[i].visible ? "caret-down-filled" : "caret-right-filled"} />}
                                                        {cell.render('Cell')}
                                                    </SpaceBetween>
                                               </td>
                                    })}
                                </tr>
                            : null
                        })}
                    </tbody>
                }

            </table>
            {isSearching ?
                <Box textAlign="center" padding="l"><StatusIndicator type="loading">{isSearchingMessage}</StatusIndicator></Box> : null}
            {!isSearching && (rows == null || rows.length == 0) ?
                <Box textAlign="center" padding="l" className="tpeTableEmptyMessage"><StatusIndicator type="info">{emptyMessage}</StatusIndicator></Box> : null}
        </div>
    )
}