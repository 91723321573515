import React, { useEffect } from 'react';
import 'src/assets/styles/react-table.scss';
import { Alert, Box, Button, Link, Modal, Select } from '@amzn/awsui-components-react/polaris';
import { TPEReactTable, DefaultColumnFilter } from 'src/components/shared/TPEReactTable'
import CONSTANTS from 'src/utils/constants';
import TPENavigator from '../TPENavigator';
import { AgreementsContext } from './AgreementsView';
import { ACTIONS } from 'src/services/agreements/AgreementsReducer';
import { CLI } from 'src/models/common/CLI';
import { getPermissions } from '../AppPermissions';
import { AppModules } from 'src/models/permissions/RolePermissions';

export default function CalculationsGrid(params: { data: any, showFilters: boolean, isSearching: boolean, onSortBy: (e: Array<any>) => any, onFilter: (e: Array<any>) => any }) {
    const { services, state, dispatch } = React.useContext(AgreementsContext);
    const { workbooks, showAssigneeNameConfirmation, users,
        showCalcBuilderNameConfirmation, showWorkbookNameConfirmation, showCloseModalButton: calculationSuccesfullyUpdated } = state;
    const { data, showFilters, isSearching, onSortBy, onFilter } = params;
    const { canEdit: canEditCalculation } = getPermissions(AppModules.CALCULATION_BUILDER);
    const { canEdit } = getPermissions(AppModules.TP_WORKLISTS);

    const [navigateURL, setNavigateURL] = React.useState('');
    const [modalModel, setModalModel] = React.useState({} as any);
    const [savingCalcAssigneeModel, setSavingCalcAssigneeModel] = React.useState(null as unknown as any);
    const [updatedCLIByAssignee, isSavingCalcAssignee, errorSavingCalcAssignee] = services.agreementsService.updateCLICalcAssignee(savingCalcAssigneeModel);
    const [savingCalcBuilderModel, setSavingCalcBuilderModel] = React.useState(null as unknown as any);
    const [updatedCLIByCalcBuilder, isSavingCalcBuilder, errorSavingCalcBuilder] = services.agreementsService.updateCLICalcBuilder(savingCalcBuilderModel);
    const [savingWorkbookModel, setSavingWorkbookModel] = React.useState(null as unknown as any);
    const [updatedCLIByWorkbook, isSavingWorkbook, errorSavingWorkbook] = services.agreementsService.updateCLIWorkbook(savingWorkbookModel);
    

    const navigateToAgreementDetails = (agreementRecordId: string) => {
        setNavigateURL(CONSTANTS.PAGE_NAV.AGREEMENT_DETAILS.URL + '/' + btoa(agreementRecordId));
    };

    const navigateToCalcBuilder = (recordId: string) => {
        setNavigateURL(CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + '/' + btoa(recordId));
    };

    const onAssigneeNameChanged = function (detail: any, editingItem: CLI) {
        setModalModel({
            message: `Are you sure you want to assign "${detail.selectedOption.label}" as Calculation Assignee for ${editingItem.calculationNumber}?`,
            header: "Calculation Assignee",
            newValue: {
                calcAssigneeEmployeeId: detail.selectedOption.value,
                cliRecordId: editingItem.recordId
            }
        });
        dispatch(ACTIONS.SET_CALCULATION_BEING_UPDATED.withPayload(editingItem));
        dispatch(ACTIONS.SET_ASSIGNEE_NAME_CONFIRMATION_VISIBLE.withPayload(true));        
    };

    const onCalcBuilderNameChanged = function (detail: any, editingItem: CLI) {
        setModalModel({
            message: `Are you sure you want to set "${detail.selectedOption.label}" as Calculation Builder for ${editingItem.calculationNumber}?`,
            header: "Calculation Builder",
            newValue: {
                calcBuilderEmployeeId: detail.selectedOption.value,
                cliRecordId: editingItem.recordId
            }
        });
        dispatch(ACTIONS.SET_CALCULATION_BEING_UPDATED.withPayload(editingItem));
        dispatch(ACTIONS.SET_CALC_BUILDER_NAME_CONFIRMATION_VISIBLE.withPayload(true));
    };

    const onWorkbookNameChanged = function (detail: any, editingItem: CLI) {
        setModalModel({
            message: `Are you sure you want to set "${detail.selectedOption.label}" as Workbook name for ${editingItem.calculationNumber}?`,
            header: "Workbook Name",
            newValue: {
                cliRecordId: editingItem.recordId,
                workbookRecordId: detail.selectedOption.value,
            }
        })
        dispatch(ACTIONS.SET_CALCULATION_BEING_UPDATED.withPayload(editingItem));
        dispatch(ACTIONS.SET_WORKBOOK_NAME_CONFIRMATION_VISIBLE.withPayload(true));
    };

    const hideModal = function () {
        dispatch(ACTIONS.SET_ALL_CONFIRMATIONS_VISIBLE.withPayload(false));
        dispatch(ACTIONS.SET_SHOW_CLOSE_MODAL_BUTTON.withPayload(false));
    }

    useEffect(() => {
        if (updatedCLIByAssignee == null){
            return;
        }
        dispatch(ACTIONS.UPDATE_CLI.withPayload(updatedCLIByAssignee));
        setModalModel({
            message: <Alert visible={true} type="success">Assignee name has been set</Alert>,
            header: "Calculation Assignee",
        })
    }, [updatedCLIByAssignee])

    useEffect(() => {
        if (updatedCLIByCalcBuilder == null){
            return;
        }
        dispatch(ACTIONS.UPDATE_CLI.withPayload(updatedCLIByCalcBuilder));
        setModalModel({
            message: <Alert visible={true} type="success">Calc Builder name has been set</Alert>,
            header: "Calc Builder Name",
        })        
    }, [updatedCLIByCalcBuilder])
    
    useEffect(() => {
        if (updatedCLIByWorkbook == null){
            return;
        }
        dispatch(ACTIONS.UPDATE_CLI.withPayload(updatedCLIByWorkbook));
        setModalModel({
            message: <Alert visible={true} type="success">Workbook name has been set</Alert>,
            header: "Workbook Name",
        })      
    }, [updatedCLIByWorkbook])

    useEffect(() => {
        if (errorSavingCalcAssignee == null || errorSavingCalcAssignee === ''){
            return;
        }
        setModalModel({
            message: <Alert visible={true} type="error">{errorSavingCalcAssignee}</Alert>,
            header: "Update Calculation Assignee Error",
        });
        dispatch(ACTIONS.SET_SHOW_CLOSE_MODAL_BUTTON.withPayload(true));
    }, [errorSavingCalcAssignee])

    useEffect(() => {
        if (errorSavingCalcBuilder == null || errorSavingCalcBuilder === ''){
            return;
        }
        setModalModel({
            message: <Alert visible={true} type="error">{errorSavingCalcBuilder}</Alert>,
            header: "Update Calculation Builder Error",
        });
        dispatch(ACTIONS.SET_SHOW_CLOSE_MODAL_BUTTON.withPayload(true));
    }, [errorSavingCalcBuilder])

    useEffect(() => {
        if (errorSavingWorkbook == null || errorSavingWorkbook === ''){
            return;
        }
        setModalModel({
            message: <Alert visible={true} type="error">{errorSavingWorkbook}</Alert>,
            header: "Update Calculation Workbook Error",
        });
        dispatch(ACTIONS.SET_SHOW_CLOSE_MODAL_BUTTON.withPayload(true));
    }, [errorSavingWorkbook])

    const confirmUpdate = function () {
        if (showAssigneeNameConfirmation) {
            setSavingCalcAssigneeModel(modalModel.newValue);            
        }
        else if (showCalcBuilderNameConfirmation) {
            setSavingCalcBuilderModel(modalModel.newValue);            
        }
        else if (showWorkbookNameConfirmation) {
            const {workbookRecordId} = modalModel.newValue;
            const workbook = workbooks.find(x => x.workbookRecordId === workbookRecordId);
            if (workbook == null) {
                setModalModel({
                    message: <Alert visible={true} type="error">Workbook was not found. Workbook name could not be updated. Please try again later</Alert>,
                    header: "Workbook Name",
                });
                dispatch(ACTIONS.SET_SHOW_CLOSE_MODAL_BUTTON.withPayload(true));
                return;
            }
            setSavingWorkbookModel(modalModel.newValue);            
        }
    }

    
    const columnDefinitions = React.useMemo(() => { 
        return [
        {
            accessor: "calculationNumber",
            Header: (e: any) => <div className="cell-text">Calculation Number</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => {
                return e.row.original.calculationStatus?.toLowerCase() !== CONSTANTS.CALCULATION_STATUS.YET_TO_START.toLowerCase() ?
                    <Link className="cell-link selectCalculationLink" onFollow={({ detail }) => navigateToCalcBuilder(e.row.original.recordId)}>{e.value}</Link> : <div className="cell-text">{e.value}</div>
            },
        },
        {
            accessor: "functionalSegment",
            Header: (e: any) => <div className="cell-text">Functional Segment</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "agreementName",
            Header: (e: any) => <div className="cell-text">Agreement Name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) =>
                <Link className="cell-link calcNameLink"
                    onFollow={detail => navigateToAgreementDetails(e.row.original.agreementRecordId)}>{e.value}</Link>,
        },
        {
            accessor: "agreementStatusCategory",
            Header: (e: any) => <div className="cell-text">Agreement Status Category</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "agreementStatus",
            Header: (e: any) => <div className="cell-text">Agreement Status</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "calculationStatus",
            Header: (e: any) => <div className="cell-text">Calculation Status</div>,
            Filter: DefaultColumnFilter,
            Cell: (cellInfo: any) => {
                return canEditCalculation && cellInfo.value?.toLowerCase() === CONSTANTS.CALCULATION_STATUS.YET_TO_START.toLowerCase() ?
                    <Link className="cell-link calcStatusLink"
                        onFollow={e => navigateToCalcBuilder(cellInfo.row.original.recordId)}>{cellInfo.value}</Link> : <div className="cell-text">{cellInfo.value}</div>
            },
        },
        {
            accessor: "apttusCalculationStatus",
            Header: (e: any) => <div className="cell-text">APTTUS Status</div>,
            Filter: DefaultColumnFilter,
            Cell: (cellInfo: any) => <div className="cell-text">{cellInfo.value}</div>
        },
        {
            accessor: "workbookName",
            Header: (e: any) => <div className="cell-text">Workbook name</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "accountingOwner",
            Header: (e: any) => <div className="cell-text">TP Accounting Owner</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "assignee",
            Header: (e: any) => <div className="cell-text">Calculation assignee</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => canEdit ? <Select className="dataSourcesButton assigneeDropdown"
                placeholder="Select"
                virtualScroll={true}
                onChange={changeEvent => onAssigneeNameChanged(changeEvent.detail, e.row.original)}
                filteringType="auto"
                selectedOption={e.value == null ? null : { label: e.value, value: e.value }}
                options={users.map(x => ({ label: `${x.fullName} (${x.alias}@)`, value: x.employeeId }))} />
                :
                <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "calculationBuilder",
            Header: (e: any) => <div className="cell-text">Calculation builder</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => canEdit ? <Select className="dataSourcesButton calcBuilderDropdown"
                onChange={changeEvent => onCalcBuilderNameChanged(changeEvent.detail, e.row.original)}
                virtualScroll={true}
                filteringType="auto"
                selectedOption={e.value == null ? null : { label: e.value, value: e.value }}
                options={users.map(x => ({ label: `${x.fullName} (${x.alias}@)`, value: x.employeeId }))} />
                :
                <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "taxOwner",
            Header: (e: any) => <div className="cell-text">TP Tax Owner</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "taskType",
            Header: (e: any) => <div className="cell-text">Task Type</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "providerCode",
            Header: (e: any) => <div className="cell-text">Provider</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : `${e.value}`}</div>,
        },
        {
            accessor: "recipientCode",
            Header: (e: any) => <div className="cell-text">Recipient</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : `${e.value}`}</div>,
        },
        {
            accessor: "lastUpdateDate",
            Header: (e: any) => <div className="cell-text">Last Updated</div>,
            Filter: DefaultColumnFilter,
            Cell: (e: any) => <div className="cell-text">{e.value == null ? <span /> : new Date(e.value).toLocaleString()}</div>,
        }] }, [data, workbooks, users, canEdit, canEditCalculation]);

    const shouldShowModal = showAssigneeNameConfirmation || showCalcBuilderNameConfirmation || showWorkbookNameConfirmation;
    const isSaving = isSavingCalcAssignee || isSavingCalcBuilder || isSavingWorkbook;
    // Render the UI for your table
    return (
        <div>
            <TPENavigator path={navigateURL} />
            <TPEReactTable {...{ data, columnDefinitions, className: "calculationsWorklists", isSearching, showFilters, onFilter, onSortBy }} />
            <Modal onDismiss={hideModal} className="agreementsViewCalculationsGridModal"
                visible={shouldShowModal}
                closeAriaLabel="Close modal"
                size="medium"
                header={modalModel.header}
                footer={
                    calculationSuccesfullyUpdated ?
                        <Box float="right">
                            <Button variant="primary" onClick={hideModal}>Close</Button>
                        </Box>
                        :
                        <Box float="right">
                            <Button variant="link" onClick={hideModal}>Cancel</Button>
                            <Button className="agreementsViewCalculationsGridModal_ConfirmButton" variant="primary" onClick={confirmUpdate} loading={isSaving}>{isSaving? "Saving..." : "Confirm"}</Button>
                        </Box>
                }>
                {modalModel.message}
            </Modal>
        </div>
    )
}