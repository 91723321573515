import { Alert, Box, Button, ButtonDropdown, ButtonDropdownProps, Container, ExpandableSection, FormField, Header, Link, Popover, Select, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useState } from 'react';
import { TPELoadingSpinner } from 'src/components/shared/TPELoadingSpinner';
import { JournalLine } from 'src/models/calculation-builder/Journal';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import CONSTANTS from 'src/utils/constants';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import JournalEntriesGrid from './JournalEntriesGrid';
import { reviewJournalsReducer, ACTIONS } from '../../../services/calculation-builder/ReviewJournalsReducer';
import { initialState } from '../../../services/calculation-builder/ReviewJournalsState';
import { ACTIONS as CALC_BUILDER_ACTIONS } from '../../../services/calculation-builder/CalculationBuilderReducer';
import { ACTION_TYPE, TPEBasicModal } from 'src/components/shared/TPEBasicModal';
import { GlobalAppContext } from '../../App';
import ArrayUtils from 'src/utils/arrayUtils';
import TPEErrorWatcher from 'src/components/shared/TPEErrorWatcher';
import ATPUserCommentModal from '../../shared/ATPUserCommentModal';
import StringUtils from 'src/utils/stringUtils';
import {Checkbox} from "@amzn/awsui-components-react/polaris";
import CalculationSource, { CalculationSourceType } from 'src/models/common/CalculationSource';
import { ExecutionGroupDetails } from 'src/models/calculation-builder/ExecutonGroupDetails';
import { CLI } from 'src/models/common/CLI';

export default function ReviewJournalEntriesContainer(props: { calculationSource: CalculationSource, expanded?: boolean, onCancel: () => void }) {
    const { expanded = false, calculationSource, onCancel } = props;
    const { globalDispatch, userProfile } = React.useContext(GlobalAppContext);
    const { services, calcBuilderState, calcBuilderDispatch } = useContext(CalculationBuilderContext);
    const [ state, dispatch ] = useReducerWithLogger(reviewJournalsReducer, initialState);

    const { executionGroupDetails } = state;
    
    const [ saveExecutionGroupPayload, setSaveExecutionGroupPayload ] = useState(null as unknown as any);
    const [ submitCalculationPayload, setSubmitCalculationPayload ] = useState(undefined as {calculationNumber: string, notes: string} | undefined);
    const [ calculationNumberForApproval, setCalculationNumberForApproval] = useState(undefined as string | undefined);
    const [ showSubmitConfirmationModal, setShowSubmitConfirmationModal ] = useState(false);
    const [ showApproveConfirmation, setShowApproveConfirmation ] = useState(false);
    const [ showRejectConfirmation, setShowRejectConfirmation ] = useState(false);
    const [loadTime, setLoadTime] = useState(null as string | null);
    const [updateCalculationAttributePayload, setUpdateCalculationAttributePayload] = useState(undefined as {calculationNumber: string, attributeName: string, attributeValue: string} | undefined);
    const [rejectDraftPayload, setRejectDraftPayload] = useState(undefined as {calculationNumber: string, rejectionReason: string} | undefined);

    const { calculation, calculationTemplateMetaData, reviewJournals, generatingReviewJournalsStatus,
        executionPeriod, viewMode, executionResultLines, reversalExecutionResultLines } = calcBuilderState;
    
    const [data, setData] = useState([] as JournalLine[]);
    const [reversalData, setReversalData] = useState([] as JournalLine[]);
    
    const [executionGroupDetailsResult, isGettingExecutionGroupDetails, executionGroupDetailsError] = services.calculationsService.getOrAssignExecutionGroup(loadTime, calculation?.calculationNumber);
    const [saveExecutionGroupResult, isSavingExecutionGroup, saveExecutionGroupError] = services.calculationsService.saveCalculationExecutionGroup(saveExecutionGroupPayload);
    const [submitForReviewResult, isSubmittingForReview, submitForReviewError] = services.calculationsService.submitCalculationForReview(submitCalculationPayload);
    const [updateCLIAttributeResult, isUpdatingCLIAttributeResult, updateCLIAttributeError] = services.calculationsService.saveCalculationAttribute(updateCalculationAttributePayload);
    const [rejectDraftResult, isRejectingDraft, rejectDraftError] = services.calculationsService.rejectCalculationDraft(rejectDraftPayload);
    const [cliExecutionGroups, cliExecutionGroupsLoading, cliExecutionGroupsError] = services.calculationsService.getCLIExecutionGroups(loadTime);
    const [approvalResult, isApproving, approvalError] = services.calculationsService.approveCalculation(calculationNumberForApproval);

    const [blockReversalFlag, setBlockReversalFlag] = useState(false);
    const [suppressNegChargeFlag, setSuppressNegChargeFlag] = useState(false);
    const [canEditCLIAttributes, setCanEditCLIAttributes] = useState(false);


    useEffect(
        () => setLoadTime(new Date().toString())
    , []);

    useEffect(() => {
        setCanEditCLIAttributes(!isUpdatingCLIAttributeResult)
    }, [isUpdatingCLIAttributeResult]);

    useEffect(() => {
        setBlockReversalFlag(calculationTemplateMetaData?.blockReversalFlag ? calculationTemplateMetaData.blockReversalFlag : false)
        setSuppressNegChargeFlag(calculationTemplateMetaData?.suppressNegativeChargeFlag ? calculationTemplateMetaData.suppressNegativeChargeFlag : false)
    }, [calculationTemplateMetaData]);

    useEffect(() => {
        setCanEditCLIAttributes(viewMode != CONSTANTS.VIEW_MODE.READ_ONLY && viewMode != CONSTANTS.VIEW_MODE.FROZEN);
    }, [viewMode])

    useEffect(() => {
        if (executionGroupDetailsResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_EXECUTION_GROUP_DETAILS.withPayload(executionGroupDetailsResult));
    }, [executionGroupDetailsResult])
    
    useEffect( () => {
        if (rejectDraftResult == null) {
            return;
        }
        services.messageService.showSuccessAutoDismissBanner("Calculation draft successfully rejected.");
        setShowRejectConfirmation(false);
         // This is triggering a refresh on the calculation data, which is needed to reflect the new status
         calcBuilderDispatch(CALC_BUILDER_ACTIONS.REFRESH_ALL_CALCULATION_INFORMATION);
    }, [rejectDraftResult])

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(executionResultLines)) {
            return;
        }
        setData(executionResultLines);
    }, [executionResultLines])

    useEffect(() => {
        setReversalData(reversalExecutionResultLines || []);
    }, [reversalExecutionResultLines])

    useEffect(() => {
        if (executionPeriod != null) {
            return;
        }
        const combinedArray: JournalLine[] = [];
        reviewJournals.forEach(l => combinedArray.push(...l.journalLines));
        setData(combinedArray);
    }, [reviewJournals]) 
    
    useEffect(() => {
        if (submitForReviewResult == null){
            return;
        }
        services.messageService.showSuccessAutoDismissBanner("Calculation successfully submitted for review.");
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.REFRESH_ALL_CALCULATION_INFORMATION);
        setShowSubmitConfirmationModal(false);
    }, [submitForReviewResult])
    
    useEffect(() => {
        if (approvalResult == null){
            return;
        }
        services.messageService.showSuccessAutoDismissBanner("Calculation successfully approved.");
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.REFRESH_ALL_CALCULATION_INFORMATION);
        setShowApproveConfirmation(false);
    }, [approvalResult])
    
    useEffect(() => {
        if (updateCLIAttributeResult == null){
            return;
        }
        // This is triggering a refresh on the calculation data
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [updateCLIAttributeResult])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(updateCLIAttributeError)) {
            return;
        }
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [updateCLIAttributeError])

    useEffect(() => {
        if (saveExecutionGroupResult == null) {
            return;
        }
        dispatch(ACTIONS.SET_EXECUTION_GROUP_DETAILS.withPayload(saveExecutionGroupResult));
        let successMsg = `Calculation assigned to Group ${saveExecutionGroupResult.executionGroup} successfully.`;
        if (calculation?.workbookId != null) {
            successMsg += ` ${saveExecutionGroupResult.autoDraftsForActiveCalcsCreated} active calculation(s) and `
            successMsg += `${saveExecutionGroupResult.autoDraftsForPendingReviewCalcsCreated} pending review calculation(s) `
            successMsg += `in the workbook ${calculation?.workbookId} (${calculation?.workbookName}) were automatically moved to draft status due to this update.`
        }
        services.messageService.showSuccessAutoDismissBanner(successMsg, 10000);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [saveExecutionGroupResult])

    useEffect(() => {
        if (saveExecutionGroupError == null) {
            return;
        }
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [saveExecutionGroupError])

    const saveExecutionGroup = (executionGroup: string) => {
        setSaveExecutionGroupPayload({
            calculationNumber: calculation?.calculationNumber,
            executionGroup: executionGroup
        });
    }

    
    const executeJournalEntryAction = (event: any) => {
        event.stopPropagation();
        switch(event.detail.id){
            case "SUBMIT":
                setShowSubmitConfirmationModal(true);
            break;
            case "APPROVE":
                if (validateReviewer()) {
                    setShowApproveConfirmation(true);
                }
            break;
            case "REJECT":
                if (validateReviewer()) {
                    setShowRejectConfirmation(true);
                }
            break;
        }
    }

    const validateReviewer = () => {
        if (userProfile.user == calculationTemplateMetaData?.submitUser) {
            services.messageService.showErrorBanner('You cannot review this calculation as you were the submitter of the calculation.');
            return false;
        }
        return true;
    }
    
    function getJournalActionItemsByCalculationStatus(): ButtonDropdownProps.ItemOrGroup[] {
        const reviewerAlias = calculationSource.reviewerAlias;
        const calcAsigneeAlias = calculationSource.calcAsigneeAlias;
        const canReview = userProfile.user === reviewerAlias;

        const canSubmit = (userProfile.user === calcAsigneeAlias || 
            userProfile.user === calculationSource.calcBuilderAlias) &&
            executionGroupDetails?.executionGroup != null &&
            calculationTemplateMetaData?.templateEditCalculation != calculationSource.sourceKeyNumber;
        
        
        switch (calculationTemplateMetaData?.calculationStatus){
            case CONSTANTS.CALCULATION_STATUS.DRAFT_IN_PROGRESS:
                return [
                    { text: "Submit for Review", id: "SUBMIT", disabled: !canSubmit},
                ]
            case CONSTANTS.CALCULATION_STATUS.PENDING_REVIEW:
                return canReview?
                [
                    { text: "Approve", id: "APPROVE", disabled: false },
                    { text: "Reject", id: "REJECT", disabled: false },
                ]:
                [
                    { text: "Pending Review", id: "PENDING", disabled: true, iconName: 'status-pending' },
                ]
        }
        return [];
    }

    function submitForApproval(comment: string): void {
        setSubmitCalculationPayload({calculationNumber: calculation?.calculationNumber || '', notes: comment});
    }

    function approveCalculation(): void {
        setCalculationNumberForApproval(calculation?.calculationNumber);
    }

    function rejectCalculation(rejectReason:string): void {
        setRejectDraftPayload({calculationNumber:calculation?.calculationNumber || '', rejectionReason: rejectReason})        
    }

    function updateSuppressNegativeChargeFlag() {
        setUpdateCalculationAttributePayload({calculationNumber: calculation?.calculationNumber || '', attributeName: "suppressNegativeCharge", attributeValue: (!suppressNegChargeFlag).toString()})
        setSuppressNegChargeFlag(!suppressNegChargeFlag);
    }

    function updateBlockReversalFlag() {
        setUpdateCalculationAttributePayload({calculationNumber: calculation?.calculationNumber || '', attributeName: "blockReversal", attributeValue: (!blockReversalFlag).toString()})
        setBlockReversalFlag(!blockReversalFlag);
    }

    const showLoadingSpinner = generatingReviewJournalsStatus === CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.IN_PROGRESS || generatingReviewJournalsStatus === CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.STARTED;
    
    return (
        <ExpandableSection className="polarisExpandableSection reviewJournalEntriesSection" variant="container"
            defaultExpanded={expanded}
            header={
                <Header><h2>Step 5: Review journal entries</h2></Header>
            }            
        >
            <TPELoadingSpinner loading={showLoadingSpinner} loadingText="Generating journals for review">
                    <div className="calcBuilderContentContainer">
                        <SpaceBetween direction="vertical" size="l">
                            <Container className="polaris-content-container-review-journal-entries"
                                header={
                                    <Header
                                    actions={
                                        executionPeriod == null && CONSTANTS.CALCULATION_STATUS.ACTIVE !== calculationTemplateMetaData?.calculationStatus && <ButtonDropdown
                                        onItemClick={(event: any) => executeJournalEntryAction(event)}
                                        variant='primary'
                                            items={getJournalActionItemsByCalculationStatus()}
                                            >
                                            Journal Entry Action
                                            </ButtonDropdown>
                                    }
                                    >Original journal entry</Header>
                                }
                            >
                                <div className="journalEntriesContainer">
                                    <JournalEntriesGrid data={data}/>
                                </div>
                            </Container>
                            { executionPeriod != null && !ArrayUtils.isNullOrEmpty(reversalData) &&
                                <Container className="polaris-content-container-review-journal-entries"
                                    header={ <Header>Reversed journal entry</Header> }
                                >
                                    <div className="journalEntriesContainer">
                                        <JournalEntriesGrid data={reversalData}/>
                                    </div>
                                </Container>
                            }
                        </SpaceBetween>
                        <div className="actionButtonsContainer">
                            <Box float="left">
                                <SpaceBetween direction="horizontal" size="xl">
                                    <TPELoadingSpinner loading={isGettingExecutionGroupDetails}>
                                        <ExecutionGroupSelector {...{cliExecutionGroups, executionGroupDetails, canEditCLIAttributes, calculation, saveExecutionGroup, isSaving: isSavingExecutionGroup }} />
                                    </TPELoadingSpinner>
                                    <div className="calcAttributeDiv">
                                        <Checkbox disabled={!canEditCLIAttributes} checked={blockReversalFlag} onChange={updateBlockReversalFlag}>Flag as non-reversible</Checkbox>
                                    </div>
                                    <div className="calcAttributeDiv">
                                        <Checkbox disabled={!canEditCLIAttributes} checked={suppressNegChargeFlag} onChange={updateSuppressNegativeChargeFlag}>Suppress negative charge</Checkbox>
                                    </div>
                                    {(isUpdatingCLIAttributeResult || isSavingExecutionGroup) && <div className="calcAttributeDiv"><StatusIndicator type={"loading"}/></div>}
                                </SpaceBetween>
                            </Box>
                        </div>
                        <ATPUserCommentModal
                            key="submitConfirmationModal"
                            visible={showSubmitConfirmationModal}
                            header="Submit calculation for review"
                            content={ <span>
                                        This calculation will be reviewed by <b>{calculationSource.reviewerAlias}</b> (Workbook Reviewer).
                                        Provide a brief comment and click on <b>Confirm</b> to submit this calculation for review.
                                      </span>
                                    }
                            formFieldLabel="Submit comment"
                            isSaving={isSubmittingForReview}
                            saveError={submitForReviewError}
                            onCancel={() => setShowSubmitConfirmationModal(false)}
                            onSubmit={(comment) => submitForApproval(comment)}
                        />
                        <TPEBasicModal
                            className="approveConfirmModal"
                            visible={showApproveConfirmation}
                            action={ACTION_TYPE.CONFIRM_CANCEL}
                            title="Approve calculation confirmation"
                            onCancel={() => setShowApproveConfirmation(false)}
                            onConfirm={() => approveCalculation()}
                            primaryButtonDisabledLabel={ isApproving? "Approving...": undefined}
                        >
                            <div>
                                Click on <b>Confirm</b> to approve this calculation.
                                <br/>
                                <br/>
                                {!StringUtils.isNullOrEmpty(approvalError) && <Alert type="error" dismissible={false}>{approvalError}</Alert>}
                            </div>
                        </TPEBasicModal>
                        <ATPUserCommentModal
                            key="rejectionConfirmationModal"
                            visible={showRejectConfirmation} 
                            header="Reject calculation"
                            content={<span>Provide a reason and click on <b>Confirm</b> to reject this calculation.</span>}
                            formFieldLabel="Rejection reason"
                            isSaving={isRejectingDraft}
                            saveError={rejectDraftError}
                            onCancel={() => setShowRejectConfirmation(false)}
                            onSubmit={(rejectReason) => rejectCalculation(rejectReason)}
                        />
                        <TPEErrorWatcher services={services} errors={[submitForReviewError, approvalError, cliExecutionGroupsError, rejectDraftError, executionGroupDetailsError, saveExecutionGroupError]} />
                    </div>
            </TPELoadingSpinner>            
        </ExpandableSection>
    )
}

function ExecutionGroupSelector(props: {cliExecutionGroups: any[], 
                                        executionGroupDetails?: ExecutionGroupDetails,
                                        canEditCLIAttributes: boolean,
                                        calculation?: CLI,
                                        saveExecutionGroup: (groupNumber: string) => any,
                                        isSaving: boolean}) {
    const { cliExecutionGroups, executionGroupDetails, canEditCLIAttributes, calculation, saveExecutionGroup, isSaving } = props;

    const options = [{label: 'Select', value: ''}];
    cliExecutionGroups.forEach((x:string) => options.push({label: `Group ${x}`, value: `${x}`}));

    const [isEditing, setIsEditing] = useState(false);
    const [selectedOption, setSelectedOption] = useState<any>(options[0]);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        resetDropdown();
    }, [executionGroupDetails])

    const resetSelectedOption = () => {
        setSelectedOption(options.find(o => o.value == executionGroupDetails?.executionGroup) || options[0]);
    }

    const resetDropdown = () => {
        resetSelectedOption();
        setIsEditing(executionGroupDetails?.executionGroup == null);
    }

    const selectGroup = (selectedGroup: any) => {
        setSelectedOption(selectedGroup);
        if (selectedGroup == options[0] || selectedGroup.value == executionGroupDetails?.executionGroup) {
            return;
        }
        setShowConfirmation(true);
    }

    const cancelChange = () => {
        setShowConfirmation(false);
        resetDropdown();
    }

    const saveChange = () => {
        setShowConfirmation(false);
        saveExecutionGroup(selectedOption.value);
    }

    return <React.Fragment>
            <FormField
            label="Assign CLI to a Group"
            >
                {isEditing ?
                    <Select data-class="basicDropdown"
                        disabled={!canEditCLIAttributes || isSaving}
                        onChange={({ detail }) => selectGroup(detail.selectedOption)}
                        selectedOption={selectedOption}
                        options={options}
                    />
                :
                    <React.Fragment>
                        {executionGroupDetails?.workbookId == null ?
                            <span className="smallTextSpan">Group {executionGroupDetails?.executionGroup}</span>
                        :
                            <Popover
                                data-class="basicPopover"
                                dismissButton={false}
                                position="top"
                                content={`This execution group for workbook ${executionGroupDetails?.workbookId} (${executionGroupDetails?.workbookName}) was updated by user ${executionGroupDetails?.lastUpdateUser} from calculation ${executionGroupDetails?.updatedFromCalculation} on ${executionGroupDetails?.lastUpdateDate}.`}
                            >
                                <span className="smallTextSpan">Group {executionGroupDetails?.executionGroup}</span>
                            </Popover>
                        }
                        {canEditCLIAttributes && <Button data-class="smallIconButton" variant="icon" iconName="edit" onClick={() => { setIsEditing(true); resetSelectedOption() }} /> }
                    </React.Fragment>
                }
        </FormField>
        <TPEBasicModal
            className="updateExecutionGroupConfirmationModal"
            visible={showConfirmation}
            action={ACTION_TYPE.CONFIRM_CANCEL}
            title="Assign execution group confirmation"
            onCancel={cancelChange}
            onConfirm={saveChange}
        >
            <div>
                {calculation?.workbookId == null?
                    `This will assign this calculation to ${selectedOption.label}. Confirm to proceed.` :
                    `This will assign all calculations in workbook ${calculation?.workbookId} - ${calculation?.workbookName} to ${selectedOption.label}. Confirm to proceed.`
                }
            </div>
        </TPEBasicModal>
    </React.Fragment>
}

