import { StatusCategory } from "src/models/agreements/AgreementStatusCategoriesResult";
import TemporaryMessage from "src/models/common/TemporaryMessage";
import TPESearchRequest from "src/models/common/TPESearchRequest";
import { WorklistItem } from "../../models/agreements/Worklist";
import SearchSortingSpec from "src/models/common/SearchSortingSpec"
import CONSTANTS from "src/utils/constants";
import { Workbook } from "src/models/agreements/Workbook";
import { TPEUser } from "src/models/users/TPEUser";
import { CLI } from "src/models/common/CLI";

export interface AgreementsState {
    isLoading: boolean;
    error?: string;
    isSearching: boolean;
    agreementStatusCategories: Array<StatusCategory>;
    calculationStatuses: Array<string>;
    accountingOwners: Array<string>;
    taxOwners: Array<string>;
    defaultWorklists: Array<WorklistItem>;
    customWorklists: Array<WorklistItem>;
    selectedWorklist?: WorklistItem;
    searchAgreementsParams: TPESearchRequest,
    searchCalculationsParams: TPESearchRequest,
    searchICRSParams: TPESearchRequest,
    searchAgreementsItems: Array<any>;
    searchCalculationsItems: Array<CLI>;
    searchICRSItems: Array<any>,
    customWorklistMessage?: TemporaryMessage;
    customWorklistsNeedSaving: boolean;
    customWorklistAddedDate?: Date;
    customWorklistUpdatedDate?: Date;
    customWorklistDeletedDate?: Date;
    savingMode?: string;
    searchCurrentPage: number;
    searchPageSize: number;
    searchTotalSize: number;
    searchSorting?: SearchSortingSpec;
    searchFilters?: Array<{ id: string, value: any }>;
    selectedTab: string;
    userTouchedSorting: boolean;
    workbooks: Array<Workbook>;
    users: Array<TPEUser>;
    showWorkbookNameConfirmation: boolean;
    showAssigneeNameConfirmation: boolean;
    showCalcBuilderNameConfirmation: boolean;
    calculationBeingUpdated?: any;
    showCloseModalButton: boolean;
}

export const initialState: AgreementsState = {
    isLoading: true,
    isSearching: false,
    agreementStatusCategories: [],
    calculationStatuses: [],
    accountingOwners: [],
    taxOwners: [],
    customWorklists: [],
    defaultWorklists: [],
    searchAgreementsParams: new TPESearchRequest(),
    searchCalculationsParams: new TPESearchRequest(),
    searchICRSParams: new TPESearchRequest(),
    searchAgreementsItems: [],
    searchCalculationsItems: [],
    searchICRSItems: [],
    searchCurrentPage: 1,
    searchPageSize: 20,
    searchTotalSize: 0,
    selectedWorklist: undefined,
    customWorklistsNeedSaving: false,
    selectedTab: CONSTANTS.WORKLIST_TABS.NONE,
    userTouchedSorting: false,
    workbooks: [],
    users: [],
    showWorkbookNameConfirmation: false,
    showAssigneeNameConfirmation: false,
    showCalcBuilderNameConfirmation: false,
    showCloseModalButton: false,
};