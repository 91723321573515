import {useEffect, useState} from "react";
import { format } from 'date-fns';
import apiService from "../ApiCallService";
import ErrorUtils from "../../utils/ErrorUtils";
import { Journal, JournalLine } from "src/models/calculation-builder/Journal";
import FormattingService from "../utils/FormattingService";
import { DryRunResponse } from "src/models/calculation-builder/DryRunResponse";

/**
 * This class should only contain the API calls related to JE Settings and Review Journals
 */
 export default class JournalsService {
    getJournals(getJournals: boolean, journalLineType: string, calculationNumber?: string, version?: number) : [Journal[], boolean, string] {
        const [result, setResult] = useState([] as Journal[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData($this: JournalsService, calculationNumber: string, version: number) {
                try {
                    setLoading(true);
                    const response = await apiService.getJournals(calculationNumber, version, journalLineType);
                    const json = await response.json();
                    const journals = json.journals;
                    $this.convertJournalFieldsToDisplayFormat(journals);
                    $this.convertLineErrorsToMap(journals);
                    setResult(journals);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (getJournals && calculationNumber != null && version != null) {
                fetchData(this, calculationNumber, version);
            }
        }, [getJournals, calculationNumber, version]);
        return [result, loading, error]
    }

    updateJournals(payload?: Journal[], calculationNumber?: string) : [Journal[], boolean, string] {
        const [result, setResult] = useState([] as Journal[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData($this: JournalsService, calculationNumber: string, payload: Journal[]) {
                try {
                    setLoading(true);
                    const response = await apiService.updateJournals(calculationNumber, {'journals': payload});
                    const json = await response.json();
                    const updatedJournals = json.journals;
                    $this.convertJournalFieldsToDisplayFormat(updatedJournals);
                    setResult(updatedJournals);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null && payload != null) {
                postData(this, calculationNumber, payload);
            }
        }, [payload]);
        return [result, loading, error]
    }

    dryRunCalculation(query: string, calculationNumber: string, version: number, generateTaxJournals: boolean) : [DryRunResponse, boolean, string] {
        const [result, setResult] = useState(null as unknown as DryRunResponse);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function postData($this: JournalsService, calculationNumber: string, version: number, generateTaxJournals: boolean) {
                try {
                    setLoading(true);
                    const response = await apiService.dryRunCalculation(calculationNumber, version, generateTaxJournals);
                    const dryRunResponse: DryRunResponse = await response.json();
                    $this.convertJournalFieldsToDisplayFormat(dryRunResponse.journals);
                    $this.convertLineErrorsToMap(dryRunResponse.journals);
                    setResult(dryRunResponse);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (query != null && calculationNumber != null && version != null && generateTaxJournals != null) {
                postData(this, calculationNumber, version, generateTaxJournals);
            }
        }, [query]);
        return [result, loading, error]
    }

    getJournalEntries(calculationExecutionId?: string) : [JournalLine[], JournalLine[], boolean, string] {
        const [result, setResult] = useState([] as JournalLine[]);
        const [reversalsResult, setReversalsResult] = useState([] as JournalLine[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData($this: JournalsService, calculationExecutionId: string) {
                try {
                    const response = await apiService.getJournalEntries(calculationExecutionId);
                    const json = await response.json();
                    const journalLines = json.journalLines;
                    const reversalJournalLines = json.reversalJournalLines || [];
                    $this.convertJournalLineFieldsToDisplayFormat(journalLines);
                    $this.convertJournalLineFieldsToDisplayFormat(reversalJournalLines);
                    setResult(journalLines);
                    setReversalsResult(reversalJournalLines);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationExecutionId != null) {
                fetchData(this, calculationExecutionId);
            }
        }, [calculationExecutionId]);
        return [result, reversalsResult, loading, error]
    }

    convertJournalFieldsToDisplayFormat(journals: Journal[]) {
        journals.forEach((journal: Journal) => {
            this.convertJournalLineFieldsToDisplayFormat(journal.journalLines);
        })
    }

    convertJournalLineFieldsToDisplayFormat(journalLines: JournalLine[]) {
        const formattingService: FormattingService = new FormattingService();
        journalLines.forEach((journalLine: JournalLine) => {
            if (journalLine.accountingDate != null) {
                journalLine.accountingDateDisplay = this.getUTCDateDisplay(journalLine.accountingDate);
            }
            if (journalLine.currencyConversionDate != null) {
                journalLine.currencyConversionDateDisplay = this.getUTCDateDisplay(journalLine.currencyConversionDate);
            }
            if (journalLine.reversalPeriod != null) {
                journalLine.reversalPeriodDisplay = format(new Date(journalLine.reversalPeriod), "MMM-yyyy");
            }
            if (journalLine.enteredDr != null) {
                journalLine.enteredDrFormatted = formattingService.formatString(journalLine.enteredDr);
            }
            if (journalLine.enteredCr != null) {
                journalLine.enteredCrFormatted = formattingService.formatString(journalLine.enteredCr);
            }
            if (journalLine.accountedDr != null) {
                journalLine.accountedDrFormatted = formattingService.formatString(journalLine.accountedDr);
            }
            if (journalLine.accountedCr != null) {
                journalLine.accountedCrFormatted = formattingService.formatString(journalLine.accountedCr);
            }
        })
    }

    getUTCDateDisplay(epoch: number) {
        const d = new Date(epoch);
        const utcDate = d.toUTCString();
        return (utcDate.substring(5, 16) as any).replaceAll(' ', '-');
    }

    convertLineErrorsToMap(journals: Journal[]) {
        journals.forEach((journal: Journal) => {
            journal.journalLines.forEach((journalLine: JournalLine) => {
                if (journalLine.errors != null) {
                    const errorMap = new Map<string, string>();
                    Object.keys(journalLine.errors).forEach(k => {
                        errorMap.set(k, (journalLine.errors as any)[k].join(', '));
                    })
                    journalLine.errors = errorMap;
                }
            })
        })
    }
 }