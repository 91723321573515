import React, { useEffect, useState } from "react";
import { Box, Button, ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react';
import { TPAllocationContext } from "src/services/tp-allocation/TPAllocationContext";
import CONSTANTS from "src/utils/constants";
import DatasetMainGrid from "./DatasetMainGrid";
import { DatasetState, initialState } from 'src/services/tp-allocation/data-set/DatasetState';
import useReducerWithLogger from "src/services/utils/ReducerWithLogger";
import { datasetReducer, ACTIONS } from "src/services/tp-allocation/data-set/DatasetReducer";
import { ACTIONS as TP_ALLOCATION_ACTIONS } from "src/services/tp-allocation/TPAllocationReducer";
import TPEAction from "src/models/common/TPEAction";
import ServiceCollection from "src/services/ServiceCollection";
import ArrayUtils from "src/utils/arrayUtils";
import { TPAllocationState } from "src/services/tp-allocation/TPAllocationState";
import { WorksheetMode } from "src/models/tp-allocation/TPAllocationEnums";
import { TPELoadingSpinner } from "src/components/shared/TPELoadingSpinner";
import DataSourcesSelectCOA from "src/components/calculation-builder/data-sources/SelectCOA";
import DataSourcesMagicBox from "src/components/calculation-builder/data-sources/MagicBox";
import DatasetSplitGrid from "./DatasetSplitGrid";
import { DatasetRecord } from "src/models/tp-allocation/DatasetRecord";
import BalancePuller from "src/components/calculation-builder/data-sources/BalancePuller";
import { PullBalanceWSMessage } from "src/models/calculation-builder/PullBalanceWebSocketMessage";
import { PullBalanceRequestTypes } from "src/models/calculation-builder/PullBalanceRequest";
import SimpleUseEffect from "src/components/shared/SimpleUseEffect";
import Placeholder from "src/models/tp-allocation/Placeholder";
import { DataKeyInput } from "src/models/common/DataSourceRecord";

export type DatasetContextType = {
    datasetState: DatasetState,
    datasetDispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    tpAllocationState: TPAllocationState
}

const DatasetProvider = (props: any) => {
    const { state, dispatch, services, tpAllocationState, children } = props;
    const providerValue = React.useMemo(() => ({
        datasetState: state, datasetDispatch: dispatch, services, tpAllocationState
    }), [state, dispatch]);
    return (
        <DatasetContext.Provider value={providerValue}>
            {children}
        </DatasetContext.Provider>
    );
}
export const DatasetContext = React.createContext(null as unknown as DatasetContextType);

export default function DatasetContainer(props: {
    containerTitle: string,
    expanded?: boolean,
    onCancel: () => void
}) {
    const { containerTitle, expanded } = props;
    const { services, tpAllocationState, tpAllocationDispatch } = React.useContext(TPAllocationContext);
    const [datasetState, datasetDispatch] = useReducerWithLogger(datasetReducer, initialState);
    const { viewMode, worksheet, worksheetMode, worksheetTemplate, sevenSegmentLOVMap, placeholdersMap, dataSourcesNeedRefresh } = tpAllocationState;
    const { datasetRecords, customCoaReference = new Map(), pullBalanceRequestPayloads,
        recordBeingEdited, recordBeingEditedOriginal, showDecimals, selectedCustomCoaValues } = datasetState;
    const { showDataKeySelection = false } = recordBeingEdited || {};
    const [loadTime, setLoadTime] = React.useState('');
    const [tableKey, setTableKey] = React.useState(0);
    const [refreshRecordsFlag, setRefreshRecordsFlag] = useState(undefined as string | undefined);
    const isTemplate = worksheetMode === WorksheetMode.TEMPLATE;
    const parentEntityId = isTemplate ? worksheetTemplate?.templateId : worksheet?.worksheetId;
    const parentEntityVersion = isTemplate ? worksheetTemplate?.templateVersion : worksheet?.worksheetVersion;

    const [datasetRecordsResult, datasetCustomCoaReferenceMap, datasetRecordsLoading, datasetRecordsError] = isTemplate ?
        services.tpAllocationDatasetService.getTemplateDatasetRecords(worksheetTemplate?.templateId, worksheetTemplate?.templateVersion) :
        services.tpAllocationDatasetService.getDatasetRecords(worksheet?.worksheetId, worksheet?.worksheetVersion, worksheet?.executionPeriod?.id, refreshRecordsFlag || loadTime);

    const [coaSegmentsResponse, coaSegmentsLoading, coaSegmentError] = services.tpAllocationService.getAllCoaSegments(
        isTemplate ? worksheetTemplate?.templateId : worksheet?.worksheetId
    );

    const [dataSourceTypes, currencyCodes, periods, currencyConversionTypes, loadingCheck, error] = services.dataSourcesService.getDataSourceLOVs(loadTime);
    const [recordNeedsSaving, setRecordNeedsSaving] = React.useState(undefined as string | undefined);
    const [saveDatasetRecordResponse, isSaving, savingError] = isTemplate? 
        services.tpAllocationDatasetService.saveTemplateDataset(customCoaReference, placeholdersMap || new Map<string, Placeholder[]>(), false, recordBeingEdited, recordNeedsSaving) : 
        services.tpAllocationDatasetService.saveDataset(customCoaReference, false, recordBeingEdited, recordNeedsSaving);
        
    const [coaTypes, loadingCoa, errorCoa] = services.dataSourcesService.getCoaTypes(loadTime);

    useEffect(() => {
        setLoadTime(new Date().toDateString());
        const webSocketListener = setupWebSocketEvents(services, datasetDispatch);
        setTableKey(tableKey + 1);
        return () => {
            // Anything in here is fired on component unmount.
            services.atpWebSocketApiService.removeListener('',webSocketListener);
        }
    }, [])

    useEffect(() => {
        if (dataSourcesNeedRefresh){
            setRefreshRecordsFlag(new Date().toISOString());
        }
    }, [dataSourcesNeedRefresh])

    const replacePlaceholderIdsFromDataKeyInput = (placeholders: Map<string,Placeholder[]>, coaType: string, coaInput: string) : string=> {
        if (coaInput.match(/<.*>/g)){
            const inputValues = coaInput.split(",").map(x => x.trim());
            const replacedValues = inputValues.map(x => {
                const matches = x.match(/<(.*?)>/);
                if (matches && matches.length > 1){
                    const placeholderId = matches[1];
                    const placeholder = placeholders.get(coaType)?.find(p => p.placeholderId === placeholderId);
                    if (placeholder){
                        return `<${placeholder.placeholderName}>`;
                    }
                    return `<${placeholderId}>`
                }
                return x;
            })
            return replacedValues.join(", ");
        }
        else {
            return coaInput;
        }
    }

    useEffect(() => {
        if (datasetRecordsResult == null) {
            return;
        }
        if (datasetRecordsResult.length === 0 && !datasetRecordsLoading) {
            addNewRecord();
            return;
        }
        if (isTemplate && placeholdersMap != null && placeholdersMap.size > 0){
            datasetRecordsResult.forEach(x => {
                if (x.dataKeyInput != null){
                    const dataKeyInputCopy: DataKeyInput = x.dataKeyInput;
                    dataKeyInputCopy.selectedCompanies = replacePlaceholderIdsFromDataKeyInput(placeholdersMap, CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI, x.dataKeyInput.selectedCompanies);
                    dataKeyInputCopy.selectedAccounts = replacePlaceholderIdsFromDataKeyInput(placeholdersMap, CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI, x.dataKeyInput.selectedAccounts);
                    Array.from(dataKeyInputCopy.selectedCOA.keys()).forEach(mapKey => {
                        const mapValue = dataKeyInputCopy.selectedCOA.get(mapKey) || '';
                        dataKeyInputCopy.selectedCOA.set(mapKey, replacePlaceholderIdsFromDataKeyInput(placeholdersMap, mapKey, mapValue))
                    })
                }
            })
        }
        datasetDispatch(ACTIONS.SET_RECORDS.withPayload(datasetRecordsResult));
    }, [datasetRecordsResult, datasetRecordsLoading, placeholdersMap])
    
    useEffect(() => {
        if (datasetRecords == null) {
            return;
        }
        tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_DATASET_RECORDS.withPayload(datasetRecords));
    }, [datasetRecords])

    useEffect(() => {
        if (datasetCustomCoaReferenceMap == null) {
            return;
        }
        datasetDispatch(ACTIONS.SET_CUSTOM_COA_REFERENCE.withPayload(datasetCustomCoaReferenceMap));
    }, [datasetCustomCoaReferenceMap])

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(dataSourceTypes) || ArrayUtils.isNullOrEmpty(currencyCodes)
            || ArrayUtils.isNullOrEmpty(periods) || ArrayUtils.isNullOrEmpty(currencyConversionTypes)) {
            return;
        }
        datasetDispatch(ACTIONS.SET_TP_LOV.withPayload({
            dataSourceTypes, currencyCodes, periods, currencyConversionTypes
        }))
    }, [dataSourceTypes, currencyCodes, periods, currencyConversionTypes]);



    useEffect(() => {
        if (coaSegmentsResponse == null) {
            return;
        }
        tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_SEVEN_SEGMENTS_LOV_MAP.withPayload(coaSegmentsResponse))
    }, [coaSegmentsResponse])

    useEffect(() => {
        if (saveDatasetRecordResponse == null) {
            return;
        }
        if (recordBeingEditedOriginal?.description != recordBeingEdited?.description) {
            tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SET_REFRESH_TOTALS_BUILDER_STEPS.withPayload(true));
        }
        datasetDispatch(ACTIONS.SAVE_RECORD.withPayload({ recordID: recordBeingEdited?.datasourceId, updatedRecord: saveDatasetRecordResponse }));
        //tpAllocationDispatch(TP_ALLOCATION_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [saveDatasetRecordResponse])

    const addNewRecord = function(){
        datasetDispatch(ACTIONS.ADD_NEW_RECORD.withPayload({ parentEntityId, worksheetMode: worksheetMode }));
    }

    const [atLeastOneDatasetItemIsSaved, setAtLeastOneDatasetItemIsSaved] = useState(false);
    useEffect(()=>{
        setAtLeastOneDatasetItemIsSaved(datasetRecords.some(x => !x.isNewRecord))
    }, [datasetRecords])

    // If the balance pull was initiated for a new data source and save was clicked when the balance pull was in progress,
    // then the record has to be completely saved to save the currency in the DB.
    // This could happen only in case of draft versions.
    // So in this case to update the currency returned by the balance pull, we have to save the data source.
    const [balancePullingRecord,setBalancePullingRecord] = useState(undefined as DatasetRecord | undefined);
    const [balancePullingRecordNeedsSaving, setBalancePullingRecordNeedsSaving] = React.useState(undefined as string | undefined);
    const [saveBalancePullingRecordResponse] = services.tpAllocationDatasetService.saveDataset(
        customCoaReference,
        parentEntityVersion == CONSTANTS.CALCULATION_VERSIONS.DRAFT_VERSION ? false : balancePullingRecord?.requestAutoSave || false, 
        balancePullingRecord, balancePullingRecordNeedsSaving);
    useEffect(() => {
        if ( saveBalancePullingRecordResponse == null ){
            return;
        }
        datasetDispatch(ACTIONS.SAVE_RECORD.withPayload({ recordID: balancePullingRecord?.datasourceId, updatedRecord: saveBalancePullingRecordResponse }));
    }, [saveBalancePullingRecordResponse])
    const triggerBalancePullingRecordSave = function(record:DatasetRecord){
        setBalancePullingRecord(record);
        setBalancePullingRecordNeedsSaving(new Date().toISOString());
    }

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(pullBalanceRequestPayloads)){
            return;
        }
        const currentPayload = pullBalanceRequestPayloads[0];
        if (currentPayload.requestType === PullBalanceRequestTypes.PullGLBalance) {
                services.atpWebSocketApiService.requestBalancePull(currentPayload.payload);
        }
        else {
                console.warn("Unknown request type found for: ",currentPayload);
        }        
        datasetDispatch(ACTIONS.REMOVE_PULL_BALANCE_PAYLOAD);
    }, [pullBalanceRequestPayloads]);
    
    return (
        <ExpandableSection data-class="polarisExpandableSection"
            variant="container"
            defaultExpanded={expanded}
            headerText={containerTitle}
        >
            <DatasetProvider services={services} state={datasetState} dispatch={datasetDispatch} tpAllocationState={tpAllocationState}>
                <div className="wizardBoxContentContainer">
                    <div className="datasourceHeader">
                        <TPELoadingSpinner loading={datasetRecordsLoading} loadingText="Loading data sources">
                            <DatasetMainGrid key={1} />
                        </TPELoadingSpinner>
                    </div>
                    {showDataKeySelection &&
                        <div className="dataKeyContainer">
                            <DataSourcesSelectCOA {...{
                                allRecordDescriptions: datasetRecords.map(x => x.description),
                                coaTypes,
                                customCoaReference,
                                placeholdersMap,
                                dispatch: datasetDispatch,
                                isSaving,
                                onRecordNeedsSaving: (x: string) => setRecordNeedsSaving(x),
                                originalRecordDescription: recordBeingEditedOriginal?.description || '',
                                recordBeingEdited: { ...recordBeingEdited, entityId: recordBeingEdited?.datasourceId || '' },
                                services,
                                sevenSegmentLOV: sevenSegmentLOVMap,
                                showDrillDownSelect: false,
                                templateId: worksheetTemplate?.templateId,
                                saveButtonLabel: "Save data source"
                            }}
                            />
                            <DataSourcesMagicBox
                                key={recordBeingEdited?.datasourceId}
                                recordBeingEdited={recordBeingEdited}
                                services={services}
                                dispatch={datasetDispatch}
                                ACTIONS={ACTIONS}
                                showDecimals={showDecimals}
                                coaTypes={coaTypes}
                                selectedCustomCoaValues={selectedCustomCoaValues}
                                placeholdersMap={placeholdersMap}
                                sevenSegmentLOV={sevenSegmentLOVMap} 
                                allowBalancePull={!isTemplate} />
                        </div>
                    }
                    {recordBeingEdited != undefined && datasetRecords.indexOf(recordBeingEdited) != datasetRecords.length - 1 &&
                        <div>
                            <DatasetSplitGrid key={tableKey} />
                        </div>
                    }
                    {!isTemplate && <BalancePuller 
                                                records={datasetRecords} 
                                                sevenSegmentLOV={sevenSegmentLOVMap} 
                                                state={datasetState} 
                                                dispatch={datasetDispatch}
                                                onRecordNeedsSaving={triggerBalancePullingRecordSave} />}
                    <SimpleUseEffect 
                        useEffectVars={[saveDatasetRecordResponse, savingError]} 
                        action={() => tpAllocationDispatch(TP_ALLOCATION_ACTIONS.CHECK_AND_REFRESH_ENTITY_DETAILS)}
                    />
                    {viewMode === CONSTANTS.VIEW_MODE.EDITABLE &&
                        <div className="actionButtonsContainer">
                            <Box float="left">
                                <Button data-class="subPrimaryButton" onClick={addNewRecord} disabled={recordBeingEdited?.isEditing}>Add data source</Button>
                            </Box>
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="m">
                                    <Button data-class="nextButton" variant="primary" disabled={!atLeastOneDatasetItemIsSaved} onClick={() => tpAllocationDispatch(TP_ALLOCATION_ACTIONS.SELECT_NEXT_WIZARD_STEP)}>
                                        Next Step
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        </div>
                    }
                </div>
            </DatasetProvider>
        </ExpandableSection>
    );
}

/**
 * Appends a listener for the balance pull WS (Web Socket) messages. It checks the messages and
 * dispatches actions on the dataSource reducer based on status and balance pull data.
 * @param services The list of services
 * @param datasetDispatch The dataset dispatcher function
 */
function setupWebSocketEvents(services: ServiceCollection, datasetDispatch: React.Dispatch<TPEAction>) {
    const listener = (x:any) => {
        const webSocketMessage = JSON.parse(x);
        // Adding this check since now we have worsheet execution messages coming from the web socket API
        if (webSocketMessage?.messageType !== CONSTANTS.WEB_SOCKET_API_ROUTES.PULL_GL_BALANCE || webSocketMessage?.status == null){
            return;
        }
        const message = webSocketMessage as PullBalanceWSMessage;
        if ( message?.status.statusType === CONSTANTS.PROCESSING_STATUS.SUCCEEDED ){
            const balanceData = message.balance;
            try {
                const { glBalances, glBalanceBreakdownKeys } = services.dataSourcesService.convertToGLBalancesModel(balanceData);                
                datasetDispatch(
                    ACTIONS.UPDATE_BALANCES.withPayload(
                        { 
                            recordID: message.dataSourceId, 
                            requestID: message.requestId,
                            totalBalance: balanceData.balance, 
                            calculatedFxRate: balanceData.fxRate, 
                            ledgerCurrency: balanceData.ledgerCurrency || '', 
                            glBalances: glBalances,
                            glBalanceDrillDownKeys: glBalanceBreakdownKeys
                        }
                    )
                )
            }
            catch(error){
                datasetDispatch(ACTIONS.UPDATE_DATASET_RECORD_STATUS.withPayload(message));
                datasetDispatch(ACTIONS.SET_PULL_BALANCE_ERROR.withPayload({ recordID: message.dataSourceId, value: 'There was a problem parsing the results. Please try again later', requestID: message.requestId }));
            }
        }
        else if ( message?.status.statusType === "FAILED" ) {
            datasetDispatch(ACTIONS.UPDATE_DATASET_RECORD_STATUS.withPayload(message));
            datasetDispatch(ACTIONS.SET_PULL_BALANCE_ERROR.withPayload({ recordID: message.dataSourceId, value: message.balance.error, requestID: message.requestId }));
        }
        else {
            datasetDispatch(ACTIONS.UPDATE_DATASET_RECORD_STATUS.withPayload(message));
        }
    };
    services.atpWebSocketApiService.addListener(listener);
    return listener;
}