import React, { useEffect, useState } from 'react';
import TemporaryMessage from '../../models/common/TemporaryMessage';
import TPEAction from '../../models/common/TPEAction';
import { FlashbarProps } from '@amzn/awsui-components-react';
import { nanoid } from 'nanoid'

export default class MessageService {
    
    dispatcher?: React.Dispatch<TPEAction>;
    bannerDispatcher?: React.Dispatch<TPEAction>;
    dispatchAction?: TPEAction;
    addBannerMessageAction?: TPEAction;
    clearBannerMessageAction?: TPEAction;
    /**
     * setup
     */
    public setup(dispatcher: React.Dispatch<TPEAction>, action: TPEAction) {
        this.dispatchAction = action;
        this.dispatcher = dispatcher;
    }

    /**
     * setup for banner
     */
     public setupForBanner(dispatcher: React.Dispatch<TPEAction>, addMessageAction: TPEAction, clearMessageAction: TPEAction) {
        this.addBannerMessageAction = addMessageAction;
        this.clearBannerMessageAction = clearMessageAction;
        this.bannerDispatcher = dispatcher;
    }

    /**
     * Allows showing a success message for a period of time
     * @param message The message
     * @param duration The duration in miliseconds (default is 5000)
     */
    public showSuccessTimed(message: string, duration: number = 5000) {
        this.showSuccess(message);
        setTimeout((dispatch: React.Dispatch<TPEAction>, action: TPEAction) => {
            dispatch(action.withPayload(null));
        }, duration, this.dispatcher, this.dispatchAction);
    }

    /**
     * Allows showing a permanent success message
     * @param message The message
     */
    public showSuccess(message: string) {
        if (this.dispatcher == null || this.dispatchAction == null) {
            console.error("Dispatcher and action has not been set. Invoke setup method before invoking show method.");
            return;
        }
        this.dispatcher(this.dispatchAction.withPayload(new TemporaryMessage(message, "success", true)));
    }
    
    
    /**
     * Allows showing an error message for a period of time
     * @param message The message
     * @param duration The duration in miliseconds (default is 5000)
     */
    public showErrorTimed(message: string, duration: number = 5000) {
        this.showError(message);
        setTimeout((dispatch: React.Dispatch<TPEAction>, action: TPEAction) => {
            dispatch(action.withPayload(null));
        }, duration, this.dispatcher, this.dispatchAction);
    }

    /**
     * Allows showing a permanent error message
     * @param message The message
     */
    public showError(message: string) {
        if (this.dispatcher == null || this.dispatchAction == null) {
            console.error("Dispatcher and action has not been set. Invoke setup method before invoking show method.");
            return;
        }
        this.dispatcher(this.dispatchAction.withPayload(new TemporaryMessage(message, "error", true)));
    }

    public clearMessages() {
        if (this.dispatcher == null || this.dispatchAction == null) {
            console.error("Dispatcher and action has not been set. Invoke setup method before invoking clear method.");
            return;
        }
        this.dispatcher(this.dispatchAction.withPayload(undefined));
    }

    public showBannerMessage(message: string, type: 'success' | 'warning' | 'info' | 'error'){
        if (this.bannerDispatcher == null || this.addBannerMessageAction == null || this.clearBannerMessageAction == null) {
            console.error("Dispatcher and addMessageAction has not been set. Invoke setupForBanner method before invoking show method.");
            return;
        }
        const id = nanoid();
        const $bannerDispatcher = this.bannerDispatcher;
        const $clearAction = this.clearBannerMessageAction;
        const dismissAction = () => $bannerDispatcher($clearAction.withPayload(id));
        this.bannerDispatcher(this.addBannerMessageAction.withPayload({
            id: id,
            content: message,
            type: type,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: dismissAction
        }));
        return id;
    }

    /**
     * Allows showing a success message in top banner
     * @param message The message
     */
    public showSuccessBanner(message: string) {
        return this.showBannerMessage(message,"success");
    }

    /**
     * Allows showing a success banner message for a period of time
     * @param message The message
     * @param duration The duration in miliseconds (default is 5000)
     */
     public showSuccessAutoDismissBanner(message: string, duration: number = 5000) {
        const messageId = this.showSuccessBanner(message);
        setTimeout((dispatch: React.Dispatch<TPEAction>, action: TPEAction) => {
            dispatch(action.withPayload(messageId));
        }, duration, this.bannerDispatcher, this.clearBannerMessageAction);
    }


    /**
     * Allows showing an error message in top banner
     * @param message The message
     */
     public showErrorBanner(message: string) {
        return this.showBannerMessage(message,"error")
    }

    /**
     * Allows showing an error banner message for a period of time
     * @param message The message
     * @param duration The duration in miliseconds (default is 5000)
     */
     public showErrorAutoDismissBanner(message: string, duration: number = 5000) {
        const messageId = this.showErrorBanner(message);
        setTimeout((dispatch: React.Dispatch<TPEAction>, action: TPEAction) => {
            dispatch(action.withPayload(messageId));
        }, duration, this.bannerDispatcher, this.clearBannerMessageAction);
    }

    /**
     * Allows showing an informational message in top banner
     * @param message The message
     */
     public showInfoBanner(message: string) {
        return this.showBannerMessage(message,"info")
    }

    /**
     * Allows showing an informational banner message for a period of time
     * @param message The message
     * @param duration The duration in miliseconds (default is 5000)
     */
     public showInfoAutoDismissBanner(message: string, duration: number = 5000) {
        const messageId = this.showInfoBanner(message);
        setTimeout((dispatch: React.Dispatch<TPEAction>, action: TPEAction) => {
            dispatch(action.withPayload(messageId));
        }, duration, this.bannerDispatcher, this.clearBannerMessageAction);
    }

    /**
     * Allows showing a warning message in top banner
     * @param message The message
     */
     public showWarningBanner(message: string) {
        return this.showBannerMessage(message,"warning")
    }

    /**
     * Allows showing a warning banner message for a period of time
     * @param message The message
     * @param duration The duration in miliseconds (default is 5000)
     */
     public showWarningAutoDismissBanner(message: string, duration: number = 5000) {
        const messageId = this.showWarningBanner(message);
        setTimeout((dispatch: React.Dispatch<TPEAction>, action: TPEAction) => {
            dispatch(action.withPayload(messageId));
        }, duration, this.bannerDispatcher, this.clearBannerMessageAction);
    }
}