import * as XLSX from 'xlsx';

export function downloadReport(fileName: string, dataURL: any) {
    const DATA_URL_BASE = 'data:application/vnd.ms-excel;base64,';
    const reportDownload = document.createElement('a');
    reportDownload.download = fileName;
    reportDownload.href = DATA_URL_BASE + dataURL;
    document.body.appendChild(reportDownload);
    reportDownload.click();
    document.body.removeChild(reportDownload);
}

/**
 * This function is used to perform auto-adjustment of the excel worksheet
 * @param worksheet 
 * @param data 
 */
export function autoAdjustColumnWidths(worksheet: XLSX.WorkSheet, data: any[]) {

    // Get the keys (column names) from the first row of data
    const colLengths = Object.keys(data[0]).map((k) => k.toString().length)

    // Iterate through each row in the data to find the maximum width for the column
    for (const record of data) {
        Object.values(record).forEach((element: any, index) => {
            if (element) {
                const length = element.length
                if (colLengths[index] < length) {
                    colLengths[index] = length
                }
            }
        })
    }
    // Set the calculated column widths to the worksheet
    worksheet["!cols"] = colLengths.map((length) => {
        return {
            wch: length + 6, // Adding some padding
        }
    })
}