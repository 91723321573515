import { getDefaultSearchPayload } from "src/components/custom-tables-shared/CustomDataTableSearchHelper"
import TPESearchRequest from "src/models/common/TPESearchRequest"
import CustomDataTableRowsInfo from "src/models/custom-data-tables/CustomDataTableRowsInfo"
import { SearchCustomDataTablesResult } from "src/models/custom-data-tables/SearchCustomDataTablesResult"

export interface CustomTablesState {
    error?: string,
    s3Key?: string,
    duplicateRowsInfo?: CustomDataTableRowsInfo,
    saveCustomDataTablePayload?: CustomDataTableRowsInfo,
    searchPayload?: TPESearchRequest,
    searchResult?: SearchCustomDataTablesResult
}


export const initialState: CustomTablesState = {
    
}
