import { useEffect, useState } from 'react';
import { WorklistItem } from '../../models/agreements/Worklist';
import apiService from '../ApiCallService';
import { UserData, UserProfile } from 'src/models/users/UserProfile';
import ErrorUtils from 'src/utils/ErrorUtils';

export default class WorklistService {

    /**
    * Pulls the worklists from the user profile
    * @param userName The logged in userName
    */
    getCustomWorklists(userProfile: UserProfile): [WorklistItem[], boolean, string] {
        const [results, setResults] = useState([] as WorklistItem[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    if (userProfile.userData == null || userProfile.userData.length == 0) {
                        setResults([]);
                    }
                    const data = JSON.parse(userProfile.userData as string) as UserData;
                    setResults(data.worklists);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }
            if (userProfile != null) {
                fetchData();
            }
        }, [userProfile]);
        return [results, loading, error];
    }

    /**
    * Pulls the default worklists
    */
    getDefaultWorklists(): [WorklistItem[], boolean, string] {
        const [results, setResults] = useState([] as WorklistItem[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getDefaultWorklists();
                    const json = (await response.json()).worklists as WorklistItem[];
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }
            fetchData();
        }, []);
        return [results, loading, error];
    }


    /**
    * Saves the list of a worklists for the user
    * @param user The logged in user
    * @param payload The list of worklist items
    * @returns An array with the state of the async operation
    */
    saveCustomWorklists(customWorklistsNeedSaving: boolean, payload: WorklistItem[], user?: UserProfile): [boolean, boolean, string] {
        const [isSuccessful, setIsSuccessful] = useState(false);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function saveData() {
                try {
                    setIsSuccessful(false);
                    if (user == null) {
                        return;
                    }
                    setLoading(true);
                    const data = JSON.parse(user.userData as string) as UserData;
                    data.worklists = payload;
                    user.userData = JSON.stringify(data);
                    apiService.saveUserProfile(user);
                    setIsSuccessful(true);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (customWorklistsNeedSaving) {
                saveData();
            }
        }, [payload]);
        return [isSuccessful, loading, error];
    }
}