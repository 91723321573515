import React from "react";
import {
    CLI_JE_STATUS_TO_ICON_TYPE,
    INDIRECT_TAX_CHANGE_CLI_STATUS_TO_ICON_TYPE,
    REPORT_STATUS_TO_ICON_TYPE
} from "src/utils/constants";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import StringUtils from "src/utils/stringUtils";

export default function ReportStatus(props: {
    reportStatus: string
}) {
    const { reportStatus } = props;

    return (
        <StatusIndicator
            type={REPORT_STATUS_TO_ICON_TYPE[reportStatus]}
        >
            {StringUtils.convertCamelCaseToTitleCase(reportStatus)}
        </StatusIndicator>
    );
}

export function IndirectTaxChangeCLIStatus(props: {
    indirectTaxChangeCLIStatus: string
}) {
    const { indirectTaxChangeCLIStatus } = props;

    return (
        <StatusIndicator
            type={INDIRECT_TAX_CHANGE_CLI_STATUS_TO_ICON_TYPE[indirectTaxChangeCLIStatus]}
        >
            {StringUtils.convertCamelCaseToTitleCase(indirectTaxChangeCLIStatus)}
        </StatusIndicator>
    );
}

export function CLIJEStatus(props: {
    cliJEStatus: string
}) {
    const { cliJEStatus } = props;

    return (
        <StatusIndicator
            type={CLI_JE_STATUS_TO_ICON_TYPE[cliJEStatus]}
        >
            {cliJEStatus}
        </StatusIndicator>
    );
}