import ServiceCollection from "src/services/ServiceCollection";
import React, { useEffect } from "react";
import { accountingDaysList } from "src/models/reports/GetAccountingDayList"
import { FormField, Select, SelectProps } from '@amzn/awsui-components-react/polaris';
import { ColumnLayout } from "@amzn/awsui-components-react";

type ComponentProps = {
    services: ServiceCollection,
    selectedAccountingPeriod: SelectProps.Option | null,
    selectedAccountingDay: SelectProps.Option | null,
    onAccountingPeriodSelected: (option: SelectProps.Option | null) => void,
    onAccountingDaySelected: (option: SelectProps.Option | null) => void
}

export default function ReportMetricsDashboardFilterSelector(props: ComponentProps) {
    const {
        services,
        selectedAccountingPeriod,
        selectedAccountingDay,
        onAccountingPeriodSelected,
        onAccountingDaySelected
    } = props;

    const [accountingPeriodResult, accountingPeriodLoading, accountingPeriodError] = services.reportsService.getReportingPeriods();

    useEffect(() => {
        if (!accountingPeriodError) {
            return;
        }
        services.messageService.showErrorAutoDismissBanner(accountingPeriodError);
    }, [accountingPeriodError])

    return (
        <ColumnLayout columns={2}>
            <FormField label="Accounting Period">
                <Select
                    statusType={accountingPeriodLoading ? "loading" : "finished"}
                    loadingText="Fetching available options"
                    selectedOption={{
                        label: selectedAccountingPeriod?.label,
                        value: selectedAccountingPeriod?.value
                    }}
                    onChange={({ detail }) =>
                        onAccountingPeriodSelected(detail.selectedOption)
                    }
                    options={
                        accountingPeriodResult == null
                            ? []
                            : accountingPeriodResult.ReportingPeriodList.map(
                                (accountingPeriodNameValue) => ({
                                    label: accountingPeriodNameValue,
                                    value: accountingPeriodNameValue,
                                })
                            )
                    }
                    placeholder="Select the accounting period"
                    virtualScroll={true}
                    filteringType="auto"
                />
            </FormField>
            <FormField label="Accounting Day">
                <Select
                    selectedOption={
                        {
                            label: selectedAccountingDay?.label,
                            value: selectedAccountingDay?.value
                        }
                    }
                    options={accountingDaysList}
                    onChange={({ detail }) => {
                        onAccountingDaySelected(detail.selectedOption);
                    }}
                    selectedAriaLabel="Selected"
                    placeholder="Select Accounting Day"
                />
            </FormField>
        </ColumnLayout>
    );
}