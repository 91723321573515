export default class AgreementStatusCategoriesResult {
    /**
     * This property holds the categories as they come from the API
     */
    agreementStatusCategories: any

    /**
     * The parsed list of categories and sub-categories
     */
    statusCategoriesList: Array<StatusCategory>;

    constructor(data: any) {
        this.agreementStatusCategories = data.agreementStatusCategories;
        this.statusCategoriesList = [];
        this.buildList();
    }

    private buildList() {
        Object.keys(this.agreementStatusCategories).forEach(field => {
            this.statusCategoriesList.push({
                name: field,
                subCategories: this.agreementStatusCategories[field]
            });
        })
    }
}

export interface StatusCategory {
    name: string,
    subCategories: Array<string>,
}