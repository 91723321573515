export default class TPESearchRequest {
    page: number;
    pageSize: number;
    sortField?: string;
    sortOrder?: string;

    filterExpression?: DbMainExpression;
    searchStartedDate?: Date;


    constructor() {
        this.page = 1;
        this.pageSize = 100;
    }
}

export interface DbMainExpression {
    conjunctor: DbConjunctor,
    expressions: Array<DbExpression | DbMainExpression>,
}

export interface DbExpression {
    conjunctor?: DbConjunctor,
    attributeName: string,
    values: Array<string>,
    operator: DbOperator,
    not?: boolean,
    date?: boolean,
}


export enum DbOperator {
    ATTRIBUTE_EXISTS = "ATTRIBUTE_EXISTS",
    ATTRIBUTE_NOT_EXISTS = "ATTRIBUTE_NOT_EXISTS",
    BEGINS_WITH = "BEGINS_WITH",
    BETWEEN = "BETWEEN",
    CONTAINS = "CONTAINS",
    EQUALS = "EQUALS",
    GREATER_THAN = "GREATER_THAN",
    GREATER_THAN_EQUALS = "GREATER_THAN_EQUALS",
    IN = "IN",
    LESS_THAN = "LESS_THAN",
    LESS_THAN_EQUALS = "LESS_THAN_EQUALS",
    NOT_EQUALS = "NOT_EQUALS"
}

export enum DbSortOrder {
    ASCENDING = "ASCENDING",
    DESCENDING = "DESCENDING"
}

export enum DbConjunctor {
    AND = "AND",
    OR = "OR"
}


