import { Button, ButtonDropdown, Container, Header, Pagination, SpaceBetween, TextFilter } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import TPAllocationWorksheetSelectorGrid from './TPAllocationWorksheetSelectorGrid';
import { DataSourcesContext } from './DataSourcesContainer';
import TPESearchRequest from 'src/models/common/TPESearchRequest';
import TPEErrorWatcher from 'src/components/shared/TPEErrorWatcher';
import { defaultSearchPayload } from 'src/services/calculation-builder/DataSourcesState';
import { Worksheet } from 'src/models/tp-allocation/WorksheetList';
import { TPEBasicModal, ACTION_TYPE } from 'src/components/shared/TPEBasicModal';
import { ACTIONS } from 'src/services/calculation-builder/DataSourcesReducer';
import { ACTIONS as CALC_BUILDER_ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import TPAllocationGroupSelectorGrid from './TPAllocationGroupSelectorGrid';
import CONSTANTS from 'src/utils/constants';
import AllocationGroup from 'src/models/tp-allocation/AllocationGroup';
import { SearchWorksheetAllocationFormulasRequest } from 'src/models/tp-allocation/SearchWorksheetAllocationFormulasRequest';
import { AllocationFormula } from 'src/models/tp-allocation/AllocationFormula';
import TPAllocationFormulaSelectorGrid from './TPAllocationFormulaSelectorGrid';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import StringUtils from 'src/utils/stringUtils';
import DataKeySummaryView from './DataKeySummaryView';
import KeyValuePair from 'src/models/common/KeyValuePair';
import CancelEditing from './CancelEditing';

export function TPAllocationWorksheetSelector() {
    const { calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const { state, services, dispatch } = React.useContext(DataSourcesContext);

    const { recordBeingEdited = {} as DataSourceRecord,
            dataSourceRecords,
            customCoaReference = new Map<string, string>(),
            recordBeingEditedOriginal } = state;

    const WORKSHEETS_GRID_STEP = 1;
    const ALLOCATION_GROUPS_GRID_STEP = 2;
    const ALLOCATION_FORMULAS_GRID_STEP = 3;

    const [searchWorksheetsPayload, setSearchWorksheetsPayload] = useState(null as unknown as TPESearchRequest);
    const [searchFormulasPayload, setSearchFormulasPayload] = useState(null as unknown as SearchWorksheetAllocationFormulasRequest);
    const [searchResult, setSearchResult] = useState(null as unknown as any);
    const [nextButtonText, setNextButtonText] = useState('');
    const [tableHeaderText, setTableHeaderText] = useState('');
    const [selectedWorksheet, setSelectedWorksheet] = useState(null as unknown as Worksheet | undefined);
    const [selectedAllocationGroup, setSelectedAllocationGroup] = useState(null as unknown as AllocationGroup | undefined);
    const [selectedAllocationFormula, setSelectedAllocationFormula] = useState(null as unknown as AllocationFormula | undefined);
    const [selectedMappingType, setSelectedMappingType] = useState('');
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [pullAllocationGroups, setPullAllocationGroups] = useState(false);
    const [activeGridStep, setActiveGridStep] = useState(WORKSHEETS_GRID_STEP);
    const [recordNeedsSaving, setRecordNeedsSaving] = React.useState(undefined as string | undefined);
    const [viewMode, setViewMode] = useState(false);

    const [searchWorksheetsResult, isSearchingWorksheets, searchWorksheetsError] = services.tpAllocationService.searchWorksheets(searchWorksheetsPayload);
    const [searchAllocationGroupsResult, isSearchingAllocationGroups, searchAllocationGroupsError] = services.tpAllocationGroupsService.getAllocationGroups(pullAllocationGroups,
        activeGridStep == ALLOCATION_GROUPS_GRID_STEP ? selectedWorksheet?.worksheetId : null as unknown as string, CONSTANTS.CALCULATION_VERSIONS.CURRENT_VERSION);
    const [searchFormulasResult, isSearchingFormulas, searchFormulasError] = services.tpAllocationService.searchTPAllocationWorksheetFormulas(searchFormulasPayload);
    const [saveDataSourceRecordResponse, isSaving, savingError] = services.dataSourcesService.saveDataSource(customCoaReference, false, recordBeingEdited, recordNeedsSaving);

    useEffect(() => {
        if (recordBeingEdited?.tpAllocationWorksheetId != null) {
            setViewMode(true);
        } else {
            setSearchWorksheetsPayload({...defaultSearchPayload});
        }
    }, [])

    useEffect(() => {
        switch (activeGridStep) {
            case WORKSHEETS_GRID_STEP:
                setNextButtonText('Next');
                setTableHeaderText('Active TP allocation worksheets')
                break;
            case ALLOCATION_GROUPS_GRID_STEP:
                setNextButtonText('');
                setTableHeaderText(`Allocation groups in ${selectedWorksheet?.worksheetName}`)
                break;
            case ALLOCATION_FORMULAS_GRID_STEP:
                setNextButtonText('Save data source');
                setTableHeaderText(`Allocation formulas in ${selectedWorksheet?.worksheetName} - ${selectedAllocationGroup?.description}`);
                break;
        }
    }, [activeGridStep])

    useEffect(() => {
        if (searchWorksheetsResult == null) {
            return;
        }
        setSearchResult(searchWorksheetsResult);
    }, [searchWorksheetsResult])

    useEffect(() => {
        if (searchAllocationGroupsResult == null) {
            return;
        }
        setSearchResult(constructAllocationGroupSearchResult());
        setPullAllocationGroups(false);
    }, [searchAllocationGroupsResult])

    useEffect(() => {
        if (searchFormulasResult == null) {
            return;
        }
        setSearchResult(searchFormulasResult);
    }, [searchFormulasResult])

    useEffect(() => {
        if ( saveDataSourceRecordResponse == null ){
            return;
        }
        if (recordBeingEditedOriginal?.description != recordBeingEdited?.description) {
            calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.withPayload(true));
        }
        dispatch(ACTIONS.SAVE_RECORD.withPayload({ recordID: recordBeingEdited?.datasourceId, updatedRecord: saveDataSourceRecordResponse }));
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [saveDataSourceRecordResponse])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(savingError)) {
            return;
        }
        dispatch(ACTIONS.SET_SAVE_ERROR.withPayload(savingError));
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [savingError])

    const constructAllocationGroupSearchResult = () => {
        return {
            allocationGroups: searchAllocationGroupsResult,
            pagesCount: 1,
            totalSize: searchAllocationGroupsResult.length
        }
    }

    const constructAllocationFormulasSearchRequest = () => {
        return {
                ...defaultSearchPayload,
                filterExpression: undefined,
                worksheetId: selectedWorksheet?.worksheetId,
                worksheetVersion: CONSTANTS.CALCULATION_VERSIONS.CURRENT_VERSION,
                allocationGroupId: selectedAllocationGroup?.allocationGroupId
            }
    }

    const goBack = () => {
        switch (activeGridStep) {
            case ALLOCATION_GROUPS_GRID_STEP:
                setActiveGridStep(WORKSHEETS_GRID_STEP);
                setSearchResult(searchWorksheetsResult);
                setSelectedAllocationGroup(undefined);
                break;
            case ALLOCATION_FORMULAS_GRID_STEP:
                setActiveGridStep(ALLOCATION_GROUPS_GRID_STEP);
                setSearchResult(constructAllocationGroupSearchResult());
                setSelectedAllocationFormula(undefined);
                break;
        }
    }

    const goNext = () => {
        switch (activeGridStep) {
            case WORKSHEETS_GRID_STEP:
                setActiveGridStep(ALLOCATION_GROUPS_GRID_STEP);
                setPullAllocationGroups(true);
                break;
            case ALLOCATION_GROUPS_GRID_STEP:
                setActiveGridStep(ALLOCATION_FORMULAS_GRID_STEP);
                setSearchFormulasPayload(constructAllocationFormulasSearchRequest());
                break;
            case ALLOCATION_FORMULAS_GRID_STEP:
                saveDataSource();
                break;
        }
    }

    const goNextFromAllocationGroup = (mappingType: string) => {
        setSelectedMappingType(mappingType);
        if (mappingType == CONSTANTS.TP_ALLOCATION_MAPPING_TYPE.FORMULA) {
            goNext();
        } else {
            saveDataSource();
        }
    }

    const isNextDisabled = () => {
        switch (activeGridStep) {
            case WORKSHEETS_GRID_STEP:
                return selectedWorksheet == null;
            case ALLOCATION_GROUPS_GRID_STEP:
                return selectedAllocationGroup == null;
            case ALLOCATION_FORMULAS_GRID_STEP:
                return selectedAllocationFormula == null;
        }
    }

    const isSearching = () => {
        return isSearchingWorksheets || isSearchingAllocationGroups || isSearchingFormulas;
    }

    const onCancelPostProcess = () => {
        setSelectedWorksheet(undefined);
        dispatch(ACTIONS.CANCEL_EDITING);
    }

    const goToResultsPage = (pageNumber: number) => {
        switch (activeGridStep) {
            case WORKSHEETS_GRID_STEP:
                setSearchWorksheetsPayload({...searchWorksheetsPayload, page: pageNumber});
                break;
            case ALLOCATION_FORMULAS_GRID_STEP:
                setSearchFormulasPayload({...searchFormulasPayload, page: pageNumber});
                break;
        }
    }

    const saveDataSource = () => {
        recordBeingEdited.tpAllocationWorksheetId = selectedWorksheet?.worksheetId as string;
        recordBeingEdited.tpAllocationGroupId = selectedAllocationGroup?.allocationGroupId as string;
        recordBeingEdited.tpAllocationMappingType = selectedMappingType;
        if (selectedMappingType == CONSTANTS.TP_ALLOCATION_MAPPING_TYPE.FORMULA) {
            recordBeingEdited.tpAllocationFormulaId = selectedAllocationFormula?.allocationFormulaId as string;
        } else {
            // State change not taking effect, so set RECIPIENT manually here
            recordBeingEdited.tpAllocationMappingType = CONSTANTS.TP_ALLOCATION_MAPPING_TYPE.RECIPIENT;
            recordBeingEdited.tpAllocationFormulaId = null as unknown as string;
        }

        let errorMap = new Map<string, string>();

        if (recordBeingEdited.description == null) {
            let i = 1;
            let description = selectedWorksheet?.worksheetName;
            while (dataSourceRecords.find(x => x.description == description) != undefined) {
                description = `${selectedWorksheet?.worksheetName} (${i})`;
                i++;
            }
            recordBeingEdited.description = description as string;
        } else {
            errorMap = services.dataSourcesService.validateDataSourceDescription(recordBeingEdited.description, dataSourceRecords.map(x => x.description));
        }

        recordBeingEdited.dataKey = selectedWorksheet?.worksheetName as string;

        if (errorMap.size > 0) {
            dispatch(ACTIONS.SET_ERRORS.withPayload({ recordID: recordBeingEdited?.datasourceId, value: errorMap }));
        } else {
            dispatch(ACTIONS.CLEAR_ERRORS.withPayload({ recordID: recordBeingEdited?.datasourceId }));
            setRecordNeedsSaving(new Date().toISOString());
        }
    }

    const getSummary = () => {
        const items: KeyValuePair[] = [];
        items.push(new KeyValuePair("TP allocation worksheet", recordBeingEdited?.tpAllocationWorksheetName));
        items.push(new KeyValuePair("Allocation group", recordBeingEdited?.tpAllocationGroupName));
        items.push(new KeyValuePair("Formula selection", recordBeingEdited?.tpAllocationMappingType == CONSTANTS.TP_ALLOCATION_MAPPING_TYPE.RECIPIENT ? 
        "By recipient company": "Manual"));
        items.push(new KeyValuePair("Formula name", recordBeingEdited?.tpAllocationFormulaName));
        return items;
    }

    const editFromSummary = () => {
        setViewMode(false);
        setSearchWorksheetsPayload({...defaultSearchPayload});
    }

    return (
        viewMode ? 
            <DataKeySummaryView 
                header="Selected TP allocation worksheet formula" 
                summaryItems={getSummary()} 
                showEdit={recordBeingEdited?.isEditing} 
                onEdit={editFromSummary}/>
        :
        <Container data-class="dataKeyContentContainer"
            footer={
                <SpaceBetween data-class="dataKeyTableFooter" direction="horizontal" size="s">
                    <CancelEditing onCancelPostProcess={onCancelPostProcess} />
                    {activeGridStep != WORKSHEETS_GRID_STEP &&
                        <Button variant="link" className="smallSecondaryButton backBtn" onClick={goBack}>Back</Button>
                    }
                    {activeGridStep == ALLOCATION_GROUPS_GRID_STEP ?
                        <ButtonDropdown data-class="smallPrimaryButtonDropdown"
                            disabled={isNextDisabled()}
                            items={[
                                { text: 'Select formula manually', id: CONSTANTS.TP_ALLOCATION_MAPPING_TYPE.FORMULA },
                                { text: 'Auto-select formula by recipient and save', id: CONSTANTS.TP_ALLOCATION_MAPPING_TYPE.RECIPIENT }
                            ]}
                            onItemClick={({ detail }) => goNextFromAllocationGroup(detail.id)}
                        >
                            Next
                        </ButtonDropdown>
                    :
                        <Button className="smallPrimaryButton" disabled={isNextDisabled()} onClick={goNext}>{nextButtonText}</Button>
                    }
                </SpaceBetween>
            }
        >
            <div className={'dataKeyTableHeader' + (activeGridStep == WORKSHEETS_GRID_STEP ? ' dataKeyTableHeaderWithSearch': '')}>
                <SpaceBetween direction="vertical" size="s">
                <Header 
                    variant="h3"
                    counter={isSearching() ? '(...)' : `(${searchResult?.totalSize})`}
                    actions={searchResult != null && searchResult?.pagesCount > 1 &&
                            <Pagination
                                currentPageIndex={searchResult?.page}
                                pagesCount={searchResult?.pagesCount}
                                disabled={searchResult?.pagesCount == 0 || isSearching()}
                                onChange={({ detail }) => goToResultsPage(detail.currentPageIndex)}
                            />
                    }
                >
                    <span className="datKeyTableHeaderText">{tableHeaderText}</span>
                </Header>
                {activeGridStep == WORKSHEETS_GRID_STEP && <TextFilter data-class="basicTextFilter"
                            filteringText=''
                            filteringPlaceholder='Search TP allocation worksheets'
                            filteringAriaLabel="Filter instances"
                />}
                </SpaceBetween>
            </div>
            <div className="tableContainer">
                {activeGridStep == WORKSHEETS_GRID_STEP && <TPAllocationWorksheetSelectorGrid 
                    data={searchWorksheetsResult?.worksheets}
                    loading={isSearchingWorksheets}
                    onSelect={setSelectedWorksheet}
                />}
                {activeGridStep == ALLOCATION_GROUPS_GRID_STEP && <TPAllocationGroupSelectorGrid 
                    data={searchAllocationGroupsResult}
                    loading={isSearchingAllocationGroups}
                    onSelect={setSelectedAllocationGroup}
                />}
                {activeGridStep == ALLOCATION_FORMULAS_GRID_STEP && <TPAllocationFormulaSelectorGrid 
                    data={searchFormulasResult?.allocationFormulas}
                    loading={isSearchingFormulas}
                    onSelect={setSelectedAllocationFormula}
                />}
            </div>
            <TPEErrorWatcher services={services} errors={[searchWorksheetsError, searchAllocationGroupsError, searchFormulasError]} />
        </Container>
    )
}