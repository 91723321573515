import React from 'react';
import { Box, SpaceBetween, Button, Modal } from '@amzn/awsui-components-react/polaris';

export enum ACTION_TYPE {
    YES_NO,
    OK_CANCEL,
    OK_ONLY,
    CONFIRM_CANCEL
}

export const DEFAULT_BUTTON_LABEL = 'default';

export function TPEBasicModal(props: { children: any, title: string, action: ACTION_TYPE, visible: boolean, onConfirm: () => void, onCancel: () => void, className?: string, primaryButtonDisabledLabel?: string }) {
    const { children, title, action, visible, onConfirm, onCancel, className = '', primaryButtonDisabledLabel } = props;

    const yesButton = action == ACTION_TYPE.YES_NO ? 'Yes' : (action == ACTION_TYPE.CONFIRM_CANCEL ? 'Confirm' : 'OK');
    const noButton = action == ACTION_TYPE.YES_NO ? 'No' : (action == ACTION_TYPE.OK_CANCEL || action == ACTION_TYPE.CONFIRM_CANCEL ? 'Cancel' : null);
    const internalDisabledLabel = primaryButtonDisabledLabel == DEFAULT_BUTTON_LABEL ? yesButton : primaryButtonDisabledLabel;
    
    return (
        <Modal
            className={className}
            onDismiss={onCancel}
            visible={visible}
            size="medium"
            footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                      {noButton && <Button variant="link" className="basicModalCancelBtn" onClick={onCancel}>{noButton}</Button>}
                      <Button variant="primary" className="basicModalOkBtn" onClick={onConfirm} disabled={internalDisabledLabel != null}>{internalDisabledLabel != null? internalDisabledLabel : yesButton}</Button>
                  </SpaceBetween>
                </Box>
              }
              header={title}
        >
            {children}
        </Modal>
    )
}