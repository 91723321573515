import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { SpaceBetween } from '@amzn/awsui-components-react/polaris';
import TPAgreementDetails from './TPAgreementDetails';
import TPCalculationList from './TPCalculationList';
import DynamicBreadcrumb from '../../models/navigation/DynamicBreadcrumb';
import ServiceCollection from '../../services/ServiceCollection';
import { AgreementDetailsResult, Agreement } from '../../models/agreements/AgreementDetailsResult';
import { AgreementVersionsResult, AgreementVersionItem } from '../../models/agreements/AgreementVersionsResult';
import { GlobalAppContext } from '../App';
import { GLOBAL_ACTIONS } from 'src/services/global/GlobalReducer';

export default function AgreementDetails(props: { services: ServiceCollection }) {
    const { pathname } = useLocation();
    const { encodedRecordId } = useParams< { encodedRecordId: string }>();
    const [selectedVersion, setSelectedVersion] = React.useState(atob(encodedRecordId));
    const { services } = props;

    const [agreementDetailsResult, loading, error] = services.agreementsService.getAgreementDetails(selectedVersion);
    const [agreement, setAgreement] = useState(new AgreementDetailsResult().agreement);

    const [agreementVersionsResult, loadingVersions, errorVersions] = services.agreementsService.getAgreementVersions(agreement?.recordId);
    const [versions, setVersions] = useState([] as AgreementVersionItem[]);
    const { globalDispatch } = React.useContext(GlobalAppContext);

    useEffect(() => {
        if (agreementDetailsResult == null) {
            return;
        }
        setAgreement(agreementDetailsResult.agreement);
    }, [agreementDetailsResult])

    useEffect(() => {
        if (agreementVersionsResult == null) {
            setVersions([]);
            return;
        }
        setVersions(agreementVersionsResult.versions);
    }, [agreementVersionsResult])

    // Set breadcrumb on change of agreement
    useEffect(() => {
        if (agreement == null) {
            return;
        }
        let breadcrumbText = ' ';
        if (agreement.agreementName || agreement.agreementNumber) {
            breadcrumbText = `${agreement.agreementName} (${agreement.agreementNumber})`;
        }
        globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, breadcrumbText)));
    }, [agreement]);

    return (
        <SpaceBetween direction="vertical" size="l">
            <TPAgreementDetails agreement={agreement} versions={versions} expanded={true} setSelectedVersion={setSelectedVersion} loading={loading as boolean} />
            <TPCalculationList agreementNumber={agreement?.agreementNumber} services={services} expanded={true} />
        </SpaceBetween>
    )
}
