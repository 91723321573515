import { useEffect, useState } from "react";
import READ_ONLY_CONSTANTS from "../../utils/readOnlyCalculationConstants"
import apiService from '../ApiCallService'
import { CalculationFormula, ExpressionPart, ExpressionPartTypes } from "src/models/calculations/CalculationDetailsResult";
import CalculationStep from "src/models/calculation-builder/CalculationStep";
import FormulaExpressionItem from "src/models/common/FormulaExpressionItem";
import { DataSourceRecord } from "src/models/common/DataSourceRecord";
import ErrorUtils from "src/utils/ErrorUtils";

export default class ReadOnlyCalculationService {
    /**
     * Pulls NAFN only agreement numbers
     * TODO: add api call
     */
    getAgreementNumbers(): [string[], boolean, string] {
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');
        return [READ_ONLY_CONSTANTS.AGREEMENT_NUMBERS, loading, error];
    }

    /**
     * Pulls NAFN only calculation numbers
     * @param agreementNumber that maps to a list of calculations
     * TODO: add api call
     */
    getCalculationNumbers(agreementNumber?: string): [string[], boolean, string] {
        const [results, setResults] = useState([] as string[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    // @ts-ignore
                    const response = READ_ONLY_CONSTANTS.AGREEMENT_TO_CALCULATION_NUMBERS[agreementNumber]
                    setResults(response);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (agreementNumber != null) {
                fetchData();
            }
        }, [agreementNumber])
        return [results, loading, error];
    }

    /**
     * Pulls version numbers of a specific calculation
     * @param calculationNumber that maps to a list of versions
     * TODO: add api call
     */
    getVersions(calculationNumber?: string): [number[], boolean, string] {
        const [results, setResults] = useState([] as number[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCalculationVersions(calculationNumber || "");
                    const result = (await response.json()).versions as number[];
                    //result.unshift(0);
                    setResults(result);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null) {
                fetchData();
            }
        }, [calculationNumber])
        return [results, loading, error];
    }

    /**
     * Constructs CLI# from version and calculation number to pull CLI's formulas
     * @param calculationNumber and version that maps to a list of forulas
     * TODO: add api call
     */
    getFormulas(calculationNumber?: string, version?: number): [CalculationFormula[], boolean, string] {
        const [results, setResults] = useState([] as CalculationFormula[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    // @ts-ignore
                    const response = await apiService.getCalcSteps(calculationNumber, version);
                    setResults((await response.json()).steps);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }
            }
            if (calculationNumber != null && version != null) {
                fetchData();
            }
        }, [calculationNumber, version])
        return [results, loading, error];
    }

    

    breakExpressionIntoParts(expression: string, allSteps: FormulaExpressionItem[], dataSources: FormulaExpressionItem[], tpAllocationIds: Set<string>): ExpressionPart[] {
        if (expression == null || expression.length === 0){
            return [];
        }
        // Breaking down this Regex into parts to make it easier to maintain
        const safeDivideRegex          = /(safeDivide\(.*,.*\))/;
        const getStdAllocRegex         = /(getStandardAllocation\(\[.*?\]\))/;
        const getStdAllocTotalRegex    = /(getStandardAllocationTotal\(\[.*?\]\))/;
        const anythingInBracketsRegex  = /(\[.*?\])/;
        const supportedOperatorsRegex  = /([*+-/%()=\|\&\r\n])/;
        const greaterThanLessThanRegex = /(\<\>)|([<>])/;
        const combinedRegex            = ReadOnlyCalculationService.getCompoxedRegex(
            getStdAllocRegex,
            getStdAllocTotalRegex,
            safeDivideRegex,
            anythingInBracketsRegex,
            supportedOperatorsRegex,
            greaterThanLessThanRegex
        );

        expression = expression.trim();
        const result: ExpressionPart[] = [];
        try {        
            const tokens = expression
                // splitting by supported operators and grouping characters
                .split(combinedRegex)
                .filter(token => token != null && token.length !== 0 && token !== ' ');

            // splitting by supported operators and grouping characters
            const operators = "-*/+=<>|&%";
            const grouping = "(),";
            const keywords = ["IF", "if", "If"];
            for (let index = 0; index < tokens.length; index++) {
                const token = tokens[index];
                
                if (token == null || token.replace(/ /g, "").length === 0) {
                    continue;
                }
                const tokenTrimmed = token.trim();
                if (token === '\n' || token === '\r'){
                    result.push({ value: token, type: ExpressionPartTypes.NEW_LINE })
                }
                else if (keywords.includes(tokenTrimmed)){
                    result.push({value: token.toLocaleLowerCase(), type: ExpressionPartTypes.KEYWORD})
                }
                else if (operators.indexOf(token) >= 0) {
                    result.push({ value: token, type: ExpressionPartTypes.OPERATOR })
                }
                else if (grouping.indexOf(token) >= 0) {
                    result.push({ value: token, type: ExpressionPartTypes.GROUPING })
                }
                // Decimals are split on the period character, so joining the before and after positions
                else if (token === '.') {
                    result.push({ value: `${tokens[index - 1].trim()}.${tokens[index + 1].trim()}`, type: ExpressionPartTypes.LITERAL });
                    index++;
                }
                // If there is a number with no period character next then it is an integer
                else if (!isNaN(+tokenTrimmed) && tokens[index + 1] !== ".") {
                    result.push({ value: tokenTrimmed, type: ExpressionPartTypes.LITERAL })
                }
                else if (isNaN(+token)) {

                    const step = allSteps.find(x => `[${x.name}]` === token);
                    const dataSource = dataSources.find(x => `[${x.name}]` === token);
                    const cleanToken = token.replace(/[\[\]]/g, '');
                    const hasTPAllocation = tpAllocationIds.has(cleanToken.trim());
                    if (step) {                    
                        result.push({ value: cleanToken, type: ExpressionPartTypes.STEP, displayValue: `S${step.visibleSequence}.${cleanToken}` });
                    }
                    else if (dataSource) {
                        result.push({ value: cleanToken, type: ExpressionPartTypes.DATA_SOURCE, displayValue: cleanToken });
                    }
                    else if (hasTPAllocation) {
                        result.push({ value: token, type: ExpressionPartTypes.TP_ALLOCATION_ID, displayValue: cleanToken });
                    }
                    else if (token.includes("(")){
                        // At this point if token includes parenthesis then it's a function since grouping characters
                        // are already capture above.
                        const insideParenthesisRegex = new RegExp(/\((([^()]*|\([^()]*\))*)\)/);
                        const insideParenthesisMatch = tokenTrimmed.match(insideParenthesisRegex);
                        // If the function contains arguments then they need to be parsed as tokens
                        if (insideParenthesisMatch){                            
                            result.push({ value: tokenTrimmed, type: ExpressionPartTypes.FUNCTION, subTokens: this.breakExpressionIntoParts(insideParenthesisMatch[1], allSteps, dataSources, tpAllocationIds)});
                        }
                        else {
                            result.push({ value: tokenTrimmed, type: ExpressionPartTypes.FUNCTION });
                        }
                    }
                    else {
                        result.push({ value: cleanToken, type: ExpressionPartTypes.PARAMETER });
                    }
                }
            }
        }
        catch(e){
            console.warn("************* error expression: ", expression);
            console.warn("************* Regex error: ", e);
        }
        return result;
    }

    /**
     * Flattens steps and sub steps into a single array
     * @param steps The parent steps
     * @returns The flat array
     */
    public static flattenSteps(steps: CalculationStep[]) : CalculationStep[] {
        return steps.reduce((a, b) => a.concat(b.subRows != null && b.subRows.length > 0 ? 
            [...ReadOnlyCalculationService.flattenSteps(b.subRows),b] : b), [] as CalculationStep[]);
    }


    /**
     * Flattens steps and sub steps into a single array
     * @param steps The parent steps
     * @returns The flat array
     */
     public static flattenStepsSorted(steps: CalculationStep[], compareFunction: (a:CalculationStep, b:CalculationStep) => number) : CalculationStep[] {
        const flatSteps = ReadOnlyCalculationService.flattenSteps(steps);
        
        return flatSteps.sort(compareFunction);
    }
    
    /**
     * Combines muyltiple regex into one by string joining them with a pipe
     * @param regexes An infinte list of regular expressions
     * @returns A single RegEx with all the regexes combined
     */
    public static getCompoxedRegex(...regexes:RegExp[]) {
        return new RegExp(regexes.map(regex => regex.source).join("|"));
    }
}


