export const PRE_VALIDATIONS_CONSTANTS = Object.freeze({
    PRE_VALIDATIONS_COA_CHANGES_COLUMN_HEADERS: [
        {
            ACCESSOR: 'cli',
            DISPLAY: 'CLI',
            WIDTH: 200
        },
        {
            ACCESSOR: 'group',
            DISPLAY: 'Group',
            WIDTH: 200
        },
        {
            ACCESSOR: 'stepName',
            DISPLAY: 'Step Name',
            WIDTH: 200
        },
        {
            ACCESSOR: 'type',
            DISPLAY: 'DataSource Type',
            WIDTH: 200
        },
        {
            ACCESSOR: 'description',
            DISPLAY: 'Description',
            WIDTH: 200
        },
        {
            ACCESSOR: 'tableName',
            DISPLAY: 'Table Name',
            WIDTH: 200
        },
        {
            ACCESSOR: 'classification',
            DISPLAY: 'Classification',
            WIDTH: 200
        },
        {
            ACCESSOR: 'segment',
            DISPLAY: 'Segment',
            WIDTH: 200
        },
        {
            ACCESSOR: 'coaDataKey',
            DISPLAY: 'COA Data Key',
            WIDTH: 200
        },
        {
            ACCESSOR: 'coaParents',
            DISPLAY: 'COA Parents',
            WIDTH: 200
        },
        {
            ACCESSOR: 'coaChanges',
            DISPLAY: 'COA Changes',
            WIDTH: 200
        },
        {
            ACCESSOR: 'workbookName',
            DISPLAY: 'Workbook name',
            WIDTH: 200
        },
        {
            ACCESSOR: 'calcAssignee',
            DISPLAY: 'Calc assignee',
            WIDTH: 200
        },
        {
            ACCESSOR: 'accountingOwner',
            DISPLAY: 'Accounting owner',
            WIDTH: 200
        },
        {
            ACCESSOR: 'tpTaxOwner',
            DISPLAY: 'TP tax owner',
            WIDTH: 200
        }
    ],
    PRE_VALIDATIONS_CLI_CDT_PTA_COLUMN_HEADERS: [
        {
            ACCESSOR: 'cli',
            DISPLAY: 'CLI',
            WIDTH: 200
        },
        {
            ACCESSOR: 'workbookName',
            DISPLAY: 'Workbook Name',
            WIDTH: 200
        },
        {
            ACCESSOR: 'workbookAssignee',
            DISPLAY: 'Workbook Assignee',
            WIDTH: 200
        },
        {
            ACCESSOR: 'group',
            DISPLAY: 'Group',
            WIDTH: 200
        },
        {
            ACCESSOR: 'cadence',
            DISPLAY: 'Cadence',
            WIDTH: 200
        },
        {
            ACCESSOR: 'customDataTable',
            DISPLAY: 'Custom data table name',
            WIDTH: 200
        },
        {
            ACCESSOR: 'cdtValue',
            DISPLAY: 'Value',
            WIDTH: 200
        },
        {
            ACCESSOR: 'lastUpdateMonth',
            DISPLAY: 'Last Update Month',
            WIDTH: 200
        },
        {
            ACCESSOR: 'errorMsg',
            DISPLAY: 'Error',
            WIDTH: 200
        }
    ],
});
