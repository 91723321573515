export const AppModules = Object.freeze({
    APP: 'App',
    DASHBOARD: 'Dashboard',
    TP_WORKLISTS: 'TP Worklists',
    CALCULATION_MANAGER: 'Calculation Manager',
    CREATE_NEW_CALCULATION: 'Create New Calculation',
    CREATE_NEW_ICRS_CALCULATION: 'Create New ICRS Calculation',
    SEARCH_CALCULATION: 'Search Calculation',
    BULK_CLONE: 'Bulk Clone',
    BULK_LINK: 'Bulk Link',
    CUSTOM_DATA_TABLES: 'Custom Data Tables',
    CUSTOM_COA: 'Custom COA',
    TP_ALLOCATION: 'TP Allocation',
    CALCULATION_BUILDER: 'Calculation Builder',
    MEC: 'MEC',
    REPORTING: 'Reporting',
    READ_ONLY_CALC: 'Read-only Calculations',
    BULK_ACTIONS: 'Bulk Actions'
});

export const AppRoles = Object.freeze({
    ADMIN: 'admin',
    ACCOUNTING: 'accounting',
    TAX: 'tax',
    NAFN: 'nafnAdmin',
    ENGINEERING: 'engineering',
    ENGINEERING_I3: 'engineeringI3'
});

export const PermissionTypes = Object.freeze({
    CAN_VIEW: 'canView',
    CAN_EDIT: 'canEdit',
    CAN_FILTER: 'canFilter',
    CAN_VIEW_ALL: 'canViewAll',
    CAN_SIMULATE: 'canSimulate',
    CAN_VIEW_APTTUS_AGREEMENTS: 'canViewApttusAgreements',
    CAN_ADD_NEW: 'canAddNew',
    CAN_RENAME: 'canRename',
    CAN_RUN_PREVALIDATION: 'canRunPrevalidation',
    CAN_BULK_REVERSE: 'canBulkReverse'
});

export class RolePermissions {
    canView: boolean = false;
    canEdit: boolean = false;
    canFilter: boolean = false;
    canViewAll: boolean = false;
    canSimulate: boolean = false;
    canViewApttusAgreements: boolean = false;
    canAddNew: boolean = false;
    canRename: boolean = false;
    canRunPrevalidation: boolean = false;
    canBulkReverse: boolean = false;
}

export class PermissionMapping {
    role: string;
    module: string;
    permissions: string[];

    constructor(role: string, module: string, permissions: string[]) {
        this.role = role;
        this.module = module;
        this.permissions = permissions;
    }
}
