import React from 'react';
import { Link } from '@amzn/awsui-components-react/polaris';
import CONSTANTS from '../../../utils/constants';
import LinkedCLI from '../../../models/calculation-builder/LinkedCLI';

export default function LinkedCLILink(props:{linkedCLI:LinkedCLI}){
    const { linkedCLI } = props;
    return <Link external 
                externalIconAriaLabel='Opens in a new tab' 
                href={CONSTANTS.PAGE_NAV.CALCULATION_BUILDER.URL + '/' + window.btoa(linkedCLI.cliRecordId)}>
                    {linkedCLI.calculationNumber}
            </Link>
}