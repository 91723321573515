import React from 'react';
import 'src/assets/styles/react-table.scss';
import { useTable, useFilters, useSortBy, useRowSelect, useExpanded } from 'react-table';
import { Box, Icon, Popover, StatusIndicator } from '@amzn/awsui-components-react/polaris';
import CONSTANTS from "src/utils/constants";

type ComponentProps = {
    data: any[],
    columnDefinitions: any[],
    className: string,
    sortable?: boolean,
    showFilters?: boolean,
    showHeader?: boolean,
    highlightErrorCells?: boolean
    isSearching?: boolean,
    isSearchingMessage?: string,
    emptyMessage?: string,
    onSortBy?: (e: Array<any>) => any,
    onFilter?: (e: Array<any>) => any,
    onRowBlur?: (...args : any[]) => any,
    onRowClicked?: (...args : any[]) => any,
    onExpandedChanged?: (e: any) => void,
    editableOptions?: { defaultColumn: any, onRowUpdated: any, skipPageReset: boolean, editorsData?: any, onCellUpdated?: any },
    initialState?: any,
    allowMultipleRowSelection?: boolean,
    alternateBackgroundColor?: boolean,
    alternateCount?: number,
    highlightSelected?: boolean
};


const doNothing = () => { }

const IndeterminateCheckBox = React.forwardRef<{indeterminate: any}>(
    ({ ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;
      const indeterminate = (rest as any).indeterminate;
      (rest as any).ref = resolvedRef;
      React.useEffect(() => {
        (resolvedRef as any).current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
      
      return (
        <div className="cell-text checkbox-container"><input type="checkbox" {...rest} /></div>
      );
    }
  );

export function TPERowSelectableReactTable(props: ComponentProps) {
    const {
        className, data, columnDefinitions, sortable = true, showFilters = false, showHeader = true, highlightErrorCells = false, isSearching = false, 
        onSortBy = doNothing, onFilter = doNothing, emptyMessage = "No results", editableOptions, isSearchingMessage = "Searching...",
        onRowBlur, onRowClicked = doNothing, onExpandedChanged = doNothing, allowMultipleRowSelection = false,
        alternateBackgroundColor = false, alternateCount = 1, highlightSelected = true,
    } = props;
    
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns: columnDefinitions,
        data,
        //initialState: { selectedRowIds },
        manualFilters: true,
        disableMultiSort: true,
        autoResetSelectedRows: false,
        defaultColumn: editableOptions?.defaultColumn ? editableOptions.defaultColumn : {},
        onRowUpdated: editableOptions?.onRowUpdated ? editableOptions.onRowUpdated : doNothing,
        onCellUpdated: editableOptions?.onCellUpdated ? editableOptions.onCellUpdated : doNothing,
        autoResetPage: editableOptions?.skipPageReset != null ? !editableOptions.skipPageReset : true,
        editorsData: editableOptions ? editableOptions.editorsData : null
    } as any, useFilters, useSortBy, useExpanded, 
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: () => <div />,
            // The cell can use the individual row's getToggleRowSelectedProps method
            sortable: false,
            Cell: ({ row } : { row: any }) => {
              if (allowMultipleRowSelection){
                return (
                  <div>
                    <IndeterminateCheckBox
                      onClick={(e: any) => onRowClicked(e.target.checked, row.original, row.index) }
                      {...row.getToggleRowSelectedProps()} />
                  </div>
                );
              }
              // If there is no other checkbox selected
              if (
                rows.filter((r:any) => r.isSelected).length < 1 || row.isSelected
              ) {
                return (
                  <div>
                    <IndeterminateCheckBox
                      onClick={(e: any) => onRowClicked(e.target.checked, row.original, row.index) }
                      {...row.getToggleRowSelectedProps()} />
                  </div>
                );
              } else {
                return (
                  <div>
                    <IndeterminateCheckBox  />
                  </div>
                );
              }
            }
          },
          ...columns
        ]);
      }
    );

    
    const getCellClass = (row: any, cell: any) => {
        return highlightErrorCells && row.original.errors != null && (row.original.errors as Map<string, string[]>).has(cell.column.id) ? 
            "errorCell" 
            : row.original.editableFields != null && (row.original.editableFields as string[]).includes(cell.column.id) ?
                "editableTd"
                : "" ;
    }

    const getRowClass = (index: number) => {
      if (alternateBackgroundColor) {
        const oneBasedIndex = index + 1;
        const toAlternate = alternateCount * 2;
        
        for (var i = 0; i < alternateCount; i++) {
          if ((oneBasedIndex + i) % toAlternate == 0) {
            return 'greyRow';
          }
        }

        return '';
      }
    }

    
    // Render the UI for your table
    return (
        <div>
            <table className={className+CONSTANTS.ATP_CUSTOM_REACT_TABLE_CSS} {...getTableProps()}>
                {showHeader &&
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th {...column.getHeaderProps()} className={column.headerClass}>
                                    <div
                                        className="sorting-arrows"
                                        {...column.getSortByToggleProps()} >
                                        {column.render('Header')}
                                        {sortable &&
                                            <div>
                                                {column.isSorted ? column.isSortedDesc ?
                                                    <Icon name="caret-down-filled" /> :
                                                    <Icon name="caret-up-filled" /> :
                                                    <Icon name="caret-down" />}
                                            </div>
                                        }
                                    </div>

                                    {showFilters ? (<div className="cell-filter">{column.canFilter ? <Popover
                                        position="bottom"
                                        size="medium"
                                        triggerType="custom"
                                        dismissButton={false}
                                        content={
                                            <StatusIndicator type="warning">
                                                Search is case sensitive
                                            </StatusIndicator>
                                        }
                                    >{column.render('Filter')}</Popover> : null}</div>) : null}
                                </th>

                            ))}
                        </tr>

                    ))}
                </thead>
                }
                {isSearching || rows == null || rows.length == 0 ?
                    null :
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row:any, i) => {
                            prepareRow(row);

                            return (
                                <tr className={row.isSelected && highlightSelected ? 'selected-row' : getRowClass(i)} {...row.getRowProps()}>
                                    {
                                        ((row.original as any).colspan) ?
                                            <td className="colspanRow" colSpan={row.cells.length} {...row.cells[0].getCellProps()}>{(row.original as any).content}</td>
                                        :
                                        row.cells.map((cell:any) => {
                                            return <td {...cell.getCellProps()} className={getCellClass(row, cell)}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                }

            </table>
            {isSearching ?
                <Box textAlign="center" padding="l"><StatusIndicator type="loading">{isSearchingMessage}</StatusIndicator></Box> : null}
            {!isSearching && (rows == null || rows.length == 0) ?
                <Box textAlign="center" padding="l" className="tpeTableEmptyMessage"><StatusIndicator type="info">{emptyMessage}</StatusIndicator></Box> : null}
        </div>
    )
}