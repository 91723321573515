import React, { useState } from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";


export type AutoSuggesstListItem = {
    name: string,
    value: string,
    type: string, 
    hasHeader?:boolean,
}

export const AUTOSUGGEST_TRIGGER = '@'

export default function ATPTextAreaAutoComplete (props: {
    key?: any;
    editorsData: {
        suggestionsList:AutoSuggesstListItem[],
        onTextAreaCaretPositionChanged: (e:any) => void},
    initialValue: any,
    onTextAreaBlur: (e:any) => void,
}) {

    const {editorsData, initialValue, onTextAreaBlur} = props;

    const [textAreaValue, setTextAreaValue] = useState(`${initialValue}`);
    
    const onCaretPositionChange = (e: any) => {
        editorsData.onTextAreaCaretPositionChanged(e);
    }

    const onTextAreaChanged = function(e:any){
        setTextAreaValue(e.target.value)
    }

    const onInternalTextAreaBlur = (e: any) => {
        // Not triggering the on blur event if the text area content has the @ symbol as last character instead of anywhere
        if (textAreaValue.trim().length > 0 && textAreaValue.trim().indexOf(AUTOSUGGEST_TRIGGER) >= textAreaValue.trim().length - 1){
            return;
        }
        onTextAreaBlur({e,textAreaValue});
    }

    const AutoCompleteItem = ({ entity: { name, type, hasHeader } }: {entity: AutoSuggesstListItem}) => (
        <React.Fragment>
          {hasHeader ? (
            <div className="dropdown-item-header">{`${type}`}</div>
          ) : null}
          <div className="dropdown-item">{`${name}`}</div>
        </React.Fragment>
    );
    
    const suggestionsList:AutoSuggesstListItem[] = editorsData.suggestionsList;
    const triggerObject: any = {};
    triggerObject[AUTOSUGGEST_TRIGGER] = {
        dataProvider: (token:string) => {
            const result = suggestionsList.filter(
                (x) => x.name.toLowerCase().indexOf(token.toLowerCase()) >= 0
            );
            let previousType = "";
            return result.map((x) => {
                if (x.type !== previousType) {
                    x.hasHeader = true;
                    previousType = x.type;
                }
                return {
                    name: x.name,
                    type: x.type,
                    hasHeader: x.hasHeader,
                    output: `[${x.value}]`
                };
            });
        },
        component: AutoCompleteItem as any,
        output: (item: any, trigger: any) => (item as any).output
    };

    return <ReactTextareaAutocomplete key={props.key}
                className="formulaEditorTextarea" value={textAreaValue} 
                onChange={onTextAreaChanged}
                onCaretPositionChange={onCaretPositionChange}
                onBlur={onInternalTextAreaBlur}
                loadingComponent={() => <span>Loading</span>}
                minChar={0}
                containerStyle={{
                    marginTop: 20,
                    width: "100%",
                    height: 60,
                    margin: "0 auto"
                }}
                trigger={triggerObject}
            />;
}