import { useEffect, useState } from 'react';
import ErrorUtils from 'src/utils/ErrorUtils';

/**
 * This class should only contain the API calls used in the dashboard
 */
export default class DashboardService {

    /**
     * This function shows how to call a remote API asynchronously and 
     * handle error message and loading flag using local state
     * @param query An arbitrary string
     */
    loadDataWithLocalState(query: string) {
        const [results, setResults] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await fetch(
                        `http://jsonplaceholder.typicode.com/posts`
                    );
                    const json = await response.json();
                    setResults(json);
                }
                catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                }
                finally {
                    setLoading(false);
                }

            }

            if (query !== '') {
                fetchData();
            }
        }, [query]);
        return [results, loading, error];
    }
    /**
     * This function delegates error handling and loading 
     * state to the invoker by returning a promise
     */
    loadData() {
        return fetch(
            `https://jsonplaceholder.typicode.com/posts`
        );
    }
}