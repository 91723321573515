import {useState} from "react";
import ErrorUtils from "src/utils/ErrorUtils";
import * as XLSX from 'xlsx';
import {JEStatusDashboardTableColumns} from "src/components/reports/jeStatusDashboard/Constants";
import StringUtils from "src/utils/stringUtils";
import {CLIExecutionRecord} from "src/models/reports/GetCLIExecutionRecords";
import {downloadReport} from "src/components/reports/downloadReports/Utils";

function prepareJEPostingStatusDataForExcel(data: CLIExecutionRecord[]): any[] {
    return data.map((row: { [key: string]: any }) => {
        const formattedRow: { [key: string]: any } = {};
        JEStatusDashboardTableColumns.forEach(column => {
            const camelCaseColumn = StringUtils.convertCamelCaseToTitleCase(column);
            formattedRow[camelCaseColumn] = row[column];
        });
        return formattedRow;
    });
}

export function useJEPostingDashboardExcelBasedDownload(sheetName: string, fileName: string) {
    const [downloadResult, setDownloadResult] = useState<string>('');
    const [downloadError, setDownloadError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const downloadJEPostingStatusReportExcel = (jePostingStatusRecordsToDownload: any[]) => {
        setLoading(true);
        setDownloadResult('');
        setDownloadError('');

        try {
            if (jePostingStatusRecordsToDownload.length > 0) {
                const formattedJEPostingStatusData = prepareJEPostingStatusDataForExcel(jePostingStatusRecordsToDownload);
                const workSheet = XLSX.utils.json_to_sheet(formattedJEPostingStatusData);
                const workBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
                const wbOut = XLSX.write(workBook, { bookType: 'xlsx', type: 'base64' });
                downloadReport(fileName, wbOut);
                setDownloadResult('Success');
            } else {
                setDownloadResult('No records to download.');
            }
        } catch (error) {
            setDownloadError(ErrorUtils.getMessage(error));
        } finally {
            setLoading(false);
        }
    };

    return [downloadResult, loading, downloadError, downloadJEPostingStatusReportExcel] as const;
}
