import React, { useMemo } from 'react';
import { Box, Modal, Button, Link } from '@amzn/awsui-components-react/polaris';
import CONSTANTS from 'src/utils/constants';
import { TPEReactTable } from 'src/components/shared/TPEReactTable';
import { DataSourceRecord } from 'src/models/common/DataSourceRecord';


export default function DataKeyModal (props: {
        visible: boolean,
        onClose: () => void,
        onEditRecord?: (datasourceId: string, recordBeingEdited?: DataSourceRecord) => void,
        dataSourcesAreEditable: boolean,
        dataSourceRecords: DataSourceRecord[], 
        recordBeingEdited?: DataSourceRecord,
        showGLOnly?: boolean
    }) {
    const { visible, onClose, dataSourcesAreEditable, dataSourceRecords, recordBeingEdited, onEditRecord, showGLOnly = false } = props;

    const getColumnDefinitions = () => {
        const colDefs: any[] = [
            {
                accessor: "rowNumber",
                Header: (e: any) => <div className="cell-text-non-sortable">#</div>,
                Cell: (e: any) => <div className="cell-text-wrap">{e.value}</div>
            },
            {
                accessor: "description",
                Header: (e: any) => <div className="cell-text-non-sortable">Description</div>,
                Cell: (e: any) => <div className="cell-text-wrap">{e.value}</div>
                
            },
        ];
        Object.values(CONSTANTS.COA_SEGMENT_MAPPING).forEach((x) => {
            colDefs.push({
                accessor: x.UI.replace(' ', '_'),
                Header: (e: any) => <div className="cell-text-non-sortable">{x.UI}</div>,
                Cell: (e: any) => <div className="cell-text-wrap">{e.value}</div>
            });
        });
        if (!showGLOnly){
            colDefs.push({
                accessor: 'cdt',
                Header: (e: any) => <div className="cell-text-non-sortable">Custom data table</div>,
                Cell: (e: any) => <div className="cell-text-wrap cdtValueCell">{e.value}</div>
            });

            colDefs.push({
                accessor: 'tpAlloc',
                Header: (e: any) => <div className="cell-text-non-sortable">TP allocation worksheet</div>,
                Cell: (e: any) => <div className="cell-text-wrap cdtValueCell">{e.value}</div>
            });
        }

        if (dataSourcesAreEditable) {
            colDefs.push({
                accessor: "action",
                Header: (e: any) => <div className="cell-text-non-sortable">Action</div>,
                Cell: (e: any) => <Link className="dataKeyModalEditLink cell-link" onFollow={() => editDataSource(e.row.original)}>Edit</Link>
            })
        }
        return colDefs;
    }

    const editDataSource = (row: any) => {
        onClose();
        if ( onEditRecord ) onEditRecord(row.datasourceId, recordBeingEdited);
        //performRowAction(dispatch, recordBeingEdited, row.datasourceId, String(RowActions.EDIT));
    }

    const getColumns = () => {
        const records: any[] = [];
        dataSourceRecords.forEach((x, index) => {
            let record = { rowNumber: index + 1, description: x.description, datasourceId: x.datasourceId, cdt: <span></span>, tpAlloc: <span></span> };
            Object.values(CONSTANTS.COA_SEGMENT_MAPPING).forEach((y) => 
                (record as any)[y.UI.replace(' ', '_')] = x.dataKeyInput?.selectedCOA.has(y.UI) ? x.dataKeyInput?.selectedCOA.get(y.UI) : ''
            );
            (record as any)[CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.UI.replace(' ', '_')] = x.dataKeyInput?.selectedCompanies;
            (record as any)[CONSTANTS.COA_SEGMENT_MAPPING.GL_ACCOUNT.UI.replace(' ', '_')] = x.dataKeyInput?.selectedAccounts;
            if (x.datasource === CONSTANTS.DATA_SOURCE_TYPES.CUSTOM_DATA_TABLE){
                record.cdt = <>
                    <div><strong>Table: </strong>{x.customDatasourceTableName}</div>
                    <div><strong>Calculation number: </strong>{x.customDatasourceCalculationNumber}</div>
                    <div><strong>Value: </strong>{x.customDatasourceValueLegend}</div>
                </>;
            }
            if (x.datasource === CONSTANTS.DATA_SOURCE_TYPES.TP_ALLOCATION_WORKSHEET){
                record.tpAlloc = <>
                    <div><strong>TP allocation worksheet: </strong>{x.tpAllocationWorksheetName}</div>
                    <div><strong>Allocation group: </strong>{x.tpAllocationGroupName}</div>
                    <div><strong>Formula mapping: </strong>{x.tpAllocationMappingType == CONSTANTS.TP_ALLOCATION_MAPPING_TYPE.RECIPIENT ? 'By recipient company' : 'Manual'}</div>
                    <div><strong>Formula name: </strong>{x.tpAllocationFormulaName}</div>
                </>;
            }
            records.push(record);
        });
        return records;
    }

    const columnDefinitions = useMemo(getColumnDefinitions, [dataSourceRecords, dataSourcesAreEditable, recordBeingEdited]);
    const data = useMemo(getColumns, [dataSourceRecords, dataSourcesAreEditable, recordBeingEdited]);

    return <Modal className="dataKeyModal" visible={visible} size="max"
        header="Data Key Overview"
        onDismiss={onClose}
        footer={
            <Box float="right">
                    <Button variant="primary" className="dataKeyOKButton" onClick={onClose}>OK</Button>
            </Box>
        }>
            <TPEReactTable {...{
                data,
                columnDefinitions,
                className: (dataSourcesAreEditable ? "dataKeyOverviewWithEdit" : "dataKeyOverview") + (showGLOnly? " glOnly" : ""),
                sortable: false,
            }} />
    </Modal>
}