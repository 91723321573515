import {ListReportsResponse} from "src/models/reports/ListReportResponse";
import {useEffect, useState} from "react";
import apiService from "src/services/ApiCallService";
import ErrorUtils from "src/utils/ErrorUtils";
import {DownloadReportRequest, DownloadReportResponse} from "src/models/reports/DownloadReport";
import {GetReportingPeriodsResponse} from "src/models/reports/GetReportingPeriodListResult";
import {GenerateReportRequest, GenerateReportResponse} from "src/models/reports/GenerateReport";
import {TaxAuditRecordsRequest, TaxAuditRecordsResponse} from "src/models/reports/TaxAuditRecords";
import {TPTaxVarianceRecord, TPTaxVarianceRecordsRequest, TPTaxVarianceRecordsResponse} from "src/models/reports/TPTaxVarianceRecords";
import { TPReportsSummaryRequest,TPReportsSummaryResponse} from "src/models/reports/TPReportsSummary";
import {TaxAuditRecordDetailsResponse} from "src/models/reports/TaxAuditRecordDetails";
import {DownloadTaxAuditRecordRequest, DownloadTaxAuditRecordResponse} from "src/models/reports/DownloadTaxAuditRecord";
import {
    GetCLIsByProviderCompanyCodeRequest,
    GetCLIsResponse
} from "src/models/reports/TPTaxReport";
import {FetchReportRequest, FetchReportResponse} from "src/models/reports/FetchReport";
import {
    DownloadIndirectTaxChangeRecordsRequest,
    DownloadIndirectTaxChangeRecordsResponse
} from "src/models/reports/DownloadIndirectTaxChangeRecords";
import {
    FetchIndirectTaxChangeRecordsRequest,
    FetchIndirectTaxChangeRecordsResponse
} from "src/models/reports/FetchIndirectTaxChangeRecords";
import {
    GetCLIExecutionRecordsRequest,
    GetCLIExecutionRecordsResponse
} from "src/models/reports/GetCLIExecutionRecords";
import {
    DownloadCLIExecutionRecordsRequest,
    DownloadCLIExecutionRecordsResponse
} from "src/models/reports/DownloadCLIExecutionRecords";
import { COADataRecord } from "src/models/reports/GetCOAData";

export default class ReportsService {

    fetchReportHistory(username?: string, refresh?:boolean): [ListReportsResponse, boolean, string] {
        const [results, setResults] = useState(null as unknown as ListReportsResponse);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.listReports(username);
                    const json = await response.json() as ListReportsResponse;
                    setResults(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (refresh){
                fetchData();
            }
        }, [refresh]);
        return [results, loading, error];
    }

    downloadReport(payload?: DownloadReportRequest): [string, boolean, string] {
        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.downloadReport(payload);
                    const json = await response.json() as DownloadReportResponse;
                    setResult(json.reportURL)
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!!payload) {
                fetchData();
            }
        }, [payload]);
        return [result, loading, error];
    }

    getReportingPeriods(): [GetReportingPeriodsResponse, boolean, string] {
        const [results, setResults] = useState(null as unknown as GetReportingPeriodsResponse);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getReportingPeriods();
                    const json = await response.json() as GetReportingPeriodsResponse;
                    setResults(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            fetchData();
        }, []);
        return [results, loading, error];
    }

    generateReport(payload?: GenerateReportRequest | null): [GenerateReportResponse, boolean, string] {
        const [results, setResults] = useState(null as unknown as GenerateReportResponse);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.generateReport(payload);
                    const json = await response.json() as GenerateReportResponse;
                    setResults(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!!payload){
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    searchTaxAuditRecords(payload?: TaxAuditRecordsRequest): [TaxAuditRecordsResponse, boolean, string] {
        const [results, setResults] = useState(null as unknown as TaxAuditRecordsResponse);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.searchTaxAuditRecords(payload);
                    const json = await response.json() as TaxAuditRecordsResponse;
                    setResults(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!!payload) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    getCLIExecutionRecords(payload?: GetCLIExecutionRecordsRequest): [GetCLIExecutionRecordsResponse, boolean, string] {
        const [results, setResults] = useState(null as unknown as GetCLIExecutionRecordsResponse);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCLIExecutionRecords(payload);
                    const json = await response.json() as GetCLIExecutionRecordsResponse;
                    setResults(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!!payload) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    downloadCLIExecutionRecords(payload: DownloadCLIExecutionRecordsRequest): [string, boolean, string] {
        const [results, setResults] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.downloadCLIExecutionRecords(payload.periodId);
                    const json = await response.json() as DownloadCLIExecutionRecordsResponse;
                    setResults(json.reportURL);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!!payload && !!payload.periodId) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    getTaxAuditRecordDetails(taxJournalHeaderId: string): [TaxAuditRecordDetailsResponse, boolean, string] {
        const [results, setResults] = useState(null as unknown as TaxAuditRecordDetailsResponse);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getTaxAuditRecordDetails(taxJournalHeaderId);
                    const json = await response.json() as TaxAuditRecordDetailsResponse;
                    setResults(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!!taxJournalHeaderId) {
                fetchData();
            }
        }, [taxJournalHeaderId]);
        return [results, loading, error];
    }

    downloadTaxAuditRecord(payload?: DownloadTaxAuditRecordRequest): [string, boolean, string] {
        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.downloadTaxAuditRecord(payload);
                    const json = await response.json() as DownloadTaxAuditRecordResponse;
                    setResult(json.reportURL);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }

            if (!!payload) {
                fetchData();
            }
        }, [payload]);
        return [result, loading, error];
    }

    getCLIsByProviderCompanyCode(payload: GetCLIsByProviderCompanyCodeRequest | null) : [GetCLIsResponse, boolean, string] {
        const [result, setResult] = useState(null as unknown as GetCLIsResponse);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async  function fetchData(providerCompanyCode: string, calculationStatus: string) {
                try{
                    setLoading(true);
                    const response = await  apiService.getCLIsByProviderCompanyCode(providerCompanyCode,
                        calculationStatus)
                    const json = await response.json() as GetCLIsResponse;
                    setResult(json)
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }

            if (!!payload) {
                fetchData(payload.providerCompanyCode, payload.calculationStatus);
            }
        }, [payload]);
        return [result, loading, error];
    }

    fetchReportData(payload: FetchReportRequest | null) : [FetchReportResponse, boolean, string]{
        const [result, setResult] = useState(null as unknown as FetchReportResponse);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async  function fetchData() {
                try{
                    setLoading(true);
                    const response = await  apiService.fetchReportData(payload)
                    const json = await response.json() as FetchReportResponse;
                    setResult(json)
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }

            if (!!payload) {
                fetchData();
            }
        }, [payload]);
        return [result, loading, error];
    }

    fetchIndirectTaxChangeRecords(payload?: FetchIndirectTaxChangeRecordsRequest): [FetchIndirectTaxChangeRecordsResponse, boolean, string] {
        const [results, setResults] = useState(null as unknown as FetchIndirectTaxChangeRecordsResponse);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');


        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.fetchIndirectTaxChangeRecords(payload);
                    const json = await response.json() as FetchIndirectTaxChangeRecordsResponse;
                    setResults(json);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }

            if (!!payload) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    fetchCompleteTPTaxVarianceRecords(payload?: TPTaxVarianceRecordsRequest): [TPTaxVarianceRecord[], boolean, string] {
        const [results, setResults] = useState<TPTaxVarianceRecord[]>([]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    let allRecords: TPTaxVarianceRecord[] = [];
                    let lastEvaluatedKey: string | null = null;
                    do {
                        const response = await apiService.fetchTPTaxVarianceRecords(payload);
                        const json = await response.json() as TPTaxVarianceRecordsResponse;
                        lastEvaluatedKey = json.lastEvaluatedKey
                        if(lastEvaluatedKey!=null && payload != undefined){
                            payload.startKey = lastEvaluatedKey;
                        }
                        allRecords = [...allRecords, ...json.records];
                    } while(lastEvaluatedKey!=null)
                    
                    setResults(allRecords);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }

            if (!!payload) {
                fetchData();
            }
        }, [payload]);
        return [results, loading, error];
    }

    fetchCompleteTPReportsSummary(payload?: TPReportsSummaryRequest): [TPReportsSummaryResponse, boolean, string]{
     const [loading, setLoading] = useState(false);
     const [error, setError] = useState('');
     const [results, setResults] = useState(null as unknown as TPReportsSummaryResponse);

     useEffect(() => {
             async function fetchData() {
                 try {
                     setLoading(true);
                     const response = await apiService.fetchTPReportsSummary(payload);
                     const json = await response.json() as TPReportsSummaryResponse;
                     setResults(json)
                 } catch (ex) {
                     setError(ErrorUtils.getMessage(ex));
                 } finally {
                     setLoading(false);
                 }
             }
             if (!!payload) {
                 fetchData();
             }
         }, [payload]);
     return [results,loading,error];
    }

    downloadIndirectTaxChangeRecords(payload: DownloadIndirectTaxChangeRecordsRequest): [string, boolean, string] {
        const [result, setResult] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');

        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.downloadIndirectTaxChangeRecords(payload.reportId);
                    const json = await response.json() as DownloadIndirectTaxChangeRecordsResponse;
                    setResult(json.reportURL)
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if (!!payload && !!payload.reportId) {
                fetchData();
            }
        }, [payload]);
        return [result, loading, error];
    }

    getCOADataBySegment(segment:string): [COADataRecord[], boolean, string] {
        const [result, setResult] = useState([] as COADataRecord[]);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState('');


        useEffect(() => {
            async function fetchData() {
                try {
                    setLoading(true);
                    const response = await apiService.getCOADataBySegment(segment);
                    const json = await response.json();
                    setResult(json.coaDataList);
                } catch (ex) {
                    setError(ErrorUtils.getMessage(ex));
                } finally {
                    setLoading(false);
                }
            }
            if(segment!= ''){
                fetchData()
            }
        }, [segment]);

        return [result, loading, error];
    }
}