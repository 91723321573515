import { Button, Container, Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import KeyValuePair from 'src/models/common/KeyValuePair';
import CancelEditing from './CancelEditing';
import { DataSourcesContext } from './DataSourcesContainer';
import { ACTIONS } from 'src/services/calculation-builder/DataSourcesReducer';
import { ACTIONS as CALC_BUILDER_ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import StringUtils from 'src/utils/stringUtils';
import { CalculationBuilderContext } from '../CalculationBuilderView';

export default function DataKeySummaryView(props: {header: string, summaryItems: KeyValuePair[], showEdit: boolean, onEdit: () => any}) {
    const { header, summaryItems, showEdit, onEdit } = props;

    const { calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const { state, services, dispatch } = React.useContext(DataSourcesContext);
    const { customCoaReference = new Map<string, string>(), recordBeingEdited, recordBeingEditedOriginal, dataSourceRecords } = state;

    const [recordNeedsSaving, setRecordNeedsSaving] = useState(undefined as unknown as string);

    const [saveDataSourceRecordResponse, isSaving, savingError] = services.dataSourcesService.saveDataSource(customCoaReference, false, recordBeingEdited, recordNeedsSaving);

    const saveRecord = () => {
        // Save from this component means only the data source description might have changed
        const errorMap = services.dataSourcesService.validateDataSourceDescription(recordBeingEdited?.description, dataSourceRecords.map(x => x.description));

        if (errorMap.size > 0) {
            dispatch(ACTIONS.SET_ERRORS.withPayload({ recordID: recordBeingEdited?.datasourceId, value: errorMap }));
        } else {
            dispatch(ACTIONS.CLEAR_ERRORS.withPayload({ recordID: recordBeingEdited?.datasourceId }));
            setRecordNeedsSaving(new Date().toISOString());
        }
    }

    useEffect(() => {
        if ( saveDataSourceRecordResponse == null ){
            return;
        }
        if (recordBeingEditedOriginal?.description != recordBeingEdited?.description) {
            calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_REFRESH_CALC_STEPS_FLAG.withPayload(true));
        }
        dispatch(ACTIONS.SAVE_RECORD.withPayload({ recordID: recordBeingEdited?.datasourceId, updatedRecord: saveDataSourceRecordResponse }));
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [saveDataSourceRecordResponse])

    useEffect(() => {
        if (StringUtils.isNullOrEmpty(savingError)) {
            return;
        }
        dispatch(ACTIONS.SET_SAVE_ERROR.withPayload(savingError));
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
    }, [savingError])

    return (
        <Container data-class="dataKeyContentContainer">
            <div className="dataKeyTableHeader">
                <Header variant="h3">
                    <span className="datKeyTableHeaderText">{header}</span>
                </Header>
            </div>
            <div>
                <div className="dataKeySummaryContainer">
                    <div></div>
                    <div>
                        <div className="dataKeySummarySection">
                            {summaryItems.map(x => 
                                <React.Fragment>
                                    <div className="dataKeySummaryKey">{x.key}</div>
                                    <div className="dataKeySummaryValue">{x.value}</div>
                                </React.Fragment>
                            )}
                        </div>
                        {showEdit && <div className="editLinkBox">
                            <Link onFollow={onEdit}>Edit</Link>
                        </div> }
                    </div>
                    <div></div>
                </div>
                {showEdit && <div className="saveAndCancelDataKeySummary">
                    <SpaceBetween size="xs" direction="horizontal">
                        <CancelEditing onCancelPostProcess={() => dispatch(ACTIONS.CANCEL_EDITING)} />
                        <Button className="smallPrimaryButton saveDataSourceBtn" loading={isSaving} onClick={saveRecord}>{isSaving? "Saving..." : "Save data source"}</Button>
                    </SpaceBetween>
                </div>}
            </div>
        </Container>
    )
}