import { Alert, Box, Button, ExpandableSection, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { TPELoadingSpinner } from 'src/components/shared/TPELoadingSpinner';
import TPEAction from 'src/models/common/TPEAction';
import { ACTIONS, jeSettingsReducer } from 'src/services/calculation-builder/JESettingsReducer';
import { initialState, JESettingsState } from 'src/services/calculation-builder/JESettingsState';
import { ACTIONS as CALC_BUILDER_ACTIONS } from 'src/services/calculation-builder/CalculationBuilderReducer';
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import ArrayUtils from 'src/utils/arrayUtils';
import { CalculationBuilderContext } from '../CalculationBuilderView';
import JournalEntriesGrid from './JournalEntriesGrid';
import { ACTION_TYPE, TPEBasicModal } from 'src/components/shared/TPEBasicModal';
import CONSTANTS from 'src/utils/constants';
import JE7SegmentsValidator from './JE7SegmentsValidator';
import StringUtils from 'src/utils/stringUtils';
import { CalculationBuilderState } from 'src/services/calculation-builder/CalculationBuilderState';
import { DryRunResponse } from 'src/models/calculation-builder/DryRunResponse';
import { CalculationSourceType } from 'src/models/common/CalculationSource';
import WebSocketApi from 'src/services/WebSocketApi';

export type ContextType = {
    state: JESettingsState,
    dispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    calculationSource: CalculationSourceType
}

const JESettingsProvider = (props: any) => {
    const { state, dispatch, services, children, calculationSource } = props;
    const providerValue = React.useMemo(() => ({
        state, dispatch, services, calculationSource
    }), [state, dispatch]);
    return (
        <JESettingsContext.Provider value={providerValue}>
            {children}
        </JESettingsContext.Provider>
    );
}
export const JESettingsContext = React.createContext(null as unknown as ContextType);

export default function JESettingsContainer(props: { expanded?: boolean, onCancel: () => void, onSave: (e: any) => void }) {
    const { expanded = false, onCancel, onSave } = props;
    const { services, calcBuilderState, calcBuilderDispatch } = React.useContext(CalculationBuilderContext);
    const [ state, dispatch ] = useReducerWithLogger(jeSettingsReducer, initialState);
    const { calculation, 
            selectedCalculationVersion, 
            journalsNeedRefresh, 
            executeCLIPayload, 
            generatingReviewJournalsStatus, 
            generateReviewJournalsPayload, 
            viewMode, 
            calculationSource,
            calculationExecution
    } = calcBuilderState;
    const { journals,
            updateJournalsPayload,
            journalsAreValid,
            journalsAreValidInIPE,
            taxJournalsAvailable,
            workflowAction,
            lastModifiedUser,
            lastModifiedDate 
    } = state;

    const [ getJournalsFlag, setGetJournalsFlag ] = useState(true);
    const [ showInfoAlert, setShowInfoAlert ] = useState(true);
    const [ showCancelConfirmModal, setShowCancelConfirmModal ] = useState(false);
    const [ showChargeUnavailableModal, setShowChargeUnavailableModal ] = useState(false);
    const [ configurationJournalsAreEditable, setConfigurationJournalsAreEditable ] = useState(true);
    
    const [journalsResult, journalsLoading, journalsError] = services.journalsService.getJournals(getJournalsFlag, CONSTANTS.JOURNAL_LINE_TYPES.CONFIGURATION, calculation?.calculationNumber, calculationExecution?.calculationVersion == null ? selectedCalculationVersion : calculationExecution?.calculationVersion);
    const [updateJournalsResult, isUpdating, updateJournalsError] = services.journalsService.updateJournals(updateJournalsPayload, calculation?.calculationNumber);

    useEffect(() => {
        const webSocketListener = setupWebSocketEvents(services, calcBuilderDispatch, calcBuilderState, dispatch);
        return () => {
            // Anything in here is fired on component unmount.
            services.atpWebSocketApiService.removeListener('',webSocketListener);
        }
    }, [])

    useEffect(() => {
        const editable = viewMode != CONSTANTS.VIEW_MODE.READ_ONLY && viewMode != CONSTANTS.VIEW_MODE.FROZEN;
        setConfigurationJournalsAreEditable(editable);
    }, [viewMode])

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(journalsResult)) {
            return;
        }
        // Don't display entered dr/cr in configuration journals when viewing execution results
        if (calculationExecution?.calculationExecutionId != null) {
            journalsResult.forEach(j => {
                j.journalLines.forEach(l => {
                    (l as any)[CONSTANTS.JE_FIELDS.ENTERED_CR.ACCESSOR] = '';
                    (l as any)[CONSTANTS.JE_FIELDS.ENTERED_DR.ACCESSOR] = '';
                })
            })
        }
        dispatch(ACTIONS.SET_JOURNALS.withPayload(journalsResult));
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_REFRESH_JOURNALS_FLAG.withPayload(false));
        setGetJournalsFlag(false);
    }, [journalsResult])

    useEffect(() => {
        if (journalsNeedRefresh) {
            setGetJournalsFlag(true);
        }
    }, [journalsNeedRefresh])

    useEffect(() => {
        if (ArrayUtils.isNullOrEmpty(updateJournalsResult)) {
            return;
        }
        dispatch(ACTIONS.SET_JOURNALS.withPayload(updateJournalsResult));
        services.messageService.showSuccessAutoDismissBanner('JE settings successfully saved.', 10000);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
        generateReviewJournalsAfterValidationAndSave();
    }, [updateJournalsResult])

    useEffect(() => {
        if (journalsError != null && journalsError != '') {
            services.messageService.showErrorBanner(`Failed to get journals. ${journalsError}`);
        }
    }, [journalsError])

    useEffect(() => {
        if (updateJournalsError != null && updateJournalsError != '') {
            calcBuilderDispatch(CALC_BUILDER_ACTIONS.CHECK_AND_REFRESH_CALCULATION_INFORMATION);
            services.messageService.showErrorBanner(`Failed to save JE settings. ${updateJournalsError}`);
        }
    }, [updateJournalsError])

    useEffect(() => {
        if (journalsAreValid) {
            if (workflowAction != null) {
                let saveNeeded = false;
                journals.forEach(j => saveNeeded = saveNeeded || j.journalLines.some(l => l.isDirty))
                
                if (saveNeeded) {
                    dispatch(ACTIONS.SAVE_JOURNALS);
                } else {
                    generateReviewJournalsAfterValidationAndSave();
                }
            }
        }
    }, [journalsAreValid])

    useEffect(() => {
        if (journalsAreValidInIPE) {
            switch(workflowAction){
                case ACTIONS.EXECUTE_CLI: {
                    const payload = {
                        agreementCLI: calculation?.calculationNumber,
                        calculationVersion: -1,
                    }
                    calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_EXECUTE_CLI_PAYLOAD.withPayload(payload));                    
                }
            }
        }
    }, [journalsAreValidInIPE])


    useEffect(() => {
        if (generateReviewJournalsPayload == null) {
            dispatch(ACTIONS.RESET_WORKFLOW);
        }
    }, [generateReviewJournalsPayload])

    useEffect(() => {
        if (executeCLIPayload == null) {
            return;
        }
        services.atpWebSocketApiService.executeCLI(executeCLIPayload);
        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_EXECUTE_CLI_PAYLOAD.withPayload(null));
    }, [executeCLIPayload])

    const updateJournals = () => {
        dispatch(ACTIONS.RESET_WORKFLOW);
        dispatch(ACTIONS.SAVE_JOURNALS);
    }

    const cancelChanges = () => {
        dispatch(ACTIONS.RESET_VALIDATION);
        setGetJournalsFlag(true);
        setShowCancelConfirmModal(false);
    }

    const executeCLI = () => {
        if (validateEnteredAmounts()) {
            dispatch(ACTIONS.TRIGGER_WORKFLOW.withPayload(ACTIONS.EXECUTE_CLI));
        }
    }

    const generateReviewJournal = () => {
        if (validateEnteredAmounts()) {
            dispatch(ACTIONS.TRIGGER_WORKFLOW.withPayload(ACTIONS.GENERATE_REVIEW_JOURNALS));
        }
    }

    const addIndirectTax = () => {
        if (validateEnteredAmounts()) {
            dispatch(ACTIONS.TRIGGER_WORKFLOW.withPayload(ACTIONS.ADD_INDIRECT_TAX));
        }
    }

    const validateEnteredAmounts = () => {
        let chargeUnavailable = false;
        journals.forEach(j => {
            if (j.journalType == CONSTANTS.JOURNAL_TYPES.TP_CHARGE || 
                j.journalType == CONSTANTS.JOURNAL_TYPES.TP_CHARGE_BASE ||
                j.journalType == CONSTANTS.JOURNAL_TYPES.TP_CHARGE_MARKUP) {
                
                j.journalLines.forEach(jl => {
                    if (StringUtils.isNullOrEmpty(jl.enteredCr) && StringUtils.isNullOrEmpty(jl.enteredDr)) {
                        chargeUnavailable = true;
                    }
                })
            }
        })
        
        if (chargeUnavailable) {
            setShowChargeUnavailableModal(true);
            return false;
        }

        return true;
    }

    function shouldGenerateJournalButtonBeLoading(workflowAction: TPEAction | undefined, generatingReviewJournalsStatus: string): boolean {
        return workflowAction == ACTIONS.GENERATE_REVIEW_JOURNALS && 
        (
            generatingReviewJournalsStatus === CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.STARTED || 
            generatingReviewJournalsStatus === CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.IN_PROGRESS
        )
    }

    function generateReviewJournalsAfterValidationAndSave() {
        if (workflowAction != null && workflowAction == ACTIONS.GENERATE_REVIEW_JOURNALS) {
            const payload = {
                calculationNumber: calculation?.calculationNumber,
            }
            services.atpWebSocketApiService.generateReviewJournals(payload);            
            calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_GENERATE_REVIEW_JOURNALS_PAYLOAD.withPayload(payload));
        }
    }

    return (
        <ExpandableSection className="polarisExpandableSection configureJESettingsSection" variant="container"
            defaultExpanded={expanded}
            header={
                <Header><h2>Step 4: Configure journal entries</h2></Header>
            }
        >
            <JESettingsProvider services={services} state={state} dispatch={dispatch} calculationSource={calculationSource}>
                    <TPELoadingSpinner loading={journalsLoading}>
                        <div className="calcBuilderContentContainer">
                            <SpaceBetween direction='vertical' size='m'>
                                {showInfoAlert &&
                                    <Alert type="info" dismissible onDismiss={() => {setShowInfoAlert(false)}} >
                                        {configurationJournalsAreEditable && 
                                            <React.Fragment>
                                                <span>Click the "Configure journal entries" button in step 3 to regenerate the journals.</span>
                                                <br />
                                            </React.Fragment>
                                        }
                                        <SpaceBetween direction="horizontal" size="xs">
                                            <div><b>Last updated by: </b><span>{lastModifiedUser}</span></div>
                                            <div><b>Last updated on: </b><span>{lastModifiedDate}</span></div>
                                        </SpaceBetween>
                                    </Alert>
                                }
                                <div className="journalEntriesContainer">
                                    <JournalEntriesGrid editable={configurationJournalsAreEditable} />
                                </div>
                            </SpaceBetween>
                            {configurationJournalsAreEditable && 
                                <div className="actionButtonsContainer">
                                    <JE7SegmentsValidator />
                                    
                                    <Box float="right">
                                        <SpaceBetween direction="horizontal" size="m">
                                            {/* TODO: Remove all code related to execute CLI and add indirect tax post MVP */}
                                            {/* <Button
                                                variant="primary"
                                                disabled={dryRunLoading || cliExecutionInProgress} 
                                                loading={(workflowAction == ACTIONS.EXECUTE_CLI && dryRunLoading) || cliExecutionInProgress}
                                                onClick={executeCLI}
                                            >
                                                Execute CLI
                                            </Button> */}
                                            <Button onClick={() => setShowCancelConfirmModal(true)} className="cancelJEChangesButton">Cancel</Button>
                                            <Button className="saveJEChangesButton" disabled={isUpdating} onClick={updateJournals}>{isUpdating? 'Saving...' : 'Save'}</Button>
                                            <Button
                                                variant="primary" 
                                                className="generateJournalButton" 
                                                loading={shouldGenerateJournalButtonBeLoading(workflowAction,generatingReviewJournalsStatus)}
                                                onClick={generateReviewJournal}>
                                                Generate journal
                                            </Button>
                                        </SpaceBetween>
                                    </Box>
                                </div>
                            }
                        </div>
                    </TPELoadingSpinner>
                <TPEBasicModal
                    className="cancelJESettingsChangesModal"
                    visible={showCancelConfirmModal}
                    action={ACTION_TYPE.YES_NO}
                    title="Cancel JE Settings Changes"
                    onConfirm={cancelChanges}
                    onCancel={() => setShowCancelConfirmModal(false)}
                >
                    This action will delete changes and revert the JE settings to the previous saved version. This action cannot be undone. Are you sure you want to proceed?
                </TPEBasicModal>
                <TPEBasicModal
                    className="chargeUnavailableModal"
                    visible={showChargeUnavailableModal}
                    action={ACTION_TYPE.OK_ONLY}
                    title="Missing TP charge"
                    onConfirm={() => setShowChargeUnavailableModal(false)}
                    onCancel={() => setShowChargeUnavailableModal(false)}
                >
                    Please click on "Configure journal entries" in Step 3 to populate the TP charge in the journals.
                </TPEBasicModal>

            </JESettingsProvider>
        </ExpandableSection>
    )
}

function setupWebSocketEvents(services: ServiceCollection, calcBuilderDispatch: React.Dispatch<TPEAction>, calcBuilderState: CalculationBuilderState, dispatch: React.Dispatch<TPEAction>) {
    services.atpWebSocketApiService.addListener((x:any) => {
        const message = JSON.parse(x);
        switch(message.messageType){
            case CONSTANTS.WEB_SOCKET_API_ROUTES.EXECUTE_CLI:
                calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_EXECUTE_CLI_TASK.withPayload(message));
            break;            
        }        
    });

    const listener = (x:any) => {
        const message = JSON.parse(x);
        switch(message.messageType){
            case CONSTANTS.WEB_SOCKET_API_ROUTES.GENERATE_REVIEW_JOURNALS:
                
                if (message.status === CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.FAILED){
                    const JE_SETTINGS_WIZARD_STEP = calcBuilderState.wizardSteps[3];
                    calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_SELECTED_WIZARD_STEP.withPayload(JE_SETTINGS_WIZARD_STEP));
                    calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_GENERATE_REVIEW_JOURNALS_PAYLOAD.withPayload(null));
                    calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_GENERATE_REVIEW_JOURNALS_STATUS.withPayload(CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.FAILED));
                    
                    try {
                        const dryRunResponse = JSON.parse(message.message) as DryRunResponse;
                        services.journalsService.convertJournalFieldsToDisplayFormat(dryRunResponse.journals);
                        services.journalsService.convertLineErrorsToMap(dryRunResponse.journals);
                        displayDryRunResult(dryRunResponse, dispatch);
                    }
                    catch(error) {
                        services.messageService.showErrorBanner(message.message);
                    }
                }
                else if (message.status === CONSTANTS.GENERATE_REVIEW_JOURNAL_STATUS.SUCCEEDED) {
                    if (calcBuilderState.executionPeriod == null){
                        const reviewJournals = JSON.parse(message.message).journals;
                        services.journalsService.convertJournalFieldsToDisplayFormat(reviewJournals);
                        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_REVIEW_JOURNALS.withPayload(reviewJournals));
                    }
                    else {
                        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_EXECUTION_PERIOD.withPayload(null));
                        calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_CALCULATION_EXECUTION.withPayload(null));
                    }
                }
            break;
        }        
    };

    const errorHandler = (x:any) => {
        setTimeout(() => {
            dispatch(ACTIONS.RESET_WORKFLOW);
            calcBuilderDispatch(CALC_BUILDER_ACTIONS.SET_GENERATE_REVIEW_JOURNALS_STATUS.withPayload('')), 500
        });
    }

    services.atpWebSocketApiService.addListener(listener);
    services.atpWebSocketApiService.addKeyListener(WebSocketApi.ConnectionEvent.ON_ERROR, errorHandler);
    return listener;
}

function displayDryRunResult(dryRunResult: DryRunResponse, dispatch: React.Dispatch<TPEAction>) {
    dispatch(ACTIONS.SET_JOURNALS.withPayload(dryRunResult.journals));
    dispatch(ACTIONS.SET_JOURNALS_ARE_VALID_IN_IPE.withPayload(dryRunResult.isValid));
    dispatch(ACTIONS.SET_VALIDATION_ERRORS.withPayload(dryRunResult.errorSummary));
}
