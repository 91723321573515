import React from "react"
import TpTaxVarianceReportHelpPanel from "./TpTaxVarianceReportHelpPanel"
import CONSTANTS from "src/utils/constants";

// Add the help panel for the pages in the Map
const headerBasedHelpPanelMap = new Map<string, React.ReactNode>();
headerBasedHelpPanelMap.set(CONSTANTS.PAGE_NAV.TP_TAX_VARIANCE_RECORD.HEADER_SIDEBAR_KEY, <TpTaxVarianceReportHelpPanel />);


export const getHeaderHelpPanelDetails = (pathname: string| undefined) => {
    if(pathname){
        return headerBasedHelpPanelMap.get(pathname) || null;
    } else {
        return null;
    }
    
}