import Placeholder from '../../../models/tp-allocation/Placeholder';

export interface PlaceholdersState {
    placeholdersMap: Map<string, Array<Placeholder>>,
    placeholderBeingEdited?: Placeholder,
    error: string,
    isLoading: boolean,
    isSaving: boolean,
    showValues: boolean,
    canAddNewPlaceholder: boolean,
    expandedState?: any,
    updatePlaceholderPayload?: any,
    placeholderSavedId?: string,
    maxPlaceholdersCount: number,
    alreadySelectedCOAs: Array<string>
}


export const initialState: PlaceholdersState = {
    placeholdersMap: new Map(),
    error: '',
    isLoading: true,
    isSaving: false,
    showValues: false,
    canAddNewPlaceholder: false,
    maxPlaceholdersCount: 0,
    alreadySelectedCOAs: []
};