import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { GLOBAL_ACTIONS } from '../../services/global/GlobalReducer';
import { MECState, initialState } from 'src/services/mec/MECState';
import { mecReducer, ACTIONS } from 'src/services/mec/MECReducer';
import { UserProfile } from 'src/models/users/UserProfile';
import ServiceCollection from 'src/services/ServiceCollection';
import useReducerWithLogger from 'src/services/utils/ReducerWithLogger';
import TPEAction from 'src/models/common/TPEAction';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import { GlobalAppContext } from '../App';
import CONSTANTS from 'src/utils/constants';
import PreValidationView from './prevalidation/PreValidationView';
import StatusView from './status/StatusView';
import DynamicBreadcrumb from 'src/models/navigation/DynamicBreadcrumb';
import { useLocation } from 'react-router-dom';
import JEStatusDashboardView from "src/components/reports/jeStatusDashboard/JEStatusDashboardView";

export type ContextType = {
    mecState: MECState,
    mecDispatch: React.Dispatch<TPEAction>,
    services: ServiceCollection,
    userProfile: UserProfile,
}

const MECProvider = (props: any) => {
    const { state, dispatch, services, userProfile, children } = props;
    const providerValue = React.useMemo(() => ({
        mecState: state, mecDispatch: dispatch, services, userProfile
    }), [state, dispatch]);
    return (
        <MECContext.Provider value={providerValue}>
            {children}
        </MECContext.Provider>
    );
}
export const MECContext = React.createContext(null as unknown as ContextType);

export default function MECView(props: { services: ServiceCollection }) {
    const [state, dispatch] = useReducerWithLogger(mecReducer, initialState);
    const location: any = useLocation();
    const { pathname } = location;
    const { globalDispatch } = useContext(GlobalAppContext);
    const { services } = props;
    const { selectedTab } = state;

    const { userProfile } = React.useContext(GlobalAppContext);
    const period = moment().subtract(1, 'months').format('MMM YYYY').toUpperCase();

    useEffect(() => {
        globalDispatch(GLOBAL_ACTIONS.SET_DYNAMIC_BREADCRUMB.withPayload(new DynamicBreadcrumb(pathname, CONSTANTS.PAGE_NAV.MONTH_END_CLOSE.TITLE, undefined, undefined, period)));
    }, [period])

    useEffect(() => {
        globalDispatch(GLOBAL_ACTIONS.SET_SELECTED_TAB.withPayload(selectedTab));
    }, [selectedTab])

    return (
        <React.Fragment>
        <MECProvider services={services} state={state} dispatch={dispatch} userProfile={userProfile}>
            <div className="monthEndClose">
                <SpaceBetween direction="vertical" size="m">
                    <div className="topContainerTitle">
                        <Button
                            variant="normal"
                            data-class={selectedTab == CONSTANTS.MEC_TABS.PREVALIDATION ? "activeTab" : "inactiveTab"}
                            onClick={e => {
                                dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.MEC_TABS.PREVALIDATION));
                            }}
                        >Pre-validation</Button>
                        <Button
                            variant="normal"
                            data-class={selectedTab == CONSTANTS.MEC_TABS.JE_STATUS_DASHBOARD ? "activeTab" : "inactiveTab"}
                            onClick={e => {
                                dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.MEC_TABS.JE_STATUS_DASHBOARD));
                            }}
                        >JE Status</Button>
                        <Button
                            variant="normal"
                            data-class={selectedTab == CONSTANTS.MEC_TABS.STATUS ? "activeTab" : "inactiveTab"}
                            onClick={e => {
                                dispatch(ACTIONS.SET_SELECTED_TAB.withPayload(CONSTANTS.MEC_TABS.STATUS));
                            }}
                        >MEC Status</Button>
                    </div>
                    {renderTabView(selectedTab, services)}
                </SpaceBetween>
            </div>
        </MECProvider>
    </React.Fragment>
    );
}

function renderTabView(selectedTab: string, services: ServiceCollection) {
    switch (selectedTab) {
        case CONSTANTS.MEC_TABS.PREVALIDATION:
            return <PreValidationView services={services}/>;
        case CONSTANTS.MEC_TABS.JE_STATUS_DASHBOARD:
            return <JEStatusDashboardView services={services}/>;
        default:
            return <StatusView services={services}/>;
    }
}
