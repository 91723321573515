import React from "react";
import ServiceCollection from "src/services/ServiceCollection";
import {Box, Button, SpaceBetween} from "@amzn/awsui-components-react";
import {Grid, SelectProps} from "@amzn/awsui-components-react/polaris";
import TPTaxCwbReportFilterSelector from "src/components/reports/cwbReport/TPTaxCwbReportFilterSelector";

export default function TPTaxCwbReportView(props: { services: ServiceCollection }) {
    const {services} = props;
    const [selectedAccountingDayOption, setSelectedAccountingDayOption] = React.useState(null as SelectProps.Option | null);
    const [selectedWorkbook, setSelectedWorkbook] = React.useState(null as SelectProps.Option | null);
    const [selectedAccountingPeriod, setSelectedAccountingPeriod] = React.useState(null as SelectProps.Option | null);

    return (
        <>
            <SpaceBetween direction="vertical" size="l">
                <Grid gridDefinition={[{colspan: 10}, {colspan: 2}]}>
                    <TPTaxCwbReportFilterSelector
                        services={services}
                        selectedWorkbook={selectedWorkbook}
                        selectedAccountingPeriod={selectedAccountingPeriod}
                        selectedAccountingDay={selectedAccountingDayOption}
                        onWorkbookSelected={(workbookOption) => setSelectedWorkbook({
                            label: workbookOption?.label,
                            value: workbookOption?.value
                        })}
                        onAccountingPeriodSelected={(accountingPeriodOption) => setSelectedAccountingPeriod({
                            label: accountingPeriodOption?.label,
                            value: accountingPeriodOption?.value
                        })}
                        onAccountingDaySelected={(accountingDayOption) => setSelectedAccountingDayOption({
                            label: accountingDayOption?.label,
                            value: accountingDayOption?.value
                        })}
                    />
                    <Box float="left" padding={{top: "xl"}}>
                        <Button
                            onClick={() => {
                                console.log("Button clicked");
                            }}
                            disabled={(!selectedAccountingDayOption || !selectedAccountingPeriod || !selectedWorkbook)}
                            loading={false}
                            variant="primary"
                        >
                            View Report
                        </Button>
                    </Box>
                </Grid>
            </SpaceBetween>
        </>
    );
}
