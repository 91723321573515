import React, { useState } from "react";
import { Autosuggest } from "@amzn/awsui-components-react";
import Placeholder from "src/models/tp-allocation/Placeholder";

export default function PlaceholderAutosuggest(props: {
    placeholder: Placeholder, 
    lov: string[], 
    inputPlaceholder?: string,
    onBlur: (ph: Placeholder, value: string) => void, onChange?:(value:string) => void }    
    ) {
    const { placeholder, lov, inputPlaceholder, onBlur, onChange } = props;
    const [options, setOptions] = useState([] as any[]);
    const [value, setValue] = useState(placeholder.value || '');
    
    const handleLoadItems = (event: any) => {
        let { filteringText } = event.detail;
        if (filteringText == null || filteringText == '') {
            filteringText = value;
        }

        if (filteringText == null || filteringText == '') {
            setOptions([]);
            return;
        }

        const filteredOptions = lov.filter((x: string) => x.toLowerCase().startsWith(filteringText.toLowerCase())).map((val: string) => { return {value: val} });
        setOptions(filteredOptions || []);
    }

    return <Autosuggest
                key={`placeholderAutoSuggest${placeholder.placeholderId}`}
                data-class="coaAutosuggest"
                onChange={({ detail }) => { 
                    setValue(detail.value);
                    if (onChange) {
                        onChange(detail.value);
                    }
                }}
                onBlur={() => onBlur(placeholder, value)}
                options={options}
                onLoadItems={(event) => handleLoadItems(event)}
                value={value}
                enteredTextLabel={val => `Use: "${val}"`}
                filteringType="manual"
                placeholder={inputPlaceholder || placeholder.placeholderName}
                empty="No matches found"
                statusType={lov == null || lov.length == 0 ? 'loading' : 'finished'}
                loadingText="Loading"
            />
}