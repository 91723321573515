import React from 'react';
import 'src/assets/styles/react-table.scss';
import 'src/components/CustomAppLayout.scss';
import {DefaultColumnFilter, TPEReactTable} from "src/components/shared/TPEReactTable";
import {TaxAuditRecordSummary} from "src/models/reports/TaxAuditRecords";
import {Link} from "@amzn/awsui-components-react";
import CONSTANTS from "src/utils/constants";

export default function TaxAuditRecordTableGrid(props: { loading: boolean, taxAuditRecordTables: TaxAuditRecordSummary[] }) {
    const {loading, taxAuditRecordTables = []} = props;

    const navigateToCalcBuilder = (icTransactionId: string,taxJournalHeaderId: number ) => {
        return CONSTANTS.PAGE_NAV.TAX_AUDIT_RECORD_DETAIL.URL + '/' + icTransactionId + '&' + taxJournalHeaderId;
    };

    const columnDefinitions = React.useMemo(() => [
            {
                accessor: "icTransactionId",
                Header: (e: any) => <div className="cell-text">Ic Transaction Id</div>,
                Filter: DefaultColumnFilter,
                Cell: (cellInfo: any) => {
                    return <Link className="cell-link" external href= {navigateToCalcBuilder(cellInfo.row.original.icTransactionId, cellInfo.row.original.taxJournalHeaderId)}>{cellInfo.value}</Link>
                }
            },
            {
                accessor: "accountingDate",
                Header: (e: any) => <div className="cell-text">Accounting Date</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{new Date(e.value).toLocaleString()}</div>
            },
            {
                accessor: "providerCountryCode",
                Header: (e: any) => <div className="cell-text">Provider Country Code</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "recipientCountryCode",
                Header: (e: any) => <div className="cell-text">Recipient Country Code</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "providerCompanyCode",
                Header: (e: any) => <div className="cell-text">Provider Company Code</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "recipientCompanyCode",
                Header: (e: any) => <div className="cell-text">Recipient Company Code</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "cli",
                Header: (e: any) => <div className="cell-text">CLI</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "taxType",
                Header: (e: any) => <div className="cell-text">Tax Type</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "indirectTaxRate",
                Header: (e: any) => <div className="cell-text">Indirect Tax Rate</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            },
            {
                accessor: "processingStatus",
                Header: (e: any) => <div className="cell-text">Processing Status</div>,
                Filter: DefaultColumnFilter,
                Cell: (e: any) => <div className="cell-text">{e.value}</div>
            }]
        , []);

    return (
            <TPEReactTable
                className={'taxAuditRecordGrid'}
                isSearching={loading}
                data={taxAuditRecordTables}
                columnDefinitions={columnDefinitions}
                emptyMessage = {'There are no Tax Audit Records for the selected accounting period & CLI.'}
                sortable={false}/>
    )}