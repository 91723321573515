import React from 'react';
import 'src/assets/styles/react-table.scss';
import { renderExpressionParts } from 'src/components/shared/FormulaExpressionFunctionComponents';
import { AllocationFormula } from 'src/models/tp-allocation/AllocationFormula';
import { DataSourcesContext } from './DataSourcesContainer';
import TPESelectableReactTable from 'src/components/shared/TPESelectableReactTable';

export default function TPAllocationFormulaSelectorGrid(props: {data: AllocationFormula[], loading: boolean, onSelect: (e: AllocationFormula | undefined) => any}) {

    const { data = [], loading, onSelect } = props;

    const { services } = React.useContext(DataSourcesContext);

    const columnDefinitions = React.useMemo(() => [
        {
            accessor: "description",
            Header: (e: any) => <div className="cell-text">Formula name</div>,
            Cell: (e: any) => <div className="cell-text">{e.value}</div>
        },
        {
            accessor: "expressionTokens",
            Header: (e: any) => <div className="cell-text">Formula overview</div>,
            Cell: (e: any) => <div className="cell-text">{renderExpressionParts(e.value)}</div>
        },
        {
            accessor: "value",
            Header: (e: any) => <div className="cell-text">Value</div>,
            Cell: (e: any) => <div className="cell-text">{services.formattingService.formatString(e.value)}</div>
        }]
    ,[data]);

    return <div>
        <TPESelectableReactTable {...{ 
                data, 
                columnDefinitions, 
                className: 'allocationFormulasGrid',
                isSearching: loading, 
                isSearchingMessage: 'Searching', 
                showFilters: false,
                sortable: false,
                onRowClicked:(isChecked, allocationFormula: AllocationFormula, index) =>  onSelect(isChecked ? allocationFormula : undefined)} 
        } />
    </div>
    
}