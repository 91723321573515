import CONSTANTS from "src/utils/constants";

export const REPORT_DETAILS: {
    [key: string]: {
        headerName: string;
        reportType: string;
    };
} = Object.freeze({
    CALCULATIONS: {
        headerName: CONSTANTS.REPORT_HEADERS.CALCULATIONS,
        reportType: CONSTANTS.REPORT_TYPES.CALCULATIONS
    },
    DATASOURCES: {
        headerName: CONSTANTS.REPORT_HEADERS.DATASOURCES,
        reportType: CONSTANTS.REPORT_TYPES.DATASOURCES
    },
    CUSTOM_COA: {
        headerName: CONSTANTS.REPORT_HEADERS.CUSTOM_COA,
        reportType: CONSTANTS.REPORT_TYPES.CUSTOM_COA
    },
    JOURNAL_DETAILS: {
        headerName: CONSTANTS.REPORT_HEADERS.JOURNAL_DETAILS,
        reportType: CONSTANTS.REPORT_TYPES.JOURNAL_DETAILS
    },
    TP_TRENDING: {
        headerName: CONSTANTS.REPORT_HEADERS.TP_TRENDING.TP_TRENDING_REPORT,
        reportType: CONSTANTS.REPORT_TYPES.TP_TRENDING
    },
    TAX_AGREEMENT_DETAILS: {
        headerName: CONSTANTS.REPORT_HEADERS.TAX_AGREEMENT_DETAILS,
        reportType: CONSTANTS.REPORT_TYPES.TAX_AGREEMENT_DETAILS
    },
    TP_TAX: {
        headerName: CONSTANTS.REPORT_HEADERS.TP_TAX,
        reportType: CONSTANTS.REPORT_TYPES.TP_TAX
    },
    TP_TAX_LEF_REPORT: {
        headerName: CONSTANTS.REPORT_HEADERS.TP_TAX_LEF_REPORT,
        reportType: CONSTANTS.REPORT_TYPES.TP_TAX_LEF_REPORT
    },
    TP_TAX_VARIANCE: {
        headerName: CONSTANTS.REPORT_HEADERS.TP_TAX_VARIANCE,
        reportType: CONSTANTS.REPORT_TYPES.TP_TAX_VARIANCE
    },
    TP_TAX_CWB_REPORT: {
        headerName: CONSTANTS.REPORT_HEADERS.TP_TAX_CWB_REPORT,
        reportType: CONSTANTS.REPORT_TYPES.TP_TAX_CWB_REPORT
    },
    COA_CHANGE_MANAGEMENT: {
        headerName: CONSTANTS.REPORT_HEADERS.COA_CHANGE_MANAGEMENT,
        reportType: CONSTANTS.REPORT_TYPES.COA_CHANGE_MANAGEMENT
    },
    PRE_VALIDATION_PTA: {
        headerName: CONSTANTS.REPORT_HEADERS.PRE_VALIDATION_REPORTS,
        reportType: CONSTANTS.REPORT_TYPES.PRE_VALIDATION_PTA
    },
    PRE_VALIDATION_CDT: {
        headerName: CONSTANTS.REPORT_HEADERS.PRE_VALIDATION_REPORTS,
        reportType: CONSTANTS.REPORT_TYPES.PRE_VALIDATION_CDT
    },
    PRE_VALIDATION_COA: {
        headerName: CONSTANTS.REPORT_HEADERS.PRE_VALIDATION_REPORTS,
        reportType: CONSTANTS.REPORT_TYPES.PRE_VALIDATION_COA
    },
    PRE_VALIDATION_INDIRECT_TAX: {
        headerName: CONSTANTS.REPORT_HEADERS.PRE_VALIDATION_REPORTS,
        reportType: CONSTANTS.REPORT_TYPES.PRE_VALIDATION_INDIRECT_TAX
    },
    PRE_VALIDATION_CLI_STATUS: {
        headerName: CONSTANTS.REPORT_HEADERS.PRE_VALIDATION_REPORTS,
        reportType: CONSTANTS.REPORT_TYPES.PRE_VALIDATION_CLI_STATUS
    },
    ALLOCATION_TRENDING: {
        headerName: CONSTANTS.REPORT_HEADERS.ALLOCATION_TRENDING,
        reportType: CONSTANTS.REPORT_TYPES.ALLOCATION_TRENDING
    },
    FORMULA_SET: {
        headerName: CONSTANTS.REPORT_HEADERS.FORMULA_SET,
        reportType: CONSTANTS.REPORT_TYPES.FORMULA_SET
    },
    CALCULATION_CHANGE: {
        headerName: CONSTANTS.REPORT_HEADERS.CALCULATION_CHANGE,
        reportType: CONSTANTS.REPORT_TYPES.CALCULATION_CHANGE
    },
    CUSTOM_DATA_AUTO_INSERT: {
        headerName: CONSTANTS.REPORT_HEADERS.CUSTOM_DATA_AUTO_INSERT,
        reportType: CONSTANTS.REPORT_TYPES.CUSTOM_DATA_AUTO_INSERT
    },
    TAX_AUDIT_RECORD: {
        headerName: CONSTANTS.REPORT_HEADERS.TAX_AUDIT_RECORD,
        reportType: "TAX_AUDIT_RECORD"
    }
});