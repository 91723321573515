import React, { useEffect, useState } from 'react';
import { Button, Cards, Container, Header, SelectProps, SpaceBetween } from "@amzn/awsui-components-react";
import TPENavigator from 'src/components/TPENavigator';
import CONSTANTS from 'src/utils/constants';
import ServiceCollection from 'src/services/ServiceCollection';
import { PeriodDropdownOption } from 'src/models/reports/GetReportingPeriodListResult';
import TPTaxFilterReportModal from '../TPTaxFilterReportModal';
import { getHeaderActionDetailsForIndirectTaxReportCard, getHeaderActionDetailsForTPAccountingReportCard, getHeaderActionDetailsForTPTaxReportCard } from './CardHeaderDefinitions';
import PeriodFilterReportModal from '../PeriodFilterReportModal';
import NoFilterReportModal from '../NoFilterReportModal';
import TPEErrorWatcher from 'src/components/shared/TPEErrorWatcher';
import { REPORT_DETAILS } from './ReportDefinitionConstants';
import { INDIRECT_TAX_REPORT_DETAILS, TP_ACCOUNTING_REPORT_DETAILS, TP_TAX_REPORT_DETAILS } from './DashboardConstants';

type ComponentProps = {
    services: ServiceCollection

}

export default function ReportViewDashboard(props: ComponentProps) {
    const [navigateURL, setNavigateURL] = React.useState('');
    const [showPeriodFilterReportModal, setShowPeriodFilterReportModal] = React.useState(false);
    const [showTPTaxMARFilterReportModal, setShowTPTaxMARFilterReportModal] = React.useState(false);
    const [showTPTaxLEFFilterReportModal, setShowTPTaxLEFFilterReportModal] = React.useState(false);
    const [showNoFilterReportModal, setShowNoFilterReportModal] = React.useState(false);
    const [periodList, setPeriodList] = React.useState([] as PeriodDropdownOption[]);
    const [workbookIdDropdownList, setWorkbookIdDropdownList] = React.useState([] as SelectProps.Option[]);
    const [companyCodeDropdownList, setCompanyCodeDropdownList] = React.useState([] as SelectProps.Option[]);
    const [loadTime, setLoadTime] = useState((new Date()).toString());
    const [reportName, setReportName] = React.useState(null as unknown as string);
    const [reportHeader, setReportHeader] = React.useState(null as unknown as string);
    const [getReportingPeriodsResponse, getReportingPeriodsLoading, getReportingPeriodsError] = props.services.reportsService.getReportingPeriods();
    const [workbooksListResponse, isWorkbooksDataLoading, workbooksListFetchError] = props.services.mecService.getWorkbooks(loadTime);
    const [companyCodesListResponse, isCompanyCodesDataLoading, companyCodesFetchError] = props.services.calculationsService.getCompany(CONSTANTS.COA_SEGMENT_MAPPING.COMPANY.WEB_API);

    useEffect(() => {
        if (!companyCodesFetchError) {
            return;
        }
        props.services.messageService.showErrorAutoDismissBanner(companyCodesFetchError);
    }, [companyCodesFetchError])

    useEffect(() => {
        if (!workbooksListFetchError) {
            return;
        }
        props.services.messageService.showErrorAutoDismissBanner(workbooksListFetchError);
    }, [workbooksListFetchError])

    useEffect(() => {
        if (!getReportingPeriodsError) {
            return;
        }
        props.services.messageService.showErrorAutoDismissBanner(workbooksListFetchError);
    }, [getReportingPeriodsError])

    useEffect(() => {
        if (!getReportingPeriodsResponse)
            return;

        setPeriodList([...getReportingPeriodsResponse.ReportingPeriodList.map(value => ({
            label: value,
            value: value
        }))]);

    }, [getReportingPeriodsResponse]);

    useEffect(() => {
        if (!workbooksListResponse) {
            return;
        }
        const updatedWorkbookList: SelectProps.Option[] = [];
        workbooksListResponse.forEach(workbook => {
            const workbookOption: SelectProps.Option = {
                label: workbook.workbookName,
                value: workbook.workbookId
            };
            updatedWorkbookList.push(workbookOption);
        });

        // Update the state with the new workbook list
        setWorkbookIdDropdownList(updatedWorkbookList);
    }, [workbooksListResponse]);

    useEffect(() => {
        if (!companyCodesListResponse) {
            return;
        }
        const updatedCompanyCodeList: SelectProps.Option[] = [];
        companyCodesListResponse.forEach(companyCode => {
            const companyCodeOption: SelectProps.Option = {
                label: companyCode,
                value: companyCode
            };
            updatedCompanyCodeList.push(companyCodeOption);
        });

        // Update the state with the new company code list
        setCompanyCodeDropdownList(updatedCompanyCodeList);
    }, [companyCodesListResponse]);

    const setReportNameAndReportHeader = (header: string, type: string) => {
        setReportHeader(header);
        setReportName(type);
    }


    return (
        <SpaceBetween size='s'>
            <TPENavigator path={navigateURL} />
            <Container header={
                <Header variant="h2"
                    actions={<Button onClick={() => setNavigateURL('/reports/history')}>History</Button>}>
                    Transfer Pricing Reports
                </Header>}>
                <Cards
                    cardDefinition={{
                        header: (item) => getHeaderActionDetailsForTPTaxReportCard(item.name, setReportNameAndReportHeader, setShowTPTaxMARFilterReportModal, setNavigateURL, setShowTPTaxLEFFilterReportModal),
                        sections: [
                            {
                                id: "description",
                                header: "Description",
                                content: (item) => item.description
                            }
                        ]
                    }}
                    cardsPerRow={[{ cards: 3 }]}
                    items={TP_TAX_REPORT_DETAILS}
                />
                <TPTaxFilterReportModal
                    visible={showTPTaxMARFilterReportModal}
                    onClose={() => setShowTPTaxMARFilterReportModal(false)}
                    reportName={reportName}
                    periodList={periodList}
                    reportHeader={reportHeader}
                    services={props.services}
                    workbookList={workbookIdDropdownList}
                />
                <TPTaxFilterReportModal
                    visible={showTPTaxLEFFilterReportModal}
                    onClose={() => setShowTPTaxLEFFilterReportModal(false)}
                    reportName={reportName}
                    periodList={periodList}
                    reportHeader={reportHeader}
                    services={props.services}
                    companyCodeList={companyCodeDropdownList}
                />
            </Container>
            <Container header={
                <Header variant="h2"
                    actions={<Button onClick={() => setNavigateURL('/reports/history')}>History</Button>}>
                    Accounting Reports
                </Header>}>
                <Cards
                    cardDefinition={{
                        header: (item) => getHeaderActionDetailsForTPAccountingReportCard(item.name, setReportNameAndReportHeader, setShowNoFilterReportModal, setShowPeriodFilterReportModal),
                        sections: [
                            {
                                id: "description",
                                header: "Description",
                                content: (item) => item.description
                            }
                        ]
                    }}
                    cardsPerRow={[{ cards: 3 }]}
                    items={TP_ACCOUNTING_REPORT_DETAILS}
                />
                <PeriodFilterReportModal
                    visible={showPeriodFilterReportModal}
                    onClose={() => setShowPeriodFilterReportModal(false)}
                    reportName={reportName}
                    periodList={periodList}
                    reportHeader={reportHeader}
                    services={props.services}
                />

                <NoFilterReportModal
                    visible={showNoFilterReportModal}
                    onClose={() => setShowNoFilterReportModal(false)}
                    reportName={reportName}
                    reportHeader={reportHeader}
                    services={props.services}
                />
            </Container>
            <Container header={
                <Header variant="h2">
                    Tax Reports
                </Header>}>
                <Cards
                    cardDefinition={{
                        header: (item) => getHeaderActionDetailsForIndirectTaxReportCard(item.name, setNavigateURL),
                        sections: [
                            {
                                id: "description",
                                header: "Description",
                                content: (item) => item.description
                            }
                        ]
                    }}
                    cardsPerRow={[{ cards: 3 }]}
                    items={INDIRECT_TAX_REPORT_DETAILS}
                />
            </Container>
        </SpaceBetween>
    )
}